import { BaseInsurancePolicy, InsurancePolicyEntityType } from "@elphi/types";
import {
  EntityId,
  EntityState,
  PayloadAction,
  createSlice
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { SearchCursorState } from "../utils/search.utils";
import { insurancePolicyAdapter } from "./insurancePolicy.adapter";
import { insurancePolicyApi } from "./insurancePolicy.service";
import { propertyInsurancePolicyApi } from "./propertyInsurancePolicy.service";
export type InsurancePolicySliceState = EntityState<
  BaseInsurancePolicy<InsurancePolicyEntityType>
> & { searchCursor: SearchCursorState } & {
  selectedId?: EntityId;
};

const initialState: InsurancePolicySliceState = {
  ids: [],
  entities: {},
  selectedId: undefined,
  searchCursor: {
    query: {}
  }
};

export const insurancePolicySlice = createSlice({
  name: "insurancePolicy",
  initialState: insurancePolicyAdapter.getInitialState(initialState),
  reducers: {
    update: insurancePolicyAdapter.updateOne,
    remove: insurancePolicyAdapter.removeOne,
    add: insurancePolicyAdapter.addOne,
    upsert: insurancePolicyAdapter.upsertOne,
    upsertMany: insurancePolicyAdapter.upsertMany,
    removeMany: insurancePolicyAdapter.removeMany,
    updateMany: insurancePolicyAdapter.updateMany,
    setSelectId: (state, action: PayloadAction<{ id?: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(propertyInsurancePolicyApi)(builder);
    builder.addMatcher(
      insurancePolicyApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
        state.searchCursor.query[payload.query] = {
          hasMore: payload.hasMore,
          nextCursor: payload.nextCursor
        };
      }
    );
    builder.addMatcher(
      insurancePolicyApi.endpoints.get.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, [payload.id]);
        state.entities = {
          ...state.entities,
          ...keyBy([payload], "id")
        };
      }
    );
    builder.addMatcher(
      insurancePolicyApi.endpoints.getAll.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.results.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.results, "id")
        };
      }
    );
  }
});
