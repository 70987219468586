import { InsurancePolicyCoverages, InsurancePolicyView } from "@elphi/types";
import { buildAction } from "../../builders/action.builder";
import { ActionDispatcher } from "../../types/action.types";
import { insurancePolicyViewSlice } from "./insurancePolicyView.slice";

export const insuranceViewAction: ActionDispatcher<
  InsurancePolicyView<InsurancePolicyCoverages>
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(insurancePolicyViewSlice);
  return actionDispatcher(dispatch, callback);
};
