import { pickFromEnum } from "../../../utils/enums";
import { LoanProgramType } from "../../deal.enums.types";

export const baseChannelType = {
  Retail: "Retail",
  Broker: "Broker",
  Correspondent: "Correspondent",
  Wholesale: "Wholesale"
} as const;

export const baseAggregationLoanProductType = {
  N51ARM: "5/1 ARM",
  N101ARM: "10/1 ARM",
  N5IO25ARM: "5 IO - 25ARM",
  Fixed: "Fixed",
  N71ARM: "7/1 ARM"
} as const;

export const baseLoanProductType = {
  N51ARM: "5/1 ARM",
  N71ARM: "7/1 ARM",
  Fixed: "Fixed"
} as const;
export const baseAssignmentStatus = {
  InvestorResponsibleForRecording: "Investor Responsible for Recording",
  ReadyToBeSent: "Ready to be sent",
  SentForRecording: "Sent for Recording",
  ReceivedRecordedOriginal: "Received Recorded (original)",
  ReceivedRecordedElectronicOnly: "Received Recorded (electronic only)",
  UploadedRecordedInvestorSite: "Uploaded Recorded Investor Site",
  MailedRecordedToInvestor: "Mailed Recorded to Investor",
  NotRequired: "Not Required",
  MERS: "MERS"
} as const;
export const baseDefaultStatus = {
  N30Plus: "30+",
  N60Plus: "60+",
  N90Plus: "90+",
  FCL: "FCL",
  REO: "REO"
} as const;
export const baseLoanTermPeriodMonthCount = {
  N12: "12",
  N18: "18",
  N24: "24",
  N360: "360",
  N480: "480"
} as const;

export const basePaymentDueDay = {
  N10: "10",
  N1: "1"
} as const;

export const baseLoanPayment = {
  PI: "Principal & Interest",
  IO: "Interest Only",
  Hybrid: "Hybrid"
} as const;

export const baseInterestType = {
  Dutch: "Dutch",
  NonDutch: "Non-Dutch"
} as const;

export const basePrepaymentPenaltyTerm = {
  None: "0",
  TwelveMonths: "12",
  TwentyFourMonths: "24",
  ThirtySixMonths: "36",
  FortyEightMonths: "48",
  SixtyMonths: "60",
  EightyFourMonths: "84"
} as const;

export const baseLockPeriod = {
  FifteenDays: "15",
  ThirtyDays: "30",
  FortyFiveDays: "45",
  SixtyDays: "60",
  SeventyFiveDays: "75",
  NinetyDays: "90"
} as const;

export const basePrepaymentPenaltyType = {
  NA: "N/A",
  N2: "2%",
  N3: "3%",
  N5: "5%",
  N21: "2/1%",
  N321: "3/2/1%",
  N4321: "4/3/2/1%",
  N54321: "5/4/3/2/1%",
  N5544321: "5/5/4/4/3/2/1%",
  N620: "6 months interest on amount prepaid >20%"
} as const;

export const baseLoanProgramType = {
  ...pickFromEnum(LoanProgramType, [
    "Rental30",
    "FixNFlip",
    "NewConstruction",
    "BridgePlus"
  ])
} as const;

export const baseDealExceptionApprovedBy = {
  Underwriter: "Underwriter",
  Director: "Director"
} as const;
