import {
  FloodCertificateVendorType,
  IntegrationType,
  ServiceLinkOptions,
  StatusCode,
  Task,
  TaskType
} from "@elphi/types";
import { responseHandler } from "../apis/rtk/response.handler";
import { useElphiToast } from "../components/toast/toast.hook";
import { serviceLinkFloodApi } from "../redux/v2/integration/service-link/service-link-flood.service";
import { usePropertyFormHandler } from "./propertyform.hooks";

const optionOrderHandlerMessageMap = {
  [ServiceLinkOptions.CertificateOnly]: "certificate",
  [ServiceLinkOptions.DeterminationOnly]: "determination service",
  [ServiceLinkOptions.DeterminationAndCertificate]: "determination service",
  [ServiceLinkOptions.UpdateCertificateOrder]: "update certificate"
};

export const useServiceLinkFloodTaskHooks = () => {
  const { errorToast, successToast } = useElphiToast();
  const { updatePropertyHandler } = usePropertyFormHandler();
  const [createServiceLinkFloodOrderApi, createServiceLinkFloodOrderResponse] =
    serviceLinkFloodApi.useCreateOrderMutation();

  const optionOrderHandler = (r: {
    taskId: string;
    propertyId: string;
    dealId: string;
    option: ServiceLinkOptions;
  }) => {
    createServiceLinkFloodOrderApi(r)
      .then(responseHandler)
      .then((res) => {
        const message = optionOrderHandlerMessageMap[r.option];
        if (res.status === StatusCode.OK) {
          successToast({
            title: `Flood ${message} ordered successfully`,
            description: `task id ${res.data.taskId}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: `Flood ${message} order failed`,
            description: `description ${
              res.data.description
            }, error ${JSON.stringify(res.data.error)}`
          });
        }
        return r;
      });
  };

  const getServiceLinkTask = (
    task: Task | undefined,
    vendor: FloodCertificateVendorType
  ) =>
    task?.type === TaskType.Integration &&
    task?.integrationType === IntegrationType.FloodCertificate &&
    task?.vendorType === vendor
      ? task
      : undefined;

  return {
    updatePropertyHandler,
    optionOrderHandler,
    getServiceLinkTask,
    createServiceLinkFloodOrderResponse
  };
};
