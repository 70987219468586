import { Asset } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import { buildSection } from "../../utils/formBuilder.utils";
import {
  accountName,
  accountType,
  assetType,
  financialInstitution,
  fullNameOnAccount,
  last4Digits
} from "../inputs/asset.inputs";
import { BaseSchemaAssetSections, BaseSchemaSectionsBuilders } from "../types";

const assetInfoSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Asset>;
    onChange: (v: OnChangeInput) => void;
    options: {
      header?: string;
      hideAttachedComponent: boolean;
    };
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Asset Info",
      inputs: [assetType],
      hideAttachedComponent: r.options.hideAttachedComponent,
      fieldSpecs
    });

const createAccountSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Asset>;
    onChange: (v: OnChangeInput) => void;
    options: {
      header?: string;
      hideAttachedComponent: boolean;
    };
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: r.options.header || "",
      inputs: [
        fullNameOnAccount,
        accountName,
        accountType,
        financialInstitution,
        last4Digits
      ],
      hideAttachedComponent: r.options.hideAttachedComponent,
      fieldSpecs
    });

export const assetSectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaAssetSections> =
  {
    assetInfoSection,
    createAccountSection
  };
