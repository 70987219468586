import { PartyUser } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { partyUserRelationSlice } from "./partyUserRelation.slice";
export const partyUserRelationAction: ActionDispatcher<PartyUser> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: PartyUser[]) => void;
    update?: (r: Update<PartyUser>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = partyUserRelationSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

export default partyUserRelationAction;
