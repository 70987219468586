import { pickFromEnum } from "../../../utils/enums";
import { DealMilestoneType } from "../../deal.enums.types";

export const mofinDealMilestoneType = {
  ...pickFromEnum(DealMilestoneType, [
    "NewDeal",
    "InitialDealReview",
    "Processing"
  ]),
  UnderwriterReview: "Underwriter Review",
  FinalTermsCommunicated: "Final Terms Communicated",
  TermsApprovedFinalUnderwriterReview:
    "Terms Approved / Final Underwriter Review",
  PreCloseSubmitted: "Pre-close Submitted",
  ConditionClearing: "Condition Clearing",
  ...pickFromEnum(DealMilestoneType, ["ClearToClose"]),
  LoanDocumentsSent: "Loan Documents Sent",
  LoanFundedClosed: "Loan Funded / Closed",
  PostCloseSubmitted: "Post-Close Submitted",
  PostCloseClear: "Post-Close Clear",
  ...pickFromEnum(DealMilestoneType, ["CloseOfEscrow", "FileComplete"]),
  SuspendedDeal: "Suspended Deal",
  Dead: "Dead"
} as const;

export const mofinACHStatusType = {
  Active: "Active",
  Declined: "Declined",
  SentToBorrower: "Sent to Borrower",
  SentToServicer: "Sent to Servicer",
  SentToInvestor: "Sent to Investor",
  NeedToSendToServicer: "Signed form received, Need to send to servicer",
  BorrowerDirectly: "Borrower setting up directly",
  NeedToSend: "Need to send"
} as const;

export const mofinInsuranceMortgageeStatusType = {
  ReadyToRequest: "Ready to Request",
  Requested: "Requested",
  Complete: "Complete"
} as const;

export const mofinServicerIdentifierType = {
  FCI: "FCI",
  SPS: "SPS",
  BSI: "BSI",
  FAY: "FAY",
  Shellpoint: "Shellpoint",
  Mofin: "MoFin",
  Other: "Other"
} as const;

export const mofinInvestorIdentifierType = {
  AngelOak: "Angel Oak",
  Bayview: "Bayview",
  Blackstone: "Blackstone",
  Eastview: "Eastview",
  eResi: "eResi",
  MLCManagement: "MLC Management",
  Mofin: "MoFin",
  NYMT: "NYMT",
  Pretium: "Pretium",
  Toorak: "Toorak",
  Verus: "Verus"
} as const;

export const mofinLenderIdentifierType = {
  MoFinLendingCorporation: "MoFin Lending Corporation"
} as const;

export const mofinOperationsDepartmentType = {
  FL: "FL",
  NY: "NY"
} as const;

export const mofinPaymentDueDayType = {
  N1: "1"
} as const;

export const mofinWarehouseBankTypeType = {
  Citibank: "Citibank",
  Northpointe: "Northpointe"
} as const;

export const mofinWarehouseBankStatusTypeType = {
  WireRequested: "Wire Requested",
  Funded: "Funded"
} as const;

export const mofinChannelType = {
  Broker: "Broker",
  Retail: "Retail"
} as const;

export const mofinDealExceptionApprovedBy = {
  Underwriter: "Underwriter",
  Director: "Director"
} as const;
