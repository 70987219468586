import { RoleGroup } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { roleGroupSlice } from "./roleGroup.slice";

export const roleGroupAction: ActionDispatcher<RoleGroup> = (
  dispatch,
  callback
) => {
  const actionDispatcher = buildAction(roleGroupSlice);
  return actionDispatcher(dispatch, callback);
};
