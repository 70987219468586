import { StatusCode } from "@elphi/types";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { posUserApi } from "../redux/v2/pos";

export const usePosUserHooks = () => {
  const { errorToast } = useElphiToast();
  const [searchApi, searchResponse] = posUserApi.useLazySearchQuery();
  const [getByPartyIdApi, getUserByPartyIdResponse] =
    posUserApi.useLazyGetByPartyIdQuery();

  const posUserState = useSelector((state: RootState) => state.posUserState);

  const handleGetUserByPartyId = async (partyId: string) => {
    if (!partyId) {
      return;
    }
    return await getByPartyIdApi({ partyId }, true)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (
          r.status === StatusCode.BadRequest &&
          r.data?.error?.status !== StatusCode.NotFound
        ) {
          errorToast({
            title: "Failed to get user",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  return {
    searchApi,
    searchResponse,
    posUserState,
    handleGetUserByPartyId,
    getUserByPartyIdResponse
  };
};
