// import { useEffect, useState } from "react";
// import localStorage from "redux-persist/es/storage";
// import { useOrgHooks } from "../hooks/org.hooks";
import { getBaseUrl } from "./envUtils";

// export const useElphiUrl = () => {
//   const { selectedOrgId } = useOrgHooks();
//   const [baseUrl, setBaseUrl] = useState<string>("");
//   const [rootUrl, setRootUrl] = useState<string>("");
//   useEffect(() => {
//     if (selectedOrgId) {
//       setBaseUrl(getBaseUrl(selectedOrgId.toString()));
//       setRootUrl(getBaseUrl(""));
//       localStorage.setItem("orgId", selectedOrgId?.toString() || "");
//     }
//   }, [selectedOrgId]);
//   return {
//     baseUrl,
//     rootUrl
//   };
// };

export const baseUrl = getBaseUrl("");
export const rootUrl = getBaseUrl("");
