import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Progress,
  Text,
  Tooltip
} from "@chakra-ui/react";
import {
  AggregationType,
  CreditScore,
  ElphiEntityType,
  IndividualParty,
  Party
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { get, orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../../apis/rtk/response.handler";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { AppConfig } from "../../config/appConfig";
import { getSpecs } from "../../forms/schemas/factories/specsFactory";
import { useCreditReportHooks } from "../../hooks/creditReport.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { RootState } from "../../redux/store";
import {
  CreditReportSliceState,
  creditReportApi
} from "../../redux/v2/credit-report";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { getFocusedAggregationSpecs } from "../form-builder/field-specs/utils/aggregation.utils";
import { ElphiTable } from "../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { useElphiToast } from "../toast/toast.hook";
import { TableInput } from "../utils/tableUtils";
import { CreateCreditReportModal } from "./CreateCreditReportModal";
import { DeleteCreditReportModal } from "./DeleteCreditReportModal";

export const CreditReportHistoryTableContainer = (props: {
  selectedParty: Partial<Party>;
  snapshotId?: string;
  getLatestScore?: Function;
  includeManualCreate?: boolean;
}) => {
  return props.snapshotId ? (
    <CreditReportHistorySnapshotContainer
      {...props}
      snapshotId={props.snapshotId}
    />
  ) : (
    <CreditReportHistoryLiveStateContainer {...props} />
  );
};
export const CreditReportHistorySnapshotContainer = (props: {
  selectedParty: Partial<Party>;
  snapshotId: string;
  getLatestScore?: Function;
  includeManualCreate?: boolean;
}) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({
    snapshotId: props.snapshotId
  });
  if (!snapshot.partyState || !snapshot.creditReportState) {
    return <>empty credit report snapshot</>;
  }
  return (
    <CreditReportHistoryTable
      getLatestScore={props.getLatestScore}
      snapshotId={props.snapshotId}
      isDisabled={!!props.snapshotId}
      selectedParty={props.selectedParty}
      partyState={snapshot.partyState}
      creditReportState={snapshot.creditReportState}
      isLoading={false}
      includeManualCreate={props.includeManualCreate}
    />
  );
};
export const CreditReportHistoryLiveStateContainer = (props: {
  selectedParty: Partial<Party>;
  getLatestScore?: Function;
  includeManualCreate?: boolean;
}) => {
  const partyState = useSelector((state: RootState) => state.party);
  const creditReportState = useSelector(
    (state: RootState) => state.creditReport
  );
  const { selectedParty } = props;
  const { getPartyCreditScores, getPartyCreditScoresResponse } =
    useCreditReportHooks();

  useEffect(() => {
    if (selectedParty && selectedParty.id) {
      getPartyCreditScores(selectedParty.id);
    }
  }, [selectedParty]);

  return (
    <CreditReportHistoryTable
      getLatestScore={props.getLatestScore}
      selectedParty={selectedParty}
      partyState={partyState}
      creditReportState={creditReportState}
      isLoading={getPartyCreditScoresResponse.isLoading}
      includeManualCreate={props.includeManualCreate}
    />
  );
};

export type CreditScoreStructure = {
  creditReports: { [id: string]: CreditScore };
};
export const CreditReportHistoryTable = (props: {
  snapshotId?: string;
  isDisabled?: boolean;
  selectedParty: Partial<Party>;
  partyState: EntityState<Party>;
  creditReportState: CreditReportSliceState;
  isLoading: boolean;
  getLatestScore?: Function;
  includeManualCreate?: boolean;
}) => {
  const { partyState, creditReportState, selectedParty, isLoading } = props;
  const PAGE_SIZE = 10;
  const [pageIndex, setPageIndex] = useState(1);
  const { errorToast, successToast } = useElphiToast();
  const fieldsSpecs = getSpecs();

  const [updateBatchCreditScoresApi] = creditReportApi.useBatchUpdateMutation();
  const updateBatchCreditScores = async (newCreditScores: {
    creditScores: ({ id: string } & Partial<CreditScoreStructure>)[];
  }) => {
    return await updateBatchCreditScoresApi(newCreditScores).then(
      responseHandler
    );
  };

  const updateCreditScoresHandler = async (
    diff: Partial<CreditScoreStructure>
  ) => {
    if (!diff.creditReports) return null;
    const creditReports = Object.keys(diff.creditReports)
      .map((id) => {
        if (diff.creditReports && diff.creditReports[id]) {
          return {
            ...diff.creditReports[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!creditReports.length) return null;
    return await updateBatchCreditScores({ creditScores: creditReports } as {
      creditScores: ({
        id: string;
      } & Partial<CreditScore>)[];
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Credit Scores Updated",
          description: `${r.data.batch.length} scores updated`
        });
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to update credit scores",
          description: r.data.description
        });
      }
      return r;
    });
  };

  const {
    onChange: onCreditReportChange,
    state: localCreditReportState,
    syncState: syncCreditReportState
  } = useFormBuilderStateHandler({
    initialState: {
      creditReports: {}
    },
    callback: updateCreditScoresHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (creditReportState) {
      syncCreditReportState({
        shouldSync: !!creditReportState,
        state: creditReportState.entities,
        statePath: () => {
          return ["creditReports"];
        }
      });
    }
  }, [creditReportState]);

  const getCreditReportRowData = useCallback(
    (currentCreditReportId: EntityId) => {
      return {
        party: selectedParty,
        creditReport: creditReportState.entities[currentCreditReportId]
      };
    },
    [partyState, creditReportState, selectedParty]
  );

  const pageData = useMemo(() => {
    if (!selectedParty) return [];
    const currentCreditReports = orderBy(
      creditReportState.ids.filter((creditReportId) => {
        const currentEntity = creditReportState?.entities?.[creditReportId];
        return currentEntity && currentEntity?.partyId === selectedParty.id;
      }),
      ["createdAt._seconds"],
      ["desc"]
    );
    return currentCreditReports.map((id) => getCreditReportRowData(id));
  }, [selectedParty, pageIndex, creditReportState]);

  const pageDataMemo = useMemo(() => {
    return pageData.slice((pageIndex - 1) * PAGE_SIZE, pageIndex * PAGE_SIZE);
  }, [pageIndex, pageData]);

  const getLocalCreditReportAggregationFocused = ({
    creditReportId,
    fieldPath
  }: {
    creditReportId?: string;
    fieldPath: DotNestedKeys<CreditScore>;
  }): "override" | "calculated" | "thirdParty" | undefined => {
    return creditReportId
      ? get(
          localCreditReportState?.creditReports[creditReportId],
          String(fieldPath)
        )
      : undefined;
  };

  const creditReportHistoryTableFullDataRows: ElphiTableProps["rows"] =
    useMemo(() => {
      const creditReportFieldSpecs =
        fieldsSpecs?.[ElphiEntityType.creditScore]?.entitySpecs;
      return (
        pageDataMemo?.flatMap((rowData, i) => {
          const specFieldExpirationDate = getFocusedAggregationSpecs({
            spec: creditReportFieldSpecs?.aggregations?.expirationDate,
            focused: getLocalCreditReportAggregationFocused({
              creditReportId: rowData.creditReport?.id,
              fieldPath: "aggregations.expirationDate.focused"
            })
          });
          const specFieldExperianScore = getFocusedAggregationSpecs({
            spec: creditReportFieldSpecs?.experian?.score,
            focused: getLocalCreditReportAggregationFocused({
              creditReportId: rowData.creditReport?.id,
              fieldPath: "experian.score.focused"
            })
          });
          const specFieldEquifaxScore = getFocusedAggregationSpecs({
            spec: creditReportFieldSpecs?.equifax?.score,
            focused: getLocalCreditReportAggregationFocused({
              creditReportId: rowData.creditReport?.id,
              fieldPath: "equifax.score.focused"
            })
          });
          const specFieldTransUnionScore = getFocusedAggregationSpecs({
            spec: creditReportFieldSpecs?.transunion?.score,
            focused: getLocalCreditReportAggregationFocused({
              creditReportId: rowData.creditReport?.id,
              fieldPath: "transunion.score.focused"
            })
          });
          const specFieldExperianReportDate = getFocusedAggregationSpecs({
            spec: creditReportFieldSpecs?.experian?.date,
            focused: getLocalCreditReportAggregationFocused({
              creditReportId: rowData.creditReport?.id,
              fieldPath: "experian.date.focused"
            })
          });
          const specFieldEquifaxReportDate = getFocusedAggregationSpecs({
            spec: creditReportFieldSpecs?.equifax?.date,
            focused: getLocalCreditReportAggregationFocused({
              creditReportId: rowData.creditReport?.id,
              fieldPath: "equifax.date.focused"
            })
          });
          const specFieldTransUnionReportDate = getFocusedAggregationSpecs({
            spec: creditReportFieldSpecs?.transunion?.date,
            focused: getLocalCreditReportAggregationFocused({
              creditReportId: rowData.creditReport?.id,
              fieldPath: "transunion.date.focused"
            })
          });
          return {
            cells: [
              {
                index: 0,
                data: (
                  <Box>
                    <Center>
                      <TableInput
                        specFields={creditReportFieldSpecs?.pullType!}
                        prefix={["creditReports", rowData.creditReport?.id!]}
                        state={localCreditReportState}
                        currentEntity={
                          localCreditReportState?.creditReports[
                            rowData.creditReport?.id!
                          ]
                        }
                        onChange={onCreditReportChange}
                      />
                    </Center>
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 1,
                data: (
                  <Box>
                    <Center>
                      <TableInput
                        isAggregation={AggregationType.ThirdParty}
                        specFields={specFieldExperianScore}
                        prefix={["creditReports", rowData.creditReport?.id!]}
                        state={localCreditReportState}
                        currentEntity={
                          localCreditReportState?.creditReports[
                            rowData.creditReport?.id!
                          ]
                        }
                        onChange={onCreditReportChange}
                      />
                    </Center>
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 2,
                data: (
                  <Box>
                    <Center>
                      <TableInput
                        isAggregation={AggregationType.ThirdParty}
                        specFields={specFieldEquifaxScore}
                        prefix={["creditReports", rowData.creditReport?.id!]}
                        state={localCreditReportState}
                        currentEntity={
                          localCreditReportState?.creditReports[
                            rowData.creditReport?.id!
                          ]
                        }
                        onChange={onCreditReportChange}
                      />
                    </Center>
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 3,
                data: (
                  <Box>
                    <Center>
                      <TableInput
                        isAggregation={AggregationType.ThirdParty}
                        specFields={specFieldTransUnionScore}
                        prefix={["creditReports", rowData.creditReport?.id!]}
                        state={localCreditReportState}
                        currentEntity={
                          localCreditReportState?.creditReports[
                            rowData.creditReport?.id!
                          ]
                        }
                        onChange={onCreditReportChange}
                      />
                    </Center>{" "}
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 4,
                data: (
                  <Box>
                    <Center>
                      <TableInput
                        isAggregation={AggregationType.ThirdParty}
                        specFields={specFieldExperianReportDate}
                        prefix={["creditReports", rowData.creditReport?.id!]}
                        state={localCreditReportState}
                        currentEntity={
                          localCreditReportState?.creditReports[
                            rowData.creditReport?.id!
                          ]
                        }
                        onChange={onCreditReportChange}
                      />
                    </Center>{" "}
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 5,
                data: (
                  <Box>
                    <Center>
                      <TableInput
                        isAggregation={AggregationType.ThirdParty}
                        specFields={specFieldEquifaxReportDate}
                        prefix={["creditReports", rowData.creditReport?.id!]}
                        state={localCreditReportState}
                        currentEntity={
                          localCreditReportState?.creditReports[
                            rowData.creditReport?.id!
                          ]
                        }
                        onChange={onCreditReportChange}
                      />
                    </Center>{" "}
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 6,
                data: (
                  <Box>
                    <Center>
                      <TableInput
                        isAggregation={AggregationType.ThirdParty}
                        specFields={specFieldTransUnionReportDate}
                        prefix={["creditReports", rowData.creditReport?.id!]}
                        state={localCreditReportState}
                        currentEntity={
                          localCreditReportState?.creditReports[
                            rowData.creditReport?.id!
                          ]
                        }
                        onChange={onCreditReportChange}
                      />
                    </Center>{" "}
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 7,
                data: (
                  <Box>
                    <Center>
                      <TableInput
                        isAggregation={AggregationType.Aggregation}
                        specFields={specFieldExpirationDate}
                        prefix={["creditReports", rowData.creditReport?.id!]}
                        state={localCreditReportState}
                        currentEntity={
                          localCreditReportState?.creditReports[
                            rowData.creditReport?.id!
                          ]
                        }
                        onChange={onCreditReportChange}
                      />
                    </Center>
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 8,
                data: (
                  <HStack spacing="10px" justifyContent={"center"}>
                    <Tooltip label="Delete credit report">
                      <Center>
                        {rowData.creditReport && (
                          <DeleteCreditReportModal
                            callback={props?.getLatestScore || (() => {})}
                            selectedCreditReport={rowData.creditReport}
                          />
                        )}
                      </Center>
                    </Tooltip>
                  </HStack>
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              }
            ],
            index: rowData.creditReport?.index || `${i}`,
            minHeight: "53px",
            maxHeight: "53px"
          };
        }) || []
      );
    }, [partyState, pageDataMemo, creditReportState, localCreditReportState]);

  const creditReportHistoryTableHeader: ElphiTableProps["header"] =
    useMemo(() => {
      return [
        {
          index: 0,
          data: "Hard/Soft Pull",
          type: ElphiCellType.String,
          maxWidth: "350px",
          minWidth: "350px"
        },
        {
          index: 1,
          data: "Experian Score",
          type: ElphiCellType.String,
          maxWidth: "350px",
          minWidth: "350px"
        },
        {
          index: 2,
          data: "Equifax Score",
          type: ElphiCellType.String,
          maxWidth: "350px",
          minWidth: "350px"
        },
        {
          index: 3,
          data: "TransUnion Score",
          type: ElphiCellType.String,
          maxWidth: "350px",
          minWidth: "350px"
        },
        {
          index: 4,
          data: "Experian Report Date",
          type: ElphiCellType.String,
          maxWidth: "350px",
          minWidth: "350px"
        },
        {
          index: 5,
          data: "Equifax Report Date",
          type: ElphiCellType.String,
          maxWidth: "350px",
          minWidth: "350px"
        },
        {
          index: 6,
          data: "TransUnion Report Date",
          type: ElphiCellType.String,
          maxWidth: "350px",
          minWidth: "350px"
        },
        {
          index: 7,
          data: "Credit Report Expiration Date",
          type: ElphiCellType.String,
          maxWidth: "350px",
          minWidth: "350px"
        },
        {
          index: 8,
          data: "Actions",
          type: ElphiCellType.String,
          maxWidth: "200px",
          minWidth: "200px"
        }
      ];
    }, []);

  const creditReportHistoryTableProps: ElphiTableProps = useMemo(() => {
    return {
      header: creditReportHistoryTableHeader,
      rows: creditReportHistoryTableFullDataRows,
      footer: (
        <Box>
          {isLoading && <Progress size="xs" isIndeterminate />}

          <Flex w="100%" justifyContent={"flex-start"} mb="20px" mt="10px">
            <Box lineHeight={"40px"}>
              <Text fontSize={"16px"} fontWeight={"bold"}>
                Page: {pageIndex} /{" "}
                {Math.floor(pageData.length / PAGE_SIZE + 1)}
              </Text>
            </Box>
            <Box pl="20px">
              <Button
                w={"100px"}
                {...elphiTheme.components.light.button.primary}
                isDisabled={pageIndex <= 1}
                onClick={() => {
                  setPageIndex(pageIndex - 1);
                }}
              >
                Prev
              </Button>
            </Box>
            <Box pl="10px" mr={"auto"}>
              <Button
                w={"100px"}
                {...elphiTheme.components.light.button.primary}
                isDisabled={pageIndex * PAGE_SIZE >= pageData.length}
                onClick={() => {
                  setPageIndex(pageIndex + 1);
                }}
              >
                Next
              </Button>
            </Box>
          </Flex>
        </Box>
      ),
      isLoading: isLoading,
      rowsCount: 1
    };
  }, [
    creditReportHistoryTableFullDataRows,
    pageIndex,
    isLoading,
    selectedParty
  ]);

  return (
    <>
      {props.includeManualCreate && (
        <CreateCreditReportModal
          party={selectedParty as Partial<IndividualParty>}
        />
      )}
      <ElphiTable
        minHeight={"300px"}
        maxHeight={"825px"}
        header={creditReportHistoryTableProps.header}
        rows={creditReportHistoryTableProps.rows}
        footer={creditReportHistoryTableProps.footer}
        isLoading={creditReportHistoryTableProps.isLoading}
        rowsCount={1}
      />
    </>
  );
};
