import { Organization } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import lodash from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { orgEntityAdapter as entityAdapter } from "./adapter";
import { orgApi } from "./service";

export type OrganizationSliceFieldsState = {
  selectedId?: EntityId;
};
export type OrganizationSliceState = EntityState<Organization> &
  OrganizationSliceFieldsState;

const initialState: OrganizationSliceFieldsState = { selectedId: undefined };

export const orgSlice = createSlice({
  name: "org",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(orgApi)(builder);
    builder.addMatcher(
      orgApi.endpoints.userOrganizations.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      orgApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
  }
});
