import { AuditEventGroup } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { auditEventGroupSlice } from "./auditEventGroup.slice";

export const auditEventGroupAction: ActionDispatcher<AuditEventGroup> = (
  dispatch,
  callback
) => {
  const actionDispatcher = buildAction(auditEventGroupSlice);
  return actionDispatcher(dispatch, callback);
};
