import { SystemSetup } from "../../../common";
import { DealPartyRelationType } from "../../../entities";
import {
  elphiDealEntitySchema,
  elphiDealIndividualSchema
} from "../../base/dealParty.typebox";
import {
  limaDealEntitySchema,
  limaDealIndividualSchema
} from "../../lima/dealParty.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type DealPartyEnvToSchemaMap = {
  [DealPartyRelationType.DealEntity]: EnvToSchemasMap;
  [DealPartyRelationType.DealIndividual]: EnvToSchemasMap;
};

export const dealPartySchemas: DealPartyEnvToSchemaMap = {
  [DealPartyRelationType.DealEntity]: {
    [SystemSetup.Elphi]: elphiDealEntitySchema,
    [SystemSetup.Lima]: limaDealEntitySchema,
    [SystemSetup.Mofin]: elphiDealEntitySchema,
    [SystemSetup.LedgerTC]: elphiDealEntitySchema
  },
  [DealPartyRelationType.DealIndividual]: {
    [SystemSetup.Elphi]: elphiDealIndividualSchema,
    [SystemSetup.Lima]: limaDealIndividualSchema,
    [SystemSetup.Mofin]: elphiDealIndividualSchema,
    [SystemSetup.LedgerTC]: elphiDealIndividualSchema
  }
};
