export const addressLineTextStyles = {
  maxWidth: "280px",
  minWidth: "280px"
};
export const userAssignmentStyles = {
  maxWidth: "280px",
  minWidth: "280px"
};

export const unitIdentifierStyles = {
  maxWidth: "280px",
  minWidth: "280px"
};

export const actionStyles = {
  maxWidth: "160px",
  minWidth: "160px"
};

export const rowIndexStyles = {
  maxWidth: "280px",
  minWidth: "280px"
};
