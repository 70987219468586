import { BaseEntity } from "@elphi/types";
import { Dictionary } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { SearchComponentProps } from "../components/SearchComponent";
import { SearchApiV2 } from "../components/search/SearchHandler";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";

export const useSearchBarHooks = <T extends BaseEntity<object>>(props: {
  entities: Dictionary<T>;
  dataRank: FieldsWeight<T>;
}) => {
  const { entities, dataRank } = props;
  const customFilter: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    if (!searchText) {
      return true;
    }
    const ent = entities?.[option.data.value];
    if (!ent?.id) {
      return false;
    }
    return (
      getRankedData(searchText, ent, dataRank) > 0.5 ||
      searchText === ent.id.toLowerCase()
    );
  };

  const filterOption = useCallback(customFilter, [entities]);

  const rankedSort = (query: string) => {
    return Object.values(entities).sort((a, b) => {
      const rankA = getRankedData(query, a, dataRank);
      const rankB = getRankedData(query, b, dataRank);
      if (rankA < rankB) {
        return 1;
      }
      return -1;
    });
  };

  return {
    filterOption,
    rankedSort
  };
};

export const useSearchApiWithParamsHooks = () => {
  const searchApiWithParams = <T extends { params: object }>(r: {
    additionalRequestData: T;
    searchApi: SearchApiV2["searchApi"];
  }) => {
    const { additionalRequestData, searchApi } = r;
    return (r: { query: string; cursor?: string | null }, cache: boolean) => {
      return searchApi(
        {
          query: r.query,
          cursor: r.cursor,
          ...additionalRequestData
        },
        cache
      );
    };
  };

  return { searchApiWithParams };
};
