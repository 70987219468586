import { Flex } from "@chakra-ui/react";
import { ServiceProviderDealContainer } from "../rolodex";

export const ServiceProviderTab = (props: { dealId: string }) => {
  return (
    <Flex p={"10px"} direction={"column"}>
      <ServiceProviderDealContainer {...props} />
    </Flex>
  );
};
