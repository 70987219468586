import { EntityId, Slice } from "@reduxjs/toolkit";
import { ActionDispatcher, ActionResponseType } from "../types/action.types";

export const buildAction = <T extends { id: EntityId }, S>(
  slice: Slice<S>
): ActionDispatcher<T> => {
  return (dispatch, callback) => {
    return {
      add: async (r) => {
        const res = dispatch(slice.actions.upsertMany(r));
        callback && callback?.add?.(r);
        return {
          type: ActionResponseType.Add,
          payload:
            res.payload &&
            Object.values(res.payload).map((d?: { id: string }) => {
              return {
                id: d?.id
              };
            })
        };
      },
      update: async (r) => {
        const res = dispatch(slice.actions.updateMany(r));
        callback && callback?.update?.(r);
        return {
          type: ActionResponseType.Update,
          payload: res.payload.map((d) => {
            return { id: d.id };
          })
        };
      },
      remove: async (r) => {
        const res = dispatch(slice.actions.removeMany(r));
        callback && callback?.remove?.(r);
        return {
          type: ActionResponseType.Remove,
          payload: res.payload
        };
      }
    };
  };
};
