import { BaseAddress, StateCode, StateName } from "@elphi/types";
import { createOptionsFromMap } from "../../../utils/formUtils";
import { FieldType } from "../../fieldFormat.types";
import { EntityFormFieldSpecs } from "../fields.types";

export const nestedAddressFieldSpecs: EntityFormFieldSpecs<{
  Address: BaseAddress;
}> = {
  Address: {
    AddressLineText: {
      fieldKey: ["Address", "AddressLineText"],
      fieldType: FieldType.String,
      label: "Address Line 1"
    },
    AddressUnitIdentifier: {
      fieldKey: ["Address", "AddressUnitIdentifier"],
      fieldType: FieldType.String,
      label: "Address Line 2"
    },
    CityName: {
      fieldKey: ["Address", "CityName"],
      fieldType: FieldType.String,
      label: "City Name"
    },
    CountyName: {
      fieldKey: ["Address", "CountyName"],
      fieldType: FieldType.String,
      label: "County"
    },
    PostalCode: {
      fieldKey: ["Address", "PostalCode"],
      fieldType: FieldType.String,
      label: "Zip Code"
    },
    StateCode: {
      fieldKey: ["Address", "StateCode"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(StateCode),
      label: "State Code"
    },
    StateName: {
      fieldKey: ["Address", "StateName"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(StateName),
      label: "State Name"
    }
  }
};
export const addressFieldSpecs: EntityFormFieldSpecs<BaseAddress> = {
  AddressLineText: {
    fieldKey: ["AddressLineText"],
    fieldType: FieldType.String,
    label: "Address Line 1"
  },
  AddressUnitIdentifier: {
    fieldKey: ["AddressUnitIdentifier"],
    fieldType: FieldType.String,
    label: "Unit Identifier"
  },
  CityName: {
    fieldKey: ["CityName"],
    fieldType: FieldType.String,
    label: "City Name"
  },
  CountyName: {
    fieldKey: ["CountyName"],
    fieldType: FieldType.String,
    label: "County Name"
  },
  PostalCode: {
    fieldKey: ["PostalCode"],
    fieldType: FieldType.String,
    label: "Zip Code"
  },
  StateCode: {
    fieldKey: ["StateCode"],
    fieldType: FieldType.SingleSelect,
    options: createOptionsFromMap(StateCode),
    label: "State Code"
  },
  StateName: {
    fieldKey: ["StateName"],
    fieldType: FieldType.SingleSelect,
    options: createOptionsFromMap(StateName),
    label: "State Name"
  }
};
