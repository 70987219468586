import { FieldSelectionInteractiveFormContainer } from "../field-selection/FieldSelectionInteractiveForm";

const DataToVerifyInteractiveForm = (props: { snapshotId?: string }) => {
  return (
    <FieldSelectionInteractiveFormContainer
      snapshotId={props.snapshotId}
      labelPrefix={"Verify"}
      fieldSelection="dataToVerify"
    />
  );
};

export default DataToVerifyInteractiveForm;
