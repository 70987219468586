import { createIcon } from "@chakra-ui/react";

const customBuildingWithHouseIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 40 40",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#EBF8FF" />
        <path
          d="M26.9998 10H16.9998C15.8968 10 14.9998 10.897 14.9998 12V17.586L10.2928 22.293C10.153 22.4329 10.0578 22.611 10.0192 22.805C9.98064 22.9989 10.0004 23.2 10.0761 23.3827C10.1518 23.5654 10.2799 23.7215 10.4443 23.8314C10.6087 23.9413 10.802 24 10.9998 24V29C10.9998 29.2652 11.1051 29.5196 11.2927 29.7071C11.4802 29.8946 11.7346 30 11.9998 30H27.9998C28.265 30 28.5194 29.8946 28.7069 29.7071C28.8944 29.5196 28.9998 29.2652 28.9998 29V12C28.9998 10.897 28.1028 10 26.9998 10ZM18.9998 28H12.9998V22.414L15.9998 19.414L18.9998 22.414V28ZM26.9998 28H20.9998V24C21.1978 24.0004 21.3914 23.942 21.5561 23.8322C21.7208 23.7224 21.8492 23.5662 21.925 23.3833C22.0007 23.2004 22.0204 22.9991 21.9816 22.805C21.9427 22.6109 21.8471 22.4327 21.7068 22.293L16.9998 17.586V12H26.9998V28Z"
          fill="#3182CE"
        />
        <path
          d="M19 14H21V16H19V14ZM23 14H25V16H23V14ZM23 18.031H25V20H23V18.031ZM23 22H25V24H23V22ZM15 23H17V25H15V23Z"
          fill="#3182CE"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const BuildingWithHouseIcon = customBuildingWithHouseIcon();
