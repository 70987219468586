import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { InvitePosUserModal } from "./modal/InviteUserModal";
import { PosUserTable } from "./table/PosUserTable";

export const PosUserTabContainer = (props: {
  dealId: string;
  loanIdentifier: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Flex px={2} py={4}>
        <Box px={2}>
          <Button
            aria-label="add-user"
            onClick={onOpen}
            leftIcon={<AddIcon />}
            {...elphiTheme.components.light.button.primary}
          >
            Add a User
          </Button>
        </Box>
      </Flex>
      <Flex px={2} direction={"column"}>
        <PosUserTable {...props} />;
      </Flex>
      <InvitePosUserModal show={isOpen} onClose={onClose} {...props} />
    </Box>
  );
};
