import firebase from "firebase/compat/app";
import { isEmpty } from "lodash";
import { DependencyList, useEffect } from "react";
import { auth } from "../firebase/firebaseConfig";

type AuthStateChangeHook = (r: {
  onAuthStateChange: (a: firebase.User | null) => any;
  deps?: DependencyList;
}) => void;

export const useAuthStateChangeHook: AuthStateChangeHook = ({
  deps,
  onAuthStateChange
}) => {
  useEffect(() => {
    if (!auth?.currentUser) {
      const unsubscribe = auth.onAuthStateChanged(onAuthStateChange);
      return () => {
        unsubscribe();
      };
    } else if (!isEmpty(deps)) {
      onAuthStateChange(auth?.currentUser);
    }
  }, deps);
};
