import { Box } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { usePartyHooks } from "../../../hooks/party.hooks";
import { RootState } from "../../../redux/store";
import { dealApi } from "../../../redux/v2/deal/deal.service";

import Tree from "../../tree/Tree";
import { loadingSkeleton } from "../../tree/TreeUtils";

import { auth } from "../../../firebase/firebaseConfig";
import useTree from "../../tree/tree.hooks";

import { Deal, DealPartyRelation, Party, PartyRelation } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import {
  SnapshotDataStateFormat,
  useSnapshotHooks
} from "../../../hooks/snapshot.hooks";
import {
  ExpandSectionNames,
  ExpandSections
} from "../../application/sections/expandSections";
import { navigateAction } from "../../party/PartyTreeActions";
import { ElphiNode, NodeAction } from "../../tree/types/Tree.types";
import AddDealPartyButton from "./AddDealPartyButton";
import DeleteDealPartyButton from "./DeleteDealPartyButton";
import { generateTree } from "./deal-party-chart-utils/dealPartyChart.utils";
export const DealPartyChartContainer = (props: {
  dealId: string;
  snapshotId?: string;
  expandSections: ExpandSections;
  setExpandSections: React.Dispatch<React.SetStateAction<ExpandSections>>;
}) => {
  return props.snapshotId ? (
    <DealPartyChartSnapshotContainer
      {...props}
      snapshotId={props.snapshotId!}
    />
  ) : (
    <DealPartyChartLiveStateContainer {...props} />
  );
};
export const DealPartyChartSnapshotContainer = (props: {
  dealId: string;
  snapshotId: string;
}) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });
  const {
    partyTab,
    setHighlightedPartyTabParty,
    setHighlightedPartyTabPartyParent,
    setSelectedTreeNodeKey,
    setSelectedParty
  } = usePartyHooks();
  const highlightedParty = useMemo(
    () =>
      partyTab &&
      partyTab?.highlightedPartyId &&
      snapshot.partyState?.entities?.[partyTab.highlightedPartyId],
    [props.snapshotId, partyTab?.highlightedPartyId]
  );
  const chartMemo = useMemo(() => {
    if (
      !snapshot.dealPartyRelationState ||
      !snapshot.dealState ||
      !snapshot.partyState ||
      !snapshot.partyRelationState
    ) {
      return <></>;
    }
    return (
      <DealPartyChart
        {...props}
        setSelectedParty={setSelectedParty}
        dealPartyRelationState={snapshot.dealPartyRelationState}
        dealState={snapshot.dealState}
        partyState={snapshot.partyState}
        getTreeApi={(_: any) => {
          return null as any;
        }}
        isLoading={false}
        partyRelationState={snapshot.partyRelationState}
        partyTabHighlightedParty={highlightedParty || undefined}
        setHighlightedPartyTabParty={setHighlightedPartyTabParty}
        setHighlightedPartyTabPartyParent={setHighlightedPartyTabPartyParent}
        setSelectedTreeNodeKey={setSelectedTreeNodeKey}
        dealNodeActions={[]}
        depth1PartyNodeActions={[]}
      />
    );
  }, [props.dealId, props.snapshotId]);
  return chartMemo;
};
export const DealPartyChartLiveStateContainer = (props: {
  dealId: string;
  expandSections: ExpandSections;
  setExpandSections: React.Dispatch<React.SetStateAction<ExpandSections>>;
}) => {
  const {
    getTreeApi,
    getTreeApiResponse,
    setHighlightedPartyTabPartyParent,
    setHighlightedPartyTabParty,
    partyTabHighlightedParty,
    setSelectedTreeNodeKey,
    partyState,
    setSelectedParty
  } = usePartyHooks();
  const partyRelationState = useSelector(
    (state: RootState) => state.partyRelation
  );
  const dealPartyRelationState = useSelector(
    (state: RootState) => state.dealPartyRelation
  );
  const dealState = useSelector((state: RootState) => state.deal);
  const [getDealParties, dealPartiesResponse] =
    dealApi.useLazyDealPartiesQuery();
  useEffect(() => {
    if (auth.currentUser && props.dealId) {
      getDealParties([props.dealId], true);
    }
  }, [auth.currentUser, props.dealId]);
  return (
    <DealPartyChart
      dealId={props.dealId}
      dealState={dealState}
      getTreeApi={getTreeApi}
      setSelectedParty={setSelectedParty}
      setHighlightedPartyTabPartyParent={setHighlightedPartyTabPartyParent}
      setHighlightedPartyTabParty={setHighlightedPartyTabParty}
      partyTabHighlightedParty={partyTabHighlightedParty}
      setSelectedTreeNodeKey={setSelectedTreeNodeKey}
      partyState={partyState}
      dealPartyRelationState={dealPartyRelationState}
      partyRelationState={partyRelationState}
      isLoading={dealPartiesResponse.isLoading || getTreeApiResponse.isLoading}
      expandSections={props.expandSections}
      setExpandSections={props.setExpandSections}
      // depth1PartyNodeActions={[navigateAction]}
    />
  );
};
const DealPartyChart = (props: {
  dealId: string;
  dealState: EntityState<Deal>; //Omit<DealSliceState, "dealTable">;
  getTreeApi: ReturnType<typeof usePartyHooks>["getTreeApi"];
  setHighlightedPartyTabPartyParent: ReturnType<
    typeof usePartyHooks
  >["setHighlightedPartyTabPartyParent"];
  setHighlightedPartyTabParty: ReturnType<
    typeof usePartyHooks
  >["setHighlightedPartyTabParty"];
  partyTabHighlightedParty: ReturnType<
    typeof usePartyHooks
  >["partyTabHighlightedParty"];
  setSelectedTreeNodeKey: ReturnType<
    typeof usePartyHooks
  >["setSelectedTreeNodeKey"];
  setSelectedParty: ReturnType<typeof usePartyHooks>["setSelectedParty"];
  partyState:
    | ReturnType<typeof usePartyHooks>["partyState"]
    | SnapshotDataStateFormat<Party>;
  dealPartyRelationState: EntityState<DealPartyRelation>;
  partyRelationState: EntityState<PartyRelation>;
  isLoading: boolean;
  dealNodeActions?: NodeAction<Deal | Party>[];
  depth1PartyNodeActions?: NodeAction<Deal | Party>[];
  expandSections?: ExpandSections;
  setExpandSections?: React.Dispatch<React.SetStateAction<ExpandSections>>;
}) => {
  const {
    getTreeApi,
    setHighlightedPartyTabPartyParent,
    setHighlightedPartyTabParty,
    setSelectedTreeNodeKey,
    partyState,
    partyRelationState,
    dealPartyRelationState,
    dealState,
    isLoading,
    setSelectedParty,
    expandSections,
    setExpandSections
  } = props;
  const { partyTabHighlightedParty } = usePartyHooks();
  const resetSelection = () => {
    elphiTreeOperations.setSelectedNode(null);
  };
  const [rootNode, setRootNode] = useState<ElphiNode<Deal | Party> | null>(
    null
  );
  const [dealNodeActions, setDealNodeActions] = useState<
    NodeAction<Deal | Party>[] | null
  >(props.dealNodeActions || null);
  const [depth1PartyNodeActions, setDepth1PartyNodeActions] = useState<
    NodeAction<Deal | Party>[] | null
  >(props.depth1PartyNodeActions || null);

  // const emptyNodeActions = [];

  const initialState = useMemo(() => {
    if (
      props.dealId &&
      dealState &&
      dealPartyRelationState &&
      partyState &&
      partyRelationState &&
      dealNodeActions &&
      depth1PartyNodeActions
    ) {
      return {
        initialState: generateTree?.({
          dealId: props.dealId,
          dealState,
          dealPartyRelationState,
          partyState,
          partyRelationState,
          dealNodeActions,
          depth1PartyNodeActions,
          setSelectedParty
        })
      };
    }

    return {
      initialState: {
        id: "$",
        children: [],
        data: null,
        label: "",
        sublabel: "",
        nodeKey: "$"
      }
    };
  }, [
    rootNode,
    dealNodeActions,
    depth1PartyNodeActions,
    dealPartyRelationState,
    partyState.entities
  ]);

  const { treeState, elphiTreeOperations, setTreeState } = useTree<
    Deal | Party
  >(initialState);

  useEffect(() => {
    if (depth1PartyNodeActions === null && elphiTreeOperations && treeState) {
      setDepth1PartyNodeActions([
        DeleteDealPartyButton({
          dealId: props.dealId,
          callback: resetSelection
        })
      ]);
    }
    if (dealNodeActions === null && elphiTreeOperations && treeState) {
      setDealNodeActions([
        AddDealPartyButton({
          dealId: props.dealId
        })
      ]);
    }
  }, [treeState, elphiTreeOperations]);

  useEffect(() => {
    if (dealNodeActions && depth1PartyNodeActions) {
      const newState = generateTree?.({
        dealId: props.dealId,
        dealState,
        dealPartyRelationState,
        partyState,
        partyRelationState,
        dealNodeActions,
        depth1PartyNodeActions,
        setSelectedParty
      });
      newState && setTreeState({ ...newState });
    }
  }, [
    dealNodeActions,
    depth1PartyNodeActions,
    partyRelationState,
    elphiTreeOperations.selectedNodeKey,
    rootNode,
    dealState,
    partyState,
    dealPartyRelationState
  ]);

  useEffect(() => {
    if (!rootNode && initialState.initialState) {
      setRootNode(initialState.initialState);
    }
  }, [rootNode, initialState.initialState]);

  useEffect(() => {
    if (initialState.initialState.data) {
      const asyncEffect = async (ids: string[]) => {
        await getTreeApi(ids, true);
        setRootNode(initialState.initialState);
      };
      const childrenIds = initialState.initialState.children.map((c) => c.id);
      if (childrenIds.length) {
        asyncEffect(childrenIds);
      }
    }
  }, [dealPartyRelationState, initialState.initialState.data]);

  const handleNodeSelect = async (node: ElphiNode<Party | Deal>) => {
    // @Willis - this is a very disturing hack, using setHighlightedPartyTabParty to identify if focused on a deal,
    //           what should be done is adding a focus state to redux-tab accepting values as "deal" | "party"
    if (node.label === "Deal") setHighlightedPartyTabParty("Deal");
    else setHighlightedPartyTabParty(node.id);
    setSelectedTreeNodeKey(node.nodeKey);
    if (node.nodeKey) {
      const parent = elphiTreeOperations.findParentNode(
        treeState,
        node.nodeKey
      );
      parent && parent.id
        ? setHighlightedPartyTabPartyParent(parent.id)
        : setHighlightedPartyTabPartyParent("");
    }
  };

  useEffect(() => {
    if (!!partyTabHighlightedParty) {
      const node = elphiTreeOperations.findNode(
        treeState,
        partyTabHighlightedParty.id
      );
      if (!!node) {
        elphiTreeOperations.setSelectedNode(node);
        handleNodeSelect(node);
        !!setExpandSections &&
          !!expandSections && {
            ...expandSections,
            DealPartyRelationship: {
              name: ExpandSectionNames.DealPartyRelationship,
              isExpanded: true
            }
          };
      }
    }
  }, [treeState]);

  const loadingSkeletonMemo = useMemo(() => loadingSkeleton, []);
  return (
    <Box>
      {isLoading
        ? loadingSkeletonMemo
        : rootNode && (
            <Tree
              handleNodeSelect={(selectedNode) => {
                setHighlightedPartyTabParty("");
                handleNodeSelect(selectedNode);
              }}
              treeState={treeState}
              elphiTreeOperations={elphiTreeOperations}
              nodeActions={[
                navigateAction({
                  callback: (node) => {
                    setSelectedParty(node?.data?.id || "");
                  }
                })
              ]} //not doing anything
            />
          )}
    </Box>
  );
};

export default DealPartyChart;
