import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip
} from "@chakra-ui/react";
import {
  AuditEvent,
  AuditEventLink,
  ElphiEntityType,
  PartyType,
  ServiceProviderType
} from "@elphi/types";
import { useState } from "react";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { printDateTime } from "../../../../firebase/firebase.utils";
import { useAuditEventBaseHooks } from "../../../../hooks/allEvents.hooks";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import {
  CircleBookIcon,
  HandHouseBlue100Icon,
  HouseOrange100Icon,
  HumanCyan100Icon,
  HumanPurple100Icon
} from "../../../icons";
import { newTab } from "../../../utils/navigationUtils";
import { EventEntityPath, EventEntityValue } from "./EventRowEntity";
import { EventRowIcon } from "./EventRowIcon";
import { buildUrl } from "./eventRowLinkBuilder.utils";

export const EventRowBuilder = (props: { event: AuditEvent }) => {
  const { event } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <Flex
      alignItems="center"
      borderBottomColor={"gray.100"}
      borderBottomWidth={1}
      py={4}
      px={2}
      _hover={{ bg: "blue.50" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isHovered ? <EventRowIcon event={event} /> : <GotoMenu event={event} />}
      <Flex direction="column" textAlign="left" width={"65%"}>
        <Box fontSize="14px" fontWeight={700}>
          <EventEntityPath event={event} />
        </Box>
        <Flex alignItems="center" fontSize="14px" fontWeight="400">
          <EventEntityValue event={event} />
        </Flex>
      </Flex>
      <Flex direction="column" textAlign="right" ml={4} width={"35%"}>
        <Text fontSize="14px" fontWeight={700}>
          {event.userEmail || event.createdUID || NOT_AVAILABLE}
        </Text>
        <Text fontSize="14px" fontWeight={700} color="gray.500">
          {printDateTime(event.createdAt) || NOT_AVAILABLE}
        </Text>
      </Flex>
    </Flex>
  );
};

const GotoMenu = (props: { event: AuditEvent }) => {
  const { event } = props;
  const { auditEventState, handleGetLinks, getLinksResponse } =
    useAuditEventBaseHooks();
  const handleGotoLinksClick = (id: string) => {
    handleGetLinks(id);
  };
  const links = auditEventState?.links?.[event.id];
  return (
    <Menu placement={"bottom-start"}>
      <>
        <Tooltip label={"Go to..."} hasArrow placement={"left"}>
          <MenuButton
            as={IconButton}
            aria-label={"Go to..."}
            variant={"unstyled"}
            minW={"unset"}
            minH={"unset"}
            h={"unset"}
            mr={"8px"}
            isLoading={getLinksResponse.isLoading}
            icon={<ExternalLinkIcon w={6} h={6} color={"blue.600"} />}
            onClick={() => {
              handleGotoLinksClick(event.id);
            }}
          />
        </Tooltip>
        {links?.length && !getLinksResponse.isLoading && (
          <MenuList maxH={"300px"} w={"300px"} overflow={"auto"}>
            {links.map((link, i) => {
              return <GotoLinkItem key={i} {...link} />;
            })}
          </MenuList>
        )}
      </>
    </Menu>
  );
};

const GotoLinkItem = (props: AuditEventLink) => {
  const { selectedOrgId } = useOrgHooks();
  const onClickTab = () => {
    const url = buildUrl({
      ...props,
      selectedOrgId: selectedOrgId?.toString() || EMPTY
    });
    url && newTab(url);
  };
  return (
    <MenuItem onClick={onClickTab} _focus={{ bg: "blue.50" }}>
      <Tooltip label={"Open in a new tab"} hasArrow placement={"left"}>
        <Flex>
          <Box mr={2}>
            <EntityLinkIcon {...props} />
          </Box>
          <Box>
            <EntityLinkIdentifier
              {...props}
              entityIdentifier={props.entityIdentifier || NOT_AVAILABLE}
            />
          </Box>
        </Flex>
      </Tooltip>
    </MenuItem>
  );
};

const EntityLinkIdentifier = (props: AuditEventLink) => {
  const { entityType, entityIdentifier } = props;
  if (entityType === ElphiEntityType.deal) {
    return <>{`Deal ${entityIdentifier}`}</>;
  }
  if (entityType === ElphiEntityType.party) {
    return props.innerType === PartyType.Entity ? (
      <>{`E | ${entityIdentifier}`}</>
    ) : (
      <>{`I | ${entityIdentifier}`}</>
    );
  }
  if (entityType === ElphiEntityType.serviceProvider) {
    if (props.innerType === ServiceProviderType.Company) {
      return <>{`C | ${entityIdentifier}`}</>;
    }
    if (props.innerType === ServiceProviderType.Branch) {
      return <>{`B | ${entityIdentifier}`}</>;
    }
    if (props.innerType === ServiceProviderType.Representative) {
      return <>{`R | ${entityIdentifier}`}</>;
    }
  }
  if (entityType === ElphiEntityType.property) {
    return <>{entityIdentifier}</>;
  }
  return <>{NOT_AVAILABLE}</>;
};

const EntityLinkIcon = (props: AuditEventLink) => {
  const { entityType } = props;
  if (entityType === ElphiEntityType.deal) {
    return <HandHouseBlue100Icon w={6} h={6} />;
  }
  if (entityType === ElphiEntityType.party) {
    return props.innerType === PartyType.Entity ? (
      <HumanPurple100Icon w={6} h={6} />
    ) : (
      <HumanCyan100Icon w={6} h={6} />
    );
  }
  if (entityType === ElphiEntityType.serviceProvider) {
    return <CircleBookIcon w={6} h={6} />;
  }
  if (entityType === ElphiEntityType.property) {
    return <HouseOrange100Icon w={6} h={6} />;
  }
  return <>{NOT_AVAILABLE}</>;
};
