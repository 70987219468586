import { BaseEntity } from "../db/baseEntity.types";

export enum PartyRelationType {
  EntityToEntity = "Entity-To-Entity",
  EntityToIndividual = "Entity-To-Individual"
}

export enum EntityToEntityRelationRoleType {
  Member = "Member",
  Shareholder = "Shareholder",
  GeneralPartner = "General Partner",
  Manager = "Manager"
}

export enum EntityToIndividualRelationRoleType {
  Member = "Member",
  Secretary = "Secretary",
  President = "President",
  ManagingMember = "Managing Member",
  SoleMember = "Sole Member",
  Principal = "Principal",
  CEO = "CEO",
  Director = "Director",
  CFO = "CFO",
  Executor = "Executor",
  GeneralPartner = "General Partner",
  LimitedPartner = "Limited Partner",
  Manager = "Manager",
  NonOwnerManagingMember = "Non-Owner Managing Member",
  Owner = "Owner",
  Partner = "Partner",
  SpecialLimitedPartner = "Special Limited Partner",
  Treasurer = "Treasurer",
  Trustee = "Trustee",
  VicePresident = "Vice President",
  AuthorizedSignatory = "Authorized Signatory",
  LegalCounsel = "Legal Counsel"
}

export type BasePartyRelation<TType extends PartyRelationType> = BaseEntity<{
  type: TType;
  parentId: string;
  childId: string;
  ownershipPercentage?: string;
}>;
export type EntityToEntityRelation =
  BasePartyRelation<PartyRelationType.EntityToEntity> & {
    relationRoleType: EntityToEntityRelationRoleType[];
  };
export type EntityToIndividualRelation =
  BasePartyRelation<PartyRelationType.EntityToIndividual> & {
    relationRoleType: EntityToIndividualRelationRoleType[];
  };
export type PartyRelation = EntityToEntityRelation | EntityToIndividualRelation;
