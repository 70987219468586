import {
    BaseInsurancePolicy,
    InsurancePolicyEntityType,
    OutstandingPremiumStatus
} from "../../insurance-policy";

export const getBaseInsurancePolicy =
  (): BaseInsurancePolicy<InsurancePolicyEntityType> => {
    return {
      id: "",
      index: "",
      modifiedAt: "",
      createdAt: "",
      entityIds: [],
      dealIds: [],
      entityType: "" as InsurancePolicyEntityType,
      expirationDate: "",
      insuranceCarrier: "",
      insurancePremiumPaymentAmount: "",
      nextPaymentDueDate: "",
      outstandingPremiumAmount: "",
      outstandingPremiumStatus: "" as OutstandingPremiumStatus,
      policyDeductibleAmount: "",
      policyNumber: "",
      policyPremiumAmount: "",
      policyProviderInfo: { id: "", name: "" },
      coverageTypes: [],
      entities: {},
      effectiveDate: "",
      branchRepId: "",
      deals: {},
      domainConfigurationId: ""
    };
  };
