import { createIcon } from "@chakra-ui/react";

const customBookIcon = (r: { fill?: string; stroke: string }) =>
  createIcon({
    viewBox: "0 0 29 29",
    path: (
      <svg fill={r.fill} xmlns="http://www.w3.org/2000/svg" stroke={r.stroke}>
        <path
          d="M11.06 4.04689C9.08414 3.10353 6.32242 2.64142 2.625 2.62502C2.2763 2.62028 1.93444 2.72196 1.645 2.9165C1.40743 3.07708 1.21296 3.29358 1.0787 3.54695C0.94443 3.80032 0.874481 4.0828 0.875003 4.36955V20.0156C0.875003 21.0733 1.6275 21.8712 2.625 21.8712C6.51164 21.8712 10.4103 22.2343 12.7455 24.4415C12.7774 24.4718 12.8176 24.4921 12.8609 24.4998C12.9043 24.5075 12.949 24.5023 12.9894 24.4848C13.0299 24.4673 13.0643 24.4384 13.0884 24.4015C13.1125 24.3646 13.1252 24.3215 13.125 24.2774V5.84174C13.1251 5.71735 13.0985 5.5944 13.047 5.48118C12.9955 5.36797 12.9202 5.26713 12.8264 5.18549C12.2915 4.72821 11.6974 4.34522 11.06 4.04689V4.04689ZM26.355 2.91486C26.0654 2.7208 25.7235 2.61969 25.375 2.62502C21.6776 2.64142 18.9159 3.10135 16.94 4.04689C16.3027 4.34468 15.7084 4.72691 15.1731 5.1833C15.0794 5.26508 15.0044 5.36596 14.953 5.47915C14.9016 5.59235 14.875 5.71523 14.875 5.83955V24.2763C14.875 24.3187 14.8875 24.3601 14.9109 24.3953C14.9343 24.4306 14.9676 24.4582 15.0066 24.4746C15.0456 24.491 15.0886 24.4955 15.1302 24.4876C15.1718 24.4797 15.2101 24.4596 15.2403 24.43C16.6441 23.0355 19.1078 21.8695 25.3772 21.8701C25.8413 21.8701 26.2864 21.6857 26.6146 21.3575C26.9428 21.0293 27.1272 20.5842 27.1272 20.1201V4.3701C27.1278 4.08278 27.0577 3.79973 26.923 3.54593C26.7884 3.29213 26.5933 3.0754 26.355 2.91486V2.91486Z"
          strokeWidth="1.5"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const BookIcon = customBookIcon({ stroke: "#A2C1FF" });

export const BlackBookIcon = customBookIcon({ stroke: "#000000" });

export const CircleBookIcon = createIcon({
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#EDF2F7" />
      <path
        d="M10.425 6.66808C9.36651 6.1627 7.88701 5.91515 5.90625 5.90636C5.71945 5.90382 5.5363 5.95829 5.38125 6.06251C5.25398 6.14853 5.1498 6.26451 5.07787 6.40025C5.00594 6.53598 4.96847 6.68731 4.96875 6.84093V15.2228C4.96875 15.7894 5.37188 16.2168 5.90625 16.2168C7.98838 16.2168 10.077 16.4113 11.3279 17.5938C11.345 17.61 11.3666 17.6209 11.3898 17.625C11.413 17.6291 11.437 17.6263 11.4586 17.617C11.4803 17.6076 11.4987 17.5921 11.5116 17.5723C11.5245 17.5526 11.5314 17.5295 11.5313 17.5059V7.6296C11.5313 7.56297 11.517 7.4971 11.4895 7.43645C11.4619 7.37579 11.4216 7.32177 11.3713 7.27804C11.0847 7.03307 10.7664 6.82789 10.425 6.66808V6.66808ZM18.6188 6.06163C18.4636 5.95767 18.2805 5.9035 18.0938 5.90636C16.113 5.91515 14.6335 6.16153 13.575 6.66808C13.2336 6.8276 12.9152 7.03237 12.6284 7.27686C12.5783 7.32068 12.5381 7.37472 12.5105 7.43536C12.483 7.496 12.4687 7.56183 12.4688 7.62843V17.5053C12.4687 17.528 12.4754 17.5501 12.488 17.569C12.5005 17.5879 12.5183 17.6027 12.5392 17.6115C12.5601 17.6203 12.5832 17.6227 12.6055 17.6185C12.6277 17.6142 12.6483 17.6035 12.6645 17.5876C13.4165 16.8405 14.7363 16.2159 18.0949 16.2162C18.3436 16.2162 18.582 16.1174 18.7578 15.9416C18.9337 15.7658 19.0324 15.5274 19.0324 15.2787V6.84122C19.0328 6.6873 18.9952 6.53567 18.9231 6.3997C18.8509 6.26374 18.7464 6.14763 18.6188 6.06163V6.06163Z"
        stroke="#2D3748"
      />
    </svg>
  )
});
