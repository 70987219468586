import { createIcon } from "@chakra-ui/icons";

export const CircleIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="4.5" fill={fill} stroke="#3182CE" />
      </svg>
    )
  });

export const BorderCircleIcon = CircleIcon();
export const FullCircleIcon = CircleIcon("#3182CE");
