import { BaseEntity } from "../db";
import { DealMilestoneType } from "./deal.enums.types";
import { DiscussionEntityType } from "./discussion.types";
import { ElphiEntityType } from "./elphiEntities.types";
import { ServiceProviderEntityType, ServiceProviderType } from "./rolodex";
import { ElphiTrxStatus, TaskStatusType, TaskType } from "./task-types";

export type AuditEvent = BaseEntity<AuditEventFields>;

export type AuditEventFields =
  | AuditDealEventFields
  | AuditPartyEventFields
  | AuditPropertyEventFields
  | AuditTaskEventFields
  | AuditDealPartyEventFields
  | AuditPartyAssetEventFields
  | AuditDealPropertyEventFields
  | AuditAssetEventFields
  | AuditStatementEventFields
  | AuditDealUserEventFields
  | AuditPartyUserEventFields
  | AuditPartyRelationEventFields
  | AuditDiscussionEntityEventFields
  | AuditServiceProvidersEventFields;

export type AuditDealEventFields =
  BaseAuditEventFields<AuditEventEntityType.Deal> & {
    dealMilestone?: DealMilestoneType;
    assignedUsers?: string[];
  };
export type AuditPartyEventFields =
  BaseAuditEventFields<AuditEventEntityType.Party> & {
    assignedUsers?: string[];
  };
export type AuditPropertyEventFields =
  BaseAuditEventFields<AuditEventEntityType.Property> & {
    dealMilestone?: DealMilestoneType;
    assignedUsers?: string[];
  };
export type AuditTaskEventFields =
  BaseAuditEventFields<AuditEventEntityType.Task> & {
    configurationId: string;
    taskType: TaskType;
    taskStatusType?: TaskStatusType;
    taskEntityType: ElphiEntityType;
    integrationStatus?: ElphiTrxStatus;
    partyIds?: string[];
    dealMilestone?: DealMilestoneType;
    assignedUsers?: string[];
  };
export type AuditDealPartyEventFields =
  BaseAuditEventFields<AuditEventEntityType.DealParty> & {
    dealMilestone?: DealMilestoneType;
    assignedUsers?: string[];
  };
export type AuditPartyAssetEventFields =
  BaseAuditEventFields<AuditEventEntityType.PartyAsset> & {
    assignedUsers?: string[];
    partyIds?: string[];
  };
export type AuditDealPropertyEventFields =
  BaseAuditEventFields<AuditEventEntityType.DealProperty> & {
    dealMilestone?: DealMilestoneType;
    assignedUsers?: string[];
  };
export type AuditAssetEventFields =
  BaseAuditEventFields<AuditEventEntityType.Asset> & {
    assignedUsers?: string[];
    partyIds?: string[];
  };
export type AuditStatementEventFields =
  BaseAuditEventFields<AuditEventEntityType.Statement> & {
    assignedUsers?: string[];
    partyIds?: string[];
  };
export type AuditDealUserEventFields =
  BaseAuditEventFields<AuditEventEntityType.DealUser> & {
    assignedUsers?: string[];
    dealMilestone?: DealMilestoneType;
  };
export type AuditPartyUserEventFields =
  BaseAuditEventFields<AuditEventEntityType.PartyUser>;
export type AuditPartyRelationEventFields =
  BaseAuditEventFields<AuditEventEntityType.PartyRelation> & {
    assignedUsers?: string[];
  };
export type AuditDiscussionEntityEventFields =
  BaseAuditEventFields<AuditEventEntityType.DiscussionEntity> & {
    discussionEntityId: string;
    discussionEntityType: DiscussionEntityType;
    watcherUIDs?: string[];
    mentionUIDs?: string[];
    commentOwnerUID?: string;
  };

export type AuditServiceProvidersEventFields =
  BaseAuditEventFields<AuditEventEntityType.ServiceProvider> & {
    assignedUsers?: { [dealId: string]: string[] };
    serviceProviderType: ServiceProviderType;
    serviceProviderEntityType?: ServiceProviderEntityType;
    dealMilestone?: { [dealId: string]: DealMilestoneType };
  };

export type BaseAuditEventFields<TEntityType extends AuditEventEntityType> = {
  dealIds?: string[];
  entityId: string;
  entityType: TEntityType;
  entityPrettyIdentifier?: string[];
  fieldPath?: string;
  fieldType?: AuditEventFieldType;
  beforeValue?: string;
  afterValue?: string;
  operation: AuditEventOperation;
  userEmail?: string;
};

export enum AuditEventOperation {
  Created = "created",
  Updated = "updated",
  Deleted = "deleted"
}

export enum AuditEventFieldType {
  Value = "value",
  Focus = "focus",
  FieldStatus = "fieldStatus",
  IntegrationStatus = "integrationStatus",
  UploadedFile = "uploaded-file",
  DeletedFile = "deleted-file",
  Message = "message",
  Reaction = "reaction"
}

export enum AuditEventEntityType {
  Deal = "deal",
  Party = "party",
  Property = "property",
  Task = "task",
  DealParty = "deal-party",
  PartyAsset = "party-asset",
  DealProperty = "deal-property",
  Asset = "asset",
  Statement = "statement",
  DealUser = "deal-user",
  PartyUser = "party-user",
  PartyRelation = "party_relationship",
  DiscussionEntity = "discussion-entity",
  ServiceProvider = "service-provider"
}
