import {
  AggregationFocusType,
  FieldType,
  IntegrationType,
  RentalDataVendorType,
  RentRageAggregationIntergrationFields
} from "@elphi/types";
import { createOptionsFromEnumAllowUndefined } from "../../../../../../components/utils/formUtils";
import { IntegrationsFieldSpecs } from "../../../types/integrations.types";

export type RentRangeIntegrationsFieldSpecs = IntegrationsFieldSpecs<
  RentRageAggregationIntergrationFields,
  {}
>;

export const rentRangeIntegrationsFieldSpecs: RentRangeIntegrationsFieldSpecs =
  {
    aggregations: {
      Integrations: {
        [IntegrationType.RentalData]: {
          [RentalDataVendorType.RentRange]: {
            confidenceScore: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "confidenceScore",
                  "thirdParty"
                ],
                fieldType: FieldType.Number,
                label: "Confidence Score"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "confidenceScore",
                  "override"
                ],
                fieldType: FieldType.Number,
                label: "Confidence Score"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "confidenceScore",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            errorMessage: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "errorMessage",
                  "thirdParty"
                ],
                fieldType: FieldType.RichText,
                label: "Error Message"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "errorMessage",
                  "override"
                ],
                fieldType: FieldType.RichText,
                label: "Error Message"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "errorMessage",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            orderStatus: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "orderStatus",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Order Status"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "orderStatus",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Order Status"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "orderStatus",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            requestId: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "requestId",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Request Id"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "requestId",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Request Id"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.RentalData,
                  RentalDataVendorType.RentRange,
                  "requestId",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            }
          }
        }
      }
    },
    Integrations: {}
  };
