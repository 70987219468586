import { TSchema, Type } from "@sinclair/typebox";
import {
  ContactPointRoleType,
  CreditReportPullType,
  EntityType,
  PartyOperationsDepartmentType,
  PartyType,
  RealEstateOperationType,
  TaxpayerIdentifierType
} from "../../entities";
import {
  baseCitizenshipResidency,
  baseFixNFlipNBridgePlusTier,
  baseLOCStatus,
  basePartyMilestone
} from "../../entities/base";
import { BaseEntitySchema } from "../baseEntity.typebox";
import {
  BaseAddressSchema,
  StateCodeSchema,
  StateNameSchema
} from "../location.typebox";
import {
  AggregationFieldSchema,
  ArrayField,
  DateField,
  EmailField,
  EnumField,
  IntegerField,
  MoneyAmountField,
  PhoneNumberField,
  StringField
} from "../utils.typebox";

const BasePartyAggregationsSchema = Type.Object({
  BackgroundReportExpirationDate: AggregationFieldSchema(DateField),
  LOCExpirationDate: AggregationFieldSchema(DateField),
  TotalStatementQualifyingBalance: AggregationFieldSchema(MoneyAmountField)
});

const BaseSchemaCommonParty = <
  TType extends TSchema,
  TTaxIdentifier extends TSchema
>(
  partyType: TType,
  taxpayerIdentifierType: TTaxIdentifier
) =>
  Type.Intersect([
    BaseEntitySchema,
    Type.Object({
      aggregations: Type.Partial(BasePartyAggregationsSchema),
      PartyType: partyType,
      GroupId: StringField,
      TaxpayerIdentifierType: taxpayerIdentifierType,
      TaxpayerIdentifierValue: StringField,
      LiquidAssetTotalAmount: MoneyAmountField,
      OperationsDepartment: EnumField(PartyOperationsDepartmentType),
      BackgroundReportDate: DateField,
      Address: Type.Partial(BaseAddressSchema),
      ContactPointTelephoneValue: PhoneNumberField,
      ContactPointEmailValue: EmailField,
      CitizenshipResidency: EnumField(baseCitizenshipResidency),
      NumberOfRehabProjects: IntegerField,
      NumberOfTransactions: IntegerField,
      CreditReportDate: DateField
    })
  ]);

const BaseSchemaEntityPartyFieldsSchema = Type.Object({
  EntityType: EnumField(EntityType),
  FullName: StringField,
  BusinessIncorporationStateName: StateNameSchema,
  BusinessIncorporationStateCode: StateCodeSchema,
  BusinessRegistrationStates: ArrayField(StateCodeSchema),
  FixAndFlipBridgePlusTier: EnumField(baseFixNFlipNBridgePlusTier),
  NewConstructionEligibilityIndicator: StringField,
  RemainingLineOfCreditAmount: MoneyAmountField,
  Notes: StringField,
  EvidenceOfGoodStandingExpirationDate: DateField,
  RealEstateOperationType: ArrayField(EnumField(RealEstateOperationType)),
  TotalFixAndFlipNewConstructionSoldPastThreeYearsCount: IntegerField,
  PartyMilestone: EnumField(basePartyMilestone),
  TotalOutstandingLongTermLoanCount: IntegerField,
  TotalOutstandingLongTermPrincipalAmount: MoneyAmountField,
  RepresentativeCreditScore: IntegerField,
  OperationsDepartment: EnumField(PartyOperationsDepartmentType),
  AuthorizedContacts: StringField
});

const BaseSchemaIndividualPartyFieldsSchema = Type.Object({
  FirstName: StringField,
  MiddleName: StringField,
  LastName: StringField,
  BirthDate: DateField,
  GovernmentIssuedIDExpirationDate: DateField,
  CitizenshipCountry: StringField,
  ContactPointRoleType: EnumField(ContactPointRoleType),
  USCitizenshipIndicator: StringField,
  CreditReportNotes: StringField,
  FixNFlipNBridgePlusTier: EnumField(baseFixNFlipNBridgePlusTier),
  NewConstructionEligibilityIndicator: StringField,
  LOCStatusType: EnumField(baseLOCStatus),
  TotalApprovedLOC: MoneyAmountField,
  UnpaidPrincipalBalance: MoneyAmountField,
  LoanAmountInProcess: MoneyAmountField,
  RemainingLOCAvailable: MoneyAmountField,
  CreditReportPullType: EnumField(CreditReportPullType),
  CompletedExits: IntegerField,
  TransactionsCumulativeSalePrice: MoneyAmountField,
  TransactionsAverageProjectDuration: StringField,
  TransactionsAverageSalePrice: MoneyAmountField,
  NumberOfFlips: IntegerField,
  FlipsCumulativeSalePrice: MoneyAmountField,
  FlipsAverageProjectDuration: StringField,
  FlipsAverageSalePrice: MoneyAmountField,
  NumberOfNewBuilds: IntegerField,
  NewBuildsCumulativeSalePrice: MoneyAmountField,
  NewBuildsAverageProjectDuration: StringField,
  NewBuildsAverageSalePrice: MoneyAmountField,
  ExperienceNotes: StringField,
  EstimatedCreditScore: IntegerField,
  ConvictionIndicator: StringField,
  OutstandingLawsuitsIndicator: StringField,
  OutstandingJudgmentsIndicator: StringField,
  BankruptcyIndicator: StringField,
  ForeclosureIndicator: StringField,
  MortgageDelinquentIndicator: StringField,
  StatedCashOnHandAmount: MoneyAmountField
});

const BaseSchemaIndividualPartyFields = <T extends TSchema>(
  taxpayerIdentifierType: T
) =>
  Type.Intersect([
    BaseSchemaCommonParty(
      Type.Literal(PartyType.Individual),
      taxpayerIdentifierType
    ),
    BaseSchemaIndividualPartyFieldsSchema
  ]);

export const elphiBaseSchemaEntityParty = Type.Intersect([
  BaseSchemaCommonParty(
    Type.Literal(PartyType.Entity),
    Type.Union([
      Type.Literal(TaxpayerIdentifierType.EIN),
      Type.Literal(TaxpayerIdentifierType.SSN),
      Type.Literal(TaxpayerIdentifierType.TIN)
    ])
  ),
  BaseSchemaEntityPartyFieldsSchema
]);

export const elphiBaseSchemaIndividualParty = BaseSchemaIndividualPartyFields(
  Type.Union([
    Type.Literal(TaxpayerIdentifierType.SSN),
    Type.Literal(TaxpayerIdentifierType.TIN)
  ])
);
