import { ElphiTrxStatus, StepRequired } from "../../integration.types";
import {
  FloodCertificateVendorFields,
  FloodCertificateVendorType
} from "../floodCertificateVendor.types";

export enum ServiceLinkOptions {
  DeterminationAndCertificate = "Determination + Certificate",
  DeterminationOnly = "Determination Only",
  CertificateOnly = "Certificate Only",
  UpdateCertificateOrder = "Update Certificate Order"
}

export enum ServiceLinkTrxStatus {
  Complete = "C",
  Open = "O",
  DelayA1 = "A1",
  DelayA2 = "A2",
  DelayA3 = "A3",
  NoHit = "NH",
  Failed = "FAILED"
}

//TODO for retry
export type ServiceLinkFloodFailedStepLabel = "failedUploadingCertificate";

export type ServiceLinkFloodCertificateFields =
  FloodCertificateVendorFields<FloodCertificateVendorType.ServiceLink> & {
    elphiTrxStatus: ElphiTrxStatus;
    serviceLinkTrxStatus?: ServiceLinkTrxStatus;
    floodCheckTrxStatus?: ElphiTrxStatus;
    transactionId?: string;
    failedLabel?: ServiceLinkFloodFailedStepLabel | string;
    serviceOption?: ServiceLinkOptions;
    stepRequired?: StepRequired;
  };
