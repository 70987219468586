import { Property } from "@elphi/types";

export const propertyLabel = (p: Property) =>
  `${p?.Address?.AddressLineText ?? "Address Line"}
    ${p?.Address?.AddressUnitIdentifier ?? ""}
    ${p?.Address?.CityName ?? "City"},
    ${p?.Address?.StateName ?? "State Name"}
    ${p?.Address?.PostalCode ?? "Postal Code"}
    - ${p?.PropertyLoanPurpose ?? "Loan Type"}`;

export const propertyAddress = (p: Property) => {
  const addressLineText = p?.Address?.AddressLineText ?? "";
  const addressUnitIdentifier = p?.Address?.AddressUnitIdentifier ?? "";
  const cityName = p?.Address?.CityName ?? "";
  const stateCode = p?.Address?.StateCode ?? "";
  const postalCode = p?.Address?.PostalCode ?? "";
  if (!!addressLineText || !!cityName || !!stateCode || !!postalCode) {
    return `${addressLineText} ${addressUnitIdentifier} ${cityName}, ${stateCode} ${postalCode}`;
  }
  return "N/A";
};

export const propertyStateCounty = (p: Property) => {
  const stateCode = p?.Address?.StateCode ?? "";
  const countyName = p?.Address?.CountyName ?? "";
  if (!!stateCode) {
    return `State - ${stateCode} , County - ${countyName}`;
  }
  return "N/A";
};
