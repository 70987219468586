import { Static, Type } from "@sinclair/typebox";
import { StringField } from "../../typebox/utils.typebox";
import { createEntitySchema } from "../../typebox/utils/entity.utils";

export const TeamFields = Type.Object({
  name: StringField,
  description: StringField
});

export const {
  Update: TeamUpdate,
  Create: TeamCreate,
  Schema: TeamSchema,
  getOperationSchema: getOperationTeamSchema
} = createEntitySchema(TeamFields);

export type Team = Static<typeof TeamSchema>;
