import { DealUser } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { dealUserRelationSlice } from "./dealUserRelation.slice";

export const dealUserRelationAction: ActionDispatcher<DealUser> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: DealUser[]) => void;
    update?: (r: Update<DealUser>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = dealUserRelationSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

export default dealUserRelationAction;
