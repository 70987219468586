export enum StateCode {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VA = "VA",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY"
}
export enum StateName {
  Alaska = "Alaska",
  Alabama = "Alabama",
  Arkansas = "Arkansas",
  Arizona = "Arizona",
  California = "California",
  Colorado = "Colorado",
  Connecticut = "Connecticut",
  WashingtonDC = "Washington,D.C.",
  Delaware = "Delaware",
  Florida = "Florida",
  Georgia = "Georgia",
  Hawaii = "Hawaii",
  Iowa = "Iowa",
  Idaho = "Idaho",
  Illinois = "Illinois",
  Indiana = "Indiana",
  Kansas = "Kansas",
  Kentucky = "Kentucky",
  Louisiana = "Louisiana",
  Massachusetts = "Massachusetts",
  Maryland = "Maryland",
  Maine = "Maine",
  Michigan = "Michigan",
  Minnesota = "Minnesota",
  Missouri = "Missouri",
  Mississippi = "Mississippi",
  Montana = "Montana",
  NorthCarolina = "North Carolina",
  NorthDakota = "North Dakota",
  Nebraska = "Nebraska",
  NewHampshire = "New Hampshire",
  NewJersey = "New Jersey",
  NewMexico = "New Mexico",
  Nevada = "Nevada",
  NewYork = "New York",
  Ohio = "Ohio",
  Oklahoma = "Oklahoma",
  Oregon = "Oregon",
  Pennsylvania = "Pennsylvania",
  RhodeIsland = "Rhode Island",
  SouthCarolina = "South Carolina",
  SouthDakota = "South Dakota",
  Tennessee = "Tennessee",
  Texas = "Texas",
  Utah = "Utah",
  Virginia = "Virginia",
  Vermont = "Vermont",
  Washington = "Washington",
  Wisconsin = "Wisconsin",
  WestVirginia = "West Virginia",
  Wyoming = "Wyoming"
}

export type BaseAddress = {
  AddressLineText: string;
  AddressUnitIdentifier: string;
  CityName: string;
  CountyName: string;
  StateName: StateName;
  StateCode: StateCode;
  PostalCode: string;
};
