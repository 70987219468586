import {
  BaseInsurancePolicy,
  Deal,
  InsurancePolicyEntityType,
  Property
} from "@elphi/types";
import { buildPropertyName } from "@elphi/utils";
import { NOT_AVAILABLE } from "../../../../../../constants/common";

export const buildOption = (r: {
  property?: Property;
  selectedDeal?: Deal;
  selectedProperty?: Property;
  selectedInsurancePolicy?: BaseInsurancePolicy<InsurancePolicyEntityType>;
}) => {
  const { property, selectedDeal, selectedProperty, selectedInsurancePolicy } =
    r;

  if (!property) {
    return undefined;
  }

  const isPropertyBelongToSelectedDeal = checkPropertyBelongingToDeal(
    property,
    selectedDeal
  );
  const isSelectedProperty = checkIfSelectedProperty(
    property,
    selectedProperty
  );
  const isDisabled = selectedDeal
    ? !isPropertyBelongToSelectedDeal
    : !isSelectedProperty;

  const loanIdentifier = getLoanIdentifier(
    property,
    selectedDeal,
    selectedInsurancePolicy,
    isPropertyBelongToSelectedDeal
  );
  const label = buildLabel(property, loanIdentifier);

  return {
    label,
    value: property.id,
    isDisabled
  };
};

const checkPropertyBelongingToDeal = (
  property: Property,
  selectedDeal?: Deal
): boolean => {
  return !!(
    selectedDeal?.id && property.dealRelation?.includes(selectedDeal.id)
  );
};

const checkIfSelectedProperty = (
  property: Property,
  selectedProperty?: Property
): boolean => {
  return !!(selectedProperty && selectedProperty.id === property.id);
};

const getLoanIdentifier = (
  property: Property,
  selectedDeal?: Deal,
  selectedInsurancePolicy?: BaseInsurancePolicy<InsurancePolicyEntityType>,
  isPropertyBelongToSelectedDeal?: boolean
): string | undefined => {
  if (isPropertyBelongToSelectedDeal) {
    return selectedDeal?.LoanIdentifier;
  }

  const dealId = property.dealRelation?.at(0);
  return dealId
    ? selectedInsurancePolicy?.deals?.[dealId]?.loanIdentifier
    : undefined;
};

const buildLabel = (property: Property, loanIdentifier?: string): string => {
  const propertyName = buildPropertyName(property) || NOT_AVAILABLE;
  const formattedLoanIdentifier = loanIdentifier
    ? `#${loanIdentifier}`
    : NOT_AVAILABLE;
  return `${propertyName} | Loan ${formattedLoanIdentifier}`;
};
