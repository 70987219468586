import { RolodexServiceProvider } from "@elphi/types";
import { cloneDeep, values } from "lodash";
import { useEffect, useState } from "react";
import { EMPTY } from "../../../../../../constants/common";
import { useServiceProviderHooks } from "../../../../../../hooks/rolodexServiceProvider.hooks";
import { useFormBuilderStateHandler } from "../../../../../form-builder/InputBuilder";
import { addProviderOption } from "../../../../../rolodex/service-provider/utils/serviceProvider.utils";

export const useServiceProviderForm = <T extends RolodexServiceProvider>(r?: {
  initialState: Partial<T>;
}) => {
  const { serviceProviderState, getByIdHandler, getByIdApiResponse } =
    useServiceProviderHooks();
  const [selectedProviderId, setSelectedProviderId] = useState<string>(EMPTY);
  const { onChange, state, setState } = useFormBuilderStateHandler<Partial<T>>({
    initialState: r?.initialState || {}
  });
  const notRealIds = values(addProviderOption).map((x) => x.value);
  useEffect(() => {
    if (selectedProviderId && !notRealIds.includes(selectedProviderId)) {
      const provider = serviceProviderState.entities[selectedProviderId] as
        | T
        | undefined;
      if (!provider) {
        getByIdHandler({ id: selectedProviderId });
      }
      if (provider) {
        setState(cloneDeep(provider));
      }
    }
  }, [selectedProviderId, serviceProviderState.entities[selectedProviderId]]);

  return {
    onChange,
    state,
    setState,
    selectedProviderId,
    setSelectedProviderId,
    getByIdApiResponse
  };
};
