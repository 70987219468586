import { Flex, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { EMPTY } from "../../../constants/common";
import { ServiceProviderContainer } from "./ServiceProviderContainer";
import { serviceProviderContainerHooks } from "./ServiceProviderContainer.hooks";
import { ServiceProviderListCard } from "./list-card/ServiceProviderListCard";
import { ServiceProviderPartyCard } from "./list-card/card/ServiceProviderPartyCard";
import { RolodexServiceProviderCard } from "./list-card/serviceProviderCard.types";
import { serviceProviderListHooks } from "./list-card/serviceProviderList.hooks";
import { ServiceProviderCreateModal } from "./modal/ServiceProviderCreateModal";
import { ServiceProviderEditModal } from "./modal/ServiceProviderEditModal";
import { PartyServiceProviderEditModal } from "./modal/ServiceProviderPartyEditModal";
import { ServiceProviderPartyRelationRemoveModal } from "./modal/ServiceProviderPartyRelationRemoveModal";

export const ServiceProviderPartyContainer = (props: {
  dealId?: string;
  partyId: string;
}) => {
  const { partyId, dealId } = props;
  const [isNewProviderProcess, setIsNewProvideProcess] = useState(false);
  const handleCreateProvider = (isUnderCreation: boolean) => {
    setIsNewProvideProcess(isUnderCreation);
  };

  const {
    setCurrentFilter,
    currentFilter,
    getAllHandler,
    isOpenAddModal,
    isOpenEditModal,
    isOpenRemoveModal,
    onToggleAddModal,
    onToggleRemoveModal,
    onToggleEditModal,
    handleToggleEditModal,
    handleToggleRemoveModal,
    setSelectedProvider,
    selectedProvider,
    isLoading
  } = serviceProviderContainerHooks();

  const { filteredByPartyId } = serviceProviderListHooks();
  const {
    isOpen: isOpenConnectToDealModal,
    onToggle: onToggleConnectToDealModal
  } = useDisclosure();

  useEffect(() => {
    setCurrentFilter({
      partyId
    });
  }, [partyId]);

  useEffect(() => {
    if (currentFilter?.partyId) {
      getAllHandler();
    }
  }, [currentFilter?.partyId]);

  const handleToggleConnectToDealModal = (
    selected: RolodexServiceProviderCard
  ) => {
    setSelectedProvider(selected);
    onToggleConnectToDealModal();
  };

  return (
    <>
      <ServiceProviderContainer
        onOpenModal={onToggleAddModal}
        isLoading={isLoading}
        hideEntitiesCount={true}
      >
        <>
          <Flex>
            <ServiceProviderListCard
              isNewProviderProcess={isNewProviderProcess}
              filteredItems={filteredByPartyId}
              card={({ data }) => (
                <ServiceProviderPartyCard
                  data={data}
                  onToggleEdit={() => handleToggleEditModal(data)}
                  onToggleRemove={() => handleToggleRemoveModal(data)}
                  onToggleConnectToDeal={
                    dealId
                      ? () => handleToggleConnectToDealModal(data)
                      : undefined
                  }
                />
              )}
              isLoading={isLoading}
            />
          </Flex>
          <>
            <ServiceProviderCreateModal
              isShow={isOpenAddModal}
              onClose={onToggleAddModal}
              dealId={EMPTY}
              partyId={props.partyId}
              filteredItems={[]}
              showEntitiesStep={false}
              notifyNewProvider={handleCreateProvider}
            />
            {selectedProvider && (
              <PartyServiceProviderEditModal
                dealId={EMPTY}
                data={{
                  ...selectedProvider,
                  representativeId: selectedProvider.repId
                }}
                isShow={isOpenEditModal}
                onClose={onToggleEditModal}
              />
            )}
            {selectedProvider && (
              <ServiceProviderPartyRelationRemoveModal
                data={selectedProvider}
                show={isOpenRemoveModal}
                onClose={onToggleRemoveModal}
              />
            )}
            {selectedProvider && dealId && (
              <ServiceProviderEditModal
                dealId={dealId}
                data={{
                  ...selectedProvider,
                  representativeId: selectedProvider.repId
                }}
                isShow={isOpenConnectToDealModal}
                onClose={onToggleConnectToDealModal}
              />
            )}
          </>
        </>
      </ServiceProviderContainer>
    </>
  );
};
