export const universalPureBlack000 = "#000";
export const universalWhitefff = "#fff";
export const primary100 = "#f2f7ff";
export const primary600 = "#002a7b";
export const secondary100 = "#fcf5ff";
export const primary500default = "#0157fe";
export const secondary200 = "#f6e0ff";
export const primary200 = "#dbe7ff";
export const secondary400 = "#cb61f5";
export const secondary300 = "#e9b3ff";
export const primary400 = "#5b92fb";
export const primary300 = "#a2c1ff";
export const secondary600 = "#4a1f5b";
export const grey100background = "#f6f7f8";
export const grey200hover = "#edeef2";
export const secondary500 = "#a015d7";
export const grey300dividersborders = "#d0d3dd";
export const grey400 = "#8f96ae";
export const grey600primarytext = "#1f232e";
export const grey500iconssecondarytext = "#505872";
export const success200 = "#d3f1e2";
export const success100 = "#f4faf7";
export const success300 = "#8cdeb4";
export const success400 = "#37a46c";
export const success500 = "#15653c";
export const success600 = "#114028";
export const warning100 = "#fff9f0";
export const warning200 = "#ffeaca";
export const warning300 = "#ffce85";
export const warning400 = "#ffa929";
export const warning500 = "#c27400";
export const warning600 = "#754600";
export const error100 = "#fff5f6";
export const error200 = "#ffe0e3";
export const error300 = "#ffb8bf";
export const error400 = "#f02b3f";
export const error500 = "#ba2c3a";
export const error600 = "#6f1a23";

export  const blue100 = "#BEE3F8"
export  const blue400 = "##4299E1"
export const grey100 = "##EDF2F7";