export const mofinOperationsDepartment = {
  NY: "NY",
  FL: "FL"
} as const;

export const mofinEntityType = {
  GeneralPartnership: "General Partnership",
  LimitedLiabilityCompany: "Limited Liability Company",
  LimitedLiabilityPartnership: "Limited Liability Partnership",
  LimitedPartnership: "Limited Partnership",
  LimitedLiabilityLimitedPartnership: "Limited Liability Limited Partnership",
  Corporation: "Corporation",
  LivingTrust: "Living Trust"
} as const;
