import {
  BasePaginationResponse,
  DiscussionComment,
  DiscussionEntityType
} from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";

export type DiscussionEntityCommentPaginateRequest = Partial<{
  cursor: string;
  limit: string;
  entityId: string;
}>;

type BaseDiscussionEntityCommentRequest = {
  entityId: string;
  comment: string;
  mentionedUIDs?: string[];
  htmlComment: string;
};

export type DiscussionEntityCommentAddRequest =
  BaseDiscussionEntityCommentRequest & {
    entityType: DiscussionEntityType;
  };

export type DiscussionEntityCommentUpdateRequest =
  BaseDiscussionEntityCommentRequest & {
    id: string;
    entityId: string;
  };

export type DiscussionEntityCommentDeleteRequest = {
  id: string;
  entityId: string;
};

export type DiscussionEntityCommentPaginateResponse =
  BasePaginationResponse<DiscussionComment> & { entityId?: string };

export type SaveWatchersRequest = {
  entityId: string;
  entityType: DiscussionEntityType;
  watcherUIDs: string[];
};

export type ToggleReactionRequest = {
  entityId: string;
  commentId: string;
  reactionId: string;
};

export const discussionEntityApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "discussionEntityApi",
  tagTypes: ["DiscussionEntity", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "discussion-entity"
  }),
  endpoints: (builder) => ({
    paginateV2: builder.query<
      DiscussionEntityCommentPaginateResponse,
      DiscussionEntityCommentPaginateRequest
    >({
      query: (r) => {
        return {
          url: `/paginate`,
          method: "POST",
          body: r
        };
      },
      transformResponse: (
        response: DiscussionEntityCommentPaginateResponse,
        _,
        arg
      ): DiscussionEntityCommentPaginateResponse => {
        return {
          entityId: arg.entityId,
          page: response.page,
          nextCursor: response.nextCursor,
          hasMore: response.hasMore
        };
      }
    }),
    addComment: builder.mutation<
      { id: string },
      DiscussionEntityCommentAddRequest
    >({
      query: (r) => {
        return {
          url: `/add-comment`,
          method: "POST",
          body: r
        };
      }
    }),
    updateComment: builder.mutation<
      { id: string },
      DiscussionEntityCommentUpdateRequest
    >({
      query: (r) => {
        return {
          url: `/update-comment`,
          method: "PUT",
          body: r
        };
      }
    }),
    deleteComment: builder.mutation<
      { id: string },
      DiscussionEntityCommentDeleteRequest
    >({
      query: (r) => {
        return {
          url: `/delete-comment`,
          method: "DELETE",
          body: r
        };
      }
    }),
    toggleNotify: builder.mutation<{ notify: boolean }, { entityId: string }>({
      query: (r) => {
        return {
          url: `/toggle-notify`,
          method: "PUT",
          body: r
        };
      }
    }),
    saveWatchers: builder.mutation<
      { watcherUIDs: string[]; entityId: string },
      SaveWatchersRequest
    >({
      query: (r) => {
        return {
          url: `/save-watchers`,
          method: "POST",
          body: r
        };
      },
      transformResponse: (
        response: { watcherUIDs: string[]; entityId: string },
        _,
        arg
      ): {
        entityId: string;
        watcherUIDs: string[];
      } => {
        return {
          entityId: arg.entityId,
          watcherUIDs: response.watcherUIDs
        };
      }
    }),
    toggleReaction: builder.mutation<
      { commentId: string },
      ToggleReactionRequest
    >({
      query: (r) => {
        return {
          url: `/toggle-reaction`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
