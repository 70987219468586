import { LabelValue } from "@elphi/types";
import copy from "copy-to-clipboard";
import { map, values } from "lodash";

export const saveToClipboard = (
  data: string,
  options?: {
    successToast?: any;
    errorToast?: any;
    onSuccess?: Function;
    onError?: Function;
  }
): void => {
  try {
    copy(data);
    options?.onSuccess?.();
    options?.successToast({ title: "CSV was copied to clipboard." });
  } catch (error) {
    console.error(error);
    options?.onError?.(error);
    options?.errorToast({
      title: "CSV to clipboard has failed. Please try again."
    });
  }
};

export const saveCSVFile = (
  data: string,
  filename: string = "Checklist.csv",
  options?: {
    successToast?: any;
    errorToast?: any;
    onSuccess?: Function;
    onError?: Function;
  }
) => {
  try {
    const pom = document.createElement("a");
    const csvContent = data;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
    options?.onSuccess?.();
    options?.successToast({ title: "CSV was downloaded." });
  } catch (error) {
    console.error(error);
    options?.onError?.(error);
    options?.errorToast({
      title: "CSV to file download has failed. Please try again."
    });
  }
};

export const saveTableToClipboard = (
  data: string,
  options?: {
    successToast?: any;
    errorToast?: any;
    onSuccess?: Function;
    onError?: Function;
  }
) => {
  try {
    const elementId = "divToCopyTable";
    const textDiv = document.createElement("div");
    textDiv.setAttribute("id", elementId);

    textDiv.innerHTML = data;

    document.body.appendChild(textDiv);

    let doc = document,
      text = doc.getElementById(elementId),
      range,
      selection;

    if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    setTimeout(() => {
      document.execCommand("copy");
      window!.getSelection()!.removeAllRanges();
      document.body.removeChild(textDiv);
      options?.onSuccess?.();
      options?.successToast({
        title: "Checklist Table was copied to clipboard."
      });
    }, 0);
  } catch (error) {
    console.error(error);
    options?.onError?.(error);
    options?.errorToast({
      title: "Table to clipboard has failed. Please try again."
    });
  }
};

export const getLabelValue = (value: string): LabelValue => ({
  label: value,
  value
});

export const mapEnumValues = <T>(enumObject: T): LabelValue[] =>
  map(values(enumObject), (value) => getLabelValue(value));
