export const baseEntityToIndividualRelationRoleType = {
  AuthorizedSignatory: "Authorized Signatory",
  CEO: "CEO",
  CFO: "CFO",
  ChiefExecutiveMember: "Chief Executive Member",
  Director: "Director",
  Executor: "Executor",
  GeneralPartner: "General Partner",
  LegalCounsel: "Legal Counsel",
  LimitedPartner: "Limited Partner",
  Manager: "Manager",
  ManagingMember: "Managing Member",
  Member: "Member",
  Partner: "Partner",
  President: "President",
  Principal: "Principal",
  Secretary: "Secretary",
  SoleMember: "Sole Member",
  Treasurer: "Treasurer",
  Trustee: "Trustee",
  VicePresident: "Vice President"
} as const;

export const baseEntityToEntityRelationRoleType = {
  Member: "Member",
  Shareholder: "Shareholder",
  GeneralPartner: "General Partner",
  Manager: "Manager"
} as const;
