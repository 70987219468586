import {
  limaLoanPartyEntityRoleType,
  limaLoanPartyIndividualRoleType
} from "@elphi/types/entities/lima";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";
import {
  baseSchemaDealToEntityFieldSpecs,
  baseSchemaDealToIndividualFieldSpecs,
  fullBaseSchemaDealToEntitySpecs,
  fullBaseSchemaDealToIndividualSpecs
} from "../../../base/spec-files/deal-party/dealParty.fields";
import { modifyFromSpec } from "../../../utils/formsSpec.utils";

export const fullLimaDealToIndividualSpecs = {
  ...fullBaseSchemaDealToIndividualSpecs,
  relationRoleType: modifyFromSpec({
    fieldSpecs: baseSchemaDealToIndividualFieldSpecs.relationRoleType,
    changes: {
      options: createOptionsFromMap(limaLoanPartyIndividualRoleType)
    }
  })
};

export const fullLimaDealToEntitySpecs = {
  ...fullBaseSchemaDealToEntitySpecs,
  relationRoleType: modifyFromSpec({
    fieldSpecs: baseSchemaDealToEntityFieldSpecs.relationRoleType,
    changes: {
      options: createOptionsFromMap(limaLoanPartyEntityRoleType)
    }
  })
};
