import { ElphiSnapshotEntity } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import lodash from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { snapshotEntityAdapter as entityAdapter } from "./snapshot.adapter";
import { snapshotApi } from "./snapshot.service";

export type ElphiSnapshotEntitySliceState = EntityState<ElphiSnapshotEntity> & {
  selectedId?: EntityId;
};

export const snapshotSlice = createSlice({
  name: "snapshot",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as ElphiSnapshotEntitySliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(snapshotApi)(builder);
    builder.addMatcher(
      snapshotApi.endpoints.getSnapshot.matchFulfilled,
      (state, { payload }) => {
        // const update: Update<ElphiSnapshotEntity>[] =
        //   payload.snapshot.ids.reduce((p, id) => {
        //     const changes = payload?.snapshot?.entities[id];
        //     if (changes) p.push({ id, changes: changes! });
        //     return p;
        //   }, [] as Update<ElphiSnapshotEntity>[]);
        // snapshotSlice.actions.updateMany(update);
        state.entities = lodash.merge(
          state.entities,
          payload.snapshot.entities
        );

        // state.entities = lodash.merge({
        //   ...state.entities,
        //   ...payload.snapshot.entities
        // });
      }
    );
  }
});
