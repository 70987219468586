import { InsurancePolicyEntityType, StatusCode } from "@elphi/types";
import { keys, merge, pickBy, values } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../../apis/rtk/response.handler";
import { useElphiToast } from "../../components/toast/toast.hook";
import { EMPTY } from "../../constants/common";
import { RootState } from "../../redux/store";
import { dealApi } from "../../redux/v2/deal";
import {
  InsurancePolicyProvidersServiceRequest,
  propertyInsurancePolicyApi
} from "../../redux/v2/insurance-policy/propertyInsurancePolicy.service";
import { useRolodexBranchRepRelation } from "../rolodexBranchRepRelation.hooks";
import { useInsurancePolicyHooks } from "./insurancePolicy.hooks";

type UpsertRequest = {
  dealId: string;
} & Required<InsurancePolicyProvidersServiceRequest>;

export const usePropertyInsurancePolicyHooks = () => {
  const { successToast, errorToast } = useElphiToast();
  const { branchRepState } = useRolodexBranchRepRelation();
  const [getDealPropertiesApi, getDealPropertiesApiResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const selectedDeal = useSelector(
    (state: RootState) =>
      (state.deal.selectedId && state.deal.entities[state.deal.selectedId]) ||
      undefined
  );
  const getDealByIdFromState = (dealId?: string) =>
    useSelector((state: RootState) =>
      dealId ? state?.deal?.entities?.[dealId] : undefined
    );

  const { insurancePolicyState } = useInsurancePolicyHooks();
  const [createApi, createResponse] =
    propertyInsurancePolicyApi.useCreateWithProviderMutation();
  const [updateApi, updateResponse] =
    propertyInsurancePolicyApi.useUpdateWithProviderMutation();

  const entities = useMemo(() => {
    return pickBy(
      insurancePolicyState.entities,
      (v) => v?.entityType === InsurancePolicyEntityType.Property
    );
  }, [insurancePolicyState.entities]);

  const propertyInsurancePolicyState: typeof insurancePolicyState = {
    entities,
    ids: keys(entities),
    selectedId: insurancePolicyState.selectedId,
    searchCursor: insurancePolicyState.searchCursor
  };

  const createPolicyWithProviders = async (
    r: InsurancePolicyProvidersServiceRequest
  ) => {
    return await createApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Created  successfully",
            description: `policy id: ${
              res.data.policyId
            } providers: ${JSON.stringify(res.data.policyProvider)}`
          });
        } else {
          errorToast({
            title: "Failed",
            description: "Failed to create insurance policy."
          });
        }
        return res;
      });
  };

  const updatePolicyWithProviders = async (
    r: InsurancePolicyProvidersServiceRequest
  ) => {
    return await updateApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Updated successfully",
            description: `policy id: ${
              res.data.policyId
            } providers: ${JSON.stringify(res.data.policyProvider)}`
          });
        } else {
          errorToast({
            title: "Failed",
            description: "Failed to update insurance policy."
          });
        }
        return res;
      });
  };

  const createUpsertPayload = (
    r: UpsertRequest & { branchRepRelation: string }
  ): InsurancePolicyProvidersServiceRequest => {
    const { branchRepRelation } = r;
    const previousRelationId = r.insurancePolicy.id
      ? insurancePolicyState.entities[r.insurancePolicy.id]?.branchRepId ||
        EMPTY
      : EMPTY;

    const entityIds = values(
      merge(
        branchRepState?.entities?.[branchRepRelation]?.dealEntities,
        branchRepState?.entities?.[previousRelationId]?.dealEntities
      )
    )
      .map((x) => x.entityIds)
      .flat();

    return {
      insurancePolicy: {
        ...r.insurancePolicy,
        dealIds: [r.dealId, ...(r.insurancePolicy.dealIds || [])]
      },
      serviceProvider: {
        ...r.serviceProvider,
        dealId: r.dealId,
        entityIds
      }
    };
  };

  const upsertHandler = async (r: UpsertRequest) => {
    const { branch, rep } = r.serviceProvider;
    const branchRepRelation = branch && rep ? `${branch.id}_${rep.id}` : EMPTY;
    const payload = createUpsertPayload({ ...r, branchRepRelation });

    if (!r.insurancePolicy.id) {
      return await createPolicyWithProviders(payload);
    }
    return await updatePolicyWithProviders(payload);
  };

  const isSubmitting =
    (createResponse.isLoading || updateResponse.isLoading) &&
    !(createResponse.isError || updateResponse.isError);

  return {
    propertyInsurancePolicyState,
    getDealPropertiesApi,
    upsertHandler,
    isSubmitting,
    updateResponse,
    createResponse,
    getDealPropertiesApiResponse,
    selectedDeal,
    getDealByIdFromState
  };
};
