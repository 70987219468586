export type ResetPasswordRequest = {
  code: string;
  password: string;
};

export enum InvalidIssue {
  UnmatchedPasswords,
  InvalidChars
}

export type PasswordState = {
  password: string;
  confirmPassword: string;
  invalidIssues?: InvalidIssue[];
};
