import { Box, Flex, Text } from "@chakra-ui/react";
import { BooleanSelectType, Deal, Task } from "@elphi/types";
import { WireInsuranceFile } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/funding-shield/fundingShield.types";
import { getFocusedData } from "@elphi/utils";
import { isEmpty } from "lodash";
import {
  InputsFormBuilder,
  OnChangeInput
} from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { TaskFileManagerBody } from "../../../../../components/task/TaskFileManager";
import { fileTypes } from "../../../../../firebase/constants";
import { buildSection } from "../../../utils/formBuilder.utils";
import { stateNYIndicator } from "../../inputs/wire-insurance/fundingShield.inputs";

export type WireInsuranceFilesProps = {
  state: Partial<Deal>;
  onChange: (v: OnChangeInput) => void;
  selectedTask: Task;
  fieldSpecs: EntityFormFieldSpecs<object>;
};

export const WireInsuranceFiles = ({
  selectedTask,
  onChange,
  state,
  fieldSpecs
}: WireInsuranceFilesProps) => {
  const { inputs } = buildSection({
    state,
    onChange,
    fieldSpecs,
    inputs: [stateNYIndicator],
    header: ""
  });

  const stateNYIndicatorValue = getFocusedData(
    state.aggregations?.Integrations?.WireInsuranceCertificate?.FundingShield
      .StateNYIndicator
  );
  return (
    <Box borderTopWidth={"2px"} borderTopColor={"grey.100"} mt={5}>
      <Box mt={5} flexDir={"column"} justifyContent={"space-between"}>
        <Box>
          <Box>
            <InputsFormBuilder inputs={inputs} onChange={onChange} />
          </Box>
        </Box>
        <Flex mt={2} flexWrap={"wrap"}>
          <Box w={300}>
            <Text pl="3px" fontWeight={"bold"}>
              Upload Errors & Omissions Policy
            </Text>
            <TaskFileManagerBody
              isSingleFile
              hideCheckAll
              hideUploadedNumber
              selectedTask={selectedTask}
              types={fileTypes.PDF}
              fileKey={WireInsuranceFile.uploadErrorsAndOmissionsPolicy}
            />
          </Box>
          <Box w={300} ml={5}>
            <Text pl="3px" fontWeight={"bold"}>
              Upload Wire Instructions
            </Text>
            <TaskFileManagerBody
              isSingleFile
              hideCheckAll
              hideUploadedNumber
              selectedTask={selectedTask}
              types={fileTypes.PDF}
              fileKey={WireInsuranceFile.uploadWiresInstructions}
            />
          </Box>
          {(isEmpty(stateNYIndicatorValue) ||
            stateNYIndicatorValue === BooleanSelectType.false) && (
            <Box w={300} ml={5}>
              <Text pl="3px" fontWeight={"bold"}>
                Upload Closing Protection Letter
              </Text>
              <TaskFileManagerBody
                isSingleFile
                hideCheckAll
                hideUploadedNumber
                selectedTask={selectedTask}
                types={fileTypes.PDF}
                fileKey={WireInsuranceFile.uploadClosingProtectionLetter}
              />
            </Box>
          )}
          {(isEmpty(stateNYIndicatorValue) ||
            stateNYIndicatorValue === BooleanSelectType.true) && (
            <Box w={300} ml={5}>
              <Text pl="3px" fontWeight={"bold"}>
                Upload Crimes Policy
              </Text>
              <TaskFileManagerBody
                isSingleFile
                hideCheckAll
                hideUploadedNumber
                selectedTask={selectedTask}
                types={fileTypes.PDF}
                fileKey={WireInsuranceFile.uploadCrimesPolicy}
              />
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
