export const rowIndexStyles = {
  maxWidth: "200px",
  minWidth: "200px"
};

export const timestampStyles = {
  maxWidth: "200px",
  minWidth: "200px"
};

export const taskNameStyles = {
  maxWidth: "400px",
  minWidth: "400px"
};

export const propertyAddressStyles = {
  maxWidth: "300px",
  minWidth: "300px"
};

export const partyNameStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const taskTypeStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const roleStyles = {
  maxWidth: "250px",
  minWidth: "250px"
};

export const kctStyles = {
  maxWidth: "100px",
  minWidth: "100px"
};

export const taskStatusStyles = {
  maxWidth: "400px",
  minWidth: "400px"
};

export const columnStyle = {
  flagIndicator: {
    maxWidth: "10px",
    minWidth: "10px"
  }
};

export const orderStatusStyles = {
  maxWidth: "250px",
  minWidth: "250px"
};
