import { pick } from "lodash";
import { ElphiEnv, SystemSetup } from "../common";
import { DotNestedKeys } from "./flatten";

export const pickFromEnum = <T extends object>(
  obj: T,
  keys: DotNestedKeys<T>[]
) => pick(obj, keys);

export const getSystemSetup = (elphiEnv?: string) => {
  switch (elphiEnv) {
    case ElphiEnv.LimaTest:
    case ElphiEnv.LimaPoc:
    case ElphiEnv.LimaProd:
    case ElphiEnv.ElamiaTest:
      return SystemSetup.Lima;
    case ElphiEnv.MofinTest:
    case ElphiEnv.MofinProd:
      return SystemSetup.Mofin;
    case ElphiEnv.Localhost:
    case ElphiEnv.ElphiTest:
    case ElphiEnv.EdomonTest:
    case ElphiEnv.Demo:
    case ElphiEnv.LedgerTCTest:
    case ElphiEnv.LedgerTCProd:
    default:
      console.log("system setup defaulting to Elphi");
      return SystemSetup.Elphi;
  }
};
