import { SystemSetup } from "../../../common";
import { elphiPropertySchema } from "../../base/property.typebox";
import { limaPropertySchema } from "../../lima/property.typebox";
import { mofinPropertySchema } from "../../mofin/property.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type PropertyEnvToSchemaMap = EnvToSchemasMap;
export const propertySchemas: PropertyEnvToSchemaMap = {
  [SystemSetup.Elphi]: elphiPropertySchema,
  [SystemSetup.Lima]: limaPropertySchema,
  [SystemSetup.Mofin]: mofinPropertySchema,
  [SystemSetup.LedgerTC]: elphiPropertySchema
};
