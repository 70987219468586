import { StatusCode } from "@elphi/types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import {
  discussionEntityApi,
  DiscussionEntityCommentAddRequest,
  DiscussionEntityCommentDeleteRequest,
  DiscussionEntityCommentUpdateRequest,
  SaveWatchersRequest,
  ToggleReactionRequest
} from "../redux/v2/discussion-entity";

export const DISCUSSION_COMMENT_LIMIT = 10;

export const useDiscussionEntityHooks = () => {
  const { errorToast } = useElphiToast();

  const discussionEntityState = useSelector(
    (state: RootState) => state.discussionEntity
  );

  const [addCommentApi, addCommentResponse] =
    discussionEntityApi.useAddCommentMutation();

  const [updateCommentApi, updateCommentResponse] =
    discussionEntityApi.useUpdateCommentMutation();

  const [deleteCommentApi, deleteCommentResponse] =
    discussionEntityApi.useDeleteCommentMutation();

  const [toggleNotifyApi, toggleNotifyResponse] =
    discussionEntityApi.useToggleNotifyMutation();

  const [saveWatchersApi, saveWatchersResponse] =
    discussionEntityApi.useSaveWatchersMutation();

  const [toggleReactionApi, toggleReactionResponse] =
    discussionEntityApi.useToggleReactionMutation();

  const handleAddComment = (r: DiscussionEntityCommentAddRequest) => {
    addCommentApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to add comment",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const handleUpdateComment = (r: DiscussionEntityCommentUpdateRequest) => {
    updateCommentApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update comment",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const handleDeleteComment = async (
    r: DiscussionEntityCommentDeleteRequest
  ) => {
    const response = await deleteCommentApi(r);
    const res = responseHandler(response);
    if (res.status === StatusCode.BadRequest) {
      errorToast({
        title: "Failed to delete comment",
        description: res.data?.description
      });
    }
    return res;
  };

  const handleToggleNotify = async (r: { entityId: string }) => {
    const response = await toggleNotifyApi(r);
    const res = responseHandler(response);
    return res;
  };

  const handleSaveWatchers = (r: SaveWatchersRequest) => {
    saveWatchersApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to save watchers",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const handleToggleReaction = (r: ToggleReactionRequest) => {
    toggleReactionApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to toggle reaction",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  return {
    discussionEntityState,
    addCommentResponse,
    updateCommentResponse,
    deleteCommentResponse,
    toggleNotifyResponse,
    saveWatchersResponse,
    toggleReactionResponse,
    handleAddComment,
    handleUpdateComment,
    handleDeleteComment,
    handleToggleNotify,
    handleSaveWatchers,
    handleToggleReaction
  };
};

export const useDiscussionCommentTableHooks = (r: { entityId: string }) => {
  const { errorToast } = useElphiToast();
  const [cursor, setCursor] = useState<string>();
  const [hasMore, setHasMore] = useState<boolean>();

  const [nextPage, paginateApiResponse] =
    discussionEntityApi.useLazyPaginateV2Query();

  const handleNextPage = () => {
    nextPage(
      {
        entityId: r.entityId,
        cursor,
        limit: String(DISCUSSION_COMMENT_LIMIT)
      },
      false
    )
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((res) => {
        if (res.status === StatusCode.OK) {
          setCursor(res?.data?.nextCursor);
          setHasMore(res?.data?.hasMore);
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to load comments",
            description: res.data?.description
          });
        }
        return res;
      });
  };
  return {
    handleNextPage,
    paginateApiResponse,
    hasMore
  };
};
