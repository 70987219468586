import { BaseInsurancePolicy, InsurancePolicyEntityType } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { insurancePolicySlice } from "./insurancePolicy.slice";

export const insurancePolicyAction: ActionDispatcher<
  BaseInsurancePolicy<InsurancePolicyEntityType>
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(insurancePolicySlice);
  return actionDispatcher(dispatch, callback);
};
