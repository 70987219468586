import {
  BranchServiceProvider,
  CompanyServiceProvider,
  RepresentativeServiceProvider,
  ServiceProviderType
} from "@elphi/types";
import { useState } from "react";
import { EMPTY } from "../../../constants/common";
import { useRolodexConfiguration } from "../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderForm } from "./wizard/steps/providers-step/providersStep.hooks";

export const usePropertyInsuranceProvidersModalHooks = () => {
  const { configurationState, setSelectedConfiguration } =
    useRolodexConfiguration();

  const domainConfigurationId =
    configurationState.selectedId?.toString() || EMPTY;
  const [isProviderFormValid, setIsProviderFormValid] =
    useState<boolean>(false);

  const companyInitialState: Partial<CompanyServiceProvider> = {
    type: ServiceProviderType.Company,
    domainConfigurationId
  };
  const branchInitialState: Partial<BranchServiceProvider> = {
    type: ServiceProviderType.Branch
  };
  const repInitialState: Partial<RepresentativeServiceProvider> = {
    type: ServiceProviderType.Representative
  };

  const companyFormState = useServiceProviderForm<CompanyServiceProvider>({
    initialState: companyInitialState
  });

  const branchFormState = useServiceProviderForm<BranchServiceProvider>({
    initialState: branchInitialState
  });
  const repFormState = useServiceProviderForm<RepresentativeServiceProvider>({
    initialState: repInitialState
  });

  const resetProviderStates = () => {
    [companyFormState, branchFormState, repFormState].forEach((s) => {
      s.setSelectedProviderId(EMPTY);
    });
    companyFormState.setState(companyInitialState);
    branchFormState.setState(branchInitialState);
    repFormState.setState(repInitialState);
    setIsProviderFormValid(false);
  };

  const resetDomainConfiguration = () => {
    setSelectedConfiguration(EMPTY);
  };

  return {
    companyFormState,
    branchFormState,
    repFormState,
    domainConfigurationId,
    isProviderFormValid,
    setIsProviderFormValid,
    resetProviderStates,
    resetDomainConfiguration
  };
};
