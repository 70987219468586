import { Party, PartyType } from "@elphi/types";
import { FeatureFlag } from "@elphi/utils";
import {
  OnChangeInput,
  Section
} from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { EMPTY } from "../../../../../constants/common";
import { addRuleToInput, buildSection } from "../../../utils/formBuilder.utils";
import {
  citizenshipResidency,
  creditScore,
  groupId,
  locStatusType,
  partyAddressLineText,
  partyAddressUnitIdentifier,
  partyCityName,
  partyCountyName,
  partyMilestone,
  partyPostalCode,
  partyStateCode,
  partyStateName,
  partyType,
  representativeCreditScore
} from "../../inputs/party.inputs";
import {
  BaseSchemaBasePartySections,
  BaseSchemaSectionsBuilders
} from "../../types";

const addressSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (p: {
    state: Partial<Party>;
    title?: string;
    onChange?: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: p.state,
      fieldSpecs,
      onChange: p.onChange,
      header: p.title ? `${p.title}` : "Address",
      inputs: [
        partyAddressLineText,
        partyAddressUnitIdentifier,
        partyCityName,
        partyCountyName,
        partyStateName,
        partyStateCode,
        partyPostalCode
      ]
    });

const partyInfoSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Party>;
    onChange?: (v: OnChangeInput) => void;
    options?: {
      isReadOnly?: boolean;
      hideAttachedComponent?: boolean;
      gates?: Partial<{ [key in FeatureFlag]: boolean }>;
    };
  }): Section =>
    buildSection({
      state: r.state,
      fieldSpecs,
      onChange: r.onChange,
      header: "Party Info",
      inputs: [
        partyType,
        addRuleToInput({
          input: groupId,
          rules: [
            {
              field: "isHidden",
              predicate: (_) =>
                !r.options?.gates?.esd_2826_organization_management
            }
          ],
          state: r.state
        })
      ],
      hideAttachedComponent: r.options?.hideAttachedComponent
    });

const calendarPartyEditSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Party>;
    onChange?: (v: OnChangeInput) => void;
  }): Section => {
    const { state, onChange } = r;
    const isEntityParty = state?.PartyType === PartyType.Entity;
    return buildSection({
      state,
      onChange,
      header: "",
      inputs: [
        addRuleToInput({
          input: partyMilestone,
          rules: [
            {
              field: "isHidden",
              predicate: (_) => !!state?.PartyType && !isEntityParty
            }
          ],
          state
        }),
        addRuleToInput({
          input: locStatusType,
          rules: [
            {
              field: "isHidden",
              predicate: (_) => !!state?.PartyType && isEntityParty
            }
          ],
          state
        })
      ],
      fieldSpecs
    });
  };

const partyInformationSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Party>;
    onChange?: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      fieldSpecs,
      onChange: r.onChange,
      header: EMPTY,
      inputs: [
        citizenshipResidency,
        addRuleToInput({
          input: creditScore,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !r.state || r.state.PartyType === PartyType.Entity
            }
          ],
          state: r.state
        }),
        addRuleToInput({
          input: representativeCreditScore,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !r.state || r.state.PartyType === PartyType.Individual
            }
          ],
          state: r.state
        })
      ]
    });

export const partySectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaBasePartySections> =
  {
    partyInformationSection,
    addressSection,
    partyInfoSection,
    calendarPartyEditSection
  };
