import { BaseEntityWithCorrelationId } from "../db/baseEntity.types";
import { ElphiEntityType } from "./elphiEntities.types";
import { TaskConfiguration } from "./taskConfiguration.types";
import { TaskTemplate } from "./taskTemplate.types";

export enum TaskFlagType {
  Attention = "Attention",
  None = ""
}
export type Task = BaseEntityWithCorrelationId<
  {
    name: string;
    configurationId: string;
    templateId: string;
    ruleId: string;
    entityId: string;
    entityType: ElphiEntityType;
    keyClosingTaskType: TaskConfiguration["keyClosingTaskType"];
    generation: string;
    autoGenerate: TaskConfiguration["autoGenerate"];
    dataToVerify?: TaskConfiguration["dataToVerify"];
    dataToOrder?: TaskConfiguration["dataToOrder"];
    internalInstruction?: TaskConfiguration["internalInstruction"];
    externalInstruction?: TaskConfiguration["externalInstruction"];
    flagged?: TaskFlagType;
    comments?: string;
    rootTaskEntityId?: string;
    dealIds?: string[];
    partyIds?: string[];
  } & TaskTemplate["template"]
>;
