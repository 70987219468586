import { Property, PropertyReportVendorType } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import {
  parcelNumber,
  propertyAddressLineText,
  propertyAddressUnitIdentifier,
  propertyCityName,
  propertyCountyName,
  propertyPostalCode,
  propertyStateCode
} from "../../../base/inputs/property.inputs";
import { buildSection } from "../../../utils/formBuilder.utils";
import {
  siteXAddressLineText,
  siteXAddressUnitIdentifier,
  siteXAssessorParcelNumber,
  siteXCityName,
  siteXCountyName,
  siteXFips,
  siteXOrderCompletedOn,
  siteXOrderStatus,
  siteXPostalCode,
  siteXStateCode
} from "../../inputs/property-report/siteXOrder.inputs";

const siteXOrderSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Property Report: Order",
      inputs: [
        {
          ...propertyAddressLineText,
          isRequired: true,
          validation: (v) => !!propertyAddressLineText.validation?.(v, true)
        },
        propertyAddressUnitIdentifier,
        {
          ...propertyCityName,
          isRequired: true,
          validation: (v) => !!propertyCityName.validation?.(v, true)
        },
        {
          ...propertyStateCode,
          isRequired: true,
          validation: (v) => !!propertyStateCode.validation?.(v, true)
        },
        {
          ...propertyPostalCode,
          isRequired: true,
          validation: (v) => !!propertyPostalCode.validation?.(v, true)
        },
        propertyCountyName,
        parcelNumber
      ],
      fieldSpecs
    });

const siteXVerifySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
    vendorType: PropertyReportVendorType;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Property Report: Verify",
      inputs: [
        siteXAddressLineText,
        siteXAddressUnitIdentifier,
        siteXCityName,
        siteXStateCode,
        siteXPostalCode,
        siteXCountyName,
        siteXAssessorParcelNumber,
        siteXFips
      ],
      fieldSpecs
    });

const siteXOrderStatusSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
    vendorType: PropertyReportVendorType;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Property Report: Order Status",
      inputs: [siteXOrderStatus, siteXOrderCompletedOn],
      fieldSpecs
    });

export const siteXSectionBuilders = {
  siteXOrderSection,
  siteXVerifySection,
  siteXOrderStatusSection
};
