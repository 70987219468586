import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import {
  BooleanSelectType,
  CreditScore,
  IndividualParty,
  OmitCreate
} from "@elphi/types";
import { responseHandler } from "../../apis/rtk/response.handler";
import { RTKResponse } from "../../apis/rtk/response.types";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { creditReportApi } from "../../redux/v2/credit-report";
import { useElphiToast } from "../toast/toast.hook";

export const CreateCreditReportModal = (props: {
  isDisabled?: boolean;
  party?: Partial<IndividualParty>;
  getLatestScore?: Function;
}) => {
  const { party } = props;
  const { errorToast, successToast } = useElphiToast();

  const [createCreditReportApi, createCreditReportResponse] =
    creditReportApi.useCreateMutation();
  const createCreditReportResponseIsLoading =
    createCreditReportResponse.isLoading;

  const orderCreditScore = async () => {
    if (party?.id) {
      const response = await createCreditReportApi({
        partyId: party.id,
        latest: BooleanSelectType.true
      } as OmitCreate<CreditScore>);
      return responseHandler(response as RTKResponse<CreditScore>);
    }
    return;
  };

  return (
    <Button
      leftIcon={<AddIcon />}
      onClick={async () => {
        if (party) {
          await orderCreditScore().then((r) => {
            if (r) {
              if (r.status === 200) {
                successToast({
                  title: "Credit Report Manually created",
                  description: `${r?.data.id} created`
                });
              }
              if (r.status === 400) {
                errorToast({
                  title: "Failed to create report",
                  description: r.data.description
                });
              }
            }
            return r;
          });
        }
        props?.getLatestScore && (await props.getLatestScore());
      }}
      isLoading={createCreditReportResponseIsLoading}
      isDisabled={props.isDisabled}
      {...elphiTheme.components.light.button.primary}
    >
      Manually Add Report
    </Button>
  );
};
