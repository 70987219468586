import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { CreditScore } from "../../../../../../shared/types/entities/creditReport.types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { creditReportSlice } from "./creditReport.slice";

export const creditReportAction: ActionDispatcher<CreditScore> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: CreditScore[]) => void;
    update?: (r: Update<CreditScore>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = creditReportSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

export default creditReportAction;
