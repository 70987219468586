import { pickFromEnum } from "../../../utils/enums";
import { baseAccountType } from "../../base/asset/baseAccountAsset.enumeration";

export const limaAccountType = {
  ...pickFromEnum(baseAccountType, [
    "Annuity",
    "CashOutProceeds",
    "Checking",
    "EarnestMoneyDeposit",
    "IRA",
    "MoneyMarket",
    "N401K",
    "Other",
    "Savings",
    "TrustAccount"
  ]),
  Bonds: "Bonds",
  MutualFund: "Mutual Fund",
  Stocks: "Stocks"
};
