import { TaskTemplate } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { SearchCursorState } from "../utils/search.utils";
import { taskTemplateEntityAdapter as entityAdapter } from "./taskTemplate.adapter";
import { taskTemplateApi } from "./taskTemplate.service";

export type TaskTemplateSliceFields = {
  selectedId?: EntityId;
  searchCursor: SearchCursorState;
};

export type TaskConfigurationSliceState = EntityState<TaskTemplate> &
  TaskTemplateSliceFields;

const initialState: TaskTemplateSliceFields = {
  selectedId: undefined,
  searchCursor: {
    query: {}
  }
};

export const taskTemplateSlice = createSlice({
  name: "taskTemplate",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(taskTemplateApi)(builder);
    builder.addMatcher(
      taskTemplateApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      taskTemplateApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
    builder.addMatcher(
      taskTemplateApi.endpoints.paginate.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
  }
});
