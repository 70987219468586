import { DealUser, StatusCode } from "@elphi/types";
import { useDealUserRelationHooks } from "../../hooks/dealuser.hooks";
import { useElphiToast } from "../toast/toast.hook";

export const useUserTableHooks = () => {
  const { updateBatch } = useDealUserRelationHooks();
  const { errorToast, successToast } = useElphiToast();

  const handleUpdateDealUserBatch = async (relations: {
    [id: string]: {
      id: string;
    } & Partial<DealUser>;
  }) => {
    const listRelations = Object.entries(relations).map(([key, value]) => ({
      id: key,
      roleIds: value.roleIds
    }));
    return await updateBatch({ relations: listRelations }).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Deal Roles Updated",
          description: `total relations updated: ${listRelations.length}`
        });
      }
      if (r.status === StatusCode.BadRequest)
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      return r;
    });
  };

  return { handleUpdateDealUserBatch };
};
