import { Box, Text } from "@chakra-ui/react";
import { Property } from "@elphi/types";
import { useState } from "react";
import ModalContainer, {
  ModalContainerProps
} from "../../../../modal-container/ModalContainer";
import { ElphiLocationResponse } from "../propertyReport.type";
import { SiteXPropertiesList } from "./SiteXPropertiesList";

export type SiteXOrderModalProps = {
  selectedPropertyState: Partial<Property>;
  onCreateOrder: Function;
  modalType?: SiteXOrderModalType;
  maxWidth?: number;
  isDisabled?: boolean;
} & ModalContainerProps;

export enum SiteXOrderModalType {
  AlreadySuccessfullyOrdered = "alreadySuccessfullyOrdered",
  Multi = "multi"
}

const getSiteXOrderModalContent: {
  [key in SiteXOrderModalType]: {
    confirmTitle: string;
    body?: React.ReactNode;
  };
} = {
  [SiteXOrderModalType.AlreadySuccessfullyOrdered]: {
    confirmTitle: "Yes, I am sure. Order another report",
    body: (
      <Box>
        <Text>
          You have already successfully ordered a SiteX Report for this
          property. Are you sure you want to order another report?
        </Text>
        <Text mt={5} color={"red.400"}>
          This action will incur additional cost from the vendor
        </Text>
      </Box>
    )
  },
  [SiteXOrderModalType.Multi]: {
    confirmTitle: "Order"
  }
};

const SiteXOrderModalContainer = ({
  isShow,
  onCloseModal,
  selectedPropertyState,
  onCreateOrder,
  modalType = SiteXOrderModalType.AlreadySuccessfullyOrdered,
  children,
  maxWidth,
  isDisabled
}: SiteXOrderModalProps) => {
  const { confirmTitle } = getSiteXOrderModalContent[modalType];
  const header = `Property Report, Address: ${selectedPropertyState?.Address?.AddressLineText} ${selectedPropertyState?.Address?.AddressUnitIdentifier}, ${selectedPropertyState?.Address?.CityName}, ${selectedPropertyState?.Address?.StateCode} ${selectedPropertyState?.Address?.PostalCode}`;
  return (
    <ModalContainer
      isShow={isShow}
      header={header}
      submit={{
        onConfirm: onCreateOrder,
        isDisabled,
        confirmTitle
      }}
      onCloseModal={onCloseModal}
      body={children}
      maxWidth={maxWidth}
    />
  );
};

export const SiteXOrderStatusModal = ({
  modalType = SiteXOrderModalType.AlreadySuccessfullyOrdered,
  ...props
}: SiteXOrderModalProps) => {
  const { body } = getSiteXOrderModalContent[modalType];

  return (
    <SiteXOrderModalContainer {...props}>
      <>{body}</>
    </SiteXOrderModalContainer>
  );
};

const MULTI_MODAL_WIDTH = 1300;
export const SiteXOrderMultiModal = ({
  data,
  onCreateOrder,
  ...props
}: SiteXOrderModalProps & { data?: ElphiLocationResponse[] }) => {
  const [selectedRow, setSelectedRow] = useState<
    ElphiLocationResponse | undefined
  >(undefined);

  const onSelectedRow = (item: ElphiLocationResponse) => {
    setSelectedRow(item);
  };

  const onCreate = () => {
    onCreateOrder(selectedRow);
  };

  return (
    <SiteXOrderModalContainer
      {...props}
      modalType={SiteXOrderModalType.Multi}
      onCreateOrder={onCreate}
      maxWidth={MULTI_MODAL_WIDTH}
      isDisabled={!selectedRow}
    >
      <Box>
        <Text>
          Your property search returned multiple matches. Please choose the
          record you are interested in, and click Order.
        </Text>
        {!!data && (
          <SiteXPropertiesList locations={data} onSelectRow={onSelectedRow} />
        )}
      </Box>
    </SiteXOrderModalContainer>
  );
};

export const SiteXOrderModal = ({
  modalType,
  ...props
}: SiteXOrderModalProps & { data?: ElphiLocationResponse[] }) => {
  return modalType === SiteXOrderModalType.Multi ? (
    <SiteXOrderMultiModal {...props} />
  ) : (
    <SiteXOrderStatusModal {...props} />
  );
};
