import { createIcon } from "@chakra-ui/react";

const customFileIcon = (stroke?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18 1L1 8L8 11L11 18L18 1Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      stroke: "rgb(39,103,73)"
    }
  });

export const SendIcon = customFileIcon();
