import {
  limaEntityToEntityRelationRoleType,
  limaEntityToIndividualRelationRoleType
} from "@elphi/types/entities/lima";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";
import {
  baseSchemaEntityToEntityFieldBaseSpecs,
  fullBaseSchemaEntityToEntityFieldSpecs,
  fullBaseSchemaEntityToIndividualFieldSpecs
} from "../../../base/spec-files/party-relation/partyRelation.fields";
import { modifyFromSpec } from "../../../utils/formsSpec.utils";

export const fullLimaSchemaEntityToEntityFieldSpecs = {
  ...fullBaseSchemaEntityToEntityFieldSpecs,
  relationRoleType: modifyFromSpec({
    fieldSpecs: baseSchemaEntityToEntityFieldBaseSpecs.relationRoleType,
    changes: {
      options: createOptionsFromMap(limaEntityToEntityRelationRoleType)
    }
  })
};

export const fullLimaSchemaEntityToIndividualFieldSpecs = {
  ...fullBaseSchemaEntityToIndividualFieldSpecs,
  relationRoleType: modifyFromSpec({
    fieldSpecs: baseSchemaEntityToEntityFieldBaseSpecs.relationRoleType,
    changes: {
      options: createOptionsFromMap(limaEntityToIndividualRelationRoleType)
    }
  })
};
