import { PosUser } from "@elphi/types";
import { buildAction } from "../../builders/action.builder";
import { ActionDispatcher } from "../../types/action.types";
import { posUserSlice } from "./posUser.slice";

export const posUserAction: ActionDispatcher<PosUser> = (
  dispatch,
  callback
) => {
  const actionDispatcher = buildAction(posUserSlice);
  return actionDispatcher(dispatch, callback);
};
