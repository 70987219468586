import {
  ElphiTrxStatus,
  IntegrationType,
  Property,
  PropertyReportVendorType,
  Task,
  TaskType
} from "@elphi/types";
import { responseHandler } from "../apis/rtk/response.handler";
import { ELPHI_TRX_STATUS_TOOLTIP_MESSAGES } from "../components/task/integrations/integration.type";
import {
  ElphiLocationResponse,
  SITE_X_FAILED_STEP_LABEL_TOOLTIP_MESSAGES,
  SITE_X_OVERRIDE_STEP_LABEL_TOOLTIP_MESSAGES,
  ServiceOption
} from "../components/task/integrations/property-report/propertyReport.type";
import { useElphiToast } from "../components/toast/toast.hook";
import { siteXApi } from "../redux/v2/integration/site-x/site-x.service";
import { usePropertyFormHandler } from "./propertyform.hooks";
import { EMPTY } from "../constants/common";

export const usePropertyReportTaskHooks = () => {
  const { updatePropertyHandler } = usePropertyFormHandler();
  const [createSiteXReportApi, createSiteXReportApiResponse] =
    siteXApi.useCreateReportMutation();
  const [retrySiteXReportApi, retrySiteXReportApiResponse] =
    siteXApi.useRetryOrderReportMutation();
  const { errorToast, successToast } = useElphiToast();

  const getPropertyReportVendorTask = (
    task: Task | undefined,
    vendor: PropertyReportVendorType
  ) =>
    task?.type === TaskType.Integration &&
    task?.integrationType === IntegrationType.PropertyReport &&
    task?.vendorType === vendor
      ? task
      : undefined;

  const handleOnCreateOrder = async (
    propertySelection: ServiceOption,
    selectedProperty: Partial<Property>,
    currentTaskId: string,
    location?: ElphiLocationResponse
  ) => {
    if (
      propertySelection === ServiceOption.SiteXReport &&
      !!selectedProperty?.id
    ) {
      if (selectedProperty) {
        return await createSiteXReportApi({
          propertyId: selectedProperty.id,
          taskId: currentTaskId,
          location
        })
          .then(responseHandler)
          .then((r) => {
            return r;
          });
      }
    }
  };

  const getOrderStatusTooltip = (
    task: Task,
    showOnlyError?: boolean
  ): string | undefined => {
    const currentTask = getPropertyReportVendorTask(
      task,
      PropertyReportVendorType.SiteX
    )!;
    if (currentTask) {
      const { elphiTrxStatus, failedLabel } = currentTask;
      if (elphiTrxStatus === ElphiTrxStatus.Failed) {
        if (failedLabel) {
          return (
            SITE_X_FAILED_STEP_LABEL_TOOLTIP_MESSAGES?.[failedLabel] || EMPTY
          );
        }
        return ELPHI_TRX_STATUS_TOOLTIP_MESSAGES?.[ElphiTrxStatus.Failed];
      }

      if (SITE_X_OVERRIDE_STEP_LABEL_TOOLTIP_MESSAGES?.[elphiTrxStatus]) {
        return SITE_X_OVERRIDE_STEP_LABEL_TOOLTIP_MESSAGES?.[elphiTrxStatus];
      }

      if (!showOnlyError) {
        return (
          ELPHI_TRX_STATUS_TOOLTIP_MESSAGES?.[elphiTrxStatus] ||
          ELPHI_TRX_STATUS_TOOLTIP_MESSAGES?.[ElphiTrxStatus.Pending]
        );
      }
    }
    return undefined;
  };

  const handleOnRetryOrder = async (
    propertySelection: ServiceOption,
    currentTaskId: string
  ) => {
    if (propertySelection === ServiceOption.SiteXReport) {
      return await retrySiteXReportApi({
        taskId: currentTaskId
      })
        .then(responseHandler)
        .then((r) => {
          return r;
        });
    }
  };

  return {
    createSiteXReportApi,
    createSiteXReportApiResponse,
    retrySiteXReportApi,
    retrySiteXReportApiResponse,
    errorToast,
    successToast,
    getPropertyReportVendorTask,
    handleOnCreateOrder,
    handleOnRetryOrder,
    updatePropertiesHandler: updatePropertyHandler,
    getOrderStatusTooltip
  };
};
