import { createIcon } from "@chakra-ui/react";

const customToolsIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 31 31",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.83998 14.7225C9.29498 15.1775 11.08 17.05 11.08 17.05L12.06 16.035L10.52 14.4425L13.4775 11.2925C13.4775 11.2925 12.1475 9.9975 12.725 10.505C13.285 8.4225 12.7775 6.1125 11.2025 4.485C9.62748 2.875 7.40498 2.35 5.40998 2.8925L8.78748 6.3925L7.89498 9.8225L4.58748 10.7325L1.20998 7.2325C0.667479 9.2975 1.17498 11.59 2.74998 13.2C4.39498 14.915 6.75748 15.405 8.83998 14.7225V14.7225ZM20.11 18.1175L16.0325 22.1425L22.7525 29.1075C23.295 29.685 24.03 29.965 24.7475 29.965C25.465 29.965 26.1825 29.685 26.7425 29.1075C27.845 27.97 27.845 26.1325 26.7425 24.995L20.11 18.1175ZM29 6.4275L24.7125 2L12.0775 15.055L13.6175 16.6475L6.07498 24.4525L4.34248 25.38L1.90998 29.3525L2.52248 30L6.37248 27.48L7.26498 25.695L14.825 17.89L16.365 19.4825L29 6.4275Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const ToolsIcon = customToolsIcon();
