import { ActionReducerMapBuilder, EntityState } from "@reduxjs/toolkit";
import { coreModuleName } from "@reduxjs/toolkit/dist/query/core/module";
import { reactHooksModuleName } from "@reduxjs/toolkit/dist/query/react/module";
import {
  Api,
  BaseQueryFn,
  EndpointDefinitions,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import lodash from "lodash";

export type RTKGenericApi<T extends EndpointDefinitions> = Api<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    any,
    FetchBaseQueryMeta
  >,
  T,
  string,
  string,
  typeof coreModuleName | typeof reactHooksModuleName
> & { endpoints: any }; //TODO: @hack should be solved in the future

const crudExtraReducers = <T extends EndpointDefinitions>(
  api: RTKGenericApi<T>
) => {
  return (builder: ActionReducerMapBuilder<EntityState<unknown>>) => {
    builder.addMatcher(
      api.endpoints.get.matchFulfilled,
      (state, { payload }: { payload: any }) => {
        state.ids = lodash.union(state.ids, [payload.id]);
        state.entities = { ...state.entities, [payload.id]: payload };
      }
    ),
      builder.addMatcher(
        api.endpoints.paginate.matchFulfilled,
        (state, { payload }: { payload: any }) => {
          state.ids = lodash.union(state.ids, payload.ids);
          state.entities = { ...state.entities, ...payload.entities };
        }
      );
  };
};
export default { crudExtraReducers };
