import { createIcon } from "@chakra-ui/icons";

const customFileIcon = (stroke?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.49902 9.83325H8.49902"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.49902 13.1667H10.1657"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6657 16.5H0.999023V1.5H7.66569H8.49902L12.6657 5.66667V6.5V16.5Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.66602 1.5V4.83333C7.66602 5.66667 8.49935 6.5 9.33268 6.5C10.166 6.5 11.9716 6.5 12.666 6.5"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      stroke: "#0157FE"
    }
  });

export const FileIcon = customFileIcon();
export const EmptyFileIcon = customFileIcon("#505872");
export const DeleteFileIcon = createIcon({
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 9.5L8 12L5.5 14.5"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50003 9.5L8.00001 12L10.5 14.5"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 19H1V1H9H10L15 6V7V19Z"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 1V5C9 6 10 7 11 7C12 7 14.1667 7 15 7"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
});
