import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { auth } from "../../../firebase/firebaseConfig";
export type HeadersBuilder = (
  headers: Headers,
  api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">
) => MaybePromise<Headers>;

export const prepareHeaders: HeadersBuilder = async (headers, {}) => {
  const token = await auth.currentUser?.getIdToken();
  headers.set("authorization", `Bearer ${token}`);
  return headers;
};
