import { createIcon } from "@chakra-ui/react";

const customUsersIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 28 28",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.50002 22.1666H1.16669V21C1.16669 18.8255 2.65392 16.9983 4.66669 16.4803"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.00002 12.6341C5.64063 12.1537 4.66669 10.8572 4.66669 9.33331C4.66669 7.80938 5.64063 6.51294 7.00002 6.03247"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.5 22.1666H26.8334V21C26.8334 18.8255 25.3461 16.9983 23.3334 16.4803"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 6.03247C22.3594 6.51294 23.3333 7.80938 23.3333 9.33331C23.3333 10.8572 22.3594 12.1537 21 12.6341"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3333 16.3333H11.6667C9.08934 16.3333 7 18.4227 7 21V22.1666H21V21C21 18.4227 18.9106 16.3333 16.3333 16.3333Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 12.8333C15.933 12.8333 17.5 11.2663 17.5 9.33331C17.5 7.40032 15.933 5.83331 14 5.83331C12.0669 5.83331 10.5 7.40032 10.5 9.33331C10.5 11.2663 12.0669 12.8333 14 12.8333Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const UsersIcon = customUsersIcon();
