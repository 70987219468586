import {
  LabelValue,
  replaceCommaWithSemicolon,
  SelectFormatsType,
  Task
} from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { filter, reduce, sortBy, upperCase, values } from "lodash";
import React from "react";
import {
  convertToCSV,
  convertToHtml
} from "../../redux/v2/utils/generate-document.utils";
import { CopyIcon, DownloadIcon, Grid4Icon } from "../icons";
import {
  CellFilterField,
  CellsFilterStateFlat
} from "../table/hooks/filter.hook";
import {
  createTooltipForUserRoles,
  getDocumentStatusFromTask,
  getGenerationTextFromTask,
  getKeyClosingTaskType,
  getModifiedAt
} from "../task/taskPrint.utils";
import { taskRowDataToText } from "../task/taskRowData.utils";
import { useElphiToast } from "../toast/toast.hook";
import {
  getLabelValue,
  saveCSVFile,
  saveTableToClipboard,
  saveToClipboard
} from "./general.utils";

export const filterState: CellsFilterStateFlat<Task> = {
  documentStatus: {
    column: "Document Status",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => getDocumentStatusFromTask(task)
  },
  generation: {
    column: "Generation",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => getGenerationTextFromTask(task, task?.generation)
  },
  taskStatus: {
    column: "Task Status",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => task?.taskStatus || "",
    show: true,
    index: 1
  },
  name: {
    column: "Task Name",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => task?.name,
    show: true,
    index: 0
  },
  entityInformation: {
    column: "Task Entity Information",
    orderBy: "asc",
    query: [],
    getData: ({ task, states }) =>
      taskRowDataToText({
        task,
        props: states
      }),
    show: true,
    index: 2
  },
  entityType: {
    column: "Entity Type",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => task?.entityType
  },
  checklistOf: {
    column: "Assignee Role",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => createTooltipForUserRoles(task?.checklistOf || [])
  },
  posUserType: {
    column: "POS User Type",
    orderBy: "asc",
    query: []
  },
  type: {
    column: "Task Type",
    orderBy: "asc",
    query: []
  },
  keyClosingTaskType: {
    column: "KCT",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => getKeyClosingTaskType(task?.keyClosingTaskType)
  },
  modifiedAt: {
    column: "Modified At",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => getModifiedAt(task)
  },
  createdAt: {
    column: "Created At",
    orderBy: "asc",
    query: []
  },
  entityId: {
    column: "Entity Id",
    orderBy: "asc",
    query: []
  },
  orderStatus: {
    column: "Order Status",
    orderBy: "asc",
    query: []
  },
  comments: {
    column: "Comments",
    orderBy: "asc",
    query: [],
    show: true,
    getData: ({ task }) => task.comments || "",
    index: 3
  }
};

export const transformedTaskEntities = (
  ids: EntityId[],
  taskEntities: {
    [id: string]: Task;
  },
  states: Object
) => {
  if (ids?.length) {
    return ids?.map((taskId: string, index: number) => {
      const task = taskEntities?.[taskId] as Task;
      return reduce(
        sortBy(
          filter(
            values(filterState),
            (filter) => filter?.getData && filter?.show
          ),
          "index"
        ),
        (filterValues, filter: CellFilterField<Task>) => ({
          ...filterValues,
          [upperCase(filter.column)]: replaceCommaWithSemicolon(
            filter.getData!({ task, states })
          )
        }),
        { "": ++index } // row number, without column name
      );
    });
  }
  return "";
};

export const selectFormatsOptions: {
  [key in SelectFormatsType]: LabelValue & {
    icon?: React.ReactElement;
    isDisabled?: boolean;
  };
} = {
  [SelectFormatsType.TableToClipboard]: {
    ...getLabelValue(SelectFormatsType.TableToClipboard),
    icon: <CopyIcon />
  },
  [SelectFormatsType.CSV]: {
    ...getLabelValue(SelectFormatsType.CSV),
    icon: <DownloadIcon />
  },
  [SelectFormatsType.CSVToClipboard]: {
    ...getLabelValue(SelectFormatsType.CSVToClipboard),
    icon: <Grid4Icon />
  }
};

export const exportChecklistActions: {
  [key in SelectFormatsType]: (
    data: Array<Object>,
    options?: {
      successToast?: ReturnType<typeof useElphiToast>["successToast"];
      errorToast?: ReturnType<typeof useElphiToast>["errorToast"];
      onSuccess?: Function;
      onError?: Function;
    }
  ) => void;
} = {
  [SelectFormatsType.TableToClipboard]: (data, options) =>
    saveTableToClipboard(convertToHtml(data), { ...options }),
  [SelectFormatsType.CSVToClipboard]: (data, options) =>
    saveToClipboard(convertToCSV(data), { ...options }),
  [SelectFormatsType.CSV]: (data, options) =>
    saveCSVFile(convertToCSV(data), undefined, { ...options })
};
