import { Box, Button, ListItem, OrderedList, Text } from "@chakra-ui/react";
import { Property, RentRangeRequest } from "@elphi/types";
import elphiTheme from "../../../../../assets/themes/elphi.theme.default";
import { NOT_AVAILABLE } from "../../../../../constants/common";
import ModalContainer from "../../../../modal-container/ModalContainer";

export type RentRangeModalModalProps = {
  property: Partial<Property>;
  data: RentRangeRequest[];
  onClickOrder: () => void;
  onCloseModal: () => void;
  loading: boolean;
  isShow: boolean;
};

export const RentRangeModal = (props: RentRangeModalModalProps) => {
  const { property, isShow, onCloseModal, onClickOrder, loading, data } = props;
  const { Address } = property || {};
  const header = `Rental AVM, Address: ${Address?.AddressLineText} ${Address?.AddressUnitIdentifier}, ${Address?.CityName}, ${Address?.StateCode} ${Address?.PostalCode}`;

  return (
    <ModalContainer
      isShow={isShow}
      header={header}
      body={
        <ModalBody>
          <RentRangeRequestList data={data} />
        </ModalBody>
      }
      onCloseModal={onCloseModal}
      footer={
        <>
          <Button
            {...elphiTheme.components.light.button.primary}
            mr={"15px"}
            isLoading={loading}
            onClick={onClickOrder}
          >
            Confirm
          </Button>
          <Button
            {...elphiTheme.components.light.button.secondary}
            onClick={onCloseModal}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};

const ModalBody = (props: { children: React.ReactNode }) => {
  return (
    <>
      <Box>
        <Text>
          You have already successfully ordered a Rental AVM Report for
          following properties:
        </Text>
        {props.children}
        <Text mt={5} color={"red.400"}>
          This action will incur additional cost from the vendor
        </Text>
      </Box>
    </>
  );
};

const RentRangeRequestList = (props: { data: RentRangeRequest[] }) => {
  return (
    <OrderedList
      sx={{
        maxHeight: "80px",
        overflowY: "auto",
        paddingLeft: "32px",
        marginTop: "10px"
      }}
    >
      {props.data.map((d, i) => {
        return (
          <ListItem key={i}>{`${d?.address || NOT_AVAILABLE}, ${
            d?.city || NOT_AVAILABLE
          }, ${d?.state || NOT_AVAILABLE}, ${
            d?.zipCode || NOT_AVAILABLE
          }`}</ListItem>
        );
      })}
    </OrderedList>
  );
};
