import { take } from "lodash";
import Quill from "quill";
import { RichTextMentionList } from "./richText.types";

export const buildViewModeInstance = (r: {
  ref: HTMLDivElement;
  placeholder: string;
}) => {
  return new Quill(r.ref, {
    theme: "snow",
    placeholder: r.placeholder,
    readOnly: true,
    modules: {
      toolbar: null
    }
  });
};

export const buildRichModeInstance = (r: {
  ref: HTMLDivElement;
  placeholder: string;
  mentionList?: RichTextMentionList;
}) => {
  const mentionList = r.mentionList || [];
  return new Quill(r.ref, {
    theme: "snow",
    placeholder: r.placeholder,
    modules: {
      toolbar: toolbarContainer,
      mention: mentionContainer(mentionList)
    },
    formats
  });
};

// Not support upload image for now
// Should be implemented in server side
const toolbarContainer = [
  [{ header: [1, 2, 3, 4, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["link"], //image
  ["clean"]
];

const mentionContainer = (mentionList: RichTextMentionList) => {
  return {
    mentionDenotationChars: ["@"],
    source: (
      searchTerm: string,
      renderList: (mentionList: RichTextMentionList, searchTerm: any) => void,
      mentionChar: string
    ) => {
      if (mentionChar !== "@") return;
      if (searchTerm.length === 0) {
        renderList(take(mentionList, 10), searchTerm);
        return;
      }
      const filtered = mentionList.filter((item) => {
        return item.value.some((v) =>
          v.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      renderList(filtered, searchTerm);
    }
  };
};

const formats = [
  "background",
  "bold",
  "color",
  "font",
  //"code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "mention"
  //"code-block",
  //"formula"
  //"image"
  //"video"
];
