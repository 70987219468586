import { Dispatch } from "redux";
import { resetUserData } from "../../redux/actions";

export interface Dispatcher<D> {
  [key: string]: (r: D) => void;
}

export type BaseDispatcher<T extends (dispatch: Dispatch) => Dispatcher<any>> =
  { dispatchers: T };

export type DispatchBuilder = BaseDispatcher<{
  (dispatch: Dispatch): Dispatcher<any>;
}>;

export const userDispatcher: DispatchBuilder = {
  dispatchers: (dispatch: Dispatch) => {
    return {
      resetUserData: () => {
        dispatch(resetUserData());
      }
    };
  }
};
