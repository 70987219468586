import { ElphiTrxStatus, SiteXFailedStepLabel } from "@elphi/types";

export enum ServiceOption {
  SiteXReport = "SiteX Report"
}

export const SITE_X_OVERRIDE_STEP_LABEL_TOOLTIP_MESSAGES: {
  [key in ElphiTrxStatus]?: string;
} = {
  [ElphiTrxStatus.Success]:
    "Requested data and documents were successfully retrieved."
};

export const SITE_X_FAILED_STEP_LABEL_TOOLTIP_MESSAGES: {
  [key in SiteXFailedStepLabel]: string;
} = {
  propertyNotFound:
    "Failed to place the order. there was an issue with SiteX, property not found.",
  failedFetchProperty:
    "Failed to place the order. Please complete all mandatory fields, and click Order again. If the issue persists, contact Elphi and SiteX.",
  failedMultiLocations: "",
  failedOrder:
    "Failed to place the order. Please complete all mandatory fields, and click Order again. If the issue persists, contact Elphi and SiteX.",
  failedSiteXMissReport:
    "A Property Report was supposed to be received, but there was an issue with SiteX, please try to order again, and if the issue persists, contact SiteX.",
  failedUploadingReport:
    "File was received, but failed to upload. Please click Retry to solve the problem. If the issue persists, contact Elphi"
};

export type ElphiLocationResponse = {
  fips?: string;
  assessorParcelNumber?: string;
  addressLineText?: string;
  addressUnitIdentifier?: string;
  cityName?: string;
  stateCode?: string;
  postalCode?: string;
};
