import { SystemSetup } from "../../../common";
import { elphiDealSchema } from "../../base/deal.typebox";
import { limaDealSchema } from "../../lima/deal.typebox";
import { mofinDealSchema } from "../../mofin/deal.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type DealEnvToSchemaMap = EnvToSchemasMap;
export const dealSchemas: DealEnvToSchemaMap = {
  [SystemSetup.Elphi]: elphiDealSchema,
  [SystemSetup.Lima]: limaDealSchema,
  [SystemSetup.Mofin]: mofinDealSchema,
  [SystemSetup.LedgerTC]: elphiDealSchema
};
