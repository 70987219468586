import { PartyRelation } from "@elphi/types";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RootState } from "../redux/store";
import { partyRelationApi } from "../redux/v2/party-relation/partyRelation.service";

export const usePartyRelationHooks = () => {
  const [deletePartyRelationApi, deletePartyRelationResponse] =
    partyRelationApi.useDeleteMutation();
  const [updatePartyRelationApi, updatePartyRelationResponse] =
    partyRelationApi.useUpdateMutation();

  const [updateBatchApi, updateBatchResponse] =
    partyRelationApi.useBatchUpdateMutation();

  const deletePartyRelation = async (id: string) => {
    return await deletePartyRelationApi(id).then(responseHandler);
  };
  const updatePartyRelation = async (
    r: { id: string } & Partial<PartyRelation>
  ) => {
    return await updatePartyRelationApi(r).then(responseHandler);
  };
  const updateBatch = async (r: {
    relations: ({ id: string } & Partial<PartyRelation>)[];
  }) => {
    return await updateBatchApi(r).then(responseHandler);
  };
  const partyRelationState = useSelector(
    (state: RootState) => state.partyRelation
  );

  return {
    partyRelationState,
    updateBatch,
    updateBatchResponse,
    deletePartyRelationResponse,
    deletePartyRelation,
    updatePartyRelation,
    updatePartyRelationResponse
  };
};
