import { FirebaseCollections } from "../db";
import { ServiceContext } from "../services/service.types";
import { Flatten } from "../utils/flatten";

export interface AggregationBuilder<
  T extends object,
  TAggregationFields extends object
> {
  name: string;
  buildPayload: (
    context: ServiceContext
  ) => (r: {
    data: T;
  }) => Promise<AggregationUpdateObject<TAggregationFields>[] | null>;
}
export type AggregationTriggerFields<
  T extends object,
  TAggregationFields extends object
> = Partial<Flatten<T, AggregationBuilder<T, TAggregationFields>[]>>;

export type AggregationFieldFlatType<TAggregationFields extends object> =
  Partial<Flatten<TAggregationFields, AggregationField>>;

export type AggregationFieldsType<TAggregationFields extends object> = {
  aggregations: AggregationFieldFlatType<TAggregationFields>;
  quote: {
    aggregations: AggregationFieldFlatType<TAggregationFields>;
  };
};
export type AggregationUpdateObject<TAggregationFields extends object> = {
  collection: FirebaseCollections;
  id: string;
  fields: Partial<AggregationFieldsType<TAggregationFields>>;
};

export const FOCUSED = "focused";
export const AGGREGATIONS = "aggregations";

export type ThirdPartyField = {
  override?: string;
  thirdParty?: string;
  focused?: "override" | "thirdParty";
};
export type DefaultAggregation = {
  override?: string;
  calculated?: string;
  thirdParty?: string;
  focused?: "override" | "calculated" | "thirdParty";
};

export type AggregationField = {
  override?: string;
  calculated?: string;
  focused?: "override" | "calculated";
};

export type ThirdPartyAggregationField = Omit<
  AggregationField & ThirdPartyField,
  "focused"
> & { focused?: "override" | "calculated" | "thirdParty" };

export enum AggregationFocusType {
  Override = "override",
  Calculated = "calculated",
  None = "",
  ThirdParty = "thirdParty"
}

export enum AggregationType {
  ThirdParty = "thirdParty",
  Aggregation = "aggregation",
  ThirdPartyAggregation = "thirdPartyAggregation"
}

export type AnyAggregationField =
  | AggregationField
  | ThirdPartyAggregationField
  | ThirdPartyField;

export const aggregationFieldTemplate = (): AggregationField => ({
  calculated: "",
  focused: "calculated",
  override: ""
});

export const thirdPartyFieldTemplate = (r?: {
  defaultFocus: ThirdPartyField["focused"];
}): ThirdPartyField => ({
  thirdParty: "",
  focused: r?.defaultFocus || "thirdParty",
  override: ""
});
