import { OmitUpdate, PartyAsset } from "@elphi/types";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RootState } from "../redux/store";
import { partyAssetApi } from "../redux/v2/party-asset-relation/partyAssetRelation.service";
export const usePartyAssetHooks = () => {
  const [deletePartyAssetRelationApi, deletePartyAssetRelationResponse] =
    partyAssetApi.useDeleteMutation();
  const [updatePartyAssetRelationApi, updatePartyAssetRelationResponse] =
    partyAssetApi.useUpdateMutation();
  const [createPartyAssetRelationApi, createPartyAssetRelationResponse] =
    partyAssetApi.useCreateMutation();
  const partyAssetState = useSelector((state: RootState) => state.partyAsset);

  const deletePartyAssetRelation = async (id: string) => {
    return await deletePartyAssetRelationApi(id).then(responseHandler);
  };
  const updatePartyAssetRelation = async (
    r: { id: string } & Partial<PartyAsset>
  ) => {
    return await updatePartyAssetRelationApi(r).then(responseHandler);
  };
  const createPartyAssetRelation = async (partyId: string, assetId: string) => {
    const partyAsset: OmitUpdate<PartyAsset> = {
      partyId,
      assetId,
      id: `${partyId}_${assetId}`
    };
    return await createPartyAssetRelationApi(partyAsset).then((r) =>
      responseHandler<{ id: string }>(r)
    );
  };

  return {
    partyAssetState,
    deletePartyAssetRelationResponse,
    updatePartyAssetRelationResponse,
    createPartyAssetRelationResponse,
    deletePartyAssetRelation,
    updatePartyAssetRelation,
    createPartyAssetRelation
  };
};
