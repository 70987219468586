import { FieldGroup } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { EMPTY } from "../../../constants/common";
import sliceBuilder from "../builders/slice.builder";
import { FiltersState } from "../types/stateWithFilter.types";
import {
  initCombination,
  setCombinationInfo,
  setFilterAndCombination
} from "../utils/stateFilter.utils";
import { fieldGroupAdapter } from "./fieldGroup.adapter";
import { fieldGroupApi } from "./fieldGroup.service";

export type FieldGroupSliceState = EntityState<FieldGroup> & {
  selectedId?: EntityId;
  filters: FiltersState<
    FieldGroup,
    "name" | "status" | "taskConfigurationIds" | "integrationStatuses"
  >;
};

const initialState: FieldGroupSliceState = {
  ids: [],
  entities: {},
  selectedId: undefined,
  filters: {
    current: {
      name: [],
      status: [],
      taskConfigurationIds: [],
      integrationStatuses: []
    },
    combinations: {
      [EMPTY]: initCombination()
    },
    currentCombination: EMPTY
  }
};

export const fieldGroupSlice = createSlice({
  name: "fieldGroup",
  initialState: fieldGroupAdapter.getInitialState(initialState),
  reducers: {
    update: fieldGroupAdapter.updateOne,
    remove: fieldGroupAdapter.removeOne,
    add: fieldGroupAdapter.addOne,
    upsert: fieldGroupAdapter.upsertOne,
    upsertMany: fieldGroupAdapter.upsertMany,
    removeMany: fieldGroupAdapter.removeMany,
    updateMany: fieldGroupAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    setFilterAndCombination: (
      state,
      action: PayloadAction<{
        combination: string;
        filters: FieldGroupSliceState["filters"];
      }>
    ) => setFilterAndCombination(state, action),

    setFilterCombinationDetails: (
      state,
      action: PayloadAction<
        FieldGroupSliceState["filters"]["combinations"][string]
      >
    ) => setCombinationInfo(state, action)
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(fieldGroupApi)(builder);
    builder.addMatcher(
      fieldGroupApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      fieldGroupApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page, "id")
        };
      }
    );
    builder.addMatcher(
      fieldGroupApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
  }
});
