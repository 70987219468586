import { createIcon } from "@chakra-ui/icons";
export const ArrowDown = createIcon({
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 9V1V9Z" fill="#1A202C" />
      <path
        d="M4 9V1"
        stroke="#1A202C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6L4 9L7 6"
        stroke="#1A202C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
});
