import { PropertyInsurancePolicyCoverageType } from "@elphi/types";

export const propertyInsurancePolicyCoverageTypeOptions = {
  [PropertyInsurancePolicyCoverageType.BuildersRisk]: "Builder’s Risk",
  [PropertyInsurancePolicyCoverageType.CAFairPlan]: "CA Fair Plan",
  [PropertyInsurancePolicyCoverageType.CondoMasterFlood]: "Condo Master Flood",
  [PropertyInsurancePolicyCoverageType.CondoMasterHazard]:
    "Condo Master Hazard",
  [PropertyInsurancePolicyCoverageType.Earthquake]: "Earthquake",
  [PropertyInsurancePolicyCoverageType.ExcessFlood]: "Excess Flood",
  [PropertyInsurancePolicyCoverageType.Flood]: "Flood",
  [PropertyInsurancePolicyCoverageType.Hazard]: "Hazard",
  [PropertyInsurancePolicyCoverageType.HO6]: "HO6",
  [PropertyInsurancePolicyCoverageType.Lava]: "Lava",
  [PropertyInsurancePolicyCoverageType.Liability]: "Liability",
  [PropertyInsurancePolicyCoverageType.RentLoss]: "Rent Loss",
  [PropertyInsurancePolicyCoverageType.Wind]: "Wind"
};
