// Need to use the React-specific entry point to allow generating React hooks
import { TaskConfiguration } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { RootState } from "../../store";
import serviceBuilder from "../builders/api.builder";
import { taskConfigurationEntityAdapter } from "./taskConfiguration.adapter";

export const taskConfigurationSelector =
  taskConfigurationEntityAdapter.getSelectors<RootState>(
    (state) => state.taskConfiguration
  );

// Define a service using a base URL and expected endpoints
export const baseTaskConfigurationApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "taskConfigurationApi",
  tagTypes: ["Task", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "task-configuration"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: taskConfigurationEntityAdapter
  })
});

export const taskConfigurationApi = baseTaskConfigurationApi.injectEndpoints({
  endpoints: (builder) => ({
    batchUpdate: builder.mutation<
      { batch: string[] },
      { tasks: ({ id: string } & Partial<TaskConfiguration>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    search: builder.query<
      {
        results: EntityState<TaskConfiguration>;
        hasMore: boolean;
        nextCursor: string | null;
        query: string;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: TaskConfiguration[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        const adapter = taskConfigurationEntityAdapter.addMany(
          taskConfigurationEntityAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          hasMore: response.hasMore,
          nextCursor: response.nextCursor
        };
      }
    }),
    getBatch: builder.query<
      { batch: EntityState<TaskConfiguration> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: TaskConfiguration[] }) => {
        const adapter = taskConfigurationEntityAdapter.addMany(
          taskConfigurationEntityAdapter.getInitialState(),
          response.results
        );

        return { batch: adapter };
      }
    })
  })
});
