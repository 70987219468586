import { BaseEntity, FieldType } from "@elphi/types";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "./fields.types";

export const baseEntityFormFieldSpecs: EntityFormFieldSpecs<
  SpecsBaseOmit<BaseEntity<object>>
> = {
  id: {
    fieldKey: ["id"],
    fieldType: FieldType.String,
    label: "ID"
  },
  modifiedAt: {
    fieldKey: ["modifiedAt"],
    fieldType: FieldType.Date,
    label: "modified at"
  },
  createdAt: {
    fieldKey: ["createdAt"],
    fieldType: FieldType.Date,
    label: "created at"
  }
};
