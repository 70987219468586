import { LoanpassEventObject, MappingDirection } from "@elphi/types";
import { BaseSchemaDeal } from "@elphi/types/entities/base";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityId, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { union, values } from "lodash";
import { loanpassAdapter } from "./loanpass.adapter";
import { loanpassApi } from "./loanpass.service";

type LoanpassSliceState = {
  pipelineRecords: {
    name: string;
    id: string;
  }[];
  selectedPipelineRecordId?: EntityId;
  loampassToElphiMapping?: {
    messageType: LoanpassEventObject["message"];
    deal: Partial<BaseSchemaDeal>;
  };
  isDefaultPipelineRecordAdded: boolean;
};

export const DEFAULT_PIPELINE_RECORD = {
  id: "noExistingPipelineRecord",
  name: "No Existing Pipeline Record"
};

const initialState: LoanpassSliceState = {
  pipelineRecords: [],
  selectedPipelineRecordId: undefined,
  loampassToElphiMapping: undefined,
  isDefaultPipelineRecordAdded: false
};

export const loanpassSlice = createSlice({
  name: "loanpass",
  initialState: loanpassAdapter.getInitialState(initialState),
  reducers: {
    selectPipelineRecod: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedPipelineRecordId = action.payload.id;
    },
    removeMappedDeal: (state) => {
      state.loampassToElphiMapping = undefined;
    },
    addDefaultPipelineRecord: (state) => {
      state.pipelineRecords = union(state.pipelineRecords, [
        DEFAULT_PIPELINE_RECORD
      ]);
      state.isDefaultPipelineRecordAdded = true;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      loanpassApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.pipelineRecords = union(
          state.pipelineRecords,
          values(payload.results.entities).filter(removeEmpty)
        );
      }
    ),
      builder.addMatcher(
        loanpassApi.endpoints.mapFields.matchFulfilled,
        (state, { payload }) => {
          if (payload.direction === MappingDirection.In) {
            state.loampassToElphiMapping = {
              messageType: payload.requestType,
              deal: payload.deal
            };
          }
        }
      );
  }
});
