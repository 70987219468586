import { BaseInsurancePolicy, InsurancePolicyEntityType } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import { PropertyInsurancePolicyState } from "../../../../components/insurance-policy/property/wizard/steps/types/insurancePolicySteps.types";
import { addRulesToInputs, buildSection } from "../../utils/formBuilder.utils";
import {
  buildersRiskCoverageAmount,
  buildingMailingAddress,
  buildingType,
  cAFairPlanCoverageAmount,
  condoMasterFloodCoverageAmount,
  condoMasterHazardCoverageAmount,
  coverageTypes,
  earthquakeCoverageAmount,
  effectiveDate,
  excessFlood,
  expirationDate,
  floodCoverageAmount,
  hO6CoverageAmount,
  hazardCoverageAmount,
  hazardReplacementCoverage,
  insuranceCarrier,
  insurancePremiumPaymentAmount,
  lavaCoverageAmount,
  liabilityCoverageAmount,
  nextPaymentDueDate,
  outstandingPremiumAmount,
  outstandingPremiumStatus,
  policyDeductibleAmount,
  policyNumber,
  policyPremiumAmount,
  rentLossCoverageAmount,
  windCoverageAmount
} from "../inputs/insurancePolicy.inputs";
import {
  BaseSchemaInsurancePolicySections,
  BaseSchemaSectionsBuilders
} from "../types";

const propertyInsurancePolicyCreateSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseInsurancePolicy<InsurancePolicyEntityType>>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "",
      inputs: [
        {
          ...policyNumber,
          isRequired: true,
          validation: (v) => !!coverageTypes.validation?.(v, true)
        },
        {
          ...coverageTypes,
          isRequired: true,
          validation: (v) => !!coverageTypes.validation?.(v, true)
        },
        insuranceCarrier,
        effectiveDate,
        expirationDate,
        nextPaymentDueDate,
        policyDeductibleAmount,
        outstandingPremiumStatus,
        outstandingPremiumAmount,
        insurancePremiumPaymentAmount
      ],
      hideAttachedComponent: true,
      fieldSpecs
    });

const propertyInsurancePolicyStaticFieldsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseInsurancePolicy<InsurancePolicyEntityType>>;
    prefix: string[];
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "",
      inputs: [policyPremiumAmount],
      hideAttachedComponent: true,
      fieldSpecs,
      prefixPathToValue: r.prefix
    });

const propertyInsurancePolicyCoveragesDataSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<PropertyInsurancePolicyState>;
    onChange?: (v: OnChangeInput) => void;
    prefix: string[];
    isReadOnly: boolean;
    visibleFields?: string[];
  }): Section => {
    const { visibleFields } = r;
    const sections = buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "",
      inputs: addRulesToInputs({
        inputs: [
          cAFairPlanCoverageAmount,
          hO6CoverageAmount,
          buildersRiskCoverageAmount,
          condoMasterFloodCoverageAmount,
          condoMasterHazardCoverageAmount,
          earthquakeCoverageAmount,
          excessFlood,
          floodCoverageAmount,
          hazardCoverageAmount,
          hazardReplacementCoverage,
          lavaCoverageAmount,
          liabilityCoverageAmount,
          rentLossCoverageAmount,
          windCoverageAmount
        ],
        state: r.state,
        rules: [
          {
            field: "isHidden",
            predicate: (r) => {
              const isHidden = visibleFields
                ? r.input?.path &&
                  !visibleFields.includes(r.input?.path?.join("."))
                : false;
              return !!isHidden;
            }
          }
        ]
      }),
      prefixPathToValue: r.prefix,
      hideAttachedComponent: true,
      fieldSpecs
    });
    return sections;
  };

const propertyBuildingsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<PropertyInsurancePolicyState>;
    onChange: (v: OnChangeInput) => void;
    prefix: string[];
    isReadOnly: boolean;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "",
      inputs: addRulesToInputs({
        inputs: [buildingMailingAddress, buildingType],
        state: r.state,
        rules: [
          {
            field: "isReadOnly",
            predicate: () => r.isReadOnly
          }
        ]
      }),
      hideAttachedComponent: true,
      fieldSpecs,
      prefixPathToValue: r.prefix
    });

export const insurancePolicyBuilders: BaseSchemaSectionsBuilders<BaseSchemaInsurancePolicySections> =
  {
    propertyInsurancePolicyCreateSection,
    propertyInsurancePolicyCoveragesDataSection,
    propertyInsurancePolicyStaticFieldsSection,
    propertyBuildingsSection
  };
