import { RolodexConfiguration } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../../builders/slice.builder";
import { serviceDomainConfigurationApi } from "../service-domain-configuration/serviceDomainConfiguration.service";
import { serviceDomainConfigurationAdapter as entityAdapter } from "./serviceDomainConfiguration.adapter";

export type ServiceDomainConfigurationSliceState =
  EntityState<RolodexConfiguration> & {
    selectedId?: EntityId;
  };

const initialState: ServiceDomainConfigurationSliceState = {
  ids: [],
  entities: {},
  selectedId: undefined
};

export const serviceDomainConfigurationSlice = createSlice({
  name: "serviceDomainConfiguration",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(serviceDomainConfigurationApi)(builder);
    builder.addMatcher(
      serviceDomainConfigurationApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
  }
});
