import {
  AggregationType,
  IntegrationType,
  RentalDataVendorType
} from "@elphi/types";
import {
  numberValidation,
  stringValidation
} from "../../../../../components/utils/validationUtils";
import { buildInput } from "../../../utils/formBuilder.utils";
import { integrationSpecs } from "../../spec-files/integrationFieldsSpecs";

const { spec } = integrationSpecs.property.RentalData.RentRange;

export const confidenceScore = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.RentalData]?.[
      RentalDataVendorType.RentRange
    ]?.confidenceScore?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.ThirdParty
});

export const orderStatus = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.RentalData]?.[
      RentalDataVendorType.RentRange
    ]?.orderStatus?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const errorMessage = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.RentalData]?.[
      RentalDataVendorType.RentRange
    ]?.errorMessage?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const requestId = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.RentalData]?.[
      RentalDataVendorType.RentRange
    ]?.requestId?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});
