import { createIcon } from "@chakra-ui/react";

const customIcon = (fill?: string, innerElementsColor?: string) =>
  createIcon({
    viewBox: "0 0 18 18",
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_773_97345)">
          <rect width="16" height="16" rx="8" fill={fill} />
          <g clipPath="url(#clip1_773_97345)">
            <path
              d="M4.66797 5.91667L8.0013 4.25L11.3346 5.91667C11.3346 8.0805 10.1745 11.1291 8.0013 11.75C5.82809 11.1291 4.66797 8.0805 4.66797 5.91667Z"
              stroke={innerElementsColor || "#22543D"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g clipPath="url(#clip2_773_97345)">
            <path
              d="M9.33465 7.16663L7.50132 8.99996L6.66797 8.16663"
              stroke={innerElementsColor || "#505872"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_773_97345">
            <rect width="16" height="16" fill="white" />
          </clipPath>
          <clipPath id="clip1_773_97345">
            <rect
              width="10"
              height="10"
              fill="white"
              transform="translate(3 3)"
            />
          </clipPath>
          <clipPath id="clip2_773_97345">
            <rect
              width="4"
              height="4"
              fill="white"
              transform="translate(6 6)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const ShieldSucceededIcon = customIcon();

export const ShieldPurpleIcon = customIcon("#E9D8FD", "#44337A");
