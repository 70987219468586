import { DealCalendar } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { dealCalendarSlice } from "./dealCalendar.slice";

export const dealCalendarAction: ActionDispatcher<DealCalendar> = (
  dispatch,
  callback
) => {
  const actionDispatcher = buildAction(dealCalendarSlice);
  return actionDispatcher(dispatch, callback);
};
