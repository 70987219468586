import { merge } from "lodash";
import { SystemSetup } from "../../common";
import { ElphiEntityType } from "../elphiEntities.types";
import { getBaseSchemaDealTemplate } from "./base-schema/deal.template";
import { getBaseSchemaPropertyTemplate } from "./base-schema/property.template";

type TemplateMap = Partial<
  Record<ElphiEntityType, Partial<Record<SystemSetup, () => object>>>
>;
const templatesMap: TemplateMap = {
  [ElphiEntityType.deal]: {
    [SystemSetup.Mofin]: getBaseSchemaDealTemplate,
    [SystemSetup.Elphi]: getBaseSchemaDealTemplate
  },
  [ElphiEntityType.property]: {
    [SystemSetup.Elphi]: getBaseSchemaPropertyTemplate,
    [SystemSetup.Mofin]: getBaseSchemaPropertyTemplate
  }
};

export const getEntityTemplate = <T extends object>(r: {
  entityType: ElphiEntityType;
  systemSetup: SystemSetup;
  baseEntityTemplate: T;
}) => {
  const { entityType, systemSetup, baseEntityTemplate } = r;
  const getTemplate = templatesMap?.[entityType]?.[systemSetup];
  if (!getTemplate) {
    console.warn(
      "No template found for entity:",
      entityType,
      systemSetup,
      "using default template"
    );
    return baseEntityTemplate;
  }
  const template = getTemplate();
  return merge({}, baseEntityTemplate, template);
};
