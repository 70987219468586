import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { RolodexServiceProviderCard } from "./list-card/serviceProviderCard.types";
import { serviceProviderListHooks } from "./list-card/serviceProviderList.hooks";

export const serviceProviderContainerHooks = () => {
  const { setCurrentFilter, currentFilter, getAllHandler, getAllResponse } =
    serviceProviderListHooks();
  const { isOpen: isOpenAddModal, onToggle: onToggleAddModal } =
    useDisclosure();
  const { isOpen: isOpenEditModal, onToggle: onToggleEditModal } =
    useDisclosure();
  const { isOpen: isOpenRemoveModal, onToggle: onToggleRemoveModal } =
    useDisclosure();
  const [selectedProvider, setSelectedProvider] =
    useState<RolodexServiceProviderCard>();

  const handleToggleEditModal = (selected: RolodexServiceProviderCard) => {
    setSelectedProvider(selected);
    onToggleEditModal();
  };

  const handleToggleRemoveModal = (selected: RolodexServiceProviderCard) => {
    setSelectedProvider(selected);
    onToggleRemoveModal();
  };

  const isLoading = getAllResponse.isLoading || getAllResponse.isFetching;

  return {
    setCurrentFilter,
    currentFilter,
    getAllHandler,
    isOpenAddModal,
    isOpenEditModal,
    isOpenRemoveModal,
    onToggleAddModal,
    onToggleRemoveModal,
    onToggleEditModal,
    handleToggleEditModal,
    handleToggleRemoveModal,
    setSelectedProvider,
    selectedProvider,
    isLoading
  };
};
