import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NavigateHandler = (props: { location: string }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${props.location}`, { replace: true });
  }, []);
  return <></>;
};
