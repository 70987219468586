import {
  FloodCertificateVendorType,
  IntegrationType,
  PricingVendorType
} from "@elphi/types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import { merge } from "lodash";
import { baseSectionsLoader } from "../base/baseSectionsLoader";
import {
  getDealIntegrationsSections,
  getPropertyIntegrationsSections
} from "../integrations/sections";
import { SectionsLoader } from "../types/dynamicSchema.types";
import {
  dealSectionBuilders,
  entityPartySectionBuilders,
  individualPartySectionBuilders,
  propertySectionBuilders
} from "./sections";
import { MofinSpecs } from "./types";

export const mofinSectionsLoader: SectionsLoader = (r: {
  specs: MofinSpecs;
}) => ({
  loadSections: () => {
    const { specs } = r;
    const baseSections = baseSectionsLoader(r).loadSections();

    const integrations = {
      deal: buildDealIntegrations(specs.deal.entitySpecs),
      property: buildPropertyIntegrations(specs.property.entitySpecs)
    };

    return {
      ...baseSections,
      party: {
        ...baseSections.party,
        ...merge(entityPartySectionBuilders, individualPartySectionBuilders)
      },
      deal: {
        ...baseSections.deal,
        ...dealSectionBuilders
      },
      property: {
        ...baseSections.property,
        ...propertySectionBuilders
      },
      integrations
    };
  }
});

const buildPropertyIntegrations = (
  propertySpecs: MofinSpecs["property"]["entitySpecs"]
) => {
  const { serviceLinkFloodSections } =
    getPropertyIntegrationsSections(propertySpecs);

  return {
    [IntegrationType.FloodCertificate]: {
      [FloodCertificateVendorType.ServiceLink]: {
        ...serviceLinkFloodSections
      }
    }
  };
};

const buildDealIntegrations = (
  dealSpecs: MofinSpecs["deal"]["entitySpecs"]
) => {
  const {
    pricingLoanpassSections,
    fundingShieldWireInsuranceCertificateSections
  } = getDealIntegrationsSections(dealSpecs);

  return {
    [IntegrationType.WireInsuranceCertificate]: {
      [WireInsuranceVendorType.FundingShield]: {
        ...fundingShieldWireInsuranceCertificateSections
      }
    },
    [IntegrationType.Pricing]: {
      [PricingVendorType.Loanpass]: {
        ...pricingLoanpassSections
      }
    }
  };
};
