import { TaskConfiguration } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { taskConfigurationSlice } from "./taskConfiguration.slice";

export const taskConfigurationAction: ActionDispatcher<TaskConfiguration> = (
  dispatch,
  callback
) => {
  const slice = taskConfigurationSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

export default taskConfigurationAction;
