import { Type } from "@sinclair/typebox";
import { ExceptionApprovedBy, ExceptionType } from "../entities";
import { BooleanField, EnumField, StringField } from "./utils.typebox";

export const FieldExceptionTemplateSchema = Type.Object({
  ExceptionStatus: BooleanField,
  ExceptionType: EnumField(ExceptionType),
  PolicySection: StringField,
  ExceptionCommentary: StringField,
  ExceptionRiskMitigants: StringField,
  ExceptionApprovedBy: EnumField(ExceptionApprovedBy)
});
