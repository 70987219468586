import { PropertyInsurancePolicyView } from "@elphi/types";
import { MaximizedInfo } from "../../../../common-components/card";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { usePropertyHooks } from "../../../../hooks/property.hooks";
import { MaximizedProviderInfo } from "../../../rolodex/service-provider/list-card/card/ServiceProviderCard";
import { MaximaizedPropertyCoveragesInfo } from "./MaximaizedCoveragesInfo";

export const PropertyInsurancePolicyMaximizedView = (props: {
  view: PropertyInsurancePolicyView;
}) => {
  const { selectedProperty } = usePropertyHooks();
  const propertyId = selectedProperty?.id || EMPTY;
  const { view } = props;
  const policyPremiumAmount = view.entities?.[propertyId]?.policyPremiumAmount;
  return (
    <>
      <MaximizedInfo
        title={"Service Domain"}
        subTitle={view.domainConfiguration.name}
      />
      <MaximizedProviderInfo
        title={"Company"}
        name={view.providers.company.name || NOT_AVAILABLE}
        status={view.providers.company.status}
      />
      <MaximizedProviderInfo
        title={"Branch"}
        name={view.providers.branch.name || NOT_AVAILABLE}
        status={view.providers.branch.status}
      />
      <MaximizedProviderInfo
        title={"Representative"}
        name={view.providers.rep.name || NOT_AVAILABLE}
        status={view.providers.rep.status}
      />
      <MaximizedInfo
        title={"Policy Premium Amount"}
        subTitle={
          policyPremiumAmount ? `$${policyPremiumAmount}` : NOT_AVAILABLE
        }
      />
      <MaximaizedPropertyCoveragesInfo propertyId={propertyId} view={view} />
    </>
  );
};
