import { createIcon } from "@chakra-ui/icons";

export const FlagIcon = createIcon({
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2.11111C1 2.11111 4 -0.388889 9 2.11111C14 4.61111 17 2.11111 17 2.11111V14.1111C17 14.1111 14 16.6111 9 14.1111C4 11.6111 1 14.1111 1 14.1111"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19.1111V14.1111V2.11108"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
});
