import { DefaultCreditReport } from "./elphi.creditReport.types";
import { LimaCreditReport } from "./lima.creditReport.types";

export enum CreditReportVendorType {
  Lima = "lima",
  Default = "default"
}

export type BaseCreditReportVendor<T extends CreditReportVendorType> = {
  type: T;
};

export type CreditReportVendor = LimaCreditReport | DefaultCreditReport;
