import { BaseSchemaDeal } from "@elphi/types/entities/base";
import {
  OnChangeInput,
  Section
} from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { EMPTY } from "../../../../../constants/common";
import { buildSection } from "../../../utils/formBuilder.utils";
import {
  adjustedPrice,
  adjustedRate,
  appraisedValue,
  baseLoanAmount,
  citizenshipType,
  decisionCreditScore,
  expirationDate,
  investorName,
  loanPurpose,
  lockPeriod,
  lockStatus,
  mortgageLatePayments,
  pipelineRecordId,
  pipelineRecordName,
  prepaymentPenaltyTerm,
  priceAdjustments,
  productName,
  propertyType,
  requestDate,
  stateCode,
  stipulations,
  totalLLPA
} from "../../inputs/pricing/loanpass.inputs";

const loanPassSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  <T extends BaseSchemaDeal>(r: {
    state: Partial<T>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Pricing & Secondary",
      inputs: [
        pipelineRecordId,
        pipelineRecordName,
        investorName,
        productName,
        loanPurpose,
        propertyType,
        stateCode,
        appraisedValue,
        decisionCreditScore,
        citizenshipType,
        mortgageLatePayments,
        baseLoanAmount,
        adjustedRate,
        adjustedPrice,
        priceAdjustments,
        lockStatus,
        requestDate,
        expirationDate,
        lockPeriod,
        prepaymentPenaltyTerm,
        stipulations,
        totalLLPA
      ],
      fieldSpecs
    });

const loanPassPipelineRecordSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  <T extends BaseSchemaDeal>(r: {
    state: Partial<T>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: EMPTY,
      inputs: [pipelineRecordId],
      fieldSpecs
    });

export const loanpassSectionBuilders = {
  loanPassSection,
  loanPassPipelineRecordSection
};
