import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const widthOffset = (o: number) => {
    const offset = windowDimensions.width - o;
    return offset;
  };

  const heightOffset = (o: number) => {
    const offset = windowDimensions.height - o;
    return offset;
  };
  const widthOffsetInPx = (o: number) => {
    const offset = windowDimensions.width - o;
    return `${offset}px`;
  };

  const heightOffsetInPx = (o: number) => {
    const offset = windowDimensions.height - o;
    return `${offset}px`;
  };
  return {
    width: windowDimensions.width,
    height: windowDimensions.height,
    widthOffset,
    heightOffset,
    widthOffsetInPx,
    heightOffsetInPx
  };
}
