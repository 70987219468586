import {
  ChangeReasonMilestones,
  DealMilestoneType,
  UnionToObject
} from "../../types";

const changeReasonMilestonesMap: UnionToObject<ChangeReasonMilestones> = {
  [DealMilestoneType.DeadDeal]: DealMilestoneType.DeadDeal,
  [DealMilestoneType.Suspended]: DealMilestoneType.Suspended
};
export const changeReasonMilestones = Object.values(changeReasonMilestonesMap);
