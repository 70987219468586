import { FirebaseFilter } from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import lodash from "lodash";
import { useEffect, useRef, useState } from "react";
import firebase, { auth, firestore } from "../firebase/firebaseConfig";
import { ActionHandler } from "../redux/v2/types/action.types";
import { ElphiDomain } from "../utils/envUtils";
import { useOrgHooks } from "./org.hooks";

const shouldLog = window.location.hostname !== ElphiDomain.LimaProd;

export type BaseListener<T extends { id: EntityId }> = {
  collection: string;
  actionHandler: ActionHandler<T>;
  filter?: FirebaseFilter;
};
const initTimestamp = firebase.firestore.Timestamp.now().toDate();
export type BatchedDataActions = {
  removed: string[];
  modified: {
    [id: string]: { id: string; changes: firebase.firestore.DocumentData };
  };
  added: firebase.firestore.DocumentData[];
};
export type ListenerBatchedActions = {
  [collection: string]: {
    listener: BaseListener<any>;
    batchedData: BatchedDataActions;
  };
};

export type FirestoreListenerHookSettings = {
  operation: "debounce" | "throttle";
  wait: number;
} & {
  debounce?: lodash.DebounceSettings;
  throttle?: lodash.ThrottleSettings;
};
export type FirestoreListenerHookProps = {
  listeners: BaseListener<any>[];
  shouldListen: boolean;
  options?: {
    listener?: {
      settings?: FirestoreListenerHookSettings;
    };
  };
};
export const useFirestoreListener = (props: FirestoreListenerHookProps) => {
  const { selectedOrgId } = useOrgHooks();
  const [listenersState] = useState<ListenerBatchedActions>(
    props.listeners.reduce((p, v) => {
      p[v.collection] = {
        listener: v,
        batchedData: {
          added: [],
          modified: {},
          removed: []
        }
      };
      return p;
    }, {} as ListenerBatchedActions)
  );
  const executeListenerAction = async (listener: BaseListener<any>) => {
    const promises: Promise<any>[] = [];
    const data = listenersState[listener.collection].batchedData;
    const modified = Object.values(data.modified);
    data.added.length && promises.push(listener.actionHandler.add(data.added));
    modified.length && promises.push(listener.actionHandler.update(modified));
    data.removed.length &&
      promises.push(listener.actionHandler.remove(data.removed));
    await Promise.all(promises);
    listenersState[listener.collection].batchedData = {
      added: [],
      modified: {},
      removed: []
    };

    shouldLog &&
      console.log(
        `${listener.collection} listener cleared: `,
        listenersState[listener.collection].batchedData
      );
  };

  const executeListenersAction = async () => {
    const promises = props.listeners.map((listener) => {
      if (
        listenersState[listener.collection].batchedData.added.length ||
        Object.keys(listenersState[listener.collection].batchedData.modified)
          .length ||
        listenersState[listener.collection].batchedData.removed.length
      ) {
        shouldLog &&
          console.log(
            `${listener.collection} listener executed: `,
            listenersState[listener.collection].batchedData
          );
        return executeListenerAction(listener);
      }
    });
    await Promise.all(promises);
  };
  const debounceListenerAction = useRef(
    lodash.debounce(
      async () => {
        await executeListenersAction();
      },
      props.options?.listener?.settings?.wait || 1000,
      props.options?.listener?.settings?.debounce
    )
  );
  const throttleListenerAction = useRef(
    lodash.throttle(
      async () => {
        await executeListenersAction();
      },
      props.options?.listener?.settings?.wait || 1000,
      props.options?.listener?.settings?.throttle
    )
  );

  useEffect(() => {
    if (
      props.shouldListen &&
      selectedOrgId
      // && auth.currentUser //TODO: check if it is better
    ) {
      console.log("Hello from listener: ", initTimestamp);
      const unsubscribeListeners = props.listeners.map((listener) => {
        const baseQuery = firestore
          .context({
            orgId: selectedOrgId.toString(),
            user: {
              id: auth.currentUser?.uid
            }
          })
          .collection(listener.collection)
          .where("modifiedAt", ">=", initTimestamp);
        const filterQuery = listener.filter
          ? baseQuery.where(
              listener.filter.fieldPath,
              listener.filter.opStr,
              listener.filter.value
            )
          : baseQuery;

        return filterQuery.onSnapshot(async (qs) => {
          const changes = qs.docChanges().reduce((p, dc) => {
            const data = dc.doc.data();
            if (data.deleted === true) {
              p["removed"] = p["removed"]
                ? lodash.union(p["removed"], [data.id])
                : [data.id];
              // listener.actionHandler.remove({ id: data.id });
            } else if (dc.type === "modified") {
              //TODO: remove duplicates and choose based on latest modifiedAt
              p[dc.type] = p[dc.type]
                ? lodash.union(p[dc.type], [{ id: data.id, changes: data }])
                : [{ id: data.id, changes: data }];
              //listener.actionHandler.update({ id: data.id, changes: data });
            } else if (dc.type === "added") {
              //TODO: remove duplicates and choose based on latest modifiedAt
              p[dc.type] = p[dc.type]
                ? lodash.union(p[dc.type], [data])
                : [data];
              // listener.actionHandler.add(data);
            } else if (dc.type === "removed") {
              p[dc.type] = p[dc.type]
                ? lodash.union(p[dc.type], [data.id])
                : [data.id];
              //listener.actionHandler.remove({ id: data.id });
            }
            return p;
          }, {} as { removed: string[]; modified: { id: string; changes: firebase.firestore.DocumentData }[]; added: firebase.firestore.DocumentData[] });

          if (changes.added?.length)
            listenersState[listener.collection].batchedData.added =
              lodash.union(
                listenersState[listener.collection].batchedData.added,
                changes.added
              );

          if (changes.modified?.length) {
            changes.modified.forEach((c) => {
              listenersState[listener.collection].batchedData.modified[c.id] =
                c;
            });
          }
          if (changes.removed?.length)
            listenersState[listener.collection].batchedData.removed =
              lodash.union(
                listenersState[listener.collection].batchedData.removed,
                changes.removed
              );

          if (props.options?.listener?.settings?.operation === "debounce") {
            await debounceListenerAction.current();
          } else if (
            props.options?.listener?.settings?.operation === "throttle"
          ) {
            await throttleListenerAction.current();
          } else {
            throw `${props.options?.listener?.settings?.operation} is not a valid listener operation`;
          }
        });
      });
      return () => {
        console.log("Goodbye from listener");
        unsubscribeListeners.forEach((unsubscribe) => {
          unsubscribe();
        });
        console.log("unsubscribe app listeners success");
      };
    }
  }, [props.shouldListen, selectedOrgId]);
};
