import { Flex, Text } from "@chakra-ui/react";
import { FieldType, LabelValueWithStatus } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { concat, merge, omit } from "lodash";
import { useEffect, useState } from "react";
import { LoadingContent } from "../../../../../../common-components";
import { ClearSelectAllButtons } from "../../../../../../common-components/buttons/SelectAndClearAllButtons";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { FuzzySearch } from "../../../../../search/fuzzy/FuzzySearch";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { entitiesPath } from "../utils/formUtils";
import { PropertyOptions } from "./PropertyOptions";
import { usePropertiesSelectStepHook } from "./propertiesSelect.hooks";

const entitiesIdsPath: DotNestedKeys<PropertyInsurancePolicyState> =
  "entityIds";

type PropertiesSelectStepProps = {
  onChange: (value: OnChangeInput) => void;
  state: Partial<PropertyInsurancePolicyState>;
  dealId?: string;
};

export const PropertiesSelectStep = (props: PropertiesSelectStepProps) => {
  const { state, onChange, dealId } = props;
  const { isLoading, options, fetchProperties } = usePropertiesSelectStepHook({
    state
  });
  const [filteredOptions, setFilteredOptions] = useState<
    LabelValueWithStatus[]
  >([]);

  const handleSelectAll = () => {
    const selectedIds = state?.entityIds || [];
    onChange({
      fieldKey: [entitiesIdsPath],
      value: concat(
        filteredOptions.map((x) => x.value),
        selectedIds
      ),
      fieldType: FieldType.Array
    });
  };

  const handleClearAll = () => {
    const otherDealsProperties = options
      .filter((x) => x.isDisabled)
      .map((x) => x.value);
    const propertiesToUnselect = filteredOptions.map((x) => x.value);
    const entityIds = state?.entityIds || [];
    onChange({
      fieldKey: [entitiesIdsPath],
      value: entityIds.filter(
        (e) =>
          otherDealsProperties.includes(e) || !propertiesToUnselect.includes(e)
      ),
      fieldType: FieldType.Array
    });
  };

  const handelChange = (r: { isCheck: boolean; value: string }) => {
    const entityIds = state?.entityIds || [];
    const { value, isCheck } = r;
    onChange({
      fieldKey: [entitiesIdsPath],
      value: isCheck
        ? [...entityIds, value]
        : entityIds.filter((x) => x !== value),
      fieldType: FieldType.Array
    });

    onChange({
      fieldKey: [entitiesPath],
      value: isCheck
        ? merge(state.entities, {
            [value]: { dealId: props.dealId }
          })
        : omit(state.entities!, value),
      fieldType: FieldType.Array
    });
  };

  useEffect(() => {
    if (dealId) {
      fetchProperties();
    }
  }, [dealId]);

  useEffect(() => {
    if (options && state.entityIds) {
      const deletedProperties: string[] = options
        .map((x) => x.value)
        .filter((x) => !state?.entityIds?.includes(x));
      deletedProperties.forEach((id) => {
        handelChange({ isCheck: false, value: id });
      });
    }
  }, [options]);

  useEffect(() => {
    options.length && setFilteredOptions(options);
  }, [options]);

  return (
    <LoadingContent isLoading={isLoading} flexProps={{ gap: "12px" }}>
      <FuzzySearch
        label={"Search properties"}
        dataSet={options}
        configuration={{
          keys: ["label"],
          threshold: 0.4,
          includeScore: true
        }}
        onResult={setFilteredOptions}
      />

      <Text fontSize="sm" color="gray.500">
        Select the Properties that apply to this policy:
      </Text>
      <ClearSelectAllButtons
        onClearAll={handleClearAll}
        onSelectAll={handleSelectAll}
      />
      <Flex direction="column" overflowY="auto" gap="16px">
        {filteredOptions.map((option) => (
          <PropertyOptions
            value={option.value}
            isChecked={state.entityIds?.includes(option.value) || false}
            isDisabled={option.isDisabled || false}
            key={option.value}
            label={option.label}
            onChange={handelChange}
          />
        ))}
      </Flex>
    </LoadingContent>
  );
};
