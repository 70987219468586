import { Box, Button, Heading } from "@chakra-ui/react";
import { StatusCode } from "@elphi/types";
import { getPasswordStrength, Strength } from "@elphi/utils";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import {
  PasswordState,
  resetPassword,
  ResetPasswordForm
} from "../../../components/resetPassword";
import { useElphiToast } from "../../../components/toast/toast.hook";
import { useQueryParamsHooks } from "../../../hooks/queryParams.hooks";

export const ResetPasswordPage = () => {
  const [passwordState, setPasswordState] = useState<PasswordState>({
    password: "",
    confirmPassword: "",
    invalidIssues: []
  });
  const {
    password,
    confirmPassword,
    invalidIssues: blockMessages
  } = passwordState;
  const { errorToast, successToast } = useElphiToast();
  const navigate = useNavigate();

  const params = useQueryParamsHooks();
  const code = params.get("oobCode") || "";

  const isPasswordStrong = useMemo(
    () =>
      getPasswordStrength(passwordState.password)?.strength === Strength.Strong,
    [password]
  );

  const submit = async () => {
    const { status, error } = await resetPassword({ code, password });

    if (status === StatusCode.OK) {
      successToast({
        title: "Success",
        description: "password changed"
      });
      navigate("/login");
      return;
    }

    errorToast({
      title: "Failed",
      description: "something went wrong"
    });
    console.error("failed reset password", error);
  };

  const isDisabled =
    !code || !isPasswordStrong || !confirmPassword || !isEmpty(blockMessages);
  return (
    <Box maxW="container.lg" mx="auto" p={8}>
      <Heading as="h1" fontSize="3xl" mb={6}>
        Reset Password
      </Heading>
      <ResetPasswordForm
        passwordState={passwordState}
        setPasswordState={setPasswordState}
      />
      <Button
        {...elphiTheme.components.light.button.primary}
        mt={4}
        size="lg"
        width="full"
        disabled={isDisabled}
        onClick={submit}
      >
        Reset Password
      </Button>
    </Box>
  );
};
