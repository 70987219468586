import { Box, Button, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  CreditReportOrderTask,
  ElphiTrxStatus,
  FieldType,
  FloodCertificateOrderTask,
  IntegrationTaskTemplate,
  Property,
  PropertyReportOrderTask,
  RentalDataOrderTask,
  RentalDataVendorType
} from "@elphi/types";
import { WireInsuranceCertificateOrderTask } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import { useEffect, useState } from "react";
import elphiTheme from "../../../../../assets/themes/elphi.theme.default";
import { useStepRequiredHooks } from "../../../../../hooks/integrationCenter.hooks";
import { useRentalDataTaskHooks } from "../../../../../hooks/rentalDataTask.hooks";
import ScrollableSections from "../../../../ScrollableSections";
import FormBuilder, {
  OnChangeInput,
  Section,
  SectionHeader
} from "../../../../form-builder/FormBuilder";
import StyledInputBuilder from "../../../../form-builder/InputBuilder";
import { RotateLeft, WindWhiteIcon } from "../../../../icons";
import { createOptionsFromMap } from "../../../../utils/formUtils";
import { TaskFormState } from "../../../common.task.types";
import { IntegrationTaskHeaderForm } from "../../IntegrationTaskHeaderForm";
import { BUTTON_TOOLTIP_MESSAGES } from "../../integration.type";
import { RentRangeModal } from "./RentRangeModal";

type IntegrationTask =
  | FloodCertificateOrderTask
  | CreditReportOrderTask
  | WireInsuranceCertificateOrderTask
  | PropertyReportOrderTask
  | RentalDataOrderTask;
type IntegrationFormContainerProps<
  T extends IntegrationTask = IntegrationTask
> = {
  snapshotId?: string;
  state?: TaskFormState;
} & IntegrationTaskTemplate<T>;

export type RentalDataVendorTaskFormProps = {
  sections: Section[];
  propertyOnChangeBatch: (v: OnChangeInput) => void;
  selectedPropertyState: Partial<Property>;
} & IntegrationFormContainerProps;
enum RentRangeServiceOption {
  RentalAVM = "Rental AVM"
}

export type RentRangeTaskFormProps = RentalDataVendorTaskFormProps;

export const RentRangeForm = (props: RentRangeTaskFormProps) => {
  const {
    snapshotId,
    selectedTask,
    state,
    sections,
    propertyOnChangeBatch,
    selectedPropertyState
  } = props;
  const [serviceSelection, setServiceSelection] =
    useState<RentRangeServiceOption>(RentRangeServiceOption.RentalAVM);
  const [sectionValidationState, setSectionValidationState] = useState<{
    [index: string]: boolean;
  }>({});
  const [rentRangeModal, setRentRangeModal] = useState<boolean>(false);
  const { disableStepRequired, syncState } = useStepRequiredHooks();

  const {
    getRentalDataVendorTask,
    createOrderHandler,
    createOrderResponse,
    retryOrderHandler,
    retryOrderResponse,
    getOrderStatusTooltip
  } = useRentalDataTaskHooks();
  const rentRangeTask = getRentalDataVendorTask(
    selectedTask,
    RentalDataVendorType.RentRange
  );

  const isAllValid = sectionValidationState[0];

  const tooltipOrderLabel = !!serviceSelection
    ? !isAllValid
      ? BUTTON_TOOLTIP_MESSAGES.validationError
      : BUTTON_TOOLTIP_MESSAGES?.[
          rentRangeTask?.elphiTrxStatus || ElphiTrxStatus.Pending
        ]
    : BUTTON_TOOLTIP_MESSAGES.chooseService;

  const handleOnClickOrder = () => {
    const canOrder = rentRangeTask && isAllValid;
    if (canOrder) {
      if (rentRangeTask.requests) {
        setRentRangeModal(true);
        return;
      }
      createOrderHandler({
        taskId: rentRangeTask.id
      });
    }
  };

  const onCloseModal = () => {
    setRentRangeModal(false);
    if (rentRangeTask?.stepRequired) {
      disableStepRequired(selectedTask.id);
    }
  };
  const onSubmitModal = () => {
    const canReorder = rentRangeTask && isAllValid && rentRangeModal;
    if (canReorder) {
      createOrderHandler({
        taskId: rentRangeTask.id
      }).then((_) => {
        onCloseModal();
      });
    }
  };

  useEffect(() => {
    syncState({
      shouldSync: !!selectedTask,
      state: selectedTask,
      statePath: () => {
        return ["tasks", selectedTask.id];
      }
    });
    if (rentRangeTask?.stepRequired) {
      setRentRangeModal(true);
    }
  }, [selectedTask]);

  const shouldOrderButtonDisable =
    !isAllValid || !serviceSelection || !rentRangeTask?.isReadyToOrder;

  return (
    <>
      {rentRangeTask && (
        <>
          <ScrollableSections
            customKey={"rentRangeTaskForm"}
            sections={[
              {
                header: (
                  <SectionHeader
                    header={
                      <IntegrationTaskHeaderForm
                        task={selectedTask}
                        elphiTrxStatus={rentRangeTask.elphiTrxStatus}
                        getOrderStatusTooltip={getOrderStatusTooltip}
                      />
                    }
                  />
                ),
                body: (
                  <Flex flexDirection={"row"}>
                    <Box w="250px" mr="50px">
                      <StyledInputBuilder
                        options={createOptionsFromMap(RentRangeServiceOption)}
                        label="Choose Service"
                        onChange={(e) => setServiceSelection(e.target.value)}
                        isClearable={true}
                        fieldType={FieldType.SingleSelect}
                        currentValue={serviceSelection}
                      />
                    </Box>
                    <Tooltip label={tooltipOrderLabel}>
                      <Button
                        iconSpacing={0}
                        leftIcon={<WindWhiteIcon boxSize="8" pt="8px" />}
                        disabled={shouldOrderButtonDisable}
                        onClick={handleOnClickOrder}
                        isLoading={
                          createOrderResponse.isLoading && !rentRangeModal
                        }
                        color="white"
                        {...elphiTheme.components.light.button.scaleChart}
                      >
                        Order
                      </Button>
                    </Tooltip>
                    {rentRangeTask?.elphiTrxStatus === ElphiTrxStatus.Failed &&
                      rentRangeTask?.failedLabel ===
                        "failedUploadingReport" && (
                        <Tooltip label={BUTTON_TOOLTIP_MESSAGES.retryToOrder}>
                          <IconButton
                            ml={5}
                            isLoading={retryOrderResponse.isLoading}
                            variant={"ghost"}
                            aria-label="retry-button"
                            onClick={() =>
                              retryOrderHandler({ taskId: rentRangeTask.id })
                            }
                            icon={<RotateLeft boxSize="10" pr="1px" pt="5px" />}
                          />
                        </Tooltip>
                      )}
                  </Flex>
                )
              }
            ]}
          />
          <>
            {state?.tasks && (
              <FormBuilder
                setSectionValidationState={setSectionValidationState}
                customKey="rentRangeSections"
                isDisabled={!!snapshotId}
                size={{
                  minW: "300px"
                }}
                onChange={propertyOnChangeBatch}
                sections={sections}
              />
            )}
          </>
          <RentRangeModal
            isShow={rentRangeModal}
            onClickOrder={onSubmitModal}
            loading={createOrderResponse.isLoading}
            property={selectedPropertyState}
            data={Object.values(rentRangeTask.requests || {})}
            onCloseModal={onCloseModal}
          />
        </>
      )}
    </>
  );
};
