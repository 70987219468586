import { DocumentConfiguration } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { documentPackageOrderApi } from "../document-package-order/documentPackageOrder.service";
import { documentConfigurationEntityAdapter as entityAdapter } from "./documentConfiguration.adapter";
import { documentConfigurationApi } from "./documentConfiguration.service";

export type DocumentConfigurationSliceState =
  EntityState<DocumentConfiguration> & {
    selectedId?: EntityId;
  };
export const documentConfigurationSlice = createSlice({
  name: "documentConfiguration",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as DocumentConfigurationSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(documentConfigurationApi)(builder);
    builder.addMatcher(
      documentConfigurationApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      documentConfigurationApi.endpoints.getConfigurationBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.configurations.ids);
        state.entities = {
          ...state.entities,
          ...payload.configurations.entities
        };
      }
    );
    builder.addMatcher(
      documentPackageOrderApi.endpoints.getEntitiesData.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.documentConfigurations.ids);
        state.entities = {
          ...state.entities,
          ...payload.documentConfigurations.entities
        };
      }
    );
  }
});
