import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { CreditScore } from "@elphi/types";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useCreditReportHooks } from "../../hooks/creditReport.hooks";
import { ButtonIconDelete } from "../button-icon/ButtonIcon";
import { useElphiToast } from "../toast/toast.hook";

export const DeleteCreditReportModal = (props: {
  callback?: Function;
  buttonFn?: (props?: {
    onClick?: () => void;
    isDisabled?: boolean;
  }) => JSX.Element;
  selectedCreditReport: CreditScore;
  isDisabled?: boolean;
}) => {
  const { selectedCreditReport } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteCreditScore, deleteCreditScoreResponse } =
    useCreditReportHooks();
  const { successToast, errorToast } = useElphiToast();
  const buttonComponent = props.buttonFn?.({ onClick: onOpen }) ?? null;

  const deleteCreditReportHandler = async (
    selectedCreditReport: CreditScore
  ) => {
    return await deleteCreditScore(selectedCreditReport.id).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "CreditReport Deleted",
          description: `creditReport: ${r.data?.id}`
        });
        props.callback && props.callback();
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to delete creditReport",
          description: r.data.description
        });
      return r;
    });
  };

  const confirmModal = (
    <Box>
      <Text p="10px">
        Are you sure you want to delete this credit report? Deleting this credit
        report will delete all of its information.
      </Text>
      <Spacer mb="50px" />
      <Flex justifyContent={"space-between"}>
        <Button
          {...elphiTheme.components.light.button.primary}
          isLoading={deleteCreditScoreResponse.isLoading}
          isDisabled={
            !selectedCreditReport || deleteCreditScoreResponse.isLoading
          }
          onClick={() => {
            selectedCreditReport &&
              deleteCreditReportHandler(selectedCreditReport).then((r) => {
                r.status === 200 && onClose();
              });
          }}
        >
          Yes, delete credit report
        </Button>
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );

  return (
    <Box>
      {props.buttonFn
        ? buttonComponent
        : ButtonIconDelete({ onClick: onOpen, isDisabled: props.isDisabled })}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Deleting a Credit Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{confirmModal}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
