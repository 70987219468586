import {
  FieldType,
  IntegrationType,
  PropertyReportVendorType,
  SiteXAggregationIntergrationFields,
  SiteXTrxStatus
} from "@elphi/types";
import { addressFieldSpecs } from "../../../../../../components/form-builder/field-specs/address/address.fields";
import { mapEnumValues } from "../../../../../../components/utils/general.utils";
import { IntegrationsFieldSpecs } from "../../../types/integrations.types";

export type SiteXIntegrationsFieldSpecs = IntegrationsFieldSpecs<
  SiteXAggregationIntergrationFields,
  {}
>;

export const siteXPropertyIntegrationsFieldSpecs: SiteXIntegrationsFieldSpecs =
  {
    aggregations: {
      Integrations: {
        [IntegrationType.PropertyReport]: {
          [PropertyReportVendorType.SiteX]: {
            addressLineText: {
              thirdParty: {
                ...addressFieldSpecs.AddressLineText,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "addressLineText",
                  "thirdParty"
                ],
                label: "Property Report Address Line 1"
              },
              focused: {
                ...addressFieldSpecs.AddressLineText,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "addressLineText",
                  "focused"
                ],
                label: "Focus"
              },
              override: {
                ...addressFieldSpecs.AddressLineText,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "addressLineText",
                  "override"
                ],
                label: "Property Report Address Line 1"
              }
            },
            addressUnitIdentifier: {
              thirdParty: {
                ...addressFieldSpecs.AddressUnitIdentifier,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "addressUnitIdentifier",
                  "thirdParty"
                ],
                label: "Property Report Address Line 2"
              },
              focused: {
                ...addressFieldSpecs.AddressUnitIdentifier,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "addressUnitIdentifier",
                  "focused"
                ],
                label: "Focus"
              },
              override: {
                ...addressFieldSpecs.AddressUnitIdentifier,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "addressUnitIdentifier",
                  "override"
                ],
                label: "Property Report Address Line 2"
              }
            },
            cityName: {
              thirdParty: {
                ...addressFieldSpecs.CityName,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "cityName",
                  "thirdParty"
                ],
                label: "Property Report City Name"
              },
              focused: {
                ...addressFieldSpecs.CityName,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "cityName",
                  "focused"
                ],
                label: "Focus"
              },
              override: {
                ...addressFieldSpecs.CityName,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "cityName",
                  "override"
                ],
                label: "Property Report City Name"
              }
            },
            stateCode: {
              thirdParty: {
                ...addressFieldSpecs.StateCode,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "stateCode",
                  "thirdParty"
                ],
                label: "Property Report State Code"
              },
              focused: {
                ...addressFieldSpecs.StateCode,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "stateCode",
                  "focused"
                ],
                label: "Focus"
              },
              override: {
                ...addressFieldSpecs.StateCode,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "stateCode",
                  "override"
                ],
                label: "Property Report State Code"
              }
            },
            postalCode: {
              thirdParty: {
                ...addressFieldSpecs.PostalCode,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "postalCode",
                  "thirdParty"
                ],
                label: "Property Report Zip Code"
              },
              focused: {
                ...addressFieldSpecs.PostalCode,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "postalCode",
                  "focused"
                ],
                label: "Focus"
              },
              override: {
                ...addressFieldSpecs.PostalCode,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "postalCode",
                  "override"
                ],
                label: "Property Report Zip Code"
              }
            },
            countyName: {
              thirdParty: {
                ...addressFieldSpecs.CountyName,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "countyName",
                  "thirdParty"
                ],
                label: "Property Report County"
              },
              focused: {
                ...addressFieldSpecs.CountyName,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "countyName",
                  "focused"
                ],
                label: "Focus"
              },
              override: {
                ...addressFieldSpecs.CountyName,
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "countyName",
                  "override"
                ],
                label: "Property Report County"
              }
            },
            assessorParcelNumber: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "assessorParcelNumber",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Property Report APN"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "assessorParcelNumber",
                  "focused"
                ],
                fieldType: FieldType.String,
                label: "Focus"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "assessorParcelNumber",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Property Report APN"
              }
            },
            fips: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "fips",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "FIPS"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "fips",
                  "focused"
                ],
                fieldType: FieldType.String,
                label: "Focus"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "fips",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "FIPS"
              }
            },
            orderStatus: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "orderStatus",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Order Status"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "orderStatus",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                options: mapEnumValues(SiteXTrxStatus),
                label: "Focus"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "orderStatus",
                  "override"
                ],
                fieldType: FieldType.SingleSelect,
                options: mapEnumValues(SiteXTrxStatus),
                label: "Order Status"
              }
            },
            orderCompletedOn: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "orderCompletedOn",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Order Completed On"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "orderCompletedOn",
                  "focused"
                ],
                fieldType: FieldType.Date,
                label: "Focus"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.PropertyReport,
                  PropertyReportVendorType.SiteX,
                  "orderCompletedOn",
                  "override"
                ],
                fieldType: FieldType.Date,
                label: "Order Completed On"
              }
            }
          }
        }
      }
    },
    Integrations: {}
  };
