import { SystemSetup } from "../../../common";
import { PartyType } from "../../../entities";
import {
  elphiBaseSchemaEntityParty,
  elphiBaseSchemaIndividualParty
} from "../../base/party.typebox";
import {
  limaEntityPartySchema,
  limaIndividualPartySchema
} from "../../lima/party.typebox";
import {
  mofinEntityPartySchema,
  mofinIndividualPartySchema
} from "../../mofin/party.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type PartyEnvToSchemaMap = {
  [PartyType.Entity]: EnvToSchemasMap;
  [PartyType.Individual]: EnvToSchemasMap;
};

export const partySchemas: PartyEnvToSchemaMap = {
  [PartyType.Entity]: {
    [SystemSetup.Elphi]: elphiBaseSchemaEntityParty,
    [SystemSetup.Lima]: limaEntityPartySchema,
    [SystemSetup.Mofin]: mofinEntityPartySchema,
    [SystemSetup.LedgerTC]: elphiBaseSchemaEntityParty
  },
  [PartyType.Individual]: {
    [SystemSetup.Elphi]: elphiBaseSchemaIndividualParty,
    [SystemSetup.Lima]: limaIndividualPartySchema,
    [SystemSetup.Mofin]: mofinIndividualPartySchema,
    [SystemSetup.LedgerTC]: elphiBaseSchemaIndividualParty
  }
};
