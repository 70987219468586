import {
  CreditReportPullType,
  IndividualParty
} from "../../entities/party.types";
import { DotNestedKeys, Flatten } from "../../utils/flatten";

export type CreditReportPOSTV1 = {
  party: {
    borrowerFirstName: string; // party.FirstName // Required
    borrowerMiddleName: string; // party.MiddleName // Optional
    borrowerLastName: string; // party.LastName // Required
    borrowerNameSuffix: string; // Not needed
    borrowerSSN: string; // party.TaxpayerIdentifierType // 9 digits, no spaces /  Required
    borrowerDOB: string; // party.BirthDate // format as shown "YYYY-MM-DD" /  Required
    borrowerAddress: {
      streetPrimaryNumberText: string; // Not Needed
      streetName: string; // party.Address.AddressLineText //  Required
      streetSuffixText: string; //Not Needed
      streetCity: string; // party.Address.CityName /  Required
      streetStateCode: string; // party.Address.StateCode /  Required
      streetPostalCode: string; //party.Address.PostalCode /  Required
      countryCode: string; // Not Needed
    };
  };
  SoftPull: CreditReportPullType; // "true", "false"
  CreditReports: {
    includeEquifax: boolean; // "true", "false"
    includeExperian: boolean; // "true", "false"
    includeTransUnion: boolean; // "true", "false"
  };
};
export type CreditReportPOSTV2 = CreditReportPOSTV1 & {
  loanIdentifier: string;
  loanIdentifierType: string; //there was a typo here
  loanOriginationSystemName: string;
  partyRoleType: string;
  creditReportRequestActionType: string;
  creditReportType?: string;
  creditReportTypeOtherDescription?: string;
  reportingInformationIdentifier: string;
  reportingInformationName: string;
  pullType?: string;
  creditReportIdentifier?: string;
  requestedByName: string;
  submittingParty: string;
  requestingParty: string;
};

export type IndividualToCreditScorePOSTMap = Partial<
  Flatten<IndividualParty, DotNestedKeys<CreditReportPOSTV1>>
>;

export const individualToCreditScorePOSTMap: IndividualToCreditScorePOSTMap = {
  FirstName: "party.borrowerFirstName",
  MiddleName: "party.borrowerMiddleName",
  LastName: "party.borrowerLastName",
  TaxpayerIdentifierValue: "party.borrowerSSN",
  BirthDate: "party.borrowerDOB",
  "Address.AddressLineText": "party.borrowerAddress.streetName",
  "Address.CityName": "party.borrowerAddress.streetCity",
  "Address.StateCode": "party.borrowerAddress.streetStateCode",
  "Address.PostalCode": "party.borrowerAddress.streetPostalCode",
  CreditReportPullType: "SoftPull"
};

export const getCreditReportRequestTemplate = () => {
  const creditReport: CreditReportPOSTV2 = {
    CreditReports: {
      includeEquifax: true,
      includeExperian: true,
      includeTransUnion: true
    },
    party: {
      borrowerFirstName: "",
      borrowerMiddleName: "",
      borrowerLastName: "",
      borrowerNameSuffix: "",
      borrowerSSN: "",
      borrowerDOB: "",
      borrowerAddress: {
        streetPrimaryNumberText: "",
        streetName: "",
        streetSuffixText: "",
        streetCity: "",
        streetStateCode: "",
        streetPostalCode: "",
        countryCode: "US"
      }
    },
    SoftPull: "" as CreditReportPullType,
    loanIdentifier: "",
    loanIdentifierType: "LenderCase",
    loanOriginationSystemName: "",
    partyRoleType: "Borrower",
    creditReportRequestActionType: "Submit",
    reportingInformationIdentifier: "GG000283",
    reportingInformationName: "GlobalGatewayLenderIdentifier",
    requestedByName: "Lima One Capital",
    submittingParty: "Lima One Capital",
    requestingParty: "Lima One Capital"
  };
  return creditReport;
};
