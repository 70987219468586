import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { useState } from "react";

export const PasswordField = ({
  value,
  onChange
}: {
  value: string;
  onChange: (e) => void;
}) => {
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? "text" : "password"}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        size="lg"
      />
      <InputRightElement
        width="4.5rem"
        marginTop="25px"
        transform="translateY(-50%)"
      >
        <IconButton
          aria-label="Toggle password visibility"
          h="1.75rem"
          size="sm"
          icon={show ? <ViewOffIcon /> : <ViewIcon />}
          onClick={handleClick}
        />
      </InputRightElement>
    </InputGroup>
  );
};
