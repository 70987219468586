import { useToast, UseToastOptions } from "@chakra-ui/react";

export type ToastSpecs = {
  title: string;
  description: string;
};
export type ToastOptions = {
  onSuccess: ToastSpecs;
  onError: ToastSpecs;
};

export const useElphiToast = (props?: UseToastOptions) => {
  const toast = useToast();

  const errorToast = (r: ToastSpecs) => {
    return toast({
      title: r.title,
      description: r.description,
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
      variant: "errorToast",
      ...props
    });
  };
  const successToast = (r: ToastSpecs) => {
    return toast({
      title: r.title,
      description: r.description,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-right",
      variant: "successToast"
    });
  };
  const infoToast = (r: ToastSpecs) => {
    return toast({
      title: r.title,
      description: r.description,
      status: "info",
      duration: 3000,
      isClosable: true,
      position: "top-right",
      variant: "infoToast"
    });
  };
  return {
    toast,
    errorToast,
    successToast,
    infoToast
  };
};
