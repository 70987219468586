import { createIcon } from "@chakra-ui/react";

const customFileIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 5.5H0.5C0.225 5.5 0 5.275 0 5C0 4.725 0.225 4.5 0.5 4.5H2C2.275 4.5 2.5 4.725 2.5 5C2.5 5.275 2.275 5.5 2 5.5ZM2.87812 3.37813C2.74688 3.37813 2.61875 3.325 2.525 3.23125L1.46563 2.17188C1.27188 1.97812 1.27188 1.65938 1.46563 1.46563C1.65938 1.27188 1.97812 1.27188 2.17188 1.46563L3.23125 2.525C3.42812 2.71875 3.42812 3.0375 3.23125 3.23125C3.1375 3.325 3.0125 3.37813 2.87812 3.37813ZM5 2.5C4.725 2.5 4.5 2.275 4.5 2V0.5C4.5 0.225 4.725 0 5 0C5.275 0 5.5 0.225 5.5 0.5V2C5.5 2.275 5.275 2.5 5 2.5ZM7.12187 3.37813C6.84687 3.37813 6.62187 3.15313 6.62187 2.87812C6.62187 2.74688 6.675 2.61875 6.76875 2.525L7.82812 1.46563C8.02188 1.27188 8.34062 1.27188 8.53438 1.46563C8.72813 1.65938 8.72813 1.97812 8.53438 2.17188L7.475 3.23125C7.38125 3.325 7.25312 3.37813 7.12187 3.37813ZM1.81875 8.68125C1.54375 8.68125 1.31875 8.45625 1.31875 8.18125C1.31875 8.05 1.37188 7.92188 1.46563 7.82812L2.525 6.76875C2.71875 6.575 3.0375 6.575 3.23125 6.76875C3.425 6.9625 3.425 7.28125 3.23125 7.475L2.17188 8.53438C2.07812 8.62813 1.95 8.68125 1.81875 8.68125ZM6.31875 4.21875C5.74375 3.63438 4.80312 3.625 4.21875 4.19688C3.63438 4.76875 3.625 5.7125 4.19688 6.29688L4.21875 6.31875L5.45312 7.55313C5.55 7.65 5.70937 7.65 5.80625 7.55313L7.55313 5.80625C7.65 5.70937 7.65 5.55 7.55313 5.45312L6.31875 4.21875ZM13.2812 11.1812L8.6125 6.5125C8.51562 6.41562 8.35625 6.41562 8.25937 6.5125L6.5125 8.25937C6.41562 8.35625 6.41562 8.51562 6.5125 8.6125L11.1812 13.2812C11.7562 13.8656 12.6969 13.875 13.2812 13.3031C13.8656 12.7281 13.875 11.7875 13.3031 11.2031C13.2969 11.1969 13.2906 11.1875 13.2812 11.1812Z"
          fill={fill}
        />
      </svg>
    ),
    defaultProps: {
      fill: "white"
    }
  });

export const WindIcon = customFileIcon();
export const WindWhiteIcon = customFileIcon("white");
export const WingGrayIcon = customFileIcon("#4A5568");
