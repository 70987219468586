import { Box, Input, InputProps } from "@chakra-ui/react";
import { DATES_FORMATS, elphiDate } from "@elphi/utils";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { EMPTY } from "../../../constants/common";
import { DatePickerContainer } from "./DatePickerContainer";
import "./dateRangePicker.css";

const WITHOUT_WIDTH = "150px";
const SELECT_FROM_WIDTH = "160px";
const SELECT_TO_WIDTH = "245px";

export type DateRangePickerProps = {
  value?: { from: string; to: string };
  onChange?: (e: {
    target: {
      value?: { from?: string; to?: string };
    };
  }) => void;
  isRequired?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  dateFormat?: string;
  inputDateFormat?: string;
  isClearable?: boolean;
} & InputProps;

export const DateRangePicker = ({
  value,
  onChange,
  isRequired,
  isReadOnly,
  isDisabled,
  placeholder,
  dateFormat = DATES_FORMATS.YYYY_MM_DD,
  inputDateFormat = DATES_FORMATS.dd_MM_yyyy_WITH_SLASH,
  isClearable = true,
  ...inputProps
}: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const initDates = () => {
    const { from, to } = value || {};
    setStartDate(
      from ? elphiDate(from, { format: dateFormat }).toDate() : undefined
    );
    setEndDate(to ? elphiDate(to, { format: dateFormat }).toDate() : undefined);
  };

  useEffect(() => {
    initDates();
  }, [value]);

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onChange?.({
      target: {
        value:
          newStartDate || newEndDate
            ? {
                from:
                  (newStartDate &&
                    elphiDate(newStartDate).format(dateFormat)) ||
                  EMPTY,
                to:
                  (newEndDate && elphiDate(newEndDate).format(dateFormat)) ||
                  EMPTY
              }
            : {}
      }
    });
  };

  const onClearSelected = () => {
    handleChange([undefined, undefined]);
  };

  return (
    <Box
      zIndex={10}
      _hover={{ bgColor: "gray.200", cursor: "pointer", borderRadius: "5px" }}
    >
      <DatePickerContainer
        isSelected={!!startDate && !!endDate}
        onClear={onClearSelected}
        isClearable={isClearable}
        showClear={!!startDate}
      >
        <DatePicker
          readOnly={isReadOnly || isDisabled}
          selectsRange
          onChange={handleChange}
          required={isRequired}
          startDate={startDate}
          endDate={endDate}
          todayButton="Today"
          placeholderText={placeholder || "Select dates"}
          dateFormat={inputDateFormat}
          popperPlacement="top-end"
          portalId="date-picker"
          customInput={
            <Input
              w={
                startDate
                  ? endDate
                    ? SELECT_TO_WIDTH
                    : SELECT_FROM_WIDTH
                  : WITHOUT_WIDTH
              }
              {...inputProps}
              fontWeight={"medium"}
              border={0}
              fontSize={14}
              fontFamily={"inherit"}
              _hover={{ cursor: "pointer" }}
            />
          }
        />
      </DatePickerContainer>
    </Box>
  );
};
