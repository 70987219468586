export const filterStyle = {
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "5px"
  }
};

export const filterChildrenStyle = {
  title: filterStyle.title,
  inputContainer: {
    marginTop: "-10px",
    marginLeft: "-10px"
  },
  container: {
    marginBottom: "-10px"
  }
};
