import {
  Box,
  Button,
  Flex,
  Spacer,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Tooltip,
  VStack,
  useSteps
} from "@chakra-ui/react";
import { take } from "lodash";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { WizardProps } from "./wizardContainer.types";

type StepCircleProps = {
  children: JSX.Element;
  bgColor: string;
  textColor: string;
  borderColor: string;
};

const StepCircle = (props: StepCircleProps) => {
  return (
    <Flex
      w="100%"
      h="100%"
      borderRadius="50%"
      justifyContent="center"
      alignItems="center"
      border="2px"
      bg={props.bgColor}
      color={props.textColor}
      borderColor={props.borderColor}
    >
      {props.children}
    </Flex>
  );
};

export const WizardContainer = (props: WizardProps) => {
  const { steps, onSubmit, isLoading, height } = props;
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length
  });

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleStepClick = (index: number) => {
    if (index <= activeStep) {
      setActiveStep(index);
      return;
    }

    const previousSteps = take(steps, index);
    if (previousSteps.every((x) => x.isValid)) {
      setActiveStep(index);
    }
  };

  return (
    <VStack width={"100%"} align={"stretch"}>
      <Stepper size={"lg"} index={activeStep}>
        {steps.map((step, index) => (
          <Step
            style={{ cursor: "pointer" }}
            key={index}
            onClick={() => {
              handleStepClick(index);
            }}
          >
            <StepIndicator border="none">
              <StepStatus
                complete={
                  <StepCircle
                    bgColor="white"
                    textColor="blue.500"
                    borderColor="blue.500"
                  >
                    <StepIcon />
                  </StepCircle>
                }
                incomplete={
                  <StepCircle
                    bgColor="white"
                    textColor="black"
                    borderColor="gray.200"
                  >
                    <StepNumber />
                  </StepCircle>
                }
                active={
                  <StepCircle
                    bgColor="blue.500"
                    textColor="white"
                    borderColor="transperant"
                  >
                    <StepNumber />
                  </StepCircle>
                }
              />
            </StepIndicator>
            <Box>
              <StepTitle>{step.label}</StepTitle>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Box my={"10px"} height={height} overflowY="auto">
        {steps[activeStep].content}
      </Box>
      <Flex my={"10px"}>
        <Flex>
          <Button
            {...elphiTheme.components.light.button.secondary}
            mr={"15px"}
            isDisabled={isFirstStep}
            onClick={handlePrev}
          >
            Previous
          </Button>
        </Flex>
        <Spacer />
        <Flex>
          <Button
            {...elphiTheme.components.light.button.primary}
            hidden={isLastStep}
            isDisabled={!steps[activeStep].isValid}
            onClick={handleNext}
          >
            Next
          </Button>
          <Tooltip
            isDisabled={!isLastStep}
            label={props.tooltipSubmitLabel}
            placement={"top-end"}
            hasArrow
          >
            <Button
              {...elphiTheme.components.light.button.primary}
              onClick={onSubmit}
              isLoading={isLoading}
              hidden={!isLastStep}
              isDisabled={!steps.every((x) => x.isValid)}
            >
              Save
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </VStack>
  );
};
