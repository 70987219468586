import { DealCalendarDealListDateType, DealCalendarType } from "@elphi/types";
import { elphiDate } from "@elphi/utils";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isEmpty, keys, size } from "lodash";
import { DateRange } from "../../../common-components/calendar/calender.type";
import { DateType } from "../../../shared/types/deal-calendar.types";
import sliceBuilder from "../builders/slice.builder";
import { DealTableFilterState } from "../deal/deal.slice";
import { dealCalendarEntityAdapter as entityAdapter } from "./dealCalendar.adapter";
import { dealCalendarApi } from "./dealCalendar.service";
import { syncDealCalendarData } from "./dealCalendarSlice.utils";
export type Dates = {
  [dateType in DateType]: {
    [date: string]: DealCalendarDealListDateType;
  };
};

export type DealCalendarSliceStateFields = {
  dates: Dates;
  hoverDate?: Date;
  isToolTipLoaded?: boolean;
  calendarCurrentViewDates: DateRange;
  selectedCalendarViewDate: {
    selectedMonth: number;
    selectedYear: number;
  };
  selectedDateType: DateType;
  filter: DealTableFilterState;
  enableCloseClickOutsideSidebar?: boolean;
  sideBarDealCalendar?: {
    dealCalendarId?: string;
    date?: Date;
  };
};

export type DealCalendarSliceState = DealCalendarSliceStateFields;

export const initialState: DealCalendarSliceStateFields = {
  dates: { closingDate: {}, createdAt: {} },
  calendarCurrentViewDates: {},
  selectedCalendarViewDate: {
    selectedMonth: elphiDate().monthIndex(),
    selectedYear: elphiDate().yearIndex()
  },
  selectedDateType: "closingDate",
  filter: {
    dealMilestone: [],
    lenderIdentifier: [],
    dealMilestoneOp: "in",
    lenderIdentifierOp: "in",
    estimatedClosingDateRange: {},
    dealParties: [],
    loanProgramTypes: [],
    totalLoanAmount: []
  }
};

export const dealCalendarSlice = createSlice({
  name: "dealCalendar",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    setSelectedCalendarViewDate: (
      state,
      action: PayloadAction<{ month: number; year: number }>
    ) => {
      state.selectedCalendarViewDate = {
        selectedMonth: action.payload?.month,
        selectedYear: action.payload?.year
      };
    },
    setHoverDate: (state, action: PayloadAction<{ hoverDate?: Date }>) => {
      state.hoverDate = action.payload?.hoverDate;
    },
    setIsToolTipLoaded: (
      state,
      action: PayloadAction<{ isToolTipLoaded?: boolean }>
    ) => {
      state.isToolTipLoaded = action.payload?.isToolTipLoaded;
    },
    setSelectedDateType: (
      state,
      action: PayloadAction<{ dateType: DateType }>
    ) => {
      state.selectedDateType = action.payload?.dateType;
    },
    setFilter: (
      state,
      action: PayloadAction<{ filter: DealTableFilterState }>
    ) => {
      state.filter = action.payload?.filter;
    },
    setSideBarDealCalendar: (
      state,
      action: PayloadAction<
        { dealCalendarId?: string; date?: Date } | undefined
      >
    ) => {
      state.sideBarDealCalendar = action.payload;
    },
    setEnableCloseClickOutsideSidebar: (
      state,
      action: PayloadAction<{ enableCloseClickOutsideSidebar?: boolean }>
    ) => {
      state.enableCloseClickOutsideSidebar =
        action.payload.enableCloseClickOutsideSidebar;
    },
    setCalendarCurrentViewDates: (state, action: PayloadAction<DateRange>) => {
      const { startDate, endDate } = action.payload;
      state.calendarCurrentViewDates = {
        startDate,
        endDate
      };
    },
    updateDates: (
      state,
      action: PayloadAction<{
        updatedDealCalendars: DealCalendarType[];
      }>
    ) => {
      const { updatedDealCalendars } = action.payload;

      updatedDealCalendars.forEach((dealCalendar) =>
        syncDealCalendarData(dealCalendar, state)
      );
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(dealCalendarApi)(builder);
    builder.addMatcher(
      dealCalendarApi.endpoints.dateRange.matchFulfilled,
      (state, { payload }) => {
        if (payload?.data) {
          const dataKeys = keys(payload?.data);
          const keysSize = size(dataKeys);
          if (keysSize === 1 && !isEmpty(state.dates[state.selectedDateType])) {
            const dataKey = dataKeys[0];
            const dealCalenderDate = payload?.data?.[dataKey];
            if (
              dealCalenderDate?.deals &&
              dealCalenderDate?.deals?.length >= 3
            ) {
              state.dates[state.selectedDateType][dataKey] = dealCalenderDate;
            }
          } else {
            state.dates[state.selectedDateType] = payload?.data;
          }
        }
      }
    );
  }
});
