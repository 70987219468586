import { LabelValue, ServiceProviderType } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { AppConfig } from "../../../../../config/appConfig";
import { EMPTY } from "../../../../../constants/common";
import { useServiceProviderHooks } from "../../../../../hooks/rolodexServiceProvider.hooks";
import {
  BranchSearchRequestParams,
  CompanySearchRequestParams,
  RepSearchRequestParams,
  buildBranchCursorKey,
  buildCompanyCursorKey,
  buildRepCursorKey,
  serviceProviderApi
} from "../../../../../redux/v2/rolodex";
import { getRankedData } from "../../../../../utils/ranked.utils";
import { SearchComponentProps } from "../../../../SearchComponent";
import {
  addProviderOption,
  buildPersonalRolodexOption
} from "../../utils/serviceProvider.utils";
import { ServiceProvideSearchHooksProps } from "./serviceProviderSearch.types";

export const useServiceProviderSearchHooks = (
  props: ServiceProvideSearchHooksProps
) => {
  const { type, params, filterOptions } = props;
  const query = props.query || EMPTY;
  const [options, setOptions] = useState<LabelValue[]>([]);
  const {
    serviceProviderState,
    dealPartyIndividualsSet,
    rankedSort,
    dataRank
  } = useServiceProviderHooks();
  const { searchApi } = useSearchApiHooks(props);
  const { fetchData, response } = searchApi();

  const selectedSearchCursor =
    serviceProviderState.searchCursor.query[
      buildCursorKey({ type, query, params }) || EMPTY
    ];

  useEffect(() => {
    const rankedOptions = rankedSort(query).filter(removeEmpty);
    const filteredOptions = rankedOptions.filter(filterOptions);

    const result = filteredOptions.map((p) =>
      buildPersonalRolodexOption({
        provider: p,
        dealPartyIndividuals: dealPartyIndividualsSet
      })
    );
    if (result.length < 10) {
      fetchData(
        query,
        selectedSearchCursor?.nextCursor
          ? selectedSearchCursor.nextCursor
          : undefined
      );
    }

    query
      ? setOptions([addProviderOption[type]].concat(result))
      : setOptions(result);

    return () => {
      fetchData.cancel();
    };
  }, [serviceProviderState?.entities, query]);

  const customFilterLogic: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    if (!searchText) {
      return true;
    }
    const selectedOption = option.data.value;
    if (selectedOption === addProviderOption[type].value) {
      return true;
    }
    const serviceProvider = serviceProviderState?.entities?.[selectedOption];
    if (!serviceProvider?.id) {
      return false;
    }
    return (
      getRankedData(searchText, serviceProvider, dataRank) > 0.5 ||
      searchText === serviceProvider.id.toLowerCase()
    );
  };

  const customFilter = useCallback(customFilterLogic, [
    serviceProviderState?.entities
  ]);

  return {
    serviceProviderState,
    query,
    response,
    fetchData,
    customFilter,
    options,
    selectedSearchCursor
  };
};

const useSearchApiHooks = (props: ServiceProvideSearchHooksProps) => {
  const { params, type } = props;

  const [searchCompanyApi, searchCompanyApiResponse] =
    serviceProviderApi.useLazySearchCompanyQuery();

  const [searchBranchApi, searchBranchApiResponse] =
    serviceProviderApi.useLazySearchBranchQuery();

  const [searchRepApi, searchRepApiResponse] =
    serviceProviderApi.useLazySearchRepQuery();

  const searchApi = () => {
    if (type === ServiceProviderType.Company) {
      return {
        response: searchCompanyApiResponse,
        fetchData: debounce((query: string, cursor?: string) => {
          if (query) {
            searchCompanyApi(
              {
                query,
                cursor,
                ...params
              },
              true
            );
          }
        }, AppConfig.search.debounceRate)
      };
    }
    if (type === ServiceProviderType.Branch) {
      return {
        response: searchBranchApiResponse,
        fetchData: debounce((query: string, cursor?: string) => {
          if (query) {
            searchBranchApi(
              {
                query,
                cursor,
                ...params
              },
              true
            );
          }
        }, AppConfig.search.debounceRate)
      };
    }
    if (type === ServiceProviderType.Representative) {
      return {
        response: searchRepApiResponse,
        fetchData: debounce((query: string, cursor?: string) => {
          if (query) {
            searchRepApi(
              {
                query,
                cursor,
                ...params
              },
              true
            );
          }
        }, AppConfig.search.debounceRate)
      };
    }
    throw new Error("Search type not support");
  };

  return {
    searchApi
  };
};

const buildCursorKey = (
  props: Pick<ServiceProvideSearchHooksProps, "params" | "type"> & {
    query: string;
  }
) => {
  const { params, type, query } = props;
  if (type === ServiceProviderType.Company) {
    return buildCompanyCursorKey({
      ...(params as CompanySearchRequestParams),
      query
    });
  }
  if (type === ServiceProviderType.Branch) {
    return buildBranchCursorKey({
      ...(params as BranchSearchRequestParams),
      query
    });
  }
  if (type === ServiceProviderType.Representative) {
    return buildRepCursorKey({
      ...(params as RepSearchRequestParams),
      query
    });
  }
};
