import { TObject, TSchema } from "@sinclair/typebox";
import Ajv from "ajv";
import addFormats, { FormatsPluginOptions } from "ajv-formats";

//--------------------------------------------------------------------------------------------
//
// Setup Ajv validator with the following options and formats
//
//--------------------------------------------------------------------------------------------

const ajvInstance = new Ajv({
  allErrors: true
});
const formats: FormatsPluginOptions = [
  "date-time",
  "time",
  "date",
  "email",
  "hostname",
  "ipv4",
  "ipv6",
  "uri",
  "uri-reference",
  "uuid",
  "uri-template",
  "json-pointer",
  "relative-json-pointer",
  "regex"
];
const ajv = addFormats(ajvInstance, formats);
interface ValidatorFactoryReturn<T> {
  schema: TObject | TSchema;
  verify: (data: T) => ValidationResponse<T>;
}
export type ValidationResponse<T> =
  | {
      status: "valid";
      data: T;
    }
  | { status: "failed"; errors: string };
export const validatorFactory = <T extends unknown>(
  schema: TObject | TSchema
): ValidatorFactoryReturn<T> => {
  const C = ajv.compile(schema); //TypeCompiler.Compile(schema);

  const verify = (data: T): ValidationResponse<T> => {
    const isValid = ajv.validate(schema, data); //C.Check(data);

    if (isValid) {
      return { status: "valid", data };
    }
    return {
      status: "failed",
      errors: JSON.stringify(
        //[...C.Errors(data)].map(({ path, message }) => ({ path, message }))
        //C.errors?.map(({ instancePath, message,params }) => ({ path:instancePath, message,params }))
        C.errors?.reduce((p, v) => {
          if (p[v.instancePath]) {
            p[v.instancePath].params.push(v.params);
          } else {
            p[v.instancePath] = {
              path: v.instancePath,
              message: v.message,
              params: [v.params]
            };
          }
          return p;
        }, {} as { [key: string]: { path: string; message: string | undefined; params: Record<string, any>[] } })
      )
    };
  };

  return { schema, verify };
};
