import { set } from "lodash";
import { useEffect, useState } from "react";
import {
  ElphiNode,
  TreeHook,
  TreeHookProps,
  TreeOperations
} from "./types/Tree.types";

const useTree = <T,>(props: TreeHookProps<T>): TreeHook<T> => {
  const [treeState, setTreeState] = useState<ElphiNode<T>>(props.initialState);
  const [idsThatHaveFetchedChildren, setIdsThatHaveFetchedChildren] = useState<
    string[]
  >([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState<string | null>(null);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [refreshToggler, setRefreshToggler] = useState<boolean>(false);

  const traverseTree = <T,>(
    rootNode: ElphiNode<T>,
    breakCondition: (node: ElphiNode<T>) => boolean
  ) => {
    const queue: ElphiNode<T>[] = [];
    if (rootNode != null) {
      queue.push(rootNode);
      while (queue.length) {
        const node = queue.pop();
        if (node != null) {
          if (breakCondition(node)) return node;
          node.children.forEach((child) => {
            queue.push(child);
          });
        }
      }
    }
    return null;
  };

  // There are defined before elphiTreeOperations because
  // they are used in some of the operations themselves
  const reusableOperations: Pick<
    TreeOperations<T>,
    "findNode" | "findParentNode"
  > = {
    findNode: (rootNode: ElphiNode<T>, id: string) => {
      return traverseTree(rootNode, (node) => {
        return node.id === id;
      });
    },
    findParentNode: (rootNode: ElphiNode<T>, nodeKey: string) => {
      return traverseTree(rootNode, (node) => {
        return node.children.map((c) => c.nodeKey).includes(nodeKey);
      });
    }
  };

  useEffect(() => {
    if (
      treeState.data === null ||
      !reusableOperations.findNode(treeState, props.initialState.id)
    ) {
      setTreeState(props.initialState);
      setIdsThatHaveFetchedChildren([]);
    }
  }, [props.initialState]);

  const elphiTreeOperations: TreeOperations<T> = {
    ...reusableOperations,
    getRoot: () => treeState,
    selectedNodeKey: selectedNodeKey,
    selectedNodeId: selectedNodeId,
    setSelectedNode: (node: ElphiNode<T> | null) => {
      if (!node) {
        setSelectedNodeKey("");
        setSelectedNodeId("");
      } else {
        setSelectedNodeKey(node.nodeKey);
        setSelectedNodeId(node.id);
      }
    },

    addNode: (srcId, newNode) => {
      const srcNode = reusableOperations.findNode(treeState, srcId);
      if (srcNode) {
        srcNode.children.push(newNode);
        setTreeState({ ...treeState });
        return newNode;
      }
      return null;
    },
    updateNode: (id, newInfo) => {
      const node = reusableOperations.findNode(treeState, id);
      if (node != null) {
        set(node, "data", newInfo);
        setTreeState({ ...treeState });
        return node;
      }
      return null;
    },
    updateChildren: (id, childrenArray) => {
      const node = reusableOperations.findNode(treeState, id);
      if (node != null) {
        set(node, "children", childrenArray);
        setTreeState({ ...treeState });
        return node;
      }
      return null;
    },
    deleteNode: (id) => {
      const node = reusableOperations.findNode(treeState, id);
      if (node != null && selectedNodeKey) {
        const parentNode = reusableOperations.findParentNode(
          treeState,
          selectedNodeKey
        );
        if (parentNode != null) {
          parentNode.children = parentNode.children.filter((c) => c.id !== id);
          setTreeState({ ...treeState });
          return node;
        }
      }
      if (node == null) return null;
      return null;
    },
    getIdsThatHaveFetchedChildren: idsThatHaveFetchedChildren,
    pushToIdsThatHaveFetchedChildren: (id) =>
      setIdsThatHaveFetchedChildren([...idsThatHaveFetchedChildren, id]),
    refreshState: refreshToggler,
    toggleRefresh: () => {
      setRefreshToggler(!refreshToggler);
    }
  };

  return { treeState, elphiTreeOperations, setTreeState };
};

export default useTree;
