import { TaskInstruction } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { taskInstructionSlice } from "./taskInstruction.slice";

export const taskInstructionAction: ActionDispatcher<TaskInstruction> = (
  dispatch,
  callback
) => {
  const actionDispatcher = buildAction(taskInstructionSlice);
  return actionDispatcher(dispatch, callback);
};
