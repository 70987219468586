import { EntityState } from "@reduxjs/toolkit";
// Need to use the React-specific entry point to allow generating React hooks
import { DealPartyRelation } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";

import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";
import { dealPartyEntityAdapter } from "./dealPartyRelation.adapter";

// Define a service using a base URL and expected endpoints
export const dealPartyRelationBaseApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "dealPartyRelationApi",
  tagTypes: ["Deal-Party-Relation", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "deal-party"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: dealPartyEntityAdapter
  })
});

export const dealPartyRelationApi = dealPartyRelationBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    children: builder.query<EntityState<DealPartyRelation>, string>({
      query: (id) => {
        return {
          url: `/${id}`,
          method: "GET"
        };
      },
      transformResponse: (response: DealPartyRelation[]) => {
        const adapter = dealPartyEntityAdapter.addMany(
          dealPartyEntityAdapter.getInitialState(),
          response
        );
        return adapter;
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { relations: ({ id: string } & Partial<DealPartyRelation>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    })
  })
});
