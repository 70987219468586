import { Deal } from "@elphi/types";
import { ElphiTrxStatus } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/funding-shield/fundingShield.types";
import {
  BUTTON_TOOLTIP_MESSAGES as BUTTON_TOOLTIP_MESSAGES_COMMON,
  ELPHI_TRX_STATUS_TOOLTIP_MESSAGES as ELPHI_TRX_STATUS_TOOLTIP_MESSAGES_COMMON
} from "../integration.type";
export enum ServiceOption {
  OrderCertificate = "Order Certificate"
}

export const ELPHI_TRX_STATUS_TOOLTIP_MESSAGES = {
  ...ELPHI_TRX_STATUS_TOOLTIP_MESSAGES_COMMON,
  [ElphiTrxStatus.Success]:
    "File was received, and successfully uploaded into the Elphi Task."
};

export const FUNDING_SHIELD_FAILED_STEP_LABEL_TOOLTIP_MESSAGES = {
  failedInitialization:
    "Failed to place the order. Please review all mandatory fields and files, correct any relevant items, and retry to order. If the issue persists, contact Elphi.",
  failedUploadingCertificate:
    "File was received, but failed to upload into the Elphi Task. If the issue persists, contact Elphi.",
  failedOrder:
    "There was an issue with the order, please contact Funding Shield for support."
};

export const BUTTON_TOOLTIP_MESSAGES = {
  ...BUTTON_TOOLTIP_MESSAGES_COMMON,
  contactFundingShield:
    "There was an issue with the order, please contact Funding Shield for support.",
  alreadyOrdered: "A certificate was already ordered - you can only order once",
  ...ELPHI_TRX_STATUS_TOOLTIP_MESSAGES,
  ...FUNDING_SHIELD_FAILED_STEP_LABEL_TOOLTIP_MESSAGES
};

export type DealStructureState = {
  deals: { [id: string]: Deal };
};
