import { FirestoreListenerHookSettings } from "../hooks/listener.hook";

export type ListenersConfig = {
  settings?: FirestoreListenerHookSettings;
};
export type AppConfiguration = {
  debounceRate: number;
  rtk: {
    cache: {
      //in seconds
      keepUnusedDataFor: number;
    };
  };
  search: {
    debounceRate: number;
  };
  listeners?: ListenersConfig;
};

export const AppConfig: AppConfiguration = {
  debounceRate: 1000,
  rtk: {
    cache: {
      keepUnusedDataFor: 360 * 6
    }
  },
  search: {
    debounceRate: 300
  },
  listeners: {
    settings: {
      operation: "debounce",
      wait: 2200,
      debounce: {
        maxWait: 5000,
        // leading: true,
        trailing: true
      }
    }
  }
};
