import { RepeatIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { useEffect } from "react";
import { useFirstMountHook } from "../../../hooks/firstMount.hook";
import {
  NOTIFICATION_USER_LIMIT,
  useNotificationTableHooks,
  useNotificationUserHooks
} from "../../../hooks/notificationUser.hooks";
import { useNotificationUserSubscriber } from "../../../hooks/notificationUserSubscriber.hooks";
import { EventLoadButton } from "../../audit-log/modal/list/EventLoadButton";
import { NotificationFilter } from "./NotificationFilter";
import { NotificationList } from "./NotificationList";

export const NotificationContent = () => {
  const { toggleNotifyApi, toggleStatusApi, notificationUserState } =
    useNotificationUserHooks();
  const { nextPage, paginateApiResponse, currentCombination, hasMore } =
    useNotificationTableHooks();
  const isFirstMount = useFirstMountHook();
  const { hasNew, setHasNew } = useNotificationUserSubscriber();

  useEffect(() => {
    nextPage({ preferCacheValue: false });
  }, []);

  useEffect(() => {
    if (!isFirstMount) {
      nextPage();
    }
  }, [
    notificationUserState.filters.current.dealIds,
    notificationUserState.filters.current.createdUID,
    notificationUserState.filters.current.fieldPath,
    notificationUserState.filters.current.auditType,
    notificationUserState.filters.current.entityType,
    notificationUserState.filters.current.unread
  ]);

  const handleReloadClick = () => {
    setHasNew(false);
    nextPage({ preferCacheValue: false });
    toggleNotifyApi();
  };

  const isLoading =
    paginateApiResponse.isLoading || paginateApiResponse.isFetching;

  const isDisabled = isLoading || !hasNew;

  const data = currentCombination.ids
    .map((x) => notificationUserState.entities[x])
    .filter(removeEmpty);

  return (
    <Box>
      <Box>
        <NotificationFilter />
        <EventLoadButton
          text={"Load New Notifications"}
          onClick={handleReloadClick}
          isDisabled={isDisabled}
          icon={<RepeatIcon w={4} h={4} />}
        />
      </Box>
      <NotificationList
        data={data}
        onToggleStatus={toggleStatusApi}
        hasMore={hasMore}
        isLoading={isLoading}
        next={nextPage}
        height={640}
        limit={NOTIFICATION_USER_LIMIT}
      />
    </Box>
  );
};
