import _ from "lodash";
import { useEffect, useRef } from "react";
import { NOT_AVAILABLE } from "../constants/common";

export const sendError = (error: Error) => {
  console.log(error);
};

export const exists = (...args: (string | object[])[]) => {
  let doItemsExist = true;
  for (let i = 0; i < args.length; i++) {
    if (args[i] === null || args[i] === undefined) {
      doItemsExist = false;
      break;
    } else if (
      typeof args[i] === "object" &&
      Object.keys(args[i]).length === 0
    ) {
      doItemsExist = false;
      break;
    } else if (Array.isArray(args[i]) && args[i].length === 0) {
      doItemsExist = false;
      break;
    } else if (typeof args[i] === "string" && args[i].length === 0) {
      doItemsExist = false;
      break;
    }
  }
  return doItemsExist;
};

export const usePrevious = (value?: string | number) => {
  // The ref object is a generic container whose current property is mutable
  //    and can hold any value, similar to an instance property on a class
  const ref = useRef<undefined | string | number>();
  // Store current value in ref. Only re-run if value changes
  useEffect(() => {
    ref.current = value;
  }, [value]);
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const getStringValueOrDefault = (
  value?: string | null,
  defaultValue = NOT_AVAILABLE
) => {
  return value || defaultValue;
};

export const capitalize = (s: string) => _.startCase(_.toLower(s));

export const toFirebaseId = (s: string) => s.toLowerCase().replaceAll(" ", "_");

export const fromFirebaseIdToLabel = (s: string) => {
  return capitalize(s).replaceAll("_", " ");
};

export const capitalizePhrase = (s: string[]) =>
  s.map((x) => _.startCase(_.toLower(x))).join(" ");

export const sequentializeIndexes = <T extends { index: number }>(
  data: Record<string, T>
): Record<string, T> => {
  return Object.entries(data)
    .sort(([, a], [, b]) => a.index - b.index)
    .reduce((acc, [key, value], index) => {
      value.index = index;
      acc[key] = value;
      return acc;
    }, {} as Record<string, T>);
};
