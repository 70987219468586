import { PartyValidationConfiguration } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { partyValidationConfigSlice } from "./partyValidationConfiguration.slice";

export const partyValidationConfigurationAction: ActionDispatcher<
  PartyValidationConfiguration
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(partyValidationConfigSlice);
  return actionDispatcher(dispatch, callback);
};
