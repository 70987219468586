import { Status } from "../common";
import { BaseEntity } from "../db";

export enum PartyValidationType {
  TaxpayerIdentifierDuplication = "taxpayerIdentifierDuplication",
  PartyGroupDuplication = "partyGroupDuplication"
}

export type PartyValidationConfiguration = BaseEntity<{
  validations: { [key in PartyValidationType]: Status };
}>;

export type PartialValidationConfiguration =
  PartyValidationConfiguration extends { validations: infer V }
    ? { validations?: Partial<V> } | PartyValidationConfiguration
    : never;
