import { LabelValue, PosUser } from "@elphi/types";
import { buildPosUserName } from "@elphi/utils";
import { NOT_AVAILABLE } from "../../../constants/common";
import { usePosUserHooks } from "../../../hooks/posUser.hooks";
import { FuzzySearchSelect } from "../../search/fuzzy/FuzzySearchSelect";
import { SelectSearchProps } from "../../search/search.types";

const buildOption = (p: PosUser) => {
  const label = `${buildPosUserName(p) || NOT_AVAILABLE} | ${
    p.email || NOT_AVAILABLE
  }`;
  const value = p.email;
  return {
    label,
    value
  };
};

export const PosUserSearch = (
  props: SelectSearchProps & {
    defaultOption?: LabelValue;
    isReadOnly: boolean;
  }
) => {
  const { searchApi, searchResponse, posUserState } = usePosUserHooks();
  return (
    <FuzzySearchSelect
      {...props}
      label={"Search POS User"}
      searchApi={searchApi}
      searchResponse={searchResponse}
      state={posUserState}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["firstName", "lastName", "email"],
        shouldSort: true
      }}
      buildOption={buildOption}
      defaultOptions={props.defaultOption ? [props.defaultOption] : undefined}
      wrapperStyle={{ p: "0px" }}
    />
  );
};
