import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, IconButton, Link } from "@chakra-ui/react";
import { Party } from "@elphi/types";
import { useOrgHooks } from "../../hooks/org.hooks";
import { NodeAction } from "../tree/types/Tree.types";

export const NavigatePartyIcon = () => {
  // const navigate = useNavigate();

  return (
    <IconButton
      size="xs"
      aria-label="party-navigation"
      bgColor="white"
      icon={<ExternalLinkIcon />}
      onClick={(_) => {
        // navigate("/dashboard/parties");
      }}
    />
  );
};

export const PartyNewTabNavigation = (props: { partyId?: string }) => {
  const { selectedOrgId } = useOrgHooks();
  const hrefUrl =
    props.partyId &&
    selectedOrgId &&
    !!selectedOrgId.toString() &&
    //IMPORTANT NOTE:
    // this F'ing forward slash is critical to set if it is a full replace (with the forward slash) or concat (without a forward slash)
    `/${selectedOrgId.toString()}/dashboard/parties?party=${props.partyId}`;
  return (
    <>
      {hrefUrl ? (
        <Link href={hrefUrl} isExternal>
          <NavigatePartyIcon />
        </Link>
      ) : (
        <Box>Please Select an Organization</Box>
      )}
    </>
  );
};

export const navigateAction = (props: {
  partyId?: string;
  callback?: NodeAction<Party>["onClick"];
}): NodeAction<Party> => {
  return {
    component: <PartyNewTabNavigation partyId={props.partyId} />,
    tooltip: `open party in new tab`,
    onClick: (node) => {
      /** Deprecated: using Link component for handling navigation */
      // console.log("navigate to: ", node.data?.id);
      // newTab(`/dashboard/parties?party=${node.data?.id}`, {});

      props?.callback?.(node);
    }
  };
};
