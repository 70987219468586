import {
  DocumentConfiguration,
  DocumentEntityType
} from "../documentConfiguration.types";

export const getDocumentConfigurationTemplate = () => {
  const documentConfiguration: DocumentConfiguration = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    autoGenerate: "" as DocumentConfiguration["autoGenerate"],
    documentEntity: "" as DocumentEntityType,
    documentRuleTemplateId: "",
    filePath: "",
    name: "",
    status: "" as DocumentConfiguration["status"],
    documentPackageType: []
  };
  return documentConfiguration;
};
