import { createSlice } from "@reduxjs/toolkit";
import lodash from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { dealApi } from "../deal/deal.service";
import { propertyApi } from "../property/property.service";
import { dealPropertyEntityAdapter as entityAdapter } from "./dealPropertyRelation.adapter";
import { dealPropertyRelationApi } from "./dealPropertyRelation.service";

export const dealPropertyRelationSlice = createSlice({
  name: "dealPropertyRelation",
  initialState: entityAdapter.getInitialState(),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(dealPropertyRelationApi)(builder);
    builder.addMatcher(
      dealPropertyRelationApi.endpoints.dealPropertyBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      dealPropertyRelationApi.endpoints.children.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      propertyApi.endpoints.propertyDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.dealProperties.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      propertyApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.page.dealProperty.map((p) => p.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.page.dealProperty, "id")
        };
      }
    );
  }
});
