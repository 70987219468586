import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from "@chakra-ui/react";
import {
  IntegrationBoardFilterType,
  IntegrationCenterFilterProps
} from "./integrationCenter.types";

export const IntegrationCenterFilter = (
  props: IntegrationCenterFilterProps
) => {
  const { title, options, onChange, selected, filterType } = props;
  const handleSelectChange = (value: string) => {
    onChange(
      selected
        ? selected.includes(value)
          ? selected.filter((option) => option !== value)
          : [...selected, value]
        : [value]
    );
  };

  switch (filterType) {
    case IntegrationBoardFilterType.Menu:
      return (
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            background={"transparent"}
            fontSize={14}
            fontWeight={"normal"}
            rightIcon={<ChevronDownIcon w={4} h={4} />}
          >
            {title}
            {selected?.length > 0 && (
              <NotificationBadge count={selected?.length} />
            )}
          </MenuButton>
          <MenuList zIndex={5} maxHeight={"200px"} overflow={"scroll"}>
            <MenuOptionGroup defaultValue={[]} type="checkbox">
              {options.map((option, i) => (
                <CheckboxItemOption
                  key={i}
                  value={option.value}
                  isChecked={selected?.includes(option.value)}
                  onChange={handleSelectChange}
                >
                  {option.label}
                </CheckboxItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      );

    case IntegrationBoardFilterType.CheckboxGroup:
      return (
        <CheckboxGroup
          value={selected}
          onChange={(values: string[]) => onChange(values)}
        >
          {options.map((option, i) => (
            <Checkbox
              mr={1}
              key={i}
              value={option.value}
              onChange={() => handleSelectChange(option.value)}
            >
              {option.label}
            </Checkbox>
          ))}
        </CheckboxGroup>
      );

    default:
      return <></>;
  }
};

type CustomMenuItemOptionProps = {
  children: React.ReactNode;
  value: string;
  isChecked: boolean;
  onChange: (value: string) => void;
};

const CheckboxItemOption = (props: CustomMenuItemOptionProps) => {
  const { children, value, isChecked, onChange } = props;
  const handleToggle = () => onChange(value);
  return (
    <MenuItemOption value={value} onClick={handleToggle}>
      <Checkbox isChecked={isChecked} pointerEvents="none">
        {children}
      </Checkbox>
    </MenuItemOption>
  );
};

const NotificationBadge = ({ count }) => {
  return (
    <Box display="inline-block">
      <Badge
        bgColor={"blue.500"}
        color={"white"}
        borderRadius="full"
        ml={2}
        px={2}
        py={"1"}
        fontWeight={"normal"}
      >
        {count}
      </Badge>
    </Box>
  );
};
