import { AuditEvent, AuditEventLinkResponse } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { EMPTY } from "../../../constants/common";
import sliceBuilder from "../builders/slice.builder";
import { auditEventEntityAdapter } from "./auditEvent.adapter";
import { auditEventApi } from "./auditEvent.service";

export enum AllEventsMode {
  Deal = "deal",
  Field = "field"
}

export type BaseAllEventsState<T extends AllEventsMode> = {
  mode?: T;
  current: {
    filter: AllEventsFilterState;
    cursor?: string;
    hasMore: boolean;
  };
  eventsFilterCursor: {
    [modeKey: string]: {
      hasNew: boolean;
      filters: {
        [cursorKey: string]: {
          cursor?: string;
          hasMore?: boolean;
        };
      };
    };
  };
  links?: {
    [id: string]: AuditEventLinkResponse;
  };
};

export type AllEventsDealState = BaseAllEventsState<AllEventsMode.Deal> & {
  selectedDealId?: EntityId;
};

export type AllEventsFieldState = BaseAllEventsState<AllEventsMode.Field> & {
  selectedFieldId?: string;
  selectedEntityId?: EntityId;
};

export type AllEventsState = AllEventsDealState | AllEventsFieldState;

export type AllEventsFilterState = {
  entities?: string[];
  fields?: string[];
  auditTypes?: string[];
  eventTypes?: string[];
  users?: string[];
  createdAt?: string;
};

export type AuditEventSliceState = EntityState<AuditEvent> & AllEventsState;

const initialState: AuditEventSliceState = {
  ids: [],
  entities: {},
  current: {
    filter: {
      entities: [],
      fields: [],
      auditTypes: [],
      eventTypes: [],
      users: []
    },
    hasMore: true
  },
  selectedDealId: undefined,
  selectedFieldId: undefined,
  selectedEntityId: undefined,
  eventsFilterCursor: {}
};

export const auditEventSlice = createSlice({
  name: "auditEvent",
  initialState: auditEventEntityAdapter.getInitialState(initialState),
  reducers: {
    update: auditEventEntityAdapter.updateOne,
    remove: auditEventEntityAdapter.removeOne,
    add: auditEventEntityAdapter.addOne,
    upsert: auditEventEntityAdapter.upsertOne,
    upsertMany: auditEventEntityAdapter.upsertMany,
    removeMany: auditEventEntityAdapter.removeMany,
    updateMany: auditEventEntityAdapter.updateMany,
    setSelectedEntityField: (
      state,
      action: PayloadAction<{ entityId: EntityId; fieldId: string }>
    ) => {
      state.mode = AllEventsMode.Field;
      if (state.mode === AllEventsMode.Field) {
        state.selectedFieldId = action.payload.fieldId;
        state.selectedEntityId = action.payload.entityId;
      }
    },
    selectedDealId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.mode = AllEventsMode.Deal;
      if (state.mode === AllEventsMode.Deal) {
        state.selectedDealId = action.payload.id;
      }
    },
    setAllEventsFilter: (
      state,
      action: PayloadAction<AllEventsFilterState>
    ) => {
      state.current.filter = action.payload;
      const modeKey = getModeKey(state);
      if (modeKey) {
        if (!state.eventsFilterCursor[modeKey]) {
          state.eventsFilterCursor[modeKey] = {
            hasNew: false,
            filters: {}
          };
        }

        const key = uniqCursorKey(action.payload);
        if (!state.eventsFilterCursor[modeKey]?.filters) {
          state.eventsFilterCursor[modeKey].filters = {};
        }
        state.eventsFilterCursor[modeKey].filters[key] = {
          ...state.eventsFilterCursor[modeKey].filters[key],
          hasMore: true
        };
      }
    },
    setAllEventsCursor: (state, action: PayloadAction<{ cursor?: string }>) => {
      state.current.cursor = action.payload.cursor;
      const modeKey = getModeKey(state);
      if (modeKey) {
        const key = uniqCursorKey(state.current.filter);
        if (!state.eventsFilterCursor[modeKey]?.filters) {
          state.eventsFilterCursor[modeKey] = {
            filters: { [key]: {} },
            hasNew: false
          };
        }
        state.eventsFilterCursor[modeKey].filters[key] = {
          ...state.eventsFilterCursor[modeKey].filters[key],
          cursor: action.payload.cursor
        };
      }
    },
    setAllEventsHasMore: (
      state,
      action: PayloadAction<{ hasMore: boolean }>
    ) => {
      state.current.hasMore = action.payload.hasMore;
      const modeKey = getModeKey(state);
      if (modeKey) {
        const key = uniqCursorKey(state.current.filter);
        if (!state.eventsFilterCursor[modeKey]?.filters) {
          state.eventsFilterCursor[modeKey] = {
            filters: { [key]: {} },
            hasNew: false
          };
        }
        state.eventsFilterCursor[modeKey].filters[key] = {
          ...state.eventsFilterCursor[modeKey].filters[key],
          hasMore: action.payload.hasMore
        };
      }
    },
    setAllEventsHasNew: (state, action: PayloadAction<{ hasNew: boolean }>) => {
      const modeKey = getModeKey(state);
      if (modeKey) {
        state.eventsFilterCursor[modeKey] = {
          ...state.eventsFilterCursor[modeKey],
          hasNew: action.payload.hasNew
        };
      }
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(auditEventApi)(builder);
    builder.addMatcher(
      auditEventApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page, "id")
        };
      }
    );
    builder.addMatcher(
      auditEventApi.endpoints.getLinks.matchFulfilled,
      (state, { payload }) => {
        state.links = {
          ...state.links,
          [payload.id]: payload.links
        };
      }
    );
  }
});

export const uniqCursorKey = (r: AllEventsFilterState) => {
  const { auditTypes, entities, eventTypes, fields, users } = r;
  return JSON.stringify({ auditTypes, entities, eventTypes, fields, users });
};

export const getModeKey = (r: AllEventsState) => {
  if (r.mode === AllEventsMode.Deal && r.selectedDealId) {
    return String(r.selectedDealId);
  }
  if (
    r.mode === AllEventsMode.Field &&
    r.selectedEntityId &&
    r.selectedFieldId
  ) {
    return `${r.selectedEntityId}_${r.selectedFieldId}`;
  }
  return EMPTY;
};
