import { Type } from "@sinclair/typebox";
import { StateCode, StateName } from "../entities";
import { EnumField, PostalCodeField, StringField } from "./utils.typebox";

export const BaseAddressSchema = Type.Object({
  AddressLineText: StringField,
  AddressUnitIdentifier: Type.Optional(StringField),
  CityName: StringField,
  CountyName: StringField,
  StateName: EnumField(StateName),
  StateCode: EnumField(StateCode),
  PostalCode: PostalCodeField
});

export const StateNameSchema = EnumField(StateName);
export const StateCodeSchema = EnumField(StateCode);
