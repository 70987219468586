import { Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  NotificationAuditEvent,
  NotificationStatus,
  StatusCode
} from "@elphi/types";
import { getToggledStatus } from "@elphi/utils";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewportList from "react-viewport-list";
import { responseHandler } from "../../../apis/rtk/response.handler";
import { RTKResponse } from "../../../apis/rtk/response.types";
import { useNotificationUserHooks } from "../../../hooks/notificationUser.hooks";
import { EventListSkeleton } from "../../audit-log/modal/list/EventListSkeleton";
import { EventLoadButton } from "../../audit-log/modal/list/EventLoadButton";
import { EventRowBuilder } from "../../audit-log/modal/row/EventRowBuilder";
import { ArrowDown, BorderCircleIcon, FullCircleIcon } from "../../icons";

type NotificationListProps = {
  data: NotificationAuditEvent[];
  next: () => void;
  isLoading: boolean;
  hasMore: boolean;
  limit: number;
  onToggleStatus: ReturnType<
    typeof useNotificationUserHooks
  >["toggleStatusApi"];
  height: number;
};

export const NotificationList = (props: NotificationListProps) => {
  const { data, next, isLoading, hasMore, limit, onToggleStatus } = props;
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={next}
      hasMore={true}
      height={props.height}
      loader={
        <>
          {isLoading && <EventListSkeleton number={6} />}
          {data.length > limit && (
            <EventLoadButton
              text={"Load More"}
              onClick={next}
              isDisabled={!hasMore}
              icon={<ArrowDown w={6} h={6} mt={3} mr={-4} />}
            />
          )}
        </>
      }
    >
      <ViewportList items={data} withCache={true}>
        {(item, i) => (
          <NotificationRow
            key={i}
            item={item}
            onToggleStatus={onToggleStatus}
          />
        )}
      </ViewportList>
    </InfiniteScroll>
  );
};

type NotificationRowProps = Pick<NotificationListProps, "onToggleStatus"> & {
  item: NotificationListProps["data"][0];
};

const NotificationRow = (props: NotificationRowProps) => {
  const { item, onToggleStatus } = props;
  const [status, setStatus] = useState<NotificationStatus>();

  useEffect(() => {
    setStatus(item.status);
  }, [item.status]);

  const handleStatusClick = () => {
    const toggledStatus = getToggledStatus(item.status);
    setStatus(toggledStatus);
    onToggleStatus({
      id: item.id,
      status: item.status
    })
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          setStatus(item.status);
        }
      });
  };

  return (
    <Flex alignItems={"center"}>
      <Flex direction="column" textAlign="left" width={"98%"}>
        <EventRowBuilder event={item} />
      </Flex>
      <Flex direction="column" textAlign="left">
        <IconButton
          minW={"unset"}
          bgColor={"transparent"}
          size={"sm"}
          variant={"unstyled"}
          aria-label={"notification-status-button"}
          pt={3}
          pl={3}
          pr={3}
          onClick={handleStatusClick}
          icon={
            status === NotificationStatus.SEEN_READ ? (
              <Tooltip label={"Mark as unread"}>
                <BorderCircleIcon w={6} h={6} />
              </Tooltip>
            ) : (
              <Tooltip label={"Mark as read"}>
                <FullCircleIcon w={6} h={6} />
              </Tooltip>
            )
          }
        />
      </Flex>
    </Flex>
  );
};
