import { useDispatch, useSelector } from "react-redux";

import { ElphiTableProps } from "../components/table/table.types";
import { RootState } from "../redux/store";
import { tableManagerSlice } from "../redux/v2/table-manager";

type BasicActionProps = {
  tableName: string;
};

export type MovementActionProps = BasicActionProps & {
  columnId: number;
};
export type SwitchActionProps = BasicActionProps & {
  columnAId: number;
  columnBId: number;
};

export const useTableManagerHook = () => {
  const dispatcher = useDispatch();
  const tablesState = useSelector((state: RootState) => state.tableManager);

  const getTableState = (tableName: string) => {
    const columnsOrder =
      tablesState && tableName in tablesState
        ? tablesState[tableName]
        : undefined;

    return columnsOrder;
  };

  const setTableColumns = ({
    tableName,
    headerCells
  }: {
    tableName: string;
    headerCells: ElphiTableProps["header"];
  }) => {
    const defaultMapped = headerCells.reduce((acc, c) => {
      acc[c.index] = { columnId: c.index, index: c.index };
      return acc;
    }, {} as { [columnId: number]: { columnId: number; index: number } });
    dispatcher(
      tableManagerSlice.actions.setTableColumns({
        tableName,
        defaultOrder: defaultMapped
      })
    );
  };

  const moveRight = ({ tableName, columnId }: MovementActionProps) => {
    const tableState = getTableState(tableName);
    if (tableState) {
      const { columnsOrder, maxIndex } = tableState;
      const index = columnsOrder[columnId]?.index;
      if (index !== undefined) {
        const newIndex = index + 1;
        newIndex <= maxIndex &&
          dispatcher(
            tableManagerSlice.actions.updateColumnsOrder({
              tableName,
              orderArray: [
                {
                  columnId,
                  newIndex
                }
              ]
            })
          );
      }
    }
  };

  const moveLeft = ({ tableName, columnId }: MovementActionProps) => {
    const tableState = getTableState(tableName);
    if (tableState) {
      const { columnsOrder, minIndex } = tableState;
      const index = columnsOrder[columnId]?.index;
      if (index !== undefined) {
        const newIndex = index - 1;
        newIndex > minIndex &&
          dispatcher(
            tableManagerSlice.actions.updateColumnsOrder({
              tableName: tableName,
              orderArray: [
                {
                  columnId,
                  newIndex
                }
              ]
            })
          );
      }
    }
  };

  const moveToStart = ({ tableName, columnId }: MovementActionProps) => {
    const tableState = getTableState(tableName);
    if (tableState) {
      const { minIndex } = tableState;
      dispatcher(
        tableManagerSlice.actions.updateColumnsOrder({
          tableName,
          orderArray: [
            {
              columnId,
              newIndex: minIndex + 1
            }
          ]
        })
      );
    }
  };

  const moveToEnd = ({ tableName, columnId }: MovementActionProps) => {
    const tableState = getTableState(tableName);
    if (tableState) {
      const { maxIndex } = tableState;
      dispatcher(
        tableManagerSlice.actions.updateColumnsOrder({
          tableName,
          orderArray: [
            {
              columnId,
              newIndex: maxIndex
            }
          ]
        })
      );
    }
  };

  const switchColumns = ({
    tableName,
    columnAId,
    columnBId
  }: SwitchActionProps) => {
    const tableState = getTableState(tableName);
    if (tableState) {
      const { columnsOrder } = tableState;
      const indexA = columnsOrder[columnAId]?.index;
      const indexB = columnsOrder[columnBId]?.index;
      if (indexA !== undefined && indexB !== undefined) {
        dispatcher(
          tableManagerSlice.actions.updateColumnsOrder({
            tableName,
            orderArray: [
              {
                columnId: columnAId,
                newIndex: indexB
              },
              {
                columnId: columnBId,
                newIndex: indexA
              }
            ]
          })
        );
      }
    }
  };

  const toggleReorderMode = ({ tableName }: { tableName: string }) => {
    const tableState = getTableState(tableName);
    if (tableState) {
      dispatcher(tableManagerSlice.actions.toggleReorderMode({ tableName }));
    }
  };

  const resetOrder = ({ tableName }: { tableName: string }) => {
    const tableState = getTableState(tableName);
    if (tableState) {
      const { defaultOrder } = tableState;
      const orderArray = Object.values(defaultOrder).map((d) => ({
        columnId: d.columnId,
        newIndex: d.index
      }));
      dispatcher(
        tableManagerSlice.actions.updateColumnsOrder({ tableName, orderArray })
      );
    }
  };

  type SortCells = <T extends { index: number }>(args: {
    tableName?: string;
    cells: T[];
  }) => T[];

  const sortCellsByCustomOrder: SortCells = ({ tableName, cells }) => {
    const { columnsOrder } = (tableName && getTableState(tableName)) || {};
    return cells.sort((a, b) => {
      const columnAIndex = columnsOrder ? columnsOrder[a.index].index : a.index;
      const columnBIndex = columnsOrder ? columnsOrder[b.index].index : b.index;
      return columnAIndex > columnBIndex ? 1 : -1;
    });
  };

  const sortCellsByDefault: SortCells = ({ cells }) => {
    return cells.sort((a, b) => (a.index > b.index ? 1 : -1));
  };

  const getSortCallback = ({
    isOrderable
  }: {
    isOrderable?: boolean;
  }): SortCells => {
    return isOrderable ? sortCellsByCustomOrder : sortCellsByDefault;
  };

  return {
    getTableState,
    setTableColumns,
    moveRight,
    moveLeft,
    switchColumns,
    resetOrder,
    toggleReorderMode,
    getSortCallback,
    moveToStart,
    moveToEnd
  };
};
