import { RolodexServiceProviderView } from "@elphi/types";
import { buildAction } from "../../builders/action.builder";
import { ActionDispatcher } from "../../types/action.types";
import { serviceProviderViewSlice } from "./serviceProviderView.slice";

export const serviceProviderViewAction: ActionDispatcher<
  RolodexServiceProviderView
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(serviceProviderViewSlice);
  return actionDispatcher(dispatch, callback);
};
