import {
  AuditEventEntityType,
  AuditEventFieldType,
  AuditEventOperation,
  ElphiInternalUser,
  LabelValue,
  ThirdPartyAggregationField
} from "@elphi/types";
import { isPlainObject, reduce } from "lodash";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { createSelectOptions } from "../../../utils/formUtils";
import {
  entityMap,
  entityRelationMap,
  fieldTypeMap
} from "../row/eventRow.utils";

type ObjectFieldSpec = {
  [key: string]: unknown;
};

export const collectFieldSpecLabels = (
  obj: ObjectFieldSpec,
  currentPath: string = EMPTY
): LabelValue[] => {
  return reduce(
    obj,
    (result: LabelValue[], value, key) => {
      try {
        const path = currentPath ? `${currentPath}.${key}` : key;
        if (isPlainObject(value)) {
          return result.concat(
            collectFieldSpecLabels(value as ObjectFieldSpec, path)
          );
        }
        if (
          key === "label" &&
          typeof path === "string" &&
          typeof value === "string"
        ) {
          const cleanPath = path.replace(".label", EMPTY);

          if (value === NOT_AVAILABLE) {
            return result.concat([{ value: cleanPath, label: cleanPath }]);
          }
          const lastKey = cleanPath.split(".")?.at(-1);
          if (lastKey && aggKeys.has(lastKey)) {
            if (lastKey === "focused") {
              return result.concat([{ value: cleanPath, label: cleanPath }]); //since focus is labeled as focused, returning full path
            }
            return result.concat([
              { value: cleanPath, label: `${value} - ${lastKey}` }
            ]);
          }
          return result.concat([{ value: cleanPath, label: value }]);
        }
        return result;
      } catch (error) {
        console.error("collectFieldSpecLabels", error);
        return [];
      }
    },
    []
  );
};

const aggProps: Array<keyof ThirdPartyAggregationField> = [
  "focused",
  "override",
  "calculated",
  "thirdParty"
];
const aggKeys = new Set(aggProps as string[]);

const auditEventMap = {
  [AuditEventOperation.Created]: "Created",
  [AuditEventOperation.Updated]: "Updated",
  [AuditEventOperation.Deleted]: "Deleted"
} as const;

export const entityOptions = createSelectOptions(AuditEventEntityType, {
  ...entityMap,
  ...entityRelationMap
}).map((x): LabelValue => x);

export const auditFieldOptions = createSelectOptions(
  AuditEventFieldType,
  fieldTypeMap
).map((x): LabelValue => x);

export const auditEventOptions = createSelectOptions(
  AuditEventOperation,
  auditEventMap
).map((x): LabelValue => x);

const internalUsersMap: Readonly<{ [f in ElphiInternalUser]: string }> = {
  [ElphiInternalUser.Automation]: ElphiInternalUser.Automation,
  [ElphiInternalUser.Migration]: ElphiInternalUser.Migration
};

export const elphiInternalUsers = createSelectOptions(
  ElphiInternalUser,
  internalUsersMap
).map((x): LabelValue => x);

export const allEventFilters = {
  entities: "Entities",
  fields: "Fields",
  auditTypes: "Audit Type",
  eventTypes: "Audit Event",
  users: "Users"
} as const;
