import { AggregationFocusType, FieldType } from "@elphi/types";
import { limaDealOperationsDepartmentType } from "@elphi/types/entities/lima";
import {
  BaseCommonPartyFieldsOmit,
  LimaBasePartyFields
} from "@elphi/types/entities/lima/party";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import {
  createOptionsFromEnumAllowUndefined,
  createOptionsFromMap
} from "../../../../../components/utils/formUtils";
import {
  BaseSchemaCommonPartyFieldSpecs,
  baseSchemaCommonPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import {
  mergeSpecs,
  modifyFromSpec,
  omitFromSpec
} from "../../../utils/formsSpec.utils";

type LimaCommonPartyFieldSpecs = EntityFormFieldSpecs<LimaBasePartyFields>;

export type FullLimaCommonPartyFieldSpecs =
  Partial<BaseSchemaCommonPartyFieldSpecs> & LimaCommonPartyFieldSpecs;

const limaCommonPartyFieldSpecs: LimaCommonPartyFieldSpecs = {
  aggregations: {
    LOCExpirationDate: {
      calculated: {
        fieldKey: ["aggregations", "LOCExpirationDate", "calculated"],
        fieldType: FieldType.Date,
        label: "LOC Expiration Date"
      },
      override: {
        fieldKey: ["aggregations", "LOCExpirationDate", "override"],
        fieldType: FieldType.Date,
        label: "LOC Expiration Date"
      },
      focused: {
        fieldKey: ["aggregations", "LOCExpirationDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  CRMId: {
    fieldKey: ["CRMId"],
    fieldType: FieldType.String,
    label: "CRM ID"
  }
};
const overriddenBaseSchemaPartySpecs = {
  OperationsDepartment: modifyFromSpec({
    fieldSpecs: baseSchemaCommonPartyFieldSpecs.OperationsDepartment,
    changes: {
      label: "Operations Department",
      options: createOptionsFromMap(limaDealOperationsDepartmentType)
    }
  })
};

export const limaPartyPathsToOmitFromBase: BaseCommonPartyFieldsOmit = [
  "CitizenshipResidency",
  "CreditScore",
  "CreditReportDate",
  "AuthorizedContacts",
  "NumberOfRehabProjects"
];

export const limaCommonPartySpecs: FullLimaCommonPartyFieldSpecs = mergeSpecs(
  omitFromSpec({
    obj: baseSchemaCommonPartyFieldSpecs,
    paths: limaPartyPathsToOmitFromBase
  }),
  overriddenBaseSchemaPartySpecs,
  limaCommonPartyFieldSpecs
);
