export enum PropertyInsurancePolicyCoverageType {
  Hazard = "hazard",
  Flood = "flood",
  Liability = "liability",
  BuildersRisk = "buildersRisk",
  RentLoss = "rentLoss",
  Earthquake = "earthquake",
  Wind = "wind",
  CondoMasterHazard = "condoMasterHazard",
  HO6 = "hO6",
  CondoMasterFlood = "condoMasterFlood",
  CAFairPlan = "cAFairPlan",
  ExcessFlood = "excessFlood",
  Lava = "lava"
}

export enum BuildingType {
  ADU = "ADU",
  SFR = "SFR",
  Duplex = "Duplex",
  Triplex = "Triplex"
}
