import { map, reduce, some } from "lodash";
import { useState } from "react";
import { WhereFilterOp } from "../../../../../shared/firestore/src/firebase/types";

export const MAX_TOTAL_QUERY: { [key in WhereFilterOp]?: number } = {
  in: 30,
  "not-in": 10
};

export interface FilterMenuHook {
  setFilter: (filter: { [key: string]: Array<string> }) => void;
  checkIfFilterLimitValid: (r: {
    selectedFilter?: string;
    currentOp: WhereFilterOp;
  }) => boolean;
  checkIfFilterSelectAllLimitValid: (r: {
    selectedFilter?: string;
    arrayLength: number;
    currentOp: WhereFilterOp;
  }) => boolean;
  clearFilters: () => void;
  isDisabledOp: () => boolean;
  filters: {
    [key: string]: Array<string>;
  };
}

export const useFilterMenuHook = (options?: {
  limitSelectedCount?: number;
}): FilterMenuHook => {
  const { limitSelectedCount } = options || {};

  const [filters, setFilters] = useState<{
    [key: string]: Array<string>;
  }>();

  const setFilter = (filter: { [key: string]: Array<string> }) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...filter }));
  };

  const checkIfFilterLimitValid = (r: {
    selectedFilter?: string;
    currentOp: WhereFilterOp;
  }) => {
    const { selectedFilter, currentOp } = r;

    if (!selectedFilter || !filters?.[selectedFilter]) {
      return false;
    }

    const lengthsArray = map(filters, (values, key) =>
      key === selectedFilter ? values.length + 1 : values.length
    );

    const totalQuerySum = reduce(
      lengthsArray,
      (accumulator, currentValue) => accumulator * (currentValue || 1),
      1
    );

    const maxTotalQuery = limitSelectedCount || MAX_TOTAL_QUERY[currentOp];

    return totalQuerySum >= (maxTotalQuery || 0) + 1;
  };

  const checkIfFilterSelectAllLimitValid = ({
    selectedFilter,
    arrayLength,
    currentOp
  }: {
    selectedFilter: string;
    arrayLength: number;
    currentOp: WhereFilterOp;
  }): boolean => {
    const lengthsArray = map(filters, (values, key) =>
      key === selectedFilter ? 1 : values.length
    );

    const totalQuerySum = reduce(
      lengthsArray,
      (accumulator, currentValue) => accumulator * (currentValue || 1),
      1
    );

    const maxTotalQuery = limitSelectedCount || MAX_TOTAL_QUERY[currentOp];

    return totalQuerySum * arrayLength > (maxTotalQuery || 1);
  };

  const clearFilters = () => {
    setFilters(undefined);
  };

  const isDisabledOp = () => some(filters, (filter) => filter.length > 0);

  return {
    setFilter,
    checkIfFilterLimitValid,
    checkIfFilterSelectAllLimitValid,
    isDisabledOp,
    clearFilters,
    filters: filters || {}
  };
};
