import { Box, Text } from "@chakra-ui/react";
import { NOT_AVAILABLE } from "../../constants/common";

export const MaximizedInfo = (props: {
  title: string;
  subTitle?: string;
  color?: string;
}) => {
  return (
    <Box>
      <Text
        color={props.color || "gray.500"}
        fontSize="14px"
        fontWeight={"500"}
      >
        {props.title}
      </Text>
      <Text color={"black"} fontSize="14px" fontWeight={"600"}>
        {props.subTitle || NOT_AVAILABLE}
      </Text>
    </Box>
  );
};
