import {
  Badge,
  Box,
  ListItem,
  Stack,
  Text,
  ThemeTypings,
  UnorderedList
} from "@chakra-ui/react";
import { getPasswordStrength, Strength } from "@elphi/utils";

const strengthColorMap: { [key in Strength]: ThemeTypings["colorSchemes"] } = {
  [Strength.Weak]: "red",
  [Strength.Medium]: "orange",
  [Strength.Strong]: "green"
};

const strengthTextMap: { [key in Strength]: string } = {
  [Strength.Weak]: "weak",
  [Strength.Medium]: "medium",
  [Strength.Strong]: "strong"
};

const getStrengthColor = (strength) => {
  return strengthColorMap[strength] || "gray";
};

const StrengthIndicator = ({
  strength
}: {
  strength: Strength | undefined;
}) => {
  const color = getStrengthColor(strength);
  return (
    <Box>
      <Text fontWeight="bold" mb={2}>
        Strength:
      </Text>

      <Badge
        colorScheme={color}
        textTransform="capitalize"
        px={3}
        py={1}
        borderRadius="md"
      >
        {strength ? strengthTextMap[strength] : strengthTextMap[Strength.Weak]}
      </Badge>
    </Box>
  );
};

const IssuesList = ({ issues }: { issues: string[] }) => {
  return issues.length ? (
    <Box>
      <Text fontWeight="bold" mb={2}>
        Issues:
      </Text>

      <UnorderedList spacing={1}>
        {issues.map((issue, index) => (
          <ListItem key={`${index}-${issue}`}> {issue}</ListItem>
        ))}
      </UnorderedList>
    </Box>
  ) : null;
};

export const PasswordStrengthMeter = ({ password }) => {
  const { strength, issues } = getPasswordStrength(password);

  return (
    <Box p={5} borderWidth="1px" borderRadius="lg" boxShadow="sm">
      <Stack spacing={8}>
        <StrengthIndicator strength={strength} />

        <IssuesList issues={issues} />
      </Stack>
    </Box>
  );
};
