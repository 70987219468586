import { Box, Tab, TabList, Tabs, Tooltip } from "@chakra-ui/react";
import { LabelValue } from "@elphi/types";
import { findIndex } from "lodash";
import { useEffect, useState } from "react";
import {
  FilterOp,
  WhereFilterOp
} from "../../../../../shared/firestore/src/firebase/types";
import { MenuOptions, MenuOptionsProps } from "./MenuOptions";
import { FilterMenuHook } from "./filter-menu-hook";

export type FilterMenuOptionsProps = {
  filterMenuHook: FilterMenuHook;
  selectedOp?: WhereFilterOp;
  onChangeOp?: (op: WhereFilterOp) => void;
  showFilterOp?: boolean;
  chunkLength?: number;
} & MenuOptionsProps;

const FILTER_OP_TABS: LabelValue<FilterOp, WhereFilterOp>[] = [
  { label: "OR", value: "in" },
  { label: "NOT", value: "not-in" }
];

export const FilterMenuOptions = ({
  filterMenuHook,
  selectedOp = "in",
  onChangeOp,
  showFilterOp = false,
  chunkLength,
  ...menuOptionsProps
}: FilterMenuOptionsProps) => {
  const [currentOp, setCurrentOp] = useState<WhereFilterOp>(selectedOp);
  const [selectedFilter, setSelectedFilter] = useState<string>();
  const [isFilterLimitValid, setIsFilterLimitValid] = useState<boolean>();
  const [isFilterSelectAllLimitValid, setIsFilterSelectAllLimitValid] =
    useState<boolean>();

  const {
    setFilter,
    checkIfFilterLimitValid,
    isDisabledOp,
    checkIfFilterSelectAllLimitValid,
    filters
  } = filterMenuHook;

  useEffect(() => {
    setCurrentOp(selectedOp);
  }, [selectedOp]);

  const onSelect = (selected: string[]) => {
    setFilter({ [menuOptionsProps.customKey]: selected || [] });
    menuOptionsProps.onChange(selected);
  };

  const onSelectOp = (op: WhereFilterOp) => {
    onChangeOp?.(op);
  };

  const onOpen = (selectedFilter: string) => {
    setSelectedFilter(selectedFilter);
    setIsFilterLimitValid(
      checkIfFilterLimitValid({ selectedFilter, currentOp })
    );
    setIsFilterSelectAllLimitValid(
      checkIfFilterSelectAllLimitValid({
        selectedFilter,
        arrayLength: menuOptionsProps?.options?.length,
        currentOp
      })
    );
  };

  const onClose = () => {
    if (!isDisabledOp()) {
      setCurrentOp("in");
      onChangeOp?.("in");
    }
  };

  const filterLimitValid = checkIfFilterLimitValid({
    selectedFilter,
    currentOp
  });

  const isSelectAllDisabled =
    isFilterSelectAllLimitValid ||
    checkIfFilterSelectAllLimitValid({
      selectedFilter,
      arrayLength: menuOptionsProps?.options?.length,
      currentOp
    }) ||
    filterLimitValid;

  return (
    <MenuOptions
      {...menuOptionsProps}
      selected={
        menuOptionsProps.selected || filters?.[menuOptionsProps.customKey] || []
      }
      onChange={onSelect}
      isSelectAllDisabled={isSelectAllDisabled}
      RightSearchElement={
        <>
          {showFilterOp && (
            <FilterOpElement
              onSelect={onSelectOp}
              selected={currentOp}
              isDisabled={isDisabledOp()}
            />
          )}
        </>
      }
      onOpen={onOpen}
      onClose={onClose}
      isUncheckedDisabled={isFilterLimitValid || filterLimitValid}
      uncheckedTooltipLabel={
        <Box whiteSpace="pre-line">
          You have reached the maximum number of filters.{"\n"} Clear other
          filters to enable this one.
        </Box>
      }
      chunkLength={chunkLength}
    />
  );
};

const FilterOpElement = ({ onSelect, selected, isDisabled }) => (
  <Tabs
    variant="unstyled"
    ml={"8px"}
    index={findIndex(FILTER_OP_TABS, { value: selected })}
  >
    <TabList>
      {FILTER_OP_TABS.map((tab, index) => (
        <Tooltip
          key={`tooltip-${index}`}
          isDisabled={isDisabled ? tab.value === selected : true}
          hasArrow
          label={
            <Box whiteSpace="pre-line">
              Cannot use {tab.label} when other filters are used.{"\n"}
              Clear all filters to enable this one.
            </Box>
          }
          placement="top-start"
        >
          <Tab
            key={`tab-${index}`}
            isDisabled={isDisabled && tab.value !== selected}
            onClick={() => onSelect(tab.value)}
            borderLeftRadius={!index ? "8px" : "none"}
            borderRightRadius={
              index === FILTER_OP_TABS.length - 1 ? "8px" : "none"
            }
            margin={"3px"}
            bgColor={"gray.300"}
            fontWeight={"bold"}
            _selected={{ color: "white", bg: "blue.600" }}
          >
            {tab.label}
          </Tab>
        </Tooltip>
      ))}
    </TabList>
  </Tabs>
);
