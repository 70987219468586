import {
  IntegrationTask,
  IntegrationTaskTemplate,
  IntegrationType,
  Task
} from "@elphi/types";
import { isIntegrationTaskGuard } from "@elphi/utils";
import { useSnapshotHooks } from "../../../hooks/snapshot.hooks";
import useTaskHooks from "../../../hooks/task.hooks";
import { TaskFileManager } from "../TaskFileManager";
import { PropertyReportTaskForm } from "./property-report/PropertyReportTaskForm";
import { RentalDataTaskForm } from "./rental-data/RentalDataTaskForm";
import { WireInsuranceTaskForm } from "./wire-insurance/WireInsuranceTaskForm";
import { TaskFormState } from "../../../shared/types/task.types";

export type IntegrationFormContainerProps<
  T extends IntegrationTask = IntegrationTask
> = {
  snapshotId?: string;
  state?: TaskFormState;
} & IntegrationTaskTemplate<T>;

const taskIntegrationForm = {
  [IntegrationType.WireInsuranceCertificate]: WireInsuranceTaskForm,
  [IntegrationType.PropertyReport]: PropertyReportTaskForm,
  [IntegrationType.RentalData]: RentalDataTaskForm
};

export const IntegrationFormContainer = ({
  snapshotId,
  selectedTask,
  state
}: IntegrationFormContainerProps) => {
  const integrationType = selectedTask?.integrationType;
  const TaskIntegrationForm = !!integrationType
    ? taskIntegrationForm?.[integrationType]
    : undefined;
  let snapshotSelectedTask: Task | undefined;
  if (snapshotId) {
    const { snapshotDataState } = useSnapshotHooks();
    const { taskState } = useTaskHooks();
    snapshotSelectedTask =
      (taskState?.selectedId &&
        snapshotDataState({ snapshotId: String(snapshotId) })?.taskState
          ?.entities?.[taskState.selectedId]) ||
      undefined;
  }

  if (snapshotSelectedTask && isIntegrationTaskGuard(snapshotSelectedTask)) {
    selectedTask = snapshotSelectedTask;
  }

  return TaskIntegrationForm ? (
    <>
      {!!selectedTask && (
        <TaskFileManager selectedTask={selectedTask} snapshotId={snapshotId} />
      )}
      <TaskIntegrationForm
        snapshotId={snapshotId}
        state={state}
        selectedTask={selectedTask}
      />
    </>
  ) : null;
};
