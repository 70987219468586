import { StatusCode } from "@elphi/types";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import {
  branchRepRelationApi,
  branchRepRelationSlice
} from "../redux/v2/rolodex";

export const useRolodexBranchRepRelation = () => {
  const dispatch = useDispatch();
  const [branchRepApi, branchRepResponse] =
    branchRepRelationApi.useLazyGetDealBatchQuery();
  const [cascadeDeleteApi, cascadeDeleteResponse] =
    branchRepRelationApi.useLazyCascadeDeleteQuery();
  const { successToast, errorToast } = useElphiToast();

  const branchRepState = useSelector(
    (state: RootState) => state.branchRepRelation
  );

  const getBranchRepByDealIdHandler = (
    dealId: string,
    preferCacheValue?: boolean
  ) => {
    branchRepApi({ dealId }, preferCacheValue)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get branch representative relations",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const cascadeDeleteHandler = async (r: {
    id: string;
    preprocess: boolean;
  }) => {
    const { preprocess } = r;
    return await cascadeDeleteApi(r, false)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.OK && !preprocess) {
          successToast({
            title: "Removed successfully",
            description: "Service provider relation removed successfully"
          });
        }
        if (r.status === StatusCode.BadRequest) {
          const op = preprocess ? "remove" : "preprocess";
          errorToast({
            title: `Failed to ${op}`,
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const resetPreprocessed = () =>
    dispatch(branchRepRelationSlice.actions.resetPreprocessed());

  const preprocessed = useSelector(
    (state: RootState) => state.branchRepRelation.preprocessed
  );

  return {
    getBranchRepByDealIdHandler,
    branchRepResponse,
    branchRepState,
    cascadeDeleteHandler,
    cascadeDeleteResponse,
    resetPreprocessed,
    preprocessed
  };
};
