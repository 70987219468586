import { TabsContainer } from "../../tabs-container/TabsContainer";
import { ServiceProviderTab } from "./tabs/ServiceProviderTab";

export const ServiceProviderPage = () => {
  return (
    <TabsContainer
      tabs={[{ label: "Service Providers", page: <ServiceProviderTab /> }]}
    />
  );
};

export default ServiceProviderPage;
