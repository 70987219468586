export const removeEmpty = <S>(value: S | undefined | null): value is S =>
  value !== undefined && value !== null;

export const removeBlankAndEmpty = <S>(
  value: S | undefined | null
): value is S => removeEmpty(value) && value !== "";

export const removeEmptyValues = <T>(
  dictionary: Record<string, T | undefined>
) =>
  Object.keys(dictionary)
    .map((id) => {
      return {
        ...dictionary[id],
        id
      };
    })
    .filter(removeEmpty);
