import { CreditScore, ElphiEntityType, IndividualParty } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../../components/form-builder/FormBuilder";
import {
  getTaxIdentifierFormatter,
  getTaxIdentifierValidator
} from "../../../../../components/utils/partyUtils";
import {
  individualBirthDate,
  individualFirstName,
  individualLastName,
  individualMiddleName,
  partyAddressLineText,
  partyAddressUnitIdentifier,
  partyCityName,
  partyPostalCode,
  partyStateCode,
  partyStateName,
  taxpayerIdentifierValue
} from "../../../base/inputs/party.inputs";
import { limaSpecs } from "../../../lima/spec-files/limaFieldsSpecs";
import { buildSection } from "../../../utils/formBuilder.utils";
import {
  creditScorePullType,
  creditScorePullTypeField,
  equifaxDate,
  equifaxScore,
  experianDate,
  experianScore,
  transunionDate,
  transunionScore
} from "../../inputs/credit-score/creditScore.inputs";

const creditReportSection = (r: {
  state: Partial<CreditScore>;
  onChange: (v: OnChangeInput) => void;
  options: {
    header?: string;
    hideAttachedComponent: boolean;
  };
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Integration Credit Report: Verify",
    inputs: [
      experianScore,
      experianDate,
      equifaxScore,
      equifaxDate,
      transunionScore,
      transunionDate,
      creditScorePullTypeField
    ],
    fieldSpecs: limaSpecs[ElphiEntityType.creditScore].entitySpecs || {}
  });

const creditReportOrderSection = (r: {
  state: Partial<IndividualParty>;
  onChange: (v: OnChangeInput) => void;
  options: {
    header?: string;
    hideAttachedComponent: boolean;
  };
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Integration Credit Report: Order",
    inputs: [
      creditScorePullType,
      individualFirstName,
      individualMiddleName,
      individualLastName,
      individualBirthDate,
      {
        ...taxpayerIdentifierValue,
        formatter: getTaxIdentifierFormatter(r.state?.TaxpayerIdentifierType),
        validation: getTaxIdentifierValidator(r.state?.TaxpayerIdentifierType)
      },
      partyAddressLineText,
      partyAddressUnitIdentifier,
      partyCityName,
      partyStateName,
      partyStateCode,
      partyPostalCode
    ],
    fieldSpecs: limaSpecs.party.entitySpecs || {}
  });

export const creditReportSectionBuilders = {
  creditReportSection,
  creditReportOrderSection
};
