import { DocumentConfiguration } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { documentConfigurationSlice } from "./documentConfiguration.slice";
export const documentConfigurationAction: ActionDispatcher<
  DocumentConfiguration
> = (dispatch, callback) => {
  const slice = documentConfigurationSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

export default documentConfigurationAction;
