import { Box, Flex, Text } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import SearchComponent from "../../../../SearchComponent";
import {
  DropDownIndicatorFirstControl,
  IndicatorsContainer
} from "../../../../form-builder/InputBuilder";
import { BlackBookIcon } from "../../../../icons";
import { querySanitizer } from "../../../../utils/sanitize.utils";
import { useServiceProviderSearchHooks } from "./serviceProviderSearch.hooks";
import { ServiceProviderSearchComponentProps } from "./serviceProviderSearch.types";

export const ServiceProviderSearch = (
  props: ServiceProviderSearchComponentProps
) => {
  if (!props.type) {
    return <></>;
  }

  const {
    query,
    customFilter,
    options,
    fetchData,
    response,
    selectedSearchCursor
  } = useServiceProviderSearchHooks({ ...props });

  const onInputChange = (newInput: string) => {
    props.onInputChange?.(querySanitizer(newInput));
  };

  return (
    <SearchComponent
      query={query}
      isReadOnly={props.isReadOnly}
      fieldType={props.fieldType}
      options={options}
      onInputChange={onInputChange}
      currentValue={props.currentValue}
      onSelect={props.onSelect}
      filterOption={customFilter}
      hasMore={response.data?.hasMore}
      fetchMore={() =>
        fetchData(
          query,
          selectedSearchCursor?.nextCursor
            ? selectedSearchCursor.nextCursor
            : undefined
        )
      }
      isLoading={response.isLoading || response.isFetching}
      label={props.label}
      labelPosition={"placeHolder"}
      customComponent={{
        Option: DisabledOptionTooltip,
        Control: DropDownIndicatorFirstControl,
        IndicatorsContainer: IndicatorsContainer
      }}
    />
  );
};

export const DisabledOptionTooltip = (props) => {
  const { isPersonalRolodex, label } = props.data;
  return (
    <chakraComponents.Option {...props}>
      <Flex>
        <Box hidden={!isPersonalRolodex} mr={2}>
          <BlackBookIcon />
        </Box>
        <Text>{label}</Text>
      </Flex>
    </chakraComponents.Option>
  );
};
