import { BaseEntity } from "@elphi/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import {
  FilterCombinationState,
  FiltersState
} from "../types/stateWithFilter.types";

export const initCombination = (): FilterCombinationState["combinations"] => {
  return {
    cursor: "",
    hasMore: true,
    ids: []
  };
};

export const setFilterAndCombination = <
  T extends BaseEntity<object>,
  U extends keyof T,
  TState extends WritableDraft<{ filters: FiltersState<T, U> }>
>(
  state: TState,
  action: PayloadAction<{
    combination: string;
    filters: TState["filters"];
  }>
) => {
  const { filters, combination } = action.payload;
  state.filters.current = filters.current;
  if (!state.filters.combinations[combination]) {
    state.filters.combinations[combination] = initCombination();
  }

  state.filters.currentCombination = combination;
};

export const setCombinationInfo = <
  T extends BaseEntity<object>,
  U extends keyof T,
  TState extends WritableDraft<{ filters: FiltersState<T, U> }>
>(
  state: TState,
  action: PayloadAction<{ cursor?: string; hasMore?: boolean; ids: string[] }>
) => {
  const { currentCombination } = state.filters;
  const { cursor, hasMore, ids } =
    state.filters.combinations[currentCombination];

  state.filters.combinations[currentCombination].cursor =
    action.payload.cursor ?? cursor;
  state.filters.combinations[currentCombination].hasMore =
    action.payload.hasMore ?? hasMore;
  state.filters.combinations[currentCombination].ids =
    action.payload.ids ?? ids;
};
