import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { DiscussionComment } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { RichTextEditor } from "../../common-components";
import { EmojiData } from "../../common-components/reaction-picker/reaction.types";
import { ReactionCounterBar } from "../../common-components/reaction-picker/ReactionCounter";
import { ReactionPicker } from "../../common-components/reaction-picker/ReactionPicker";
import {
  RichTextEditorValue,
  RichTextMentionList
} from "../../common-components/rich-text-editor/richText.types";
import { NOT_AVAILABLE } from "../../constants/common";
import { printDateTime } from "../../firebase/firebase.utils";
import { auth } from "../../firebase/firebaseConfig";
import { useLOSUserHooks } from "../../hooks/losuser.hooks";
import { UnderlineButton } from "./Discussion.utils";

type DiscussionCommentRowProps = {
  mode: "edit" | "view";
  discussionComment: DiscussionComment;
  avatarName?: string;
  userMentions: RichTextMentionList;
  handleSaveClick: (editorValue: RichTextEditorValue) => void;
  handleEditClick: (commentId?: string) => void;
  handleDeleteClick: (commentId: string) => void;
  handleReplyClick: (userId: string) => void;
  handleReactionClick: (reaction: EmojiData) => void;
};

export const DiscussionCommentRow = (props: DiscussionCommentRowProps) => {
  const { mode, discussionComment } = props;
  const isCurrentUser = auth.currentUser?.uid === discussionComment.createdUID;
  return (
    <Box>
      <DiscussionCommentRowHeader isCurrentUser={isCurrentUser} {...props} />
      <DiscussionCommentRowBody {...props} />
      {mode === "view" && (
        <DiscussionCommentRowFooter isCurrentUser={isCurrentUser} {...props} />
      )}
    </Box>
  );
};

const DiscussionCommentRowHeader = (
  props: { isCurrentUser: boolean } & Pick<
    DiscussionCommentRowProps,
    "avatarName" | "discussionComment"
  >
) => {
  const { isCurrentUser, avatarName, discussionComment } = props;
  const avatarBg = isCurrentUser ? "rgb(246, 224, 255)" : "rgb(224, 242, 255)";
  const avatarColor = isCurrentUser ? "rgb(160, 21, 215)" : "rgb(49, 130, 206)";
  return (
    <Flex gap={2} mb={2}>
      <Box>
        <Avatar
          size={"xs"}
          name={avatarName}
          bg={avatarBg}
          color={avatarColor}
        />
      </Box>
      <Text color={"gray.900"} fontWeight={700}>
        {avatarName || NOT_AVAILABLE}
      </Text>
      <Text color={"gray.500"} fontWeight={700}>
        {printDateTime(discussionComment.createdAt) || NOT_AVAILABLE}
      </Text>
      {discussionComment.edited && <Text color={"gray.500"}>(edited)</Text>}
    </Flex>
  );
};

const DiscussionCommentRowBody = (
  props: Pick<
    DiscussionCommentRowProps,
    | "discussionComment"
    | "handleSaveClick"
    | "handleEditClick"
    | "userMentions"
    | "mode"
  >
) => {
  const {
    discussionComment,
    handleSaveClick,
    handleEditClick,
    userMentions,
    mode
  } = props;
  return (
    <RichTextEditor
      key={`${discussionComment.id}_${mode}`}
      onSave={handleSaveClick}
      text={discussionComment.comment}
      mode={mode}
      onCancel={handleEditClick}
      mentionList={userMentions}
    />
  );
};

const SELF = "You";

const DiscussionCommentRowFooter = (
  props: { isCurrentUser: boolean } & Pick<
    DiscussionCommentRowProps,
    | "discussionComment"
    | "handleEditClick"
    | "handleDeleteClick"
    | "handleReplyClick"
    | "handleReactionClick"
  >
) => {
  const {
    isCurrentUser,
    discussionComment,
    handleEditClick,
    handleDeleteClick,
    handleReplyClick,
    handleReactionClick
  } = props;

  const { losUserState } = useLOSUserHooks();

  const reactions = useMemo(() => {
    return orderBy(
      Object.entries(discussionComment?.reaction || {})
        .map(([key, value]) => {
          if (value.length === 0) {
            return undefined;
          }
          const reactedUsers = orderBy(value, "reactedAtUTC")
            .map((x) => {
              const isSelf = auth.currentUser?.uid === x.userUID;
              if (isSelf) {
                return SELF;
              }
              return losUserState.entities?.[x.userUID]?.name;
            })
            .filter(removeEmpty);

          return {
            id: key,
            count: value.length,
            users: reactedUsers,
            isSelf: reactedUsers.includes(SELF)
          };
        })
        .filter(removeEmpty),
      "id",
      "desc"
    );
  }, [discussionComment?.reaction, losUserState.entities]);

  return (
    <Flex>
      {isCurrentUser ? (
        <>
          <UnderlineButton
            text={"Edit"}
            fontSize={"14px"}
            fontWeight={400}
            color={"gray.600"}
            onClick={() => handleEditClick(discussionComment.id)}
          />
          <UnderlineButton
            text={"Delete"}
            fontSize={"14px"}
            fontWeight={400}
            color={"gray.600"}
            onClick={() => handleDeleteClick(discussionComment.id)}
          />
        </>
      ) : (
        <>
          <UnderlineButton
            text={"Reply"}
            fontSize={"14px"}
            fontWeight={400}
            color={"gray.600"}
            onClick={() =>
              discussionComment.createdUID &&
              handleReplyClick(discussionComment.createdUID)
            }
          />
        </>
      )}
      <ReactionCounterBar
        onToggle={handleReactionClick}
        reactions={reactions}
      />
      <ReactionPicker onSelect={handleReactionClick} />
    </Flex>
  );
};
