const SECOND_TO_MILLISECOND = 1000;
const MINUTE_TO_SECOND = 60;
const HOUR_TO_MINUTE = 60;
const DAY_TO_HOUR = 24;
const WEEK_TO_DAY = 7;
const MONTH_TO_WEEK = 4;
const YEAR_TO_MONTH = 12;
export const DATES_FORMATS = {
  YYYY_MM_DD: "YYYY-MM-DD",
  YYYY_MM: "YYYY-MM",
  MMM_YYYY: "MMM YYYY",
  DD_MM_YYYY_WITH_SLASH: "DD/MM/YYYY",
  dd_MM_yyyy_WITH_SLASH: "dd/MM/yyyy"
};

// regex for yyyy-mm-dd: https://stackoverflow.com/questions/22061723/regex-date-validation-for-yyyy-mm-dd
export const dateRegExp = RegExp(
  /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
);

export const ISODate = (d: Date) => {
  return d.toISOString().split("T")[0];
};
export const formatDate = (date: Date, format?: "iso-date") => {
  if (format && format === "iso-date") {
    return ISODate(date);
  }
  return date;
};

export const formatDateDDMMYYYY = (date: Date) => {
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  }).format(date);
};
export const dateDelta = (r: {
  period: "d" | "m" | "y";
  delta: number;
  date?: Date;
  format?: "iso-date";
}) => {
  let date = r.date;

  if (!date) {
    date = new Date();
  }
  if (r.period === "m") {
    const deltaDate = new Date(
      date.getFullYear(),
      date.getMonth() + r.delta,
      date.getDate(),
      date.getHours()
    );

    return formatDate(deltaDate, r.format);
  } else if (r.period === "d") {
    const deltaDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + r.delta,
      date.getHours()
    );

    return formatDate(deltaDate, r.format);
  } else if (r.period === "y") {
    const deltaDate = new Date(
      date.getFullYear() + r.delta,
      date.getMonth(),
      date.getDate(),
      date.getHours()
    );

    return formatDate(deltaDate, r.format);
  }

  throw `dateDelta no case for period: ${r.period}`;
};

export const dateDifference = (r: {
  period: "d" | "m" | "y";
  dateA?: Date;
  dateB?: Date;
}) => {
  let { dateA, dateB } = r;

  if (!dateA) {
    dateA = new Date();
  }

  if (!dateB) {
    dateB = new Date();
  }

  const dateDifferenceInMilliseconds = dateA.getTime() - dateB.getTime();
  if (dateDifferenceInMilliseconds < 0) return NaN;

  if (r.period === "d") {
    return (
      dateDifferenceInMilliseconds /
      SECOND_TO_MILLISECOND /
      MINUTE_TO_SECOND /
      HOUR_TO_MINUTE /
      DAY_TO_HOUR
    );
  } else if (r.period === "m") {
    return (
      dateDifferenceInMilliseconds /
      SECOND_TO_MILLISECOND /
      MINUTE_TO_SECOND /
      HOUR_TO_MINUTE /
      DAY_TO_HOUR /
      WEEK_TO_DAY /
      MONTH_TO_WEEK
    );
  } else if (r.period === "y") {
    return (
      dateDifferenceInMilliseconds /
      SECOND_TO_MILLISECOND /
      MINUTE_TO_SECOND /
      HOUR_TO_MINUTE /
      DAY_TO_HOUR /
      WEEK_TO_DAY /
      MONTH_TO_WEEK /
      YEAR_TO_MONTH
    );
  }

  throw `dateDifference no case for period: ${r.period}`;
};

const datePeriodMultiplierMap: { [key in "d" | "m" | "y"]: number } = {
  d: SECOND_TO_MILLISECOND * MINUTE_TO_SECOND * HOUR_TO_MINUTE * DAY_TO_HOUR,
  m:
    SECOND_TO_MILLISECOND *
    MINUTE_TO_SECOND *
    HOUR_TO_MINUTE *
    DAY_TO_HOUR *
    WEEK_TO_DAY *
    MONTH_TO_WEEK,
  y:
    SECOND_TO_MILLISECOND *
    MINUTE_TO_SECOND *
    HOUR_TO_MINUTE *
    DAY_TO_HOUR *
    WEEK_TO_DAY *
    MONTH_TO_WEEK *
    YEAR_TO_MONTH
};

export const addDate = (r: {
  period: "d" | "m" | "y";
  periodCount: number;
  date: Date;
}) => {
  const { date, periodCount, period } = r;

  const dateInTime = date.getTime();
  if (dateInTime < 0) return undefined;

  const multiplier = datePeriodMultiplierMap[period];
  if (!multiplier) {
    throw new Error(`Not supported period: ${period}`);
  }

  const updatedTime = dateInTime + periodCount * multiplier;
  return new Date(updatedTime);
};

export default {
  dateDelta
};
