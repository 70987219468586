import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { memo } from "react";
import { useTableManagerHook } from "../../hooks/tableManager.hooks";
import { OrderableTableHeader } from "../orderable-table/OrderableTableHeader";
import {
  ElphiCell as ElphiHeaderCell,
  OrderableTableProps
} from "../table/table.types";
const CELL_DEFAULT_WIDTH = "280px";
export const ElphiListHeader = memo(
  ({
    tableName,
    headerCells,
    isOrderable
  }: {
    headerCells: ElphiHeaderCell[];
  } & OrderableTableProps) => {
    const { getTableState, getSortCallback } = useTableManagerHook();
    const sortCallback = getSortCallback({ isOrderable });
    const sortedCells = sortCallback({ tableName, cells: headerCells });

    const { columnsOrder, reorderMode, minIndex } =
      getTableState(tableName) || {};
    return (
      <Flex w="100%" bgColor="gray.300">
        {sortedCells.map(({ maxWidth, minWidth, index, data }) => {
          return (
            <Box
              maxW={maxWidth || CELL_DEFAULT_WIDTH}
              minW={minWidth || CELL_DEFAULT_WIDTH}
              w={minWidth || CELL_DEFAULT_WIDTH}
              key={index}
            >
              <Center w="100%" p="10px">
                {index !== minIndex && isOrderable && reorderMode ? (
                  <OrderableTableHeader
                    tableName={tableName}
                    columnId={
                      columnsOrder ? columnsOrder[index].columnId : index
                    }
                    header={data}
                    columnList={headerCells}
                  />
                ) : typeof data === "string" ? (
                  <Text fontWeight="bold" fontSize="16px">
                    {data}
                  </Text>
                ) : (
                  data
                )}
              </Center>
            </Box>
          );
        })}
      </Flex>
    );
  }
);
