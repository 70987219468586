import { AggregationType } from "@elphi/types";

const DELIMITER = ".";

export const buildFieldPaths = (r: {
  path: string[];
  aggregationType?: AggregationType;
}) => {
  const { path, aggregationType } = r;
  const fieldPath = path.join(DELIMITER);
  const allPaths = [["fieldMeta", fieldPath, "status"].join(DELIMITER)];
  if (!aggregationType) {
    allPaths.push(fieldPath);
    return allPaths;
  }
  allPaths.push([fieldPath, "focused"].join(DELIMITER));
  allPaths.push([fieldPath, "override"].join(DELIMITER));
  aggregationType === AggregationType.ThirdParty
    ? allPaths.push([fieldPath, "thirdParty"].join(DELIMITER))
    : allPaths.push([fieldPath, "calculated"].join(DELIMITER));

  return allPaths;
};

export const buildFieldId = (r: { fieldPaths: string[] }) => {
  return r.fieldPaths.join(DELIMITER);
};
