import { Box, Tooltip } from "@chakra-ui/react";
import { cloneElement } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useOrgHooks } from "../../../hooks/org.hooks";
import { SidebarIconProps } from "../../../shared/type-props/sidebar-icon-props.types";

const SidebarIcon = (props: SidebarIconProps) => {
  const location = useLocation();
  const { selectedOrgId } = useOrgHooks();

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.disabled) e.preventDefault();
  };
  const isActive = location.pathname.includes(props.pathName);
  return (
    <Tooltip
      label={props.name}
      placement={"right"}
      borderRadius={"4px"}
      marginLeft={"-9px"}
      backgroundColor={"gray.900"}
      hasArrow={true}
    >
      <NavLink
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "26px",
          width: "100%",
          zIndex: "100",
          cursor: props.disabled ? "not-allowed" : "pointer",
          marginBottom: "10px"
        }}
        to={{
          pathname: `../${props?.customOrgId || selectedOrgId}${props.pathName}` //maybe the forward slash should work
        }}
        state={{
          userData: props.userData
        }}
        data-for={props.id}
        data-place="right"
        target={props.target}
        onClick={onClick}
      >
        <Box width="28px" height="28px">
          {cloneElement(props.icon, {
            fill: isActive ? "#F2F7FF" : "none"
          })}
        </Box>
      </NavLink>
    </Tooltip>
  );
};

export default SidebarIcon;
