import { PosDealUserPartyView } from "@elphi/types";
import { buildAction } from "../../../builders/action.builder";
import { ActionDispatcher } from "../../../types/action.types";
import { posDealUserPartyViewSlice } from "./posDealUserPartyView.slice";

export const posDealUserPartyViewAction: ActionDispatcher<
  PosDealUserPartyView
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(posDealUserPartyViewSlice);
  return actionDispatcher(dispatch, callback);
};
