import { TObject, TProperties, Type } from "@sinclair/typebox";
import {
  ActionType,
  AutoGenerateType,
  ElphiEntityType,
  KeyClosingTaskType,
  TaskStatusType,
  TaskType
} from "../entities";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { EntitiesFieldSelection } from "./taskConfiguration.typebox";
import {
  ArrayField,
  Deprecated,
  EnumField,
  StringField
} from "./utils.typebox";

export enum UserRoleType {
  LineOfCreditOperationAnalyst = "LOC Ops Analyst",
  OperationAnalyst = "Ops Analyst",
  SeniorOperationAnalyst = "Senior Ops Analyst"
}

export const DataVerifyOrderFieldsSchema = Type.Object({
  deal: Type.Optional(
    Type.Object({
      deal_id: Type.Object({
        fieldPath: StringField
      })
    })
  ),
  property: Type.Optional(
    Type.Object({
      property_id: Type.Object({
        fieldPath: StringField
      })
    })
  ),
  party: Type.Optional(
    Type.Object({
      party_id: Type.Object({
        fieldPath: StringField
      })
    })
  )
});

export const TaskFieldsSchema = Type.Object({
  templateName: StringField,
  ruleId: StringField,
  availableStatus: ArrayField(EnumField(TaskStatusType)),
  id: StringField,
  name: StringField,
  dataToVerify: Type.Optional(EntitiesFieldSelection),
  storageMeta: Type.Optional(
    Type.Object({ box: Type.Object({ folderId: StringField }) })
  ),
  dataToOrder: Type.Optional(EntitiesFieldSelection),
  comments: Type.Optional(StringField),
  type: EnumField(TaskType),
  assignableBy: ArrayField(EnumField(UserRoleType)),
  editableBy: ArrayField(EnumField(UserRoleType)),
  availableActions: Deprecated(ArrayField(EnumField(ActionType))),
  entityId: StringField,
  entityType: EnumField(ElphiEntityType),
  generation: StringField,
  keyClosingTaskType: EnumField(KeyClosingTaskType),
  templateId: StringField,
  checklistOf: ArrayField(EnumField(UserRoleType)),
  taskStatus: EnumField(TaskStatusType),
  autoGenerate: EnumField(AutoGenerateType)
});

export const TaskUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(TaskFieldsSchema)
]);

export const TaskCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(TaskFieldsSchema)
]);

export const getOperationTaskSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? TaskCreate : TaskUpdate;
};
