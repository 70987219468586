import { Box, Button, Flex, Tooltip } from "@chakra-ui/react";
import { FieldType, RolodexConfigurationFieldType } from "@elphi/types";
import { useEffect, useState } from "react";
import elphiTheme from "../../../../../../assets/themes/elphi.theme.default";
import { EMPTY, NOT_AVAILABLE } from "../../../../../../constants/common";
import { printDateTime } from "../../../../../../firebase/firebase.utils";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import { BaseButtonIconDelete } from "../../../../../button-icon/ButtonIcon";
import { TextCell } from "../../../../../custom/chakra/Cell";
import StyledInputBuilder from "../../../../../form-builder/InputBuilder";
import ModalContainer from "../../../../../modal-container/ModalContainer";
import { ItemsMenuCopyWidget } from "../../../../../party/table/v2/ItemsMenuCopyWidget";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../../../../table/TableRowSizeComponent";
import { SelectFieldsModal } from "../modal/SelectFieldsModal";
import { serviceProviderEntityTypeOptionMap } from "../modal/modal.utils";
import { ConfigurationRowProps } from "../serviceDomainConfiguration.types";

export const ConfigurationRow = (props: ConfigurationRowProps) => {
  const { index, data, onChange, state, syncState } = props;
  const { id } = data;
  const { deleteConfiguration, deleteResponse } = useRolodexConfiguration();

  useEffect(() => {
    syncState({
      state: data,
      shouldSync: true,
      statePath: () => {
        return [id];
      }
    });
  }, [data]);
  const [deleteId, setDeleteId] = useState(EMPTY);
  const [editedId, setEditedId] = useState(EMPTY);
  const [fieldSelection, setFieldSelection] =
    useState<RolodexConfigurationFieldType>(
      RolodexConfigurationFieldType.CompanyFields
    );

  const handleEditClick = (type: RolodexConfigurationFieldType) => {
    setEditedId(id);
    setFieldSelection(type);
  };

  const entityType = state?.[id]?.entityType;

  return (
    <>
      <Flex w="100%" justify="space-evenly">
        <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
          <TextCell>{index + 1}</TextCell>
        </TableRowSizeComponent>
        <TableRowSizeComponent size={SIZE_FIELD.M} withBorderRightWidth={false}>
          <Flex alignItems={"center"} justifyContent={"center"}>
            {state[id]?.createdAt && printDateTime(state[id].createdAt)}
          </Flex>
        </TableRowSizeComponent>
        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <StyledInputBuilder
            currentValue={state[id]?.name}
            fieldType={FieldType.String}
            onChange={(e) => {
              onChange({
                fieldKey: [id, "name"],
                fieldType: FieldType.String,
                value: e.target.value
              });
            }}
            isValid={true}
          />
        </TableRowSizeComponent>
        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <Flex alignItems={"center"} justifyContent={"center"}>
            {entityType
              ? serviceProviderEntityTypeOptionMap[entityType]
              : NOT_AVAILABLE}
          </Flex>
        </TableRowSizeComponent>
        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Button
              {...elphiTheme.components.light.button.secondary}
              onClick={() => {
                handleEditClick(RolodexConfigurationFieldType.CompanyFields);
              }}
            >
              Edit
            </Button>
          </Flex>
        </TableRowSizeComponent>
        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Button
              {...elphiTheme.components.light.button.secondary}
              onClick={() => {
                handleEditClick(RolodexConfigurationFieldType.BranchFields);
              }}
            >
              Edit
            </Button>
          </Flex>
        </TableRowSizeComponent>
        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Button
              {...elphiTheme.components.light.button.secondary}
              onClick={() => {
                handleEditClick(
                  RolodexConfigurationFieldType.RepresentativeFields
                );
              }}
            >
              Edit
            </Button>
          </Flex>
        </TableRowSizeComponent>
        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <StyledInputBuilder
            currentValue={state[id]?.status}
            fieldType={FieldType.Switch}
            onChange={(e) => {
              onChange({
                fieldKey: [id, "status"],
                fieldType: FieldType.Switch,
                value: e.target.value
              });
            }}
          />
        </TableRowSizeComponent>
        <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
          <Flex alignItems={"center"}>
            <Tooltip label={"system identifiers"} placement="top">
              <Box pr="2px">
                <ItemsMenuCopyWidget
                  items={[
                    {
                      label: "elphi-rolodex-configuration-id",
                      value: id
                    }
                  ]}
                />
              </Box>
            </Tooltip>
            <BaseButtonIconDelete
              isDisabled={true}
              onClick={() => {
                setDeleteId(id);
              }}
            />
          </Flex>
        </TableRowSizeComponent>
      </Flex>
      <ModalContainer
        isShow={!!deleteId && !!state?.[deleteId]}
        onCloseModal={() => setDeleteId(EMPTY)}
        header={`Are you sure you want to delete ${data.name} ?`}
        submit={{
          isLoading: deleteResponse.isLoading,
          onConfirm: () => {
            deleteConfiguration(deleteId);
          }
        }}
      ></ModalContainer>
      <SelectFieldsModal
        key={editedId}
        isShow={!!editedId && !!state?.[editedId]}
        onClose={() => {
          setEditedId(EMPTY);
        }}
        type={fieldSelection}
        data={data}
      />
    </>
  );
};
