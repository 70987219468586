import {
  Asset,
  CreditScore,
  Party,
  PartyType,
  RolodexServiceProvider,
  ServiceProviderType,
  Statement
} from "@elphi/types";
import { EMPTY } from "../../../constants/common";
import { printDateTime } from "../../../firebase/firebase.utils";

export const partyToString = (party?: undefined | Partial<Party>) => {
  if (party?.PartyType === PartyType.Individual) {
    return `${party.FirstName} ${party.LastName}`;
  } else if (party?.PartyType === PartyType.Entity) {
    return party.FullName;
  }
  return "";
};

export const assetToString = (r: {
  asset: Partial<Asset>;
  parties?: (undefined | Partial<Party>)[];
}) => {
  if (r.parties) {
    const partyNames = r.parties.map(partyToString).join(", ");

    return `${partyNames}: ${r.asset.accountType}, ${r.asset.financialInstitution}, ${r.asset.last4Digits}`;
  }
  return `${r.asset.accountName}, ${r.asset.accountType}, ${r.asset.financialInstitution}, ${r.asset.last4Digits}`;
};
export const statementToString = (r: {
  statement: Statement;
  asset: Partial<Asset>;
  parties?: (undefined | Partial<Party>)[];
}) => {
  if (r.parties) {
    const partyNames = r.parties.map(partyToString).join(", ");

    return `${partyNames}: ${r.asset.accountType}, ${r.asset.financialInstitution}, ${r.asset.last4Digits}, ${r.statement.end}`;
  }
  return `${r.asset.accountType}, ${r.asset.financialInstitution}, ${r.asset.last4Digits}, ${r.statement.end}`;
};

export const creditScoreToString = (r: {
  creditScore: Partial<CreditScore>;
  party?: undefined | Partial<Party>;
}) => {
  if (r.party) {
    const partyNames = partyToString(r.party);

    return `${partyNames}: ${printDateTime(
      r.creditScore.createdAt
    )}, ${r.creditScore.pullType?.toString()}`;
  }
  return `${printDateTime(
    r.creditScore.createdAt
  )}, ${r.creditScore.pullType?.toString()}`;
};

export const serviceProviderToString = (r?: RolodexServiceProvider) => {
  if (
    r?.type === ServiceProviderType.Company ||
    r?.type === ServiceProviderType.Branch
  ) {
    return r.name;
  }
  if (r?.type === ServiceProviderType.Representative) {
    return r.middleName
      ? `${r.firstName} ${r.middleName} ${r.lastName}`
      : `${r.firstName} ${r.lastName}`;
  }
  return EMPTY;
};
