import { RolodexServiceProvider } from "@elphi/types";
import { buildAction } from "../../builders/action.builder";
import { ActionDispatcher } from "../../types/action.types";
import { serviceProviderSlice } from "./serviceProvider.slice";

export const serviceProviderAction: ActionDispatcher<RolodexServiceProvider> = (
  dispatch,
  callback
) => {
  const actionDispatcher = buildAction(serviceProviderSlice);
  return actionDispatcher(dispatch, callback);
};
