import { Skeleton } from "@chakra-ui/react";
import { Tree as TreeSkeleton, TreeNode } from "react-organizational-chart";
import Node from "./Node";
import { TreeNodeWrapper } from "./style-components/NodeLabelComponents";
import { TreeNodeProps } from "./types/Tree.types";

export const emptyAction = { component: <span /> };

export const NodeWrapper = <T extends unknown>(props: TreeNodeProps<T>) => {
  return (
    <TreeNode key={props.node.id} label={<Node {...props} />}>
      {props.node.children.map((child, i) =>
        convertDataToTreeNodes(
          {
            rootNode: child,
            operations: props.operations,
            nodeActions: child?.nodeActions?.length
              ? child?.nodeActions
              : props.nodeActions,
            customComponent: props.customComponent,
            handleNodeSelect: props.handleNodeSelect
          },
          i
        )
      )}
    </TreeNode>
  );
};
export const convertDataToTreeNodes = (
  { rootNode, operations, nodeActions, customComponent, handleNodeSelect },
  key
) => {
  //if (customComponent) return customComponent;

  return (
    <NodeWrapper
      key={key}
      node={rootNode}
      operations={operations}
      nodeActions={nodeActions}
      customComponent={customComponent}
      handleNodeSelect={handleNodeSelect}
    />
  );
};
export const nodeSkeletonComponent = (
  <TreeNodeWrapper>
    <Skeleton
      minH="60px"
      minW="200px"
      height="100%"
      width="100%"
      borderRadius="inherit"
    />
  </TreeNodeWrapper>
);

export const loadingSkeleton = (
  <TreeSkeleton
    lineWidth="5px"
    lineColor="#384a8c"
    lineBorderRadius="10px"
    label=""
  >
    <TreeNode label={nodeSkeletonComponent}>
      <TreeNode label={nodeSkeletonComponent} />
      <TreeNode label={nodeSkeletonComponent} />
    </TreeNode>
  </TreeSkeleton>
);
