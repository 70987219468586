import { Flex } from "@chakra-ui/react";
import { InsurancePolicyContainer } from "../insurance-policy/deal/InsurancePolicyContainer";

export const InsurancePolicyTab = (props: { dealId: string }) => {
  return (
    <Flex p={"10px"} direction={"column"} h="100%">
      <InsurancePolicyContainer {...props} />
    </Flex>
  );
};
