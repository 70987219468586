import { Flex } from "@chakra-ui/react";
import { PropertyInsurancePolicyView } from "@elphi/types";
import { difference, values } from "lodash";
import { CustomCollapse } from "../../../../../common-components";
import { MaximizedInfo } from "../../../../../common-components/card";
import { NOT_AVAILABLE } from "../../../../../constants/common";

export const DealPropertyInsurancePolicyList = (props: {
  view: PropertyInsurancePolicyView;
}) => {
  const { view } = props;
  const dealPolicyAccordions = view.dealIds?.map((dealId) => {
    const dealProperties = view.dealEntities?.[dealId]?.entityIds;
    const propertyNames = dealProperties?.map(
      (p) => view.entities?.[p]?.entityName
    );
    return (
      <DealPropertyInsurancePolicyAccordion
        key={dealId}
        loanIdentifier={view.dealEntities?.[dealId]?.loanIdentifier}
        propertyNames={propertyNames}
      />
    );
  });
  const propertiesConnectedToDeals = values(view.dealEntities)
    .map((x) => x.entityIds)
    .flat();
  const propertiesWithoutDeals = difference(
    view.entityIds,
    propertiesConnectedToDeals
  ).map((propertyId) => view.entities?.[propertyId]?.entityName);

  if (propertiesWithoutDeals.length) {
    dealPolicyAccordions.push(
      <DealPropertyInsurancePolicyAccordion
        key="withoutDeal"
        propertyNames={propertiesWithoutDeals}
      />
    );
  }

  return dealPolicyAccordions;
};

const DealPropertyInsurancePolicyAccordion = (props: {
  loanIdentifier?: string;
  propertyNames: string[];
}) => {
  const { loanIdentifier, propertyNames } = props;
  const title = loanIdentifier ? `Loan ${loanIdentifier}` : NOT_AVAILABLE;
  if (!propertyNames?.length) {
    return <></>;
  }
  return (
    <CustomCollapse
      rightContent={`${propertyNames.length} properties`}
      options={{
        containerBorderColor: "gray.200"
      }}
      title={title}
      customBody={
        <Flex
          borderRadius="10px"
          p="10px"
          backgroundColor="white"
          direction="column"
          gap="16px"
        >
          {propertyNames.map((propertyName, index) => (
            <MaximizedInfo
              key={index}
              title={`Property ${index + 1}`}
              subTitle={propertyName}
            />
          ))}
        </Flex>
      }
    />
  );
};
