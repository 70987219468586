import { limaAccountType } from "@elphi/types/entities/lima";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";
import {
  baseSchemaAccountAssetFieldSpecs,
  fullBaseSchemaAccountAssetFieldSpecs
} from "../../../base/spec-files/account-asset/accountAsset.fields";
import { modifyFromSpec } from "../../../utils/formsSpec.utils";

export const fullLimaSchemaAccountAssetFieldSpecs: typeof baseSchemaAccountAssetFieldSpecs =
  {
    ...fullBaseSchemaAccountAssetFieldSpecs,
    accountType: modifyFromSpec({
      fieldSpecs: baseSchemaAccountAssetFieldSpecs.accountType,
      changes: {
        options: createOptionsFromMap(limaAccountType)
      }
    })
  };
