// Need to use the React-specific entry point to allow generating React hooks
import {
  Asset,
  CreditScore,
  Deal,
  DealPartyRelation,
  LOSUser,
  Party,
  PartyAsset,
  PartyRelation,
  PartyUser
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { removeNulls } from "../../../utils/filter.utils";
import { assetEntityAdapter } from "../asset/asset.adapter";
import serviceBuilder from "../builders/api.builder";
import { dealPartyEntityAdapter } from "../deal-party-relation/dealPartyRelation.adapter";
import { dealEntityAdapter } from "../deal/deal.adapter";
import { losUserEntityAdapter } from "../los-user/losUser.adapter";
import { partyAssetEntityAdapter } from "../party-asset-relation/partyAssetRelation.adapter";
import { partyRelationEntityAdapter } from "../party-relation/partyRelation.adapter";
import { partyUserEntityAdapter } from "../party-user-relation/partyUserRelation.adapter";

import { partyEntityAdapter } from "./party.adapter";

// Define a service using a base URL and expected endpoints
export const basePartyApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "partyApi",
  tagTypes: ["Party", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "party"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: partyEntityAdapter
  })
});
export type PaginateV2Response = {
  nextCursor?: string;
  hasMore?: boolean;
  page: {
    deal: Deal[];
    party: Party[];
    dealParty: DealPartyRelation[];
    partyUser: PartyUser[];
  };
};

export const partyApi = basePartyApi.injectEndpoints({
  endpoints: (builder) => ({
    paginateV2: builder.query<
      PaginateV2Response,
      { limit?: string; cursor?: string; userId?: string; partyId?: string }
    >({
      query: (r) => {
        const urlCursor = r.cursor ? `cursor=${r.cursor}` : null;
        const urlLimit = r.limit ? `limit=${r.limit}` : null;
        const urlUser = r.userId ? `user_id=${r.userId}` : null;
        const urlParty = r.partyId ? `party_id=${r.partyId}` : null;
        const queryParams = [urlCursor, urlLimit, urlUser, urlParty]
          .filter(removeNulls)
          .join("&");
        const query = queryParams ? `?${queryParams}` : "";
        const url = `/v2/paginate${query}`;
        return {
          url,
          method: "GET"
        };
      }
    }),
    updateBatch: builder.mutation<
      { batch: string[] },
      { parties: ({ id: string } & Partial<Party>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "POST",
          body: r
        };
      }
    }),
    addChild: builder.mutation<
      { parentId: string; relationId: string; childId: string },
      { parentId: string; child: { id: string } | Party }
    >({
      query: (req) => {
        return {
          url: `/add-child/${req.parentId}`,
          method: "POST",
          body: { child: req.child }
        };
      }
    }),
    children: builder.query<
      { children: EntityState<Party>; relations: EntityState<PartyRelation> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/party-children`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: {
          children: Party[];
          relations: PartyRelation[];
        };
      }) => {
        const adapter = partyEntityAdapter.addMany(
          partyEntityAdapter.getInitialState(),
          response.results.children
        );
        const relationAdapter = partyRelationEntityAdapter.addMany(
          partyRelationEntityAdapter.getInitialState(),
          response.results.relations
        );
        return { children: adapter, relations: relationAdapter };
      }
    }),
    partyTree: builder.query<
      { parties: EntityState<Party>; relations: EntityState<PartyRelation> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/party-tree`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: {
          parties: Party[];
          relations: PartyRelation[];
        };
      }) => {
        const adapter = partyEntityAdapter.addMany(
          partyEntityAdapter.getInitialState(),
          response.results.parties
        );
        const relationAdapter = partyRelationEntityAdapter.addMany(
          partyRelationEntityAdapter.getInitialState(),
          response.results.relations
        );
        return { parties: adapter, relations: relationAdapter };
      }
    }),
    getPartyBatch: builder.query<{ parties: EntityState<Party> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: Party[] }) => {
        const adapter = partyEntityAdapter.addMany(
          partyEntityAdapter.getInitialState(),
          response.results
        );

        return { parties: adapter };
      }
    }),
    partyDeals: builder.query<
      { relations: EntityState<DealPartyRelation>; deals: EntityState<Deal> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/party-deals`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: { relations: DealPartyRelation[]; deals: Deal[] };
      }) => {
        const dealAdapter = dealEntityAdapter.addMany(
          dealEntityAdapter.getInitialState(),
          response.results.deals
        );
        const dealPartyAdapter = dealPartyEntityAdapter.addMany(
          dealPartyEntityAdapter.getInitialState(),
          response.results.relations
        );
        return {
          relations: dealPartyAdapter,
          deals: dealAdapter
        };
      }
    }),
    getPartyCreditScores: builder.query<CreditScore[], string>({
      query: (query) => {
        return {
          url: `/getPartyCreditScores/${query}`,
          method: "GET"
        };
      }
    }),
    search: builder.query<
      {
        query: string;
        hasMore: boolean;
        nextCursor: string | null;
        results: EntityState<Party>;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: Party[];
          nextCursor: string | null;
          hasMore: boolean;
        },
        _,
        arg
      ) => {
        const adapter = partyEntityAdapter.addMany(
          partyEntityAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          nextCursor: response.nextCursor,
          hasMore: response.hasMore
        };
      }
    }),
    getBatchPartyAsset: builder.query<
      { assets: EntityState<Asset>; relations: EntityState<PartyAsset> },
      { partyIds: string[] }
    >({
      query: (query) => {
        return {
          url: `/get-party-asset-batch`,
          method: "POST",
          body: { partyIds: query.partyIds }
        };
      },
      transformResponse: (response: {
        results: { relations: PartyAsset[]; assets: Asset[] };
      }) => {
        const adapter = assetEntityAdapter.addMany(
          assetEntityAdapter.getInitialState(),
          response.results.assets
        );
        const relationAdapter = partyAssetEntityAdapter.addMany(
          partyAssetEntityAdapter.getInitialState(),
          response.results.relations
        );
        return { assets: adapter, relations: relationAdapter };
      }
    }),

    partyUsers: builder.query<
      { relations: EntityState<PartyUser>; users: EntityState<LOSUser> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/party-users`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: { relations: PartyUser[]; users: LOSUser[] };
      }) => {
        const losUserAdapter = losUserEntityAdapter.addMany(
          losUserEntityAdapter.getInitialState(),
          response.results.users
        );
        const partyUserAdapter = partyUserEntityAdapter.addMany(
          partyUserEntityAdapter.getInitialState(),
          response.results.relations
        );
        return {
          relations: partyUserAdapter,
          users: losUserAdapter
        };
      }
    })
  })
});
