import { AggregationType, IntegrationType } from "@elphi/types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import {
  emailValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../../components/utils/validationUtils";
import { buildInput } from "../../../utils/formBuilder.utils";
import { integrationSpecs } from "../../spec-files/integrationFieldsSpecs";

const { spec } = integrationSpecs.deal.WireInsuranceCertificate.FundingShield;
export const wireInsuranceCertificateFundingShieldUserFirstName = buildInput({
  fieldKey:
    spec.Integrations?.[IntegrationType.WireInsuranceCertificate]?.[
      WireInsuranceVendorType.FundingShield
    ]?.userFirstName?.fieldKey,
  validation: stringValidation
});
export const wireInsuranceCertificateFundingShieldUserLastName = buildInput({
  fieldKey:
    spec.Integrations?.[IntegrationType.WireInsuranceCertificate]?.[
      WireInsuranceVendorType.FundingShield
    ]?.userLastName?.fieldKey,
  validation: stringValidation
});
export const wireInsuranceCertificateFundingShieldUserEmail = buildInput({
  fieldKey:
    spec.Integrations?.[IntegrationType.WireInsuranceCertificate]?.[
      WireInsuranceVendorType.FundingShield
    ]?.userEmail?.fieldKey,
  validation: emailValidation
});
export const wireInsuranceCertificateFundingShieldRushOrderIndicator =
  buildInput({
    fieldKey:
      spec.Integrations?.[IntegrationType.WireInsuranceCertificate]?.[
        WireInsuranceVendorType.FundingShield
      ]?.rushOrderIndicator?.fieldKey,
    validation: stringValidation
  });

export const transactionId = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[
      IntegrationType.WireInsuranceCertificate
    ]?.[WireInsuranceVendorType.FundingShield]?.TransactionId?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const transactionStatus = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[
      IntegrationType.WireInsuranceCertificate
    ]?.[WireInsuranceVendorType.FundingShield]?.TransactionStatus?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: singleSelectValidation
});

export const stateNYIndicator = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[
      IntegrationType.WireInsuranceCertificate
    ]?.[WireInsuranceVendorType.FundingShield]?.StateNYIndicator?.focused
      ?.fieldKey,
  calculationType: AggregationType.Aggregation,
  validation: singleSelectValidation
});
