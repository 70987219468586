// Need to use the React-specific entry point to allow generating React hooks
import { CloneConfigurationType, Organization } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { AppConfig } from "../../../config/appConfig";
import { baseUrl } from "../../../utils/requestVariables";
import serviceBuilder from "../builders/api.builder";
import { orgEntityAdapter } from "./adapter";

// Define a service using a base URL and expected endpoints
export const baseRoleApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "orgApi",
  tagTypes: ["Role", "Paginate"],

  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}organization`,
    prepareHeaders: serviceBuilder.prepareHeaders
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: orgEntityAdapter
  })
});

export const orgApi = baseRoleApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<
      {
        results: EntityState<Organization>;
        hasMore: boolean;
        nextCursor: string | null;
        query: string;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: Organization[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        {
          const adapter = orgEntityAdapter.addMany(
            orgEntityAdapter.getInitialState(),
            response.results
          );
          return {
            query: arg.query,
            results: adapter,
            hasMore: response.hasMore,
            nextCursor: response.nextCursor
          };
        }
      }
    }),
    userOrganizations: builder.query<EntityState<Organization>, string>({
      query: (userId) => {
        return {
          url: `/user/${userId}`,
          method: "GET"
        };
      },
      transformResponse: (response: { organizations: Organization[] }) => {
        const adapter = orgEntityAdapter.addMany(
          orgEntityAdapter.getInitialState(),
          response.organizations
        );
        return { ...adapter };
      }
    }),
    cloneConfiguration: builder.mutation<
      {},
      {
        sourceOrgId: string;
        destinationOrgId: string;
        cloneConfiguration: CloneConfigurationType[];
      }
    >({
      query: (r) => {
        return {
          url: `/clone-configuration`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
