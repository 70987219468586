import { RolodexServiceProvider } from "@elphi/types";
import { useEffect, useMemo, useState } from "react";
import { EMPTY } from "../../../../../../constants/common";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { providerFormSectionBuilder } from "./providersFormStep.utils";

export const useProviderFormStepHooks = <
  T extends RolodexServiceProvider
>(props: {
  state: Partial<T>;
  onChangeState: (v: OnChangeInput) => void;
  isReadOnly: boolean;
  showAttached: boolean;
}) => {
  const { state, onChangeState, isReadOnly, showAttached } = props;
  const { selectedConfiguration, configurationState } =
    useRolodexConfiguration();
  const [isStepValid, setStepValid] = useState<boolean>(false);
  const formSection = useMemo(() => {
    return providerFormSectionBuilder({
      state,
      onChange: onChangeState,
      configuration:
        configurationState.entities[state?.domainConfigurationId || EMPTY] ||
        selectedConfiguration,
      isReadOnly,
      showAttached
    });
  }, [state, selectedConfiguration, isReadOnly]);

  useEffect(() => {
    setStepValid(
      formSection.inputs.every((x) => x?.isValid) && !!formSection.inputs.length
    );
  }, [state]);

  return {
    formSection,
    isStepValid,
    setStepValid
  };
};
