import { SystemSetup } from "@elphi/types";
import { createContext, useContext, useMemo, useState } from "react";
import { auth } from "../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import { getSectionsLoader } from "../factories/sectionsLoaderFactory";
import { DynamicSections } from "../types/dynamicSchema.types";
import { getDomainOwner } from "../utils/formCommon.utils";

type SectionsState<T> = {
  sections?: T;
};

const SectionsContext = createContext<SectionsState<DynamicSections>>({
  sections: undefined
});

export const useSections = <T extends DynamicSections>() =>
  useContext(SectionsContext) as SectionsState<T>;

export const SectionsProvider = ({ children }) => {
  const [sections, setSections] = useState<DynamicSections | undefined>(
    undefined
  );
  const [domainOwner, setDomainOwner] = useState<SystemSetup | undefined>(
    undefined
  );

  // TODO: env var
  const domain = window.location.hostname;

  useMemo(() => {
    if (!domainOwner) {
      return;
    }
    const sections = getSectionsLoader(domainOwner).loadSections();
    setSections(sections);
  }, [domainOwner]);

  useAuthStateChangeHook({
    onAuthStateChange: (user) => {
      if (!user?.email) {
        return;
      }

      const specOwner = getDomainOwner();
      auth.currentUser && setDomainOwner(specOwner);
    },
    deps: [domain]
  });

  return (
    <SectionsContext.Provider value={{ sections }}>
      {children}
    </SectionsContext.Provider>
  );
};
