// Need to use the React-specific entry point to allow generating React hooks
import { TaskTemplate } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";
import { taskTemplateEntityAdapter } from "./taskTemplate.adapter";


// Define a service using a base URL and expected endpoints
export const baseTaskTemplateApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "taskTemplateApi",
  tagTypes: ["Task", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "task-template"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: taskTemplateEntityAdapter
  })
});

export const taskTemplateApi = baseTaskTemplateApi.injectEndpoints({
  endpoints: (builder) => ({
    batchUpdate: builder.mutation<
      { batch: string[] },
      { tasks: ({ id: string } & Partial<TaskTemplate>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    search: builder.query<
      {
        results: EntityState<TaskTemplate>;
        hasMore: boolean;
        nextCursor: string | null;
        query: string;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: TaskTemplate[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        const adapter = taskTemplateEntityAdapter.addMany(
          taskTemplateEntityAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          hasMore: response.hasMore,
          nextCursor: response.nextCursor
        };
      }
    }),
    getBatch: builder.query<{ batch: EntityState<TaskTemplate> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: TaskTemplate[] }) => {
        const adapter = taskTemplateEntityAdapter.addMany(
          taskTemplateEntityAdapter.getInitialState(),
          response.results
        );

        return { batch: adapter };
      }
    })
  })
});
