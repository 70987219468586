import {
  AggregationType,
  IntegrationType,
  PropertyReportVendorType
} from "@elphi/types";
import { stringValidation } from "../../../../../components/utils/validationUtils";
import { buildInput } from "../../../utils/formBuilder.utils";
import { integrationSpecs } from "../../spec-files/integrationFieldsSpecs";

const { spec } = integrationSpecs.property.PropertyReport.SiteX;

export const siteXOrderStatus = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].orderStatus?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXOrderCompletedOn = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].orderCompletedOn?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXAddressLineText = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].addressLineText?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXAddressUnitIdentifier = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].addressUnitIdentifier?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXCityName = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].cityName?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXStateCode = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].stateCode?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXPostalCode = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].postalCode?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXCountyName = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].countyName?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXAssessorParcelNumber = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].assessorParcelNumber?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXFips = buildInput({
  fieldKey:
    spec.aggregations?.Integrations?.[IntegrationType.PropertyReport]?.[
      PropertyReportVendorType.SiteX
    ].fips?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});
