export enum collectionTypes {
  FormItem = "form_items",
  LoanData = "loan_data",
  LoanMetadata = "loan_metadata",
  LOSUser = "los_user",
  Party = "party",
  Property = "property",
  Task = "taskV2",
  TaskRule = "task_rule_template",
  TaskTemplate = "task_template",
  TaskConfiguration = "task_configuration",
  Worksheet = "worksheets",
  LoanPartyRelationship = "loan_party_relationship",
  LoanPropertyRelationship = "loan_property_relationship",
  PartyRelationship = "party_relationship",
  Role = "role",
  Team = "team",
  Branch = "branch",
  Organization = "organization",
  Template = "template",
  FormItemTaskRelationship = "formitem_tasktype_relationship",
  DealUserRelationship = "deal_user_relationship",
  PartyUserRelationship = "party_user_relationship",
  DocumentConfiguration = "document_configuration",
  DocumentRuleTemplate = "document_rule_template",
  DocumentPackageOrder = "document_package_order",
  Asset = "asset",
  PartyAssetRelationship = "party_asset_relationship",
  Statement = "statement"
}

export enum subcollectionTypes {
  BucketNotificationCounter = "notificationCounters",
  Comment = "comments",
  Document = "documents",
  Setting = "settings",
  UserSetting = "userSettings",
  TaskActivityLog = "taskLogs"
}

export enum storageLocationTypes {
  Party = "party",
  Property = "property",
  Deal = "deal"
}

export enum queryOrderTypes {
  Ascending = "asc",
  Descending = "desc"
}

export enum queryOperatorTypes {
  ArrayContains = "array-contains",
  ArrayContainsAny = "array-contains-any",
  Equal = "==",
  GreaterThan = ">",
  GreaterThanOrEqual = ">",
  In = "in",
  LessThan = "<",
  LessThanOrEqual = "<",
  NotEqual = "!",
  NotIn = "not-in"
}

export enum entityTypes {
  Party = "party",
  Property = "property",
  Deal = "deal"
}

export enum bucketNotificationTypes {
  FormItemLog = "HISTORY_LOG",
  FormItemChat = "CHAT_PER_FIELD"
}

export enum userDataFields {
  FullName = "name",
  Role = "role",
  UserIdentifer = "id",
  WorksheetIdentifier = "worksheetId"
}

export enum userAssignmentTypes {
  Add = "Add",
  Remove = "Remove"
}

export enum activityLogTypes {
  Task = "Checklist Task"
}

export enum taskActivityLogStatusTypes {
  Created = "assigned",
  Commented = "commented",
  Completed = "completed",
  Deleted = "deleted",
  Downloaded = "downloaded",
  Rejected = "rejected",
  Denied = "denied",
  Uploaded = "uploaded",
  Viewed = "viewed",
  DateOrderedFilled = "dateOrderedFilled",
  DueDateFilled = "dueDateFilled",
  OrderedFromFilled = "orderedFromFilled",
  StatusFilled = "statusFilled"
}

// TODO= Add list of task status types to DB
// Create a default task status types list
export enum taskStatusTypes {
  Approved = "completed",
  Assigned = "assigned",
  Created = "created",
  Downloaded = "downloaded",
  Ordered = "ordered",
  NotCreated = "not created",
  Uploaded = "uploaded",
  Rejected = "rejected",
  Denied = "denied",
  UnderReview = "under review",
  Received = "received",
  Viewed = "viewed",
  DocumentsGenerated = "docs generated",
  FloodZoneDeterminationOrdered = "flood zone determination ordered",
  FloodDeterminationReceived = "flood determination received",
  FloodCertificateOrdered = "flood certificate ordered",
  FloodCertificateReceived = "flood certificate received",
  OrderFailed = "order failed",
  PropertyInspectionSubmission = "submitted to pruvan",
  PropertyInspectionOrderSubmission = "order submitted",
  PropertyInspectionOrderReceived = "order received"
}

export enum FloorCertificationOrderStatusType {
  FloodInsuranceRequired = "flood insurance required",
  FloodInsuranceNotRequired = "flood insurance not required",
  FloodInsuranceIndeterminate = "unable to determine if flood insurance is required",
  FloodCertificationOrderManualResearchRequired = "manual research required",
  OrderRequiredBeforeClosing = "order required before closing",
  FloodCertificationOrderCompleted = "order completed"
}

export enum taskDocFields {
  AssignableBy = "assignableBy",
  BorrowerView = "borrowerView",
  BorrowerTask = "borrowerTask",
  ChecklistOf = "checkListOf",
  CustomName = "customName",
  CreationDate = "creationDate",
  Description = "description",
  OrderedDate = "dateOrdered",
  DueDate = "dueDate",
  Documents = "documents",
  EditableBy = "editableBy",
  Explanation = "explanation",
  FilePath = "filePath",
  FileType = "fileType",
  GetFrom = "getFrom",
  LastModifiedDate = "lastModifiedDate",
  MilestoneName = "milestoneName",
  OrderedFrom = "orderedFrom",
  Read = "read",
  ReceivedDate = "receivedDate",
  RelationshipId = "relationshipId",
  RelationshipType = "relationshipType",
  SpecialType = "specialType",
  Status = "status",
  TaskStatus = "status",
  OrderStatus = "orderStatus",
  TaskIdentifier = "id",
  TaskName = "taskName",
  TaskType = "type",
  UploadDate = "uploadDate"
}

export enum taskActivityLogFields {
  AttachmentPath = "attachmentPath",
  AttachmentType = "attachmentType",
  AttachmentDescription = "attachment",
  ActivityLogType = "activityType",
  Comment = "comment",
  DocumentAttachedTo = "docAttachedTo",
  GenerationType = "generation",
  Status = "status",
  Result = "result",
  TaskIdentifier = "taskId",
  TaskType = "taskType",
  TaskName = "taskName",
  Timestamp = "timestamp",
  UserFullName = "user",
  UserIdentifier = "userId",
  UserRole = "userRole"
}

export enum assignLoanPermissionTypes {
  AllUsers = "ASSIGN_ALL",
  Disabled = "CANNOT_ASSIGN",
  LineOfCreditOperationAnalysts = "ASSIGN_LOC_ANALYST",
  OperationAnalysts = "ASSIGN_OPS_ANALYST",
  Processors = "ASSIGN_PROCESSORS",
  TeamMembers = "ASSIGN_TEAM_MEMBERS"
}

export enum snapshotChangeTypes {
  Modified = "modified"
}

export enum losUserFields {
  LenderIdentifier = "lender",
  Role = "role"
}

export enum loanDataFields {
  LenderIdentifier = "Deal.LenderIdentifier"
}

export enum loanMetadataFields {
  OperationAnalystIdentifier = "Ops AnalystId",
  OrganizationIdentifier = "organizations",
  TeamIdentifier = "teams",
  BranchIdentifier = "branches"
}

export enum roleTypes {
  LineOfCreditOperationAnalyst = "LOC Ops Analyst",
  OperationAnalyst = "Ops Analyst",
  SeniorOperationAnalyst = "Senior Ops Analyst"
}

export enum dashboardTableColumnNames {
  OperationAnalyst = "Ops Analyst",
  SeniorOperationAnalyst = "Senior Ops Analyst",
  LineOfCreditOperationAnalyst = "Line of Credit Member"
}

export enum taskGenerationTypes {
  Manual = "manual",
  Automatic = "automatic"
}

export enum fileTypes {
  XLSM = "xlsm",
  XSLX = "xlsx",
  PDF = "pdf",
  CSV = "csv",
  WAV = "wav",
  ZIP = "zip",
  DOCX = "docx"
}
