import {
  BranchServiceProvider,
  CompanyServiceProvider,
  RepresentativeServiceProvider
} from "@elphi/types";
import { LoadingContent } from "../../../../../../common-components";
import { ProvidersFormStepContent } from "../../../../../rolodex/service-provider/wizard/steps/provider-step-content/ProvidersFormStepContent";
import { useServiceProviderForm } from "./providersStep.hooks";

type ProvidersStepProps = {
  setStepValidity: (isValid: boolean) => void;
  companyFormState: ReturnType<
    typeof useServiceProviderForm<CompanyServiceProvider>
  >;
  branchFormState: ReturnType<
    typeof useServiceProviderForm<BranchServiceProvider>
  >;
  repFormState: ReturnType<
    typeof useServiceProviderForm<RepresentativeServiceProvider>
  >;
  branchRepRelationId?: string;
};

export const ProvidersStep = (props: ProvidersStepProps) => {
  const { companyFormState, branchFormState, repFormState, setStepValidity } =
    props;

  const isLoading =
    companyFormState.getByIdApiResponse.isFetching ||
    branchFormState.getByIdApiResponse.isFetching ||
    repFormState.getByIdApiResponse.isFetching;

  return (
    <LoadingContent isLoading={isLoading}>
      <ProvidersFormStepContent
        companyForm={{
          state: companyFormState.state,
          onChangeState: companyFormState.onChange,
          setState: (selected) => {
            companyFormState.setState(selected);
          },
          selectedProviderId: companyFormState.selectedProviderId,
          setSelectedProviderId: (id) => {
            companyFormState.setSelectedProviderId(id);
          }
        }}
        branchForm={{
          state: branchFormState.state,
          onChangeState: branchFormState.onChange,
          setState: (selected) => {
            branchFormState.setState(selected);
          },
          selectedProviderId: branchFormState.selectedProviderId,
          setSelectedProviderId: (id) => {
            branchFormState.setSelectedProviderId(id);
          }
        }}
        repForm={{
          state: repFormState.state,
          onChangeState: repFormState.onChange,
          setState: (selected) => {
            repFormState.setState(selected);
          },
          selectedProviderId: repFormState.selectedProviderId,
          setSelectedProviderId: (id) => {
            repFormState.setSelectedProviderId(id);
          }
        }}
        onFormValidation={(isValid) => setStepValidity(isValid)}
        showAttached={false}
      />
    </LoadingContent>
  );
};
