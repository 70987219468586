import { IntegrationType, ServiceLinkOrderStatusToLabel } from "@elphi/types";
import { FundingShieldTrxStatus } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import { get, mapValues } from "lodash";
import { IntegrationOrderStatusResolverRequest } from "../types";

const floodCertificateOrder: IntegrationOrderStatusResolverRequest = {
  integrationOrderStatusPath:
    "property.aggregations.Integrations.FloodCertification.ServiceLink.OrderStatus",
  isAggregation: true,
  mapper: (value) => {
    const statusLabelMap = mapValues(ServiceLinkOrderStatusToLabel);
    return get(statusLabelMap, value, value);
  }
};

const wireInsuranceCertificateOrder: IntegrationOrderStatusResolverRequest = {
  isAggregation: true,
  integrationOrderStatusPath:
    "deal.aggregations.Integrations.WireInsuranceCertificate.FundingShield.TransactionStatus",
  mapper: (value) => {
    const statusLabelMap = mapValues(FundingShieldTrxStatus);
    return get(statusLabelMap, value, value);
  }
};

const propertyOrderReport: IntegrationOrderStatusResolverRequest = {
  isAggregation: true,
  integrationOrderStatusPath:
    "property.aggregations.Integrations.PropertyReport.SiteX.orderStatus"
};

const rentalDataOrderReport: IntegrationOrderStatusResolverRequest = {
  isAggregation: true,
  integrationOrderStatusPath:
    "property.aggregations.Integrations.RentalData.RentRange.orderStatus"
};

const integrationRequestMap = (integrationType: IntegrationType) => {
  const integrationMap: {
    [t in IntegrationType]?: IntegrationOrderStatusResolverRequest;
  } = {
    [IntegrationType.FloodCertificate]: floodCertificateOrder,
    [IntegrationType.WireInsuranceCertificate]: wireInsuranceCertificateOrder,
    [IntegrationType.PropertyReport]: propertyOrderReport,
    [IntegrationType.RentalData]: rentalDataOrderReport
  };

  return integrationMap[integrationType] || {};
};

export { integrationRequestMap };
