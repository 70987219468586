import {
  DealPartyRelation,
  DealPartyRelationType,
  DealToEntityRelation,
  DealToIndividualRelation,
  FieldType
} from "@elphi/types";
import {
  baseLoanPartyEntityRole,
  baseLoanPartyIndividualRole
} from "@elphi/types/entities/base";
import { baseEntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/baseEntity.fields";
import {
  EntityFormFieldSpecs,
  SpecsBaseOmit
} from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";

export type BaseSchemaDealToIndividualFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<DealToIndividualRelation>
>;

export type BaseSchemaDealToEntityFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<DealToEntityRelation>
>;

type BaseDealPartyFieldSpecs = EntityFormFieldSpecs<
  Omit<SpecsBaseOmit<DealPartyRelation>, "relationRoleType">
>;

export const baseSchemaDealPartyFieldSpecs: BaseDealPartyFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  type: {
    fieldKey: ["type"],
    fieldType: FieldType.SingleSelect,
    options: createOptionsFromMap(DealPartyRelationType),
    label: "Deal-Party Relation Type"
  },
  dealId: {
    fieldKey: ["dealId"],
    fieldType: FieldType.String,
    label: "Deal ID"
  },
  partyId: {
    fieldKey: ["partyId"],
    fieldType: FieldType.String,
    label: "Party ID"
  }
};

export const baseSchemaDealToIndividualFieldSpecs: BaseSchemaDealToIndividualFieldSpecs =
  {
    ...baseSchemaDealPartyFieldSpecs,
    relationRoleType: {
      fieldKey: ["relationRoleType"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromMap(baseLoanPartyIndividualRole),
      label: "Deal-Party Role"
    }
  };

export const baseSchemaDealToEntityFieldSpecs: BaseSchemaDealToEntityFieldSpecs =
  {
    ...baseSchemaDealPartyFieldSpecs,
    relationRoleType: {
      fieldKey: ["relationRoleType"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromMap(baseLoanPartyEntityRole),
      label: "Deal-Party Role"
    }
  };

export const fullBaseSchemaDealToIndividualSpecs = createSpecWithFieldMeta({
  spec: baseSchemaDealToIndividualFieldSpecs
});

export const fullBaseSchemaDealToEntitySpecs = createSpecWithFieldMeta({
  spec: baseSchemaDealToEntityFieldSpecs
});
