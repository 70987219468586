import { useState } from "react";
import { IdleTimerProvider, PresenceType } from "react-idle-timer";
import { auth } from "../../../../firebase/firebaseConfig";
import { isLocal } from "../../../../utils/envUtils";
import { ActivityIdleNotifier } from "./ActivityIdleProviderNotifier";

export type ActivityIdleProviderConfiguration = {
  signOutInSeconds: number;
  notifyBeforeIdleInMilliSeconds: number;
  syncBetweenBrowsersInMilliSeconds: number;
  throttleActionAfterIdlingInMilliSeconds: number;
};

const defaultConfiguration: ActivityIdleProviderConfiguration = {
  signOutInSeconds: 14400, //240 minutes
  notifyBeforeIdleInMilliSeconds: 300000, //5 mintutes
  syncBetweenBrowsersInMilliSeconds: 200,
  throttleActionAfterIdlingInMilliSeconds: 500
};

export enum ActivityIdleProviderStatus {
  IDLE = "idle",
  ACTIVE = "active",
  PROMPTED = "promped"
}

export type ActivityIdleProviderProps = {
  children: React.ReactNode;
  signOutInSeconds?: number;
};

const getMilliSeconds = (seconds: number) => seconds * 1000;

export const ActivityIdleProvider = (props: ActivityIdleProviderProps) => {
  const [activity, setActivity] = useState<string>(
    ActivityIdleProviderStatus.ACTIVE
  );
  const [shouldNotify, setShouldNotify] = useState<boolean>(false);

  const onPresenceChange = (presence: PresenceType) => {
    console.log({ ...presence });
    console.log(activity);
  };

  const onPrompt = () => {
    setActivity(ActivityIdleProviderStatus.PROMPTED);
    setShouldNotify(true);
  };

  const onIdle = () => {
    setActivity(ActivityIdleProviderStatus.IDLE);
    setShouldNotify(false);
    handleSignOut();
  };

  const onActive = () => {
    setActivity(ActivityIdleProviderStatus.ACTIVE);
    setShouldNotify(false);
  };

  const handleSignOut = () => {
    setShouldNotify(false);
    auth.signOut().then(() => {
      console.log("activity idle provider signed out user");
    });
  };
  const handleStayActive = () => {
    setShouldNotify(false);
  };

  const timeout = getMilliSeconds(
    props.signOutInSeconds || defaultConfiguration.signOutInSeconds
  );

  return (
    <IdleTimerProvider
      disabled={isLocal()}
      crossTab={true}
      leaderElection={true}
      syncTimers={defaultConfiguration.syncBetweenBrowsersInMilliSeconds}
      throttle={defaultConfiguration.throttleActionAfterIdlingInMilliSeconds}
      promptBeforeIdle={defaultConfiguration.notifyBeforeIdleInMilliSeconds}
      timeout={timeout}
      onPresenceChange={onPresenceChange}
      onPrompt={onPrompt}
      onIdle={onIdle}
      onActive={onActive}
    >
      <ActivityIdleNotifier
        showNotifier={shouldNotify}
        onStayActive={handleStayActive}
        onSignOut={handleSignOut}
      />
      {props.children}
    </IdleTimerProvider>
  );
};
