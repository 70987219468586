import { IntegrationType, Task } from "@elphi/types";

export const hasOrderStatus = (
  task?: Task
): task is Task & { orderStatus: string } => {
  return !!task && "orderStatus" in task;
};

export const hasIntegrationType = (
  task?: Task
): task is Task & { integrationType: IntegrationType } => {
  return !!task && "integrationType" in task;
};
