import { Task } from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import { buildSection } from "../../utils/formBuilder.utils";
import {
  dueDate,
  orderStatus,
  orderedDate,
  orderedFrom,
  receivedDate,
  vendorInformation,
  vendorName
} from "../inputs/task.inputs";
import { BaseSchemaSectionsBuilders, BaseSchemaTaskSections } from "../types";

const orderStatusSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: PartialWithId<Task>;
    onChange: (v: OnChangeInput) => void;
    options: {
      header?: string;
      hideAttachedComponent: boolean;
      isDisabled?: boolean;
    };
  }): Section => {
    const isReadOnly = !!r.options.isDisabled;
    return buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Order Status",
      inputs: [
        {
          ...vendorName,
          isReadOnly
        },
        {
          ...vendorInformation,
          isReadOnly
        },
        {
          ...receivedDate,
          isReadOnly
        },
        {
          ...dueDate,
          isReadOnly
        },
        {
          ...orderedDate,
          isReadOnly
        },
        {
          ...orderedFrom,
          isReadOnly
        },
        {
          ...orderStatus,
          isReadOnly
        }
      ],
      fieldSpecs
    });
  };

export const taskSectionsBuilders: BaseSchemaSectionsBuilders<BaseSchemaTaskSections> =
  { orderStatusSection };
