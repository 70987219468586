import {
  RolodexBranchRepresentativeRelation,
  RolodexServiceProvider,
  ServiceProviderType
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityState } from "@reduxjs/toolkit";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { ElphiNode } from "../../../tree/types/Tree.types";
import {
  ServiceProviderTypeMap,
  buildOption
} from "../utils/serviceProvider.utils";

export type ServiceProviderNode = RolodexServiceProvider & {
  branchRepRelationId?: string;
};

export type ServiceProviderTreeNode = ElphiNode<ServiceProviderNode>;

export const buildTree = (r: {
  relations?: RolodexBranchRepresentativeRelation[];
  selected?: RolodexServiceProvider;
  serviceProviderState: EntityState<RolodexServiceProvider>;
}): ServiceProviderTreeNode => {
  const { selected, relations, serviceProviderState } = r;
  if (!selected) {
    return buildNode();
  }

  const rootData = getRootData({ relations, selected, serviceProviderState });
  const rootNode = buildNode({ provider: rootData });
  const queue = [rootNode];
  const processedIds = new Set();

  while (queue.length > 0) {
    const currentNode = queue.shift();
    if (currentNode && currentNode.data) {
      const { id, type } = currentNode.data;
      processedIds.add(id);
      const currentRelations = getSelectedRelations({
        relations,
        type,
        selectedId: id
      });
      currentRelations?.forEach((relation) => {
        const childId = getChildId({ relation, selectedId: id });
        if (childId && !processedIds.has(childId)) {
          const childEntity = serviceProviderState.entities[childId];
          if (childEntity) {
            const childNode = buildNode({ provider: childEntity, relation });
            currentNode.children.push(childNode);
            queue.push(childNode);
            processedIds.add(relation.branchId);
          }
        }
      });
    }
  }
  return rootNode;
};

const buildNode = (r?: {
  provider: RolodexServiceProvider;
  relation?: RolodexBranchRepresentativeRelation;
}): ServiceProviderTreeNode => {
  if (!r) {
    const emptyNode = {
      data: null,
      children: [],
      id: EMPTY,
      nodeKey: EMPTY
    };
    return emptyNode;
  }
  const { provider, relation } = r;
  return {
    data: {
      ...provider,
      branchRepRelationId: relation?.id
    },
    children: [],
    id: provider.id,
    label: buildOption(provider)?.label || NOT_AVAILABLE,
    sublabel: ServiceProviderTypeMap[provider?.type || EMPTY] || NOT_AVAILABLE,
    nodeKey:
      provider.type === ServiceProviderType.Representative && relation
        ? relation.id
        : provider.id
  };
};

const getRootData = (r: {
  relations?: RolodexBranchRepresentativeRelation[];
  selected: RolodexServiceProvider;
  serviceProviderState: EntityState<RolodexServiceProvider>;
}) => {
  const { relations, selected, serviceProviderState } = r;
  const companyId = relations?.find(
    (x) =>
      x?.companyId === selected.id ||
      x?.branchId === selected.id ||
      x?.representativeId === selected.id
  )?.companyId;

  const rootData =
    serviceProviderState.entities[companyId || EMPTY] || selected;
  return rootData;
};

const getSelectedRelations = (r: {
  relations?: RolodexBranchRepresentativeRelation[];
  type: ServiceProviderType;
  selectedId: string;
}) => {
  const { relations, type, selectedId } = r;
  return relations
    ?.filter(
      (relation) =>
        (type === ServiceProviderType.Company &&
          relation?.companyId === selectedId) ||
        (type === ServiceProviderType.Branch &&
          relation?.branchId === selectedId) ||
        (type === ServiceProviderType.Representative &&
          relation?.representativeId === selectedId)
    )
    .filter(removeEmpty);
};

const getChildId = (r: {
  relation: RolodexBranchRepresentativeRelation;
  selectedId: string;
}) => {
  const { relation, selectedId } = r;
  return relation?.companyId === selectedId
    ? relation.branchId
    : relation?.branchId === selectedId
    ? relation.representativeId
    : relation?.representativeId === selectedId
    ? relation.branchId
    : undefined;
};
