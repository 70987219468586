import { Property, StatusCode } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { pickBy, values } from "lodash";
import { useMemo } from "react";
import { useInsurancePolicyHooks } from "../../../../../../hooks/insurance-policy/insurancePolicy.hooks";
import { usePropertyInsurancePolicyHooks } from "../../../../../../hooks/insurance-policy/propertyInsurancePolicy.hooks";
import { usePropertyHooks } from "../../../../../../hooks/property.hooks";
import { useElphiToast } from "../../../../../toast/toast.hook";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { orderPropertiesByDealId } from "../utils/formUtils";
import { buildOption } from "../utils/optionsDisplay.utils";

export const usePropertiesSelectStepHook = (r: {
  state: Partial<PropertyInsurancePolicyState>;
}) => {
  const { state } = r;
  const { selectedDeal } = usePropertyInsurancePolicyHooks();
  const { getPropertyBatch, getPropertyBatchResponse, selectedProperty } =
    usePropertyHooks();
  const { propertyState } = usePropertyHooks();
  const { selectedInsurancePolicy } = useInsurancePolicyHooks();

  const { errorToast } = useElphiToast();

  const fetchProperties = async () => {
    if (!state.entityIds) {
      return;
    }

    await getPropertyBatch(state.entityIds).then((x) => {
      x.status === StatusCode.BadRequest &&
        errorToast({
          title: "Failed to fetch deal properties",
          description: "Failed to fetch deal properties"
        });
    });
  };

  const isLoading = getPropertyBatchResponse.isFetching;
  const isPropertyBelongToDeal = (property: Property) =>
    property?.id &&
    selectedDeal?.id &&
    property?.dealRelation?.includes(selectedDeal?.id);

  const isSelectedProperty = (property: Property) =>
    property?.id && selectedProperty?.id === property.id;

  const options = useMemo(() => {
    const policyPropertyIds = new Set(state.entityIds || []);
    const properties = pickBy(propertyState.entities, (property: Property) => {
      return (
        policyPropertyIds.has(property.id) ||
        isPropertyBelongToDeal(property) ||
        isSelectedProperty(property)
      );
    });

    const orderedProperties = orderPropertiesByDealId({
      properties: values(properties).filter(removeEmpty),
      dealId: selectedDeal?.id || ""
    });
    return orderedProperties
      .map((property) => {
        return buildOption({
          property,
          selectedDeal,
          selectedProperty,
          selectedInsurancePolicy
        });
      })
      .filter(removeEmpty);
  }, [propertyState.entities]);

  return {
    isLoading,
    options,
    fetchProperties
  };
};
