import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from "@chakra-ui/input";
import Fuse from "fuse.js";
import { useEffect, useMemo } from "react";
import { EMPTY } from "../../../constants/common";
import { useFirstMountHook } from "../../../hooks/firstMount.hook";

import { DEFAULT_FUSE_CONFIG, useFuzzyHooks } from "./fuzzy.hooks";
import { FuzzySearchProps } from "./fuzzySearch.types";

export const FuzzySearch = <T extends object>(props: FuzzySearchProps<T>) => {
  const isFirstMount = useFirstMountHook();
  const { query, onInputChange, debouncedQuery } = useFuzzyHooks({
    ...props
  });

  const { showAllOnEmpty = true } = props;

  const instance = useMemo(() => {
    return new Fuse(
      props.dataSet,
      props.configuration
        ? { ...props.configuration }
        : { ...DEFAULT_FUSE_CONFIG }
    );
  }, [props.dataSet, props.configuration]);

  useEffect(() => {
    if (!isFirstMount) {
      const result = debouncedQuery
        ? instance.search(debouncedQuery).map((result) => result.item)
        : showAllOnEmpty
        ? props.dataSet
        : [];
      props.onResult(result);
    }
  }, [debouncedQuery]);

  return (
    <InputGroup maxWidth={400}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        variant="outline"
        fontSize={12}
        value={query}
        placeholder={props.label || "Search"}
        onChange={(e) => onInputChange(e.target.value)}
      />
      <InputRightElement hidden={!query}>
        <CloseIcon
          w={4}
          h={4}
          cursor={"pointer"}
          color="gray.500"
          onClick={() => onInputChange(EMPTY)}
        />
      </InputRightElement>
    </InputGroup>
  );
};
