import { baseQuery } from "./baseQuery.builder";
import { crudEndpoints } from "./crud.endpoints.builder";
import { prepareHeaders } from "./headers.builder";

const serviceBuilder = {
  crudEndpoints,
  prepareHeaders,
  baseQuery
};
export default serviceBuilder;
