import { Flex } from "@chakra-ui/react";
import {
  BaseInsurancePolicy,
  Deal,
  InsurancePolicyEntityType,
  Property
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { isNil } from "lodash";
import { useMemo } from "react";
import { CustomCollapse } from "../../../../../../common-components";
import { EMPTY, NOT_AVAILABLE } from "../../../../../../constants/common";
import { usePropertyInsurancePolicyHooks } from "../../../../../../hooks/insurance-policy/propertyInsurancePolicy.hooks";
import { usePropertyHooks } from "../../../../../../hooks/property.hooks";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { orderPropertiesByDealId } from "../utils/formUtils";
import { buildOption } from "../utils/optionsDisplay.utils";
import { PropertyBuildingsFormList } from "./PropertyBuildingsFormList";
import { PropertyCoveragesData } from "./PropertyCoveragesData";

type CoverageDataRowProps = {
  onChange: (value: OnChangeInput) => void;
  state: PropertyInsurancePolicyState;
  property: Property;
  selectedProperty?: Property;
  selectedDeal?: Deal;
};

const CoverageDataRow = (props: CoverageDataRowProps) => {
  const { property, selectedDeal, selectedProperty, onChange, state } = props;

  const propertyId = property?.id || EMPTY;
  const option = useMemo(
    () =>
      buildOption({
        property,
        selectedDeal,
        selectedProperty,
        selectedInsurancePolicy:
          state as BaseInsurancePolicy<InsurancePolicyEntityType>
      }),
    [state.entityIds, property, selectedDeal, selectedProperty]
  );

  const sectionName = option?.label || NOT_AVAILABLE;
  const isDisabled = isNil(option?.isDisabled) ? true : option?.isDisabled;
  return (
    <CustomCollapse
      options={{
        containerColor: "white",
        containerBorderColor: "gray.200"
      }}
      title={sectionName}
      customBody={
        <Flex direction="column" gap="16px">
          <PropertyCoveragesData
            isDisabled={isDisabled}
            property={property}
            propertyId={propertyId}
            onChange={onChange}
            state={state}
          />
          <Flex direction="column" pl="8px">
            <PropertyBuildingsFormList
              propertyId={propertyId}
              state={state}
              isDisabled={isDisabled}
              onChange={onChange}
              sectionName={sectionName}
            />
          </Flex>
        </Flex>
      }
    />
  );
};

type CoverageDataStepProps = {
  onChange: (value: OnChangeInput) => void;
  state: PropertyInsurancePolicyState;
  dealId?: string;
};
export const CoverageDataStep = (props: CoverageDataStepProps) => {
  const { onChange, state, dealId } = props;
  const { propertyState } = usePropertyHooks();
  const policyProperties = state.entityIds || [];
  const { getDealByIdFromState } = usePropertyInsurancePolicyHooks();

  const properties = useMemo(() => {
    if (!propertyState.entities) {
      return [];
    }
    const options = policyProperties
      .map((propertyId) => propertyState.entities[propertyId])
      .filter(removeEmpty);

    return dealId
      ? orderPropertiesByDealId({ properties: options, dealId })
      : options;
  }, [propertyState.entities, policyProperties, dealId]);

  return (
    <Flex h="100%" overflowY="auto">
      <Flex width="100%" direction="column" gap="16px">
        {properties.map((property: Property, index: number) => {
          if (!property) {
            return <></>;
          }
          const selectedProperty = propertyState.selectedId
            ? propertyState.entities[propertyState.selectedId]
            : undefined;
          const deal = getDealByIdFromState(dealId);
          return (
            <CoverageDataRow
              key={index}
              property={property}
              selectedDeal={deal}
              selectedProperty={selectedProperty}
              onChange={onChange}
              state={state}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
