import { RolodexServiceProvider } from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { useEffect } from "react";
import { AppConfig } from "../../../../config/appConfig";
import { EMPTY } from "../../../../constants/common";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import FormBuilder, {
  OnChangeInput,
  Section
} from "../../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { buildOption, navigationPath } from "../utils/serviceProvider.utils";
import { providerFormSectionBuilder } from "../wizard/steps/provider-step-content/providersFormStep.utils";

type ServiceProviderState = {
  serviceProviders: { [id: string]: RolodexServiceProvider };
};

export const InfoFormSection = (props: {
  selected?: RolodexServiceProvider;
}) => {
  const { selected } = props;
  if (!selected) {
    <></>;
  }
  const { updateServiceProvidersHandler } = useServiceProviderHooks();
  const { configurationState } = useRolodexConfiguration();

  const { onChange, state, syncState } =
    useFormBuilderStateHandler<ServiceProviderState>({
      initialState: { serviceProviders: {} } as ServiceProviderState,
      callback: async (r) => {
        const providers = r.serviceProviders || {};
        const map = Object.keys(providers).reduce((p, v) => {
          const pr = providers[v];
          if (!pr) {
            return p;
          }
          p[v] = { ...providers[v], id: v };
          return p;
        }, {} as { [id: string]: PartialWithId<RolodexServiceProvider> });

        return await updateServiceProvidersHandler(map).then((r) => {
          return !r ? null : r;
        });
      },
      callbackOptions: {
        clearDiff: true,
        debounceRate: AppConfig.debounceRate
      }
    });

  useEffect(() => {
    if (selected) {
      syncState({
        shouldSync: !!selected,
        state: selected,
        statePath: () => ["serviceProviders", selected.id]
      });
    }
  }, [selected]);

  const serviceProviderOnChangeBatch = (v: OnChangeInput) => {
    selected &&
      onChange({
        fieldType: v.fieldType,
        fieldKey: ["serviceProviders", selected.id, ...v.fieldKey],
        value: v.value
      });
  };

  const currentDomainConf =
    configurationState?.entities[
      selected ? selected?.domainConfigurationId : EMPTY
    ];

  const infoSection: Section = {
    ...providerFormSectionBuilder({
      onChange: serviceProviderOnChangeBatch,
      state: state.serviceProviders[selected ? selected.id : EMPTY] || {},
      configuration: currentDomainConf,
      isReadOnly: false,
      showAttached: true
    }),
    header: `${selected ? buildOption(selected)?.label : EMPTY} Information`
  };

  return (
    <FormBuilder
      customKey={"serviceProviderInfo"}
      onChange={serviceProviderOnChangeBatch}
      sections={[infoSection]}
      navigationPath={navigationPath}
    />
  );
};
