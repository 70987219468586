import { FeatureFlag, featureFlagsFallback } from "@elphi/utils";
import { GateResult, useGate } from "statsig-react";
import { useFeatureFlag } from "../providers/FeatureFlagsProvider";

export const useStatsigGate = (gate: FeatureFlag): GateResult => {
  const { map } = useFeatureFlag();
  try {
    return useGate(gate);
  } catch (err) {
    console.error(
      `Could not retrieve the gate value for '${gate}' from Statsig. Using local value: ${map[gate]}.`
    );
  }
  return {
    isLoading: false,
    value: map[gate] ?? featureFlagsFallback[gate]
  };
};
