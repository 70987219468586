export const baseLoanPartyEntityRole = {
  PrimaryBorrower: "Primary Borrower",
  Borrower: "Borrower",
  None: "None"
} as const;

export const baseLoanPartyIndividualRole = {
  PrimaryGuarantor: "Primary Guarantor",
  Guarantor: "Guarantor",
  Signatory: "Signatory",
  SignatoryCorporateResolution: "Signatory via Corporate Resolution",
  None: "None"
} as const;
