import FormBuilder, {
  FormBuilderProps
} from "../../../../../form-builder/FormBuilder";

export const StepForm = (props: FormBuilderProps) => {
  return (
    <FormBuilder
      {...props}
      customKey={props.customKey}
      onChange={props.onChange}
      sections={props.sections}
      elphiView="form"
      size={{
        minW: "400px"
      }}
    />
  );
};
