import { PartyGroup } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { partyGroupSlice } from "./partyGroup.slice";

export const partyGroupAction: ActionDispatcher<PartyGroup> = (
  dispatch,
  callback
) => {
  const actionDispatcher = buildAction(partyGroupSlice);
  return actionDispatcher(dispatch, callback);
};
