import { Deal } from "@elphi/types";
import {
  Milestones,
  TaskTypes,
  UserData,
  VersionCondition,
  Workflow
} from "../shared";

/*
 * action types
 */
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const RESET_USER_DATA = "RESET_USER_DATA";
export const UPDATE_WORKSHEET_DATA = "UPDATE_WORKSHEET_DATA";
export const UPDATE_CURRENT_MORTGAGE = "UPDATE_CURRENT_MORTGAGE";
export const UPDATE_MASTER_SCROLL = "UPDATE_MASTER_SCROLL";
export const UPDATE_TASK_TYPES = "UPDATE_TASK_TYPES";
export const UPDATE_TASK_CONDITIONALS = "UPDATE_TASK_CONDITIONALS";
export const UPDATE_MILESTONES = "UPDATE_MILESTONES";
export const UPDATE_TASKS_VERSION_NUMBER = "UPDATE_TASKS_VERSION_NUMBER";
export const UPDATE_MILESTONES_VERSION_NUMBER =
  "UPDATE_MILESTONES_VERSION_NUMBER";
export const UPDATE_STELE_JSON = "UPDATE_STELE_JSON";
export const UPDATE_PARTIES = "UPDATE_PARTIES";
export const UPDATE_PROPERTIES = "UPDATE_PROPERTIES";
export const UPDATE_FORM_ITEMS = "UPDATE_FORM_ITEMS";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";
export const UPDATE_LOANS = "UPDATE_LOANS";
export const UPDATE_LOANS_DATA = "UPDATE_LOANS_DATA";
export const UPDATE_WORKFLOWS = "UPDATE_WORKFLOWS";

export const PartyConstant = {
  GET_PARTY_REQUEST: "GET_PARTY_REQUEST",
  GET_PARTY_SUCCESS: "GET_PARTY_SUCCESS",
  GET_PARTY_ERROR: "GET_PARTY_ERROR",
  UPDATE_PARTY_REQUEST: "UPDATE_PARTY_REQUEST",
  UPDATE_PARTY_SUCCESS: "UPDATE_PARTY_SUCCESS",
  UPDATE_PARTY_ERROR: "UPDATE_PARTY_ERROR",
  DELETE_PARTY_REQUEST: "DELETE_PARTY_REQUEST",
  DELETE_PARTY_SUCCESS: "DELETE_PARTY_SUCCESS",
  DELETE_PARTY_ERROR: "DELETE_PARTY_ERROR",
  CREATE_PARTY_REQUEST: "CREATE_PARTY_REQUEST",
  CREATE_PARTY_SUCCESS: "CREATE_PARTY_SUCCESS",
  CREATE_PARTY_ERROR: "CREATE_PARTY_ERROR"
};
export const PartyRelationConstant = {
  CREATE_RELATION_REQUEST: "CREATE_RELATION_REQUEST",
  CREATE_RELATION_SUCCESS: "CREATE_RELATION_SUCCESS",
  CREATE_RELATION_ERROR: "CREATE_RELATION_ERROR",
  GET_CHILDREN_REQUEST: "GET_CHILDREN_REQUEST",
  GET_CHILDREN_SUCCESS: "GET_CHILDREN_SUCCESS",
  GET_CHILDREN_ERROR: "GET_CHILDREN_ERROR"
};

/*
 * action creators
 */

export function updateUserData(userData: UserData) {
  return { type: UPDATE_USER_DATA, userData };
}

export function resetUserData() {
  return { type: RESET_USER_DATA };
}

export function updatePermissions(permissions: string[]) {
  return { type: UPDATE_PERMISSIONS, data: permissions };
}

export function updateLoans(deals: Deal[]) {
  return { type: UPDATE_LOANS, data: deals };
}

export function updateWorkflows(workflows: Workflow) {
  return { type: UPDATE_WORKFLOWS, data: workflows };
}

export function updateTaskTypes(taskTypes: TaskTypes) {
  return { type: UPDATE_TASK_TYPES, taskTypes };
}

export function updateTaskConditionals(taskConditionals: VersionCondition) {
  return { type: UPDATE_TASK_CONDITIONALS, taskConditionals };
}

export function updateMilestones(milestones: Milestones) {
  return { type: UPDATE_MILESTONES, milestones };
}

export function updateTasksVersionNumber(versionNumber: string) {
  return { type: UPDATE_TASKS_VERSION_NUMBER, versionNumber };
}

export function updateMilestonesVersionNumber(versionNumber: string) {
  return { type: UPDATE_MILESTONES_VERSION_NUMBER, versionNumber };
}
