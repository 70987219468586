import { AuditEventEntityType, ElphiEntityType } from "@elphi/types";
import PathVariables from "../../../../routes/pathVariables";

export const buildUrl = (r: {
  entityId: string;
  entityType: ElphiEntityType | AuditEventEntityType;
  selectedOrgId: string;
}) => {
  const { entityId, entityType, selectedOrgId } = r;
  if (
    entityType === ElphiEntityType.deal ||
    entityType === AuditEventEntityType.Deal
  ) {
    return `/${selectedOrgId}${PathVariables.WorksheetPath}/${entityId}`;
  }
  if (
    entityType === ElphiEntityType.party ||
    entityType === AuditEventEntityType.Party
  ) {
    return `/${selectedOrgId}${PathVariables.PartiesPipeline}?party=${entityId}`;
  }
  if (
    entityType === ElphiEntityType.property ||
    entityType === AuditEventEntityType.Property
  ) {
    return `/${selectedOrgId}${PathVariables.PropertyPipeline}?property=${entityId}`;
  }
  if (
    entityType === ElphiEntityType.serviceProvider ||
    entityType === AuditEventEntityType.ServiceProvider
  ) {
    return `/${selectedOrgId}${PathVariables.ServiceProvider}?serviceProvider=${entityId}`;
  }
  return undefined;
};

export const buildRelationUrls = (r: {
  entityId: string;
  entityType: ElphiEntityType | AuditEventEntityType;
  selectedOrgId: string;
}) => {
  const { entityType, entityId, selectedOrgId } = r;
  if (
    entityType === ElphiEntityType.dealParty ||
    entityType === AuditEventEntityType.DealParty
  ) {
    const [dealId, partyId] = entityId.split("_");
    const dealLink = `/${selectedOrgId}${PathVariables.WorksheetPath}/${dealId}`;
    const partyLink = `/${selectedOrgId}${PathVariables.PartiesPipeline}?party=${partyId}`;
    return [dealLink, partyLink];
  }
  if (
    entityType === ElphiEntityType.dealProperty ||
    entityType === AuditEventEntityType.DealProperty
  ) {
    const [dealId, propertyId] = entityId.split("_");
    const dealLink = `/${selectedOrgId}${PathVariables.WorksheetPath}/${dealId}`;
    const propertyLink = `/${selectedOrgId}${PathVariables.PropertyPipeline}?property=${propertyId}`;
    return [dealLink, propertyLink];
  }
  if (entityType === AuditEventEntityType.DealUser) {
    const [dealId, _] = entityId.split("_");
    const dealLink = `/${selectedOrgId}${PathVariables.WorksheetPath}/${dealId}`;
    return [dealLink];
  }
  if (entityType === AuditEventEntityType.PartyAsset) {
    const [_, partyId] = entityId.split("_");
    const partyLink = `/${selectedOrgId}${PathVariables.PartiesPipeline}?party=${partyId}`;
    return [partyLink];
  }
  if (
    entityType === ElphiEntityType.partyRelation ||
    entityType === AuditEventEntityType.PartyRelation
  ) {
    const [parentId, childId] = entityId.split("_");
    const parentLink = `/${selectedOrgId}${PathVariables.PartiesPipeline}?party=${parentId}`;
    const childLink = `/${selectedOrgId}${PathVariables.PartiesPipeline}?party=${childId}`;
    return [parentLink, childLink];
  }
  if (entityType === AuditEventEntityType.PartyUser) {
    const [partyId, _] = entityId.split("_");
    const partyLink = `/${selectedOrgId}${PathVariables.PartiesPipeline}?party=${partyId}`;
    return [partyLink];
  }
  return [];
};
