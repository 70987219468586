import { FieldStatus, ThirdPartyAggregationField } from "@elphi/types";

const nextStatusMap = {
  [FieldStatus.None]: FieldStatus.Approved,
  [FieldStatus.Approved]: FieldStatus.AttentionRequired,
  [FieldStatus.AttentionRequired]: FieldStatus.Denied,
  [FieldStatus.Denied]: FieldStatus.None
};
export const nextFieldStatus = (fieldStatus?: FieldStatus) => {
  if (!fieldStatus) {
    return FieldStatus.Approved;
  }
  return nextStatusMap[fieldStatus];
};

export const isAggregationPath = (fieldPath: string[]) =>
  fieldPath && fieldPath.length > 0
    ? fieldPath[0] === "aggregations" || fieldPath[0] === "quote"
    : false;

export const removeAggregationProp = (fieldPath: string[]) => {
  const aggProps: Array<keyof ThirdPartyAggregationField> = [
    "focused",
    "override",
    "calculated",
    "thirdParty"
  ];
  const aggKeys: string[] = aggProps as string[];
  const lastKey = fieldPath.at(-1);
  if (lastKey) {
    return aggKeys.includes(lastKey) ? [...fieldPath.slice(0, -1)] : fieldPath;
  }
  return fieldPath;
};

export const entityToHeader = {
  deal: "Deal",
  property: "Property",
  party: "Party",
  asset: "Asset",
  statement: "Statement",
  "credit-score": "Credit Score",
  "service-provider": "Service Provider",
  "insurance-policy": "Insurance Policy"
};
export const fieldStatusToColor = {
  [FieldStatus.Approved]: "green.400",
  [FieldStatus.None]: "lightgrey",
  [FieldStatus.Denied]: "red.400",
  [FieldStatus.AttentionRequired]: "yellow.400"
};

export const fieldStatusToFieldColor = {
  [FieldStatus.Approved]: "",
  [FieldStatus.None]: "",
  [FieldStatus.Denied]: "",
  [FieldStatus.AttentionRequired]: ""
};

export const fieldStatusToLetter = {
  [FieldStatus.Approved]: "V",
  [FieldStatus.None]: "P",
  [FieldStatus.Denied]: "D",
  [FieldStatus.AttentionRequired]: "A"
};
