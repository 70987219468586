import { AuditEvent } from "@elphi/types";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewportList from "react-viewport-list";
import { ArrowDown } from "../../../icons";
import { EventRowBuilder } from "../row/EventRowBuilder";
import { EventListSkeleton } from "./EventListSkeleton";
import { EventLoadButton } from "./EventLoadButton";

export const EventList = (props: {
  data: AuditEvent[];
  next: () => void;
  isLoading: boolean;
  hasMore: boolean;
  height: number | string;
  limit: number;
}) => {
  const { data, next, isLoading, hasMore, limit } = props;
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={next}
      hasMore={true}
      height={props.height}
      loader={
        <>
          {isLoading && <EventListSkeleton number={4} />}
          {data.length > limit && (
            <EventLoadButton
              text={"Load More"}
              onClick={next}
              isDisabled={!hasMore}
              icon={<ArrowDown w={6} h={6} mt={3} mr={-4} />}
            />
          )}
        </>
      }
    >
      <ViewportList items={data} withCache={true}>
        {(item, i) => <EventRowBuilder key={i} event={item} />}
      </ViewportList>
    </InfiniteScroll>
  );
};
