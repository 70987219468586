import { createIcon } from "@chakra-ui/icons";
export const PublishIcon = createIcon({
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1379 0.861278C15.3983 1.12163 15.3983 1.54374 15.1379 1.80409L7.80458 9.13742C7.54423 9.39777 7.12212 9.39777 6.86177 9.13742C6.60142 8.87707 6.60142 8.45496 6.86177 8.19461L14.1951 0.861278C14.4554 0.600928 14.8776 0.600928 15.1379 0.861278Z"
        fill="#4A5568"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1379 0.861296C15.319 1.04239 15.3804 1.31121 15.2957 1.55293L10.6291 14.8863C10.5388 15.1443 10.3001 15.321 10.027 15.3321C9.7539 15.3433 9.50165 15.1866 9.39063 14.9368L6.8281 9.1711L1.06242 6.60857C0.812628 6.49756 0.655919 6.24531 0.667061 5.97219C0.678204 5.69907 0.85494 5.46043 1.11294 5.37013L14.4463 0.703462C14.688 0.618858 14.9568 0.680204 15.1379 0.861296ZM3.14056 6.0731L7.60393 8.05683C7.75475 8.12386 7.87535 8.24445 7.94238 8.39527L9.9261 12.8587L13.5799 2.41935L3.14056 6.0731Z"
        fill="black"
      />
    </svg>
  )
});

export const UnpublishIcon = createIcon({
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.0002 4.22693L14.0002 2.00026L11.7735 8.00026L10.7335 6.96026L11.7335 4.26026L9.03354 5.26026L8.0002 4.22693ZM14.1269 14.1269L10.7469 10.7469L9.5402 14.0003H8.6002L6.71354 9.28693L2.0002 7.40026V6.46026L5.25354 5.25359L1.87354 1.87359L2.81354 0.933594L15.0669 13.1869L14.1269 14.1269ZM9.71354 9.71359L6.28687 6.28693L4.4802 6.96026L7.7402 8.26026L9.0402 11.5203L9.71354 9.71359Z"
        fill="black"
      />
    </svg>
  )
});
