import { Badge, Box, Skeleton, SkeletonText, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { EMPTY } from "../../../../constants/common";
import { TaskCard } from "../../../task-cards/task-card/TaskCard";
import {
  ELPHI_TRX_STATUS_COLOR,
  ELPHI_TRX_STATUS_COLOR_TEXT
} from "../../../task/integrations/integration.type";
import { IntegrationTaskColumnProps } from "../integrationCenter.types";

const HEIGHT = "calc(100vh - 400px)";

export const TaskStatusColumn = (props: IntegrationTaskColumnProps) => {
  const {
    elphiTrxStatus,
    setSelected,
    selectedTask,
    taskCards,
    totalCards,
    isLoading
  } = props;
  const isSelected = (taskId: string) => {
    return selectedTask === taskId;
  };

  const cards = taskCards.map((taskCard) => (
    <Box
      key={taskCard.id}
      mb={4}
      pr={4}
      pl={4}
      cursor={"pointer"}
      onClick={() => {
        isSelected(taskCard.id) ? setSelected(EMPTY) : setSelected(taskCard.id);
      }}
    >
      <TaskCard
        key={taskCard.id}
        taskCard={taskCard}
        bgColor={isSelected(taskCard.id) ? "blue.50" : "white"}
      />
    </Box>
  ));

  const skeleton = useMemo(() => {
    return Array(4)
      .fill(0)
      .map((_, i) => {
        return (
          <Skeleton key={i} height="100px" m={4}>
            <SkeletonText />
          </Skeleton>
        );
      });
  }, [props.isLoading]);

  return (
    <Box
      h={HEIGHT}
      display="flex"
      flexDirection="column"
      bg={"gray.100"}
      borderTopRadius={8}
      borderBottomRadius={8}
      boxShadow="md"
    >
      <Box p={4} textAlign={"center"} borderTopRadius={8} bg={"gray.100"}>
        <Badge
          fontSize={"12px"}
          fontWeight={"700"}
          color={ELPHI_TRX_STATUS_COLOR_TEXT[elphiTrxStatus]}
          bgColor={ELPHI_TRX_STATUS_COLOR[elphiTrxStatus]}
        >
          <Text>
            {elphiTrxStatus} {taskCards.length}/{totalCards}
          </Text>
        </Badge>
      </Box>
      <Box flex="1" overflowY="auto">
        {isLoading ? skeleton : cards}
      </Box>
    </Box>
  );
};
