import { DeepPartial, FieldType, PricingVendorType } from "@elphi/types";
import {
  BaseDealFieldsOmit,
  MofinDealFields
} from "@elphi/types/entities/mofin/deal";
import {
  mofinACHStatusType,
  mofinChannelType,
  mofinDealExceptionApprovedBy,
  mofinDealMilestoneType,
  mofinInsuranceMortgageeStatusType,
  mofinInvestorIdentifierType,
  mofinLenderIdentifierType,
  mofinOperationsDepartmentType,
  mofinPaymentDueDayType,
  mofinServicerIdentifierType,
  mofinWarehouseBankStatusTypeType,
  mofinWarehouseBankTypeType
} from "@elphi/types/entities/mofin/deal/mofinDeal.enumerations";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  booleanOptions,
  createOptionsFromMap,
  overrideSelectOptions
} from "../../../../../components/utils/formUtils";
import {
  BaseSchemaDealFieldSpecs,
  baseSchemaDealSpecs
} from "../../../base/spec-files/deal/deal.fields";
import { integrationSpecs } from "../../../integrations/spec-files/integrationFieldsSpecs";
import { BaseSpec } from "../../../types";
import {
  mergeSpecs,
  modifyFromSpec,
  omitFromSpec
} from "../../../utils/formsSpec.utils";
import { mofinMilestonesOptions } from "./milestone.mapping";

type MofinSchemaDealFieldSpecs = EntityFormFieldSpecs<MofinDealFields>;

export type FullMofinSchemaDealFieldSpecs = Partial<BaseSchemaDealFieldSpecs> &
  MofinSchemaDealFieldSpecs;

const mofinDealFieldSpecs: BaseSpec<MofinSchemaDealFieldSpecs> = {
  aggregations: {},
  DealMetadata: {
    milestone: modifyFromSpec({
      fieldSpecs: baseSchemaDealSpecs.DealMetadata.milestone,
      changes: {
        options: overrideSelectOptions(
          mofinDealMilestoneType,
          mofinMilestonesOptions
        )
      }
    })
  },
  ACHStatus: {
    fieldType: FieldType.SingleSelect,
    label: "ACH Status",
    fieldKey: ["ACHStatus"],
    options: createOptionsFromMap(mofinACHStatusType)
  },
  MERSLoanIndicator: {
    fieldType: FieldType.Boolean,
    label: "MERS Loan?",
    fieldKey: ["MERSLoanIndicator"],
    options: booleanOptions
  },
  ReceivedRecordablesIndicator: {
    fieldType: FieldType.Boolean,
    label: "Received Recordables?",
    fieldKey: ["ReceivedRecordablesIndicator"],
    options: booleanOptions
  },
  SentRecordablesToCustodianDate: {
    fieldType: FieldType.Date,
    label: "Sent Recordables to Custodian Date",
    fieldKey: ["SentRecordablesToCustodianDate"],
    options: booleanOptions
  },
  ReceivedFinalTitlePolicyIndicator: {
    fieldType: FieldType.Boolean,
    label: "Received Final Title Policy?",
    fieldKey: ["ReceivedFinalTitlePolicyIndicator"],
    options: booleanOptions
  },
  FinalTitlePolicyToCustodianDate: {
    fieldType: FieldType.Date,
    label: "Sent Final Title Policy to Custodian Date",
    fieldKey: ["FinalTitlePolicyToCustodianDate"]
  },
  InsuranceMortgageeStatus: {
    fieldType: FieldType.SingleSelect,
    label: "Insurance Mortgagee Status",
    fieldKey: ["InsuranceMortgageeStatus"],
    options: createOptionsFromMap(mofinInsuranceMortgageeStatusType)
  },
  InsuranceMortgageeRequestDate: {
    fieldType: FieldType.Date,
    label: "Insurance Mortgagee Requested Date",
    fieldKey: ["InsuranceMortgageeRequestDate"]
  },
  InsuranceMortgageeRequestFollowUpDate: {
    fieldType: FieldType.Date,
    label: "Insurance Mortgagee Requested - Last Follow-Up Date",
    fieldKey: ["InsuranceMortgageeRequestFollowUpDate"]
  },
  PayoffPerDiemInterest: {
    fieldType: FieldType.Money,
    label: "Payoff Per Diem Interest",
    fieldKey: ["PayoffPerDiemInterest"]
  },
  ServicerIdentifier: {
    fieldType: FieldType.SingleSelect,
    label: "Servicer Name",
    fieldKey: ["ServicerIdentifier"],
    options: createOptionsFromMap(mofinServicerIdentifierType)
  },
  InvestorIdentifier: {
    fieldType: FieldType.SingleSelect,
    label: "Investor Name",
    fieldKey: ["InvestorIdentifier"],
    options: createOptionsFromMap(mofinInvestorIdentifierType)
  },
  LenderIdentifier: {
    fieldType: FieldType.SingleSelect,
    label: "Lender Company Name",
    fieldKey: ["LenderIdentifier"],
    options: createOptionsFromMap(mofinLenderIdentifierType)
  },
  OperationsDepartment: {
    fieldType: FieldType.SingleSelect,
    label: "Branch",
    fieldKey: ["OperationsDepartment"],
    options: createOptionsFromMap(mofinOperationsDepartmentType)
  },
  PaymentDueDay: {
    fieldType: FieldType.SingleSelect,
    label: "Payment Due Day",
    fieldKey: ["PaymentDueDay"],
    options: createOptionsFromMap(mofinPaymentDueDayType)
  },
  WarehouseBankType: {
    fieldType: FieldType.SingleSelect,
    label: "Warehouse Bank",
    fieldKey: ["WarehouseBankType"],
    options: createOptionsFromMap(mofinWarehouseBankTypeType)
  },
  WarehouseBankStatusType: {
    fieldType: FieldType.SingleSelect,
    label: "Warehouse Status",
    fieldKey: ["WarehouseBankStatusType"],
    options: createOptionsFromMap(mofinWarehouseBankStatusTypeType)
  },
  Channel: {
    fieldType: FieldType.SingleSelect,
    label: "Channel",
    fieldKey: ["Channel"],
    options: createOptionsFromMap(mofinChannelType)
  }
};

const overriddenBaseSchemaDealSpecs: DeepPartial<typeof baseSchemaDealSpecs> = {
  Exceptions: {
    ExceptionApprovedBy: modifyFromSpec({
      fieldSpecs: baseSchemaDealSpecs.Exceptions.ExceptionApprovedBy,
      changes: {
        options: createOptionsFromMap(mofinDealExceptionApprovedBy)
      }
    })
  }
};

const pathsToOmitFromBase: BaseDealFieldsOmit = [
  "DealMetadata.milestone",
  "DownPaymentAmount",
  "TitleCommitment.TitleInternationalExecutionIndicator",
  "SellerHUDAddedIndicator",
  "PurchaserWarrantyDeedAddedIndicator",
  "UCCFiledIndicator",
  "UCCExpirationDate",
  "Ledger.NewYorkProcessingFeeAmount",
  "ServicerIdentifier",
  "InvestorIdentifier",
  "LenderIdentifier",
  "OperationsDepartment",
  "PaymentDueDay",
  "WarehouseBankType",
  "WarehouseBankStatusType",
  "Channel"
];

const mofinDealSpecs: FullMofinSchemaDealFieldSpecs = mergeSpecs(
  omitFromSpec({
    obj: baseSchemaDealSpecs,
    paths: pathsToOmitFromBase
  }),
  overriddenBaseSchemaDealSpecs,
  mofinDealFieldSpecs,
  integrationSpecs.deal.WireInsuranceCertificate.FundingShield.spec,
  integrationSpecs.deal.Pricing[PricingVendorType.Loanpass].spec
);

export const fullMofinDealSpecs = createSpecWithFieldMeta({
  spec: mofinDealSpecs
});
