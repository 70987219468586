import { FeatureFlagsFallback } from ".";

export enum BusinessFeatureFlag {
  Platform_Management = "platform_management",
  Update_Quote = "update_quote",
  Okta_SSO = "okta_sso",
  Box_Metadata = "box_metadata",
  Box_Exit_State_Folder_Structure = "box_exit_state_folder_structure",
  Integrations_Center = "integrations_center",
  Closing_Date_Filter = "closing_date_filter",
  UserSnap = "usersnap"
}

export const businessFeatureFlagFallback: FeatureFlagsFallback<BusinessFeatureFlag> =
  {
    [BusinessFeatureFlag.Platform_Management]: false,
    [BusinessFeatureFlag.Update_Quote]: false,
    [BusinessFeatureFlag.Okta_SSO]: false,
    [BusinessFeatureFlag.Box_Metadata]: true,
    [BusinessFeatureFlag.Box_Exit_State_Folder_Structure]: true,
    [BusinessFeatureFlag.Integrations_Center]: false,
    [BusinessFeatureFlag.Closing_Date_Filter]: false,
    [BusinessFeatureFlag.UserSnap]: false
  };
