import {
  Box,
  BoxProps,
  Button,
  Flex,
  Progress,
  Skeleton,
  SkeletonText,
  Stack
} from "@chakra-ui/react";
import { JSX } from "@emotion/react/jsx-runtime";
import { useEffect, useId, useMemo, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewportList from "react-viewport-list";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useTableManagerHook } from "../../hooks/tableManager.hooks";
import { OrderableTableBar } from "../orderable-table/OrderableTableBar";
import {
  ElphiCell as ElphiHeaderCell,
  OrderableTableProps
} from "../table/table.types";
import { ElphiListHeader } from "./ElphiListHeader";

type OrderableRowBuilder<T> = (
  props: {
    item: T;
    index: string | number;
  } & OrderableTableProps
) => JSX.Element;

export type ElphiPaginationListProps<T> = {
  items: T[];
  header?: JSX.Element;
  headerCells?: ElphiHeaderCell[];
  footer?: JSX.Element;
  footerBuilder?: ({
    tableName,
    isOrderable
  }: OrderableTableProps) => JSX.Element;
  rowBuilder?: (d: T, index: string | number) => JSX.Element;
  orderableRowBuilder?: OrderableRowBuilder<T>;
  pageSize: number;
  w?: BoxProps["w"];
  isLoading?: boolean;
  maxHeight?: string | number;
  minHeight?: string | number;
  options?: Partial<{
    showWatching: boolean;
    showEndMessage: boolean;
    showLoader: boolean;
    showFetchMore: boolean;
  }>;
  skeletonRows?: number;
};
export const ElphiPaginationList = <T,>(
  props: ElphiPaginationListProps<T> &
    Pick<
      React.ComponentProps<typeof InfiniteScroll>,
      "hasMore" | "next" | "height"
    > & {
      rowStyle?: BoxProps;
      headerStyle?: BoxProps;
      containerStyle?: BoxProps;
    } & OrderableTableProps
) => {
  const { options } = props;
  const {
    showWatching = true,
    showEndMessage = true,
    showLoader = true,
    showFetchMore = true
  } = options || {};
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<InfiniteScroll>(null);
  const scrollableTargetId = useId();
  const { setTableColumns, getTableState } = useTableManagerHook();

  const tableState = getTableState(props.tableName);
  const isOrderable = props.isOrderable;

  useEffect(() => {
    if (isOrderable && !tableState && props.headerCells) {
      setTableColumns({
        tableName: props.tableName,
        headerCells: props.headerCells
      });
    }
  }, []);

  return (
    <Box bgColor="white" w={props.w || "100%"} ref={containerRef}>
      <Stack direction="row" spacing={4}>
        {showWatching && <Box>watching {props.items.length} items</Box>}
        {isOrderable && <OrderableTableBar tableName={props.tableName} />}
      </Stack>
      <Box
        id={scrollableTargetId}
        maxH={props.maxHeight}
        minH={props.minHeight}
        overflow={"auto"}
      >
        <InfiniteScroll
          ref={scrollRef}
          height={props.height}
          style={{ minHeight: props.minHeight, maxHeight: props.maxHeight }}
          dataLength={props.items.length} //This is important field to render the next data
          next={props.next}
          hasMore={props.hasMore && !props.isLoading}
          scrollableTarget={scrollableTargetId}
          loader={
            <>
              {showLoader && (
                <Box>
                  {props.isLoading && !props.skeletonRows && (
                    <Progress size="xs" isIndeterminate />
                  )}
                  {props.isLoading && props.skeletonRows && (
                    <ElphiSkeleton number={props.skeletonRows} />
                  )}
                  {!props.isLoading && props.hasMore && showFetchMore && (
                    <Flex justifyContent={"space-between"} w="100%">
                      <Button
                        size="sm"
                        isLoading={props.isLoading}
                        onClick={props.next}
                        w="300px"
                        {...elphiTheme.components.light.button.primary}
                      >
                        Fetch More
                      </Button>
                      <Button
                        size="sm"
                        isLoading={props.isLoading}
                        onClick={props.next}
                        w="300px"
                        {...elphiTheme.components.light.button.primary}
                      >
                        Fetch More
                      </Button>
                    </Flex>
                  )}
                </Box>
              )}
            </>
          }
          endMessage={
            <Box>
              {showEndMessage && props.hasMore ? "no more items" : ""}
              {showLoader && props.isLoading ? (
                <>
                  {props.isLoading && !props.skeletonRows && (
                    <Progress size="xs" isIndeterminate />
                  )}
                  {props.isLoading && props.skeletonRows && (
                    <ElphiSkeleton number={props.skeletonRows} />
                  )}
                </>
              ) : showEndMessage ? (
                "done"
              ) : (
                ""
              )}
            </Box>
          }
        >
          <Box w="100%">
            <Box
              p="10px"
              position={"sticky"}
              top={0}
              zIndex={3}
              bgColor="white"
              w="100%"
              {...props?.headerStyle}
            >
              {props.header}
              {props.headerCells && (
                <ElphiListHeader
                  tableName={props.tableName}
                  headerCells={props.headerCells}
                  isOrderable={isOrderable}
                />
              )}
            </Box>
            <Box w="100%" {...props?.containerStyle}>
              <ViewportList
                items={props.items}
                viewportRef={containerRef}
                withCache
              >
                {(item, i) => (
                  <Box
                    key={i}
                    p="10px"
                    borderBottomColor={"gray.100"}
                    borderWidth="1px"
                    w="100%"
                    {...props?.rowStyle}
                  >
                    {props.orderableRowBuilder?.({
                      item,
                      index: i,
                      tableName: props.tableName,
                      isOrderable
                    })}
                    {props.rowBuilder?.(item, i)}
                  </Box>
                )}
              </ViewportList>
            </Box>
          </Box>
        </InfiniteScroll>
        {props.footerBuilder && (
          <Box>
            {props.footerBuilder({
              tableName: props.tableName,
              isOrderable
            })}
          </Box>
        )}
        {props.footer && <Box>{props.footer}</Box>}
      </Box>
    </Box>
  );
};

const ElphiSkeleton = (props: { number: Number }) => {
  const skeleton = useMemo(() => {
    return Array(props.number)
      .fill(0)
      .map((_, i) => {
        return (
          <Skeleton key={i} width={"100%"} height={"100px"} m={4}>
            <SkeletonText />
          </Skeleton>
        );
      });
  }, []);
  return <>{skeleton}</>;
};
