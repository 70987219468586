import { useCallbackRef } from "@chakra-ui/react";
import {
  EntityParty,
  IndividualParty,
  Party,
  PartyRelation,
  PartyType
} from "@elphi/types";
import { ArgumentTypes } from "@elphi/types/utils/arguments";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { RootState } from "../redux/store";
import { partyApi } from "../redux/v2/party/party.service";
import {
  PartyTableCursorType,
  partySlice
} from "../redux/v2/party/party.slice";
import { getListFromDictionary } from "../utils/batchUtils";
import { removeNulls } from "../utils/filter.utils";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";

type PartyStructureState = {
  parties: { [id: string]: Party };
};
export const usePartyHooks = () => {
  const dispatcher = useDispatch();
  const [searchParty, searchResponse] = partyApi.useLazySearchQuery();
  const [addChildApi, addChildApiResponse] = partyApi.useAddChildMutation();
  const [getChildrenApi] = partyApi.useLazyChildrenQuery();
  const [getTreeApi, getTreeApiResponse] = partyApi.useLazyPartyTreeQuery();
  const [deletePartyApi] = partyApi.useDeleteMutation();
  const [getPartyApi] = partyApi.useLazyGetQuery();
  const [updatePartyApi] = partyApi.useUpdateMutation();
  const [updateBatch, updateBatchApiResponse] =
    partyApi.useUpdateBatchMutation();
  const [createPartyApi, createPartyApiResponse] = partyApi.useCreateMutation();
  const [getPartyAssetBatchApi, getPartyAssetBatchResponse] =
    partyApi.useLazyGetBatchPartyAssetQuery();

  const [getPartyBatchApi, getPartyBatchResponse] =
    partyApi.useLazyGetPartyBatchQuery();

  const setSelectedParty = (id: string) =>
    dispatcher(partySlice.actions.selectedId({ id }));

  const setSelectedPartyParent = (id: string) =>
    dispatcher(partySlice.actions.selectedPartyParent({ id }));

  const setPartyTableCursor = (
    r: ArgumentTypes<typeof partySlice.actions.setPartyTableCursor>[0]
  ) => dispatcher(partySlice.actions.setPartyTableCursor(r));
  const getPartyTableCursor = (r: {
    cursorType: PartyTableCursorType;
    partyId?: string;
    userId?: string;
  }) => {
    const { cursorType, partyId, userId } = r;
    if (cursorType === PartyTableCursorType.ALL)
      return partyState.partyTable?.cursor.all;
    else if (userId && cursorType === PartyTableCursorType.USER_FILTER)
      return partyState.partyTable?.cursor.userFilter[userId];
    else if (partyId && cursorType === PartyTableCursorType.PARTY_FILTER)
      return partyState.partyTable?.cursor.partyFilter[partyId];
    else if (cursorType === PartyTableCursorType.PARTY_USER_FILTER)
      return partyState.partyTable?.cursor.partyUserFilter[
        `${partyId}_${userId}`
      ];
    else throw `case ${r} not supported`;
  };

  const setHighlightedParty = (id: string) =>
    dispatcher(partySlice.actions.highlightedParty({ id }));

  const setSelectedTreeNodeKey = (id: string) =>
    dispatcher(partySlice.actions.selectedTreeNodeKey({ id }));

  const setHighlightedPartyTabParty = (id: string) =>
    dispatcher(partySlice.actions.highlightedPartyTabParty({ id }));
  const setHighlightedPartyTabPartyParent = (id: string) =>
    dispatcher(partySlice.actions.highlightedPartyTabPartyParent({ id }));
  const resetPartySelections = () =>
    dispatcher(partySlice.actions.resetSelections());

  const updateBatchParties = async (newParties: {
    parties: ({ id: string } & Partial<PartyStructureState>)[];
  }) => {
    return await updateBatch(newParties).then(responseHandler);
  };

  const updatePartiesHandler = async (
    partiesMap:
      | {
          [id: string]: {
            id: string;
          } & Partial<Party>;
        }
      | undefined
  ) => {
    if (!partiesMap) return;
    const parties = getListFromDictionary(partiesMap);
    if (!parties.length) return;
    return await updateBatchParties({ parties } as {
      parties: ({
        id: string;
      } & Partial<Party>)[];
    });
  };
  const partyState = useSelector((state: RootState) => state.party);
  const selectedParty = useSelector(
    (state: RootState) =>
      state.party.selectedId && state.party.entities[state.party.selectedId]
  );
  const selectedParent = useSelector(
    (state: RootState) =>
      state.party.selectedParentId &&
      state.party.entities[state.party.selectedParentId]
  );
  const highlightedParty = useSelector(
    (state: RootState) =>
      state.party.highlightedPartyId &&
      state.party.entities[state.party.highlightedPartyId]
  );
  const partyTabHighlightedParty = useSelector((state: RootState) =>
    state.party.partyTab?.highlightedPartyId
      ? state.party.entities[state.party.partyTab.highlightedPartyId]
      : undefined
  );
  const partyTabHighlightedPartyParent = useSelector((state: RootState) =>
    state.party.partyTab?.highlightedPartyParentId
      ? state.party.entities[state.party.partyTab.highlightedPartyParentId]
      : undefined
  );
  const partyTab = useSelector((state: RootState) => {
    return state.party.partyTab;
  });
  const selectedTreeNodeKey = useSelector(
    (state: RootState) => state.party.selectedTreeNodeKey
  );

  const selectedPartyTabRelationId = useSelector((state: RootState) => {
    const parentId = state.party.partyTab?.highlightedPartyParentId;
    const partyId = state.party.partyTab?.highlightedPartyId;
    if (parentId && partyId) {
      return `${parentId}_${partyId}`;
    } else return undefined;
  });
  const selectedPartyTabRelation = useSelector((state: RootState) => {
    if (
      state.party.partyTab?.highlightedPartyParentId &&
      state.party.partyTab?.highlightedPartyId
    ) {
      return state.partyRelation.entities[
        state.party.partyTab.highlightedPartyParentId +
          "_" +
          (state.party?.partyTab?.highlightedPartyId ?? "")
      ];
    } else return undefined;
  });

  const partyTreeIds = useCallback(
    (state: EntityState<PartyRelation>, ids: string[] | EntityId[]) => {
      const partiesIdTracker: { [key: string]: boolean } = {};
      const que: (string | EntityId)[] = ids;
      while (que.length !== 0) {
        const currentId = que.pop();
        if (currentId && !(currentId in partiesIdTracker)) {
          partiesIdTracker[currentId] = true;
          const children = state.ids
            .filter(
              (id: string) => state?.entities?.[id]?.parentId === currentId
            )
            .map((id) => state?.entities[id]?.childId);
          children.forEach((c) => c && que.push(c));
        }
      }
      return Object.keys(partiesIdTracker);
    },
    []
  );
  const entityDataRank: FieldsWeight<EntityParty> = {
    FullName: 50,
    TaxpayerIdentifierValue: 50
  };
  const individualDataRank: FieldsWeight<IndividualParty> = {
    FirstName: 50,
    MiddleName: 10,
    LastName: 20,
    TaxpayerIdentifierValue: 70
  };
  const dataRank = (p: Party) => {
    if (p.PartyType === PartyType.Individual) return individualDataRank;
    return entityDataRank;
  };
  const rankedSort = (query: string) => {
    return Object.values(partyState.entities).sort((a, b) => {
      if (!a) return 1;

      if (!b) return -1;

      const rankA = getRankedData(query, a, dataRank(a), {
        sortedByKeywords: true
      });
      const rankB = getRankedData(query, b, dataRank(b), {
        sortedByKeywords: true
      });

      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const deleteParty = async (r: string) => {
    const response = await deletePartyApi(r).then(responseHandler);
    if (response.status === 200) {
      dispatcher(partySlice.actions.remove(r));
    }
    return response;
  };
  const getParty = async (r: string) => {
    const response = await getPartyApi(r, true);
    return responseHandler(response as RTKResponse<typeof response.data>);
  };
  const getPartyBatch = async (r: string[]) => {
    const response = await getPartyBatchApi(r, true);
    return responseHandler(response as RTKResponse<typeof response.data>);
  };

  const getChildren = async (ids: string[]) => {
    const response = await getChildrenApi(ids, true);
    return responseHandler(response as RTKResponse<typeof response.data>);
  };
  const addChild = async (r: {
    parentId: string;
    child: { id: string } | Party;
  }) => {
    return await addChildApi(r).then(responseHandler);
  };
  const createParty = async (newParty: Party) => {
    const response = await createPartyApi(newParty).then(responseHandler);
    if (response.status === 200) {
      setSelectedParty(response.data.id);
    }
    return response;
  };

  const updateParty = async (updateParty: { id: string } & Partial<Party>) => {
    return await updatePartyApi(updateParty).then(responseHandler);
  };

  const getPartyAssetBatch = async (partyIds: string[]) => {
    const response = await getPartyAssetBatchApi({ partyIds }, true);
    return responseHandler(response as RTKResponse<typeof response.data>);
  };

  const partyRelationCallbackHandler = useCallbackRef(
    (r: Partial<PartyRelation>[]) => {
      const partiesId = r.map((d) => d.childId);
      const missingIds = partiesId
        .filter(removeNulls)
        .filter((p) => !partyState.ids.includes(p));
      missingIds.length && getPartyBatch(missingIds);
      getTreeApi(r.map((d) => d && d?.childId).filter(removeNulls));
    },
    [partyState]
  );
// Todo: check if this is the correct return statement
  const selectedOrHighlightedParty = selectedParty
  ? highlightedParty
    ? highlightedParty
    : selectedParty
  : partyTabHighlightedParty;
  
  return {
    dataRank,
    getRankedData,
    partyRelationCallbackHandler,
    partyTab,
    selectedPartyTabRelationId,
    partyTreeIds,
    selectedPartyTabRelation,
    selectedParent,
    highlightedParty,
    selectedTreeNodeKey,
    setSelectedTreeNodeKey,
    setHighlightedParty,
    setSelectedPartyParent,
    setHighlightedPartyTabParty,
    setHighlightedPartyTabPartyParent,
    getChildren,
    getParty,
    createParty,
    createPartyApiResponse,
    updateParty,
    setSelectedParty,
    searchParty,
    searchResponse,
    rankedSort,
    selectedParty,
    partyTabHighlightedParty,
    partyTabHighlightedPartyParent,
    partyState,
    addChild,
    addChildApiResponse,
    deleteParty,
    getTreeApi,
    getTreeApiResponse,
    getPartyBatch,
    getPartyBatchResponse,
    updateBatchParties,
    updateBatchApiResponse,
    updatePartiesHandler,
    getPartyAssetBatch,
    getPartyAssetBatchResponse,
    setPartyTableCursor,
    getPartyTableCursor,
    selectedOrHighlightedParty,
    resetPartySelections
  };
};
