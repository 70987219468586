import { NotificationConfiguration } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { notificationConfigurationSlice } from "./notificationConfiguration.slice";

export const notificationConfigurationAction: ActionDispatcher<
  NotificationConfiguration
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(notificationConfigurationSlice);
  return actionDispatcher(dispatch, callback);
};
