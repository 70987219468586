import { BaseEntity } from "../../db";

export enum PosPublishStatus {
  Published = "published",
  Unpublished = "unpublished"
}

export type PosDealUserParty = BaseEntity<{
  dealId: string;
  partyIds: string[];
  userId: string;
  status?: PosPublishStatus;
}>;
