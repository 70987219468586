import { useDisclosure } from "@chakra-ui/react";
import {
  InsurancePolicyCoverages,
  InsurancePolicyEntityType,
  InsurancePolicyView
} from "@elphi/types";
import { useInsurancePolicyHooks } from "../../hooks/insurance-policy/insurancePolicy.hooks";
import { useRolodexConfiguration } from "../../hooks/rolodexConfiguration.hooks";

export const useInsurancePolicyModalsHooks = () => {
  const { getDomainConfiguration, setSelectedConfiguration } =
    useRolodexConfiguration();
  const {
    selectPolicy,
    selectedInsurancePolicy,
    getInsurancePolicyHandler,
    deleteEntityFromInsurancePolicy,
    deleteDealFromInsurancePolicy,
    deleteHandler
  } = useInsurancePolicyHooks();
  const {
    isOpen: isInsurancePolicyEditModalOpen,
    onToggle: onToggleInsurancePolicyEditModal
  } = useDisclosure();

  const {
    isOpen: isInsurancePolicyRemoveModalOpen,
    onToggle: onToggleInsurancePolicyRemoveModal
  } = useDisclosure();

  const {
    isOpen: isInsurancePolicyDeleteModalOpen,
    onToggle: onToggleInsurancePolicyDeleteModal
  } = useDisclosure();

  const {
    isOpen: isInsurancePolicyCreateModalOpen,
    onToggle: onToggleInsuranceCreatePolicyModal
  } = useDisclosure();

  const onEdit = async (
    view: InsurancePolicyView<InsurancePolicyCoverages>
  ) => {
    await Promise.all([
      getDomainConfiguration(view.domainConfiguration.id),
      getInsurancePolicyHandler({
        id: view.policy.id,
        entityType: view.entityType
      })
    ]);
    selectPolicy({ id: view.policy.id });
    setSelectedConfiguration(view.domainConfiguration.id);
    onToggleInsurancePolicyEditModal();
  };

  const onInsuredEntityRemove = async (r: {
    entityId: string;
    entityType?: InsurancePolicyEntityType;
  }) => {
    const { entityType, entityId } = r;
    await deleteEntityFromInsurancePolicy({
      entityType,
      entityId,
      policyId: selectedInsurancePolicy?.id
    });
  };

  const onInsuredDealRemove = async (r: { dealId: string }) => {
    const { dealId } = r;
    await deleteDealFromInsurancePolicy({
      dealId,
      policyId: selectedInsurancePolicy?.id
    });
  };

  const onInsurancePolicyDelete = async () => {
    await deleteHandler({
      policyId: selectedInsurancePolicy?.id
    });
  };

  const handleInsurancePolicyDeleteModalToggle = (
    view?: InsurancePolicyView<InsurancePolicyCoverages>
  ) => {
    const id = !isInsurancePolicyDeleteModalOpen ? view?.policy?.id : undefined;
    selectPolicy({ id });
    onToggleInsurancePolicyDeleteModal();
  };

  const handleInsurancePolicyRemoveModalToggle = (
    view?: InsurancePolicyView<InsurancePolicyCoverages>
  ) => {
    const id = !isInsurancePolicyRemoveModalOpen ? view?.policy?.id : undefined;
    selectPolicy({ id });
    onToggleInsurancePolicyRemoveModal();
  };

  return {
    onEdit,
    onInsuredEntityRemove,
    onInsuredDealRemove,
    onInsurancePolicyDelete,
    isInsurancePolicyEditModalOpen,
    onToggleInsurancePolicyEditModal,
    isInsurancePolicyCreateModalOpen,
    onToggleInsuranceCreatePolicyModal,
    isInsurancePolicyRemoveModalOpen,
    handleInsurancePolicyRemoveModalToggle,
    isInsurancePolicyDeleteModalOpen,
    handleInsurancePolicyDeleteModalToggle,
    selectPolicy
  };
};
