import { elphiDate } from "@elphi/utils";
import { isEmpty } from "lodash";

export const stringValidation = (s: string, isRequired: boolean = false) =>
  isRequired ? !isEmpty(s) : true;

export const stringLengthValidation =
  (maxlength: number) =>
  (s: string, isRequired: boolean = false) =>
    s ? stringValidation(s, isRequired) && s?.length <= maxlength : true;

export const singleSelectValidation = (
  s: string,
  isRequired: boolean = false
) => stringValidation(s, isRequired);

export const multiSelectValidation = <T>(
  array?: T[] | string,
  isRequired: boolean = false
) => {
  return isRequired ? !!(array && array.length > 0) : true;
};

export const booleanValidation = (
  b: boolean | string,
  isRequired: boolean = false
) => stringValidation(String(b), isRequired);

export const moneyValidation = (m: string, isRequired: boolean = false) => {
  if (isEmpty(m)) return !isRequired;
  return isGreaterThan(m, 0);
};

export const numberValidation = (
  n: string,
  isRequired: boolean = false
): boolean => {
  if (isEmpty(n)) return !isRequired;
  return isGreaterThan(n, 0);
};

export const digitsCountNumberValidation =
  (d: number) =>
  (n: string, isRequired: boolean = false) => {
    if (isEmpty(n)) return !isRequired;
    return n.length === d && isGreaterThan(n, 0);
  };

// Upper and lower bound are inclusive
export const lengthInRangeValidation =
  (lowerBound: number, upperBound: number) =>
  (n: string, isRequired: boolean = false) => {
    if (isEmpty(n)) return !isRequired;
    return isInRange(String(n.length), lowerBound, upperBound);
  };

export const percentValidation = (p: string, isRequired: boolean = false) => {
  if (isEmpty(p)) return !isRequired;
  return isInRange(p, 0, 100);
};
export const percentWithoutMaxValidation = (
  p: string,
  isRequired: boolean = false
) => {
  if (isEmpty(p)) return !isRequired;
  return isInRange(p, 0, Number.MAX_SAFE_INTEGER);
};

export const dateValidation = (date: string, isRequired: boolean = false) =>
  isRequired
    ? elphiDate(date, { format: "YYYY-MM-DD", strict: true }).isValid()
    : isEmpty(date) ||
      elphiDate(date, { format: "YYYY-MM-DD", strict: true }).isValid();

export const emailValidation = (
  email: string,
  isRequired: boolean = false,
  acceptUnicode?: boolean
) => {
  if (isEmpty(email)) return !isRequired;
  const noUnicode =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const withUnicode =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return acceptUnicode ? noUnicode.test(email) : withUnicode.test(email);
};

export const areAllValuesInOptionsValidation = (
  values?: string[],
  options?: { label: string; value: string }[],
  isRequired: boolean = false
) => {
  if (!isRequired && isEmptyValuesArray(values)) {
    return true;
  }
  return values?.every((str) =>
    options?.some((option) => option.value === str)
  );
};

export const isAnyValuesInOptionsValidation = (
  values?: string[],
  options?: { label: string; value: string }[],
  isRequired: boolean = false
) => {
  if (!isRequired && isEmptyValuesArray(values)) {
    return true;
  }
  return values?.some((str) => options?.some((option) => option.value === str));
};

export const isEmptyValuesArray = (values?: string[]) =>
  values?.filter(isEmpty).length == values?.length;

export const isGreaterThan = (value: string, limit: number) => {
  const num = Number(value);
  return !isNaN(num) && num >= limit;
};

export const isInRange = (value: string, from: number, to: number) => {
  const num = Number(value);
  return !isNaN(num) && num >= from && num <= to;
};

export const ssnValidation = (ssn: string, isRequired: boolean = false) => {
  if (isEmpty(ssn)) return !isRequired;
  const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
  return ssnRegex.test(ssn);
};
export const einValidation = (ein: string, isRequired: boolean = false) => {
  if (isEmpty(ein)) return !isRequired;
  const einRegex = /^\d{2}-\d{7}$/;
  return einRegex.test(ein);
};
