// Need to use the React-specific entry point to allow generating React hooks
import { LOSUser } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";

// Define a service using a base URL and expected endpoints
export const baseAdminApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "adminApi",
  tagTypes: ["Admin", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "admin"
  }),
  endpoints: (builder) => ({
    addUsers: builder.mutation<
      { users: string[] },
      {
        users: Pick<
          LOSUser,
          "firstName" | "middleName" | "lastName" | "email" | "roles"
        >[];
      }
    >({
      query: (body) => {
        return {
          url: `/add-users`,
          method: "POST",
          body
        };
      }
    }),
    setUsersStatus: builder.mutation<
      { results: string[] },
      {
        ids: string[];
        status: LOSUser["status"];
      }
    >({
      query: (body) => {
        return {
          url: `/set-users-status`,
          method: "POST",
          body
        };
      }
    }),
    activateUsers: builder.mutation<
      { results: string[] },
      {
        ids: string[];
      }
    >({
      query: (body) => {
        return {
          url: `/activate-users`,
          method: "POST",
          body
        };
      }
    }),
    disableUsers: builder.mutation<
      { results: string[] },
      {
        ids: string[];
      }
    >({
      query: (body) => {
        return {
          url: `/disable-users`,
          method: "POST",
          body
        };
      }
    })
  })
});

export const adminApi = baseAdminApi;
