import { PropertyInsurancePolicyView } from "@elphi/types";
import { CardMinimizedInfo } from "../../../common-components/card";
import { NOT_AVAILABLE } from "../../../constants/common";
import { ShieldPurpleIcon } from "../../icons";

export const InsurancePolicyMinimizedView = (props: {
  providers: PropertyInsurancePolicyView["providers"];
}) => {
  const { providers } = props;
  return (
    <CardMinimizedInfo
      icon={<ShieldPurpleIcon w={6} h={6} />}
      dataPreview={[
        {
          text: providers.company.name || NOT_AVAILABLE
        },
        {
          text: providers.branch.name || NOT_AVAILABLE
        },
        {
          text: providers.rep.name || NOT_AVAILABLE,
          color: providers.rep.repSkipped ? "gray.400" : "gray.600"
        }
      ]}
    />
  );
};
