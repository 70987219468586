import { Type } from "@sinclair/typebox";
import { ElphiEntityType } from "../../../entities";

// Request Type Schema
const RequestTypeSchema = Type.Object({
  requestType: Type.Union([
    Type.Literal("float-request"),
    Type.Literal("lock-ledger-updated")
  ])
});

// Base Fields Mapping
const BaseLoanpassFieldsMappingSchema = Type.Object({
  elphiField: Type.String(),
  loanpassField: Type.String()
});

// Field Type Definitions
const FieldTypeSchema = Type.Union([
  Type.Literal("string"),
  Type.Literal("number"),
  Type.Literal("duration")
]);

const LoanpassFieldSchema = Type.Object({
  fieldType: FieldTypeSchema
});

const EnumFieldSchema = Type.Object({
  fieldType: Type.Literal("enum"),
  optionMaps: Type.Record(Type.String(), Type.String())
});

// Non-Enum and Enum Field Mappings
const NonEnumFieldSchema = Type.Intersect([
  BaseLoanpassFieldsMappingSchema,
  LoanpassFieldSchema
]);

const EnumFieldMappingSchema = Type.Intersect([
  BaseLoanpassFieldsMappingSchema,
  EnumFieldSchema
]);

const ArrayFieldSchema = Type.Object({
  fieldType: Type.Literal("array"),
  pickMethod: Type.Optional(
    Type.Union([
      Type.Literal("all"),
      Type.Literal("first"),
      Type.Literal("last"),
      Type.Literal("select")
    ])
  ),
  nestedField: Type.Object({
    fieldId: Type.Optional(Type.String()),
    path: Type.String()
  })
});

const ArrayFieldMappingSchema = Type.Intersect([
  BaseLoanpassFieldsMappingSchema,
  ArrayFieldSchema
]);

// Loanpass to Elphi Mapping Type
const LoanpassToElphiMappingTypeSchema = Type.Union([
  Type.Intersect([NonEnumFieldSchema, RequestTypeSchema]),
  Type.Intersect([EnumFieldMappingSchema, RequestTypeSchema]),
  Type.Intersect([ArrayFieldMappingSchema, RequestTypeSchema])
]);

// Entity Type Schema
const EntityTypeSchema = Type.Object({
  entityType: Type.Enum(ElphiEntityType)
});

// OutLoanpass Entity Mapping
const NonEnumFieldWithEntityTypeSchema = Type.Intersect([
  NonEnumFieldSchema,
  EntityTypeSchema
]);

const EnumFieldWithEntityTypeSchema = Type.Intersect([
  Type.Intersect([
    EnumFieldMappingSchema,
    Type.Object({ enumTypeId: Type.String() })
  ]),
  EntityTypeSchema
]);

const OutLoanpassEntityMappingRequestSchema = Type.Union([
  NonEnumFieldWithEntityTypeSchema,
  EnumFieldWithEntityTypeSchema
]);

// Mappings Schema
const MappingsSchema = Type.Partial(
  Type.Object({
    out: Type.Array(OutLoanpassEntityMappingRequestSchema),
    in: Type.Array(LoanpassToElphiMappingTypeSchema)
  })
);

// Loanpass Upsert Mappings Request
export const LoanpassUpsertMappingsRequestSchema = Type.Object({
  mappings: MappingsSchema
});
