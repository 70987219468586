import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { BusinessFeatureFlag } from "@elphi/utils";
import { GateKeeper } from "../../../features/gate/GateKeeper";
import TaskPage from "../../task/TaskPage";
import { IntegrationCenter } from "../integrations-center/IntegrationCenter";

export const CheckListTabs = () => {
  return (
    <Box h="100%" w="100%" overflow="hidden" p="0">
      <Tabs
        h="100%"
        w="100%"
        position="sticky"
        top="0"
        isLazy={true}
        lazyBehavior="unmount"
        pl="10px"
      >
        <TabList>
          <Tab>Checklist</Tab>
          <GateKeeper gate={BusinessFeatureFlag.Integrations_Center}>
            <Tab>Integrations Center</Tab>
          </GateKeeper>
        </TabList>
        <TabPanels h="100%" w="100%" p="0" pt="10px">
          <TabPanel h="100%" w="100%" p="0">
            <TaskPage />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <IntegrationCenter />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
