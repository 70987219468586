import { Property } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import {
  parcelNumber,
  propertyAddressLineText,
  propertyAddressUnitIdentifier,
  propertyBlockIdentifier,
  propertyCityName,
  propertyLotIdentifier,
  propertyPostalCode,
  propertyStateCode,
  propertySubdivisionIdentifier
} from "../../../base/inputs/property.inputs";
import { buildSection } from "../../../utils/formBuilder.utils";
import {
  NFIPLegalCommunityName,
  NFIPLegalCommunityNumber,
  NFIPMapCommunityName,
  NFIPMapCommunityNumber,
  NFIPMapPanelNumber,
  TSDNComments,
  determinationDate,
  floodCheckStatus,
  floodMapDate,
  floodZone,
  orderNumber,
  orderStatus,
  propertyMSAMD,
  requestedInformation,
  resultDescription,
  returnDate,
  specialFloodHazardAreaIndicator,
  usersEmailAddress
} from "../../inputs/flood/serviceLink.inputs";

const floodCertificationSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Flood Certification",
      inputs: [
        specialFloodHazardAreaIndicator,
        floodCheckStatus,
        orderNumber,
        orderStatus,
        NFIPMapCommunityNumber,
        NFIPLegalCommunityNumber,
        NFIPMapCommunityName,
        NFIPLegalCommunityName,
        NFIPMapPanelNumber,
        floodMapDate,
        floodZone,
        determinationDate,
        propertyMSAMD,
        requestedInformation,
        TSDNComments,
        resultDescription
      ],
      fieldSpecs
    });

const serviceLinkFloodOrderSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Integration Flood Cert: Order",
      inputs: [
        {
          ...propertyAddressLineText,
          isRequired: true,
          validation: (x) => !!propertyAddressLineText.validation?.(x, true)
        },
        {
          ...propertyAddressUnitIdentifier,
          validation: (x) =>
            !!propertyAddressUnitIdentifier.validation?.(x, true)
        },
        {
          ...propertyCityName,
          isRequired: true,
          validation: (x) => !!propertyCityName.validation?.(x, true)
        },
        {
          ...propertyStateCode,
          isRequired: true,
          validation: (x) => !!propertyStateCode.validation?.(x, true)
        },
        {
          ...propertyPostalCode,
          isRequired: true,
          validation: (x) => !!propertyPostalCode.validation?.(x, true)
        },
        parcelNumber,
        propertyLotIdentifier,
        propertyBlockIdentifier,
        propertySubdivisionIdentifier,
        usersEmailAddress
      ],
      fieldSpecs
    });

const serviceLinkFloodVerifySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Integration Flood Cert: Verify",
      inputs: [
        specialFloodHazardAreaIndicator,
        floodCheckStatus,
        orderNumber,
        orderStatus,
        NFIPMapCommunityNumber,
        NFIPLegalCommunityNumber,
        NFIPMapCommunityName,
        NFIPLegalCommunityName,
        NFIPMapPanelNumber,
        floodMapDate,
        floodZone,
        determinationDate,
        propertyMSAMD,
        requestedInformation,
        TSDNComments,
        resultDescription
      ],
      fieldSpecs
    });

const serviceLinkFloodStatusSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Integration Flood Cert: Order Status",
      inputs: [
        orderNumber,
        floodCheckStatus,
        orderStatus,
        requestedInformation,
        resultDescription,
        returnDate
      ],
      fieldSpecs
    });

export const floodSectionBuilders = {
  floodCertificationSection,
  serviceLinkFloodOrderSection,
  serviceLinkFloodVerifySection,
  serviceLinkFloodStatusSection
};
