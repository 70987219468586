import { createSlice } from "@reduxjs/toolkit";
import lodash from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { dealApi } from "../deal/deal.service";
import { losUserApi } from "../los-user/losUser.service";
import { propertyApi } from "../property/property.service";
import { dealUserEntityAdapter as entityAdapter } from "./dealUserRelation.adapter";
import { dealUserRelationApi } from "./dealUserRelation.service";

export const dealUserRelationSlice = createSlice({
  name: "dealUserRelation",
  initialState: entityAdapter.getInitialState(),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(dealUserRelationApi)(builder);
    builder.addMatcher(
      dealUserRelationApi.endpoints.children.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      losUserApi.endpoints.userDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.dealUsers.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      propertyApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.page.dealUser.map((p) => p.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.page.dealUser, "id")
        };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.page.dealUser.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.page.dealUser, "id")
        };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.getAdditionalDataForDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.dealUser.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.dealUser, "id")
        };
      }
    );
  }
});
