import { Checkbox, Flex, Text } from "@chakra-ui/react";
import { BuildingWithHouseIcon } from "../../../../../icons";

type PropertyOptionsProps = {
  label: string;
  isChecked: boolean;
  isDisabled: boolean;
  value: string;
  onChange: (r: { value: string; isCheck: boolean }) => void;
};

export const PropertyOptions = (props: PropertyOptionsProps) => {
  const { isChecked, isDisabled, value, onChange, label } = props;
  return (
    <Flex
      p={5}
      borderRadius={6}
      borderWidth={1}
      borderColor={"gray.200"}
      width={"100%"}
      gap={"16px"}
      alignItems={"center"}
      _hover={{ bg: "gray.100" }}
      cursor={"pointer"}
      onClick={() => !isDisabled && onChange({ value, isCheck: !isChecked })}
    >
      <Checkbox
        value={value}
        onChange={(e) =>
          !isDisabled &&
          onChange({ value: e.target.value, isCheck: !e.target.checked })
        }
        isChecked={isChecked}
        isDisabled={isDisabled}
      />
      <BuildingWithHouseIcon w={7} h={7} />
      <Text>{label}</Text>
    </Flex>
  );
};
