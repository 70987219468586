import { ElphiEntityType } from "@elphi/types";
import { baseElphiFieldsSpecs } from "../../base/spec-files/baseElphiFieldsSpecs";
import { mergeSpecs } from "../../utils/formsSpec.utils";
import { MofinSpecs } from "../types";
import { fullMofinDealSpecs } from "./deal/deal.fields";
import {
  fullMofinSchemaEntityPartySpecs,
  fullMofinSchemaIndividualPartySpecs,
  mofinCommonPartySpecs
} from "./party";
import { fullMofinPropertySpecs } from "./property/property.fields";

export const mofinSpecs: MofinSpecs = {
  ...baseElphiFieldsSpecs,
  [ElphiEntityType.deal]: {
    entitySpecs: fullMofinDealSpecs
  },
  [ElphiEntityType.property]: {
    entitySpecs: fullMofinPropertySpecs
  },
  [ElphiEntityType.party]: {
    entitySpecs: mergeSpecs(
      mofinCommonPartySpecs,
      fullMofinSchemaEntityPartySpecs,
      fullMofinSchemaIndividualPartySpecs
    ),
    specsParts: {
      basePartyFieldSpecs: mofinCommonPartySpecs,
      entityPartyFieldSpecs: fullMofinSchemaEntityPartySpecs,
      individualPartyFieldSpecs: fullMofinSchemaIndividualPartySpecs
    }
  }
};
