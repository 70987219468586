import { AggregationField, ThirdPartyField } from "./aggregation.types";
import { PropertyAggregationIntergrationFields } from "./property.aggregation.integration.types";

export enum LeasedFinancedUnitKeys {
  unit0 = "unit0",
  unit1 = "unit1",
  unit2 = "unit2",
  unit3 = "unit3",
  unit4 = "unit4",
  unit5 = "unit5",
  unit6 = "unit6",
  unit7 = "unit7",
  unit8 = "unit8"
}

export type AggregationLeasedFinancedUnitsFieldValueType = {
  AdjustedRentAmount: AggregationField;
};
export type LeasedFinancedUnitsFieldType = {
  [key in LeasedFinancedUnitKeys]: Partial<AggregationLeasedFinancedUnitsFieldValueType>;
};

/** @deprecated
 * Use the customer-specific 'Property Aggregations' type instead.
 */
export type PropertyAggregationFields = Partial<{
  CountyInitialTaxEscrowAmount: AggregationField;
  CountyInitialTaxEscrowMonthCount: AggregationField;
  CityInitialTaxEscrowAmount: AggregationField;
  CityInitialTaxEscrowMonthCount: AggregationField;
  InitialFloodInsuranceEscrowAmount: AggregationField;
  InitialFloodInsuranceEscrowMonthCount: AggregationField;
  InitialPropertyInsuranceEscrowAmount: AggregationField;
  InitialPropertyInsuranceEscrowMonthCount: AggregationField;
  SpecialAssessmentInitialTaxEscrowAmount: AggregationField;
  SpecialAssessmentInitialTaxEscrowMonthCount: AggregationField;
  MonthlyLeaseRentAmount: AggregationField;
  TotalInsuranceEscrowAmount: AggregationField;
  TotalTaxEscrowAmount: AggregationField;
  MonthlyMarketRentAmount: AggregationField;
  AdjustedRentAmount: AggregationField;
  AnnualTaxAmount: AggregationField;
  PricingEngineLTARVRatePercent: AggregationField;
  BridgeLTVPercent: ThirdPartyField;
  AllocatedLoanAmount: AggregationField;
  TotalCostAmount: AggregationField;
  LTCRatePercent: AggregationField;
  AnnualCapitalExpenditure: AggregationField;
  MinimumRentLossCoverageAmount: AggregationField;
  LeasedFinancedUnits: LeasedFinancedUnitsFieldType;
  Integrations?: PropertyAggregationIntergrationFields;
}>;
