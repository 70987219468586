import { CreditScore, Party } from "@elphi/types";
import { useSelector } from "react-redux";
import { useCreditReportHooks } from "../../hooks/creditReport.hooks";
import { RootState } from "../../redux/store";
import { SearchHandler } from "../search/SearchHandler";
import { SearchComponentProps } from "../SearchComponent";
import { creditScoreToString } from "./utils/printUtils";

const buildOption = (creditScore: CreditScore) => {
  return {
    label: creditScoreToString({ creditScore }),
    value: creditScore!.id
  };
};
export enum creditScoreQueryType {
  onlyPartyCreditScore = "ONLY_PARTY_CREDIT_SCORE"
}

export const CreditScoreSearch = (
  props: Pick<
    SearchComponentProps,
    "onSelect" | "currentValue" | "fieldType" | "labelPosition" | "label"
  > & {
    selectedParty: Partial<Party> | Partial<Party>[];
    query: creditScoreQueryType;
  }
) => {
  const creditReportSlice = useSelector(
    (state: RootState) => state.creditReport
  );
  const { searchCreditScore, searchResponse, rankedSort } =
    useCreditReportHooks();

  return (
    <SearchHandler
      {...props}
      searchApi={searchCreditScore}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={creditReportSlice}
      buildOption={buildOption}
    />
  );
};
