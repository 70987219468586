import { createIcon } from "@chakra-ui/react";

const customIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 20 20",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#3182CE" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83331 6.64575C5.4191 6.64575 5.08331 6.98154 5.08331 7.39575C5.08331 7.80997 5.4191 8.14575 5.83331 8.14575H14.1666C14.5809 8.14575 14.9166 7.80997 14.9166 7.39575C14.9166 6.98154 14.5809 6.64575 14.1666 6.64575H5.83331ZM6.64581 9.99992C6.64581 9.58571 6.9816 9.24992 7.39581 9.24992H12.6041C13.0184 9.24992 13.3541 9.58571 13.3541 9.99992C13.3541 10.4141 13.0184 10.7499 12.6041 10.7499H7.39581C6.9816 10.7499 6.64581 10.4141 6.64581 9.99992ZM7.68748 12.6041C7.68748 12.1899 8.02327 11.8541 8.43748 11.8541H11.5625C11.9767 11.8541 12.3125 12.1899 12.3125 12.6041C12.3125 13.0183 11.9767 13.3541 11.5625 13.3541H8.43748C8.02327 13.3541 7.68748 13.0183 7.68748 12.6041Z"
          fill="white"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const FilterContainerIcon = customIcon();
