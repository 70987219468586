import { createIcon } from "@chakra-ui/react";

const customDiscussionIcon = (r: { stroke: string }) =>
  createIcon({
    path: (
      <svg stroke={r.stroke} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 9.5H15"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 13.5H13"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2998 19.0998L20.9998 20.9998L19.0998 15.2998"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3 19.1C15.3 19.1 14.0847 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5C20 14 19.1 15.3 19.1 15.3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

const customDiscussionRedIcon = (r: { stroke: string }) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" stroke={r.stroke}>
        <path
          d="M13.2998 19.0998L18.9998 20.9998L17.0998 15.2998"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3 19.1C13.3 19.1 12.0847 20 9.5 20C4.80558 20 1 16.1944 1 11.5C1 6.80558 4.80558 3 9.5 3C14.1944 3 18 6.80558 18 11.5C18 14 17.1 15.3 17.1 15.3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 9.5H13"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 13.5H11"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="17"
          cy="5"
          r="4"
          fill="#E53E3E"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const DiscussionIcon = customDiscussionIcon({ stroke: "#2b6cb0" });
export const DiscussionRedIcon = customDiscussionRedIcon({ stroke: "#2b6cb0" });
