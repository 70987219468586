import { map, toNumber, trim } from "lodash";
import { Deal, DealCalendarType } from "../../../../shared/types";
import { elphiDate } from "../elphiDate";
import { checkIncludes, checkIncludesArray } from "../object.utils";

type DealCalendarFilter = {
  userId?: string;
  milestoneOp?: "in" | "not-in";
  milestone?: Deal["DealMetadata"]["milestone"][];
  lenderOp?: "in" | "not-in";
  lender?: Deal["LenderIdentifier"][];
  estimatedClosingDateRange?: string[];
  dealParties?: string[];
  loanProgramTypes?: string[];
  totalLoanAmount?: string[];
};

const normalizeArray = (arr: string[]): string[] =>
  map(arr, (name: string) => trim(name.replace(/\s+/g, " ")));

export const filterDealCalendar = (
  dealCalendar: DealCalendarType,
  filters: DealCalendarFilter
) => {
  const {
    lenderOp = "in",
    lender,
    milestoneOp = "in",
    milestone,
    userId,
    dealParties,
    loanProgramTypes,
    totalLoanAmount,
    estimatedClosingDateRange
  } = filters || {};

  const lenderIdentifierIncludes = checkIncludes(
    lender,
    dealCalendar.lenderIdentifier,
    lenderOp
  );

  const dealMilestoneIncludes = checkIncludes(
    milestone,
    dealCalendar.milestone,
    milestoneOp
  );

  const loanProgramTypesIncludes = checkIncludes(
    loanProgramTypes,
    dealCalendar.loanProgramType,
    "in"
  );

  const selectedUserIdIncludes = checkIncludesArray(
    userId ? [userId] : [],
    dealCalendar.assignedUsers || [],
    "in"
  );

  const dealPartiesIncludes = checkIncludesArray(
    normalizeArray(dealParties || []),
    normalizeArray(dealCalendar.dealParties || []),
    "in"
  );

  const isAmountInRange = checkAmountInRange(
    totalLoanAmount,
    dealCalendar.requestedLoanAmount
  );

  const isDatesInRange = checkDatesInRange(
    estimatedClosingDateRange,
    dealCalendar.estimatedClosingDate
  );

  return (
    lenderIdentifierIncludes &&
    dealMilestoneIncludes &&
    selectedUserIdIncludes &&
    loanProgramTypesIncludes &&
    dealPartiesIncludes &&
    isAmountInRange &&
    isDatesInRange
  );
};

export const checkAmountInRange = (
  range?: string[],
  amount?: string,
  op: "in" | "not-in" = "in"
) => {
  if (Number(range?.[0] || 0) > 0 && !amount) return false;

  if (amount !== undefined && range && range.length === 2) {
    const [min, max] = range.map(toNumber);
    const amountNum = toNumber(amount);

    const inRange = amountNum >= min && amountNum <= max;
    return op === "in" ? inRange : !inRange;
  }
  return true;
};

export const checkDatesInRange = (
  range?: string[],
  date?: string,
  op: "in" | "not-in" = "in"
) => {
  if (date !== undefined && range && range.length === 2) {
    const [startTS, endTS] = range.map((item) =>
      elphiDate(item).toDate().getTime()
    );
    const dateTS = elphiDate(date).toDate().getTime();

    const inRange = dateTS >= startTS && dateTS <= endTS;
    return op === "in" ? inRange : !inRange;
  }
  return true;
};
