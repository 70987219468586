import { TaskRuleTemplate } from "@elphi/types";
import { useTaskRuleHooks } from "../../../hooks/taskRule.hooks";
import { SearchHandler } from "../../search/SearchHandler";
import { SearchComponentProps } from "../../SearchComponent";

const buildOption = (taskRule: TaskRuleTemplate) => {
  const label = `${taskRule.ruleName}`;
  const value = taskRule.id;
  return {
    label,
    value
  };
};

const TaskRuleSearch = (
  props: {
    filter?: (a: TaskRuleTemplate) => boolean;
  } & Pick<
    SearchComponentProps,
    | "onSelect"
    | "currentValue"
    | "label"
    | "labelPosition"
    | "isReadOnly"
    | "isDisabled"
  > &
    Pick<Partial<SearchComponentProps>, "fieldType">
) => {
  const { searchTaskRuleApi, rankedSort, taskRuleState, searchResponse } =
    useTaskRuleHooks();

  return (
    <SearchHandler
      {...props}
      searchApi={searchTaskRuleApi}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={taskRuleState}
      buildOption={buildOption}
    />
  );
};

export default TaskRuleSearch;
