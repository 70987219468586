import { ElphiTrxStatus } from "@elphi/types";
import { ErrorIcon, PendingIcon, ProcessIcon, SuccessIcon } from "../../icons";

export const ELPHI_TRX_STATUS_COLOR = {
  [ElphiTrxStatus.Success]: "green.100",
  [ElphiTrxStatus.Failed]: "red.100",
  [ElphiTrxStatus.InProcess]: "orange.100",
  [ElphiTrxStatus.Pending]: "gray.100",
  default: "gray.100"
};

export const ELPHI_TRX_STATUS_COLOR_TEXT = {
  [ElphiTrxStatus.Success]: "green.800",
  [ElphiTrxStatus.Failed]: "red.800",
  [ElphiTrxStatus.InProcess]: "yellow.800",
  [ElphiTrxStatus.Pending]: "gray.800",
  default: "gray.800"
};

export const ELPHI_TRX_STATUS_ICON = {
  [ElphiTrxStatus.Success]: <SuccessIcon boxSize="10" pr="3px" pt="8px" />,
  [ElphiTrxStatus.Failed]: <ErrorIcon boxSize="10" pr="3px" pt="8px" />,
  [ElphiTrxStatus.InProcess]: <ProcessIcon boxSize="10" pr="3px" pt="8px" />,
  [ElphiTrxStatus.Pending]: <PendingIcon boxSize="10" pr="3px" pt="8px" />,
  default: <PendingIcon boxSize="10" pr="3px" pt="8px" />
};

export const ELPHI_TRX_STATUS_TOOLTIP_MESSAGES: {
  [key in ElphiTrxStatus]: string;
} = {
  [ElphiTrxStatus.Success]: "SUCCESS",
  [ElphiTrxStatus.Failed]: "FAILED",
  [ElphiTrxStatus.InProcess]: "IN PROCESS",
  [ElphiTrxStatus.Pending]: "PENDING ORDER"
};

export const BUTTON_TOOLTIP_MESSAGES = {
  chooseService: "Please choose a service first",
  retryToOrder: "Retry to Order",
  validationError:
    "Please choose a service and fill out the required data to submit an order",
  copyOrPaste:
    "Click to copy/paste the property report's address to Elphi's system, replacing the existing property address"
};
