import {
  PosDealUserInvitationResponse,
  PosDealUserPartyView,
  PosInviteRequest,
  PosOrganizationMembershipStatus,
  PosPublishStatus
} from "@elphi/types";
import { createSlice, EntityState } from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { EMPTY, PROCESSING } from "../../../../../constants/common";
import { posDealUserApi } from "../posDealUser.service";
import { posDealUserPartyViewAdapter } from "./posDealUserPartyView.adapter";

export type PosDealUserPartyViewSliceState =
  EntityState<PosDealUserPartyView> & {};

const initialState: PosDealUserPartyViewSliceState = {
  ids: [],
  entities: {}
};

export const posDealUserPartyViewSlice = createSlice({
  name: "posDealUserPartyView",
  initialState: posDealUserPartyViewAdapter.getInitialState(initialState),
  reducers: {
    update: posDealUserPartyViewAdapter.updateOne,
    remove: posDealUserPartyViewAdapter.removeOne,
    add: posDealUserPartyViewAdapter.addOne,
    upsert: posDealUserPartyViewAdapter.upsertOne,
    upsertMany: posDealUserPartyViewAdapter.upsertMany,
    removeMany: posDealUserPartyViewAdapter.removeMany,
    updateMany: posDealUserPartyViewAdapter.updateMany
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      posDealUserApi.endpoints.get.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.result.map((p) => p.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.result, "id")
        };
      }
    ),
      builder.addMatcher(
        posDealUserApi.endpoints.publish.matchFulfilled,
        (state, { payload }) => {
          const { id } = payload;
          const entity = state.entities[id];
          if (entity) {
            state.entities[id] = {
              ...entity,
              publishStatus: PosPublishStatus.Published
            };
          }
        }
      ),
      builder.addMatcher(
        posDealUserApi.endpoints.unpublish.matchFulfilled,
        (state, { payload }) => {
          const { id } = payload;
          const entity = state.entities[id];
          if (entity) {
            state.entities[id] = {
              ...entity,
              publishStatus: PosPublishStatus.Unpublished
            };
          }
        }
      );
    builder.addMatcher(
      posDealUserApi.endpoints.invite.matchFulfilled,
      (state, { payload }) => {
        const { response } = payload;
        const { invitationId } = response;
        state.ids = union(state.ids, [invitationId]);
        if (!state.entities[invitationId]) {
          state.entities[invitationId] = buildPlaceholderRow(payload);
        }
      }
    );
  }
});

const buildPlaceholderRow = (payload: {
  response: PosDealUserInvitationResponse;
  request: PosInviteRequest;
}): PosDealUserPartyView => {
  const { response, request } = payload;
  const { invitationId, posUser } = response;
  return {
    id: invitationId,
    dealId: request.dealId,
    partyIds: [],
    partyMap: {
      [PROCESSING]: {
        name: PROCESSING
      }
    },
    userId: posUser.id,
    userName: PROCESSING,
    userEmail: PROCESSING,
    orgMembershipStatus: PROCESSING as PosOrganizationMembershipStatus,
    index: EMPTY,
    createdAt: EMPTY,
    modifiedAt: EMPTY
  };
};
