import { AlphaNumericUnderscoreHyphen, FieldType } from "@elphi/types";
import { isString } from "lodash";
import { EMPTY } from "../../../constants/common";
import { BaseInputFormatter } from "./formatter.types";

export const templateFormat = (v: string, template: string) => {
  var regex = "";

  for (var i = 1; template.indexOf("X") >= 0; ++i) {
    template = template.replace("X", "$" + i);
    regex += "(\\d)";
  }
  regex += "[^]*";
  return v.replace(new RegExp(regex), template);
};
export const maskFormat = (mask: string, number: string) => {
  var s = "" + number,
    r = "";
  for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
    r += mask.charAt(im) == "X" ? s.charAt(is++) : mask.charAt(im);
  }
  return r;
};
const numberDecoder = (v: string, options?: { positiveOnly?: boolean }) => {
  if (typeof v !== "string") return v;
  let legalNumberSigns: RegExp = /[^\d.-]/g;
  if (options?.positiveOnly) {
    legalNumberSigns = /[^\d.]/g;
  }

  return v.replaceAll(",", "").replaceAll(legalNumberSigns, "");
};
const toFixed = (num: string, fixed: number) => {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.match(re)?.[0] || num;
};

export const ssnInputFormatter: BaseInputFormatter<
  FieldType.String,
  string,
  string
> = {
  fieldType: FieldType.String,
  format: {
    encode: (v) => {
      const numOnly = v.replace(/\D/g, "");
      return maskFormat("XXX-XX-XXXX", numOnly);
    },
    decode: (v) => {
      v = v.substring(0, 11);
      v = v.replace(/[^0-9-]/, "");
      return v;
    }
  }
};
export const einInputFormatter: BaseInputFormatter<
  FieldType.String,
  string,
  string
> = {
  fieldType: FieldType.String,
  format: {
    encode: (v) => {
      if (typeof v !== "string") return v;
      const numOnly = v.replace(/\D/g, "");
      return maskFormat("XX-XXXXXXX", numOnly);
    },
    decode: (v) => {
      v = v.substring(0, 10);
      v = v.replace(/[^0-9-]/, "");
      return v;
    }
  }
};

export const creditScoreFormatter: BaseInputFormatter<
  FieldType.CreditScore,
  string,
  string
> = {
  fieldType: FieldType.CreditScore,
  format: {
    encode: (v) => {
      if (typeof v !== "string") return v;
      const numOnly = v.replace(/\D/g, "");
      return maskFormat("XXX", numOnly);
    },
    decode: (v) => {
      v = v.substring(0, 3);
      v = v.replace(/[^0-9]/, "");
      return v;
    }
  }
};

export const phoneNumberFormatter: BaseInputFormatter<
  FieldType.Phone,
  string,
  string
> = {
  fieldType: FieldType.Phone,
  format: {
    encode: (v) => {
      if (typeof v !== "string") return v;
      const numOnly = v.replace(/\D/g, "");
      return maskFormat("(XXX)-XXX-XXXX", numOnly);
    },
    decode: (v) => {
      v = v.substring(0, 14);
      v = v.replace(/[^0-9-()]/, "");
      return v;
    }
  }
};

export const yearFormatter: BaseInputFormatter<FieldType.Year, string, string> =
  {
    fieldType: FieldType.Year,
    format: {
      encode: (v) => {
        if (typeof v !== "string") return v;
        const numOnly = v.replace(/\D/g, "");
        return maskFormat("XXXX", numOnly);
      },
      decode: (v) => {
        if (typeof v !== "string") return v;
        return v.replace(/\D/g, "");
      }
    }
  };

export const fourDigitFormatter: BaseInputFormatter<
  FieldType.FourDigit,
  string,
  string
> = {
  fieldType: FieldType.FourDigit,
  format: {
    encode: (v) => {
      if (typeof v !== "string") return v;
      return maskFormat("XXXX", v);
    },
    decode: (v) => {
      if (typeof v !== "string") return v;
      return v.slice(0, 4);
    }
  }
};

export const numberFormatter: BaseInputFormatter<
  FieldType.Number,
  string,
  string
> = {
  fieldType: FieldType.Number,
  format: {
    encode: (v) => {
      if (typeof v !== "string") return v;
      if (v === "") return "";
      const numOnly = v.replace(/\D/g, "");
      return Number(numOnly).toLocaleString("en-US");
    },
    decode: (v) => {
      if (typeof v !== "string") return v;
      return v.replace(/\D/g, "");
    }
  }
};

const numberEncoder = (
  matchRegEx: string | RegExp,
  negative: boolean = false
) => {
  return (v: string) => {
    if (!isString(v) || v === EMPTY) return EMPTY;
    const matches = v?.match(matchRegEx);
    if (!matches?.[0]) return "";
    const [pre, post] = matches[0].split("."); // Splitting
    const value = pre
      .toString()
      .replace(negative ? /[^0-9-]/g : /\D/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); // This regex adds the commas to the pre
    return `${value}${pre && matches[0].indexOf(".") >= 0 ? `.${post}` : ""}`; // Combining the regexed pre with the dot and post
  };
};

const localeNumberPrecisionFormatter: BaseInputFormatter<
  FieldType.Number,
  string,
  string
> = {
  fieldType: FieldType.Number,
  format: {
    encode: numberEncoder(/(-?[0-9]*)((.)?([0-9]?)([0-9])?)/g),
    decode: (v) => {
      v = numberDecoder(v, { positiveOnly: true });
      v = toFixed(v, 2);
      return v;
    }
  }
};

const signedMoneyFormatter: BaseInputFormatter<
  FieldType.SignedMoney,
  string,
  string
> = {
  fieldType: FieldType.SignedMoney,
  format: {
    encode: numberEncoder(/^-?([0-9]*)((.)?([0-9]?)([0-9])?)/g, true),
    decode: localeNumberPrecisionFormatter.format.decode
  }
};

export const threeDecimalPlacesFormatter: BaseInputFormatter<
  FieldType.PercentageThreeDecimal,
  string,
  string
> = {
  fieldType: FieldType.PercentageThreeDecimal,
  format: {
    encode: numberEncoder(/(-?^[0-9]\d{0,2})((.)?([0-9]?)?([0-9]?)([0-9])?)/g),
    decode: (v) => {
      v = numberDecoder(v, { positiveOnly: true });
      v = toFixed(v, 3);
      return v;
    }
  }
};

export const moneyFormatter: BaseInputFormatter<
  FieldType.Money,
  string,
  string
> = {
  fieldType: FieldType.Money,
  format: {
    encode: localeNumberPrecisionFormatter.format.encode,
    decode: localeNumberPrecisionFormatter.format.decode
  }
};

export const percentFormatter: BaseInputFormatter<
  FieldType.Percentage,
  string,
  string
> = {
  fieldType: FieldType.Percentage,
  format: {
    encode: localeNumberPrecisionFormatter.format.encode,
    decode: localeNumberPrecisionFormatter.format.decode
  }
};
export const decimalFormatter: BaseInputFormatter<
  FieldType.Decimal,
  string,
  string
> = {
  fieldType: FieldType.Decimal,
  format: {
    encode: localeNumberPrecisionFormatter.format.encode,
    decode: localeNumberPrecisionFormatter.format.decode
  }
};

export const sanitizeStringFormatter: BaseInputFormatter<
  FieldType.String,
  string,
  string
> = {
  fieldType: FieldType.String,
  format: {
    decode: (v) => {
      return v;
    },
    encode: (v) => {
      return v.replace(AlphaNumericUnderscoreHyphen, "");
    }
  }
};

export const formatterMap = {
  [FieldType.Phone]: phoneNumberFormatter,
  [FieldType.Number]: numberFormatter,
  [FieldType.Decimal]: decimalFormatter,
  [FieldType.Integer]: numberFormatter,
  [FieldType.Percentage]: percentFormatter,
  [FieldType.PercentageThreeDecimal]: threeDecimalPlacesFormatter,
  [FieldType.Money]: moneyFormatter,
  [FieldType.SignedMoney]: signedMoneyFormatter,
  [FieldType.Year]: yearFormatter,
  [FieldType.CreditScore]: creditScoreFormatter,
  [FieldType.FourDigit]: fourDigitFormatter
};
