import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { auth } from "../../firebase/firebaseConfig";
import { usePartyHooks } from "../../hooks/party.hooks";
import { usePartyRelationHooks } from "../../hooks/partyrelation.hooks";
import { RootState } from "../../redux/store";
import { dealApi } from "../../redux/v2/deal";

import SearchComponent, { SearchComponentProps } from "../SearchComponent";
import { partyName } from "./utils/printUtils";

export const DealPartySearch = (
  props: { dealId: string; allowPartyRelations?: boolean } & Pick<
    SearchComponentProps,
    "onSelect" | "currentValue" | "fieldType" | "labelPosition" | "label"
  >
) => {
  const dealPartyState = useSelector(
    (state: RootState) => state.dealPartyRelation
  );

  const { partyState, partyTreeIds } = usePartyHooks();
  const { partyRelationState } = usePartyRelationHooks();

  const [getDealParties, dealPartiesResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const isLoading =
    dealPartiesResponse.isLoading || dealPartiesResponse.isFetching;

  useEffect(() => {
    if (auth.currentUser && props.dealId) {
      getDealParties([props.dealId], true);
    }
  }, [auth.currentUser, props.dealId]);

  const options = useMemo(() => {
    const partyIds: string[] = dealPartyState.ids
      .filter(
        (id: string) => dealPartyState?.entities?.[id]?.dealId === props.dealId
      )
      .map((id: string) => dealPartyState?.entities?.[id]?.partyId)
      .filter((v) => !!v) as string[];

    const allParties = props.allowPartyRelations
      ? partyTreeIds(partyRelationState, partyIds)
      : partyIds;

    return (
      allParties
        .filter((v) => !!v)
        .map((id: string) => {
          const entity = partyState.entities[id];
          return {
            label: entity ? partyName(entity) : "No Label",
            value: id
          };
        }) || []
    );
  }, [
    partyState,
    partyRelationState,
    props.allowPartyRelations,
    dealPartyState,
    props.dealId
  ]);

  return (
    <SearchComponent
      fieldType={props.fieldType}
      options={options}
      currentValue={props.currentValue}
      onSelect={props.onSelect}
      isLoading={isLoading}
      label={props.label}
      labelPosition={props.labelPosition}
    />
  );
};
