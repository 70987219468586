import { thirdPartyFieldTemplate } from "../../../../aggregations";
import { AggregationLoanPassPricingFields } from "../../../integrations";

export const getLoanpassPricingTemplate =
  (): AggregationLoanPassPricingFields => {
    const thirdPartyTemplate = thirdPartyFieldTemplate();

    return {
      adjustedPrice: thirdPartyTemplate,
      adjustedRate: thirdPartyTemplate,
      appraisedValue: thirdPartyTemplate,
      baseLoanAmount: thirdPartyTemplate,
      citizenshipType: thirdPartyTemplate,
      decisionCreditScore: thirdPartyTemplate,
      expirationDate: thirdPartyTemplate,
      investorName: thirdPartyTemplate,
      loanPurpose: thirdPartyTemplate,
      lockPeriod: thirdPartyTemplate,
      lockStatus: thirdPartyTemplate,
      mortgageLatePayments: thirdPartyTemplate,
      pipelineRecordId: thirdPartyTemplate,
      pipelineRecordName: thirdPartyTemplate,
      prepaymentPenaltyTerm: thirdPartyTemplate,
      priceAdjustments: thirdPartyTemplate,
      productName: thirdPartyTemplate,
      propertyType: thirdPartyTemplate,
      requestDate: thirdPartyTemplate,
      stateCode: thirdPartyTemplate,
      stipulations: thirdPartyTemplate,
      totalLLPA: thirdPartyTemplate
    };
  };
