const PathVariables = {
  PlatformManagement: "/admin/platform-management",
  SelectOrganization: "/select-organization",
  LoanPipeline: "/dashboard/ops-analyst",
  LandingPage: "/dashboard/landing-page",
  WorksheetPath: "/dashboard/worksheet",
  MortgageWorksheet: "/dashboard/worksheet/:dealId",
  Underwriting: "/dashboard/underwriting",
  LoanUnderwriting: "/dashboard/underwriting/:dealId",
  AnalyticsDashboard: "/dashboard/analytics",
  UserSettings: "/admin/settings",
  TaskConfiguration: "/admin/task-config",
  MilestoneConfiguration: "/admin/milestone-config",
  UserManagement: "/admin/user-management",
  LPASettings: "/admin/integration/freddie-lpa-config",
  PropertyPipeline: "/dashboard/properties",
  PartiesPipeline: "/dashboard/parties",
  ServiceProvider: "/dashboard/service-provider",
  WorkflowConfiguration: "/admin/workflow-config",
  TaskManagement: "/admin/task-management",
  DocumentManagement: "/admin/document-management",
  RolodexManagement: "/admin/rolodex-management",
  OrganizationManagement: "/admin/organization-management",
  NotificationManagement: "/admin/notification-management"
};

export const NestedMortgageWorksheet = {
  Application: "application",
  Checklist: "checklist",
  Portfolio: "portfolio",
  Closing: "closing",
  Pricing: "pricing"
};

export default PathVariables;
