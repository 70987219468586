import { EntityState } from "@reduxjs/toolkit";
// Need to use the React-specific entry point to allow generating React hooks
import { DealUser } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";
import { dealUserEntityAdapter } from "./dealUserRelation.adapter";

// Define a service using a base URL and expected endpoints
export const baseDealUserRelationApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "dealUserRelationApi",
  tagTypes: ["Deal-User-Relation", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "deal-user"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: dealUserEntityAdapter
  })
});

export const dealUserRelationApi = baseDealUserRelationApi.injectEndpoints({
  endpoints: (builder) => ({
    children: builder.query<EntityState<DealUser>, string>({
      query: (id) => {
        return {
          url: `/${id}`,
          method: "GET"
        };
      },
      transformResponse: (response: DealUser[]) => {
        const adapter = dealUserEntityAdapter.addMany(
          dealUserEntityAdapter.getInitialState(),
          response
        );
        return adapter;
      }
    }),
    addUserAssignment: builder.mutation<
      { batch: string[] },
      { dealUserIds: string[]; dealId: string }
    >({
      query: (r) => {
        return {
          url: `/add-user-assignment`,
          method: "PUT",
          body: r
        };
      }
    }),
    removeUserAssignment: builder.mutation<
      { batch: string[] },
      { dealUserIds: string[]; dealId: string }
    >({
      query: (r) => {
        return {
          url: `/remove-user-assignment`,
          method: "DELETE",
          body: r
        };
      }
    }),
    batchCreate: builder.mutation<
      { batch: string[] },
      { relations: ({ id: string } & Partial<DealUser>)[] }
    >({
      query: (r) => {
        return {
          url: `/create-batch`,
          method: "POST",
          body: r
        };
      }
    }),
    batchSet: builder.mutation<
      { batch: string[] },
      { relations: ({ id: string } & Partial<DealUser>)[] }
    >({
      query: (r) => {
        return {
          url: `/set-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { relations: ({ id: string } & Partial<DealUser>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    batchDelete: builder.mutation<{ batch: string[] }, { relations: string[] }>(
      {
        query: (r) => {
          return {
            url: `/delete-batch`,
            method: "DELETE",
            body: r
          };
        }
      }
    )
  })
});
