import { ElphiEntityType } from "@elphi/types";
import { dealPropertyFieldSpecs } from "../../../../components/form-builder/field-specs/deal-property/deal-property.fields";
import { fullBaseInsurancePolicyFieldFieldSpecs } from "../../../../components/form-builder/field-specs/insurance-policy/baseInsurancePolicy.fields";
import {
  basePropertyInsurancePolicyFieldSpecs,
  propertyBuildingsFieldSpecs,
  propertyInsurancePolicyCoveragesFieldBaseSpecs
} from "../../../../components/form-builder/field-specs/insurance-policy/property";
import {
  branchServiceProviderFieldsSpecs,
  companyServiceProviderFieldsSpecs,
  fullBranchServiceProviderFieldsSpecs,
  fullCompanyServiceProviderFieldsSpecs,
  fullRepServiceProviderFieldsSpecs,
  repServiceProviderFieldsSpecs
} from "../../../../components/form-builder/field-specs/service-provider/serviceProvider.fields";
import { statementFieldSpecs } from "../../../../components/form-builder/field-specs/statement/statement.fields";
import {
  taskBaseSpec,
  taskWithOrderStatusFieldSpecs
} from "../../../../components/form-builder/field-specs/task/task.fields";
import { mergeSpecs } from "../../utils/formsSpec.utils";
import { BaseSpecs } from "../types/baseSchema.types";
import { fullBaseSchemaAccountAssetFieldSpecs } from "./account-asset/accountAsset.fields";
import {
  fullBaseSchemaDealToEntitySpecs,
  fullBaseSchemaDealToIndividualSpecs
} from "./deal-party/dealParty.fields";
import { fullBaseSchemaDealSpecs } from "./deal/deal.fields";
import {
  fullBaseSchemaEntityToEntityFieldSpecs,
  fullBaseSchemaEntityToIndividualFieldSpecs
} from "./party-relation/partyRelation.fields";
import {
  baseSchemaCommonPartyFieldSpecs,
  baseSchemaEntityPartyFieldSpecs,
  baseSchemaIndividualPartyFieldSpecs
} from "./party/party.fields";
import { fullBaseSchemaPropertySpecs } from "./property/property.fields";

export const baseElphiFieldsSpecs: BaseSpecs = {
  [ElphiEntityType.deal]: {
    entitySpecs: fullBaseSchemaDealSpecs
  },
  [ElphiEntityType.party]: {
    entitySpecs: mergeSpecs(
      baseSchemaCommonPartyFieldSpecs,
      baseSchemaIndividualPartyFieldSpecs,
      baseSchemaEntityPartyFieldSpecs
    ),
    specsParts: {
      entityPartyFieldSpecs: baseSchemaEntityPartyFieldSpecs,
      individualPartyFieldSpecs: baseSchemaIndividualPartyFieldSpecs,
      basePartyFieldSpecs: baseSchemaCommonPartyFieldSpecs
    }
  },
  [ElphiEntityType.property]: {
    entitySpecs: fullBaseSchemaPropertySpecs
  },
  [ElphiEntityType.statement]: {
    entitySpecs: statementFieldSpecs
  },
  [ElphiEntityType.asset]: {
    entitySpecs: fullBaseSchemaAccountAssetFieldSpecs
  },
  [ElphiEntityType.partyRelation]: {
    entitySpecs: mergeSpecs(
      fullBaseSchemaEntityToEntityFieldSpecs,
      fullBaseSchemaEntityToIndividualFieldSpecs
    ),
    specsParts: {
      entityToEntityRelationFieldSpecs: fullBaseSchemaEntityToEntityFieldSpecs,
      entityToIndividualRelationFieldSpecs:
        fullBaseSchemaEntityToIndividualFieldSpecs
    }
  },
  [ElphiEntityType.dealParty]: {
    entitySpecs: mergeSpecs(
      fullBaseSchemaDealToIndividualSpecs,
      fullBaseSchemaDealToEntitySpecs
    ),
    specsParts: {
      dealToIndividualFieldSpecs: fullBaseSchemaDealToIndividualSpecs,
      dealToEntityFieldSpecs: fullBaseSchemaDealToEntitySpecs
    }
  },
  [ElphiEntityType.task]: {
    entitySpecs: mergeSpecs(taskBaseSpec, taskWithOrderStatusFieldSpecs)
  },
  [ElphiEntityType.serviceProvider]: {
    entitySpecs: mergeSpecs(
      fullCompanyServiceProviderFieldsSpecs,
      fullBranchServiceProviderFieldsSpecs,
      fullRepServiceProviderFieldsSpecs
    ),
    specsParts: {
      fullCompanyServiceProviderFieldsSpecs,
      fullBranchServiceProviderFieldsSpecs,
      fullRepServiceProviderFieldsSpecs,
      companyServiceProviderFieldsSpecs,
      branchServiceProviderFieldsSpecs,
      repServiceProviderFieldsSpecs
    }
  },
  [ElphiEntityType.insurancePolicy]: {
    entitySpecs: mergeSpecs(
      fullBaseInsurancePolicyFieldFieldSpecs,
      basePropertyInsurancePolicyFieldSpecs,
      propertyBuildingsFieldSpecs,
      propertyInsurancePolicyCoveragesFieldBaseSpecs
    ),
    specsParts: {
      property: {
        basePropertyInsurancePolicyFieldSpecs,
        propertyBuildingsFieldSpecs,
        propertyInsurancePolicyCoveragesFieldBaseSpecs
      },
      fullBaseInsurancePolicyFieldFieldSpecs
    }
  },
  [ElphiEntityType.dealProperty]: {
    entitySpecs: dealPropertyFieldSpecs
  }
};
