import { Box, chakra, Text, TextProps } from "@chakra-ui/react";

export const baseCellStyle: {
  textStyle: TextProps;
} = {
  textStyle: {
    fontWeight: "600"
  }
};

export const TextCell = chakra(Text, {
  default: {},
  baseStyle: {
    w: "100%",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left"
  }
});
export const LargeBoxCell = chakra(Box, {
  default: {},
  baseStyle: {
    w: "450px",
    p: "5px",
    borderRightColor: "black",
    borderRightWidth: "1px"
  }
});
export const MediumBoxCell = chakra(Box, {
  default: {},
  baseStyle: {
    w: "200px",
    p: "5px",
    borderRightColor: "black",
    borderRightWidth: "1px"
  }
});
export const SmallBoxCell = chakra(Box, {
  default: {},
  baseStyle: {
    w: "150px",
    p: "5px",
    borderRightColor: "black",
    borderRightWidth: "1px"
  }
});

export const TinyBoxCell = chakra(Box, {
  default: {},
  baseStyle: {
    w: "100px",
    p: "5px",
    borderRightColor: "black",
    borderRightWidth: "1px"
  }
});
