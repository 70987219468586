import { Box, FormLabel, Stack } from "@chakra-ui/react";
import { PasswordBadCharsRegex } from "@elphi/types/utils/regex";
import { removeSpaces } from "@elphi/utils/src/string.utils";
import { union } from "lodash";
import { Dispatch, SetStateAction, useEffect } from "react";
import { InvalidIssue, PasswordState } from "../types/types";
import { InvalidIssues } from "./InvalidIssues";
import { PasswordField } from "./PasswordField";
import { PasswordStrengthMeter } from "./PasswordStrengthMeter";

const PasswordSection = ({
  passwordState,
  setPasswordState
}: {
  passwordState: PasswordState;
  setPasswordState: Dispatch<SetStateAction<PasswordState>>;
}) => {
  const { password, confirmPassword, invalidIssues } = passwordState;
  const handlePasswordChange = (password: string) => {
    setPasswordState((prev) => ({ ...prev, password }));
  };

  const handleConfirmPasswordChange = (confirmPassword: string) => {
    setPasswordState((prev) => ({ ...prev, confirmPassword }));
  };

  const addInvalidIssue = (
    newIssue: InvalidIssue,
    prevIssues?: InvalidIssue[]
  ): InvalidIssue[] => {
    return union(prevIssues || [], [newIssue]);
  };

  const removeInvalidIssue = (
    issueToRemove: InvalidIssue,
    prevIssues?: InvalidIssue[]
  ) => {
    if (!prevIssues) return [];
    return prevIssues?.filter((issue) => issue != issueToRemove);
  };

  useEffect(() => {
    if (confirmPassword && password !== confirmPassword)
      setPasswordState((prev) => ({
        ...prev,
        invalidIssues: addInvalidIssue(
          InvalidIssue.UnmatchedPasswords,
          prev.invalidIssues
        )
      }));
    else
      setPasswordState((prev) => ({
        ...prev,
        invalidIssues: removeInvalidIssue(
          InvalidIssue.UnmatchedPasswords,
          prev.invalidIssues
        )
      }));

    if (
      PasswordBadCharsRegex.test(password) ||
      PasswordBadCharsRegex.test(confirmPassword)
    )
      setPasswordState((prev) => ({
        ...prev,
        invalidIssues: addInvalidIssue(
          InvalidIssue.InvalidChars,
          prev.invalidIssues
        )
      }));
    else
      setPasswordState((prev) => ({
        ...prev,
        invalidIssues: removeInvalidIssue(
          InvalidIssue.InvalidChars,
          prev.invalidIssues
        )
      }));
  }, [password, confirmPassword]);

  return (
    <Stack spacing={5} width="full">
      <FormLabel fontWeight="bold">New Password</FormLabel>
      <PasswordField
        value={password}
        onChange={(value) => handlePasswordChange(removeSpaces(value))}
      />

      <FormLabel fontWeight="bold">Confirm Password</FormLabel>
      <PasswordField
        value={confirmPassword}
        onChange={(value) => handleConfirmPasswordChange(removeSpaces(value))}
      />
      <InvalidIssues invalidIssues={invalidIssues} />
    </Stack>
  );
};
export const ResetPasswordForm = ({
  passwordState,
  setPasswordState
}: {
  passwordState: PasswordState;
  setPasswordState: Dispatch<SetStateAction<PasswordState>>;
}) => {
  return (
    <Box p={8} borderWidth="1px" borderRadius="lg" boxShadow="lg">
      <Stack spacing={8} align="flex-start">
        <PasswordSection
          passwordState={passwordState}
          setPasswordState={setPasswordState}
        />
        <PasswordStrengthMeter password={passwordState.password} />
      </Stack>
    </Box>
  );
};
