import { aggregationFieldTemplate } from "../../../aggregations";
import { DeepPartial } from "../../../utils";
import { BaseSchemaProperty } from "../../base";

export const getBaseSchemaPropertyTemplate =
  (): DeepPartial<BaseSchemaProperty> => {
    const calculatedFieldTelpate = aggregationFieldTemplate();

    return {
      ClosingCostsAmount: "",
      InitialFundedAmount: "",
      aggregations: {
        CostBasisAmount: calculatedFieldTelpate,
        LoanToValueRatePercent: calculatedFieldTelpate,
        AsIsLoanToValueRatePercent: calculatedFieldTelpate,
        LTARVRatePercent: calculatedFieldTelpate,
        LTCRatePercent: calculatedFieldTelpate,
        LTTCRatePercent: calculatedFieldTelpate
      }
    };
  };
