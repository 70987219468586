import { PortfolioPageContainerProps } from "@elphi/types";
import { useEffect, useMemo, useState } from "react";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import PortfolioPage, { PortfolioPageProps } from "./PortfolioPage";

const PortfolioPageSnapshotContainer = (
  props: PortfolioPageContainerProps & { snapshotId: string }
) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });
  const [isReadOnlyMode, setIsReadOnlyMode] = useState(false);

  useEffect(() => {
    setIsReadOnlyMode(true);
  }, []);

  const portfolioPageProps: PortfolioPageProps = useMemo(() => {
    return {
      dealProperties: !snapshot.propertyState
        ? []
        : Object.values(snapshot.propertyState?.entities),
      isReadOnlyMode: isReadOnlyMode
    };
  }, [snapshot.propertyState?.entities, isReadOnlyMode]);

  return <PortfolioPage {...portfolioPageProps} />;
};

export default PortfolioPageSnapshotContainer;
