import { RefObject, useEffect } from "react";

type ClickOutsideHookProps = (r: {
  ref: RefObject<HTMLElement>;
  onClickOutside?: () => void;
  onDoubleClickOutside?: () => void;
  onMouseLeave?: () => void;
  enableCloseClickOutside?: boolean;
  enableDoubleClickOutside?: boolean;
  enableMouseLeave?: boolean;
}) => void;

export const useClickOutsideHook: ClickOutsideHookProps = ({
  ref,
  onClickOutside,
  onDoubleClickOutside,
  onMouseLeave,
  enableCloseClickOutside = true,
  enableDoubleClickOutside = false,
  enableMouseLeave = false
}) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        enableCloseClickOutside &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        onClickOutside?.();
      }
    };

    const handleDoubleClickOutside = (event: MouseEvent) => {
      if (
        enableDoubleClickOutside &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        onDoubleClickOutside?.();
      }
    };

    const handleMouseLeave = (event: MouseEvent) => {
      if (
        enableMouseLeave &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        onMouseLeave?.();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("dblclick", handleDoubleClickOutside);
    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("dblclick", handleDoubleClickOutside);
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [
    ref,
    onClickOutside,
    onDoubleClickOutside,
    onMouseLeave,
    enableCloseClickOutside,
    enableDoubleClickOutside,
    enableMouseLeave
  ]);
};
