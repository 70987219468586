import { Skeleton, SkeletonText } from "@chakra-ui/react";
import { useMemo } from "react";
export const EventListSkeleton = (props: { number: Number }) => {
  const skeleton = useMemo(() => {
    return Array(props.number)
      .fill(0)
      .map((_, i) => {
        return (
          <Skeleton key={i} width={"100%"} height={"100px"} m={4}>
            <SkeletonText />
          </Skeleton>
        );
      });
  }, []);
  return <>{skeleton}</>;
};
