import { PartyAsset } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import lodash from "lodash";
import { assetApi } from "../asset/asset.service";
import sliceBuilder from "../builders/slice.builder";
import { partyApi } from "../party/party.service";
import { partyAssetEntityAdapter as entityAdapter } from "./partyAssetRelation.adapter";
import { partyAssetApi } from "./partyAssetRelation.service";

export type PartyAssetSliceState = EntityState<PartyAsset> & {
  selectedId?: EntityId;
};

export const partyAssetSlice = createSlice({
  name: "partyAsset",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as PartyAssetSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(partyAssetApi)(builder);
    builder.addMatcher(
      partyAssetApi.endpoints.paginate.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.getBatchPartyAsset.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      assetApi.endpoints.addAssetToParty.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, [payload.relation.id]);
        state.entities = {
          ...state.entities,
          [payload.relation.id]: payload.relation
        };
      }
    );
    builder.addMatcher(
      partyAssetApi.endpoints.createRelations.matchFulfilled,
      (state, { payload }) => {
        const relationMap = {};
        payload.forEach((relation) => {
          relationMap[relation.id] = relation;
        });
        state.ids = lodash.union(
          state.ids,
          payload.map((r) => r.id)
        );
        state.entities = { ...state.entities, ...relationMap };
      }
    );
    builder.addMatcher(
      assetApi.endpoints.getBatchAssetParty.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
  }
});
