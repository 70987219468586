import { RolodexBranchRepresentativeRelation } from "@elphi/types";
import { buildAction } from "../../builders/action.builder";
import { ActionDispatcher } from "../../types/action.types";
import { branchRepRelationSlice } from "./branchRepRelation.slice";

export const branchRepRelationAction: ActionDispatcher<
  RolodexBranchRepresentativeRelation
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(branchRepRelationSlice);
  return actionDispatcher(dispatch, callback);
};
