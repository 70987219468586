import { Box, Flex } from "@chakra-ui/react";
import { useOutletContext } from "react-router";
import DocumentPackageOrderTable from "./DocumentPackageOrderTable";
import {
  AutoGenerateOrderModal,
  ManualGenerateOrderModal
} from "./GenerateOrderComponent";

const DocumentPackageOrderPage = (props: { dealId?: string }) => {
  const { dealId: dealIdOutlet } =
    useOutletContext<{
      dealId?: string;
    }>() || {};

  const { dealId = dealIdOutlet } = props;

  return (
    <Box w="100%" h="100%">
      <Flex w="100%">
        <Box pl="10px">
          <Flex>
            <Box>
              <AutoGenerateOrderModal dealId={dealId!} />
            </Box>
            <Box pl="10px">
              <ManualGenerateOrderModal dealId={dealId!} />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Flex w="100%" p="10px">
        <Box w="100%">
          <DocumentPackageOrderTable dealId={dealId!} />
        </Box>
      </Flex>
    </Box>
  );
};

export default DocumentPackageOrderPage;
