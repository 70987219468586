import { TObject, TProperties, Type } from "@sinclair/typebox";
import {
  AutoGenerateType,
  DocumentConfiguration,
  DocumentEntityType,
  DocumentPackageType,
  SwitchValueType,
  getDocumentConfigurationTemplate
} from "../entities";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { ArrayField, EnumField, StringField } from "./utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "./utils/fieldMeta.utils";

const fieldMetaSchema = buildFieldMetadata<
  DocumentConfiguration,
  TObject<TProperties>
>({
  entityTemplate: getDocumentConfigurationTemplate(),
  target: FieldMetaTarget.Schema
});

export const DocumentConfigurationFieldsSchema = Type.Object({
  fieldMeta: Type.Partial(fieldMetaSchema),
  name: StringField,
  filePath: StringField,
  autoGenerate: EnumField(AutoGenerateType),
  documentEntity: EnumField(DocumentEntityType),
  documentRuleTemplateId: StringField,
  status: EnumField(SwitchValueType),
  documentPackageType: ArrayField(EnumField(DocumentPackageType))
});

export const DocumentConfigurationUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(DocumentConfigurationFieldsSchema)
]);

export const DocumentConfigurationCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(DocumentConfigurationFieldsSchema)
]);

export const getOperationDocumentConfigurationSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create"
    ? DocumentConfigurationCreate
    : DocumentConfigurationUpdate;
};
