import { DocumentPackageOrder } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import sliceBuilder from "../builders/slice.builder";
import { documentPackageOrderEntityAdapter as entityAdapter } from "./documentPackageOrder.adapter";
import { documentPackageOrderApi } from "./documentPackageOrder.service";

export type DocumentPackageOrderSliceState =
  EntityState<DocumentPackageOrder> & {
    selectedId?: EntityId;
  };
export const documentPackageOrderSlice = createSlice({
  name: "documentPackageOrder",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as DocumentPackageOrderSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(documentPackageOrderApi)(builder);
  }
});
