import { LOSUser } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import lodash from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { dealApi } from "../deal/deal.service";
import { losUserEntityAdapter as entityAdapter } from "./losUser.adapter";
import { losUserApi } from "./losUser.service";

export type LOSUserSliceState = EntityState<LOSUser> & {
  selectedId?: EntityId;
};
export const losUserSlice = createSlice({
  name: "losUser",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as LOSUserSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(losUserApi)(builder);
    builder.addMatcher(
      dealApi.endpoints.dealUsers.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.users.ids);
        state.entities = { ...state.entities, ...payload.users.entities };
      }
    );
    builder.addMatcher(
      losUserApi.endpoints.getUserBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.users.ids);
        state.entities = { ...state.entities, ...payload.users.entities };
      }
    );
    builder.addMatcher(
      losUserApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
  }
});
