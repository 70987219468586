import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from "@reduxjs/toolkit/dist/query";
import { getBaseUrl } from "../../../utils/envUtils";
import { RootState } from "../../store";
import { prepareHeaders } from "./headers.builder";

const rawBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: prepareHeaders
  });

export const baseQuery: (r: {
  route: string;
}) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  (r) => async (args, api, extraOptions) => {
    const orgId = (api.getState() as RootState).org.selectedId;

    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgId) {
      return {
        error: {
          status: 400,
          statusText: "Bad Request",
          data: "No Organization Was Selected"
        }
      };
    }
    const baseUrl = getBaseUrl(orgId.toString(), "api/v1/");

    return rawBaseQuery(`${baseUrl}/${r.route}`)(args, api, extraOptions);
  };
