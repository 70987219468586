import { Flex } from "@chakra-ui/react";
import { IntegrationType, PricingVendorType } from "@elphi/types";
import { useSections } from "../../../../../forms/schemas/providers/SectionsProvider";
import {
  LoanInformation,
  PipelineRecordInformation
} from "./deal/LoanpassDealSections";
import { PartyInformation } from "./party/PartyInformation";
import { PropertyInformation } from "./property/PropertyInformation";

type LoanpassSidebarSectionsProps = {
  dealId: string;
  isSectionsOpen: boolean;
};
export const LoanpassSidebarSections = (
  props: LoanpassSidebarSectionsProps
) => {
  const { sections } = useSections();
  const { dealId, isSectionsOpen } = props;

  const loanpassSections =
    sections?.integrations?.deal?.[IntegrationType.Pricing]?.[
      PricingVendorType.Loanpass
    ];

  const { loanPassPipelineRecordSection } = loanpassSections || {};
  const { loanOverviewSection } = sections?.deal || {};
  const { propertyInformationSection } = sections?.property || {};
  const { partyInformationSection } = sections?.party || {};

  if (!dealId) {
    return <></>;
  }

  return (
    <Flex direction="column" gap="2" h={"100%"} w={"100%"}>
      <PipelineRecordInformation
        dealId={dealId}
        shouldShow={isSectionsOpen}
        sectionBuilders={loanPassPipelineRecordSection}
      />
      <LoanInformation
        dealId={dealId}
        shouldShow={isSectionsOpen}
        sectionBuilders={loanOverviewSection}
      />
      <PartyInformation
        shouldShow={isSectionsOpen}
        dealId={dealId}
        sectionBuilders={partyInformationSection}
      />
      <PropertyInformation
        shouldShow={isSectionsOpen}
        dealId={dealId}
        sectionBuilders={propertyInformationSection}
      />
    </Flex>
  );
};
