import { createIcon } from "@chakra-ui/react";

const customBellIcon = (r: { stroke: string }) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" stroke={r.stroke}>
        <path
          d="M6 15V16C6 16.394 6.0776 16.7841 6.22836 17.1481C6.37913 17.512 6.6001 17.8427 6.87868 18.1213C7.1573 18.3999 7.488 18.6209 7.8519 18.7716C8.2159 18.9224 8.606 19 9 19C9.394 19 9.7841 18.9224 10.1481 18.7716C10.512 18.6209 10.8427 18.3999 11.1213 18.1213C11.3999 17.8427 11.6209 17.512 11.7716 17.1481C11.9224 16.7841 12 16.394 12 16V15"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 15C17 15 15 10 15 7C15 3.732 12.268 1 9 1C5.732 1 3 3.732 3 7C3 11 1 15 1 15H17Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

const customBellNotificationIcon = (r: { stroke: string }) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" stroke={r.stroke}>
        <path
          d="M6 17V18C6 18.394 6.0776 18.7841 6.22836 19.1481C6.37913 19.512 6.6001 19.8427 6.87868 20.1213C7.1573 20.3999 7.488 20.6209 7.8519 20.7716C8.2159 20.9224 8.606 21 9 21C9.394 21 9.7841 20.9224 10.1481 20.7716C10.512 20.6209 10.8427 20.3999 11.1213 20.1213C11.3999 19.8427 11.6209 19.512 11.7716 19.1481C11.9224 18.7841 12 18.394 12 18V17"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 17C17 17 15 12 15 9C15 5.732 12.268 3 9 3C5.732 3 3 5.732 3 9C3 13 1 17 1 17H17Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="14"
          cy="5"
          r="4"
          fill="#E53E3E"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const BellIcon = customBellIcon({ stroke: "#2B6CB0" });
export const BellNotificationIcon = customBellNotificationIcon({
  stroke: "#2B6CB0"
});
