import { BaseEntity, FirestoreTimestampFieldUnionType } from "../db";

export enum DiscussionEntityType {
  Deal = "deal",
  Task = "task"
}

export type DiscussionEntity = BaseEntity<{
  entityType: DiscussionEntityType;
  watcherUIDs?: string[];
}>;

export type DiscussionComment = BaseEntity<{
  comment: string;
  htmlComment: string;
  edited?: boolean;
  status: "active" | "deleted";
  mentionedUIDs?: string[];
  reaction?: {
    [reactionId: string]: {
      userUID: string;
      reactedAtUTC: string;
    }[];
  };
}>;

export type DiscussionNotifierUserEntity = {
  id: string;
  notify: boolean;
  notifiedAt: FirestoreTimestampFieldUnionType;
};
