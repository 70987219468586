import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import { AllEventsMode } from "../../redux/v2/audit-event";
import { ActiveHistoryIcon, NotActiveHistoryIcon } from "../icons";
import { AuditLogBoxProps } from "./auditLogBox.types";
import { AuditLogModal } from "./modal/AuditLogModal";
import { DealAllEventsTab } from "./modal/tabs/DealAllEventsTab";
import { FieldAllEventsTab } from "./modal/tabs/FieldAllEventsTab";

export const AuditLogBox = (props: AuditLogBoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <IconButton
        p={0}
        m={0}
        w={0}
        h={0}
        minWidth={"inherit"}
        verticalAlign={"inherit"}
        aria-label="audit"
        variant="unstyled"
        onClick={onOpen}
        icon={
          isOpen ? (
            <ActiveHistoryIcon w={7} h={7} />
          ) : (
            <NotActiveHistoryIcon w={7} h={7} />
          )
        }
      />
      <AuditLogModal
        isOpen={isOpen}
        onClose={onClose}
        title={`Audit Log - ${props.title}`}
      >
        <AllEventTab {...props} />
      </AuditLogModal>
    </Box>
  );
};

const AllEventTab = (props: AuditLogBoxProps) => {
  if (props.type === AllEventsMode.Deal) {
    return <DealAllEventsTab {...props} />;
  }
  if (props.type === AllEventsMode.Field) {
    return <FieldAllEventsTab {...props} />;
  }
  return <></>;
};
