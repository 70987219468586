import { IntegrationTaskTemplate, PropertyReportOrderTask } from "@elphi/types";
import { usePropertyReportTaskHooks } from "../../../../hooks/propertyReportTask.hooks";
import { TaskStatusIconToolTip } from "../../TaskStatusIconToolTip";
export type PropertyReportTaskIconProps =
  {} & IntegrationTaskTemplate<PropertyReportOrderTask>;

export const PropertyReportTaskIcon = ({
  selectedTask
}: PropertyReportTaskIconProps) => {
  if (!selectedTask) return null;

  const { getOrderStatusTooltip } = usePropertyReportTaskHooks();

  return (
    <TaskStatusIconToolTip
      selectedTask={selectedTask}
      getOrderStatusTooltip={getOrderStatusTooltip}
    />
  );
};
