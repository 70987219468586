import { ElphiEntityType } from "@elphi/types";
import {
  dateValidation,
  moneyValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const policyNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy].specsParts?.property
      .basePropertyInsurancePolicyFieldSpecs.policyNumber.fieldKey,
  validation: stringValidation
});
export const coverageTypes = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs?.coverageTypes
      ?.fieldKey,
  validation: stringValidation
});
export const insuranceCarrier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs?.insuranceCarrier
      ?.fieldKey,
  validation: stringValidation
});
export const effectiveDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs?.effectiveDate
      ?.fieldKey,
  validation: dateValidation
});
export const expirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs?.expirationDate
      ?.fieldKey,
  validation: dateValidation
});
export const nextPaymentDueDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs?.nextPaymentDueDate
      ?.fieldKey,
  validation: dateValidation
});
export const policyDeductibleAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs?.policyDeductibleAmount
      ?.fieldKey,
  validation: moneyValidation
});
export const outstandingPremiumStatus = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs
      ?.outstandingPremiumStatus?.fieldKey,
  validation: stringValidation
});
export const outstandingPremiumAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs
      ?.outstandingPremiumAmount?.fieldKey,
  validation: stringValidation
});
export const insurancePremiumPaymentAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.basePropertyInsurancePolicyFieldSpecs
      ?.insurancePremiumPaymentAmount?.fieldKey,
  validation: moneyValidation
});

export const policyPremiumAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.basePropertyInsurancePolicyFieldSpecs.policyPremiumAmount
      ?.fieldKey,
  validation: stringValidation
});

export const cAFairPlanCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .CAFairPlanCoverageAmount!.fieldKey,

  validation: stringValidation
});
export const hO6CoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .HO6CoverageAmount!.fieldKey,
  validation: stringValidation
});

export const buildersRiskCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .buildersRiskCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const condoMasterFloodCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .condoMasterFloodCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const condoMasterHazardCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .condoMasterHazardCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const earthquakeCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .earthquakeCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const excessFlood = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .excessFlood!.fieldKey,
  validation: stringValidation
});

export const floodCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .floodCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const hazardCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .hazardCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const hazardReplacementCoverage = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .hazardReplacementCoverage!.fieldKey,
  validation: stringValidation
});

export const lavaCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .lavaCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const liabilityCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .liabilityCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const rentLossCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .rentLossCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const windCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property.propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
      .windCoverageAmount!.fieldKey,
  validation: stringValidation
});

export const buildingMailingAddress = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.propertyBuildingsFieldSpecs?.buildingMailingAddress?.fieldKey,
  validation: stringValidation
});
export const buildingType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts
      ?.property?.propertyBuildingsFieldSpecs?.buildingType?.fieldKey,
  validation: stringValidation
});
