import { Property } from "@elphi/types";
import { useSections } from "../../../../../../forms/schemas/providers/SectionsProvider";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { StepForm } from "../components/StepForm";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { entitiesPath } from "../utils/formUtils";
import { getPropertyCoveragesSection } from "./utils/coveragesSection";

type PropertyCoveragesDataProps = {
  isDisabled: boolean;
  property: Property;
  propertyId: string;
  state: PropertyInsurancePolicyState;
  onChange: (value: OnChangeInput) => void;
};

export const PropertyCoveragesData = (props: PropertyCoveragesDataProps) => {
  const { isDisabled, property, propertyId, onChange, state } = props;
  const { sections } = useSections();
  const onPolicyFieldChange = (r: { e: OnChangeInput; propertyId: string }) => {
    const { e, propertyId } = r;
    onChange({
      ...e,
      fieldKey: [entitiesPath, propertyId, ...e.fieldKey]
    });
  };

  const propertyCoveragesSection = getPropertyCoveragesSection?.({
    propertyId,
    state,
    onChange,
    isReadOnly: isDisabled,
    prefix: [entitiesPath, propertyId]
  });

  const staticFieldsSection =
    sections?.insurancePolicy?.propertyInsurancePolicyStaticFieldsSection?.({
      onChange,
      state,
      prefix: [entitiesPath, props.propertyId]
    });

  return (
    <StepForm
      isDisabled={isDisabled}
      customKey={`insurancePolicyForm-${property?.id}`}
      onChange={(e) => onPolicyFieldChange({ e, propertyId })}
      sections={[staticFieldsSection, propertyCoveragesSection]}
    />
  );
};
