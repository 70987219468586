import { FieldType } from "@elphi/types";
import {
  PropertyInsurancePolicy,
  PropertyInsurancePolicyCoverageType
} from "@elphi/types/entities/insurance-policy";
import { createSelectOptions } from "../../../../utils/formUtils";
import { baseEntityFormFieldSpecs } from "../../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../../fields.types";
import { baseInsurancePolicyFieldSpecs } from "../baseInsurancePolicy.fields";
import { propertyInsurancePolicyCoverageTypeOptions } from "../coverageNamesMappings";

type PropertyInsurancePolicyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<PropertyInsurancePolicy>
>;

export type BasePropertyInsurancePolicyFieldSpecs = Omit<
  PropertyInsurancePolicyFieldSpecs,
  "entities"
>;

export const basePropertyInsurancePolicyFieldSpecs: BasePropertyInsurancePolicyFieldSpecs =
  {
    ...baseEntityFormFieldSpecs,
    ...baseInsurancePolicyFieldSpecs,
    coverageTypes: {
      fieldKey: ["coverageTypes"],
      fieldType: FieldType.MultiSelect,
      label: "Coverage Types",
      options: createSelectOptions(
        PropertyInsurancePolicyCoverageType,
        propertyInsurancePolicyCoverageTypeOptions
      )
    },
    policyPremiumAmount: {
      fieldKey: ["policyPremiumAmount"],
      fieldType: FieldType.Money,
      label: "Policy Premium Amount"
    }
  };
