export enum ExpandSectionNames {
  DealPartyRelationship = "DealPartyRelationship"
}
export type ExpandSection<P extends ExpandSectionNames> = {
  name: P;
  isExpanded: boolean;
};

export type ExpandSections = {
  [P in ExpandSectionNames]?: ExpandSection<P>;
};
