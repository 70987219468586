import {
  ElphiEntityType,
  FloodCertificateVendorType,
  IntegrationType,
  PricingVendorType,
  PropertyReportVendorType,
  RentalDataVendorType
} from "@elphi/types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import {
  FundingShieldIntegrationFieldSpecs,
  PricingIntegrationFieldSpecs,
  fundingShieldIntegrationFieldSpecs,
  pricingIntegrationFieldSpecs
} from "./deal";
import {
  ServiceLinkIntegrationsFieldSpecs,
  SiteXIntegrationsFieldSpecs,
  serviceLinkIntegrationsFieldSpecs,
  siteXPropertyIntegrationsFieldSpecs
} from "./property";
import {
  RentRangeIntegrationsFieldSpecs,
  rentRangeIntegrationsFieldSpecs
} from "./property/rental-data/rentRange.fields";

type SpecOf<T> = { spec: T };

type DealIntegrations = {
  [ElphiEntityType.deal]: {
    [IntegrationType.WireInsuranceCertificate]: {
      [WireInsuranceVendorType.FundingShield]: SpecOf<FundingShieldIntegrationFieldSpecs>;
    };
    [IntegrationType.Pricing]: {
      [PricingVendorType.Loanpass]: SpecOf<PricingIntegrationFieldSpecs>;
    };
  };
};

type PropertyIntegrations = {
  [ElphiEntityType.property]: {
    [IntegrationType.FloodCertificate]: {
      [FloodCertificateVendorType.ServiceLink]: SpecOf<ServiceLinkIntegrationsFieldSpecs>;
    };
    [IntegrationType.PropertyReport]: {
      [PropertyReportVendorType.SiteX]: SpecOf<SiteXIntegrationsFieldSpecs>;
    };
    [IntegrationType.RentalData]: {
      [RentalDataVendorType.RentRange]: SpecOf<RentRangeIntegrationsFieldSpecs>;
    };
  };
};

export type IntegrationsSpecs = DealIntegrations & PropertyIntegrations;

export const integrationSpecs: IntegrationsSpecs = {
  [ElphiEntityType.deal]: {
    [IntegrationType.WireInsuranceCertificate]: {
      [WireInsuranceVendorType.FundingShield]: {
        spec: fundingShieldIntegrationFieldSpecs
      }
    },
    [IntegrationType.Pricing]: {
      [PricingVendorType.Loanpass]: { spec: pricingIntegrationFieldSpecs }
    }
  },
  [ElphiEntityType.property]: {
    [IntegrationType.FloodCertificate]: {
      [FloodCertificateVendorType.ServiceLink]: {
        spec: serviceLinkIntegrationsFieldSpecs
      }
    },
    [IntegrationType.PropertyReport]: {
      [PropertyReportVendorType.SiteX]: {
        spec: siteXPropertyIntegrationsFieldSpecs
      }
    },
    [IntegrationType.RentalData]: {
      [RentalDataVendorType.RentRange]: {
        spec: rentRangeIntegrationsFieldSpecs
      }
    }
  }
};
integrationSpecs[ElphiEntityType.property][IntegrationType.FloodCertificate][
  FloodCertificateVendorType.ServiceLink
];
