import { Asset, Party } from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import useAssetHooks from "../../hooks/asset.hooks";
import { RootState } from "../../redux/store";
import { SearchHandler } from "../search/SearchHandler";

import { SearchComponentProps } from "../SearchComponent";
import { assetToString } from "./utils/printUtils";

export enum assetQueryType {
  onlyPartyAssets = "ONLY_PARTY_ASSETS",
  noPartyAssets = "NO_PARTY_ASSETS",
  moreThanOnePartyAsset = "MORE_THAN_ONE_PARTY_ASSET"
}

export const AssetQuerySearch = (
  props: Pick<
    SearchComponentProps,
    "onSelect" | "currentValue" | "fieldType" | "labelPosition" | "label"
  > & {
    selectedParty: Partial<Party> | Partial<Party>[];
    query: assetQueryType;
  }
) => {
  const assetSlice = useSelector((state: RootState) => state.asset);
  const partyAssetSlice = useSelector((state: RootState) => state.partyAsset);
  const { searchAsset, searchResponse, rankedSort } = useAssetHooks();

  const filter = (a: Asset) => {
    if (Array.isArray(props.selectedParty)) {
      const possibleIdMap: EntityId[] = props.selectedParty.map(
        (party) => `${a.id}_${party.id}`
      );
      if (props.query === assetQueryType.noPartyAssets) {
        return !partyAssetSlice.ids.some((id) => possibleIdMap.includes(id));
      }
      return partyAssetSlice.ids.some((id) => possibleIdMap.includes(id));
    }

    const isPartyAssetsContains = partyAssetSlice.ids.includes(
      `${a.id}_${props.selectedParty.id}`
    );

    if (props.query === assetQueryType.noPartyAssets) {
      return !isPartyAssetsContains;
    }
    return isPartyAssetsContains;
  };

  const buildOption = (asset: Asset) => {
    if (isMoreThanOnePartyAssetQuery()) {
      const countAssets: number = partyAssetSlice.ids
        .map(extractPartyIdFromAsset)
        .filter(isAssetBelongsToParty(asset.id)).length;
      const isDisabled = countAssets <= 1;
      return {
        label: assetToString({ asset }),
        value: asset!.id,
        isDisabled
      };
    }
    return {
      label: assetToString({ asset }),
      value: asset!.id
    };
  };

  const isMoreThanOnePartyAssetQuery = () => {
    return props.query === assetQueryType.moreThanOnePartyAsset;
  };

  const extractPartyIdFromAsset = (assetId: EntityId): string | null => {
    return assetId.toString().split("_")[0];
  };

  const isAssetBelongsToParty =
    (partyId: string) =>
    (assetPartyId: string | null): boolean => {
      return assetPartyId === partyId;
    };

  return (
    <SearchHandler
      {...props}
      filter={filter}
      searchApi={searchAsset}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={assetSlice}
      buildOption={buildOption}
    />
  );
};
