import { Box, Text } from "@chakra-ui/react";
import {
  ElphiTrxStatus,
  IntegrationType,
  RentRangeRentalDataFailedLabel,
  RentalDataVendorType,
  StatusCode,
  Task,
  TaskType
} from "@elphi/types";
import { getFocusedData } from "@elphi/utils";
import { responseHandler } from "../apis/rtk/response.handler";
import { ELPHI_TRX_STATUS_TOOLTIP_MESSAGES } from "../components/task/integrations/integration.type";
import { useElphiToast } from "../components/toast/toast.hook";
import { rentRangeApi } from "../redux/v2/integration/rent-range/rentRange.service";
import { usePropertyHooks } from "./property.hooks";

const RENT_RANGE_ELPHI_TIPS = {
  tryAddTip: "Try Adding values to: BEDROOMS, BATHROOMS, and SQUARE FOOT"
} as const;

const RENT_RANGE_LABEL_MESSAGES: {
  [key in RentRangeRentalDataFailedLabel]: string;
} = {
  ...ELPHI_TRX_STATUS_TOOLTIP_MESSAGES,
  failedUploadingReport:
    "File was received, but failed to upload. Please click Retry to solve the problem. If the issue persists, contact Elphi",
  failedFetchReport:
    "Failed to fetch the file from the vendor. Please click the Retry to solve the problem. If the issue persists, contact Elphi"
} as const;

const RENT_RANGE_STEP_LABEL_TOOLTIP_MESSAGES = {
  ...ELPHI_TRX_STATUS_TOOLTIP_MESSAGES,
  ...RENT_RANGE_ELPHI_TIPS,
  ...RENT_RANGE_LABEL_MESSAGES
} as const;

export const useRentalDataTaskHooks = () => {
  const { errorToast, successToast } = useElphiToast();
  const { propertyState } = usePropertyHooks();
  const [createOrderApi, createOrderResponse] =
    rentRangeApi.useCreateOrderReportMutation();
  const [retryOrderApi, retryOrderResponse] =
    rentRangeApi.useRetryOrderReportMutation();

  const createOrderHandler = async (r: { taskId: string }) => {
    return await createOrderApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: `Rental AVM ordered successfully`,
            description: `task id ${res.data.taskId}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: `Rental AVM order failed`,
            description: `description ${
              res.data.description
            }, error ${JSON.stringify(res.data.error)}`
          });
        }
      });
  };

  const retryOrderHandler = (r: { taskId: string }) => {
    retryOrderApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: `Rental AVM retried successfully`,
            description: `task id ${res.data.taskId}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: `Rental AVM retry failed`,
            description: `description ${
              res.data.description
            }, error ${JSON.stringify(res.data.error)}`
          });
        }
      });
  };

  const getRentalDataVendorTask = (
    task: Task | undefined,
    vendor: RentalDataVendorType
  ) =>
    task?.type === TaskType.Integration &&
    task?.integrationType === IntegrationType.RentalData &&
    task?.vendorType === vendor
      ? task
      : undefined;

  const getOrderStatusTooltip = (
    task: Task
  ): string | JSX.Element | undefined => {
    const currentTask = getRentalDataVendorTask(
      task,
      RentalDataVendorType.RentRange
    )!;
    if (!currentTask) {
      return undefined;
    }

    const { elphiTrxStatus, failedLabel } = currentTask;
    if (elphiTrxStatus === ElphiTrxStatus.Failed) {
      const selectedProperty = propertyState?.entities[task?.entityId];
      if (selectedProperty && !failedLabel) {
        const rentRangeErrorMessage = getFocusedData(
          selectedProperty?.aggregations?.Integrations?.RentalData?.RentRange
            ?.errorMessage
        );
        if (rentRangeErrorMessage) {
          const errorWithTip = (
            <Box>
              <Text>VENDOR MESSAGE:</Text>
              <Text>{rentRangeErrorMessage}</Text>
              <br />
              <Text>ELPHI TIP:</Text>
              <Text>{RENT_RANGE_STEP_LABEL_TOOLTIP_MESSAGES.tryAddTip}</Text>
            </Box>
          );
          return errorWithTip;
        }
      }

      return failedLabel
        ? RENT_RANGE_STEP_LABEL_TOOLTIP_MESSAGES?.[failedLabel]
        : RENT_RANGE_STEP_LABEL_TOOLTIP_MESSAGES?.[ElphiTrxStatus.Failed];
    }
    return RENT_RANGE_STEP_LABEL_TOOLTIP_MESSAGES?.[elphiTrxStatus];
  };

  return {
    getRentalDataVendorTask,
    createOrderResponse,
    retryOrderResponse,
    createOrderHandler,
    retryOrderHandler,
    getOrderStatusTooltip
  };
};
