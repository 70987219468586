import { createIcon } from "@chakra-ui/react";

const customBellPenIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 28 28",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.51 7.29165L22.575 9.35665L16.765 15.1666H14.7V13.1016L20.51 7.29165ZM24.325 6.77831L23.0883 5.54165C22.855 5.30831 22.4933 5.30831 22.26 5.54165L21.2683 6.53331L23.3333 8.59831L24.325 7.60665C24.5583 7.37331 24.5583 6.99998 24.325 6.77831V6.77831ZM21 14.2333V19.8333H23.3333V22.1666H4.66666V19.8333H7V11.6666C7 8.41165 9.22833 5.66998 12.25 4.89998V4.08331C12.25 3.11498 13.0317 2.33331 14 2.33331C14.9683 2.33331 15.75 3.11498 15.75 4.08331V4.89998C16.7067 5.14498 17.5817 5.58831 18.3283 6.17165L12.3667 12.1333V17.5H17.7333L21 14.2333ZM11.6667 23.3333H16.3333C16.3333 24.6166 15.2833 25.6666 14 25.6666C12.7167 25.6666 11.6667 24.6166 11.6667 23.3333Z"
          stroke="#A2C1FF"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const BellPenIcon = customBellPenIcon();
