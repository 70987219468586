import { StatusCode } from "@elphi/types";
import { getBaseUrl } from "../../../utils/envUtils";
import { ResetPasswordRequest } from "../types/types";

export const resetPassword = async ({
  code,
  password
}: ResetPasswordRequest) => {
  try {
    if (!code) {
      return { status: StatusCode.BadRequest, error: "Code is missing" };
    }
    const response = await fetch(getBaseUrl("", "auth/reset"), {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        password: password,
        code: code
      })
    });
    if (response.ok) {
      return { status: StatusCode.OK };
    } else {
      return {
        status: StatusCode.Failed,
        error: `Failed with status ${response.status}`
      };
    }
  } catch (err) {
    return { status: StatusCode.Failed, error: String(err) };
  }
};
