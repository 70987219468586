import { TObject, TProperties, Type } from "@sinclair/typebox";
import { StorageProvider } from "../storage";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { EnumField, StringField } from "./utils.typebox";

export const StorageMetadataFieldsSchema = Type.Object({
  folderId: StringField,
  fileId: StringField
});

//TODO:
// create as union type for different vendors so we get destructive union when migrating to typebox static types
export const DocumentMetadataFieldsSchema = Type.Object({
  provider: EnumField(StorageProvider),
  data: StorageMetadataFieldsSchema
});

export const DocumentMetadataUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(DocumentMetadataFieldsSchema)
]);

export const DocumentMetadataCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(DocumentMetadataFieldsSchema)
]);

export const getOperationDocumentMetadataSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? DocumentMetadataCreate : DocumentMetadataUpdate;
};
