import { EntityState } from "@reduxjs/toolkit";
import { removeNulls } from "../../utils/filter.utils";

export const filterEntities = <T>(r: {
  entityState: EntityState<T>;
  key: keyof T;
  value: string | string[];
}): T[] => {
  const { entityState, key, value } = r;
  if (!Array.isArray(value)) {
    return Object.values(entityState.entities)
      .filter((s) => s && s[key] === value)
      .filter(removeNulls);
  } else {
    return Object.values(entityState.entities)
      .filter((s) => s && value.includes(s[key.toString()]))
      .filter(removeNulls);
  }
};
