import { Button, Flex, Tooltip } from "@chakra-ui/react";
import { EMPTY } from "../../../../constants/common";
import useDealHooks from "../../../../hooks/deal.hooks";
import { DataSubmitionModalBody, LoanpassModal } from "../modals";
import { useloanpassSidebarHooks } from "./loanpassSidebar.hooks";
import { LoanpassSidebarSections } from "./sections/LoanpassSidebarSections";

type LoanpassSidebarSectionsProps = {
  onConfirm: () => void;
  isIframeListening: boolean;
  selectedPipelineRecord?: string;
};
export const LoanpassSidebar = (props: LoanpassSidebarSectionsProps) => {
  const { selectedDeal } = useDealHooks();
  const dealId = selectedDeal ? selectedDeal.id : EMPTY;
  const {
    shouldExpandAllSections,
    shouldShowSubmitModal,
    handleSubmit,
    handleSubmitModalClose,
    handleSubmitModalConfirm
  } = useloanpassSidebarHooks();

  const handleConfirm = () => {
    handleSubmitModalConfirm();
    props.onConfirm();
  };

  if (!dealId) {
    return <></>;
  }
  const isPipelineRecordSelected = !!props.selectedPipelineRecord;
  const canSubmit = isPipelineRecordSelected && props.isIframeListening;
  return (
    <>
      <Flex direction="column" gap="2" h={"fit-content"}>
        <LoanpassSidebarSections
          isSectionsOpen={shouldExpandAllSections}
          dealId={dealId}
        />
        <Tooltip
          key={`loanpass-bubmit-button`}
          label="Select a Pipeline Record first. If the button remains disabled, please try again."
          placement="top-start"
          hasArrow
          isDisabled={isPipelineRecordSelected}
        >
          <Button
            colorScheme="blue"
            width={"full"}
            p={4}
            mt={4}
            isDisabled={!canSubmit}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Tooltip>
      </Flex>
      <LoanpassModal
        maxWidth={500}
        isOpen={shouldShowSubmitModal}
        modalTitle="Confirm Data Submission"
        modalBody={<DataSubmitionModalBody />}
        onClose={handleSubmitModalClose}
        handleConfirm={handleConfirm}
        isLoading={false}
      />
    </>
  );
};
