import { AuditEvent, AuditEventEntityType } from "@elphi/types";
import { ComponentType } from "react";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { DiscussionPath, EntityPath } from "./EventRowEntityPath";
import { DiscussionValue, EntityValue } from "./EventRowFieldValue";

const EventEntity = new Set<AuditEventEntityType>([
  AuditEventEntityType.Asset,
  AuditEventEntityType.Deal,
  AuditEventEntityType.DealParty,
  AuditEventEntityType.DealProperty,
  AuditEventEntityType.DealUser,
  AuditEventEntityType.Party,
  AuditEventEntityType.PartyAsset,
  AuditEventEntityType.PartyRelation,
  AuditEventEntityType.PartyUser,
  AuditEventEntityType.Property,
  AuditEventEntityType.Statement,
  AuditEventEntityType.Task,
  AuditEventEntityType.ServiceProvider
]);

const EventEntityStrategy = (props: {
  event: AuditEvent;
  EntityComponent: ComponentType<{ event: AuditEvent }>;
  DiscussionComponent: ComponentType<{ event: AuditEvent }>;
}) => {
  const { event, EntityComponent, DiscussionComponent } = props;
  if (EventEntity.has(event.entityType)) {
    return <EntityComponent event={event} />;
  }
  if (event.entityType === AuditEventEntityType.DiscussionEntity) {
    return <DiscussionComponent event={event} />;
  }
  return <>{NOT_AVAILABLE}</>;
};

export const EventEntityPath = (props: { event: AuditEvent }) => (
  <EventEntityStrategy
    event={props.event}
    EntityComponent={EntityPath}
    DiscussionComponent={DiscussionPath}
  />
);

export const EventEntityValue = (props: { event: AuditEvent }) => (
  <EventEntityStrategy
    event={props.event}
    EntityComponent={EntityValue}
    DiscussionComponent={DiscussionValue}
  />
);
