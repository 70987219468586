import { BaseEntity } from "../db";
import { DocumentRuleTemplate } from "./documentRuleTemplate.types";
import { ConditionNode, OperationNode } from "./rulesEngine.types";
import { TaskRuleTemplate } from "./taskRuleTemplate.types";

export enum RuleTemplateType {
  Task = "Task",
  Document = "Document"
}

export type BaseRuleTemplate<TType extends RuleTemplateType> = BaseEntity<{
  type: TType;
  ruleName: string;
  includeEntities: {
    deal: boolean;
    property: boolean;
    party: boolean;
    dealParty: boolean;
    partyRelation: boolean;
    dealProperty: boolean;
    asset: boolean;
    statement: boolean;
    creditScore: boolean;
    task: boolean;
    serviceProvider: boolean;
    insurancePolicy: boolean;
  };
  rule: ConditionNode | OperationNode;
}>;

export type RuleTemplate = TaskRuleTemplate | DocumentRuleTemplate;
