import { Type } from "@sinclair/typebox";
import { AssetType } from "../../entities";
import { baseAccountType } from "../../entities/base";
import { EnumField, StringField } from "../utils.typebox";

const AssetAggregationSchema = Type.Object({
  finalEstimationAssetValue: Type.Optional(StringField)
});

const BaseAssetSchema = <T extends AssetType>(type: T) =>
  Type.Intersect([
    Type.Object({
      type: Type.Literal(type),
      statements: Type.Array(StringField),
      estimatedValueAmount: StringField,
      estimatedValueConsideredPercentage: StringField,
      appraisalValueAmount: StringField,
      finalEstimatedValueAmount: StringField
    }),
    AssetAggregationSchema
  ]);

export const elphiAccountAssetSchema = Type.Intersect([
  BaseAssetSchema(AssetType.Account),
  Type.Object({
    type: Type.Literal(AssetType.Account),
    fullNameOnAccount: StringField,
    accountName: StringField,
    accountType: EnumField(baseAccountType),
    financialInstitution: StringField,
    last4Digits: StringField
  })
]);
