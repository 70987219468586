export enum DataSheetCellState {
  Valid = "Valid",
  FollowUp = "Follow Up",
  Denied = "Denied",
  None = "None"
}

export type DataSheetCellMetadata = {
  override: boolean;
  state: DataSheetCellState;
};
