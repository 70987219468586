import {
  Deal,
  IntegrationType,
  StatusCode,
  Task,
  TaskType
} from "@elphi/types";
import { ElphiTrxStatus } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/funding-shield/fundingShield.types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/wireInsuranceVendor.types";
import { responseHandler } from "../apis/rtk/response.handler";
import {
  BUTTON_TOOLTIP_MESSAGES,
  ServiceOption
} from "../components/task/integrations/wire-insurance/wireInsurance.type";
import { useElphiToast } from "../components/toast/toast.hook";
import { useTaskTemplateHooks } from "./taskTemplate.hooks";

export const useWireInsuranceTaskHooks = () => {
  const {
    createFundingShieldApi,
    createFundingShieldApiResponse,
    retryFundingShieldApi,
    retryFundingShieldApiResponse
  } = useTaskTemplateHooks();
  const { errorToast, successToast } = useElphiToast();

  const getWireInsuranceTask = (
    task: Task | undefined,
    vendor: WireInsuranceVendorType
  ) =>
    task?.type === TaskType.Integration &&
    task?.integrationType === IntegrationType.WireInsuranceCertificate &&
    task?.vendorType === vendor
      ? task
      : undefined;

  const getOrderStatusTooltip = (task: Task, showOnlyError?: boolean) => {
    const currentTask = getWireInsuranceTask(
      task,
      WireInsuranceVendorType.FundingShield
    );
    if (currentTask) {
      const { elphiTrxStatus, failedLabel } = currentTask;
      if (elphiTrxStatus === ElphiTrxStatus.Failed) {
        if (failedLabel) {
          return BUTTON_TOOLTIP_MESSAGES?.[failedLabel];
        }
      }
      return !showOnlyError
        ? elphiTrxStatus
          ? BUTTON_TOOLTIP_MESSAGES?.[elphiTrxStatus]
          : BUTTON_TOOLTIP_MESSAGES?.[ElphiTrxStatus.Pending]
        : undefined;
    }
    return undefined;
  };

  const handleOnCreateOrderCertificate = (
    dealSelection: ServiceOption,
    selectedDeal: "" | Deal,
    currentTaskId: string
  ) => {
    if (dealSelection === ServiceOption.OrderCertificate) {
      if (selectedDeal) {
        createFundingShieldApi({
          dealId: selectedDeal.id,
          taskId: currentTaskId
        })
          .then(responseHandler)
          .then((r) => {
            if (r.status === StatusCode.OK) {
              successToast({
                title: "Order certificate created",
                description: `task id ${r.data.taskId}`
              });
            }
            if (r.status === StatusCode.BadRequest) {
              errorToast({
                title: "Order certificate failed",
                description: `description ${
                  r.data.description
                }, error ${JSON.stringify(r.data.error)}`
              });
            }
            return r;
          });
      }
    }
  };

  const handleOnRetryOrderCertificate = (
    selectedDeal: "" | Deal,
    currentTaskId: string
  ) => {
    if (selectedDeal) {
      retryFundingShieldApi({
        taskId: currentTaskId
      })
        .then(responseHandler)
        .then((r) => {
          if (r.status === StatusCode.OK) {
            successToast({
              title: "Order certificate retried",
              description: `task id ${r.data.taskId}`
            });
          }
          if (r.status === StatusCode.BadRequest) {
            errorToast({
              title: "Order certificate retry failed",
              description: `description ${
                r.data.description
              }, error ${JSON.stringify(r.data.error)}`
            });
          }
          return r;
        });
    }
  };

  return {
    createFundingShieldApiResponse,
    retryFundingShieldApiResponse,
    handleOnCreateOrderCertificate,
    handleOnRetryOrderCertificate,
    getWireInsuranceTask,
    getOrderStatusTooltip
  };
};
