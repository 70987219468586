import { Box, Tooltip } from "@chakra-ui/react";
import { FieldType, Party, PartyType } from "@elphi/types";
import { useEffect } from "react";
import { EMPTY } from "../../../../../../constants/common";
import { usePartyHooks } from "../../../../../../hooks/party.hooks";
import { usePartyFormHandler } from "../../../../../../hooks/partyform.hooks";
import { DealPartySearch } from "../../../../../deal/DealPartySearch";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import {
  LoanpassCollapseSection,
  LoanpassSection,
  LoanpassSectionsProps
} from "../LoanPassSections";

export const PartyInformation = (
  props: LoanpassSectionsProps<{ state: Party }>
) => {
  const { sectionBuilders } = props;
  const { selectedParty, setSelectedParty } = usePartyHooks();
  const { partyOnChange, partyState, syncPartyState } = usePartyFormHandler();
  const selectedPartyId = selectedParty ? selectedParty?.id : EMPTY;

  const partyOnChangeBatch = (v: OnChangeInput) => {
    selectedParty &&
      partyOnChange({
        fieldType: v.fieldType,
        fieldKey: ["parties", selectedParty.id, ...v.fieldKey],
        value: v.value
      });
  };

  useEffect(() => {
    selectedParty &&
      syncPartyState({
        shouldSync: !!selectedParty,
        state: selectedParty,
        statePath: () => {
          if (selectedParty) {
            return ["parties", selectedParty.id];
          }
        }
      });
  }, [selectedParty]);

  const section = sectionBuilders?.({
    state: partyState.parties[selectedPartyId],
    onChange: partyOnChangeBatch
  });

  return (
    <LoanpassCollapseSection
      shouldShow={props.shouldShow}
      title="Party Information"
      customBody={
        <LoanpassSection
          customKey="loanpass-party-sections"
          section={section}
          onChange={partyOnChangeBatch}
          searchComponent={
            <>
              <Tooltip
                key={`loanpass-party-sections`}
                label="If the desired party is not listed, ensure a Deal-Party role is added first via the Parties tab"
                placement="top-start"
                hasArrow
              >
                <Box>
                  <DealPartySearch
                    dealId={props.dealId}
                    entityTypes={[PartyType.Individual, PartyType.Entity]}
                    fieldType={FieldType.SingleSelect}
                    label={"Select Party"}
                    currentValue={selectedPartyId}
                    onSelect={setSelectedParty}
                  />
                </Box>
              </Tooltip>
            </>
          }
        />
      }
    />
  );
};
