import { includes, isEmpty, some } from "lodash";

export const checkIncludes = (
  list?: Object[],
  obj?: Object,
  op?: "in" | "not-in"
) => {
  if (!isEmpty(list)) {
    const match = includes(list, obj);
    if ((op === "in" && !match) || (op === "not-in" && match)) {
      return false;
    }
  }
  return true;
};

export const checkIncludesArray = (
  list?: Object[],
  dataList?: Object[],
  op?: "in" | "not-in"
) => {
  if (!isEmpty(list)) {
    const match = some(list, (item) => dataList?.includes(item));
    if ((op === "in" && !match) || (op === "not-in" && match)) {
      return false;
    }
  }
  return true;
};
