import { PasswordAllowedSpecialCharsRegex } from "../../../types/utils/regex";
import { allowedSpecialCharsList, MIN_PASSWORD_LENGTH } from "./constants";
import { PolicyRule } from "./types";

const lengthChecker = (password: string) => {
  return password.length >= MIN_PASSWORD_LENGTH;
};

const uppercaseChecker = (password: string) => {
  return password !== password.toLowerCase();
};

const specialCharsChecker = (password: string) => {
  return PasswordAllowedSpecialCharsRegex.test(password);
};

export const rules: PolicyRule[] = [
  {
    name: "Minimum Length",
    checker: lengthChecker,
    issueMessage: `Must be ${MIN_PASSWORD_LENGTH}+ characters`
  },
  {
    name: "Uppercase Letter",
    checker: uppercaseChecker,
    issueMessage: "Must have uppercase letter"
  },
  {
    name: "Special Character",
    checker: specialCharsChecker,
    issueMessage: `Must have special character: ${allowedSpecialCharsList}`
  }
];
