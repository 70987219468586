import { Button, ButtonProps, Text } from "@chakra-ui/react";
import { LOSUser } from "@elphi/types";
import ModalContainer from "../modal-container/ModalContainer";

export const UnderlineButton = (props: { text: string } & ButtonProps) => {
  return (
    <Button
      variant={"unstyled"}
      pointer={"cursor"}
      height={"unset"}
      _hover={{ textDecoration: "underline" }}
      {...props}
    >
      {props.text}
    </Button>
  );
};

export const buildMentionText = (replyTo: LOSUser) => {
  return JSON.stringify({
    ops: [
      {
        insert: {
          mention: {
            index: "0",
            denotationChar: "@",
            id: replyTo.id,
            value: `${replyTo.email},${replyTo.name}`
          }
        }
      },
      { insert: " \n" }
    ]
  });
};

export const DeleteCommentModal = (props: {
  isShow: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
}) => {
  return (
    <ModalContainer
      isShow={props.isShow}
      onCloseModal={props.onClose}
      header={"Delete message"}
      body={
        <Text fontWeight={"bold"} color={"red.500"}>
          Are you sure you want to delete this message?
        </Text>
      }
      submit={{
        showClose: true,
        onConfirm: props.onSubmit,
        isLoading: props.isLoading,
        confirmTitle: "Delete"
      }}
    />
  );
};
