export enum Status {
  Active = "active",
  Disabled = "disabled"
}

// this is the values from ELPHI_ENV
export enum ElphiEnv {
  Localhost = "localhost",
  Demo = "demo",
  ElphiTest = "elphi-test",
  LimaTest = "dev",
  LimaPoc = "poc",
  LimaProd = "prod",
  BabarTest = "test-babar",
  ElamiaTest = "test-elamia",
  EdomonTest = "test-edomon",
  MofinTest = "mofin-test",
  MofinProd = "mofin-prod",
  LedgerTCTest = "ledgertc-test",
  LedgerTCProd = "ledgertc-prod"
}

export enum SystemSetup {
  Lima = "lima",
  Mofin = "mofin",
  LedgerTC = "ledgertc",
  Elphi = "elphi"
}
