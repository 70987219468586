export enum ExceptionType {
  Deal = "Deal",
  Property = "Property",
  Party = "Party"
}

export enum ExceptionApprovedBy {
  SrOperationsAnalyst = "Sr. Operations Analyst",

  DirectorAssociatedWithDeal = "Director Associated With Deal",
  CreditReviewCommittee = "Credit Review Committee"
}
