import { Box, Button } from "@chakra-ui/react";
export type PortfolioAction = {
  themeStyle: { bgColor?: string; colorScheme?: string; variant?: string };
  onClick: () => void;
  label: string;
  isDisabled: boolean;
};
const PortfolioActions = (props: { actions: PortfolioAction[] }) => {
  return (
    <>
      {props.actions.map((a, index) => {
        return (
          <Box p="10px" key={index}>
            <Button
              {...a.themeStyle}
              onClick={() => a.onClick()}
              isDisabled={a.isDisabled}
            >
              {a.label}
            </Button>
          </Box>
        );
      })}
    </>
  );
};

export default PortfolioActions;
