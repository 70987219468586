import {
  DocumentPackageOrder,
  OrderStatusType,
  TimestampField
} from "@elphi/types";
import { WritableDraft } from "immer/dist/internal";
import { getTimestampFromFirestoreObject } from "../../../firebase/firebase.utils";

const SECOND_TO_MILLISECOND = 1000;
const MINUTE_TO_SECOND = 60;
const FIVE_MINUTES = 5;

export const timeDifferenceInMinutes = (
  documentPackageOrder: WritableDraft<DocumentPackageOrder> | undefined
) => {
  if (documentPackageOrder && documentPackageOrder?.modifiedAt) {
    const modifiedAtDate = getTimestampFromFirestoreObject(
      documentPackageOrder.modifiedAt as TimestampField
    )
      ?.toDate()
      ?.getTime();
    const nowDate = Math.floor(new Date().getTime());
    if (!!modifiedAtDate) {
      return Math.floor(
        (nowDate - modifiedAtDate) / SECOND_TO_MILLISECOND / MINUTE_TO_SECOND
      );
    }
  }
  return 0;
};

// retry order enabled if order failed or order processing for more than 5 minutes
export const isRetryOrderDisabled = (
  documentPackageOrder: WritableDraft<DocumentPackageOrder> | undefined
) => {
  if (documentPackageOrder) {
    if (documentPackageOrder?.order?.status === OrderStatusType.Processing) {
      return timeDifferenceInMinutes(documentPackageOrder) < FIVE_MINUTES;
    }
    if (documentPackageOrder?.order?.status === OrderStatusType.Failed) {
      return false;
    }
  }
  return true;
};
