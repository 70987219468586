// Need to use the React-specific entry point to allow generating React hooks
import { DocumentConfiguration } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { isString } from "lodash";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";
import { documentConfigurationEntityAdapter as entityAdapter } from "./documentConfiguration.adapter";

// Define a service using a base URL and expected endpoints
export const baseDocumentConfigurationApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "documentConfigurationApi",
  tagTypes: ["DocumentConfiguration", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "document-configuration"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter
  })
});

export const documentConfigurationApi =
  baseDocumentConfigurationApi.injectEndpoints({
    endpoints: (builder) => ({
      batchUpdate: builder.mutation<
        { batch: string[] },
        { documents: ({ id: string } & Partial<DocumentConfiguration>)[] }
      >({
        query: (r) => {
          return {
            url: `/update-batch`,
            method: "PUT",
            body: r
          };
        }
      }),
      search: builder.query<
        {
          results: EntityState<DocumentConfiguration>;
          hasMore: boolean;
          nextCursor: string | null;
          query: string;
        },
        { query: string; cursor?: string | null } | string
      >({
        query: (r) => {
          return {
            url: `/search?q=${isString(r) ? r : r.query}${
              !isString(r) && r.cursor ? `&cursor=${r.cursor}` : ""
            }`,
            method: "GET"
          };
        },
        transformResponse: (
          response: {
            results: DocumentConfiguration[];
            hasMore: boolean;
            nextCursor: string | null;
          },
          _,
          arg
        ) => {
          const adapter = entityAdapter.addMany(
            entityAdapter.getInitialState(),
            response.results
          );
          return {
            query: isString(arg) ? arg : arg.query,
            results: adapter,
            hasMore: response.hasMore,
            nextCursor: response.nextCursor
          };
        }
      }),
      getConfigurationBatch: builder.query<
        { configurations: EntityState<DocumentConfiguration> },
        string[]
      >({
        query: (ids) => {
          return {
            url: `/get-batch`,
            method: "POST",
            body: { ids }
          };
        },
        transformResponse: (response: { results: DocumentConfiguration[] }) => {
          const adapter = entityAdapter.addMany(
            entityAdapter.getInitialState(),
            response.results
          );
          return { configurations: adapter };
        }
      })
    })
  });
