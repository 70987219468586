import { AccountAsset, AssetType, BaseAsset, FieldType } from "@elphi/types";
import { baseAccountType } from "@elphi/types/entities/base";
import { baseEntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/baseEntity.fields";
import {
  EntityFormFieldSpecs,
  SpecsBaseOmit
} from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";

export type BaseSchemaAccountAssetFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<AccountAsset>
>;

export const assetFieldBaseSpecs: EntityFormFieldSpecs<
  SpecsBaseOmit<BaseAsset<AssetType>>
> = {
  ...baseEntityFormFieldSpecs,
  type: {
    fieldType: FieldType.SingleSelect,
    label: "Asset Type",
    fieldKey: ["type"],
    options: createOptionsFromMap(AssetType)
  },
  estimatedValueAmount: {
    fieldType: FieldType.Money,
    label: "Estimated Value",
    fieldKey: ["estimatedValueAmount"]
  },
  estimatedValueConsideredPercentage: {
    fieldType: FieldType.Percentage,
    label: "Estimated Value Percentage Considered",
    fieldKey: ["estimatedValueConsideredPercentage"]
  },
  appraisalValueAmount: {
    fieldType: FieldType.Money,
    label: "Appraisal Value",
    fieldKey: ["appraisalValueAmount"]
  },
  finalEstimatedValueAmount: {
    fieldType: FieldType.Money,
    label: "Final Estimated Value",
    fieldKey: ["finalEstimatedValueAmount"]
  },
  statements: {
    fieldType: FieldType.Array,
    label: "Attached Statements",
    fieldKey: ["statements"]
  }
};

export const baseSchemaAccountAssetFieldSpecs: BaseSchemaAccountAssetFieldSpecs =
  {
    ...assetFieldBaseSpecs,
    fullNameOnAccount: {
      fieldType: FieldType.String,
      label: "Name on Account",
      fieldKey: ["fullNameOnAccount"]
    },
    accountName: {
      fieldType: FieldType.String,
      label: "Account Name",
      fieldKey: ["accountName"]
    },
    accountType: {
      fieldType: FieldType.SingleSelect,
      label: "Account Type",
      fieldKey: ["accountType"],
      options: createOptionsFromMap(baseAccountType)
    },
    financialInstitution: {
      fieldType: FieldType.String,
      label: "Financial Institution",
      fieldKey: ["financialInstitution"]
    },
    last4Digits: {
      fieldType: FieldType.FourDigit,
      label: "Last 4 Digits",
      fieldKey: ["last4Digits"]
    }
  };

export const fullBaseSchemaAccountAssetFieldSpecs = createSpecWithFieldMeta({
  spec: baseSchemaAccountAssetFieldSpecs
});
