import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { ReactNode } from "react";

const HEADER_HEIGHT = "80px";

type TabsContainerProps = {
  tabs: TabProps[];
};

type TabProps = {
  label: string;
  page: ReactNode;
};

export const TabsContainer = (props: TabsContainerProps) => {
  const { tabs } = props;
  return (
    <Box h="100%" w="100%" overflow="hidden">
      <Tabs
        h="100%"
        w="100%"
        position="sticky"
        top="0"
        isLazy={true}
        lazyBehavior="unmount"
      >
        <TabList>
          {tabs.map((tab, index) => (
            <Tab key={index}>{tab.label}</Tab>
          ))}
        </TabList>
        <TabPanels h={`calc(100% - ${HEADER_HEIGHT})`} w="100%" p="0">
          {tabs.map((tab, index) => (
            <TabPanel key={index} h="100%" w="100%" p="0">
              {tab.page}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
