import { NotificationStatus, NotificationUser, Status } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { orderBy } from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { elphiInternalUsers } from "../components/audit-log/modal/list/eventList.utils";
import { RootState } from "../redux/store";
import { usePaginationHooksV2 } from "../redux/v2/hooks/pagination.hooks.v2";
import {
  notificationUserApi,
  notificationUserSlice,
  NotificationUserSliceState
} from "../redux/v2/notification-user";
import { FilterCombinationState } from "../redux/v2/types/stateWithFilter.types";
import { useFilter } from "./filter.hooks";
import { useLOSUserHooks } from "./losuser.hooks";

export const NOTIFICATION_USER_LIMIT = 10;

export const useNotificationUserHooks = () => {
  const notificationUserState = useSelector(
    (state: RootState) => state.notificationUser
  );

  const [toggleNotifyApi, toggleNotifyResponse] =
    notificationUserApi.useLazyToggleNotifyQuery();

  const [toggleStatusApi, toggleStatusResponse] =
    notificationUserApi.useLazyToggleStatusQuery();

  const [markAllAsReadApi, markAllAsReadResponse] =
    notificationUserApi.useLazyMarkAllAsReadQuery();

  return {
    notificationUserState,
    toggleNotifyResponse,
    toggleNotifyApi,
    toggleStatusApi,
    toggleStatusResponse,
    markAllAsReadApi,
    markAllAsReadResponse
  };
};

export const useNotificationFilterHooks = () => {
  const dispatch = useDispatch();
  const { losUserState, buildUserOption } = useLOSUserHooks();
  const { notificationUserState } = useNotificationUserHooks();
  const { setCombination, setFilterCombinationDetails } = useFilter<
    NotificationUser,
    NotificationUserSliceState
  >(notificationUserState);

  const setCurrentFilter = (filter: {
    values: NotificationUserSliceState["filters"]["current"];
  }) => {
    setCombination(
      filter.values,
      notificationUserSlice.actions.setFilterAndCombination
    );
  };

  const resetFiltersCombinations = () => {
    dispatch(notificationUserSlice.actions.resetFilters());
  };

  const currentCombination = useSelector((state: RootState) => {
    const filters = state.notificationUser.filters;
    return filters.combinations[filters.currentCombination];
  });

  const currentFilter = useSelector(
    (state: RootState) => state.notificationUser.filters.current
  );

  const unreadFilter = new Set([
    NotificationStatus.UNSEEN_UNREAD,
    NotificationStatus.SEEN_UNREAD
  ]);
  const filteredItems = useMemo(() => {
    const filterDealIds = new Set(currentFilter.dealIds);
    const filterUsersIds = new Set(currentFilter.createdUID);
    const filterFieldPaths = new Set(currentFilter.fieldPath);
    const filterAuditTypes = new Set(currentFilter.auditType);
    const filterEntityType = currentFilter.entityType?.at(0);
    const filterUnread = currentFilter.unread?.at(0) === Status.Active;

    const filtered = notificationUserState.ids
      .map((id) => {
        const item = notificationUserState.entities[id];
        if (!item) {
          return undefined;
        }
        const {
          dealIds,
          createdUID,
          fieldPath,
          fieldType,
          entityType,
          status
        } = item;

        if (
          (filterDealIds.size === 0 ||
            dealIds?.some((x) => filterDealIds.has(x))) &&
          (filterUsersIds.size === 0 ||
            (createdUID && filterUsersIds.has(createdUID))) &&
          (filterFieldPaths.size === 0 ||
            (fieldPath && filterFieldPaths.has(fieldPath))) &&
          (filterAuditTypes.size === 0 ||
            (fieldType && filterAuditTypes.has(fieldType))) &&
          (!filterEntityType || entityType === filterEntityType) &&
          (!filterUnread || unreadFilter.has(status))
        ) {
          return item;
        }

        return undefined;
      })
      .filter(removeEmpty);

    return orderBy(filtered, (item) => item.index, "desc");
  }, [
    currentFilter.dealIds,
    currentFilter.createdUID,
    currentFilter.fieldPath,
    currentFilter.auditType,
    currentFilter.entityType,
    currentFilter.unread,
    notificationUserState.entities
  ]);

  useEffect(() => {
    setFilterCombinationDetails(
      { ids: filteredItems.map((x) => x.id) },
      notificationUserSlice.actions.setFilterCombinationDetails
    );
  }, [filteredItems]);

  const setPagingInfo = (info: Partial<FilterCombinationState[string]>) => {
    setFilterCombinationDetails(
      info,
      notificationUserSlice.actions.setFilterCombinationDetails
    );
  };

  const userOptions = useMemo(() => {
    return elphiInternalUsers.concat(
      Object.values(losUserState.entities).map(buildUserOption)
    );
  }, [losUserState.entities]);

  return {
    setCurrentFilter,
    currentFilter,
    currentCombination,
    setPagingInfo,
    resetFiltersCombinations,
    userOptions
  };
};

export const useNotificationTableHooks = () => {
  const { currentCombination, setPagingInfo, currentFilter } =
    useNotificationFilterHooks();
  const { nextPage, paginateApiResponse, hasMore } = usePaginationHooksV2({
    paginationProvider: notificationUserApi.useLazyPaginateV2Query,
    limit: NOTIFICATION_USER_LIMIT,
    input: {
      dealIds: currentFilter.dealIds,
      auditTypes: currentFilter.auditType,
      entityType: currentFilter?.entityType?.at(0),
      fieldPaths: currentFilter.fieldPath,
      userIds: currentFilter.createdUID,
      unread: currentFilter?.unread?.at(0)
    },
    currentCursor: currentCombination.cursor,
    setHasMore: async (hasMore) => {
      setPagingInfo({
        hasMore
      });
    },
    setCursor: (cursor) => {
      setPagingInfo({
        cursor
      });
    }
  });

  return {
    nextPage,
    paginateApiResponse,
    currentCombination,
    hasMore
  };
};
