export const getListFromDictionary = <
  T extends Partial<{ [id: string]: { id: string } }>
>(
  d: T
) => {
  return (
    Object.keys(d)
      .map((id) => {
        if (d) {
          return {
            ...d[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined) || []
  );
};
