import { ElphiEntityType } from "@elphi/types";
import {
  multiSelectValidation,
  percentValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const ownershipPercentage = buildInput({
  fieldKey:
    baseElphiFieldsSpecs[ElphiEntityType.partyRelation].specsParts
      ?.entityToEntityRelationFieldSpecs.ownershipPercentage?.fieldKey,
  validation: percentValidation
});
export const partyRelationRoleType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs[ElphiEntityType.partyRelation].specsParts
      ?.entityToEntityRelationFieldSpecs.relationRoleType?.fieldKey,
  validation: multiSelectValidation
});
