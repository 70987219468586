import { TabList, TabPanels, Tabs } from "@chakra-ui/react";
import { FieldType, ServiceProviderType } from "@elphi/types";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { EMPTY } from "../../../../../../constants/common";
import { useServiceProviderHooks } from "../../../../../../hooks/rolodexServiceProvider.hooks";
import { ServiceProviderSearch } from "../../search/ServiceProviderSearch";
import { FormPanel, StyledTab } from "./ProvidersFormStepTab";
import { useProviderFormStepHooks } from "./providersFormStep.hooks";
import { ProvidersFormStepContentProps } from "./providersFormStep.types";
import {
  branchServiceProviderFields,
  companyServiceProviderFields,
  getRepFullName,
  repServiceProviderFields,
  sanitizeSpace
} from "./providersFormStep.utils";

export const ProvidersFormStepContent = (
  props: ProvidersFormStepContentProps
) => {
  const {
    companyForm,
    branchForm,
    repForm,
    onFormValidation,
    onTabChange,
    selectedTab,
    showAttached
  } = props;
  const [skipRepForm, setSkipRepForm] = useState<boolean>(
    repForm.state?.skipped || false
  );
  const [query, setQuery] = useState<string>(EMPTY);
  const { serviceProviderState } = useServiceProviderHooks();
  const companyStep = useProviderFormStepHooks({
    ...companyForm,
    isReadOnly: !companyForm.selectedProviderId,
    showAttached
  });
  const branchStep = useProviderFormStepHooks({
    ...branchForm,
    isReadOnly: !branchForm.selectedProviderId,
    showAttached
  });
  const repStep = useProviderFormStepHooks({
    ...repForm,
    isReadOnly: !repForm.selectedProviderId || skipRepForm,
    showAttached
  });

  useEffect(() => {
    onFormValidation(
      companyStep.isStepValid &&
        branchStep.isStepValid &&
        (repStep.isStepValid || skipRepForm)
    );
  }, [
    companyStep.isStepValid,
    branchStep.isStepValid,
    repStep.isStepValid,
    skipRepForm
  ]);

  const handleOnSelectCompany = (id: string) => {
    const selectedCompany = serviceProviderState?.entities?.[id];
    if (selectedCompany?.type === ServiceProviderType.Company) {
      companyForm.setState({
        ...companyForm.state,
        ...pick(selectedCompany, ["id", ...companyServiceProviderFields])
      });
    }
    if (id && !selectedCompany) {
      companyForm.setState({
        name: sanitizeSpace(query),
        ...pick(companyForm.state, ["type", "domainConfigurationId"])
      });
    }
    if (!id && !selectedCompany) {
      companyForm.setState(
        pick(companyForm.state, ["type", "domainConfigurationId"])
      );
    }
    handleOnSelectBranch(EMPTY);
    handleOnSelectRep(EMPTY);
    companyForm.setSelectedProviderId(id);
  };

  const handleOnSelectBranch = (id: string) => {
    const selectedBranch = serviceProviderState?.entities?.[id];
    if (selectedBranch?.type === ServiceProviderType.Branch) {
      branchForm.setState({
        ...branchForm.state,
        ...pick(selectedBranch, ["id", ...branchServiceProviderFields])
      });
    }
    if (id && !selectedBranch) {
      branchForm.setState({
        name: sanitizeSpace(query),
        ...pick(branchForm.state, "type")
      });
    }
    if (!id && !selectedBranch) {
      setSkipRepForm(false);
      branchForm.setState(pick(branchForm.state, "type"));
      handleOnSelectRep(EMPTY);
    }
    branchForm.setSelectedProviderId(id);
  };

  const handleOnSelectRep = (id: string) => {
    const selectedRep = serviceProviderState?.entities?.[id];
    if (selectedRep?.type === ServiceProviderType.Representative) {
      repForm.setState({
        ...repForm.state,
        ...pick(selectedRep, ["id", ...repServiceProviderFields])
      });
      if (selectedRep?.skipped) {
        handleOnCheckSkipRepForm(selectedRep.skipped);
      }
    }
    if (id && !selectedRep) {
      repForm.setState({
        ...getRepFullName(query),
        ...pick(repForm.state, "type")
      });
    }
    if (!id && !selectedRep) {
      repForm.setState(pick(repForm.state, "type"));
    }
    repForm.setSelectedProviderId(id);
  };

  const handleOnCheckSkipRepForm = (checked: boolean) => {
    setSkipRepForm(checked);
    if (checked) {
      repForm.setState({ ...pick(repForm.state, "type"), skipped: checked });
      repForm.setSelectedProviderId(EMPTY);
    }
  };

  const handleOnQueryChange = (q: string) => {
    setQuery(q);
  };

  return (
    <Tabs
      index={selectedTab}
      onChange={onTabChange}
      orientation={"vertical"}
      variant={"unstyled"}
      isLazy={true}
      lazyBehavior={"unmount"}
    >
      <TabList>
        <StyledTab
          title={"Company"}
          isDisabled={false}
          isValid={companyStep.isStepValid}
          showDivider={true}
        />
        <StyledTab
          title={"Branch"}
          isDisabled={!companyStep.isStepValid}
          isValid={branchStep.isStepValid}
          showDivider={true}
          tooltipLabel={"Please fill out the required fields for Company first"}
        />
        <StyledTab
          title={"Representative"}
          isDisabled={!companyStep.isStepValid || !branchStep.isStepValid}
          tooltipLabel={
            "Please fill out the required fields for Company and Branch first"
          }
        />
      </TabList>
      <TabPanels>
        <FormPanel
          show={!!companyForm.selectedProviderId}
          contentHeader={"Company Information"}
          searchHeader={"Add Company"}
          formKey={"companyForm"}
          formOnChange={companyForm.onChangeState}
          formSection={companyStep.formSection}
        >
          <ServiceProviderSearch
            query={query}
            onInputChange={handleOnQueryChange}
            isReadOnly={companyForm.isReadOnly}
            label={"Search Company"}
            fieldType={FieldType.SingleSelect}
            currentValue={companyForm.selectedProviderId}
            onSelect={handleOnSelectCompany}
            type={ServiceProviderType.Company}
            filterOptions={(r) =>
              r.type === ServiceProviderType.Company &&
              r.domainConfigurationId ===
                companyForm?.state?.domainConfigurationId
            }
            params={{
              domainConfigurationId:
                companyForm?.state?.domainConfigurationId || EMPTY
            }}
          />
        </FormPanel>
        <FormPanel
          show={!!branchForm.selectedProviderId}
          contentHeader={"Branch Information"}
          searchHeader={"Add Branch"}
          formKey={"branchForm"}
          formOnChange={branchForm.onChangeState}
          formSection={branchStep.formSection}
        >
          <ServiceProviderSearch
            query={query}
            onInputChange={handleOnQueryChange}
            isReadOnly={branchForm.isReadOnly}
            label={"Search Branch"}
            fieldType={FieldType.SingleSelect}
            currentValue={branchForm.selectedProviderId}
            onSelect={handleOnSelectBranch}
            type={ServiceProviderType.Branch}
            filterOptions={(r) =>
              r.type === ServiceProviderType.Branch &&
              r.companyId === companyForm.selectedProviderId
            }
            params={{
              companyId: companyForm.selectedProviderId
            }}
          />
        </FormPanel>
        <FormPanel
          show={!!repForm.selectedProviderId}
          contentHeader={"Representative Information"}
          searchHeader={"Add Representative"}
          formKey={"repForm"}
          formOnChange={repForm.onChangeState}
          formSection={repStep.formSection}
          skip={{
            title: "Check if there is no representative",
            onCheck: handleOnCheckSkipRepForm,
            checked: skipRepForm,
            readOnly: !!repForm.isReadOnly
          }}
        >
          <ServiceProviderSearch
            query={query}
            onInputChange={handleOnQueryChange}
            isReadOnly={repForm.isReadOnly || skipRepForm}
            label={"Search Representative"}
            fieldType={FieldType.SingleSelect}
            currentValue={repForm.selectedProviderId}
            onSelect={handleOnSelectRep}
            type={ServiceProviderType.Representative}
            filterOptions={(r) =>
              r.type === ServiceProviderType.Representative &&
              r.companyId === companyForm.selectedProviderId
            }
            params={{
              companyId: companyForm.selectedProviderId
            }}
          />
        </FormPanel>
      </TabPanels>
    </Tabs>
  );
};
