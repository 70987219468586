import { Flex } from "@chakra-ui/react";
import { ElphiElephantWalkingNoseThrow } from "./ElphiElephantWalkingNoseThrow";

export const ElphiLoader = () => {
  return (
    <Flex
      zIndex={1000}
      position="absolute"
      right="50%"
      top={"50%"}
      justifyContent="center"
      content=""
      transform="translate(50%, -50%)"
    >
      <ElphiElephantWalkingNoseThrow />
    </Flex>
  );
};
