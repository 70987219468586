export const baseAccountType = {
  Checking: "Checking",
  Savings: "Savings",
  MoneyMarket: "Money Market",
  InvestmentAccount: "Investment Account",
  Annuity: "Annuity",
  IRA: "IRA",
  N401K: "401(k)",
  TrustAccount: "Trust Account",
  CashOutProceeds: "Cash-Out Proceeds",
  EarnestMoneyDeposit: "Earnest Money Deposit",
  Other: "Other"
} as const;
