import LogRocket from "logrocket";
import { createContext, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { auth } from "../firebase/firebaseConfig";
import { useLOSUserHooks } from "../hooks/losuser.hooks";
import { useOrgHooks } from "../hooks/org.hooks";
import { resetUserData, updateUserData } from "../redux/actions";

const authContext = createContext<any>(undefined); //TODO: type context

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
const useProvideAuth = (props) => {
  const { getLOSUser } = useLOSUserHooks();
  const { selectedOrgId } = useOrgHooks();

  // Wrap any Firebase methods we want to use making sure to save the user to state.
  const signin = (email, password) => {
    return new Promise((resolve, reject) => {
      return auth
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          if (!response.user) {
            throw "user not found";
          } else {
            // setUser(response.user);
            resolve(response.user);
          }
        })
        .catch((error) => {
          console.log("signin: ", error);
          reject(error);
        });
    });
  };
  const signup = (email, password) => {
    return new Promise((resolve, reject) => {
      return auth
        .createUserWithEmailAndPassword(email, password)
        .then((response) => {
          // setUser(response.user);
          resolve(response.user);
        })
        .catch((error) => {
          console.log("signup: ", error);
          reject(error);
        });
    });
  };
  const signout = () => {
    return new Promise((resolve, reject) => {
      return auth
        .signOut()
        .then(() => {
          // setUser(false);
          resolve(true);
        })
        .catch((error) => {
          console.log("signout: ", error);
          reject(error);
        });
    });
  };
  const sendPasswordResetEmail = (email) => {
    return new Promise((resolve, reject) => {
      return auth
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.log("sendPasswordResetEmail: ", error);
          reject(error);
        });
    });
  };
  const confirmPasswordReset = (code, password) => {
    return new Promise((resolve, reject) => {
      return auth
        .confirmPasswordReset(code, password)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.log("confirmPasswordReset: ", error);
          reject(error);
        });
    });
  };
  // Subscribe to user on mount. Because this sets state in the callback it will cause any
  //    component that utilizes this hook to re-render with the latest auth object.

  useEffect(() => {
    auth.currentUser &&
      getLOSUser(auth.currentUser.uid, true).then((r) => {
        if (r.isSuccess) {
          props.updateUserData({
            ...r.data
          });
        }
      });
  }, [selectedOrgId, auth.currentUser]);
  useEffect(() => {
    try {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user?.email) {
          LogRocket.identify(user.uid, {
            uid: user.uid,
            email: user.email
            /**
             * when name is not present, logrocket presents the title as email and subtitle as id (preferred)
             * when name is present, title is the name, subtitle is the email
             */
            // name: user.displayName || "missing-user-name"
          });
          // setUser(user);
          getLOSUser(user.uid, true).then((r) => {
            if (r.isSuccess) {
              props.updateUserData({
                ...r.data
              });
            }
          });
        } else {
          // setUser(false);
          props.resetUserData();
        }
      });
      // Cleanup subscription on unmount
      return () => {
        console.log("Goodbye from authUtils");
        unsubscribe();
        console.log("unsubscribe authUtils success");
      };
    } catch (err) {
      console.log("authUtils useEffect error: ", err);
    }
  }, []);
  // Return the user object and auth methods
  return {
    // user,
    instance: auth,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
};

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
const ProvideAuth = (props) => {
  const auth = useProvideAuth(props);
  return (
    <authContext.Provider value={auth}>{props.children}</authContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (userData) => {
    dispatch(updateUserData(userData));
  },
  resetUserData: () => {
    dispatch(resetUserData());
  }
});

// Connect redux to auth provider to update user data
export default connect(mapStateToProps, mapDispatchToProps)(ProvideAuth);
