import { BaseEntity } from "../../db";
import { ElphiEntityType } from "../elphiEntities.types";

export enum ElphiFieldMetadataType {
  Number = "number",
  String = "string",
  Aggregation = "aggregation",
  Array = "array",
  Enum = "enum"
}

export enum ElphiFieldMetadataStatus {
  ACTIVE = "active",
  INACTIVE = "inactive"
}

export type ElphiFieldMetadata = BaseEntity<{
  originalKey: string;
  camelCaseKey: string;
  label: string;
  type: ElphiFieldMetadataType;
  entityType: ElphiEntityType;
  status: ElphiFieldMetadataStatus;
  configuration: Partial<{
    enumerations: { in: ElphiFieldEnumeration[]; out: ElphiFieldEnumeration[] };
    mapping: { in: ElphiFieldMapping; out: ElphiFieldMapping };
  }>;
}>;

export type ElphiFieldEnumeration = { id: string; name: string };

export type ElphiFieldMapping = Record<string, string>;
