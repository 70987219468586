import {
  CompanyServiceProvider,
  FieldType,
  ServiceProviderEntityType,
  Status
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { DomainStepContent } from "../../../../../rolodex/service-provider/wizard/steps/DomainStepContent";

const domainConfigurationIdPath: DotNestedKeys<CompanyServiceProvider> =
  "domainConfigurationId";

type DomainConfigurationStepProps = {
  onSelect: () => void;
  currentConfigurationId?: string;
  onChange: (v: OnChangeInput) => void;
};

export const DomainConfigurationStep = (
  props: DomainConfigurationStepProps
) => {
  const { onSelect, onChange, currentConfigurationId } = props;

  const handleSelect = (id) => {
    if (currentConfigurationId && id !== currentConfigurationId) {
      onSelect();
    }
    onChange({
      fieldKey: [domainConfigurationIdPath],
      value: id,
      fieldType: FieldType.String
    });
  };
  return (
    <DomainStepContent
      filter={(x) =>
        x.status === Status.Active &&
        x.entityType === ServiceProviderEntityType.InsurancePolicy
      }
      onSelect={handleSelect}
    />
  );
};
