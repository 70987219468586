import {
  DocumentPackageOrder,
  DocumentPackageType,
  OrderStatusType
} from "../documentPackageOrder.types";

export const getDocumentPackageOrderTemplate = (type: DocumentPackageType) => {
  const order: DocumentPackageOrder = {
    type: type,
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    order: {
      items: {},
      status: "" as OrderStatusType
    },
    dealId: "",
    userId: ""
  };
  return order;
};
