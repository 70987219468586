export enum ServiceProviderType {
  Company = "company",
  Branch = "branch",
  Representative = "representative"
}

export enum ServiceProviderEntityType {
  Deal = "deal",
  Party = "party",
  Property = "property",
  InsurancePolicy = "insurancePolicy"
  //Asset = "asset",
  //Task = "task"
}

export enum ServiceProviderStatusType {
  Active = "active",
  Prohibited = "prohibited",
  Unresponsive = "unresponsive",
  Closed = "closed"
}

export enum ServiceProviderLendingPartnerType {
  Broker = "broker",
  Wholesale = "wholesale",
  CorrespondantLender = "correspondantLender"
}
