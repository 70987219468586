export enum SwaggerEnvType {
  LOCAL = "local",
  DEMO = "demo",
  DEVELOP = "develop",
  RELEASE = "release",
  PROD = "prod"
}

export type Swagger = {
  swagger: string;
  info: {
    version: string;
    title: string;
    description: string;
  };
  host: string;
  basePath: string;
  tags: object[];
  schemes: string[];
  securityDefinitions: { JWT: object };
  consumes: string[];
  produces: string[];
  paths: {
    [route: string]: object;
  };
  components: { schemas: SwaggerDefinition };
  security: { bearerAuth: [] };
};

export type SwaggerDefinition = {
  OrgModel: object;
  GroupModel: object;
  TeamModel: object;
  DealModel: object;
  DealPartyModel: object;
  DealIndividualPartyModel: object;
  DealEntityPartyModel: object;
  DealPropertyModel: object;
  PartyRelationModel: object;
  IndividualPartyModel: object;
  EntityPartyModel: object;
  PartyModel: object;
  PartyGroupModel: object;
  PartyGroupUpdate: object;
  PartyGroupBatchUpdate: object;
  PartyGroupCount: object;
  PartyValidationConfigurationModel: object;
  PropertyModel: object;
  TaskModel: object;
  LOSUserModel: object;
  DealUserModel: object;
  PartyUserModel: object;
  DocumentConfigurationModel: object;
  RuleModel: object;
  TaskTemplateModel: object;
  TaskConfigurationModel: object;
  DocumentMetadataModel: object;
  DocumentOrderItemModel: object;
  DocumentPackageOrderModel: object;
  AssetModel: object;
  StatementModel: object;
  PartyAssetModel: object;
  SnapshotModel: object;
  SnapshotDataModel: object;
  DealQuoteModel: object;
  CreateSiteXRequestParams: object;
  DealQuoteUpdateRequest: object;
  DealQuoteSetRateLockRequest: object;
  DealQuoteSetQuoteIdRequest: object;
  CreditReportModel: object;
  UpdateIndividualPartyRequest: object;
  CreateIndividualPartyRequest: object;
  UpdateEntityPartyRequest: object;
  CreateEntityPartyRequest: object;
  AddAssetToPartyRequest: object;
  AddAssetToPartyResponse: object;
  AddStatementToAssetRequest: object;
  AddStatementToAssetResponse: object;
  CreatePartyRequest: object;
  UpdatePartyRequest: object;
  CreateDealQuoteRequest: object;
  UpdateDealQuoteRequest: object;
  CreditReportIntegrationPOSTRequest: object;
  IdsModel: object;
  SetUserStatusRequest: object;
  UpdateSelfLOSUserStatusRequest: object;
  UserAssignmentFullReplaceRequest: object;
  BasePaginationRequest: object;
  CrossOrgsPaginationRequest: object;
  CrossOrgsPageResponse: object;
  AddChildRequest: object;
  PaginateEnhanceRequest: object;
  GenerateDocumentFromTemplateRequest: object;
  RoleModel: object;
  ServiceLinkFloodCreateRequest: object;
  ServiceLinkFloodCreateResponse: object;
  ServiceLinkFloodWebhookCompleteRequest: object;
  ServiceLinkFloodWebhookDelayRequest: object;
  ServiceLinkFloodWebhookResponse: object;
  CloneConfigurationRequest: object;
  RolodexServiceProviderViewModel: object;
  RolodexServiceProviderCreateModel: object;
  RolodexServiceProviderUpdateModel: object;
  RolodexServiceProviderUpsertRequest: object;
  RolodexBranchRepRelationCreateModel: object;
  RolodexBranchRepRelationUpdateModel: object;
  RolodexConfigurationCreateModel: object;
  RolodexConfigurationUpdateModel: object;
};
