export enum RowLabelsType {
  City = "City",
  County = "County",
  SpecialAssessment = "Special Assessment"
}

export type RowLabelsObjectType = {
  [K in keyof typeof RowLabelsType]: { index: number; label: string };
};

export enum ColumnLabelsType {
  AnnualTaxAmount = "Annual Tax Amount",
  InitialTaxEscrowMonthCount = "Initial Tax Escrow Month Count",
  InitialTaxEscrowAmount = "Initial Tax Escrow Amount",
  TaxDueDate = "Tax Due Date",
  TaxBillingFrequencyType = "Tax Billing Frequency",
  TaxingAuthority = "Taxing Authority"
}

export type ColumnLabelObject = {
  [K in keyof typeof ColumnLabelsType]: { index: number; label: string };
};

export const RowLabels: RowLabelsObjectType = {
  City: {
    index: 0,
    label: RowLabelsType.City
  },
  County: {
    index: 1,
    label: RowLabelsType.County
  },
  SpecialAssessment: {
    index: 2,
    label: RowLabelsType.SpecialAssessment
  }
};

export const ColumnLabels: ColumnLabelObject = {
  AnnualTaxAmount: {
    index: 0,
    label: ColumnLabelsType.AnnualTaxAmount
  },
  InitialTaxEscrowMonthCount: {
    index: 1,
    label: ColumnLabelsType.InitialTaxEscrowMonthCount
  },
  InitialTaxEscrowAmount: {
    index: 2,
    label: ColumnLabelsType.InitialTaxEscrowAmount
  },
  TaxDueDate: {
    index: 3,
    label: ColumnLabelsType.TaxDueDate
  },
  TaxBillingFrequencyType: {
    index: 4,
    label: ColumnLabelsType.TaxBillingFrequencyType
  },
  TaxingAuthority: {
    index: 5,
    label: ColumnLabelsType.TaxingAuthority
  }
};

export const getKeysAndLabels = (e: {
  [x: string]: { index: number; label: string };
}) => {
  const keys: string[] = [];
  const labels: string[] = [];
  Object.entries(e)
    .sort((a, b) => (a[1].index > b[1].index ? 1 : -1))
    .map(([key, value]) => {
      keys.push(key);
      labels.push(value.label);
    });
  return [keys, labels];
};
