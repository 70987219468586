import { Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";

export const OmitUpdateSchema = <
  T extends ReturnType<typeof Type.Object | typeof Type.Intersect>
>(
  t: T
) =>
  Type.Intersect([
    Type.Required(Type.Pick(BaseEntitySchema, ["id"])),
    Type.Partial(t)
  ]);

export const OmitCreateSchema = <
  T extends ReturnType<typeof Type.Object | typeof Type.Intersect>
>(
  t: T
) => Type.Omit(Type.Partial(t), Type.KeyOf(BaseEntitySchema));
