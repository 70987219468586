import {
  DocumentPackageOrder,
  DocumentPackageType,
  OrderStatusType,
  getDocumentPackageOrderTemplate
} from "@elphi/types";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RootState, store } from "../redux/store";
import { documentPackageOrderApi } from "../redux/v2/document-package-order/documentPackageOrder.service";
import { documentPackageOrderSlice } from "../redux/v2/document-package-order/documentPackageOrder.slice";

export const useDocumentPackageOrderHooks = () => {
  const [createPackageOrderApi, createPackageOrderApiResponse] =
    documentPackageOrderApi.useCreateMutation();
  const [updatePackageOrderApi, updatePackageOrderApiResponse] =
    documentPackageOrderApi.useUpdateMutation();
  const [updateBatchApi, updateBatchResponse] =
    documentPackageOrderApi.useBatchUpdateMutation();
  const [getEntitiesDataApi, getEntitiesDataResponse] =
    documentPackageOrderApi.useGetEntitiesDataMutation();
  const [deletePackageOrderApi, deletePackageOrderResponse] =
    documentPackageOrderApi.useDeleteMutation();
  const createPackageOrder = async (r: {
    type: DocumentPackageType;
    dealId: string;
    configurationIds: string[];
  }) => {
    const order = getDocumentPackageOrderTemplate(r.type);
    const items = r.configurationIds.reduce((p, v) => {
      p[v] = {
        id: v
      };
      return p;
    }, {});
    order.order.items = items;
    order.dealId = r.dealId;
    const response = await createPackageOrderApi(order);
    return responseHandler(response);
  };
  const updatePackageOrder = async (r: { id: string }) => {
    const order = {
      id: r.id,
      order: {
        status: OrderStatusType.Pending
      }
    };
    const response = await updatePackageOrderApi(
      order as {
        id: string;
      } & Partial<DocumentPackageOrder>
    );
    return responseHandler(response);
  };
  const deletePackageOrder = async (id: string) => {
    return await deletePackageOrderApi(id).then(responseHandler);
  };
  const updateBatch = async (r: {
    orders: ({ id: string } & Partial<DocumentPackageOrder>)[];
  }) => {
    return await updateBatchApi(r).then(responseHandler);
  };
  const getEntitiesData = async (r: {
    orders: ({ id: string } & Partial<DocumentPackageOrder>)[];
  }) => {
    return await getEntitiesDataApi(r).then(responseHandler);
  };
  const setSelectedDocumentPackageOrder = (id: string) =>
    store.dispatch(documentPackageOrderSlice.actions.selectedId({ id }));

  const selectedDocumentPackageOrder = useSelector(
    (state: RootState) =>
      state.documentPackageOrder.selectedId &&
      state.documentPackageOrder.entities[state.documentPackageOrder.selectedId]
  );
  const documentPackageOrderState = useSelector(
    (state: RootState) => state.documentPackageOrder
  );

  return {
    documentPackageOrderState,
    selectedDocumentPackageOrder,
    setSelectedDocumentPackageOrder,
    deletePackageOrder,
    deletePackageOrderResponse,
    createPackageOrder,
    createPackageOrderApiResponse,
    updatePackageOrder,
    updatePackageOrderApiResponse,
    updateBatch,
    updateBatchResponse,
    getEntitiesData,
    getEntitiesDataResponse
  };
};
