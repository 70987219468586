import { FieldType } from "@elphi/types";
import {
  BaseCommonPartyFieldsOmit,
  MofinBasePartyFields
} from "@elphi/types/entities/mofin/party";
import {
  mofinEntityType,
  mofinOperationsDepartment
} from "@elphi/types/entities/mofin/party/mofinParty.enumeration";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import {
  booleanOptions,
  createOptionsFromMap
} from "../../../../../components/utils/formUtils";
import {
  BaseSchemaCommonPartyFieldSpecs,
  baseSchemaCommonPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import {
  mergeSpecs,
  modifyFromSpec,
  omitFromSpec
} from "../../../utils/formsSpec.utils";

type MofinCommonPartyFieldSpecs = EntityFormFieldSpecs<MofinBasePartyFields>;

export type FullMofinCommonPartyFieldSpecs =
  Partial<BaseSchemaCommonPartyFieldSpecs> & MofinCommonPartyFieldSpecs;

const mofinCommonPartyFieldSpecs: MofinCommonPartyFieldSpecs = {
  MortgageLatePaymentsIndicator: {
    fieldKey: ["MortgageLatePaymentsIndicator"],
    fieldType: FieldType.Boolean,
    label: "Mortgage Late Payments?",
    options: booleanOptions
  },
  OperationsDepartment: modifyFromSpec({
    fieldSpecs: baseSchemaCommonPartyFieldSpecs.OperationsDepartment,
    changes: { options: createOptionsFromMap(mofinOperationsDepartment) }
  }),
  EntityType: {
    fieldKey: ["EntityType"],
    fieldType: FieldType.SingleSelect,
    label: "Entity Type",
    options: createOptionsFromMap(mofinEntityType)
  }
};

export const mofinPartyPathsToOmitFromBase: BaseCommonPartyFieldsOmit = [
  "OperationsDepartment",
  "EntityType"
];

export const mofinCommonPartySpecs: FullMofinCommonPartyFieldSpecs = mergeSpecs(
  omitFromSpec({
    obj: baseSchemaCommonPartyFieldSpecs,
    paths: mofinPartyPathsToOmitFromBase
  }),
  mofinCommonPartyFieldSpecs
);
