import { createIcon } from "@chakra-ui/react";

const customFilterIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 28 28",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.66666 4.66669L10.5 14V21L17.5 24.5V14L23.3333 4.66669H4.66666Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const FilterIcon = customFilterIcon();
