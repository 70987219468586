import { NotificationAuditEvent } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { notificationUserSlice } from "./notificationUser.slice";

export const notificationUserAction: ActionDispatcher<
  NotificationAuditEvent
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(notificationUserSlice);
  return actionDispatcher(dispatch, callback);
};
