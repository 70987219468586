import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Tooltip } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { DebounceIconButton } from "../../components/custom/chakra/DebounceIconButton";
import navigationHandler from "../../components/utils/navigationUtils";
import { useNavigationHooks } from "../../hooks/navigation.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";
export const ExpandAllButton = (props: {
  navigationPath: NavigationPath | string;
}) => {
  const { setExpandAll, setCollapseAll, navigationState } =
    useNavigationHooks();
  const [showTooltip, setShowTooltip] = useState(false);
  const { getObjectAsDictionary } = navigationHandler();
  const navigationPathDictionary = getObjectAsDictionary(props.navigationPath);
  const { page, tab } = navigationPathDictionary;
  const isAllExpanded = navigationState?.isAllExpanded?.[page]?.[tab] || false;

  const handleExpandAll = useCallback(() => {
    if (!isAllExpanded) {
      setExpandAll({ navigationPathDictionary });
    } else {
      setCollapseAll({ navigationPathDictionary });
    }
  }, [isAllExpanded, navigationPathDictionary, setExpandAll, setCollapseAll]);

  useEffect(() => {
    const handleScroll = () => {
      setShowTooltip(false);
    };
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return (
    <Box p="5px">
      <Tooltip
        label={isAllExpanded ? "Collapse All" : "Expand All"}
        placement="right"
        isOpen={showTooltip}
      >
        <DebounceIconButton
          size="sm"
          aria-label={isAllExpanded ? "collapse-all" : "expand-all"}
          icon={<ChevronDownIcon />}
          transition="all 0.3s ease-out"
          transform={isAllExpanded ? "rotate(180deg)" : ""}
          bgColor="transparent"
          onClick={handleExpandAll}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        />
      </Tooltip>
    </Box>
  );
};
