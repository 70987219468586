import { Flex } from "@chakra-ui/react";
import { FieldType, TaskStatusType, statusToColor } from "@elphi/types";
import { OnChangeInput } from "../form-builder/FormBuilder";
import StyledInputBuilder from "../form-builder/InputBuilder";
import { TaskFormState } from "../task/TaskForm";
import { createOptionsFromMap } from "../utils/formUtils";

type TaskStatusDropdownProps = {
  state: TaskFormState;
  taskId: string;
  isDisabled?: boolean;
  onChange: (v: OnChangeInput) => void;
};

export const TaskStatusDropdown = (props: TaskStatusDropdownProps) => {
  const currentValue = props.state.tasks?.[props.taskId]?.taskStatus
    ? props.state.tasks?.[props.taskId]?.taskStatus
    : TaskStatusType.Created;

  return (
    <Flex>
      <StyledInputBuilder
        label="Status"
        isDisabled={props.isDisabled}
        currentValue={
          props.state.tasks?.[props.taskId]?.taskStatus
            ? props.state.tasks?.[props.taskId]?.taskStatus
            : TaskStatusType.Created
        }
        fieldType={FieldType.SingleSelect}
        onChange={(e) =>
          props.onChange({
            fieldType: FieldType.String,
            fieldKey: ["tasks", props.taskId, "taskStatus"],
            value: e.target.value
          })
        }
        options={
          props.state.tasks?.[props.taskId]?.availableStatus?.map((k) => {
            return {
              label: k,
              value: k
            };
          }) || createOptionsFromMap(TaskStatusType)
        }
        isValid={true}
        chakraStyles={{
          control: (provided) => {
            const sharedStyle = {
              minHeight: "23px",
              h: "20px",
              w: "140px"
            };
            if (statusToColor[currentValue || "Created"] !== "white") {
              return {
                ...provided,
                ...sharedStyle,
                borderColor: statusToColor[currentValue || "Created"],
                h: "20px"
              };
            }
            return { ...provided, ...sharedStyle };
          },
          dropdownIndicator: (provided) => ({
            ...provided,
            background: statusToColor[currentValue || "Created"], //"#A015D7",
            color: "black",
            w: "20px",
            h: "24px",
            p: "0px"
          }),
          clearIndicator: (provided) => ({
            ...provided,
            display: "none",
            background: "white", //statusToColor[currentValue || "Created"], //"#A015D7",
            color: "black",
            h: "15px",
            p: "0px"
          }),
          placeholder: (provided, _) => ({
            ...provided,
            background: statusToColor[currentValue || "Created"], //"#A015D7",
            color: "black",
            textAlign: "center",
            w: "100%",
            p: 0
          }),
          valueContainer: (provided, _) => ({
            ...provided,
            background: "white", //statusToColor[currentValue || "Created"], //"#A015D7",
            color: "black",
            fontWeight: "bold",
            w: "100%",
            fontSize: "12px",
            p: 0
          })
        }}
      />
    </Flex>
  );
};
