import { useSelector } from "react-redux";
import { RootState, store } from "../redux/store";
import { documentRuleApi } from "../redux/v2/document-rule/documentRule.service";
import { documentRuleSlice } from "../redux/v2/document-rule/documentRule.slice";
import { compareStrings } from "../utils/searchUtils";

export const useDocumentRuleHooks = () => {
  const setSelectedDocumentRule = (id: string) =>
    store.dispatch(documentRuleSlice.actions.selectedId({ id }));

  const selectedDocumentRule = useSelector(
    (state: RootState) =>
      state.documentRule.selectedId &&
      state.documentRule.entities[state.documentRule.selectedId]
  );
  const documentRuleState = useSelector(
    (state: RootState) => state.documentRule
  );
  const [searchDocumentRuleApi, searchResponse] =
    documentRuleApi.useLazySearchQuery();

  const [getRuleBatchApi, getRuleBatchResponse] =
    documentRuleApi.useLazyGetBatchQuery();

  const rankedSort = (query: string) => {
    return Object.values(documentRuleState.entities).sort((a, b) => {
      const rankA = compareStrings(query, a?.ruleName || "");
      const rankB = compareStrings(query, b?.ruleName || "");
      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };
  return {
    documentRuleState,
    searchDocumentRuleApi,
    searchResponse,
    rankedSort,
    selectedDocumentRule,
    setSelectedDocumentRule,
    getRuleBatchApi,
    getRuleBatchResponse
  };
};
