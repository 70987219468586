import { FieldSelectionInteractiveFormContainer } from "../field-selection/FieldSelectionInteractiveForm";

const DataToOrderInteractiveForm = (props: { snapshotId?: string }) => {
  return (
    <FieldSelectionInteractiveFormContainer
      snapshotId={props.snapshotId}
      labelPrefix={"Order"}
      fieldSelection="dataToOrder"
    />
  );
};

export default DataToOrderInteractiveForm;
