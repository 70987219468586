import { Asset, BaseInsurancePolicy, CreditScore, Deal, DealPartyRelation, DealProperty, InsurancePolicyEntityType, Party, PartyRelation, Property, RolodexServiceProvider, Statement, Task } from "@elphi/types";
import { TaskWorkflow } from "./workflow.types";
import { WritableDraft } from "immer/dist/internal";

export interface TaskTypes {
  [x: string]: VersionTask;
}

export enum VersionTaskColumns {
  AutomaticGeneration = "Automatic Generation",
  EditableBy = "Editable By",
  TaskRelation = "Task Relation",
  Hash = "Hash",
  KeyClosingTask = "KCT",
  AssignableBy = "Assignable By",
  TaskName = "Task Name",
  TaskType = "Task Type",
  ChecklistOf = "Checklist Of",
  TaskId = "id",
  Workflow = "Workflow"
}

export type VersionTask = {
  [VersionTaskColumns.AutomaticGeneration]: string;
  [VersionTaskColumns.EditableBy]: string[];
  [VersionTaskColumns.TaskRelation]: string;
  [VersionTaskColumns.Hash]: string;
  [VersionTaskColumns.KeyClosingTask]: string;
  [VersionTaskColumns.AssignableBy]: string[];
  [VersionTaskColumns.TaskName]: string;
  [VersionTaskColumns.TaskType]: string;
  [VersionTaskColumns.ChecklistOf]: string[];
  [VersionTaskColumns.Workflow]: TaskWorkflow;
  [VersionTaskColumns.TaskId]: string;
};
export type VersionCondition = {
  [x: string]: {
    Hash: string;
    Tasks: string[];
    Group: Group[];
    id: string;
    operator?: string;
  };
};

export type Group = {
  Collection: string;
  Label: string;
  ExpectedValue: string[];
  ComparatorType: string;
  Operator: string;
  FieldPath: string;
};


export type TaskFormState = {
  tasks: {
    [id: string]: { id: string } & Partial<Task>;
  };
  deals?: { [id: string]: { id: string } & Partial<Deal> };
  parties?: { [id: string]: { id: string } & Partial<Party> };
  properties?: { [id: string]: { id: string } & Partial<Property> };
  assets?: { [id: string]: { id: string } & Partial<Asset> };
  statements?: { [id: string]: { id: string } & Partial<Statement> };
  serviceProviders?: {
    [id: string]: { id: string } & Partial<RolodexServiceProvider>;
  };
};

export type TaskRowData = {
  task: Task | undefined;
  property: undefined | WritableDraft<Property>;
  party: undefined | WritableDraft<Party>;
  deal: "" | 0 | WritableDraft<Deal> | undefined;
  dealParty: undefined | WritableDraft<DealPartyRelation>;
  partyRelation: undefined | WritableDraft<PartyRelation>;
  dealProperty: undefined | WritableDraft<DealProperty>;
  parentParty: undefined | WritableDraft<Party>;
  childParty: undefined | WritableDraft<Party>;
  asset: undefined | WritableDraft<Asset>;
  statement: undefined | WritableDraft<Statement>;
  assetParties: (undefined | WritableDraft<Party>)[];
  creditScore: undefined | WritableDraft<CreditScore>;
  creditScoreParty: undefined | WritableDraft<Party>;
  followupTasks: undefined | WritableDraft<Task>[];
  serviceProvider: undefined | WritableDraft<RolodexServiceProvider>;
  insurancePolicy:
    | undefined
    | WritableDraft<BaseInsurancePolicy<InsurancePolicyEntityType>>;
};