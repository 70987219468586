import { DealMilestoneType, LenderIdentifierType } from "@elphi/types";
import { isEmpty } from "lodash";
export const uniqCursorId = (r: {
  dealId?: string;
  userId?: string;
  milestone?: DealMilestoneType[];
  lender?: LenderIdentifierType[];
  milestoneOp?: string;
  estimatedClosingDateRange?: { from?: string; to?: string };
}) => {
  const {
    dealId,
    userId,
    milestone,
    lender,
    milestoneOp,
    estimatedClosingDateRange
  } = r;
  // const b64Id = Buffer.from(
  //   JSON.stringify({
  //     dealId: dealId || "dealId",
  //     userId: userId || "userId",
  //     milestone: milestone?.length ? milestone : "milestone",
  //     lender: lender?.length ? lender : "lender",
  //     milestoneOp: milestoneOp || "milestoneOp"
  //   })
  // ).toString("base64");

  const estimatedClosingDateRangeId = !isEmpty(estimatedClosingDateRange)
    ? `${"EstimatedClosingDate"}_${estimatedClosingDateRange.from}to${
        estimatedClosingDateRange.to
      }`
    : "";

  const filtersUniqCursorId = `${dealId || ""}${userId || ""}${
    lender?.length ? lender?.join("_") || "Missing Lender" : ""
  }${
    milestone?.length ? `_${milestoneOp}_${milestone?.join("_")}` : ""
  }${estimatedClosingDateRangeId}`;
  return filtersUniqCursorId || "none";
};
