import {
  BusinessFeatureFlag,
  DevFeatureFlag,
  FeatureFlag,
  featureFlagsFallback
} from "@elphi/utils";
import { createContext, useContext, useState } from "react";
import { Statsig } from "statsig-react";

type FeatureFlagsMap = { [key in FeatureFlag]: boolean };
export const FeatureFlagContext = createContext<{
  map: Partial<FeatureFlagsMap>;
  initFeatureFlags: () => void;
}>({
  map: {},
  initFeatureFlags: () => {}
});

export const useFeatureFlag = () => useContext(FeatureFlagContext);

export const FeatureFlagProvider = ({ children }) => {
  const [map, setMap] = useState<Partial<FeatureFlagsMap>>({});

  const buildFeatureFlagsMap = (featureFlags: [string, FeatureFlag][]) => {
    return featureFlags.reduce((acc, [key, gate]) => {
      try {
        const value = Statsig.checkGate(gate);
        acc[key] = value;
      } catch (e) {
        console.error(`error checking gate ${gate}, using fallback. error:`, e);
        acc[key] = featureFlagsFallback[gate];
      }
      return acc;
    }, {});
  };

  const initFeatureFlags = () => {
    const devFeatureFlags = buildFeatureFlagsMap(
      Object.entries(DevFeatureFlag)
    );
    const businessFeatureFlags = buildFeatureFlagsMap(
      Object.entries(BusinessFeatureFlag)
    );

    setMap({ ...devFeatureFlags, ...businessFeatureFlags });
  };

  return (
    <FeatureFlagContext.Provider value={{ map, initFeatureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
