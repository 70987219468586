import { LOSSettings } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";

export const losSettingsApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "losSettingsApi",
  tagTypes: ["losSettingsEntity"],
  baseQuery: serviceBuilder.baseQuery({
    route: "los-settings"
  }),
  endpoints: (builder) => ({
    get: builder.query<{ result: LOSSettings }, {}>({
      query: () => {
        return {
          url: "/",
          method: "GET"
        };
      }
    })
  })
});
