import { FieldType } from "../../form-builder/fieldFormat.types";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  lengthInRangeValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";

export const fieldTypeValidation = {
  [FieldType.Money]: moneyValidation,
  [FieldType.SingleSelect]: singleSelectValidation,
  [FieldType.Percentage]: percentValidation,
  [FieldType.String]: stringValidation,
  [FieldType.RichText]: stringValidation,
  [FieldType.Boolean]: booleanValidation,
  [FieldType.Number]: numberValidation,
  [FieldType.Date]: dateValidation,
  [FieldType.MultiSelect]: multiSelectValidation,
  [FieldType.FourDigit]: lengthInRangeValidation(3, 4),
  [FieldType.CreditScore]: stringValidation,
  [FieldType.Decimal]: numberValidation,
  [FieldType.Integer]: numberValidation,
  [FieldType.Email]: emailValidation,
  [FieldType.PercentageThreeDecimal]: percentValidation,
  [FieldType.Phone]: stringValidation,
  [FieldType.SignedMoney]: moneyValidation,
  [FieldType.Year]: numberValidation
};
