import { RolodexBranchRepresentativeRelation } from "@elphi/types";
import { EntityState, createSlice } from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../../builders/slice.builder";
import { serviceProviderApi } from "../service-provider/serviceProvider.service";
import { branchRepRelationAdapter as entityAdapter } from "./branchRepRelation.adapter";
import { branchRepRelationApi } from "./branchRepRelation.service";
export type BranchRepRelationSliceState =
  EntityState<RolodexBranchRepresentativeRelation> & {
    preprocessed: {
      serviceProviderIds: string[];
      relationIds: string[];
    };
  };

const initialState: BranchRepRelationSliceState = {
  ids: [],
  entities: {},
  preprocessed: {
    serviceProviderIds: [],
    relationIds: []
  }
};

export const branchRepRelationSlice = createSlice({
  name: "branchRepRelation",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    resetPreprocessed: (state) => {
      state.preprocessed = initialState.preprocessed;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(branchRepRelationApi)(builder);
    builder.addMatcher(
      branchRepRelationApi.endpoints.getDealBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      serviceProviderApi.endpoints.getTree.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      branchRepRelationApi.endpoints.cascadeDelete.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.serviceProviders.ids);
        state.entities = {
          ...state.entities,
          ...payload.relations.entities
        };
        state.preprocessed.serviceProviderIds =
          payload.serviceProviders.ids.map((x) => x.toString());
        state.preprocessed.relationIds = payload.relations.ids.map((x) =>
          x.toString()
        );
      }
    );
  }
});
