import { BaseEntityWithCorrelationId } from "../../db";

export enum PosOrganizationMembershipStatus {
  Pending = "pending",
  Invited = "invited",
  Accepted = "accepted",
  Failed = "failed"
}

export enum PosUserType {
  Borrower = "borrower"
}

export type PosUser = BaseEntityWithCorrelationId<{
  firstName: string;
  lastName: string;
  email: string;
  orgMembershipStatus?: PosOrganizationMembershipStatus;
  type: PosUserType;
}>;
