import { AnyAggregationField } from "../../../aggregations";
import { IntegrationType, PricingVendorType } from "../../task-types";
import { getLoanpassPricingTemplate } from "./deal/loanpassPricing.tempate";

const buildIntegrationTemplate = (r: {
  integrationFields: Record<string, AnyAggregationField>;
  integrationType: IntegrationType;
  vendor: string;
}) => {
  const { integrationFields, integrationType, vendor } = r;
  return {
    [integrationType]: {
      [vendor]: integrationFields
    }
  };
};

export const getIntegrationsTemplates = (
  r?: {
    integrationType: IntegrationType;
    vendor: string;
  }[]
) => {
  const integrationTemplates = r?.reduce((acc, { integrationType, vendor }) => {
    if (
      integrationType === IntegrationType.Pricing &&
      vendor === PricingVendorType.Loanpass
    ) {
      const loanpassPricingTemplate = getLoanpassPricingTemplate();
      const template = buildIntegrationTemplate({
        integrationFields: loanpassPricingTemplate,
        integrationType,
        vendor
      });
      return { ...acc, ...template };
    }

    return acc;
  }, {} as Record<IntegrationType, Record<string, AnyAggregationField>>);

  return {
    aggregations: {
      Integrations: integrationTemplates || {}
    }
  };
};
