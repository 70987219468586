import { IconButton, useDisclosure } from "@chakra-ui/react";
import { StatusCode } from "@elphi/types";
import { responseHandler } from "../../../apis/rtk/response.handler";
import { RTKResponse } from "../../../apis/rtk/response.types";
import { useNotificationUserHooks } from "../../../hooks/notificationUser.hooks";
import { useShouldNotifyUserSubscriber } from "../../../hooks/notificationUserSubscriber.hooks";
import { AuditLogModal } from "../../audit-log/modal/AuditLogModal";
import { BellIcon, BellNotificationIcon } from "../../icons";
import { NotificationContent } from "./NotificationContent";

export const NotificationUserBox = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { toggleNotifyApi, toggleNotifyResponse } = useNotificationUserHooks();
  const { notify, setNotify } = useShouldNotifyUserSubscriber({
    isWatching: isOpen
  });

  const handleOnClose = () => {
    onClose();
    handleNotify();
  };

  const handleNotify = () => {
    if (notify && !toggleNotifyResponse.isLoading) {
      setNotify(false);
      toggleNotifyApi()
        .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
        .then((r) => {
          if (r.status === StatusCode.BadRequest) {
            setNotify(notify);
          }
        });
    }
  };

  return (
    <>
      <IconButton
        minW={"unset"}
        bgColor={"transparent"}
        size={"sm"}
        variant={"unstyled"}
        aria-label={"notification--user-button"}
        pt={1}
        onClick={onOpen}
        icon={
          isOpen ? (
            <BellIcon w={6} h={6} />
          ) : notify ? (
            <BellNotificationIcon w={6} h={6} />
          ) : (
            <BellIcon w={6} h={6} />
          )
        }
      />
      <AuditLogModal
        isOpen={isOpen}
        onClose={handleOnClose}
        title={"My Notifications"}
      >
        <NotificationContent />
      </AuditLogModal>
    </>
  );
};
