import { useEffect, useState } from "react";
import { auth } from "../firebase/firebaseConfig";
import { useLOSUserHooks } from "./losuser.hooks";

export const useAuthListener = () => {
  const [isLoggedIn, setShouldListen] = useState(!!auth.currentUser?.email);
  const { updateLOSUserStampApi } = useLOSUserHooks();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (s) => {
      console.log("auth state: ", !!s?.email ? "valid" : "in-valid");
      setShouldListen(!!s?.email);
      if (!!s?.email) {
        await updateLOSUserStampApi(undefined, true);
      }
    });
    return () => {
      console.log("Goodbye from auth listener");
      unsubscribe();
      console.log("unsubscribe auth listener success");
    };
  }, []);

  return { isLoggedIn };
};
