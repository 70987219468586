import { aggregationFieldTemplate } from "../../aggregations";
import { BooleanSelectType } from "../fieldFormat.types";
import { Statement, StatementStatusType } from "../statement.types";

export const getStatementTemplate = () => {
  const statement: Statement = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    assetId: "",
    status: "" as StatementStatusType,
    latestStatement: "" as BooleanSelectType,
    start: "",
    end: "",
    statementBalance: "",
    unverifiedDeposits: "",
    percentConsidered: "",
    aggregations: {
      qualifyingBalance: aggregationFieldTemplate()
    }
  };

  return statement;
};
