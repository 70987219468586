import { ElphiLocationResponse } from "../../../../../../integration-manager/src/property-report/site-x/mapper/siteX.mapper";
import { ElphiTrxStatus, StepRequired } from "../../integration.types";
import {
  PropertyReportVendorFields,
  PropertyReportVendorType
} from "../propertyReportVendor.types";

export type SiteXFailedStepLabel =
  | "failedOrder"
  | "propertyNotFound"
  | "failedUploadingReport"
  | "failedFetchProperty"
  | "failedMultiLocations"
  | "failedSiteXMissReport";

export type PropertyReportSiteXFields =
  PropertyReportVendorFields<PropertyReportVendorType.SiteX> & {
    elphiTrxStatus: ElphiTrxStatus;
    addr?: string;
    lastLine?: string;
    locations?: ElphiLocationResponse[];
    reportFile?: string;
    failedLabel?: SiteXFailedStepLabel | "";
    siteXTrxStatus?: SiteXTrxStatus;
    stepRequired?: SiteXStepRequired;
  };

export enum SiteXTrxStatus {
  Ok = "OK"
}

export type SiteXStepRequired = StepRequired | "multiLocations";
