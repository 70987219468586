import { PartyValidationConfiguration } from "@elphi/types";
import { EntityId, EntityState, createSlice } from "@reduxjs/toolkit";
import { partyValidationConfigAdapter } from "./partyValidationConfiguration.adapter";

export type PartyValidationConfigSliceState = {
  id: EntityId | undefined;
  configurations: PartyValidationConfiguration | undefined;
};

const initialState: EntityState<PartyValidationConfiguration> = {
  entities: {},
  ids: []
};

export const partyValidationConfigSlice = createSlice({
  name: "partyValidationConfig",
  initialState: partyValidationConfigAdapter.getInitialState(initialState),
  reducers: {
    update: partyValidationConfigAdapter.updateOne,
    add: partyValidationConfigAdapter.addOne,
    upsert: partyValidationConfigAdapter.upsertOne,
    upsertMany: partyValidationConfigAdapter.upsertMany,
    updateMany: partyValidationConfigAdapter.updateMany
  }
});
