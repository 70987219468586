import { PropertyTableContainerProps } from "./PropertyTable";
import { PropertyTableLiveStateContainer } from "./PropertyTableLiveStateContainer";
import { PropertyTableSnapshotContainer } from "./PropertyTableSnapshotContainer";

export const PropertyTableContainer = (props: PropertyTableContainerProps) => {
  return props.snapshotId ? (
    <PropertyTableSnapshotContainer {...props} snapshotId={props.snapshotId} />
  ) : (
    <PropertyTableLiveStateContainer {...props} />
  );
};
