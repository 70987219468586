import { TObject, TProperties, Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { TaskFieldsSchema } from "./task.typebox";
import { StringField } from "./utils.typebox";

export const TaskTemplateFieldsSchema = Type.Object({
  template: Type.Omit(TaskFieldsSchema, [
    "id",
    "templateId",
    "dataToVerify",
    "dataToOrder"
  ]),
  id: StringField
});

export const TaskTemplateUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(TaskTemplateFieldsSchema)
]);

export const TaskTemplateCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(TaskTemplateFieldsSchema)
]);

export const getOperationTaskTemplateSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? TaskTemplateCreate : TaskTemplateUpdate;
};
