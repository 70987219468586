import { CloseIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Text
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { auth } from "../../firebase/firebaseConfig";
import { BlueEyeIcon, EyeIcon } from "../icons";
import { FuzzySearch } from "../search/fuzzy/FuzzySearch";

type UserWatcher = {
  id: string;
  name: string;
  email: string;
};

export const WatchersMenuList = (props: {
  userWatcherList: UserWatcher[];
  currentWatcherUIDs: string[];
  onClose: (watcherUIDs: string[]) => void;
  isLoading?: boolean;
}) => {
  const { userWatcherList, currentWatcherUIDs, isLoading, onClose } = props;
  const [currentWatchers, setCurrentWatchers] = useState<UserWatcher[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<UserWatcher[]>([]);

  useEffect(() => {
    const userWatchers = currentWatcherUIDs.length
      ? userWatcherList.filter((c) => currentWatcherUIDs.includes(c.id))
      : [];
    setCurrentWatchers(userWatchers);
  }, [userWatcherList, currentWatcherUIDs]);

  useEffect(() => {
    handleOnSearchResult(searchedUsers);
  }, [currentWatchers]);

  const addWatcher = (user: UserWatcher) => {
    if (!currentWatchers.find((watcher) => watcher.id === user.id)) {
      setCurrentWatchers([...currentWatchers, user]);
    }
  };

  const removeWatcher = (user: UserWatcher) => {
    setCurrentWatchers(
      currentWatchers.filter((watcher) => watcher.id !== user.id)
    );
  };

  const handleOnClose = () => {
    onClose(currentWatchers.map((watcher) => watcher.id));
  };

  const handleOnSearchResult = (e: UserWatcher[]) => {
    setSearchedUsers(
      e.filter(
        (user) => !currentWatchers.find((watcher) => watcher.id === user.id)
      )
    );
  };

  const isSelfWatching = auth.currentUser?.uid
    ? currentWatcherUIDs.includes(auth.currentUser.uid)
    : false;

  const haveWatchers = currentWatchers.length > 0;

  return (
    <Menu
      closeOnSelect={false}
      onClose={handleOnClose}
      placement={"bottom-end"}
    >
      <MenuButton
        as={Button}
        aria-label={"MenuUser"}
        leftIcon={
          isSelfWatching ? (
            <BlueEyeIcon w={6} h={6} ml={haveWatchers ? 1 : 2} />
          ) : (
            <EyeIcon w={6} h={6} ml={haveWatchers ? 1 : 2} />
          )
        }
        bgColor={"white"}
        onClick={(e) => e.stopPropagation()}
        width={haveWatchers ? "50px" : "30px"}
        height={"30px"}
        padding={0}
        isLoading={isLoading}
        color={isSelfWatching ? "blue.500" : "gray.700"}
        _hover={{ bgColor: isSelfWatching ? "blue.50" : "gray.100" }}
        _active={{ bgColor: isSelfWatching ? "blue.100" : "gray.300" }}
      >
        {currentWatchers.length > 0 && <Text>{currentWatchers.length}</Text>}
      </MenuButton>

      <MenuList py={2} zIndex={10}>
        <Box>
          <MenuOptionGroup
            title={"WATCHING THIS DISCUSSION"}
            type={"checkbox"}
            defaultValue={[]}
            position={"sticky"}
            top={0}
            zIndex={1}
            bgColor={"white"}
            margin={"unset"}
            px={2}
            pb={2}
            borderBottomColor={"gray.200"}
            borderBottomWidth={1}
          >
            <List maxH={"200px"} overflowY={"auto"}>
              {currentWatchers.map((user) => (
                <ListItem
                  key={user.id}
                  px={2}
                  py={1}
                  _hover={{ bgColor: "gray.100" }}
                >
                  <Flex alignItems={"center"}>
                    <Avatar
                      size={"xs"}
                      name={user.name}
                      mr={2}
                      bgColor={"rgb(49, 130, 206)"}
                      color={"rgb(224, 242, 255)"}
                    />
                    <Text fontSize={"14px"}>{user.name}</Text>
                    <Spacer />
                    <IconButton
                      cursor={"pointer"}
                      variant={"unstyled"}
                      aria-label="Remove watcher"
                      icon={<CloseIcon />}
                      size="sm"
                      onClick={() => removeWatcher(user)}
                      ml={"auto"}
                    />
                  </Flex>
                </ListItem>
              ))}
            </List>
          </MenuOptionGroup>
        </Box>
        <Box p={2}>
          <FuzzySearch
            label={"Add watchers"}
            dataSet={userWatcherList}
            configuration={{
              keys: ["name", "email"],
              threshold: 0.4,
              includeScore: true
            }}
            onResult={handleOnSearchResult}
            showAllOnEmpty={false}
            sanitizer={(q) => decodeURIComponent(encodeURIComponent(q))}
          />
        </Box>
        <Box>
          <List maxH={"200px"} overflowY={"auto"}>
            {searchedUsers.map((user) => (
              <ListItem
                cursor={"pointer"}
                key={user.id}
                onClick={() => addWatcher(user)}
                p={2}
                _hover={{ bgColor: "gray.100" }}
              >
                <Flex alignItems={"center"}>
                  <Avatar
                    size={"xs"}
                    name={user.name}
                    mr={2}
                    bgColor={"rgb(49, 130, 206)"}
                    color={"rgb(224, 242, 255)"}
                  />
                  <Text fontSize={"14px"}>{user.name}</Text>
                </Flex>
              </ListItem>
            ))}
          </List>
        </Box>
      </MenuList>
    </Menu>
  );
};
