import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Statement } from "@elphi/types";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useStatementHooks } from "../../hooks/statement.hooks";
import { ButtonIconDelete } from "../button-icon/ButtonIcon";
import { useElphiToast } from "../toast/toast.hook";

export const DeleteStatementModal = (props: {
  callback?: (id: string) => void;
  buttonFn?: (props?: {
    onClick?: () => void;
    isDisabled?: boolean;
  }) => JSX.Element;
  selectedStatement: Statement;
  isDisabled?: boolean;
}) => {
  const { selectedStatement } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteStatement, deleteStatementResponse } = useStatementHooks();
  const { successToast, errorToast } = useElphiToast();
  const buttonComponent = props.buttonFn?.({ onClick: onOpen }) ?? null;

  const deleteStatementHandler = async (selectedStatement: Statement) => {
    return await deleteStatement(selectedStatement.id).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Statement Deleted",
          description: `statement: ${r.data?.id}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to delete statement",
          description: r.data.description
        });
      return r;
    });
  };

  const confirmModal = (
    <Box>
      <Text p="10px">
        Are you sure you want to delete this statement? Deleting this statement
        will delete all of its information, including tasks attached to it.
      </Text>
      <Spacer mb="50px" />
      <Flex justifyContent={"space-between"}>
        <Button
          {...elphiTheme.components.light.button.primary}
          isLoading={deleteStatementResponse.isLoading}
          isDisabled={!selectedStatement || deleteStatementResponse.isLoading}
          onClick={() => {
            selectedStatement &&
              deleteStatementHandler(selectedStatement).then((r) => {
                r.status === 200 && onClose();
              });
          }}
        >
          Yes, delete statement
        </Button>
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );

  return (
    <Box>
      {props.buttonFn
        ? buttonComponent
        : ButtonIconDelete({ onClick: onOpen, isDisabled: props.isDisabled })}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Deleting a Statement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{confirmModal}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
