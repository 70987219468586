import { createIcon } from "@chakra-ui/react";

const customFileIcon = (stroke?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#EDF2F7" stroke="none" />
        <path
          d="M12 9.5V12L13.25 13.25"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23857 14.7614 7 12 7C9.23857 7 7 9.23857 7 12C7 14.7614 9.23857 17 12 17Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      stroke: "#1A202C"
    }
  });

export const PendingIcon = customFileIcon();
