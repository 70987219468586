import { Button, IconButton, IconButtonProps, Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import { forwardRef, useCallback, useState } from "react";

type BasicDebounceButtonProps = {
  onClick: (e) => void;
  debounceDelay?: number;
  withDisabledDebounced?: boolean;
};
type DebounceButtonProps = BasicDebounceButtonProps & IconButtonProps;

const useDebounceButton = ({
  onClick,
  debounceDelay = 500,
  withDisabledDebounced = true
}: BasicDebounceButtonProps) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const debouncedOnClick = useCallback(
    debounce((e) => {
      onClick(e);
      withDisabledDebounced && setButtonDisabled(false);
    }, debounceDelay),
    [onClick, debounceDelay]
  );

  const handleButtonClick = useCallback(
    (e) => {
      withDisabledDebounced && setButtonDisabled(true);
      debouncedOnClick(e);
    },
    [debouncedOnClick]
  );

  return { handleButtonClick, isButtonDisabled };
};

export const DebounceIconButton = forwardRef(
  (
    {
      onClick,
      debounceDelay,
      withDisabledDebounced,
      ...iconButtonProps
    }: DebounceButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { handleButtonClick, isButtonDisabled } = useDebounceButton({
      onClick,
      debounceDelay,
      withDisabledDebounced
    });

    return (
      <IconButton
        onClick={handleButtonClick}
        isDisabled={isButtonDisabled}
        ref={ref}
        {...iconButtonProps}
      />
    );
  }
);

export const DebounceButton = forwardRef(
  (
    {
      text,
      onClick,
      debounceDelay,
      withDisabledDebounced,
      ...iconButtonProps
    }: DebounceButtonProps & { text: string },
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { handleButtonClick, isButtonDisabled } = useDebounceButton({
      onClick,
      debounceDelay,
      withDisabledDebounced
    });

    return (
      <Button
        onClick={handleButtonClick}
        isDisabled={isButtonDisabled}
        ref={ref}
        {...iconButtonProps}
        rightIcon={iconButtonProps?.icon}
      >
        <Text>{text}</Text>
      </Button>
    );
  }
);
