import { Type } from "@sinclair/typebox";
import { AggregationField } from "../../aggregations";
import { BaseEntityWithCorrelationId, FirebaseCollections } from "../../db";
import {
  AggregationFieldSchema,
  ArrayField,
  EnumField,
  NumberField,
  StringField,
  SwitchField
} from "../../typebox/utils.typebox";
import { createEntitySchema } from "../../typebox/utils/entity.utils";

export enum CloneConfigurationType {
  role = "Roles",
  userManagement = "User Management",
  taskManagement = "Task Management",
  documentManagement = "Document Management",
  notificationManagement = "Notification Management",
  serviceProvidersManagement = "Service Providers Management"
}

export type CloneConfigurationEntitiesMap = Record<
  CloneConfigurationType,
  FirebaseCollections[]
>;

export const cloneConfigurationEntitiesMap: CloneConfigurationEntitiesMap = {
  [CloneConfigurationType.role]: [
    FirebaseCollections.Role,
    FirebaseCollections.Permission
  ],
  [CloneConfigurationType.userManagement]: [
    FirebaseCollections.Role,
    FirebaseCollections.LOSUser
  ],
  [CloneConfigurationType.taskManagement]: [
    FirebaseCollections.Role,
    FirebaseCollections.TaskConfiguration,
    FirebaseCollections.TaskTemplate,
    FirebaseCollections.TaskRuleTemplate,
    FirebaseCollections.TaskInstruction
  ],
  [CloneConfigurationType.documentManagement]: [
    FirebaseCollections.DocumentConfiguration,
    FirebaseCollections.DocumentRuleTemplate
  ],
  [CloneConfigurationType.notificationManagement]: [
    FirebaseCollections.NotificationConfiguration,
    FirebaseCollections.RoleGroup,
    FirebaseCollections.AuditEventGroup,
    FirebaseCollections.FieldGroup
  ],
  [CloneConfigurationType.serviceProvidersManagement]: [
    FirebaseCollections.RolodexServiceDomainConfiguration
  ]
};

export type OrganizationAggregationFields = Partial<{
  NumberOfServiceLinkFloodCompletions: AggregationField;
}>;

export const OrgFields = Type.Object({
  name: StringField,
  description: StringField,
  keywords: ArrayField(StringField),
  aggregations: Type.Object({
    NumberOfServiceLinkFloodCompletions: AggregationFieldSchema(NumberField)
  }),
  isSubscribed: SwitchField,
  subscribeToOrganization: StringField,
  cloneConfiguration: ArrayField(EnumField(CloneConfigurationType))
});
export const {
  Update: OrgUpdate,
  Create: OrgCreate,
  Schema: OrgSchema,
  getOperationSchema: getOperationOrgSchema
} = createEntitySchema(OrgFields);

// export type Organization = Static<typeof OrgSchema>;
export type OrganizationFields = {
  name: string;
  description: string;
  aggregations: {
    NumberOfServiceLinkFloodCompletions: AggregationField;
  };
  isSubscribed: "active" | "disabled";
  subscribeToOrganization: string;
  cloneConfiguration: ("" | CloneConfigurationType)[];
};
export type Organization = BaseEntityWithCorrelationId<OrganizationFields>;
