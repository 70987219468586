import { TaskTemplate } from "@elphi/types";
import { removeNulls } from "../../../utils/filter.utils";

import { useCallback } from "react";
import { useTaskTemplateHooks } from "../../../hooks/taskTemplate.hooks";
import { getRankedData } from "../../../utils/ranked.utils";
import { SearchComponentProps } from "../../SearchComponent";
import { SearchHandler } from "../../search/SearchHandler";
import { DATA_RANK } from "./v2/taskTemplate.util";

const buildOption = (taskTemplate: TaskTemplate) => {
  return {
    label: [taskTemplate.template.templateName].filter(removeNulls).join(", "),
    value: taskTemplate.id
  };
};
export type TaskTemplateSearchProps = {
  filter?: (a: TaskTemplate) => boolean;
} & Pick<
  SearchComponentProps,
  | "onSelect"
  | "currentValue"
  | "label"
  | "labelPosition"
  | "isReadOnly"
  | "isDisabled"
> &
  Pick<Partial<SearchComponentProps>, "fieldType">;
export const TaskTemplateSearch = (props: TaskTemplateSearchProps) => {
  const {
    searchTaskTemplateApi,
    searchResponse,
    rankedSort,
    taskTemplateGetBatchApi,
    taskTemplateState,
    taskTemplateSearchState
  } = useTaskTemplateHooks();
  const customFilterLogic: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    if (!searchText) return true;
    const taskTemplate = taskTemplateState?.entities?.[option.data.value];
    if (!taskTemplate?.id) return false;
    return (
      getRankedData(searchText, taskTemplate, DATA_RANK) > 0.5 ||
      searchText === taskTemplate.id.toLowerCase()
    );
  };
  const customFilter = useCallback(customFilterLogic, [
    taskTemplateState?.entities
  ]);

  return (
    <SearchHandler
      {...props}
      version="v2"
      getBatchApi={taskTemplateGetBatchApi}
      searchApi={searchTaskTemplateApi}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      filterOption={customFilter}
      state={taskTemplateSearchState}
      buildOption={buildOption}
    />
  );
};
