import { Party, PartyType } from "../../../types";
import { BaseParty } from "../../../types/entities/base";
import { removeEmpty } from "../common.utils";

export const buildPartyName = (p: Party | BaseParty) => {
  if (p.PartyType === PartyType.Individual) {
    return [p?.FirstName, p?.MiddleName, p?.LastName]
      .filter(removeEmpty)
      .join(" ");
  }
  return p?.FullName;
};
