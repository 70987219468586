import {
  booleanValidation,
  numberValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { mofinSpecs } from "../spec-files/mofinFieldSpecs";

export const payoffInterestDays = buildInput({
  fieldKey: mofinSpecs.property?.entitySpecs?.PayoffInterestDays?.fieldKey,
  validation: numberValidation
});

export const canBeRebuiltIndicator = buildInput({
  fieldKey: mofinSpecs.property?.entitySpecs?.CanBeRebuiltIndicator?.fieldKey,
  validation: booleanValidation
});
