import {
  Avatar,
  Box,
  Divider,
  Flex,
  ScaleFade,
  useDisclosure
} from "@chakra-ui/react";
import { DiscussionEntityType, StatusCode, TimestampField } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { RichTextEditor } from "../../common-components";
import { EMPTY } from "../../constants/common";
import { getTimestampFromFirestoreObject } from "../../firebase/firebase.utils";
import { auth } from "../../firebase/firebaseConfig";
import {
  DISCUSSION_COMMENT_LIMIT,
  useDiscussionCommentTableHooks,
  useDiscussionEntityHooks
} from "../../hooks/discussionEntity.hooks";
import { useDiscussionEntityCommentSubscriber } from "../../hooks/discussionEntitySubscriber.hooks";
import { useLOSUserHooks } from "../../hooks/losuser.hooks";
import useWindowDimensions from "../../hooks/windowDimensions";
import { ElphiPaginationList } from "../elphi-list/ElphiList";
import { buildMentionText, DeleteCommentModal } from "./Discussion.utils";
import { DiscussionCommentRow } from "./DiscussionCommentRow";

export const DiscussionContent = (props: {
  entityId: string;
  entityType: DiscussionEntityType;
  isWatching: boolean;
}) => {
  const { entityId, entityType, isWatching } = props;
  const { losUserState } = useLOSUserHooks();
  const {
    handleAddComment,
    handleUpdateComment,
    handleDeleteComment,
    handleToggleReaction,
    deleteCommentResponse,
    discussionEntityState
  } = useDiscussionEntityHooks();
  const { handleNextPage, paginateApiResponse, hasMore } =
    useDiscussionCommentTableHooks({ entityId });
  useDiscussionEntityCommentSubscriber({ entityId });
  const [editedCommentId, setEditedCommentId] = useState<string>();
  const [deletedCommentId, setDeletedCommentId] = useState<string>();
  const [mentionText, setMentionText] = useState<string>();
  const { heightOffsetInPx } = useWindowDimensions();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isWatching) {
      handleNextPage();
    }
  }, [entityId, isWatching]);

  const userMentions = useMemo(() => {
    return Object.values(losUserState.entities)
      .map((user) => {
        if (!user) return;
        return {
          id: user.id,
          value: [user.email, user.name]
        };
      })
      .filter(removeEmpty);
  }, [losUserState.entities]);

  const comments = useMemo(() => {
    return orderBy(
      discussionEntityState?.discussions?.[entityId]?.comments || [],
      (item) =>
        getTimestampFromFirestoreObject(item.createdAt as TimestampField),
      "desc"
    );
  }, [discussionEntityState?.discussions?.[entityId]]);

  const handleUpdateClick = (editorValue: {
    content: string;
    mentioned?: string[];
    htmlContent: string;
  }) => {
    const { content, mentioned, htmlContent } = editorValue;
    editedCommentId &&
      handleUpdateComment({
        id: editedCommentId,
        entityId,
        comment: content,
        mentionedUIDs: mentioned,
        htmlComment: htmlContent
      });
  };

  const handleAddClick = (editorValue: {
    content: string;
    mentioned?: string[];
    htmlContent: string;
  }) => {
    const { content, mentioned, htmlContent } = editorValue;
    handleAddComment({
      entityType,
      entityId,
      comment: content,
      mentionedUIDs: mentioned,
      htmlComment: htmlContent
    });
  };

  const handleEditClick = (commentId?: string) => {
    setEditedCommentId(commentId);
  };

  const handleDeleteClick = (commentId: string) => {
    setDeletedCommentId(commentId);
    onOpen();
  };

  const handleReplyClick = (userId: string) => {
    const replyTo = losUserState?.entities?.[userId];
    if (!replyTo) {
      return;
    }
    const replyText = buildMentionText(replyTo);
    setMentionText(replyText);
  };

  const handleCancelReplyClick = () => {
    setMentionText(undefined);
  };

  const handleReactionClick = (r: {
    commentId: string;
    emojiUnified: string;
  }) => {
    const { commentId, emojiUnified } = r;
    handleToggleReaction({
      entityId,
      commentId,
      reactionId: emojiUnified
    });
  };

  const handleOnSubmitDelete = () => {
    if (deletedCommentId) {
      handleDeleteComment({ id: deletedCommentId, entityId }).then((r) => {
        if (r.status === StatusCode.OK) {
          setDeletedCommentId(undefined);
          onClose();
        }
      });
    }
  };

  return (
    <Box>
      <Flex gap={2} alignItems={"flex-start"} width={"100%"}>
        <Box width={"4%"}>
          <Avatar
            bg={"rgb(246, 224, 255)"}
            color={"rgb(160, 21, 215)"}
            height={10}
            width={10}
            name={
              auth.currentUser?.uid
                ? losUserState?.entities?.[auth.currentUser.uid]?.name
                : undefined
            }
          />
        </Box>
        <Box width={"96%"}>
          {mentionText ? (
            <RichTextEditor
              key={mentionText}
              onSave={handleAddClick}
              onCancel={handleCancelReplyClick}
              mode={"edit"}
              mentionList={userMentions}
              text={mentionText}
            />
          ) : (
            <RichTextEditor
              onSave={handleAddClick}
              mode={"create"}
              mentionList={userMentions}
            />
          )}
        </Box>
      </Flex>
      <Divider py={4} />
      <ElphiPaginationList
        height={heightOffsetInPx(320)}
        tableName={"reaction-list"}
        isLoading={
          paginateApiResponse.isLoading || paginateApiResponse.isFetching
        }
        rowStyle={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none"
        }}
        pageSize={DISCUSSION_COMMENT_LIMIT}
        items={comments}
        next={handleNextPage}
        hasMore={hasMore || true}
        options={{
          showEndMessage: false,
          showWatching: false,
          showFetchMore: false,
          showLoader: true
        }}
        skeletonRows={5}
        rowBuilder={(c, index) => {
          return (
            <ScaleFade key={index} in={true}>
              <DiscussionCommentRow
                mode={editedCommentId === c.id ? "edit" : "view"}
                discussionComment={c}
                avatarName={
                  losUserState.entities?.[c.createdUID || EMPTY]?.name
                }
                userMentions={userMentions}
                handleSaveClick={handleUpdateClick}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                handleReplyClick={handleReplyClick}
                handleReactionClick={(e) =>
                  handleReactionClick({
                    commentId: c.id,
                    emojiUnified: e.unified
                  })
                }
              />
            </ScaleFade>
          );
        }}
      />
      <DeleteCommentModal
        isShow={isOpen}
        onClose={onClose}
        onSubmit={handleOnSubmitDelete}
        isLoading={deleteCommentResponse.isLoading}
      />
    </Box>
  );
};
