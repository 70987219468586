import { Type } from "@sinclair/typebox";
import { baseEntityToIndividualRelationRoleType } from "../../entities/base";
import {
  limaEntityToEntityRelationSchema,
  limaEntityToIndividualRelationSchema
} from "../lima/partyRelation.typebox";
import { ArrayField, EnumField } from "../utils.typebox";

export const elphiEntityToEntityRelationSchema = Type.Intersect([
  Type.Omit(limaEntityToEntityRelationSchema, ["relationRoleType"]),
  Type.Object({
    relationRoleType: ArrayField(
      EnumField(baseEntityToIndividualRelationRoleType)
    )
  })
]);

export const elphiEntityToIndividualRelationSchema =
  limaEntityToIndividualRelationSchema;
