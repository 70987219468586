import { Badge, Box, Flex, Spacer, Text } from "@chakra-ui/react";
import {
  ElphiTrxStatus,
  FieldType,
  KeyClosingTaskType,
  TaskFlagType
} from "@elphi/types";
import { useEffect } from "react";
import { useTaskFormHooks } from "../../../hooks/taskForm.hooks";
import { EmptyFileIcon, FileIcon } from "../../file-uploader/Icons";
import { TaskStatusDropdown } from "../../task-status-dropdown/TaskStatusDropdown";
import { FlaggedTaskIndicator } from "../../task/TaskStatus";
import {
  ELPHI_TRX_STATUS_COLOR,
  ELPHI_TRX_STATUS_COLOR_TEXT,
  ELPHI_TRX_STATUS_TOOLTIP_MESSAGES
} from "../../task/integrations/integration.type";
import { TaskCardInfo } from "../taskCards.types";

export const TaskCard = (props: {
  taskCard: TaskCardInfo;
  dealIdentifier?: string;
  bgColor?: string;
}) => {
  const { onChange, state, syncState } = useTaskFormHooks();
  const { taskCard, bgColor = "white", dealIdentifier } = props;
  const currentFlagged = state.tasks?.[taskCard.id]?.flagged
    ? state.tasks?.[taskCard.id]?.flagged
    : TaskFlagType.None;

  const onChangeFlag = (e) => {
    e.stopPropagation();
    onChange({
      fieldType: FieldType.String,
      fieldKey: ["tasks", taskCard.id, "flagged"],
      value: currentFlagged ? TaskFlagType.None : TaskFlagType.Attention
    });
  };

  useEffect(() => {
    taskCard &&
      syncState({
        shouldSync: !!taskCard,
        state: taskCard,
        statePath: () => {
          return ["tasks", taskCard.id];
        }
      });
  }, [
    taskCard.id,
    taskCard?.taskStatus,
    taskCard?.flagged,
    taskCard?.orderStatus,
    taskCard?.elphiStatus,
    taskCard?.vendorStatus,
    taskCard?.orderedDate,
    taskCard?.stepRequired
  ]);

  return (
    <Box
      bgColor={bgColor}
      borderWidth={1}
      borderColor="{gray.500}"
      borderRadius={"8px"}
      boxShadow="md"
      p={2}
    >
      <Flex alignItems={"left"}>
        <Text fontSize={"14px"} fontWeight="semibold">
          {taskCard.taskName}
        </Text>
        <Spacer />
        <Box cursor={"pointer"} onClick={onChangeFlag}>
          <FlaggedTaskIndicator isFlagged={currentFlagged} w="26px" h="26px" />
        </Box>
        <Box>
          {taskCard.containFiles ? (
            <FileIcon w={8} h={8} />
          ) : (
            <EmptyFileIcon w={8} h={8} />
          )}
        </Box>
      </Flex>
      <Flex alignItems="left" direction="column">
        <Text fontSize="14px" fontWeight="400">
          {taskCard.entityInfo
            ? taskCard.entityInfo
            : dealIdentifier && `Deal: ${dealIdentifier}`}
        </Text>
        <Spacer />
        <Flex
          flexDir={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box>
            {taskCard.vendorStatus ? (
              <Text fontSize="12px" fontWeight="400">
                Vendor Status: {taskCard.vendorStatus}
              </Text>
            ) : (
              <Text fontSize="12px" fontWeight="400">
                Order Status: {taskCard?.orderStatus || "--"}
              </Text>
            )}
          </Box>
          <Box>
            {taskCard?.orderedDate && (
              <Text fontSize="12px" fontWeight="400">
                Ordered Date: {taskCard?.orderedDate || "--"}
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>
      <Flex flexDir={"column"} justifyContent="right">
        <Flex
          flexDir={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box>
            <Text
              mr={2}
              fontSize="12px"
              fontWeight={"bold"}
              color="red.500"
              hidden={!taskCard?.stepRequired}
            >
              STEP REQUIRED!
            </Text>
          </Box>
          <Flex>
            <Box mr={"10px"}>
              {taskCard?.elphiStatus && (
                <Badge
                  fontSize={12}
                  bgColor={
                    taskCard?.elphiStatus &&
                    ELPHI_TRX_STATUS_COLOR?.[taskCard?.elphiStatus]
                      ? ELPHI_TRX_STATUS_COLOR[taskCard?.elphiStatus]
                      : ELPHI_TRX_STATUS_COLOR?.default
                  }
                  borderColor={
                    taskCard?.elphiStatus &&
                    ELPHI_TRX_STATUS_COLOR?.[taskCard?.elphiStatus]
                      ? ELPHI_TRX_STATUS_COLOR[taskCard?.elphiStatus]
                      : ELPHI_TRX_STATUS_COLOR?.default
                  }
                  ml={5}
                  color={
                    ELPHI_TRX_STATUS_COLOR_TEXT[
                      taskCard?.elphiStatus || ElphiTrxStatus.Pending
                    ]
                  }
                >
                  {
                    ELPHI_TRX_STATUS_TOOLTIP_MESSAGES[
                      taskCard?.elphiStatus || ElphiTrxStatus.Pending
                    ]
                  }
                </Badge>
              )}
            </Box>
            <Box>
              {taskCard?.kct && taskCard.kct !== KeyClosingTaskType.None && (
                <Badge fontSize={12} colorScheme="purple">
                  KCT
                </Badge>
              )}
            </Box>
          </Flex>
        </Flex>
        <Spacer />
        <Flex
          flexDir={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box>
            <TaskStatusDropdown
              onChange={onChange}
              state={state}
              taskId={taskCard?.id}
            />
          </Box>
          <Text fontSize="10px" color="gray.500">
            {taskCard.modifiedAt}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
