import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  useDisclosure
} from "@chakra-ui/react";
import { RolodexServiceProvider, ServiceProviderType } from "@elphi/types";
import { useEffect, useState } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../constants/common";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import { useQueryParamsHooks } from "../../../../hooks/queryParams.hooks";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import { AccordionSections } from "../../../ScrollableSections";
import { SectionHeader } from "../../../form-builder/FormBuilder";
import TaskPage from "../../../task/TaskPage";
import { InfoFormSection } from "../../service-provider/form-section/InfoFormSection";
import { serviceProviderListHooks } from "../../service-provider/list-card/serviceProviderList.hooks";
import { ServiceProviderCreateModal } from "../../service-provider/modal/ServiceProviderCreateModal";
import {
  BranchRepRelationRemoveModal,
  ServiceProviderDeleteModal
} from "../../service-provider/modal/ServiceProviderDeleteModal";
import {
  FormModalProps,
  ServiceProviderFormModal
} from "../../service-provider/modal/ServiceProviderFormModal";
import { ServiceProviderSearch } from "../../service-provider/search/ServiceProviderSearch";
import { ServiceProviderTree } from "../../service-provider/tree/ServiceProviderTree";
import { ServiceProviderNode } from "../../service-provider/tree/serviceProviderTree.utils";
import { navigationPath } from "../../service-provider/utils/serviceProvider.utils";

export const ServiceProviderTab = () => {
  const [highlighted, setHighlighted] = useState<ServiceProviderNode>();
  const [selected, setSelected] = useState<RolodexServiceProvider>();
  const [formData, setFormData] = useState<FormModalProps["data"]>();
  const { isOpen: isOpenCreate, onToggle: onToggleCreate } = useDisclosure();
  const { isOpen: isOpenAdd, onToggle: onToggleAdd } = useDisclosure();
  const { isOpen: isOpenDelete, onToggle: onToggleDelete } = useDisclosure();
  const { isOpen: isOpenRemove, onToggle: onToggleRemove } = useDisclosure();
  const { getPaginateConfigurations } = useRolodexConfiguration();
  const {
    selectedServiceProvider,
    setSelectedServiceProvider,
    getByIdHandler
  } = useServiceProviderHooks();
  const { filteredByDealId } = serviceProviderListHooks();
  const params = useQueryParamsHooks();
  const paramsServiceProviderId = params.get("serviceProvider");

  useAuthStateChangeHook({
    onAuthStateChange: (currentUser) => {
      setSelectedServiceProvider(EMPTY);
      if (currentUser) {
        getPaginateConfigurations(
          {
            limit: 300
          },
          true
        );
        if (paramsServiceProviderId) {
          getByIdHandler({ id: paramsServiceProviderId }).then(() => {
            setSelectedServiceProvider(paramsServiceProviderId);
            window.history.replaceState(null, EMPTY, window.location.pathname);
          });
        }
      }
    },
    deps: [paramsServiceProviderId]
  });

  useEffect(() => {
    selectedServiceProvider?.id
      ? setSelected(selectedServiceProvider)
      : setSelected(undefined);
  }, [selectedServiceProvider?.id]);

  useEffect(() => {
    setSelected(highlighted);
  }, [highlighted]);

  const onSelectHighlighted = (selected: ServiceProviderNode) => {
    setHighlighted(selected);
  };

  const handleOnAddClick = (selected: ServiceProviderNode) => {
    if (selected.type === ServiceProviderType.Company) {
      setFormData({
        domainConfigurationId: selected.domainConfigurationId,
        type: selected.type,
        companyId: selected.id,
        branchId: EMPTY,
        representativeId: EMPTY
      });
    }
    if (selected.type === ServiceProviderType.Branch) {
      setFormData({
        domainConfigurationId: selected.domainConfigurationId,
        type: selected.type,
        companyId: selected.companyId,
        branchId: selected.id,
        representativeId: EMPTY
      });
    }

    setHighlighted(selected);
    onToggleAdd();
  };

  const handleOnDeleteSelectedClick = () => {
    onToggleDelete();
  };

  const handleOnRemoveNodeClick = (node: ServiceProviderNode) => {
    setHighlighted(node);
    onToggleRemove();
  };

  return (
    <Box h={"100%"} w="100%">
      <Flex justifyContent="space-between" wrap={"wrap"}>
        <Box width={"400px"}>
          <ServiceProviderSearch />
        </Box>
        <HStack spacing={2} wrap={"wrap"}>
          <Box p={"10px"}>
            <Button
              {...elphiTheme.components.light.button.secondary}
              isDisabled={!selectedServiceProvider}
              onClick={handleOnDeleteSelectedClick}
            >
              Delete Service Provider
            </Button>
          </Box>
          <Box p={"10px"}>
            <Button
              {...elphiTheme.components.light.button.primary}
              onClick={onToggleCreate}
            >
              Create New Service Provider
            </Button>
          </Box>
        </HStack>
      </Flex>
      <ServiceProviderCreateModal
        filteredItems={filteredByDealId}
        isShow={isOpenCreate}
        onClose={onToggleCreate}
        dealId={EMPTY}
        showEntitiesStep={false}
      />
      <ServiceProviderFormModal
        key={`${isOpenAdd}_serviceProviderFormModal`}
        isShow={isOpenAdd}
        onClose={onToggleAdd}
        data={formData}
      />
      {selectedServiceProvider && (
        <ServiceProviderDeleteModal
          isShow={isOpenDelete}
          onClose={onToggleDelete}
          selectedServiceProvider={selectedServiceProvider}
        />
      )}
      {highlighted && (
        <BranchRepRelationRemoveModal
          isShow={isOpenRemove}
          onClose={onToggleRemove}
          selectedNode={highlighted}
        />
      )}
      <Box h="100%" w="100%" overflow="scroll">
        {selectedServiceProvider ? (
          <>
            <AccordionSections
              customKey="serviceProvider"
              sections={[
                {
                  index: 0,
                  header: <SectionHeader header={"Tree"} />,
                  body: selectedServiceProvider ? (
                    <Box overflowY={"auto"} pb={2}>
                      <ServiceProviderTree
                        selected={selectedServiceProvider}
                        onSelectHighlighted={onSelectHighlighted}
                        onAddClick={handleOnAddClick}
                        onDeleteClick={handleOnRemoveNodeClick}
                      />
                    </Box>
                  ) : undefined
                }
              ]}
              navigationPath={navigationPath}
            />
            <InfoFormSection selected={selected} />
            <AccordionSections
              customKey="serviceProviderChecklist"
              sections={[
                {
                  index: 2,
                  header: <SectionHeader header={"Checklist"} />,
                  body: <TaskPage serviceProviderId={selected?.id} />
                }
              ]}
              navigationPath={navigationPath}
            />
          </>
        ) : (
          <Center height={"70vh"}>
            <Box fontSize={"30px"} fontWeight={"700"}>
              Search a Service Provider
            </Box>
          </Center>
        )}
      </Box>
    </Box>
  );
};
