import { ElphiEntityType } from "../../../../../elphiEntities.types";
import { LoanpassEventObject } from "../event-models";
import { ElphiToLoanpassMappingType } from "./elphiToLoanpass.types";
import { LoanpassToElphiMappingType } from "./loanpassToElphi.types";

export enum MappingDirection {
  In = "in",
  Out = "out"
}

export type OutLoanpassEntityMapping = Partial<{
  [key in ElphiEntityType]: {
    [loanpassPath: string]: ElphiToLoanpassMappingType;
  };
}>;

export type InLoanpassEntityMapping = Partial<{
  [key in LoanpassEventObject["message"]]: {
    [elphiPath: string]: LoanpassToElphiMappingType;
  };
}>;

export type LoanpassDirectionsMapping = {
  [MappingDirection.Out]: OutLoanpassEntityMapping;
  [MappingDirection.In]: InLoanpassEntityMapping;
};
