import { Type } from "@sinclair/typebox";
import { ElphiEntityType } from "../../../entities";

const InMappingSchema = Type.Array(
  Type.Object({
    elphiField: Type.String(),
    loanpassField: Type.String(),
    requestType: Type.String()
  })
);

const OutMappingSchema = Type.Array(
  Type.Object({
    loanpassField: Type.String(),
    entityType: Type.Enum(ElphiEntityType),
    elphiField: Type.String()
  })
);

const MappingSchema = Type.Object({
  in: Type.Optional(InMappingSchema),
  out: Type.Optional(OutMappingSchema)
});

export const LoanpassDeleteMappingsRequestSchema = Type.Object({
  mappings: MappingSchema
});
