import {
  dateValidation,
  moneyValidation,
  percentValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const statementStart = buildInput({
  fieldKey: baseElphiFieldsSpecs.statement?.entitySpecs.start?.fieldKey,
  validation: dateValidation
});

export const statementEnd = buildInput({
  fieldKey: baseElphiFieldsSpecs.statement?.entitySpecs.end?.fieldKey,
  validation: dateValidation
});

export const statementBalance = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.statement?.entitySpecs.statementBalance?.fieldKey,
  validation: moneyValidation
});

export const unverifiedDeposits = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.statement?.entitySpecs.unverifiedDeposits?.fieldKey,
  validation: moneyValidation
});

export const percentConsidered = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.statement?.entitySpecs.percentConsidered?.fieldKey,
  validation: percentValidation
});
