import {
  FirebaseCollections,
  NotificationAuditEvent,
  TimestampField
} from "@elphi/types";
import { onSnapshot } from "firebase/firestore";
import { union } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTimestampFromFirestoreObject } from "../firebase/firebase.utils";
import firebase, { auth, firestore } from "../firebase/firebaseConfig";
import { notificationUserAction } from "../redux/v2/notification-user";
import { useOrgHooks } from "./org.hooks";

export const useShouldNotifyUserSubscriber = (props: {
  isWatching: boolean;
}) => {
  const { isWatching } = props;
  const { selectedOrgId } = useOrgHooks();
  const [notify, setNotify] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedOrgId || !auth?.currentUser?.uid) {
      return;
    }

    const query = firestore
      .context({
        orgId: selectedOrgId.toString(),
        user: {
          id: auth.currentUser.uid
        }
      })
      .collection(FirebaseCollections.NotificationUser)
      .doc(auth.currentUser.uid);

    const unsubscribe = onSnapshot(query, (snapshot) => {
      const data = snapshot.data();
      setNotify(data?.notify);
      if (isWatching) {
        unsubscribe();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [selectedOrgId, auth?.currentUser?.uid, isWatching]);

  return { notify, setNotify };
};

export const useNotificationUserSubscriber = () => {
  const { selectedOrgId } = useOrgHooks();
  const dispacher = useDispatch();
  const [hasNew, setHasNew] = useState<boolean>(false);
  const actionDispacher = notificationUserAction(dispacher);

  useEffect(() => {
    if (!selectedOrgId || !auth?.currentUser?.uid) {
      return;
    }
    const dateNow = firebase.firestore.Timestamp.now().toDate();

    const query = firestore
      .context({
        orgId: selectedOrgId.toString(),
        user: {
          id: auth.currentUser.uid
        }
      })
      .collection(FirebaseCollections.NotificationUser)
      .doc(auth.currentUser.uid)
      .collection(FirebaseCollections.Notifications)
      .where("modifiedAt", ">=", dateNow);

    const unsubscribe = onSnapshot(query, async (snapshot) => {
      const changes = snapshot.docChanges().reduce((acc, dc) => {
        const data = dc.doc.data() as NotificationAuditEvent;
        const { id, modifiedAt, notifiedAt } = data;

        const notifiedDate = getTimestampFromFirestoreObject(
          notifiedAt as TimestampField
        )?.toDate();

        const modifiedDate = getTimestampFromFirestoreObject(
          modifiedAt as TimestampField
        )?.toDate();

        if (
          !modifiedAt ||
          (modifiedDate &&
            notifiedDate &&
            notifiedDate.getTime() === modifiedDate.getTime())
        ) {
          acc.added = acc.added ? union(acc.added, [data]) : [data];
        }
        if (
          notifiedDate &&
          modifiedDate &&
          modifiedDate.getTime() > notifiedDate.getTime()
        ) {
          acc.modified = acc.modified
            ? union(acc.modified, [{ id, changes: data }])
            : [{ id, changes: data }];
        }
        return acc;
      }, {} as { modified: { id: string; changes: NotificationAuditEvent }[]; added: NotificationAuditEvent[] });

      if (changes.added?.length) {
        setHasNew(true);
      }
      if (changes.modified?.length) {
        await Promise.all([actionDispacher.update(changes.modified)]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [selectedOrgId, auth?.currentUser?.uid]);

  return { hasNew, setHasNew };
};
