import styled from "@emotion/styled";
import * as style from "../../../assets/redesign/";

export const SidebarContainer = styled("div")((_) => ({
  // height: "100vh",
  height: "100%",
  backgroundColor: `${style.primary600}`,
  display: "flex",
  //width: "64px",
  flexDirection: "column"
}));

export const NavItemsContainer = styled("div")((_) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  marginBottom: "30px"
}));

export const Image = styled("img")(() => ({
  height: "28px",
  width: "36px"
}));

export const LogoContainer = styled("div")((_) => ({
  marginTop: "20px",
  marginBottom: "40px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around"
}));

export const UserIcon = styled("div")((_) => ({
  //backgroundColor: `${style.secondary200}`,
  // width: "32px",
  // height: "32px",
  fontFamily: "Heebo",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  borderRadius: "50%",
  lineHeight: "34px",
  letterSpacing: "normal",
  textAlign: "center",
  //color: `${style.secondary500}`,
  display: "flex",
  alignSelf: "center",
  flexDirection: "row",
  justifyContent: "space-around",
  position: "absolute",
  bottom: "20px",
  cursor: "pointer"
}));
