import { createIcon } from "@chakra-ui/react";

const customBuildingIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.6918 2.35844C5.34818 1.70206 6.23842 1.33331 7.16668 1.33331H18.8333C19.7616 1.33331 20.6518 1.70206 21.3082 2.35844C21.9646 3.01482 22.3333 3.90505 22.3333 4.83331V22.3333H23.5C24.1443 22.3333 24.6667 22.8556 24.6667 23.5C24.6667 24.1443 24.1443 24.6666 23.5 24.6666H2.50001C1.85568 24.6666 1.33334 24.1443 1.33334 23.5C1.33334 22.8556 1.85568 22.3333 2.50001 22.3333H3.66668V4.83331C3.66668 3.90506 4.03543 3.01482 4.6918 2.35844ZM6.00001 22.3333H9.50001V17.6666C9.50001 17.0478 9.74584 16.4543 10.1834 16.0167C10.621 15.5791 11.2145 15.3333 11.8333 15.3333H14.1667C14.7855 15.3333 15.379 15.5791 15.8166 16.0167C16.2542 16.4543 16.5 17.0478 16.5 17.6666V22.3333H20V4.83331C20 4.52389 19.8771 4.22715 19.6583 4.00835C19.4395 3.78956 19.1428 3.66665 18.8333 3.66665H7.16668C6.85726 3.66665 6.56051 3.78956 6.34172 4.00835C6.12293 4.22715 6.00001 4.52389 6.00001 4.83331V22.3333ZM14.1667 22.3333V17.6666H11.8333V22.3333H14.1667ZM8.33334 7.16665C8.33334 6.52231 8.85568 5.99998 9.50001 5.99998H10.6667C11.311 5.99998 11.8333 6.52231 11.8333 7.16665C11.8333 7.81098 11.311 8.33331 10.6667 8.33331H9.50001C8.85568 8.33331 8.33334 7.81098 8.33334 7.16665ZM14.1667 7.16665C14.1667 6.52231 14.689 5.99998 15.3333 5.99998H16.5C17.1443 5.99998 17.6667 6.52231 17.6667 7.16665C17.6667 7.81098 17.1443 8.33331 16.5 8.33331H15.3333C14.689 8.33331 14.1667 7.81098 14.1667 7.16665ZM8.33334 11.8333C8.33334 11.189 8.85568 10.6666 9.50001 10.6666H10.6667C11.311 10.6666 11.8333 11.189 11.8333 11.8333C11.8333 12.4776 11.311 13 10.6667 13H9.50001C8.85568 13 8.33334 12.4776 8.33334 11.8333ZM14.1667 11.8333C14.1667 11.189 14.689 10.6666 15.3333 10.6666H16.5C17.1443 10.6666 17.6667 11.189 17.6667 11.8333C17.6667 12.4776 17.1443 13 16.5 13H15.3333C14.689 13 14.1667 12.4776 14.1667 11.8333Z"
          stroke="#A2C1FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const BuildingIcon = customBuildingIcon();
