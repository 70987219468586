import { SystemSetup } from "../../../common";
import { AssetType } from "../../../entities";
import { elphiAccountAssetSchema } from "../../base/asset.typebox";
import { limaAssetSchema } from "../../lima/asset.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type AssetEnvToSchemaMap = {
  [AssetType.Account]: EnvToSchemasMap;
};

export const assetSchemas: AssetEnvToSchemaMap = {
  [AssetType.Account]: {
    [SystemSetup.Elphi]: elphiAccountAssetSchema,
    [SystemSetup.Lima]: limaAssetSchema,
    [SystemSetup.Mofin]: elphiAccountAssetSchema,
    [SystemSetup.LedgerTC]: elphiAccountAssetSchema
  }
};
