import {
  CalendarActionStatus,
  DealCalendarDealListDateType,
  DealCalendarType,
  TimestampField
} from "@elphi/types";
import { DATES_FORMATS, elphiDate, filterDealCalendar } from "@elphi/utils";
import { compact, isEmpty, isNumber, without } from "lodash";
import { getTimestampFromFirestoreObject } from "../../../firebase/firebase.utils";
import { DateType } from "../../../shared";
import { DealTableFilterState } from "../deal/deal.slice";
import { Dates, DealCalendarSliceStateFields } from "./dealCalendar.slice";

const filterDeal = (
  dealCalendar: DealCalendarType,
  filter: DealTableFilterState
) => {
  const {
    lenderIdentifierOp,
    lenderIdentifier,
    dealMilestoneOp,
    dealMilestone,
    selectedUserId,
    dealParties,
    estimatedClosingDateRange,
    loanProgramTypes,
    totalLoanAmount
  } = filter || {};
  if (
    [
      lenderIdentifier,
      selectedUserId,
      dealMilestone,
      dealParties,
      estimatedClosingDateRange,
      loanProgramTypes,
      totalLoanAmount
    ].every(isEmpty)
  ) {
    return !!dealCalendar;
  }

  const esDateRange = compact([
    estimatedClosingDateRange?.from,
    estimatedClosingDateRange?.to
  ]);

  return filterDealCalendar(dealCalendar, {
    lender: lenderIdentifier,
    lenderOp: lenderIdentifierOp,
    milestone: dealMilestone,
    milestoneOp: dealMilestoneOp,
    userId: selectedUserId,
    dealParties,
    estimatedClosingDateRange: esDateRange,
    loanProgramTypes,
    totalLoanAmount: getTotalAmountUpdated(
      dealCalendar.requestedLoanAmount,
      totalLoanAmount
    )
  });
};

const getTotalAmountUpdated = (
  requestedLoanAmount?: string,
  totalLoanAmount?: string[]
) => {
  if (totalLoanAmount?.length === 2) {
    const [minAmount, maxAmount] = totalLoanAmount;
    const updatedMinAmount =
      Number(requestedLoanAmount) < Number(minAmount || 0)
        ? Number(requestedLoanAmount)
        : minAmount || 0;
    const updatedMaxAmount =
      Number(requestedLoanAmount) > (Number(maxAmount || 0) || 0)
        ? Number(requestedLoanAmount)
        : maxAmount || 0;
    return [updatedMinAmount.toString(), updatedMaxAmount.toString()];
  }
  return undefined;
};

const removeDealFromPreviousDate = (
  id: string,
  dates: Dates,
  selectedDateType: DateType,
  requestedLoanAmount?: string,
  previousDate?: string
) => {
  if (previousDate) {
    const previousDayDealCalendar = dates[selectedDateType][previousDate];
    if (previousDayDealCalendar) {
      if (previousDayDealCalendar?.totalRequestedLoanAmount) {
        previousDayDealCalendar.totalRequestedLoanAmount -= Number(
          requestedLoanAmount || 0
        );
      }
      if (previousDayDealCalendar.totalDealsCalendar > 0) {
        previousDayDealCalendar.totalDealsCalendar -= 1;
      }
      const filteredDayDealCalendar = previousDayDealCalendar.deals.filter(
        (deal) => deal.id !== id
      );
      previousDayDealCalendar.deals = filteredDayDealCalendar;
      previousDayDealCalendar.dealsIds = without(
        previousDayDealCalendar.dealsIds,
        id
      );
    }
  }
};

const addDealToNewDate = (
  dates: Dates,
  selectedDateType: DateType,
  dealCalendar: DealCalendarType,
  requestedLoanAmount?: string,
  currentDate?: string
) => {
  if (!currentDate || !dealCalendar?.id) {
    return undefined;
  }

  const newDayDealCalendar = dates[selectedDateType][currentDate];
  if (newDayDealCalendar) {
    if (isNumber(newDayDealCalendar?.totalRequestedLoanAmount)) {
      newDayDealCalendar.totalRequestedLoanAmount += Number(
        requestedLoanAmount || 0
      );
    }
    newDayDealCalendar.totalDealsCalendar += 1;
    newDayDealCalendar.deals.push(dealCalendar);
    newDayDealCalendar.dealsIds.push(dealCalendar.id);
  } else {
    // create new date
    dates.closingDate[currentDate] = {
      deals: [dealCalendar],
      totalDealsCalendar: 1,
      dealsIds: [dealCalendar.id],
      totalRequestedLoanAmount: Number(requestedLoanAmount || 0)
    };
  }
};

const calcTotalRequestedLoanAmount = (
  dayDealCalendar: DealCalendarDealListDateType,
  currentDeal?: DealCalendarType
) => {
  const { previousRequestedLoanAmount, requestedLoanAmount } =
    currentDeal || {};

  if (previousRequestedLoanAmount === requestedLoanAmount) {
    return dayDealCalendar.totalRequestedLoanAmount;
  }

  if (!dayDealCalendar.totalRequestedLoanAmount) {
    return Number(requestedLoanAmount || 0);
  }

  return (
    (dayDealCalendar.totalRequestedLoanAmount || 0) -
    Number(previousRequestedLoanAmount || 0) +
    Number(requestedLoanAmount || 0)
  );
};

const updateDealCalendarValues = (
  id: string,
  dates: Dates,
  selectedDateType: DateType,
  dealCalendar: DealCalendarType,
  previousDate?: string,
  currentDate?: string
) => {
  if (previousDate && currentDate) {
    const dayDealCalendar = dates[selectedDateType][currentDate];
    if (dayDealCalendar) {
      const filteredDeals = dayDealCalendar.deals.filter(
        (deal) => deal.id !== id
      );

      const isDealCalendarInDay = dayDealCalendar.dealsIds.includes(id);

      if (!isDealCalendarInDay) {
        dayDealCalendar.totalDealsCalendar += 1;
        dayDealCalendar.dealsIds.push(id);
        dayDealCalendar.totalRequestedLoanAmount += Number(
          dealCalendar.requestedLoanAmount || 0
        );
      }

      dayDealCalendar.deals = [...filteredDeals, dealCalendar];
    }
  }
};

const updateTotalRequestedLoanAmount = (
  dates: Dates,
  selectedDateType: DateType,
  dealCalendar: DealCalendarType,
  previousDate?: string,
  currentDate?: string
) => {
  if (previousDate && currentDate) {
    const dayDealCalendar = dates[selectedDateType][currentDate];

    if (dayDealCalendar) {
      const filteredDeals = dayDealCalendar.deals.filter(
        (deal) => deal.id !== dealCalendar.id
      );

      dayDealCalendar.deals = [...filteredDeals, dealCalendar];

      const totalRequestedLoanAmount = calcTotalRequestedLoanAmount(
        dayDealCalendar,
        dealCalendar
      );

      dayDealCalendar.totalRequestedLoanAmount = totalRequestedLoanAmount || 0;
    }
  }
};

const updateNonFilteredDealCalendar = (
  dates: Dates,
  selectedDateType: DateType,
  dealCalendar: DealCalendarType,
  isDealCalendarFiltered: boolean,
  currentDate?: string
) => {
  if (
    !currentDate ||
    (selectedDateType === "closingDate" && currentDate === "none")
  ) {
    return;
  }
  const { id = "", requestedLoanAmount } = dealCalendar || {};

  const dayDealCalendar = dates[selectedDateType][currentDate];
  const filteredDeals = dayDealCalendar?.deals?.filter(
    (deal) => deal.id !== dealCalendar.id
  );

  const isDealCalendarInDay = dayDealCalendar?.dealsIds?.includes(id);

  if (isDealCalendarInDay) {
    dayDealCalendar.totalRequestedLoanAmount -= Number(
      requestedLoanAmount || 0
    );
    dayDealCalendar.totalDealsCalendar -= 1;
    dayDealCalendar.deals = filteredDeals;
    dayDealCalendar.dealsIds = without(dayDealCalendar.dealsIds, id);
  }

  if (isDealCalendarFiltered) {
    dayDealCalendar.totalRequestedLoanAmount += Number(
      requestedLoanAmount || 0
    );
    dayDealCalendar.totalDealsCalendar += 1;
    dayDealCalendar.deals.push(dealCalendar);
    dayDealCalendar.dealsIds.push(id);
  }

  return;
};

const syncDateData = ({
  dates,
  selectedDateType,
  dealCalendar,
  isDealCalendarFiltered,
  previousDate,
  currentDate
}: {
  dates: Dates;
  selectedDateType: DateType;
  dealCalendar: DealCalendarType;
  isDealCalendarFiltered: boolean;
  previousDate?: string;
  currentDate?: string;
}) => {
  const { id = "", requestedLoanAmount, action } = dealCalendar || {};

  if (!isDealCalendarFiltered && action !== CalendarActionStatus.Move) {
    updateNonFilteredDealCalendar(
      dates,
      selectedDateType,
      dealCalendar,
      isDealCalendarFiltered,
      currentDate
    );
    return;
  }

  const calendarActionMap: Record<CalendarActionStatus, () => void> = {
    [CalendarActionStatus.Create]: () =>
      addDealToNewDate(
        dates,
        selectedDateType,
        dealCalendar,
        requestedLoanAmount,
        currentDate
      ),
    [CalendarActionStatus.Move]: () => {
      removeDealFromPreviousDate(
        id,
        dates,
        selectedDateType,
        requestedLoanAmount,
        previousDate
      );
      isDealCalendarFiltered &&
        addDealToNewDate(
          dates,
          selectedDateType,
          dealCalendar,
          requestedLoanAmount,
          currentDate
        );
    },
    [CalendarActionStatus.Update]: () =>
      updateDealCalendarValues(
        id,
        dates,
        selectedDateType,
        dealCalendar,
        previousDate,
        currentDate
      ),
    [CalendarActionStatus.ChangeLoanAmount]: () =>
      updateTotalRequestedLoanAmount(
        dates,
        selectedDateType,
        dealCalendar,
        previousDate,
        currentDate
      ),
    [CalendarActionStatus.PrepareMove]: () => {},
    [CalendarActionStatus.CreateClosingDate]: () => {}
  };

  if (action && calendarActionMap[action]) {
    calendarActionMap[action]();
  }
};

export const syncDealCalendarData = (
  dealCalendar: DealCalendarType,
  state: DealCalendarSliceStateFields
) => {
  const { filter, selectedDateType, dates } = state;

  const { previousEstimatedClosingDate, estimatedClosingDate, createdAt } =
    dealCalendar || {};
  const isDealCalendarFiltered = filterDeal(dealCalendar, filter);

  const createdAtFormatted = elphiDate(
    getTimestampFromFirestoreObject(createdAt as TimestampField)?.toDate()
  ).format(DATES_FORMATS.YYYY_MM_DD);

  syncDateData({
    dates,
    selectedDateType,
    dealCalendar,
    isDealCalendarFiltered,
    currentDate:
      selectedDateType === "createdAt"
        ? createdAtFormatted
        : estimatedClosingDate,
    previousDate:
      selectedDateType === "createdAt"
        ? createdAtFormatted
        : previousEstimatedClosingDate
  });
};
