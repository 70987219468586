import { Tooltip } from "@chakra-ui/react";
import { ElphiTrxStatus, IntegrationTaskTemplate, Task } from "@elphi/types";
import {
  ELPHI_TRX_STATUS_ICON,
  ELPHI_TRX_STATUS_TOOLTIP_MESSAGES
} from "./integrations/integration.type";

export type TaskStatusIconToolTipProps = {
  getOrderStatusTooltip?: (
    Task: Task,
    showOnlyError?: boolean
  ) => string | undefined;
} & IntegrationTaskTemplate;

export const TaskStatusIconToolTip = ({
  selectedTask,
  getOrderStatusTooltip
}: TaskStatusIconToolTipProps) => {
  if (!("elphiTrxStatus" in selectedTask)) {
    return (
      <Tooltip
        hasArrow={true}
        label={
          getOrderStatusTooltip?.(selectedTask) ||
          ELPHI_TRX_STATUS_TOOLTIP_MESSAGES[ElphiTrxStatus.Pending]
        }
        placement={"top-end"}
      >
        {ELPHI_TRX_STATUS_ICON.default}
      </Tooltip>
    );
  }

  const elphiTrxStatus = selectedTask.elphiTrxStatus as ElphiTrxStatus;
  const ElphiTrxStatusIcon =
    ELPHI_TRX_STATUS_ICON?.[elphiTrxStatus] || ELPHI_TRX_STATUS_ICON.default;

  return (
    <Tooltip
      hasArrow={true}
      label={
        getOrderStatusTooltip?.(selectedTask) ||
        ELPHI_TRX_STATUS_TOOLTIP_MESSAGES[elphiTrxStatus]
      }
      placement={"top-end"}
    >
      {ElphiTrxStatusIcon}
    </Tooltip>
  );
};
