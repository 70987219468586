import { TObject, TProperties, Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "../baseEntity.typebox";

export const createEntitySchema = <T extends ReturnType<typeof Type.Object>>(
  t: T
) => {
  const Schema = Type.Intersect([BaseEntitySchema, t]);
  const Update = Type.Intersect([
    Type.Required(Type.Pick(BaseEntitySchema, ["id"])),
    Type.Partial(t)
  ]);
  const Create = Type.Omit(Type.Partial(t), Type.KeyOf(BaseEntitySchema));

  const getOperationSchema = (
    op: "create" | "update"
  ): TObject<TProperties> => {
    if (op === "create") {
      return Create;
    } else if (op === "update") {
      return Update;
    }
    throw `${op} is not supported`;
  };
  return {
    Schema,
    Update,
    Create,
    getOperationSchema
  };
};
