import { EditIcon } from "@chakra-ui/icons";
import { HStack } from "@chakra-ui/react";
import { useTableManagerHook } from "../../hooks/tableManager.hooks";
import { DebounceButton } from "../custom/chakra/DebounceIconButton";
import { RotateLeft } from "../icons";

const TURN_ON_REORDER_COLUMNS_BUTTON_TEXT = "Reorder Columns";
const TURN_OFF_REORDER_COLUMNS_BUTTON_TEXT = "Exit Reorder Mode";
const RESET_COLUMNS_ORDER_BUTTON_TEXT = "Reset Columns Order";

export const OrderableTableBar = ({ tableName }: { tableName: string }) => {
  const { resetOrder, getTableState, toggleReorderMode } =
    useTableManagerHook();

  const tableState = getTableState(tableName);
  const reorderModeButtonText = !tableState?.reorderMode
    ? TURN_ON_REORDER_COLUMNS_BUTTON_TEXT
    : TURN_OFF_REORDER_COLUMNS_BUTTON_TEXT;
  return (
    <HStack spacing={2}>
      <DebounceButton
        size="sm"
        aria-label={"toggle-reorder-mode"}
        icon={<EditIcon stroke={"black"} />}
        transition="all 0.3s ease-out"
        onClick={() => toggleReorderMode({ tableName })}
        text={reorderModeButtonText}
      />
      {tableState?.reorderMode && (
        <DebounceButton
          size="sm"
          aria-label={"reset"}
          icon={<RotateLeft stroke={"black"} />}
          transition="all 0.3s ease-out"
          onClick={() => resetOrder({ tableName })}
          text={RESET_COLUMNS_ORDER_BUTTON_TEXT}
        />
      )}
    </HStack>
  );
};
