import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
export const useScaleChartHook = () => {
  const [currentFactor, setCurrentFactor] = useState(1);
  return {
    currentFactor,
    currectScaleFactor: currentFactor.toFixed(1),
    scaleUp: () => setCurrentFactor(Number((currentFactor + 0.1).toFixed(1))),
    isScaleUpDisabled: currentFactor >= 1,
    scaleDown: () => setCurrentFactor(Number((currentFactor - 0.1).toFixed(1))),
    isScaleDownDisabled: currentFactor <= 0.1
  };
};
export type ScaleChartProps = {
  currectScaleFactor: string;
  scaleUp: () => void;
  isScaleUpDisabled: boolean;
  scaleDown: () => void;
  isScaleDownDisabled: boolean;
};
const ScaleChart = (props: ScaleChartProps) => {
  return (
    <Center w="100%">
      <Flex
        bgColor={elphiTheme.components.light.button.scaleChart.bgColor}
        borderRadius={"10px"}
      >
        <Box>
          <IconButton
            borderRadius={"10px 0 0 10px"}
            bgColor={elphiTheme.components.light.button.scaleChart.bgColor}
            aria-label="scale-down-chart"
            icon={<MinusIcon color="white" />}
            onClick={props.scaleDown}
            isDisabled={props.isScaleDownDisabled}
          />
        </Box>
        <Box>
          <IconButton
            borderRadius={"0 10px 10px 0"}
            bgColor={elphiTheme.components.light.button.scaleChart.bgColor}
            aria-label="scale-up-chart"
            icon={<AddIcon color="white" />}
            onClick={props.scaleUp}
            isDisabled={props.isScaleUpDisabled}
          />
        </Box>
      </Flex>
    </Center>
  );
};

export default ScaleChart;
