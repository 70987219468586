import { Type } from "@sinclair/typebox";
import {
  mofinACHStatusType,
  mofinChannelType,
  mofinDealMilestoneType,
  mofinInsuranceMortgageeStatusType,
  mofinInvestorIdentifierType,
  mofinLenderIdentifierType,
  mofinOperationsDepartmentType,
  mofinPaymentDueDayType,
  mofinServicerIdentifierType,
  mofinWarehouseBankTypeType
} from "../../entities/mofin/deal/mofinDeal.enumerations";
import { elphiDealSchema } from "../base/deal.typebox";
import {
  BooleanField,
  DateField,
  EnumField,
  MoneyAmountField
} from "../utils.typebox";

const DealFieldsSchema = Type.Object({
  DealMetadata: Type.Object({
    milestone: EnumField(mofinDealMilestoneType)
  }),
  ACHStatus: EnumField(mofinACHStatusType),
  MERSLoanIndicator: BooleanField,
  ReceivedRecordablesIndicator: BooleanField,
  SentRecordablesToCustodianDate: DateField,
  ReceivedFinalTitlePolicyIndicator: BooleanField,
  FinalTitlePolicyToCustodianDate: DateField,
  InsuranceMortgageeStatus: EnumField(mofinInsuranceMortgageeStatusType),
  InsuranceMortgageeRequestDate: DateField,
  InsuranceMortgageeRequestFollowUpDate: DateField,
  PayoffPerDiemInterest: MoneyAmountField,
  ServicerIdentifier: EnumField(mofinServicerIdentifierType),
  LenderIdentifier: EnumField(mofinLenderIdentifierType),
  InvestorIdentifier: EnumField(mofinInvestorIdentifierType),
  OperationDepartment: EnumField(mofinOperationsDepartmentType),
  PaymentDueDay: EnumField(mofinPaymentDueDayType),
  WarehouseBankType: EnumField(mofinWarehouseBankTypeType),
  Channel: EnumField(mofinChannelType)
});

export const mofinDealSchema = Type.Intersect([
  elphiDealSchema,
  DealFieldsSchema
]);
