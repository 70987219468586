import { every } from "lodash";
import { getTimestampFromFirestoreObject } from "../../../firebase/firebase.utils";

export const createdAtMillis = (c: any, def: number) => {
  return getTimestampFromFirestoreObject(c)?.toMillis() || def;
};
export const sortComparer = (
  a: { createdAt?: any; index: string },
  b: { createdAt?: any; index: string }
) =>
  createdAtMillis(a.createdAt, 1) < createdAtMillis(b.createdAt, 2) ? 1 : -1;

const sortData = <T extends { [key: string]: any }>(
  data: Record<string, T>,
  comparator: (a: T, b: T) => number
): Record<string, T> => {
  const sortedEntries = Object.entries(data).sort(([, a], [, b]) =>
    comparator(a, b)
  );
  return Object.fromEntries(sortedEntries);
};

const sortByIndex = <T extends { index: number }>(
  data: Record<string, T>
): Record<string, T> => sortData(data, (a, b) => a.index - b.index);

const sortByAlphanumeric = <T extends { fieldPath: string }>(
  data: Record<string, T>
): Record<string, T> =>
  sortData(data, (a, b) =>
    a.fieldPath.toLowerCase() < b.fieldPath.toLowerCase() ? -1 : 1
  );

export const getSortedData = <T extends { index?: number; fieldPath: string }>(
  data: Record<string, T>
): Record<string, T> => {
  const hasIndex = every(data, (v) => "index" in v);
  return hasIndex
    ? sortByIndex(data as Record<string, T & { index: number }>)
    : sortByAlphanumeric(data);
};
