import { Box, Button, Flex, Tooltip } from "@chakra-ui/react";
import {
  FieldType,
  FloodCertificateVendorType,
  ServiceLinkOptions,
  ServiceLinkTrxStatus,
  StatusCode,
  Task
} from "@elphi/types";
import { every } from "lodash";
import { useEffect, useState } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import { AppConfig } from "../../../../config/appConfig";
import { useSections } from "../../../../forms/schemas/providers/SectionsProvider";
import useDealHooks from "../../../../hooks/deal.hooks";
import { useServiceLinkFloodTaskHooks } from "../../../../hooks/serviceLinkFloodTask.hooks";
import useTaskHooks from "../../../../hooks/task.hooks";
import ScrollableSections from "../../../ScrollableSections";
import FormBuilder, {
  OnChangeInput,
  SectionHeader
} from "../../../form-builder/FormBuilder";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import { WindWhiteIcon } from "../../../icons";
import { useElphiToast } from "../../../toast/toast.hook";
import { createOptionsFromMap } from "../../../utils/formUtils";
import { TaskFileManager } from "../../TaskFileManager";
import { IntegrationTaskHeaderForm } from "../IntegrationTaskHeaderForm";
import { ServiceLinkFloodTaskFormProps } from "./serviceLinkFloodTask.type";

export const ServiceLinkFloodTaskForm = (
  props: ServiceLinkFloodTaskFormProps
) => {
  const { selectedTask, propertyState, snapshotId } = props;
  const selectedProperty =
    selectedTask?.entityId && propertyState?.entities[selectedTask?.entityId];

  const {
    updatePropertyHandler,
    optionOrderHandler,
    getServiceLinkTask,
    createServiceLinkFloodOrderResponse
  } = useServiceLinkFloodTaskHooks();
  const { selectedDeal } = useDealHooks();
  const [serviceLinkOption, setServiceLinkOption] = useState<
    ServiceLinkOptions | undefined
  >();

  const [sectionValidationState, setSectionValidationState] = useState<{
    [index: string]: boolean;
  }>({});
  const { updateTasksHandler } = useTaskHooks();
  const { successToast, errorToast } = useElphiToast();
  const { sections } = useSections();

  const serviceLinkFloodTask = getServiceLinkTask(
    selectedTask,
    FloodCertificateVendorType.ServiceLink
  );

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      !snapshotId &&
      propertyOnChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  const {
    onChange: propertyOnChange,
    state: propertyUpdatedState,
    syncState: syncPropertyState
  } = useFormBuilderStateHandler({
    initialState: { properties: {} },
    callback: updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    serviceLinkFloodTask &&
      setServiceLinkOption(serviceLinkFloodTask.serviceOption);
    syncState({
      shouldSync: !!selectedTask,
      state: selectedTask,
      statePath: () => {
        return ["tasks"];
      }
    });
  }, [selectedTask]);
  const { onChange, syncState } = useFormBuilderStateHandler({
    initialState: { tasks: {} as { [id: string]: Task } },
    callback: async (t) => {
      if (!t.tasks) return null;
      return await updateTasksHandler(t.tasks).then((r) => {
        if (r?.status === StatusCode.OK) {
          successToast({
            title: "tasks updated successfully",
            description: `${r.data.batch.length} tasks updated`
          });
        }
        if (r?.status === StatusCode.BadRequest) {
          errorToast({
            title: "update tasks failed",
            description: `${r?.data.error} | ${r?.data.description}`
          });
        }
        return !r ? null : r;
      });
    },
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const selectedUpdatedProperty =
    selectedProperty && propertyUpdatedState.properties[selectedProperty.id];

  const handleOnClickOrder = () => {
    const canOrder =
      serviceLinkOption &&
      selectedTask &&
      selectedUpdatedProperty &&
      selectedDeal;

    if (canOrder) {
      optionOrderHandler({
        dealId: selectedDeal.id,
        taskId: selectedTask.id,
        propertyId: selectedUpdatedProperty.id!,
        option: serviceLinkOption
      });
    }
  };

  const handleOnSelectService = (v?: ServiceLinkOptions) => {
    setServiceLinkOption(v);
    if (serviceLinkFloodTask) {
      onChange({
        fieldKey: ["tasks", serviceLinkFloodTask.id, "serviceOption"],
        fieldType: FieldType.String,
        value: v
      });
    }
  };

  const isClickOrderDisabled =
    !every(sectionValidationState) ||
    !serviceLinkFloodTask?.serviceOption ||
    !serviceLinkFloodTask.isReadyToOrder;

  const actionOptions = createOptionsFromMap(ServiceLinkOptions).map((x) => {
    return {
      ...x,
      isDisabled:
        x.value === ServiceLinkOptions.UpdateCertificateOrder &&
        serviceLinkFloodTask?.serviceLinkTrxStatus !==
          ServiceLinkTrxStatus.DelayA1 &&
        serviceLinkFloodTask?.serviceLinkTrxStatus !==
          ServiceLinkTrxStatus.DelayA2 &&
        serviceLinkFloodTask?.serviceLinkTrxStatus !==
          ServiceLinkTrxStatus.DelayA3
    };
  });

  useEffect(() => {
    selectedProperty &&
      syncPropertyState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
  }, [selectedProperty]);

  const { integrations: integrationsSections } = sections || {};
  const serviceLinkFloodSections = selectedUpdatedProperty
    ? [
        integrationsSections?.property?.FloodCertification?.ServiceLink
          ?.serviceLinkFloodOrderSection,
        integrationsSections?.property?.FloodCertification?.ServiceLink
          ?.serviceLinkFloodVerifySection,
        integrationsSections?.property?.FloodCertification?.ServiceLink
          ?.serviceLinkFloodStatusSection
      ].map((section) =>
        section?.({
          state: selectedUpdatedProperty,
          onChange: propertyOnChangeBatch
        })
      )
    : [];
  return (
    <>
      {serviceLinkFloodTask && selectedUpdatedProperty && (
        <>
          <Box>
            <TaskFileManager
              selectedTask={selectedTask}
              snapshotId={props.snapshotId}
            />
          </Box>
          <Box>
            <ScrollableSections
              customKey={"serviceLinkFloodTask"}
              sections={[
                {
                  header: (
                    <SectionHeader
                      header={
                        <IntegrationTaskHeaderForm
                          task={selectedTask}
                          elphiTrxStatus={serviceLinkFloodTask.elphiTrxStatus}
                        />
                      }
                    />
                  ),
                  body: (
                    <Flex flexDirection={"row"}>
                      <Box w="400px" mr="50px">
                        <StyledInputBuilder
                          options={actionOptions}
                          label="Choose Service"
                          onChange={(e) =>
                            handleOnSelectService(e.target.value)
                          }
                          isClearable={true}
                          isValid={true}
                          fieldType={FieldType.SingleSelect}
                          currentValue={serviceLinkOption}
                        />
                      </Box>
                      <Tooltip
                        label={
                          serviceLinkOption || "Please choose a service first"
                        }
                      >
                        <Button
                          onClick={handleOnClickOrder}
                          isLoading={
                            createServiceLinkFloodOrderResponse.isLoading
                          }
                          isDisabled={isClickOrderDisabled}
                          iconSpacing={0}
                          leftIcon={<WindWhiteIcon boxSize="8" pt="8px" />}
                          color="white"
                          {...elphiTheme.components.light.button.scaleChart}
                        >
                          Order
                        </Button>
                      </Tooltip>
                    </Flex>
                  )
                }
              ]}
            />
            <FormBuilder
              customKey={"serviceLinkFloodSections"}
              setSectionValidationState={setSectionValidationState}
              isDisabled={!!snapshotId}
              size={{
                minW: "300px"
              }}
              onChange={propertyOnChangeBatch}
              sections={serviceLinkFloodSections}
            />
          </Box>
        </>
      )}
    </>
  );
};
