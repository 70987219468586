import {
  DeepPartial,
  FieldType,
  ThirdPartyAggregationField
} from "@elphi/types";
import { NOT_AVAILABLE } from "../../../../constants/common";
import {
  EntityFormFieldSpecsHelper,
  InputBuilderFieldSpecs
} from "../fields.types";

export const getFocusedAggregationSpecs = (r: {
  focused?: "override" | "calculated" | "thirdParty" | undefined;
  spec: DeepPartial<
    EntityFormFieldSpecsHelper<ThirdPartyAggregationField | undefined, any>,
    InputBuilderFieldSpecs<any>
  >;
}): InputBuilderFieldSpecs<any> => {
  const spec =
    r.focused === "calculated"
      ? r.spec?.calculated
      : r.focused === "thirdParty"
      ? r.spec?.thirdParty
      : r.spec?.override;

  return (
    spec || {
      fieldKey: [],
      fieldType: FieldType.String,
      label: NOT_AVAILABLE
    }
  );
};
