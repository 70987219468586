import { Box } from "@chakra-ui/react";
import { RichTextEditor } from "../../common-components";
import ScrollableSections from "../ScrollableSections";
import { SectionHeader } from "../form-builder/FormBuilder";

export const TaskInstructionContainer = (props: {
  instructions: string;
  title: string;
}) => {
  const { instructions, title } = props;
  return (
    <Box bgColor={"white"}>
      <ScrollableSections
        customKey={`taskInstructions-${title}`}
        sections={[
          {
            header: <SectionHeader header={title} />,
            body: <RichTextEditor mode={"view"} text={instructions} />
          }
        ]}
      />
    </Box>
  );
};
