// import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import "firebase/compat/performance";
import { FirebaseCollections } from "@elphi/types";
import "firebase/compat/storage";

import { ElphiContext } from "../../../../shared/types/services/service.types";
import { getFirebaseConfig } from "../utils/envUtils";

// Initialize Firebase
firebase.initializeApp(getFirebaseConfig());
if (process.env.REACT_APP_USE_FIRESTORE_EMULATOR) {
  console.log("running local, connecting emulator on 8080");
  firebase.firestore().useEmulator("localhost", 8080);

  if (process.env.REACT_APP_USE_AUTH_EMULATOR) {
    //Activate for using local emulator authentication
    firebase.auth().useEmulator("http://127.0.0.1:9099/");
  }
}

const firestoreInstance = firebase.firestore();
export type ElphiFirestoreClient = {
  instance: firebase.firestore.Firestore;
  context: (context: ElphiContext) => {
    doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    collection: (
      collectionPath: string
    ) => firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  };
};
export const firestore: ElphiFirestoreClient = {
  instance: firebase.firestore(),
  context: (context: ElphiContext) => ({
    doc: firestoreInstance
      .collection(FirebaseCollections.Organization)
      .doc(context.orgId),
    collection: (sc: string) =>
      sc === FirebaseCollections.Organization
        ? firestoreInstance.collection(FirebaseCollections.Organization)
        : firestoreInstance
            .collection(FirebaseCollections.Organization)
            .doc(context.orgId)
            .collection(sc)
  })
};
export const auth = firebase.auth();

// firebase.analytics();
// firebase.performance();

export const secondaryApp = firebase.initializeApp(
  getFirebaseConfig(),
  "Secondary"
);

export default firebase;
