import { TaskInstruction } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { EMPTY } from "../../../constants/common";
import sliceBuilder from "../builders/slice.builder";
import { FiltersState } from "../types/stateWithFilter.types";
import {
  initCombination,
  setCombinationInfo,
  setFilterAndCombination
} from "../utils/stateFilter.utils";
import { taskInstructionAdapter } from "./taskInstruction.adapter";
import { taskInstructionApi } from "./taskInstruction.service";

export type TaskInstructionSliceState = EntityState<TaskInstruction> & {
  selectedId?: EntityId;
  filters: FiltersState<TaskInstruction, "name" | "status">;
};

const initialState: TaskInstructionSliceState = {
  ids: [],
  entities: {},
  selectedId: undefined,
  filters: {
    current: {
      name: [],
      status: []
    },
    combinations: {
      [EMPTY]: initCombination()
    },
    currentCombination: EMPTY
  }
};

export const taskInstructionSlice = createSlice({
  name: "taskInstruction",
  initialState: taskInstructionAdapter.getInitialState(initialState),
  reducers: {
    update: taskInstructionAdapter.updateOne,
    remove: taskInstructionAdapter.removeOne,
    add: taskInstructionAdapter.addOne,
    upsert: taskInstructionAdapter.upsertOne,
    upsertMany: taskInstructionAdapter.upsertMany,
    removeMany: taskInstructionAdapter.removeMany,
    updateMany: taskInstructionAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    setFilterAndCombination: (
      state,
      action: PayloadAction<{
        combination: string;
        filters: TaskInstructionSliceState["filters"];
      }>
    ) => setFilterAndCombination(state, action),

    setFilterCombinationDetails: (
      state,
      action: PayloadAction<
        TaskInstructionSliceState["filters"]["combinations"][string]
      >
    ) => setCombinationInfo(state, action)
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(taskInstructionApi)(builder);
    builder.addMatcher(
      taskInstructionApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      taskInstructionApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page, "id")
        };
      }
    );
    builder.addMatcher(
      taskInstructionApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
  }
});
