import { CloseIcon, ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  IconButton,
  Link,
  Text,
  Tooltip
} from "@chakra-ui/react";

export const TemplateNotification = (props: {
  onClose: () => void;
  title: string;
  body: JSX.Element;
  boxProps?: BoxProps;
}) => {
  return (
    <Box
      zIndex="9999"
      bgColor="blue.500"
      borderRadius={"8px"}
      color="white"
      p="10px"
      {...props.boxProps}
    >
      <Flex pt="10px" w="100%">
        <Box>
          <InfoIcon w="20px" h="20px" />
        </Box>
        <Box pl="10px">
          <Box>
            <Text fontWeight={"bold"} fontSize="16px">
              {props.title}
            </Text>
          </Box>
          <Box>{props.body}</Box>
        </Box>
        <Box pl="10px">
          <IconButton
            bgColor={"transparent"}
            size="xs"
            color="white"
            icon={<CloseIcon />}
            aria-label="close-notification-btn"
            onClick={() => props.onClose()}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export const LinkTemplate = (props: {
  href: string;
  tooltipLabel: string;
  btnLabel: string;
}) => {
  return (
    <Box>
      <Tooltip
        label={props.tooltipLabel}
        aria-label="new-deal-assigned-notification"
      >
        <Box p="5px">
          <Link href={props.href} isExternal>
            <Button
              bgColor={"transparent"}
              color="white"
              size="xs"
              leftIcon={<ExternalLinkIcon />}
              aria-label="btn-quick-view"
            >
              {props.btnLabel}
            </Button>
          </Link>
        </Box>
      </Tooltip>
    </Box>
  );
};
