import {
  BasePartyRelation,
  EntityToEntityRelation,
  EntityToIndividualRelation,
  FieldType,
  PartyRelationType
} from "@elphi/types";
import {
  baseEntityToEntityRelationRoleType,
  baseEntityToIndividualRelationRoleType
} from "@elphi/types/entities/base";
import { baseEntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/baseEntity.fields";
import {
  EntityFormFieldSpecs,
  SpecsBaseOmit
} from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";

export type BaseSchemaPartyRelationFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<BasePartyRelation<PartyRelationType>>
>;
export type BaseSchemaEntityToEntityFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<EntityToEntityRelation>
>;

export type BaseSchemaEntityToIndividualFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<EntityToIndividualRelation>
>;

export const baseSchemaPartyRelationFieldSpecs: BaseSchemaPartyRelationFieldSpecs =
  {
    ...baseEntityFormFieldSpecs,
    type: {
      fieldKey: ["type"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(PartyRelationType),
      label: "Party Relation Type"
    },
    parentId: {
      fieldKey: ["parentId"],
      fieldType: FieldType.String,
      label: "Parent ID"
    },
    childId: {
      fieldKey: ["childId"],
      fieldType: FieldType.String,
      label: "Child ID"
    },
    ownershipPercentage: {
      fieldKey: ["ownershipPercentage"],
      fieldType: FieldType.Percentage,
      label: "Ownership Percentage"
    }
  };

export const baseSchemaEntityToEntityFieldBaseSpecs: BaseSchemaEntityToEntityFieldSpecs =
  {
    ...baseSchemaPartyRelationFieldSpecs,
    relationRoleType: {
      fieldKey: ["relationRoleType"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromMap(baseEntityToEntityRelationRoleType),
      label: "Relation Roles"
    }
  };

export const baseSchemaEntityToIndividualFieldBaseSpecs: BaseSchemaEntityToIndividualFieldSpecs =
  {
    ...baseSchemaPartyRelationFieldSpecs,
    relationRoleType: {
      fieldKey: ["relationRoleType"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromMap(baseEntityToIndividualRelationRoleType),
      label: "Relation Roles"
    }
  };

export const fullBaseSchemaEntityToEntityFieldSpecs = createSpecWithFieldMeta({
  spec: baseSchemaEntityToEntityFieldBaseSpecs
});
export const fullBaseSchemaEntityToIndividualFieldSpecs =
  createSpecWithFieldMeta({
    spec: baseSchemaEntityToIndividualFieldBaseSpecs
  });
