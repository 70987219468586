import { createIcon } from "@chakra-ui/react";

const customDiscussionIcon = () =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 12C6 12 7.5 14 10 14C12.5 14 14 12 14 12"
          stroke="#4A5568"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
          stroke="#4A5568"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 7H13.01"
          stroke="#4A5568"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 7H7.01"
          stroke="#4A5568"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const ReactionIcon = customDiscussionIcon();
