import { css } from "@emotion/react";

export const globalStyle = css`
  @font-face {
    font-family: "Heebo";
    src: url("../fonts/Heebo/static/Heebo-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: "Heebo";
    src: url("../fonts/Heebo/static/Heebo-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: bold;
    font-stretch: normal;
  }
`;

export const TitleLarge = {
  fontFamily: "Heebo",
  fontSize: "41.8px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.15",
  letterSpacing: "normal"
};

export const TitleMedium = {
  fontFamily: "Heebo",
  fontSize: "34.8px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.15",
  letterSpacing: "normal"
};

export const TitleSmall = {
  fontFamily: "Heebo",
  fontSize: "29px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.24",
  letterSpacing: "normal"
};

export const HeaderLarge = {
  fontFamily: "Heebo",
  fontSize: "24.2px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.32",
  letterSpacing: "normal"
};

export const HeaderMedium = {
  fontFamily: "Heebo",
  fontSize: "20.2px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.39",
  letterSpacing: "normal"
};

export const ParagraphLargeBold = {
  fontFamily: "Heebo",
  fontSize: "16.8px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.43",
  letterSpacing: "normal"
};

export const HeaderSmall = {
  fontFamily: "Heebo",
  fontSize: "16.8px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.43",
  letterSpacing: "normal"
};

export const ParagraphLarge400 = {
  fontFamily: "Heebo",
  fontSize: "16.8px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.43",
  letterSpacing: "normal"
};

export const ParagraphMediumBold = {
  fontFamily: "Heebo",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.43",
  letterSpacing: "normal"
};

export const HeaderXSmall = {
  fontFamily: "Heebo",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.43",
  letterSpacing: "normal"
};

export const ParagraphMedium400 = {
  fontFamily: "Heebo",
  fontSize: "14px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.43",
  letterSpacing: "normal"
};

export const ParagraphSmallBold = {
  fontFamily: "Heebo",
  fontSize: "11.7px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.37",
  letterSpacing: "normal"
};

export const ParagraphSmall400 = {
  fontFamily: "Heebo",
  fontSize: "11.7px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.37",
  letterSpacing: "normal"
};

export const ParagraphXSmallBold = {
  fontFamily: "Heebo",
  fontSize: "9.7px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.44",
  letterSpacing: "normal"
};

export const ParagraphXSmall400 = {
  fontFamily: "Heebo",
  fontSize: "9.7px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.44",
  letterSpacing: "normal"
};
