import {
  NavigationItemType,
  NavigationPages,
  NavigationPath
} from "../../../../shared/types/navigation.types";

export const navigationPath: NavigationPath = [
  { type: NavigationItemType.Page, value: NavigationPages.ServiceProvider }
];

import {
  LabelValue,
  RolodexServiceProvider,
  ServiceProviderType
} from "@elphi/types";

export const addProviderOption = {
  [ServiceProviderType.Company]: {
    label: "+ Add a new company",
    value: "addNewCompany"
  },
  [ServiceProviderType.Branch]: {
    label: "+ Add a new branch",
    value: "addNewBranch"
  },
  [ServiceProviderType.Representative]: {
    label: "+ Add a new representative",
    value: "addNewRep"
  }
};

const isPersonalRolodex = (r: {
  provider: RolodexServiceProvider;
  dealPartyIndividuals: Set<string>;
}) =>
  !!r.provider.partyIds.find((partyId) => r.dealPartyIndividuals.has(partyId));

export const buildPersonalRolodexOption = (r: {
  provider: RolodexServiceProvider;
  dealPartyIndividuals: Set<string>;
}): LabelValue & { isPersonalRolodex: boolean } => {
  const { provider } = r;
  return {
    ...buildOption(provider),
    isPersonalRolodex: isPersonalRolodex(r)
  };
};

export const buildOption = (provider: RolodexServiceProvider): LabelValue => {
  const value = provider.id;
  if (provider.type === ServiceProviderType.Representative) {
    if (provider.middleName) {
      return {
        label: `${provider.firstName} ${provider.middleName} ${provider.lastName}`,
        value
      };
    }
    return {
      label: `${provider.firstName} ${provider.lastName}`,
      value
    };
  }
  return {
    label: provider.name,
    value
  };
};

export const ServiceProviderTypeMap = {
  [ServiceProviderType.Company]: "Company",
  [ServiceProviderType.Branch]: "Branch",
  [ServiceProviderType.Representative]: "Representative"
} as const;

export const ServiceProviderShortTypeMap = {
  [ServiceProviderType.Company]: "C",
  [ServiceProviderType.Branch]: "B",
  [ServiceProviderType.Representative]: "R"
} as const;
