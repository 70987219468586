import {
  ElphiEntityType,
  IntegrationType,
  Task,
  TaskConfiguration,
  TaskFlagType,
  TaskType
} from "@elphi/types";
import {
  getDocumentOrderStatusTemplate,
  getDocumentTemplate,
  getIntegrationTemplate
} from "./integrations/template.builder";

export type BaseBuildTaskTemplateRequest<TType extends TaskType> = {
  type: TType;
};
export type DocumentBuildTaskTemplateRequest =
  BaseBuildTaskTemplateRequest<TaskType.Document>;

export type DocumentOrderStatusBuildTaskTemplateRequest =
  BaseBuildTaskTemplateRequest<TaskType.DocumentOrderStatus>;
export type IntegrationBuildTaskTemplateRequest =
  BaseBuildTaskTemplateRequest<TaskType.Integration> & {
    integrationType: IntegrationType;
  };
export type BuildTaskTemplateRequest =
  | DocumentOrderStatusBuildTaskTemplateRequest
  | DocumentBuildTaskTemplateRequest
  | IntegrationBuildTaskTemplateRequest;

export const buildTaskTemplateByType = (r: BuildTaskTemplateRequest) => {
  if (r.type === TaskType.Document) {
    return getDocumentTemplate(r);
  }
  if (r.type === TaskType.Integration) {
    return getIntegrationTemplate(r);
  }
  if (r.type === TaskType.DocumentOrderStatus) {
    return getDocumentOrderStatusTemplate(r);
  }
};

export const getTaskTemplate = (r?: BuildTaskTemplateRequest) => {
  const specificTaskTemplate = r ? buildTaskTemplateByType(r) : {};
  const task: Partial<Task> = {
    name: "",
    configurationId: "",
    templateId: "",
    ruleId: "",
    entityId: "",
    entityType: "" as ElphiEntityType,
    keyClosingTaskType: {} as TaskConfiguration["keyClosingTaskType"],
    generation: "",
    autoGenerate: {} as TaskConfiguration["autoGenerate"],
    comments: "",
    id: "",
    index: "",
    templateName: "",
    taskStatus: "",
    editableBy: [],
    checklistOf: [],
    assignableBy: [],
    availableActions: [],
    availableStatus: [],
    createdAt: "",
    modifiedAt: "",
    vendorName: "",
    vendorInformation: "",
    receivedDate: "",
    dueDate: "",
    orderedDate: "",
    orderedFrom: "",
    orderStatus: "",
    flagged: "" as TaskFlagType,
    type: "" as TaskType,
    ...specificTaskTemplate
  };
  return task;
};

export const taskTemplateTypeOptions: { [p in TaskType]: string } = {
  [TaskType.Document]: "Document without order status",
  [TaskType.DocumentOrderStatus]: "Document with order status",
  [TaskType.Integration]: "Integration"
};
