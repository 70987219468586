import {
  AggregationFocusType,
  BooleanSelectType,
  FieldType,
  FloodCertificateVendorType,
  FloodCheckStatus,
  FloodCheckStatusShortType,
  IntegrationType,
  ServiceLinkAggregationIntergrationFields,
  ServiceLinkFlatIntergrationFields,
  ServiceLinkOrderStatusShortType,
  ServiceLinkOrderStatusType
} from "@elphi/types";
import {
  createOptionsFromEnumAllowUndefined,
  createOptionsFromEnumSeparateLabels
} from "../../../../../../components/utils/formUtils";
import { mapEnumValues } from "../../../../../../components/utils/general.utils";
import { NOT_AVAILABLE } from "../../../../../../constants/common";
import { IntegrationsFieldSpecs } from "../../../types/integrations.types";

export type ServiceLinkIntegrationsFieldSpecs = IntegrationsFieldSpecs<
  ServiceLinkAggregationIntergrationFields,
  ServiceLinkFlatIntergrationFields
>;

export const serviceLinkIntegrationsFieldSpecs: ServiceLinkIntegrationsFieldSpecs =
  {
    aggregations: {
      Integrations: {
        [IntegrationType.FloodCertificate]: {
          [FloodCertificateVendorType.ServiceLink]: {
            FloodCheckDisclaimer: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckDisclaimer",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Flood Check Disclaimer"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckDisclaimer",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Flood Check Disclaimer"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckDisclaimer",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            SpecialFloodHazardAreaIndicator: {
              calculated: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "SpecialFloodHazardAreaIndicator",
                  "calculated"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Special Flood Hazard Area?",
                options: mapEnumValues(BooleanSelectType)
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "SpecialFloodHazardAreaIndicator",
                  "override"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Special Flood Hazard Area?",
                options: mapEnumValues(BooleanSelectType)
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "SpecialFloodHazardAreaIndicator",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            OrderNumber: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "OrderNumber",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Order Number"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "OrderNumber",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Order Number"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "OrderNumber",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            DeterminationDate: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "DeterminationDate",
                  "thirdParty"
                ],
                fieldType: FieldType.Date,
                label: "Determination Date"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "DeterminationDate",
                  "override"
                ],
                fieldType: FieldType.Date,
                label: "Determination Date"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "DeterminationDate",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            ReturnDate: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ReturnDate",
                  "thirdParty"
                ],
                fieldType: FieldType.Date,
                label: "Order Return Date"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ReturnDate",
                  "override"
                ],
                fieldType: FieldType.Date,
                label: "Order Return Date"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ReturnDate",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            ReturnTime: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ReturnTime",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ReturnTime",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ReturnTime",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            FloodCheckStatus: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckStatus",
                  "thirdParty"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Flood Insurance Required?",
                options: createOptionsFromEnumSeparateLabels(
                  FloodCheckStatusShortType,
                  FloodCheckStatus
                )
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckStatus",
                  "override"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Flood Insurance Required?",
                options: createOptionsFromEnumSeparateLabels(
                  FloodCheckStatusShortType,
                  FloodCheckStatus
                )
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckStatus",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            FloodCheckStatusDescription: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckStatusDescription",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckStatusDescription",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckStatusDescription",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            FloodCheckInfo: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckInfo",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckInfo",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodCheckInfo",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            Disclaimer: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "Disclaimer",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "Disclaimer",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "Disclaimer",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            OrderStatus: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "OrderStatus",
                  "thirdParty"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Order Status",
                options: createOptionsFromEnumSeparateLabels(
                  ServiceLinkOrderStatusShortType,
                  ServiceLinkOrderStatusType
                )
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "OrderStatus",
                  "override"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Order Status",
                options: createOptionsFromEnumSeparateLabels(
                  ServiceLinkOrderStatusShortType,
                  ServiceLinkOrderStatusType
                )
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "OrderStatus",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            NFIPMapCommunityName: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapCommunityName",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "NFIP Map Community Name"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapCommunityName",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "NFIP Map Community Name"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapCommunityName",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            NFIPMapCommunityNumber: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapCommunityNumber",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "NFIP Map Community Number"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapCommunityNumber",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "NFIP Map Community Number"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapCommunityNumber",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            NFIPLegalCommunityNumber: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPLegalCommunityNumber",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "NFIP Legal Community Number"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPLegalCommunityNumber",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "NFIP Legal Community Number"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPLegalCommunityNumber",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            NFIPLegalCommunityName: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPLegalCommunityName",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "NFIP Legal Community Name"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPLegalCommunityName",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "NFIP Legal Community Name"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPLegalCommunityName",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            NFIPMapPanelNumber: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapPanelNumber",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "NFIP Map Panel Number"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapPanelNumber",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "NFIP Map Panel Number"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPMapPanelNumber",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            NFIPPrgStat: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPPrgStat",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPPrgStat",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "NFIPPrgStat",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            FloodMapDate: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodMapDate",
                  "thirdParty"
                ],
                fieldType: FieldType.Date,
                label: "Flood Map Date"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodMapDate",
                  "override"
                ],
                fieldType: FieldType.Date,
                label: "Flood Map Date"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodMapDate",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            FloodZone: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodZone",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Flood Zone"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodZone",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Flood Zone"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "FloodZone",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            GeoPropertyAddress1: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoPropertyAddress1",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoPropertyAddress1",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoPropertyAddress1",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            GeoCity: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoCity",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoCity",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoCity",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            GeoStateCode: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoStateCode",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoStateCode",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoStateCode",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            GeoZipLast4: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoZipLast4",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoZipLast4",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoZipLast4",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            GeoZipFirst5: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoZipFirst5",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoZipFirst5",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoZipFirst5",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            GeoCounty: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoCounty",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoCounty",
                  "override"
                ],
                fieldType: FieldType.String,
                label: NOT_AVAILABLE
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "GeoCounty",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            PropertyMSAMD: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "PropertyMSAMD",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Metro Statistical Area (MSD)"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "PropertyMSAMD",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Metro Statistical Area (MSD)"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "PropertyMSAMD",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            TSDNComments: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "TSDNComments",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Researcher Comments"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "TSDNComments",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Researcher Comments"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "TSDNComments",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            RequestedInformation: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "RequestedInformation",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Additional Requested Information"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "RequestedInformation",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Additional Requested Information"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "RequestedInformation",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            // Error Response
            ResultDescription: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ResultDescription",
                  "thirdParty"
                ],
                fieldType: FieldType.RichText,
                label: "Error Result Description"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ResultDescription",
                  "override"
                ],
                fieldType: FieldType.RichText,
                label: "Error Result Description"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ResultDescription",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            },
            ResultCode: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ResultCode",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Error Result Code"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ResultCode",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Error Result Code"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.FloodCertificate,
                  FloodCertificateVendorType.ServiceLink,
                  "ResultCode",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options:
                  createOptionsFromEnumAllowUndefined(AggregationFocusType)
              }
            }
          }
        }
      }
    },
    Integrations: {
      [IntegrationType.FloodCertificate]: {
        [FloodCertificateVendorType.ServiceLink]: {
          UsersEmailAddress: {
            fieldKey: [
              "Integrations",
              IntegrationType.FloodCertificate,
              FloodCertificateVendorType.ServiceLink,
              "UsersEmailAddress"
            ],
            fieldType: FieldType.String,
            label: "User(s) Email Address"
          }
        }
      }
    }
  };
