import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

import { useRTKPagination } from "../redux/v2/hooks";
import { roleApi, roleSlice } from "../redux/v2/role";
import { compareStrings } from "../utils/searchUtils";

export const useRoleHooks = () => {
  const dispatcher = useDispatch();
  const [searchRole, searchResponse] = roleApi.useLazySearchQuery();
  const setSelectedRole = (id: string) =>
    dispatcher(roleSlice.actions.selectedId({ id }));
  const roleState = useSelector((state: RootState) => state.role);
  const rankedSort = (query: string) => {
    return Object.values(roleState.entities).sort((a, b) => {
      const rankA = compareStrings(query, a?.label || "") * 50;

      const rankB = compareStrings(query, b?.label || "") * 50;

      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const rolePaginationHandler = useRTKPagination({
    entityState: roleState,
    useLazyPaginateQuery: roleApi.useLazyPaginateQuery,
    options: {
      limit: 600
    }
  });

  const [getRoleBatchApi, getRoleBatchResponse] =
    roleApi.useLazyGetBatchQuery();

  return {
    searchRole,
    searchResponse,
    setSelectedRole,
    roleState,
    rankedSort,
    rolePaginationHandler,
    getRoleBatchApi,
    getRoleBatchResponse
  };
};
