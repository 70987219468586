import { booleanValidation } from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { mofinSpecs } from "../spec-files/mofinFieldSpecs";

export const mortgageLatePaymentsIndicator = buildInput({
  fieldKey:
    mofinSpecs.party?.specsParts?.basePartyFieldSpecs
      .MortgageLatePaymentsIndicator?.fieldKey,
  validation: booleanValidation
});
