import { CreditScore } from "@elphi/types";
import { getFocusedData } from "@elphi/utils";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { RootState, store } from "../redux/store";
import { creditReportSlice } from "../redux/v2/credit-report";
import { creditReportApi } from "../redux/v2/credit-report/creditReport.service";
import { partyApi } from "../redux/v2/party/party.service";
import { getListFromDictionary } from "../utils/batchUtils";
import { compareStrings } from "../utils/searchUtils";

export const useCreditReportHooks = () => {
  const dispatcher = useDispatch();

  const creditReportState = useSelector(
    (state: RootState) => state.creditReport
  );

  const selectedCreditScore = useSelector((state: RootState) =>
    state.creditReport.selectedId
      ? state.creditReport.entities[state.creditReport.selectedId]
      : undefined
  );

  const [searchCreditScore, searchResponse] = creditReportApi.useLazyGetQuery();
  const [updateBatchApi, updateBatchResponse] =
    creditReportApi.useBatchUpdateMutation();

  const [orderCreditScoreApi, orderCreditScoreResponse] =
    creditReportApi.useGetCreditScoreMutation();
  const [orderCreditReportApi, orderCreditReportResponse] =
    creditReportApi.useGetCreditReportMutation();
  const [getPartyCreditScores, getPartyCreditScoresResponse] =
    partyApi.useLazyGetPartyCreditScoresQuery();
  const [deleteCreditScoreApi, deleteCreditScoreResponse] =
    creditReportApi.useDeleteMutation();
  const [getLatestCreditScore] =
    creditReportApi.useLazyGetLatestCreditScoreQuery();
  const [getBatchPartyCreditScores, getBatchPartyCreditScoresResponse] =
    creditReportApi.useLazyGetBatchPartyCreditScoresQuery();

  const setSelectedCreditScore = (id: string) =>
    store.dispatch(creditReportSlice.actions.selectedId({ id }));

  const orderCreditScore = async (r: { body; partyId }) => {
    const { body, partyId } = r;
    return orderCreditScoreApi({ body, partyId }).then(responseHandler);
  };

  const orderCreditReport = async (r: { body }) => {
    const { body } = r;
    return orderCreditReportApi({ body }).then(responseHandler);
  };

  const deleteCreditScore = async (r: string) => {
    const response = await deleteCreditScoreApi(r).then(responseHandler);
    if (response.status === 200) {
      dispatcher(creditReportSlice.actions.remove(r));
    }
    return response;
  };

  const updateBatch = async (r: {
    creditScores: ({ id: string } & Partial<CreditScore>)[];
  }) => {
    const response = await updateBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const updateCreditScoreHandler = async (
    map:
      | {
          [id: string]: {
            id: string;
          } & Partial<CreditScore>;
        }
      | undefined
  ) => {
    if (!map) return;
    const creditScores = getListFromDictionary(map);
    if (!creditScores.length) return;
    return await updateBatch({ creditScores } as {
      creditScores: ({
        id: string;
      } & Partial<CreditScore>)[];
    });
  };

  const rankedSort = (query: string) => {
    return Object.values(creditReportState.entities).sort((a, b) => {
      const creditScoreAExpirationDate =
        getFocusedData(a?.aggregations?.expirationDate) || "";
      const creditScoreARPullType = a?.pullType || "";

      const creditScoreBExpirationDate =
        getFocusedData(b?.aggregations?.expirationDate) || "";
      const creditScoreBPullType = b?.pullType || "";
      const rankA =
        compareStrings(query, creditScoreAExpirationDate) * 25 +
        compareStrings(query, creditScoreARPullType) * 25;

      const rankB =
        compareStrings(query, creditScoreBExpirationDate) * 25 +
        compareStrings(query, creditScoreBPullType) * 25;
      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  return {
    creditReportState,
    orderCreditReport,
    orderCreditReportResponse,
    orderCreditScore,
    orderCreditScoreResponse,
    getPartyCreditScores,
    getPartyCreditScoresResponse,
    deleteCreditScore,
    deleteCreditScoreResponse,
    getLatestCreditScore,
    getBatchPartyCreditScores,
    getBatchPartyCreditScoresResponse,
    setSelectedCreditScore,
    selectedCreditScore,
    updateCreditScoreHandler,
    updateBatchResponse,
    searchCreditScore,
    searchResponse,
    rankedSort
  };
};
