import { Icon } from "@chakra-ui/icons";
export const EditIcon = (
  <Icon viewBox="0 0 24 24" fill="none">
    <path
      d="m21 7-4-4-3 3-6 6v4h4l6-6 3-3zM14 6l4 4"
      stroke="#505872"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4H4v16h16v-6"
      stroke="#505872"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);


export const MailboxIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 18v-6l-2.757-7.351A1 1 0 0 0 17.307 4H6.693a1 1 0 0 0-.936.649L3 12v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2z"
      stroke="#A2C1FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10 15-1.5-3H3v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-5l-2 3h-4z"
      stroke="#A2C1FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FlagIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4.111s3-2.5 8 0 8 0 8 0v12s-3 2.5-8 0-8 0-8 0"
      stroke="#A2C1FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 21.111v-17" />
    <path
      d="M4 21.111v-17"
      stroke="#A2C1FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PipeIcon = (
  <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4 4 5 8v6l6 3v-9l5-8H4z"
      stroke="#A2C1FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);



export const AddDocumentIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11v6M9 14h6M19 21H5V3h9l5 5v13z"
      stroke="#2C5282"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 3v4c0 1 1 2 2 2h4"
      stroke="#2C5282"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddPackageIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 13h4M19 9v11H5V9M19 4H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"
      stroke="#276749"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MessageIconActive = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.5h7M8 13.5h5M15.3 19.1 21 21l-1.9-5.7"
      stroke="#0157FE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3 19.1s-1.215.9-3.8.9a8.5 8.5 0 1 1 8.5-8.5c0 2.5-.9 3.8-.9 3.8"
      stroke="#0157FE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="19" cy="6" r="3.5" fill="#F02B3F" stroke="#fff" />
  </svg>
);

export const MessageIconInactive = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.5h7M8 13.5h5M15.3 19.1 21 21l-1.9-5.7"
      stroke="#505872"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3 19.1s-1.215.9-3.8.9a8.5 8.5 0 1 1 8.5-8.5c0 2.5-.9 3.8-.9 3.8"
      stroke="#505872"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HistoryIconActive = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.5h7M8 13.5h5M15.3 19.1 21 21l-1.9-5.7"
      stroke="#0157FE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3 19.1s-1.215.9-3.8.9a8.5 8.5 0 1 1 8.5-8.5c0 2.5-.9 3.8-.9 3.8"
      stroke="#0157FE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="19" cy="6" r="3.5" fill="#F02B3F" stroke="#fff" />
  </svg>
);

export const HistoryIconInactive = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.5h7M8 13.5h5M15.3 19.1 21 21l-1.9-5.7"
      stroke="#505872"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3 19.1s-1.215.9-3.8.9a8.5 8.5 0 1 1 8.5-8.5c0 2.5-.9 3.8-.9 3.8"
      stroke="#505872"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EyeIcon = (
  <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5C5.636 5 2 12 2 12s3.636 7 10 7 10-7 10-7-3.636-7-10-7z" stroke="#505872" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" stroke="#505872" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Icon>
);

export const SendIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
<path d="M12.3333 1.66675L1 6.33341L5.66667 8.33341L7.66667 13.0001L12.3333 1.66675Z" stroke="rgb(39,103,73)" strokeWidth="2" strokeLinejoin="round"/>
</svg>
)