import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
type ConfigType = string | number | Date | null | undefined;
type FormatObject = {
  locale?: string;
  format?: string;
  utc?: boolean;
};
type OptionType = FormatObject | string | string[];

export type UnitTypeShort = "d" | "D" | "M" | "y" | "h" | "m" | "s" | "ms";

export type UnitTypeLong =
  | "millisecond"
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "month"
  | "year"
  | "date";

export type UnitTypeLongPlural =
  | "milliseconds"
  | "seconds"
  | "minutes"
  | "hours"
  | "days"
  | "months"
  | "years"
  | "dates";

export type UnitType = UnitTypeLong | UnitTypeLongPlural | UnitTypeShort;

export type OpUnitType = UnitType | "week" | "weeks" | "w";

export type ManipulateType = Exclude<OpUnitType, "date" | "dates">;
export type ElphiDate = {
  format(format: string): string;
  isValid(): boolean;
  toDate(): Date;
  get(): Date;
  subtract(value: number, unit?: ManipulateType): Date;
  dayIndex(): number;
  day(value: number): Date;
  dateIndex(): number;
  date(value: number): Date;
  startOf(value: OpUnitType): ElphiDate;
  endOf(value: OpUnitType): ElphiDate;
  yearIndex(): number;
  year(value: number): ElphiDate;
  monthIndex(): number;
  month(value: number): ElphiDate;
  add(value: number, unit?: ManipulateType): ElphiDate;
};

const dateFactory =
  (): ((
    date?: ConfigType,
    option?: {
      format?: OptionType;
      locale?: string;
      strict?: boolean;
    }
  ) => ElphiDate) =>
  (date, option) => {
    const { format, locale, strict } = option || {};
    let instance = date ? dayjs(date, format, locale, strict) : dayjs();

    const methods: ElphiDate = {
      format: (format: string) => instance.format(format),
      isValid: () => instance.isValid(),
      toDate: () => instance.toDate(),
      get: () => instance.toDate(),
      subtract: (value: number, unit?: ManipulateType) =>
        instance.subtract(value, unit).toDate(),
      dayIndex: () => instance.day(),
      day: (value: number) => instance.day(value).toDate(),
      dateIndex: () => instance.date(),
      date: (value: number) => instance.date(value).toDate(),
      startOf: (value: OpUnitType) => {
        instance = instance.startOf(value);
        return methods;
      },
      endOf: (value: OpUnitType) => {
        instance = instance.endOf(value);
        return methods;
      },
      yearIndex: () => instance.year(),
      year: (value: number) => {
        instance = instance.year(value);
        return methods;
      },
      monthIndex: () => instance.month(),
      month: (value: number) => {
        instance = instance.month(value);
        return methods;
      },
      add: (value: number, unit: ManipulateType) => {
        instance = instance.add(value, unit);
        return methods;
      }
    };

    return methods;
  };

export const elphiDate = dateFactory();
