import {
  Box,
  BoxProps,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react";
import { FieldStatus, FieldType } from "@elphi/types";
import lodash from "lodash";
import {
  fieldStatusToColor,
  fieldStatusToLetter,
  nextFieldStatus
} from "../task/field-selection/d2v.utils";
import { OnChangeInput } from "./FormBuilder";

export type AttachedComponentProps<T extends object> = {
  state: T;
  prefix: string[];
  fieldPath: string[];
  isReadOnly?: boolean;
  isDisabled?: boolean;
  onChange?: (v: OnChangeInput) => void;
  containerStyle?: BoxProps;
  status?: FieldStatus;
};
export const AttachedFieldStatusComponet = <T extends object>(
  props: AttachedComponentProps<T>
) => {
  const status =
    props.status ||
    lodash.get(props.state, [
      ...props.prefix,
      "fieldMeta",
      ...props.fieldPath,
      "status"
    ]);
  return (
    <Box pt="8px" pl="4px" pr="4px" {...props.containerStyle}>
      <Button
        isDisabled={props.isDisabled}
        size="xs"
        float={"right"}
        boxShadow={"md"}
        cursor={!!props.onChange ? "pointer" : "default"}
        _hover={{}}
        color={"white"}
        borderRadius={"50%"}
        bgColor={fieldStatusToColor[status || FieldStatus.None]}
        onClick={() => {
          const fieldStatus = nextFieldStatus(status);

          !!props.onChange &&
            props.onChange({
              fieldKey: [
                ...props.prefix,
                "fieldMeta",
                ...props.fieldPath,
                "status"
              ],
              fieldType: FieldType.String,
              value: fieldStatus
            });
        }}
      >
        {fieldStatusToLetter[status || FieldStatus.None]}
      </Button>
    </Box>
  );
};

export const AttachedFieldStatusComponetMenu = <T extends object>(
  props: AttachedComponentProps<T>
) => {
  const status = lodash.get(props.state, [
    ...props.prefix,
    "fieldMeta",
    ...props.fieldPath,
    "status"
  ]);
  const statusChanged = (newStatus: FieldStatus) =>
    !!props.onChange &&
    props.onChange({
      fieldKey: [...props.prefix, "fieldMeta", ...props.fieldPath, "status"],
      fieldType: FieldType.String,
      value: newStatus
    });
  return (
    <Box pt="8px" pl="4px" pr="4px">
      <Menu autoSelect={false}>
        <MenuButton
          float={"right"}
          boxShadow={"md"}
          _hover={{}}
          _focus={{ boxShadow: "outline" }}
          transition="all 0.2s"
          color={"white"}
          borderWidth="1"
          borderRadius="md"
          w="20px"
          h="23px"
          bgColor={fieldStatusToColor[status || FieldStatus.None]}
        >
          <Text fontWeight={"bold"}>
            {fieldStatusToLetter[status || FieldStatus.None]}
          </Text>
        </MenuButton>
        <MenuList w="100px" zIndex={5}>
          {[
            FieldStatus.Approved,
            FieldStatus.AttentionRequired,
            FieldStatus.Denied,
            FieldStatus.None
          ].map((fieldStatus, i) => {
            const menuItemProps = {
              label: fieldStatus,
              bgColor:
                status === fieldStatus ? fieldStatusToColor[fieldStatus] : "",
              isDisabled: props.isDisabled,
              onClick: () => statusChanged(fieldStatus)
            };
            return (
              <MenuItem
                key={i}
                {...menuItemProps}
                _hover={{
                  bgColor: fieldStatusToColor[fieldStatus],
                  opacity: 0.7
                }}
              >
                <Text fontSize="16px" fontWeight={"bold"}>
                  {fieldStatus === FieldStatus.Approved
                    ? "Verified"
                    : fieldStatus}
                </Text>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};
