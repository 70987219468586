import { Navigate } from "react-router";

export type GuardRouteProps = {
  isAllowed: boolean;
  redirectPath: string;
  children: JSX.Element;
};

export const GuardRoute = (props: GuardRouteProps) => {
  if (!props.isAllowed) {
    return <Navigate to={props.redirectPath} replace />;
  }
  return props.children;
};
