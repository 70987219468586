import { Type } from "@sinclair/typebox";
import { OutstandingPremiumStatus } from "../../entities";
import { PropertyInsurancePolicyCoverageType } from "../../entities/insurance-policy/property/propertyInsurancePolicy.enums";
import { EnumField, StringField } from "../utils.typebox";

export const PropertyInsurancePolicyCommonRequest = Type.Object({
  insuranceCarrier: Type.Optional(StringField),
  expirationDate: Type.Optional(StringField),
  nextPaymentDueDate: Type.Optional(StringField),
  policyPremiumAmount: Type.Optional(StringField),
  policyDeductibleAmount: Type.Optional(StringField),
  outstandingPremiumStatus: Type.Optional(EnumField(OutstandingPremiumStatus)),
  outstandingPremiumAmount: Type.Optional(StringField),
  insurancePremiumPaymentAmount: Type.Optional(StringField),
  entityIds: Type.Optional(Type.Array(StringField)),
  policyProvider: Type.Optional(
    Type.Optional(
      Type.Object({
        id: StringField,
        name: StringField
      })
    )
  ),
  coverageTypes: Type.Array(
    Type.Union([EnumField(PropertyInsurancePolicyCoverageType)]),
    { minItems: 1 }
  ),
  entities: Type.Optional(Type.Object({}))
});

export const PropertyInsurancePolicyCreate = Type.Intersect([
  PropertyInsurancePolicyCommonRequest,
  Type.Object({
    policyNumber: StringField
  })
]);

export const PropertyInsurancePolicyUpdate = Type.Object({
  id: StringField
});
