import { Box, Button } from "@chakra-ui/react";

import useWindowDimensions from "../hooks/windowDimensions";

import { useElphiToast } from "./toast/toast.hook";

export const DemoComponent = () => {
  const { width, height } = useWindowDimensions();
  const { successToast } = useElphiToast({
    position: "top-left"
  });
  // const snapshot = useSelector((root: RootState) => root.snapshot)
  return (
    <Box width={width} height={height}>
      <Button
        onClick={() =>
          successToast({
            description: "test",
            title: "test"
          })
        }
      >
        toast
      </Button>
    </Box>
  );
};
