import { TObject, TProperties, Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { IdsModel } from "./shared.typebox";
import { ArrayField, StringField } from "./utils.typebox";

const LosUseStatus = Type.Union([
  Type.Literal("active"),
  Type.Literal("disabled")
]);

export const LOSUserFieldsSchema = Type.Object({
  email: StringField,
  role: StringField,
  roles: ArrayField(
    Type.Object(
      {
        label: StringField,
        value: StringField
      },
      {
        examples: [
          {
            label: "user role label",
            value: "user_role_id"
          }
        ]
      }
    )
  ),
  name: StringField,
  firstName: StringField,
  middleName: StringField,
  lastName: StringField,
  status: LosUseStatus,
  title: Type.Optional(StringField)
});

export const LOSUserUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(LOSUserFieldsSchema)
]);

export const LOSUserCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(LOSUserFieldsSchema)
]);

export const getOperationLOSUserSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? LOSUserCreate : LOSUserUpdate;
};

export const SetUserStatusRequest = Type.Object({
  status: LosUseStatus,
  ids: IdsModel.properties.ids
});

export const UpdateSelfLOSUserStatusRequest = Type.Object({
  status: LosUseStatus
});
