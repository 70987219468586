import { StatementAggregationFields } from "../aggregations";
import { BaseEntity } from "../db/baseEntity.types";
import { BooleanSelectType } from "./fieldFormat.types";

export enum StatementStatusType {
  "Expired" = "Expired",
  "Valid" = "Valid"
}
export type Statement = BaseEntity<{
  aggregations?: StatementAggregationFields;
  assetId: string;
  status: StatementStatusType;
  latestStatement: BooleanSelectType;
  start: string;
  end: string;
  statementBalance: string;
  unverifiedDeposits: string;
  percentConsidered: string;
}>;
