import { useOutletContext } from "react-router";
import PortfolioPageLiveStateContainer from "./PortfolioPageLiveStateContainer";
import PortfolioPageSnapshotContainer from "./PortfolioPageSnapshotContainer";

const PortfolioPageContainer = (props: {
  dealId?: string;
  snapshotId?: string;
}) => {
  const { dealId: dealIdOutlet, snapshotId: snapshotIdOutlet } =
    useOutletContext<{
      dealId?: string;
      snapshotId?: string;
    }>() || {};

  const { dealId = dealIdOutlet, snapshotId = snapshotIdOutlet } = props;

  return snapshotId ? (
    <PortfolioPageSnapshotContainer
      {...props}
      snapshotId={snapshotId!}
      dealId={dealId!}
    />
  ) : (
    <PortfolioPageLiveStateContainer {...props} dealId={dealId!} />
  );
};

export default PortfolioPageContainer;
