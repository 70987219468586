import { Box, Center, Text } from "@chakra-ui/react";
export const SomethingWentWrong = () => {
  return (
    <Center w="100%" h="100%" pt="50px">
      <Box boxShadow={"lg"} h="200px">
        <Text fontSize={"5xl"} p="20px">
          Oh no..
        </Text>
        <Text fontSize={"5xl"} p="20px">
          Seems that something went wrong
        </Text>
        <Text fontSize={"5xl"} p="20px">
          please refresh your page and try again
        </Text>
      </Box>
    </Center>
  );
};
