import { Statement } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import lodash from "lodash";
import { assetApi } from "../asset/asset.service";
import sliceBuilder from "../builders/slice.builder";
import { statementEntityAdapter as entityAdapter } from "./statement.adapter";
import { statementApi } from "./statement.service";

export type StatementSliceState = EntityState<Statement> & {
  selectedId?: EntityId;
};

export const statementSlice = createSlice({
  name: "statement",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as StatementSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(statementApi)(builder);
    builder.addMatcher(
      assetApi.endpoints.getLatestStatements.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.map((s) => s.id)
        );
        const entityStatements = {};
        payload.forEach(
          (statement) => (entityStatements[statement.id] = statement)
        );
        state.entities = { ...state.entities, ...entityStatements };
      }
    );
    builder.addMatcher(
      assetApi.endpoints.getAssetStatements.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.statements.map((s) => s.id)
        );
        const entityStatements = {};
        payload.statements.forEach(
          (statement) => (entityStatements[statement.id] = statement)
        );
        state.entities = { ...state.entities, ...entityStatements };
      }
    );
    builder.addMatcher(
      assetApi.endpoints.addStatementToAsset.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, [payload.statement.id]);
        state.entities = {
          ...state.entities,
          [payload.statement.id]: payload.statement
        };
      }
    );
    builder.addMatcher(
      statementApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
  }
});
