import { PartyAggregationFields } from "../aggregations/party.aggregation.types";
import { BaseEntity } from "../db/baseEntity.types";
import { BooleanSelectType } from "./fieldFormat.types";
import { BaseAddress, StateCode, StateName } from "./location.types";

export enum PartyType {
  Individual = "Individual",
  Entity = "Entity"
}
export enum TaxpayerIdentifierType {
  SSN = "SSN",
  EIN = "EIN",
  TIN = "TIN"
}

export enum PartyMilestoneType {
  PreSubmissionBorrower = "Pre-Submission Borrower",
  NewBorrower = "New Borrower",
  InitialBorrowerReview = "Initial Borrower Review",
  AdditionalInfoNeeded = "Initial Borrower Review - Additional Info Needed",
  Suspended = "Suspended",
  BorrowerApprovedPendingRecert = "Borrower Approved - Pending Recert",
  BorrowerApproved = "Borrower Approved",
  BorrowerDenied = "Borrower Denied",
  Expired = "Expired",
  Resubmitted = "Resubmitted",
  Prohibited = "Prohibited"
}
export enum PartyOperationsDepartmentType {
  EastCoastOperations = "East Coast Operations",
  WestCoastOperations = "West Coast Operations",
  StrategicAccounts = "Strategic Accounts",
  LenderFinanceOperations = "Lender Finance Operations"
}

/** @deprecated
 * Use the customer-specific 'Party' type instead.
 */
export type BaseParty<
  TType extends PartyType,
  TTaxIdentifier extends TaxpayerIdentifierType
> = BaseEntity<{
  aggregations: PartyAggregationFields;
  PartyType: TType;
  GroupId: string;
  TaxpayerIdentifierType: TTaxIdentifier;
  TaxpayerIdentifierValue: string;
  LiquidAssetTotalAmount: string;
  OperationsDepartment: PartyOperationsDepartmentType;
  BackgroundReportDate: string;
  Address: BaseAddress;
  CRMId: string;
  ContactPointTelephoneValue: string;
  ContactPointEmailValue: string;
}>;

export enum EntityType {
  GeneralPartnership = "General Partnership",
  LimitedLiabilityCompany = "Limited Liability Company",
  LimitedLiabilityPartnership = "Limited Liability Partnership",
  LimitedPartnership = "Limited Partnership",
  LimitedLiabilityLimitedPartnership = "Limited Liability Limited Partnership",
  CCorp = "C-Corp",
  SCorp = "S-Corp",
  LandTrust = "Land Trust",
  Corporation = "Corporation"
}
export enum RealEstateOperationType {
  FixAndFlip = "FixNFlip",
  NewConstruction = "New Construction",
  Rentals = "Rentals",
  Commercial = "Commercial",
  Multifamily = "Multifamily",
  MixedUse = "Mixed Use"
}

/** @deprecated
 * Use the customer-specific 'Party' type instead.
 */
export type EntityParty = BaseParty<
  PartyType.Entity,
  | TaxpayerIdentifierType.EIN
  | TaxpayerIdentifierType.SSN
  | TaxpayerIdentifierType.TIN
> & {
  EntityType: EntityType;
  FullName: string;
  BusinessIncorporationStateName: StateName;
  BusinessIncorporationStateCode: StateCode;
  BusinessRegistrationStates: StateCode[];
  FixAndFlipBridgePlusTier: FixNFlipNBridgePlusTierType;
  NewConstructionEligibilityIndicator: string;
  RemainingLineOfCreditAmount: string;
  Notes: string;
  EvidenceOfGoodStandingExpirationDate: string;
  RealEstateOperationType: RealEstateOperationType[];
  TotalFixAndFlipNewConstructionSoldPastThreeYearsCount: string;
  PartyMilestone: PartyMilestoneType;
  TotalOutstandingLongTermLoanCount: string;
  TotalOutstandingLongTermPrincipalAmount: string;
  RepresentativeCreditScore: string;
  OperationsDepartment: PartyOperationsDepartmentType;
  OverridePartyValidationSettingsIndicator: BooleanSelectType;
};

export enum ContactPointRoleType {
  Main = "Main",
  Fax = "Fax",
  Home = "Home",
  Work = "Work"
}
export type PersonalInformation = {
  FirstName: string;
  MiddleName: string;
  LastName: string;
  BirthDate: string;
  GovernmentIssuedIDExpirationDate: string;
  CitizenshipCountry: string;
};
export type ContactPointInformation = {
  ContactPointRoleType: ContactPointRoleType;
  ContactPointTelephoneValue: string;
  ContactPointEmailValue: string;
};

export enum CreditReportPullType {
  Hard = "Hard",
  Soft = "Soft"
}

export enum LOCStatusType {
  None = "None",
  InProcessSubReview = "In Process - Sub Review",
  InProcessAppUnderwriting = "In Process - App UW",
  InProcessBorrowerUnderwriting = "In Process - Borrower UW",
  Suspended = "Suspended",
  SubmissionRejected = "Submission Rejected",
  Denied = "Denied",
  ConditionalApproval = "Conditional Approval",
  Approval = "Approval",
  SubmittedForLargeLineApproval = "Submitted for Large Line Approval",
  Expired = "Expired",
  New = "New",
  SubmittedForExceptionApproval = "Submitted for Exception Approval",
  Resubmitted = "Resubmitted",
  Prohibited = "Prohibited"
}
export enum FixNFlipNBridgePlusTierType {
  N1 = "1",
  N2 = "2",
  N3 = "3",
  N4 = "4",
  N5 = "5"
}

export type BaseIndividualParty<T extends TaxpayerIdentifierType> = BaseParty<
  PartyType.Individual,
  T
> & {
  FirstName: string;
  MiddleName: string;
  LastName: string;
  BirthDate: string;
  GovernmentIssuedIDExpirationDate: string;
  CitizenshipCountry: string;
  ContactPointRoleType: ContactPointRoleType;
  USCitizenshipIndicator: string;

  //Line of Credit stuff
  CreditReportNotes: string;
  FixNFlipNBridgePlusTier: FixNFlipNBridgePlusTierType;
  NewConstructionEligibilityIndicator: string;
  LOCStatusType: LOCStatusType;
  TotalApprovedLOC: string;
  UnpaidPrincipalBalance: string;
  LoanAmountInProcess: string;
  RemainingLOCAvailable: string;
  CreditReportDate: string;
  CreditReportPullType: CreditReportPullType;

  //Short Term Experience stuff
  CompletedExits: string;
  NumberOfTransactions: string;
  TransactionsCumulativeSalePrice: string;
  TransactionsAverageProjectDuration: string;
  TransactionsAverageSalePrice: string;
  NumberOfFlips: string;
  FlipsCumulativeSalePrice: string;
  FlipsAverageProjectDuration: string;
  FlipsAverageSalePrice: string;
  NumberOfNewBuilds: string;
  NewBuildsCumulativeSalePrice: string;
  NewBuildsAverageProjectDuration: string;
  NewBuildsAverageSalePrice: string;
  ExperienceNotes: string;
  PaymentTokenId: string;
  EstimatedCreditScore: string;
  ConvictionIndicator: string;
  OutstandingLawsuitsIndicator: string;
  OutstandingJudgmentsIndicator: string;
  BankruptcyIndicator: string;
  ForeclosureIndicator: string;
  MortgageDelinquentIndicator: string;
  StatedCashOnHandAmount: string;
};

export type IndividualSSNParty =
  BaseIndividualParty<TaxpayerIdentifierType.SSN>;
export type IndividualTINParty =
  BaseIndividualParty<TaxpayerIdentifierType.TIN>;

/** @deprecated
 * Use the customer-specific 'Individual Party' type instead.
 */
export type IndividualParty =
  | IndividualSSNParty
  | (IndividualTINParty & {
      OverridePartyValidationSettingsIndicator: BooleanSelectType;
    });
export type Party = IndividualParty | EntityParty;
