import { Integrations } from "@elphi/types/entities/integrations/integrations.types";
import { injectSpecToBuilder } from "../../utils/formBuilder.utils";
import { DealIntegrationsSections } from "../types/dealIntegrations.types";
import { PropertyIntegrationsSections } from "../types/propertyIntegrations.types";
import { floodSectionBuilders } from "./flood/flood.sections";
import { loanpassSectionBuilders } from "./pricing/loanpass.sections";
import { siteXSectionBuilders } from "./property-report/siteX.sections";
import { rentRangeSectionBuilders } from "./rental-data/rentRange.section";
import { wireInsuranceSectionBuilders } from "./wire-insurance/fundingShield.section";

export const getPropertyIntegrationsSections = <
  T extends { aggregations: Integrations<object> } | Integrations<object>
>(
  spec: T
) => {
  const serviceLinkFloodSections = injectSpecToBuilder<
    PropertyIntegrationsSections["FloodCertification"]["ServiceLink"]
  >({
    builders: floodSectionBuilders,
    spec
  });

  const siteXPropertyReportSections = injectSpecToBuilder<
    PropertyIntegrationsSections["PropertyReport"]["SiteX"]
  >({
    builders: siteXSectionBuilders,
    spec
  });

  const rentRangeRentalDataSections = injectSpecToBuilder<
    PropertyIntegrationsSections["RentalData"]["RentRange"]
  >({
    builders: rentRangeSectionBuilders,
    spec
  });

  return {
    serviceLinkFloodSections,
    siteXPropertyReportSections,
    rentRangeRentalDataSections
  };
};

export const getDealIntegrationsSections = <
  T extends { aggregations: Integrations<object> } | Integrations<object>
>(
  spec: T
) => {
  const fundingShieldWireInsuranceCertificateSections =
    injectSpecToBuilder<
      DealIntegrationsSections["WireInsuranceCertificate"]["FundingShield"]
    >({
      builders: wireInsuranceSectionBuilders,
      spec
    }) || {};

  const pricingLoanpassSections =
    injectSpecToBuilder<DealIntegrationsSections["Pricing"]["Loanpass"]>({
      builders: loanpassSectionBuilders,
      spec
    }) || {};

  return {
    fundingShieldWireInsuranceCertificateSections,
    pricingLoanpassSections
  };
};
