import { useSelector } from "react-redux";
import { RootState, store } from "../redux/store";
import { taskRuleApi } from "../redux/v2/task-rule/taskRule.service";
import { taskRuleSlice } from "../redux/v2/task-rule/taskRule.slice";
import { compareStrings } from "../utils/searchUtils";

export const useTaskRuleHooks = () => {
  const setSelectedTaskRule = (id: string) =>
    store.dispatch(taskRuleSlice.actions.selectedId({ id }));

  const selectedTaskRule = useSelector(
    (state: RootState) =>
      state.taskRule.selectedId &&
      state.taskRule.entities[state.taskRule.selectedId]
  );
  const taskRuleState = useSelector((state: RootState) => state.taskRule);
  const [searchTaskRuleApi, searchResponse] = taskRuleApi.useLazySearchQuery();

  const rankedSort = (query: string) => {
    return Object.values(taskRuleState.entities).sort((a, b) => {
      const rankA = compareStrings(query, a?.ruleName || "") * 50;

      const rankB = compareStrings(query, b?.ruleName || "") * 50;

      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };
  return {
    taskRuleState,
    searchTaskRuleApi,
    searchResponse,
    rankedSort,
    selectedTaskRule,
    setSelectedTaskRule
  };
};
