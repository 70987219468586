import { createIcon } from "@chakra-ui/react";

const customSettingsIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 28 28",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.8333 3.5H15.1667C15.811 3.5 16.3333 4.02234 16.3333 4.66667V5.33025C16.3333 5.82935 16.6683 6.26296 17.1292 6.45433C17.5904 6.64578 18.1273 6.57281 18.4803 6.21977L18.9497 5.75039C19.4053 5.29478 20.144 5.29478 20.5996 5.75039L22.2495 7.40031C22.7052 7.85592 22.7051 8.59461 22.2495 9.05023L21.7803 9.51953C21.4271 9.8726 21.3542 10.4095 21.5456 10.8707C21.737 11.3317 22.1706 11.6667 22.6698 11.6667H23.3333C23.9777 11.6667 24.5 12.189 24.5 12.8333V15.1667C24.5 15.811 23.9777 16.3333 23.3333 16.3333H22.6697C22.1706 16.3333 21.7371 16.6683 21.5456 17.1292C21.3542 17.5904 21.4271 18.1273 21.7803 18.4803L22.2495 18.9497C22.7052 19.4053 22.7052 20.144 22.2495 20.5996L20.5996 22.2495C20.144 22.7052 19.4053 22.7052 18.9497 22.2495L18.4803 21.7803C18.1273 21.4271 17.5904 21.3542 17.1292 21.5456C16.6683 21.7371 16.3333 22.1706 16.3333 22.6697V23.3333C16.3333 23.9777 15.811 24.5 15.1667 24.5H12.8333C12.189 24.5 11.6667 23.9777 11.6667 23.3333V22.6698C11.6667 22.1706 11.3317 21.737 10.8707 21.5456C10.4095 21.3542 9.8726 21.4271 9.51953 21.7803L9.05021 22.2495C8.59459 22.7052 7.8559 22.7052 7.40029 22.2495L5.75037 20.5996C5.29477 20.144 5.29477 19.4053 5.75037 18.9497L6.21977 18.4803C6.57281 18.1273 6.64578 17.5904 6.45433 17.1292C6.26296 16.6683 5.82935 16.3333 5.33025 16.3333H4.66667C4.02234 16.3333 3.5 15.811 3.5 15.1667V12.8333C3.5 12.189 4.02234 11.6667 4.66667 11.6667H5.33023C5.82934 11.6667 6.26296 11.3317 6.45434 10.8707C6.6458 10.4096 6.57284 9.87267 6.21977 9.5196L5.75039 9.05023C5.29478 8.59461 5.29478 7.85592 5.75039 7.40032L7.40031 5.75039C7.85592 5.29479 8.59461 5.29479 9.05023 5.75039L9.5196 6.21977C9.87266 6.57284 10.4096 6.6458 10.8707 6.45434C11.3317 6.26296 11.6667 5.82934 11.6667 5.33022V4.66667C11.6667 4.02234 12.189 3.5 12.8333 3.5Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
        />
        <path
          d="M16.3333 14C16.3333 15.2887 15.2887 16.3334 14 16.3334C12.7113 16.3334 11.6667 15.2887 11.6667 14C11.6667 12.7113 12.7113 11.6667 14 11.6667C15.2887 11.6667 16.3333 12.7113 16.3333 14Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const SettingsIcon = customSettingsIcon();
