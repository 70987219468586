import { Badge, Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import {
  AuditEvent,
  AuditEventFieldType,
  AuditEventOperation,
  FieldStatus
} from "@elphi/types";
import { TooltipHtmlBox } from "../../../../common-components/html-box/HtmlBox";
import { emojiDataMap } from "../../../../common-components/reaction-picker/emojiDataSet";
import { EMPTY, NONE, NOT_AVAILABLE } from "../../../../constants/common";
import { RightDirectionArrow } from "../../../icons";
import {
  fieldStatusToColor,
  fieldStatusToLetter
} from "../../../task/field-selection/d2v.utils";
import {
  ELPHI_TRX_STATUS_COLOR,
  ELPHI_TRX_STATUS_COLOR_TEXT
} from "../../../task/integrations/integration.type";
import { focusBgColorMap, focusColorMap } from "./eventRow.utils";

type FieldValueProps = {
  value: string | undefined;
};

type FieldValueComponentMap = {
  [key in AuditEventFieldType]: React.ComponentType<FieldValueProps>;
};

const FieldValue = (props: FieldValueProps) => {
  const value = props.value || NONE;
  return (
    <Tooltip label={value}>
      <Text whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
        {value}
      </Text>
    </Tooltip>
  );
};

const FieldStatusValue = (props: FieldValueProps) => {
  return (
    <Text
      size="xs"
      boxShadow={"md"}
      color={"white"}
      borderRadius={"50%"}
      w="23px"
      h="23px"
      fontSize={"xs"}
      fontWeight={600}
      p={0.5}
      textAlign={"center"}
      bgColor={fieldStatusToColor[props.value || FieldStatus.None]}
    >
      {fieldStatusToLetter[props.value || FieldStatus.None]}
    </Text>
  );
};

const FocusValue = (props: FieldValueProps) => {
  const bgColor = focusBgColorMap[props.value || EMPTY];
  const color = focusColorMap[props.value || EMPTY];
  const displayValue = props.value === "thirdParty" ? "3rd party" : props.value;

  return displayValue ? (
    <Text
      boxShadow="md"
      borderRadius="2px"
      textAlign="center"
      fontSize="xs"
      fontWeight={600}
      p="2px"
      minWidth="70px"
      bgColor={bgColor}
      color={color}
    >
      {displayValue}
    </Text>
  ) : (
    <Text p="2px">{NONE}</Text>
  );
};

const IntegrationStatusValue = (props: FieldValueProps) => {
  const { value } = props;
  const bgColor =
    value && ELPHI_TRX_STATUS_COLOR[value]
      ? ELPHI_TRX_STATUS_COLOR[value]
      : ELPHI_TRX_STATUS_COLOR.default;

  const borderColor =
    value && ELPHI_TRX_STATUS_COLOR[value]
      ? ELPHI_TRX_STATUS_COLOR[value]
      : ELPHI_TRX_STATUS_COLOR.default;

  const color =
    value && ELPHI_TRX_STATUS_COLOR_TEXT[value]
      ? ELPHI_TRX_STATUS_COLOR_TEXT[value]
      : ELPHI_TRX_STATUS_COLOR_TEXT.default;

  return (
    <Badge
      fontSize={12}
      bgColor={bgColor}
      borderColor={borderColor}
      color={color}
    >
      {value}
    </Badge>
  );
};

const fieldValueComponentMap: FieldValueComponentMap = {
  [AuditEventFieldType.Focus]: FocusValue,
  [AuditEventFieldType.FieldStatus]: FieldStatusValue,
  [AuditEventFieldType.IntegrationStatus]: IntegrationStatusValue,
  [AuditEventFieldType.Value]: FieldValue,
  [AuditEventFieldType.DeletedFile]: FieldValue,
  [AuditEventFieldType.UploadedFile]: FieldValue,
  [AuditEventFieldType.Message]: FieldValue,
  [AuditEventFieldType.Reaction]: FieldValue
};

export const EntityValue = (props: { event: AuditEvent }) => {
  const { operation, entityId, fieldType, beforeValue, afterValue } =
    props.event;

  if (
    operation === AuditEventOperation.Created ||
    operation === AuditEventOperation.Deleted
  ) {
    return <Text>{`id: ${entityId}`}</Text>;
  }

  if (operation === AuditEventOperation.Updated && fieldType) {
    const ValueComponent = fieldValueComponentMap[fieldType];
    if (ValueComponent) {
      return (
        <Flex width={"100%"}>
          <Box maxWidth={"50%"}>
            <ValueComponent value={beforeValue} />
          </Box>
          <RightDirectionArrow mx={2} w={6} h={6} />
          <Box maxWidth={"50%"}>
            <ValueComponent value={afterValue} />
          </Box>
        </Flex>
      );
    }
    return <FieldValue value={afterValue} />;
  }
  return <>{NOT_AVAILABLE}</>;
};

export const DiscussionValue = (props: { event: AuditEvent }) => {
  const { event } = props;
  const { operation, fieldType, beforeValue, afterValue } = event;
  if (fieldType === AuditEventFieldType.Message) {
    if (
      operation === AuditEventOperation.Created ||
      operation === AuditEventOperation.Deleted
    ) {
      return (
        <TooltipHtmlBox
          htmlAsString={afterValue}
          boxProps={{
            maxHeight: "24px",
            overflow: "hidden"
          }}
        />
      );
    }
    return (
      <Flex width={"100%"}>
        <Flex maxWidth={"50%"}>
          <TooltipHtmlBox
            htmlAsString={beforeValue}
            boxProps={{
              maxHeight: "24px",
              overflow: "hidden"
            }}
          />
        </Flex>
        <Flex>
          <RightDirectionArrow mx={2} w={6} h={6} />
        </Flex>
        <Flex>
          <TooltipHtmlBox
            htmlAsString={afterValue}
            boxProps={{
              maxHeight: "24px",
              overflow: "hidden"
            }}
          />
        </Flex>
      </Flex>
    );
  }
  if (fieldType === AuditEventFieldType.Reaction) {
    const message = beforeValue;
    const emoji = afterValue ? emojiDataMap.get(afterValue) : undefined;
    const reaction =
      operation === AuditEventOperation.Deleted
        ? `Removed ${emoji?.native}`
        : `Reacted with ${emoji?.native}`;
    return (
      <Flex width={"100%"}>
        <Flex maxWidth={"70%"}>
          <TooltipHtmlBox
            htmlAsString={message}
            boxProps={{
              maxHeight: "24px",
              overflow: "hidden"
            }}
          />
        </Flex>
        <Flex>
          <RightDirectionArrow mx={2} w={6} h={6} />
        </Flex>
        <Flex>
          <Box>{reaction}</Box>
        </Flex>
      </Flex>
    );
  }
};
