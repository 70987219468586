import { FirebaseFilter } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { FilterCombinationState } from "../redux/v2/types/stateWithFilter.types";

export const useFilter = <
  T extends object,
  U extends {
    filters: {
      current: Partial<{ [fieldPath in DotNestedKeys<T>]: string[] }>;
    };
  }
>(
  state: U
) => {
  const dispatch = useDispatch();

  const buildFireStoreFilters = (r?: {
    opStr?: Extract<FirebaseFilter["opStr"], "in" | "not-in">;
  }): FirebaseFilter[] => {
    return Object.keys(state.filters.current)
      .filter((fieldPath) => state.filters.current[fieldPath].length)
      .map((fieldPath) => ({
        fieldPath,
        opStr: r?.opStr || "in",
        value: state.filters.current[fieldPath]
      }));
  };

  const setCombination = (
    filters: typeof state.filters.current,
    action: ActionCreatorWithPayload<{
      combination: string;
      filters: typeof state.filters;
    }>
  ) => {
    const combination =
      Object.entries(filters)
        .reduce((acc: string[], [_, value]: [string, string[]]) => {
          if (value.length) {
            acc.push(...value);
          }
          return acc;
        }, [] as string[])
        ?.sort()
        ?.join("_") || "none";
    dispatch(action({ combination, filters: { current: filters } }));
  };

  const setFilterCombinationDetails = (
    r: Partial<FilterCombinationState[string]>,
    action: ActionCreatorWithPayload<typeof r>
  ) => {
    dispatch(action(r));
  };

  return {
    buildFireStoreFilters,
    setCombination,
    setFilterCombinationDetails
  };
};
