import { NotificationAuditEvent } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";
import { notificationUserEntityAdapter } from "./notificationUser.adapter";

export type NotificationUserPaginateResponse = {
  nextCursor?: string;
  hasMore: boolean;
  page: NotificationAuditEvent[];
};

export type NotificationUserPaginateRequest = Partial<{
  cursor: string;
  limit: string;
  dealIds: string[];
  entityType: string;
  fieldPaths: string[];
  auditTypes: string[];
  userIds: string[];
  unread: string;
}>;

export type NotificationToggleStatus = Pick<
  NotificationAuditEvent,
  "id" | "status"
>;
export const baseNotificationUserApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "notificationUserApi",
  tagTypes: ["NotificationUser", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "notification-user"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: notificationUserEntityAdapter
  })
});

export const notificationUserApi = baseNotificationUserApi.injectEndpoints({
  endpoints: (builder) => ({
    paginateV2: builder.query<
      NotificationUserPaginateResponse,
      NotificationUserPaginateRequest
    >({
      query: (r) => {
        return {
          url: `/paginate`,
          method: "POST",
          body: r
        };
      }
    }),
    toggleNotify: builder.query<{ notify: boolean }, void>({
      query: () => {
        return {
          url: `/toggle-notify`,
          method: "POST"
        };
      }
    }),
    toggleStatus: builder.query<
      NotificationToggleStatus,
      NotificationToggleStatus
    >({
      query: (r) => {
        return {
          url: `/toggle-status`,
          method: "POST",
          body: r
        };
      }
    }),
    markAllAsRead: builder.query<{}, {}>({
      query: () => {
        return {
          url: `/mark-all-as-read`,
          method: "POST"
        };
      }
    })
  })
});
