import { RoleGroup } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { EMPTY } from "../../../constants/common";
import sliceBuilder from "../builders/slice.builder";
import { FiltersState } from "../types/stateWithFilter.types";
import {
  initCombination,
  setCombinationInfo,
  setFilterAndCombination
} from "../utils/stateFilter.utils";
import { roleGroupAdapter } from "./roleGroup.adapter";
import { roleGroupApi } from "./roleGroup.service";

export type RoleGroupSliceState = EntityState<RoleGroup> & {
  selectedId?: EntityId;
  filters: FiltersState<RoleGroup, "name" | "status">;
};

const initialState: RoleGroupSliceState = {
  ids: [],
  entities: {},
  selectedId: undefined,
  filters: {
    current: {
      name: [],
      status: []
    },
    combinations: {
      [EMPTY]: initCombination()
    },
    currentCombination: EMPTY
  }
};

export const roleGroupSlice = createSlice({
  name: "roleGroup",
  initialState: roleGroupAdapter.getInitialState(initialState),
  reducers: {
    update: roleGroupAdapter.updateOne,
    remove: roleGroupAdapter.removeOne,
    add: roleGroupAdapter.addOne,
    upsert: roleGroupAdapter.upsertOne,
    upsertMany: roleGroupAdapter.upsertMany,
    removeMany: roleGroupAdapter.removeMany,
    updateMany: roleGroupAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    setFilterAndCombination: (
      state,
      action: PayloadAction<{
        combination: string;
        filters: RoleGroupSliceState["filters"];
      }>
    ) => setFilterAndCombination(state, action),

    setFilterCombinationDetails: (
      state,
      action: PayloadAction<
        RoleGroupSliceState["filters"]["combinations"][string]
      >
    ) => setCombinationInfo(state, action)
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(roleGroupApi)(builder);
    builder.addMatcher(
      roleGroupApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      roleGroupApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page, "id")
        };
      }
    );
    builder.addMatcher(
      roleGroupApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
  }
});
