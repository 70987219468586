import { Property } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import lodash from "lodash";
import { NONE } from "../../../constants/common";
import sliceBuilder from "../builders/slice.builder";
import { dealApi } from "../deal/deal.service";
import { documentPackageOrderApi } from "../document-package-order";
import { serviceLinkFloodApi } from "../integration/service-link/service-link-flood.service";
import { uniqCursorId } from "../utils/filter-table.utils";
import { propertyEntityAdapter as entityAdapter } from "./property.adapter";
import { propertyApi } from "./property.service";

export type PropertySliceState = EntityState<Property> & {
  selectedId?: EntityId;
  propertyTable?: {
    cursor: {
      currentId: string;
      all?: string;
      hasMore: {
        [uniqId: string]: boolean;
      };
      userFilter: {
        [userId: string]: string;
      };
      propertyFilter: {
        [propertyId: string]: string;
      };
      propertyUserFilter: {
        [propertyUserId: string]: string;
      };
    };
  };
};
export enum PropertyTableCursorType {
  ALL,
  USER_FILTER,
  PROPERTY_FILTER,
  PROPERTY_USER_FILTER
}
export const propertySlice = createSlice({
  name: "property",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined,
    propertyTable: {
      cursor: {
        all: undefined,
        currentId: NONE,
        hasMore: {},
        propertyFilter: {},
        propertyUserFilter: {},
        userFilter: {}
      }
    }
  }) as PropertySliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    setPropertyTableCurrentCursorIdHasMore: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (state.propertyTable)
        state.propertyTable.cursor.hasMore[
          state.propertyTable.cursor.currentId
        ] = action.payload;
    },
    setPropertyTableCurrentCursorId: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      if (state.propertyTable)
        state.propertyTable.cursor.currentId = action.payload.id;
    },
    setPropertyTableCursor: (
      state,
      action: PayloadAction<{
        cursorType: PropertyTableCursorType;
        cursor: string;
        propertyId?: string;
        userId?: string;
      }>
    ) => {
      const { propertyId, userId } = action.payload;
      const filtersUniqCursorId = uniqCursorId({
        id: propertyId,
        userId
      });
      if (!state.propertyTable) {
        state.propertyTable = {
          cursor: {
            currentId: filtersUniqCursorId,
            hasMore: {},
            all: undefined,
            userFilter: {},
            propertyFilter: {},
            propertyUserFilter: {}
          }
        };
      }
      if (action.payload.cursorType === PropertyTableCursorType.ALL) {
        state.propertyTable.cursor.all = action.payload.cursor;
      } else if (
        action.payload.cursorType === PropertyTableCursorType.PROPERTY_FILTER
      ) {
        if (action.payload.propertyId) {
          state.propertyTable.cursor.propertyFilter[action.payload.propertyId] =
            action.payload.cursor;
        }
      } else if (
        action.payload.cursorType === PropertyTableCursorType.USER_FILTER
      ) {
        if (action.payload.userId) {
          state.propertyTable.cursor.userFilter[action.payload.userId] =
            action.payload.cursor;
        }
      } else if (
        action.payload.cursorType ===
        PropertyTableCursorType.PROPERTY_USER_FILTER
      ) {
        if (action.payload.userId && action.payload.propertyId) {
          state.propertyTable.cursor.propertyUserFilter[
            `${action.payload.propertyId}_${action.payload.userId}`
          ] = action.payload.cursor;
        }
      }
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(propertyApi)(builder);
    builder.addMatcher(
      dealApi.endpoints.dealProperties.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.properties.ids);
        state.entities = { ...state.entities, ...payload.properties.entities };
      }
    );
    builder.addMatcher(
      propertyApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      propertyApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.page.property.map((p) => p.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.page.property, "id")
        };
      }
    );
    builder.addMatcher(
      propertyApi.endpoints.getPropertyBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.properties.ids);
        state.entities = { ...state.entities, ...payload.properties.entities };
      }
    );
    builder.addMatcher(
      documentPackageOrderApi.endpoints.getEntitiesData.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.properties.ids);
        state.entities = { ...state.entities, ...payload.properties.entities };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.page.properties.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.page.properties, "id")
        };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.getAdditionalDataForDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.properties.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.properties, "id")
        };
      }
    );
    builder.addMatcher(
      serviceLinkFloodApi.endpoints.createServiceLink.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.id);
        state.entities = { ...state.entities, payload };
      }
    );
    builder.addMatcher(
      serviceLinkFloodApi.endpoints.quickCheckServiceLink.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.id);
        state.entities = { ...state.entities, payload };
      }
    );
    builder.addMatcher(
      serviceLinkFloodApi.endpoints.changeServiceLink.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.id);
        state.entities = { ...state.entities, payload };
      }
    );
  }
});
