import { createIcon } from "@chakra-ui/react";

const customIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill={fill} />
        <path
          d="M23.5 21.0002V16.0002L21.2027 9.87423C21.0808 9.54897 20.7698 9.3335 20.4225 9.3335H11.5775C11.2301 9.3335 10.9192 9.54897 10.7972 9.87423L8.5 16.0002V21.0002C8.5 21.9207 9.24619 22.6668 10.1667 22.6668H21.8333C22.7538 22.6668 23.5 21.9207 23.5 21.0002Z"
          stroke="#7B341E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3333 18.5L13.0833 16H8.5V20.6667C8.5 21.7712 9.39543 22.6667 10.5 22.6667H21.5C22.6046 22.6667 23.5 21.7712 23.5 20.6667V16H19.3333L17.6667 18.5H14.3333Z"
          stroke="#7B341E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const InboxOrange100Icon = customIcon("#FEEBCB");
