import { Building, BuildingType, FieldType } from "@elphi/types";
import { createOptionsFromMap } from "../../../../utils/formUtils";
import { EntityFormFieldSpecs } from "../../fields.types";

export const propertyBuildingsFieldSpecs: EntityFormFieldSpecs<Building> = {
  buildingMailingAddress: {
    fieldKey: ["buildingMailingAddress"],
    fieldType: FieldType.String,
    label: "Building Mailing Address"
  },
  buildingType: {
    fieldKey: ["buildingType"],
    fieldType: FieldType.SingleSelect,
    label: "Building Type",
    options: createOptionsFromMap(BuildingType)
  }
};
