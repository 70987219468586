import { TaskTemplate } from "@elphi/types";
import lodash from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { DATA_RANK } from "../components/task/task-template/v2/taskTemplate.util";
import { RootState, store } from "../redux/store";
import { fundingShieldApi } from "../redux/v2/integration/funding-shield/funding-shield.service";
import { taskTemplateApi } from "../redux/v2/task-template/taskTemplate.service";
import { taskTemplateSlice } from "../redux/v2/task-template/taskTemplate.slice";
import { getRankedData } from "../utils/ranked.utils";

export const useTaskTemplateHooks = () => {
  const [updateBatchApi, updateBatchResponse] =
    taskTemplateApi.useBatchUpdateMutation();
  const [deleteTemplateApi, deleteTemplateResponse] =
    taskTemplateApi.useDeleteMutation();
  const [createFundingShieldApi, createFundingShieldApiResponse] =
    fundingShieldApi.useCreateOrderCertificateMutation();
  const [retryFundingShieldApi, retryFundingShieldApiResponse] =
    fundingShieldApi.useRetryFailedOrderCertificateMutation();

  const deleteTemplate = async (id: string) => {
    return await deleteTemplateApi(id).then(responseHandler);
  };
  const updateBatch = async (r: {
    tasks: ({ id: string } & Partial<TaskTemplate>)[];
  }) => {
    return await updateBatchApi(r).then(responseHandler);
  };
  const setSelectedTaskTemplate = (id: string) =>
    store.dispatch(taskTemplateSlice.actions.selectedId({ id }));

  const selectedTaskTemplate = useSelector(
    (state: RootState) =>
      state.taskTemplate.selectedId &&
      state.taskTemplate.entities[state.taskTemplate.selectedId]
  );
  const taskTemplateState = useSelector(
    (state: RootState) => state.taskTemplate
  );
  const [searchTaskTemplateApi, searchResponse] =
    taskTemplateApi.useLazySearchQuery();
  const [taskTemplateGetBatchApi, taskTemplateGetBatchApiResponse] =
    taskTemplateApi.useLazyGetBatchQuery();

  const taskTemplateSearchState = useMemo(() => {
    return {
      ...taskTemplateState,
      entities: lodash.pickBy(taskTemplateState.entities, (_, key) =>
        taskTemplateState.ids.includes(key)
      )
    };
  }, [taskTemplateState.entities]);

  const rankedSort = (query: string) => {
    return Object.values(taskTemplateState.entities).sort((a, b) => {
      const rankA = getRankedData(query, a, DATA_RANK);

      const rankB = getRankedData(query, b, DATA_RANK);

      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };
  return {
    rankedSort,
    taskTemplateSearchState,
    deleteTemplateResponse,
    deleteTemplate,
    taskTemplateState,
    searchTaskTemplateApi,
    searchResponse,
    selectedTaskTemplate,
    setSelectedTaskTemplate,
    updateBatch,
    taskTemplateGetBatchApi,
    taskTemplateGetBatchApiResponse,
    updateBatchResponse,
    createFundingShieldApi,
    createFundingShieldApiResponse,
    retryFundingShieldApi,
    retryFundingShieldApiResponse
  };
};
