import { Static, Type } from "@sinclair/typebox";
import { StringField } from "../../typebox/utils.typebox";
import { createEntitySchema } from "../../typebox/utils/entity.utils";

export const GroupFields = Type.Object({
  name: StringField,
  description: StringField
});

export const {
  Update: GroupUpdate,
  Create: GroupCreate,
  Schema: GroupSchema,
  getOperationSchema: getOperationGroupSchema
} = createEntitySchema(GroupFields);

export type Group = Static<typeof GroupSchema>;
