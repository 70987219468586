import { Role } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { union } from "lodash";
import { fromFirebaseIdToLabel, toFirebaseId } from "../../../utils/common";
import { removeNulls } from "../../../utils/filter.utils";
import sliceBuilder from "../builders/slice.builder";
import { losUserApi } from "../los-user";
import { taskTemplateApi } from "../task-template";
import { roleEntityAdapter as entityAdapter } from "./adapter";
import { roleApi } from "./service";

export type RoleSliceState = EntityState<Role> & {
  selectedId?: EntityId;
};
export const roleSlice = createSlice({
  name: "role",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as RoleSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(roleApi)(builder);
    builder.addMatcher(
      taskTemplateApi.endpoints.paginate.matchFulfilled,
      (state, { payload }) => {
        const roleIds = payload.ids
          .map((ttid) => {
            const rolesFromTemplate = [
              payload.entities[ttid]?.template.checklistOf,
              payload.entities[ttid]?.template.assignableBy,
              payload.entities[ttid]?.template.editableBy
            ]
              .filter(removeNulls)
              .flat();
            return rolesFromTemplate.map(toFirebaseId);
          })
          .flat()
          .filter(removeNulls);

        const roleEntities = roleIds.reduce((p, v) => {
          p[v] = {
            id: v,
            label: fromFirebaseIdToLabel(v)
          };
          return p;
        }, {});
        state.ids = union(state.ids, roleIds);
        state.entities = { ...roleEntities, ...state.entities };
      }
    );
    builder.addMatcher(
      roleApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      losUserApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        const roles = payload.ids
          .map((id) => {
            return payload.entities[id]?.roles?.map((role) => {
              return {
                id: role.value,
                label: fromFirebaseIdToLabel(role.label)
              };
            });
          })
          .flat()
          .filter((v) => !!v);
        state.ids = union(
          state.ids,
          roles.map((r) => r!.id)
        );
        state.entities = {
          ...state.entities,
          ...roles.reduce((p, v) => {
            return (p[v!.id] = v);
          }, {})
        };
      }
    );
    builder.addMatcher(
      losUserApi.endpoints.get.matchFulfilled,
      (state, { payload }) => {
        const roles = payload?.roles
          ?.map((role) => {
            return {
              id: role.value,
              label: fromFirebaseIdToLabel(role.label)
            };
          })
          .filter((v) => !!v);
        state.ids = union(
          state.ids,
          roles.map((r) => r!.id)
        );
        state.entities = {
          ...state.entities,
          ...roles.reduce((p, v) => {
            p[v!.id] = v;
            return p;
          }, {})
        };
      }
    );
    builder.addMatcher(
      roleApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
  }
});
