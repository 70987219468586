import {
  BranchServiceProviderFields,
  CompanyServiceProviderFields,
  RepresentativeServiceProviderFields,
  RolodexConfigurationFieldType,
  RolodexServiceProvider,
  ServiceProviderEntityType
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { v4 as uuid } from "uuid";

const companyNamePath: DotNestedKeys<CompanyServiceProviderFields> = "name";
const branchNamePath: DotNestedKeys<BranchServiceProviderFields> = "name";
const repFirstNamePath: DotNestedKeys<RepresentativeServiceProviderFields> =
  "firstName";
const repLastNamePath: DotNestedKeys<RepresentativeServiceProviderFields> =
  "lastName";
const statusPath: DotNestedKeys<RolodexServiceProvider> = "status";

export const initialStateFields = {
  [RolodexConfigurationFieldType.CompanyFields]: {
    [uuid()]: {
      fieldPath: companyNamePath,
      isRequired: true
    },
    [uuid()]: {
      fieldPath: statusPath,
      isRequired: true
    }
  },
  [RolodexConfigurationFieldType.BranchFields]: {
    [uuid()]: {
      fieldPath: branchNamePath,
      isRequired: true
    },
    [uuid()]: {
      fieldPath: statusPath,
      isRequired: true
    }
  },
  [RolodexConfigurationFieldType.RepresentativeFields]: {
    [uuid()]: {
      fieldPath: repFirstNamePath,
      isRequired: true
    },
    [uuid()]: {
      fieldPath: repLastNamePath,
      isRequired: true
    },
    [uuid()]: {
      fieldPath: statusPath,
      isRequired: true
    }
  }
} as const;

export const presetFieldsMap = {
  [RolodexConfigurationFieldType.CompanyFields]: {
    [companyNamePath]: {},
    [statusPath]: {}
  },
  [RolodexConfigurationFieldType.BranchFields]: {
    [branchNamePath]: {},
    [statusPath]: {}
  },
  [RolodexConfigurationFieldType.RepresentativeFields]: {
    [repFirstNamePath]: {},
    [repLastNamePath]: {},
    [statusPath]: {}
  }
} as const;

export const rolodexFieldTypeOptionMap: {
  [r in RolodexConfigurationFieldType]: string;
} = {
  [RolodexConfigurationFieldType.CompanyFields]: "Company Fields",
  [RolodexConfigurationFieldType.BranchFields]: "Branch Fields",
  [RolodexConfigurationFieldType.RepresentativeFields]: "Representative Fields"
} as const;

export const serviceProviderEntityTypeOptionMap: {
  [s in ServiceProviderEntityType]: string;
} = {
  [ServiceProviderEntityType.Deal]: "Deal",
  [ServiceProviderEntityType.Party]: "Party",
  [ServiceProviderEntityType.Property]: "Property",
  [ServiceProviderEntityType.InsurancePolicy]: "Insurance Policy"
} as const;
