import { Deal, Task } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import {
  lenderIdentifier,
  requestedLoanAmount,
  titleCompanyAddressLineText,
  titleCompanyAddressUnitIdentifier,
  titleCompanyCityName,
  titleCompanyClosingAgentContactPointEmail,
  titleCompanyClosingAgentFirstName,
  titleCompanyClosingAgentLastName,
  titleCompanyName,
  titleCompanyPostalCode,
  titleCompanyStateCode,
  wireABARoutingNumber,
  wireAccountName,
  wireAccountNumber
} from "../../../base/inputs/deal.inputs";
import { buildSection } from "../../../utils/formBuilder.utils";
import {
  transactionId,
  transactionStatus,
  wireInsuranceCertificateFundingShieldRushOrderIndicator,
  wireInsuranceCertificateFundingShieldUserEmail,
  wireInsuranceCertificateFundingShieldUserFirstName,
  wireInsuranceCertificateFundingShieldUserLastName
} from "../../inputs/wire-insurance/fundingShield.inputs";
import { WireInsuranceFiles } from "./wireInsuranceFiles";

const wireInsuranceOrderSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    selectedTask: Task;
    state: Partial<Deal>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      id: "integrationWireInsuranceCertOrder",
      state: r.state,
      extraBody: (
        <WireInsuranceFiles
          fieldSpecs={fieldSpecs}
          selectedTask={r.selectedTask}
          onChange={r.onChange}
          state={r.state}
        />
      ),
      onChange: r.onChange,
      header: "Integration Wire Insurance Cert: Order",
      inputs: [
        {
          ...wireABARoutingNumber,
          isRequired: true,
          validation: (v) => !!wireABARoutingNumber.validation?.(v, true)
        },
        {
          ...wireAccountNumber,
          isRequired: true,
          validation: (v) => !!wireAccountNumber.validation?.(v, true)
        },
        {
          ...wireAccountName,
          isRequired: true,
          validation: (v) => !!wireAccountName.validation?.(v, true)
        },
        {
          ...titleCompanyName,
          isRequired: true,
          validation: (v) => !!titleCompanyName.validation?.(v, true)
        },
        {
          ...titleCompanyAddressLineText,
          isRequired: true,
          validation: (v) => !!titleCompanyAddressLineText.validation?.(v, true)
        },
        titleCompanyAddressUnitIdentifier,
        {
          ...titleCompanyCityName,
          isRequired: true,
          validation: (v) => !!titleCompanyCityName.validation?.(v, true)
        },
        {
          ...titleCompanyStateCode,
          isRequired: true,
          validation: (v) => !!titleCompanyStateCode.validation?.(v, true)
        },
        {
          ...titleCompanyPostalCode,
          isRequired: true,
          validation: (v) => !!titleCompanyPostalCode.validation?.(v, true)
        },
        {
          ...titleCompanyClosingAgentFirstName,
          isRequired: true,
          validation: (v) =>
            !!titleCompanyClosingAgentFirstName.validation?.(v, true)
        },
        {
          ...titleCompanyClosingAgentLastName,
          isRequired: true,
          validation: (v) =>
            !!titleCompanyClosingAgentLastName.validation?.(v, true)
        },
        {
          ...titleCompanyClosingAgentContactPointEmail,
          isRequired: true,
          validation: (v) =>
            !!titleCompanyClosingAgentContactPointEmail.validation?.(v, true)
        },
        {
          ...lenderIdentifier,
          isRequired: true,
          validation: (v) => !!lenderIdentifier.validation?.(v, true)
        },
        {
          ...wireInsuranceCertificateFundingShieldUserFirstName,
          isRequired: true,
          validation: (v) =>
            !!wireInsuranceCertificateFundingShieldUserFirstName.validation?.(
              v,
              true
            )
        },
        {
          ...wireInsuranceCertificateFundingShieldUserLastName,
          isRequired: true,
          validation: (v) =>
            !!wireInsuranceCertificateFundingShieldUserLastName.validation?.(
              v,
              true
            )
        },
        {
          ...wireInsuranceCertificateFundingShieldUserEmail,
          isRequired: true,
          validation: (v) =>
            !!wireInsuranceCertificateFundingShieldUserEmail.validation?.(
              v,
              true
            )
        },
        {
          ...wireInsuranceCertificateFundingShieldRushOrderIndicator,
          isRequired: true,
          validation: (v) =>
            !!wireInsuranceCertificateFundingShieldRushOrderIndicator.validation?.(
              v,
              true
            )
        },
        {
          ...requestedLoanAmount,
          isRequired: true,
          validation: (v) => !!requestedLoanAmount.validation?.(v, true)
        }
      ],
      fieldSpecs
    });

const wireInsuranceVerifySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Deal>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Integration Wire Insurance Cert: Verify",
      inputs: [transactionId, transactionStatus],
      fieldSpecs
    });

const wireInsuranceOrderStatusSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Deal>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Integration Wire Insurance Cert: Order Status",
      inputs: [transactionStatus],
      fieldSpecs
    });

export const wireInsuranceSectionBuilders = {
  wireInsuranceOrderSection,
  wireInsuranceVerifySection,
  wireInsuranceOrderStatusSection
};
