import { SystemSetup } from "../../../common";
import { PartyRelationType } from "../../../entities";
import {
  elphiEntityToEntityRelationSchema,
  elphiEntityToIndividualRelationSchema
} from "../../base/partyRelation.typebox";
import {
  limaEntityToEntityRelationSchema,
  limaEntityToIndividualRelationSchema
} from "../../lima/partyRelation.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type PartyRelationEnvToSchemaMap = {
  [PartyRelationType.EntityToEntity]: EnvToSchemasMap;
  [PartyRelationType.EntityToIndividual]: EnvToSchemasMap;
};

export const partyRelationSchemas: PartyRelationEnvToSchemaMap = {
  [PartyRelationType.EntityToEntity]: {
    [SystemSetup.Elphi]: elphiEntityToEntityRelationSchema,
    [SystemSetup.Lima]: limaEntityToEntityRelationSchema,
    [SystemSetup.Mofin]: elphiEntityToEntityRelationSchema,
    [SystemSetup.LedgerTC]: elphiEntityToEntityRelationSchema
  },
  [PartyRelationType.EntityToIndividual]: {
    [SystemSetup.Elphi]: elphiEntityToIndividualRelationSchema,
    [SystemSetup.Lima]: limaEntityToIndividualRelationSchema,
    [SystemSetup.Mofin]: elphiEntityToIndividualRelationSchema,
    [SystemSetup.LedgerTC]: elphiEntityToIndividualRelationSchema
  }
};
