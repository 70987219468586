export enum AuthErrorType {
  InvalidCredentials = 1,
  EmailAlreadyInUse,
  InvalidUserToken,
  UserTokenExpired,
  UserDisabled,
  TooManyRequests,
  WeakPassword,
  NewPasswordNotConfirmed,
  RepeatPassword,
  InternalServerError,
  MustUseOkta
}

export const authErrorMessages: { [key in AuthErrorType]: string } = {
  [AuthErrorType.InvalidCredentials]: "Email or password was incorrect",
  [AuthErrorType.EmailAlreadyInUse]: "Email already in use for another account",
  [AuthErrorType.InvalidUserToken]: "User token invalid. Please try again.",
  [AuthErrorType.UserTokenExpired]: "User token expired",
  [AuthErrorType.UserDisabled]: "User account disabled",
  [AuthErrorType.TooManyRequests]:
    "Too many unsuccessful sign in attempts. Please try again later.",
  [AuthErrorType.WeakPassword]: "The password must be at least 6 characters",
  [AuthErrorType.NewPasswordNotConfirmed]:
    "New password not confirmed correctly",
  [AuthErrorType.RepeatPassword]: "New password repeats old password",
  [AuthErrorType.InternalServerError]:
    "Internal Server Error. Please try again later.",
  [AuthErrorType.MustUseOkta]: "Please sign in using Okta to continue"
};

export const firebaseAuthErrorMap: { [key: string]: AuthErrorType } = {
  "auth/user-not-found": AuthErrorType.InvalidCredentials,
  "auth/wrong-password": AuthErrorType.InvalidCredentials,
  "auth/invalid-email": AuthErrorType.InvalidCredentials,
  "auth/email-already-in-use": AuthErrorType.EmailAlreadyInUse,
  "auth/invalid-user-token": AuthErrorType.InvalidUserToken,
  "auth/user-token-expired": AuthErrorType.UserTokenExpired,
  "auth/user-disabled": AuthErrorType.UserDisabled,
  "auth/too-many-requests": AuthErrorType.TooManyRequests,
  "auth/weak-password": AuthErrorType.WeakPassword,
  "auth/new-password-not-confirmed": AuthErrorType.NewPasswordNotConfirmed,
  "auth/repeat-password": AuthErrorType.RepeatPassword,
  "auth/must-use-okta": AuthErrorType.MustUseOkta
};
