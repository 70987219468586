import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from "@chakra-ui/input";
import { Flex, Spacer } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useState } from "react";
import { AppConfig } from "../../../config/appConfig";
import { EMPTY } from "../../../constants/common";
import { CreateInsurancePolicyButton } from "../CreateInsurancePolicyButton";
import { useInsurancePolicyContainerHooks } from "./InsurancePolicyContainer.hooks";

export const InsurancePolicyContainerHeader = (props: {
  dealId: string;
  onPolicyCreated: (isCreatedProcess: boolean) => void;
}) => {
  const { currentFilter, setCurrentFilter } =
    useInsurancePolicyContainerHooks();

  const [search, setSearch] = useState<string>(EMPTY);
  const searchDebounce = debounce((query: string) => {
    setCurrentFilter({
      ...currentFilter,
      freeText: query
    });
  }, AppConfig.search.debounceRate);

  return (
    <>
      <Flex>
        <InputGroup maxWidth={400}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            variant="outline"
            placeholder={"Search"}
            fontSize={12}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              searchDebounce(e.target.value);
            }}
          />
          <InputRightElement hidden={!search}>
            <CloseIcon
              w={4}
              h={4}
              cursor={"pointer"}
              color="gray.500"
              onClick={() => {
                setSearch(EMPTY);
                setCurrentFilter({
                  ...currentFilter,
                  freeText: EMPTY
                });
              }}
            />
          </InputRightElement>
        </InputGroup>
        <Spacer />
        <CreateInsurancePolicyButton
          dealId={props.dealId}
          onPolicyCreated={props.onPolicyCreated}
          buttonWrapper={{ pr: "10px" }}
        />
      </Flex>
    </>
  );
};
