export enum Strength {
  Weak,
  Medium,
  Strong
}

export interface PolicyRule {
  name: string;
  checker: (password: string) => boolean;
  issueMessage: string;
}
