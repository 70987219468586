import { thirdPartyFieldTemplate } from "../../../aggregations";
import { DeepPartial } from "../../../utils";
import { BaseSchemaDeal } from "../../base";

export const getBaseSchemaDealTemplate = (): DeepPartial<BaseSchemaDeal> => {
  return {
    aggregations: {
      LoanProgramType: thirdPartyFieldTemplate({ defaultFocus: "override" }),
      EstimatedClosingDate: thirdPartyFieldTemplate({
        defaultFocus: "override"
      })
    }
  };
};
