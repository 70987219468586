import { BaseEntity, DealProperty } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { DEFAULT_RELEATION_ID_DELIMITER } from "./constants/relation";

const getIdsFromRelationId = <T extends BaseEntity<object>>(r: {
  principal: DotNestedKeys<T>;
  foreign: DotNestedKeys<T>;
  relationId: string;
  delimiter: string;
}) => {
  const splittedRelationId = r.relationId.split(r.delimiter);
  return {
    [`${r.principal}`]: splittedRelationId[0],
    [`${r.foreign}`]: splittedRelationId[1]
  };
};

export const getDealPropertyIdsFromRelation = (r: { relationId: string }) => {
  const ids = getIdsFromRelationId<DealProperty>({
    principal: "dealId",
    foreign: "propertyId",
    delimiter: DEFAULT_RELEATION_ID_DELIMITER,
    relationId: r.relationId
  });
  return ids;
};
