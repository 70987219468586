import { Box, Text } from "@chakra-ui/react";
import {
  FieldType,
  LabelValue,
  RolodexServiceProvider,
  ServiceProviderType
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { uniq } from "lodash";
import { useCallback, useMemo } from "react";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { useRolodexBranchRepRelation } from "../../../../hooks/rolodexBranchRepRelation.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import { getRankedData } from "../../../../utils/ranked.utils";
import { SearchComponentProps } from "../../../SearchComponent";
import StyledInputBuilder from "../../../form-builder/InputBuilder";
import { SearchHandler } from "../../../search/SearchHandler";
import {
  ServiceProviderShortTypeMap,
  ServiceProviderTypeMap,
  buildOption
} from "../utils/serviceProvider.utils";

type ServiceProviderSearchProps = {
  filter?: (provider?: RolodexServiceProvider) => boolean;
};
export const ServiceProviderSearch = (props: ServiceProviderSearchProps) => {
  const {
    selectedServiceProvider,
    setSelectedServiceProvider,
    serviceProviderState,
    searchApi,
    searchResponse,
    rankedSort,
    dataRank
  } = useServiceProviderHooks();

  const customFilter: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    const serviceProvider = serviceProviderState?.entities?.[option.data.value];
    const shouldShow = props.filter ? props.filter(serviceProvider) : true;
    if (!searchText && shouldShow) {
      return true;
    }

    if (!serviceProvider?.id) {
      return false;
    }

    return (
      (getRankedData(searchText, serviceProvider, dataRank) > 0.5 ||
        searchText === serviceProvider.id.toLowerCase()) &&
      shouldShow
    );
  };

  const filterOption = useCallback(customFilter, [
    serviceProviderState?.entities
  ]);

  const onSearchSelect = (id: string) => {
    setSelectedServiceProvider(id);
  };

  const buildSearchOption = (p: RolodexServiceProvider): LabelValue => {
    if (!p) {
      return {
        label: NOT_AVAILABLE,
        value: EMPTY
      };
    }
    const option = buildOption(p);
    if (
      p.type === ServiceProviderType.Branch ||
      p.type === ServiceProviderType.Representative
    ) {
      const company = serviceProviderState.entities[p.companyId];
      if (company) {
        const companyLabel = buildOption(company)?.label || NOT_AVAILABLE;
        return {
          ...option,
          label: `${ServiceProviderShortTypeMap[p.type]} | ${
            option.label
          } | ${companyLabel}`
        };
      }
    }
    return {
      ...option,
      label: `${ServiceProviderShortTypeMap[p.type]} | ${option.label}`
    };
  };

  return (
    <SearchHandler
      currentValue={
        selectedServiceProvider ? selectedServiceProvider.id : EMPTY
      }
      onSelect={onSearchSelect}
      fieldType={FieldType.SingleSelect}
      version={"v2"}
      label={"Search service provider"}
      labelPosition={"placeHolder"}
      searchApi={searchApi}
      searchResponse={searchResponse}
      state={serviceProviderState}
      buildOption={buildSearchOption}
      rankedSort={rankedSort}
      filterOption={filterOption}
    />
  );
};

export const ServiceProviderSelect = (props: {
  currentValue: string;
  onSelect: (v: string) => void;
  dealId: string;
}) => {
  const { branchRepState } = useRolodexBranchRepRelation();
  const { serviceProviderState } = useServiceProviderHooks();

  const options = useMemo(() => {
    return uniq(
      Object.values(branchRepState?.entities)
        .filter((cs) => cs?.dealIds?.includes(props?.dealId || EMPTY))
        .map((cs) => [cs?.companyId, cs?.branchId, cs?.representativeId])
        .flat()
        .filter(removeEmpty)
    )
      .map((id): LabelValue | undefined => {
        const provider = serviceProviderState.entities?.[id];
        if (!provider) {
          return undefined;
        }
        const option = buildOption(provider);
        return {
          ...option,
          label: `${ServiceProviderTypeMap[provider.type]}: ${option.label}`
        };
      })
      .filter(removeEmpty);
  }, [props.dealId, branchRepState.entities, serviceProviderState.entities]);

  return (
    <Box p="10px">
      <Text pl="3px" fontWeight={"bold"}>
        Select service provider
      </Text>
      <StyledInputBuilder
        label={"Select service provider"}
        fieldType={FieldType.SingleSelect}
        currentValue={props.currentValue}
        onChange={(e) => {
          props.onSelect(e.target.value);
        }}
        options={options}
      />
    </Box>
  );
};
