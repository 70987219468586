import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure
} from "@chakra-ui/react";
import { DiscussionEntityType, StatusCode } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { xor } from "lodash";
import { useEffect, useMemo } from "react";
import { useDiscussionEntityHooks } from "../../hooks/discussionEntity.hooks";
import {
  useDiscussionEntityWatchersSubscriber,
  useShouldNotifyDiscussionSubscriber
} from "../../hooks/discussionEntitySubscriber.hooks";
import { useLOSUserHooks } from "../../hooks/losuser.hooks";
import { DiscussionIcon, DiscussionRedIcon } from "../icons/DiscussionIcon";
import { DiscussionContent } from "./DiscussionContent";
import { WatchersMenuList } from "./WatchersMenuList";

export const DiscussionBox = (props: {
  entityId: string;
  entityType: DiscussionEntityType;
  title: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleToggleNotify, toggleNotifyResponse } =
    useDiscussionEntityHooks();
  const { notify, setNotify } = useShouldNotifyDiscussionSubscriber({
    entityId: props.entityId
  });

  const handleOnClose = () => {
    onClose();
    handleNotify();
  };

  const handleNotify = () => {
    if (notify && !toggleNotifyResponse.isLoading) {
      setNotify(false);
      handleToggleNotify({ entityId: props.entityId }).then((r) => {
        if (r.status === StatusCode.BadRequest) {
          setNotify(notify);
        }
      });
    }
  };

  return (
    <Box>
      <IconButton
        p={0}
        m={0}
        w={0}
        h={0}
        minWidth={"inherit"}
        verticalAlign={"inherit"}
        aria-label={"discussion-button"}
        variant={"unstyled"}
        onClick={onOpen}
        icon={
          isOpen ? (
            <DiscussionIcon w={7} h={7} />
          ) : notify ? (
            <DiscussionRedIcon w={7} h={7} />
          ) : (
            <DiscussionIcon w={7} h={7} />
          )
        }
      />
      <DiscussionModal isOpen={isOpen} onClose={handleOnClose} {...props} />
    </Box>
  );
};

const DiscussionModal = (props: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  entityId: string;
  entityType: DiscussionEntityType;
}) => {
  const { isOpen, onClose, title, entityId, entityType } = props;
  const { losUserState, getPaginateUsers } = useLOSUserHooks();
  const { handleSaveWatchers, saveWatchersResponse, discussionEntityState } =
    useDiscussionEntityHooks();

  useDiscussionEntityWatchersSubscriber({ entityId, isWatching: isOpen });

  useEffect(() => {
    if (isOpen) {
      getPaginateUsers({ limit: 600, options: {} }, true);
    }
  }, [isOpen]);

  const userWatchers = useMemo(() => {
    return Object.values(losUserState.entities)
      .map((user) => {
        if (!user) return;
        return {
          id: user.id,
          name: user.name,
          email: user.email
        };
      })
      .filter(removeEmpty);
  }, [losUserState.entities]);

  const handleOnCloseWatchers = (watcherUIDs: string[]) => {
    const shouldSave =
      xor(
        watcherUIDs,
        discussionEntityState?.discussions?.[entityId]?.watcherUIDs
      ).length > 0;

    if (shouldSave) {
      handleSaveWatchers({
        entityId,
        entityType,
        watcherUIDs
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth={"1000px"}>
        <ModalHeader>
          <Flex alignItems={"center"}>
            <Box>Discussion - {title}</Box>
            <Spacer />
            <WatchersMenuList
              currentWatcherUIDs={
                discussionEntityState?.discussions?.[entityId]?.watcherUIDs ||
                []
              }
              userWatcherList={userWatchers}
              onClose={handleOnCloseWatchers}
              isLoading={saveWatchersResponse.isLoading}
            />
            <ModalCloseButton position={"unset"} ml={1} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <DiscussionContent {...props} isWatching={isOpen} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
