import { TObject, TProperties, Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { StringField } from "./utils.typebox";

export const PartyUserFieldsSchema = Type.Object({
  partyId: StringField,
  userId: StringField
});

export const PartyUserUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(PartyUserFieldsSchema)
]);

export const PartyUserCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(PartyUserFieldsSchema)
]);

export const getOperationPartyUserSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? PartyUserCreate : PartyUserUpdate;
};
