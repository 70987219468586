import { TSchema, Type } from "@sinclair/typebox";
import { PartyRelationType } from "../../entities";
import {
  limaEntityToEntityRelationRoleType,
  limaEntityToIndividualRelationRoleType
} from "../../entities/lima";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "../service.typebox";
import {
  ArrayField,
  EnumField,
  PercentField,
  StringField
} from "../utils.typebox";

export const BasePartyRelationFieldsSchema = <TType extends TSchema>(
  type: TType
) =>
  Type.Object({
    type: type,
    parentId: StringField,
    childId: StringField,
    ownershipPercentage: PercentField
  });

export const BasePartyRelationSchema = <TType extends PartyRelationType>(
  type: TType
) =>
  Type.Intersect([
    BaseEntitySchema,
    BasePartyRelationFieldsSchema(Type.Literal(type))
  ]);

export const limaEntityToEntityRelationSchema = Type.Intersect([
  BasePartyRelationSchema(PartyRelationType.EntityToEntity),
  Type.Object({
    relationRoleType: ArrayField(EnumField(limaEntityToEntityRelationRoleType))
  })
]);

export const limaEntityToIndividualRelationSchema = Type.Intersect([
  BasePartyRelationSchema(PartyRelationType.EntityToIndividual),
  Type.Object({
    relationRoleType: ArrayField(
      EnumField(limaEntityToIndividualRelationRoleType)
    )
  })
]);

export const PartyRelationSchema = Type.Union([
  limaEntityToEntityRelationSchema,
  limaEntityToIndividualRelationSchema
]);

export const EntityToEntityPartyRelationUpdate = OmitUpdateSchema(
  limaEntityToEntityRelationSchema
);

export const EntityToEntityRelationCreate = OmitCreateSchema(
  limaEntityToEntityRelationSchema
);

export const EntityToIndividualPartyRelationUpdate = OmitUpdateSchema(
  limaEntityToIndividualRelationSchema
);

export const PartyRelationUpdate = Type.Union([
  EntityToEntityPartyRelationUpdate,
  EntityToIndividualPartyRelationUpdate
]);
