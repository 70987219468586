import { Button, Flex } from "@chakra-ui/react";

type OperationButtonsProps = {
  onClick: () => void;
  text: string;
};
const OperationButton = (props: OperationButtonsProps) => {
  return (
    <Button size="xs" onClick={props.onClick}>
      {props.text}
    </Button>
  );
};

type ClearSelectAllButtonsProps = {
  onClearAll: () => void;
  onSelectAll: () => void;
};

export const ClearSelectAllButtons = (props: ClearSelectAllButtonsProps) => {
  return (
    <Flex gap={"8px"}>
      <OperationButton text="Clear All" onClick={props.onClearAll} />
      <OperationButton text="Select All" onClick={props.onSelectAll} />
    </Flex>
  );
};
