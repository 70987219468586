import {
  AddIcon,
  DeleteIcon,
  DownloadIcon,
  RepeatClockIcon,
  RepeatIcon
} from "@chakra-ui/icons";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { EyeIcon } from "../../assets/icons";

export const BaseButtonIconDelete = (props: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => (
  <IconButton
    color="red.400"
    size={"md"}
    bgColor="white"
    aria-label="add-node-button"
    onClick={props.onClick}
    isLoading={props.isLoading}
    isDisabled={props.isDisabled}
    icon={<DeleteIcon />}
  />
);
export const BaseButtonIconAdd = (props: {
  onClick: () => void;
  isDisabled: boolean;
}) => (
  <IconButton
    color="green.400"
    size={"md"}
    bgColor="white"
    aria-label="add-node-button"
    onClick={props.onClick}
    isDisabled={props.isDisabled}
    icon={<AddIcon />}
  />
);
export const ButtonIconDelete = (props?: {
  onClick?: () => void;
  isDisabled?: boolean;
}) => (
  <IconButton
    color="red.400"
    size={"md"}
    bgColor="white"
    aria-label="add-node-button"
    icon={<DeleteIcon />}
    {...props}
  />
);

export const ButtonIconRepeatClock = (props?: {
  onClick?: () => void;
  isDisabled?: boolean;
}) => (
  <IconButton
    color="blue.400"
    size={"md"}
    bgColor="white"
    aria-label="history-button"
    icon={<RepeatClockIcon />}
    {...props}
  />
);

export const ButtonIconAdd = (props: {
  handleOpen: () => void;
  isDisabled?: boolean;
}) => (
  <IconButton
    color="green.400"
    size={"md"}
    bgColor="white"
    aria-label="add-node-button"
    onClick={props.handleOpen}
    isDisabled={props.isDisabled}
    icon={<AddIcon />}
  />
);

export const ButtonIconDownload = (props: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  tooltipLabel?: string;
}) => {
  const iconButton = (
    <IconButton
      color="blue.400"
      size={"md"}
      bgColor="white"
      aria-label="download-button"
      onClick={props.onClick}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      icon={<DownloadIcon />}
    />
  );
  return !!props?.tooltipLabel ? (
    <Tooltip label={props.tooltipLabel}>{iconButton}</Tooltip>
  ) : (
    iconButton
  );
};

export const ButtonIconRetry = (props: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  tooltipLabel?: string;
}) => {
  const iconButton = (
    <IconButton
      color="purple.400"
      size={"md"}
      bgColor="white"
      aria-label="retry-button"
      onClick={props.onClick}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      icon={<RepeatIcon />}
    />
  );
  return !!props?.tooltipLabel ? (
    <Tooltip label={props.tooltipLabel}>{iconButton}</Tooltip>
  ) : (
    iconButton
  );
};

export const ButtonIconViewSummary = (props: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  tooltipLabel?: string;
}) => {
  const iconButton = (
    <IconButton
      size={"md"}
      bgColor="white"
      aria-label="summary-button"
      onClick={props.onClick}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      icon={EyeIcon}
    />
  );
  return !!props?.tooltipLabel ? (
    <Tooltip label={props.tooltipLabel}>{iconButton}</Tooltip>
  ) : (
    iconButton
  );
};
