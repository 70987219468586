import { Box, Flex } from "@chakra-ui/react";
import { useTableManagerHook } from "../../hooks/tableManager.hooks";
import { OrderableTableProps } from "../table/table.types";
import { ElphiTableCell } from "./elphiList.types";

const CELL_DEFAULT_WIDTH = "200px";
export const ElphiListRow = ({
  tableName,
  cells,
  isOrderable
}: {
  cells: ElphiTableCell[];
} & OrderableTableProps) => {
  const { getSortCallback } = useTableManagerHook();
  const sortCallback = getSortCallback({ isOrderable });
  const sortedCells = sortCallback({ tableName, cells: cells });
  return (
    <Flex w="100%" alignItems={"center"}>
      {sortedCells.map(({ maxWidth, minWidth, data }, i) => {
        return (
          <Box
            maxW={maxWidth || CELL_DEFAULT_WIDTH}
            minW={minWidth || CELL_DEFAULT_WIDTH}
            key={i}
          >
            {data}
          </Box>
        );
      })}
    </Flex>
  );
};
