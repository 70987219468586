import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Property } from "@elphi/types";
import { useRef } from "react";
import { responseHandler } from "../../apis/rtk/response.handler";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import useDealHooks from "../../hooks/deal.hooks";
import { usePropertyHooks } from "../../hooks/property.hooks";
import { dealPropertyRelationApi } from "../../redux/v2/deal-property-relation/dealPropertyRelation.service";
import { useElphiToast } from "../toast/toast.hook";
import { propertyLabel } from "./utils/printUtils";

export const DeletePropertyModal = (props: {
  isDisabled?: boolean;
  buttonFn?: (props?: {
    onClick?: () => void;
    isDisabled?: boolean;
  }) => JSX.Element;
  injectedProperty: Property;
  paginateNext?: () => void;
}) => {
  const { buttonFn, injectedProperty, paginateNext } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const propsButton = buttonFn && buttonFn({ onClick: onOpen });
  const { deleteProperty, deletePropertyApiResponse } = usePropertyHooks();
  const { successToast, errorToast } = useElphiToast();

  const deletePropertyHandler = (id: string) => {
    deleteProperty(id).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Property deleted successfully",
          description: `property: ${r.data?.id}`
        });
        paginateNext?.();
        onClose();
      }
      if (r.status === 400) {
        errorToast({
          title: "failed to delete property",
          description: r.data.description
        });
      }
    });
  };

  const finalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box tabIndex={-1} ref={finalRef}>
      {propsButton ? (
        propsButton
      ) : (
        <Button
          {...elphiTheme.components.light.button.secondary}
          size="xs"
          onClick={onOpen}
          isDisabled={props.isDisabled}
        >
          Delete Property
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Delete Property</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Text pb="10px" fontWeight={"bold"}>
              {injectedProperty && propertyLabel(injectedProperty)}
            </Text>
            <Text pb="10px" fontWeight={"semibold"}>
              Are you sure you want to delete this property?
            </Text>
            <Text pb="10px" fontWeight={"bold"} color="red">
              This operation is irreversible
            </Text>
            <Box pt="10px">
              <Button
                {...elphiTheme.components.light.button.secondary}
                isDisabled={!injectedProperty?.id}
                onClick={() =>
                  injectedProperty && deletePropertyHandler(injectedProperty.id)
                }
                isLoading={deletePropertyApiResponse.isLoading}
              >
                Delete
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export const DeleteDealPropertyRelationModal = (props: {
  buttonFn?: (handleOpen: () => void) => JSX.Element;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const propsButton = props.buttonFn && props.buttonFn(onOpen);
  const { selectedProperty, setSelectedProperty } = usePropertyHooks();
  const { selectedDeal } = useDealHooks();
  const { successToast, errorToast } = useElphiToast();
  const [deleteDealPropertyRelationApi, deleteDealPropertyRelationApiResponse] =
    dealPropertyRelationApi.useDeleteMutation();

  const deleteDealPropertyRelationHandler = (id: string) => {
    deleteDealPropertyRelationApi(id)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Property removed successfully",
            description: `property: ${r.data?.id}`
          });
          setSelectedProperty("");
          onClose();
        }
        if (r.status === 400) {
          errorToast({
            title: "failed to remove property",
            description: r.data.description
          });
        }
      });
  };

  const finalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box tabIndex={-1} ref={finalRef}>
      {propsButton ? (
        propsButton
      ) : (
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onOpen}
        >
          Remove Property
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Remove Property</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Text pb="10px" fontWeight={"bold"}>
              Deal: {selectedDeal && selectedDeal.LoanIdentifier}
            </Text>
            <Text pb="10px" fontWeight={"bold"}>
              {selectedProperty && propertyLabel(selectedProperty)}
            </Text>
            <Text pb="10px" fontWeight={"semibold"}>
              Are you sure you want to remove this property from the deal?
            </Text>

            <Box pt="10px">
              <Button
                {...elphiTheme.components.light.button.secondary}
                isDisabled={!selectedProperty?.id || !selectedDeal}
                onClick={() =>
                  selectedProperty &&
                  selectedDeal &&
                  deleteDealPropertyRelationHandler(
                    `${selectedDeal.id}_${selectedProperty.id}`
                  )
                }
                isLoading={deleteDealPropertyRelationApiResponse.isLoading}
              >
                Remove
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
