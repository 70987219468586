import { RolodexConfiguration } from "@elphi/types";
import { buildAction } from "../../builders/action.builder";
import { ActionDispatcher } from "../../types/action.types";
import { serviceDomainConfigurationSlice } from "./serviceDomainConfiguration.slice";
export const serviceDomainConfigurationAction: ActionDispatcher<
  RolodexConfiguration
> = (dispatch, callback) => {
  const actionDispatcher = buildAction(serviceDomainConfigurationSlice);
  return actionDispatcher(dispatch, callback);
};
