import { PosUser } from "@elphi/types";
import { createSlice, EntityState } from "@reduxjs/toolkit";
import { union } from "lodash";
import { posUserAdapter } from "./posUser.adapter";
import { posUserApi } from "./posUser.service";

export type PosUserSliceState = EntityState<PosUser> & {};

const initialState: PosUserSliceState = {
  ids: [],
  entities: {}
};

export const posUserSlice = createSlice({
  name: "posUserState",
  initialState: posUserAdapter.getInitialState(initialState),
  reducers: {
    update: posUserAdapter.updateOne,
    remove: posUserAdapter.removeOne,
    add: posUserAdapter.addOne,
    upsert: posUserAdapter.upsertOne,
    upsertMany: posUserAdapter.upsertMany,
    removeMany: posUserAdapter.removeMany,
    updateMany: posUserAdapter.updateMany
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      posUserApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      posUserApi.endpoints.getByPartyId.matchFulfilled,
      (state, { payload }) => {
        if (payload.result) {
          state.ids = union(state.ids, [payload.result.id]);
          state.entities = {
            ...state.entities,
            [payload.result.id]: payload.result
          };
        }
      }
    );
  }
});
