import { Box, Flex, Tooltip } from "@chakra-ui/react";
import {
  FieldType,
  PosUserType,
  StatusCode,
  TaskStatusType,
  TaskTemplate,
  TaskType
} from "@elphi/types";
import { useEffect, useState } from "react";
import { printDateTime } from "../../../../firebase/firebase.utils";
import { useRoleHooks } from "../../../../hooks/role.hooks";
import RoleSearch from "../../../admin/role-permission/RoleSearch";
import { BaseButtonIconDelete } from "../../../button-icon/ButtonIcon";
import { TextCell } from "../../../custom/chakra/Cell";
import StyledInputBuilder, {
  DropDownIndicatorFirstControl,
  IndicatorsContainer,
  ValueContainer,
  useFormBuilderStateHandler,
  valueContainerBySize
} from "../../../form-builder/InputBuilder";
import ModalContainer from "../../../modal-container/ModalContainer";
import { ItemsMenuCopyWidget } from "../../../party/table/v2/ItemsMenuCopyWidget";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../../table/TableRowSizeComponent";
import { useElphiToast } from "../../../toast/toast.hook";
import {
  createOptionsFromMap,
  createSelectOptions
} from "../../../utils/formUtils";
import { taskTemplateTypeOptions } from "../templates/task.template";
import { useTaskTemplateTableHooks } from "./TaskTemplateTable";
import { posUserTypeOptionMap } from "./taskTemplate.util";

export type TaskTemplateRowProps = {
  index: string | number;
  taskTemplateId: string;
  taskTemplateHooks: ReturnType<
    typeof useTaskTemplateTableHooks
  >["taskTemplateHooks"];
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  state: {
    [id: string]: Partial<TaskTemplate>;
  };
};

export const multiDropDownComponent = {
  Control: DropDownIndicatorFirstControl,
  ValueContainer: valueContainerBySize.l,
  IndicatorsContainer: IndicatorsContainer
};

export const TaskTemplateTableRow = ({
  index,
  syncState,
  taskTemplateId,
  taskTemplateHooks,
  onChange,
  state
}: TaskTemplateRowProps) => {
  const { taskTemplateState, deleteTemplateResponse, deleteTemplate } =
    taskTemplateHooks;
  const [deleteId, setDeleteId] = useState("");
  const { successToast, errorToast } = useElphiToast();
  const { rolePaginationHandler } = useRoleHooks();

  useEffect(() => {
    setDeleteId("");
    syncState({
      state: taskTemplateState.entities?.[taskTemplateId],
      shouldSync: !!taskTemplateState.entities?.[taskTemplateId],
      statePath: () => {
        return [taskTemplateId.toString()];
      }
    });
  }, [taskTemplateState.entities?.[taskTemplateId]]);

  const deleteTemplateHandler = async (id: string) => {
    await deleteTemplate(id).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Template Deleted",
          description: `template: ${r.data?.id}`
        });
      } else if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to delete template",
          description: r.data?.description
        });
      }
    });
  };

  const data = taskTemplateState.entities[taskTemplateId]!;

  return (
    <Box>
      {data && (
        <Flex w="100%" key={index} justify="space-evenly">
          <TableRowSizeComponent
            size={SIZE_FIELD.S}
            withBorderRightWidth={false}
          >
            <TextCell>{index}</TextCell>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.M}
            withBorderRightWidth={false}
          >
            <TextCell>
              {data?.createdAt && printDateTime(data.createdAt)}
            </TextCell>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                currentValue={state?.[taskTemplateId]?.template?.templateName}
                fieldType={FieldType.String}
                onChange={(e) => {
                  onChange({
                    fieldKey: [taskTemplateId, "template", "templateName"],
                    fieldType: FieldType.String,
                    value: e.target.value
                  });
                }}
                label=""
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                customComponent={{
                  ValueContainer: ValueContainer
                }}
                currentValue={state?.[taskTemplateId]?.template?.type}
                fieldType={FieldType.SingleSelect}
                options={createSelectOptions(TaskType, taskTemplateTypeOptions)}
                onChange={(e) => {
                  onChange({
                    fieldKey: [taskTemplateId, "template", "type"],
                    fieldType: FieldType.SingleSelect,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <RoleSearch
                customComponent={multiDropDownComponent}
                currentValue={
                  state?.[taskTemplateId]?.template?.checklistOf || []
                }
                fieldType={FieldType.MultiSelect}
                fetchMore={rolePaginationHandler.next}
                onSelect={(items: string[]) => {
                  onChange({
                    fieldKey: [taskTemplateId, "template", "checklistOf"],
                    fieldType: FieldType.MultiSelect,
                    value: items
                  });
                }}
                hideSelectedOptions={false}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <StyledInputBuilder
              chakraStyles={{
                valueContainer: (provided) => ({
                  ...provided,
                  fontSize: "12px"
                })
              }}
              hideSelectedOptions={false}
              customComponent={{
                ValueContainer: ValueContainer
              }}
              currentValue={state?.[taskTemplateId]?.template?.posUserType}
              fieldType={FieldType.MultiSelect}
              isOptionDisabled={
                (state?.[taskTemplateId]?.template?.posUserType?.length || 0) >
                0
              }
              options={createSelectOptions(PosUserType, posUserTypeOptionMap)}
              onChange={(e) => {
                onChange({
                  fieldKey: [taskTemplateId, "template", "posUserType"],
                  fieldType: FieldType.MultiSelect,
                  value: e.target.value
                });
              }}
              label=""
              isValid={true}
            />
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <RoleSearch
                customComponent={multiDropDownComponent}
                currentValue={
                  state?.[taskTemplateId]?.template?.assignableBy || []
                }
                fieldType={FieldType.MultiSelect}
                fetchMore={rolePaginationHandler.next}
                onSelect={(items: string[]) => {
                  onChange({
                    fieldKey: [taskTemplateId, "template", "assignableBy"],
                    fieldType: FieldType.MultiSelect,
                    value: items
                  });
                }}
                hideSelectedOptions={false}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <RoleSearch
                customComponent={multiDropDownComponent}
                currentValue={
                  state?.[taskTemplateId]?.template?.editableBy || []
                }
                fieldType={FieldType.MultiSelect}
                fetchMore={() => rolePaginationHandler.next()}
                onSelect={(items: string[]) => {
                  onChange({
                    fieldKey: [taskTemplateId, "template", "editableBy"],
                    fieldType: FieldType.MultiSelect,
                    value: items
                  });
                }}
                hideSelectedOptions={false}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  }),
                  dropdownIndicator: (prev) => ({
                    ...prev,
                    w: "20px",
                    p: "0px"
                  })
                }}
                hideSelectedOptions={false}
                customComponent={{
                  ValueContainer: ValueContainer
                }}
                currentValue={
                  state?.[taskTemplateId]?.template?.availableStatus
                }
                fieldType={FieldType.MultiSelect}
                options={createOptionsFromMap(TaskStatusType)}
                onChange={(e) => {
                  onChange({
                    fieldKey: [taskTemplateId, "template", "availableStatus"],
                    fieldType: FieldType.MultiSelect,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.S}
            withBorderRightWidth={false}
          >
            <Flex alignItems={"center"}>
              <Tooltip label={"system identifiers"} placement="top">
                <Box pr="2px">
                  <ItemsMenuCopyWidget
                    items={[
                      { label: "elphi-task-template-id", value: data.id }
                    ]}
                  />
                </Box>
              </Tooltip>

              <BaseButtonIconDelete
                isDisabled
                onClick={() => setDeleteId(taskTemplateId)}
                isLoading={deleteTemplateResponse.isLoading}
              />
            </Flex>
          </TableRowSizeComponent>
        </Flex>
      )}
      <ModalContainer
        isShow={!!deleteId && !!state?.[deleteId]}
        onCloseModal={() => setDeleteId("")}
        header={`Are you sure you want to delete ${state?.[taskTemplateId]?.template?.templateName}?`}
        submit={{
          onConfirm: () => deleteTemplateHandler(taskTemplateId)
        }}
      />
    </Box>
  );
};
