import { Statement } from "@elphi/types";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState, store } from "../redux/store";
import { statementApi } from "../redux/v2/statement/statement.service";
import { statementSlice } from "../redux/v2/statement/statement.slice";
import { getListFromDictionary } from "../utils/batchUtils";
import { compareStrings } from "../utils/searchUtils";

export const useStatementHooks = () => {
  const { successToast, errorToast } = useElphiToast();

  const dispatcher = useDispatch();
  const [searchStatement, searchResponse] = statementApi.useLazySearchQuery();
  const [updateBatchApi, updateBatchResponse] =
    statementApi.useBatchUpdateMutation();
  const [deleteStatementApi, deleteStatementResponse] =
    statementApi.useDeleteMutation();
  const [getStatement, getStatementResponse] = statementApi.useLazyGetQuery();
  const [updateStatementApi] = statementApi.useUpdateMutation();
  const [createStatementApi, createStatementApiResponse] =
    statementApi.useCreateMutation();
  const statementState = useSelector((state: RootState) => state.statement);

  const rankedSort = (query: string) => {
    return Object.values(statementState.entities).sort((a, b) => {
      const statementAStartDate = a?.start || "";
      const statementAEndDate = a?.end || "";
      const statementAStatementBalance = a?.statementBalance || "";

      const statementBStartDate = b?.start || "";
      const statementBEndDate = b?.end || "";
      const statementBStatementBalance = b?.statementBalance || "";

      const rankA =
        compareStrings(query, statementAStartDate) * 33 +
        compareStrings(query, statementAEndDate) * 34 +
        compareStrings(query, statementAStatementBalance) * 33;

      const rankB =
        compareStrings(query, statementBStartDate) * 33 +
        compareStrings(query, statementBEndDate) * 34 +
        compareStrings(query, statementBStatementBalance) * 33;
      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const setSelectedStatement = (id: string) =>
    store.dispatch(statementSlice.actions.selectedId({ id }));

  const selectedStatement = useSelector((state: RootState) =>
    state.statement.selectedId
      ? state.statement.entities[state.statement.selectedId]
      : undefined
  );

  const createStatement = async (newStatement: Statement) => {
    const response = await createStatementApi(newStatement);
    return responseHandler(response);
  };

  const updateStatement = async (
    newStatement: { id: string } & Partial<Statement>
  ) => {
    const response = await updateStatementApi(newStatement);
    return responseHandler(response);
  };

  const deleteStatement = async (r: string) => {
    const response = await deleteStatementApi(r).then(responseHandler);
    if (response.status === 200) {
      dispatcher(statementSlice.actions.remove(r));
    }
    return response;
  };
  const updateBatch = async (r: {
    statements: ({ id: string } & Partial<Statement>)[];
  }) => {
    const response = await updateBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const updateStatementsHandler = async (
    statementsMap:
      | {
          [id: string]: {
            id: string;
          } & Partial<Statement>;
        }
      | undefined
  ) => {
    if (!statementsMap) return;
    const statements = getListFromDictionary(statementsMap);
    if (!statements.length) return;
    return await updateBatch({ statements } as {
      statements: ({
        id: string;
      } & Partial<Statement>)[];
    });
  };

  const handleUpdateStatementBatch = async (r: {
    statements: { [id: string]: Statement };
  }) => {
    if (!r.statements) return null;
    const listStatements = getListFromDictionary(r?.statements);
    if (!listStatements.length) return null;
    const filteredStatements = listStatements.filter(
      (d): d is Statement => !!d
    );
    return await updateBatch({ statements: filteredStatements }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Statement Updated",
          description: `total statements updated: ${listStatements.length}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      return r;
    });
  };

  return {
    searchStatement,
    searchResponse,
    rankedSort,
    getStatement,
    getStatementResponse,
    createStatement,
    createStatementApiResponse,
    statementState,
    updateStatement,
    updateStatementsHandler,
    updateBatch,
    updateBatchResponse,
    deleteStatement,
    deleteStatementResponse,
    handleUpdateStatementBatch,
    setSelectedStatement,
    selectedStatement
  };
};

export default useStatementHooks;
