import { FieldType, Property } from "@elphi/types";
import { useEffect } from "react";
import { AppConfig } from "../../../../../../config/appConfig";
import { EMPTY } from "../../../../../../constants/common";
import { usePropertyHooks } from "../../../../../../hooks/property.hooks";
import { usePropertyFormHandler } from "../../../../../../hooks/propertyform.hooks";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../../../../form-builder/InputBuilder";
import { DealPropertyLiveStateSearchContainer } from "../../../../../property/PropertySearch";
import {
  LoanpassCollapseSection,
  LoanpassSection,
  LoanpassSectionsProps
} from "../LoanPassSections";

export const PropertyInformation = (
  props: LoanpassSectionsProps<{ state: Property }>
) => {
  const { sectionBuilders } = props;
  const { selectedProperty, setSelectedProperty } = usePropertyHooks();
  const { updatePropertyHandler } = usePropertyFormHandler();
  const {
    onChange: propertyOnChange,
    state: propertyState,
    syncState: syncPropertyState
  } = useFormBuilderStateHandler({
    initialState: {
      properties: {} as { [id: string]: Property }
    },
    callback: updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  const selectedPropertyId = selectedProperty ? selectedProperty?.id : EMPTY;

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      propertyOnChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  useEffect(() => {
    selectedProperty &&
      syncPropertyState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
  }, [selectedProperty]);

  const section = sectionBuilders?.({
    state: propertyState.properties[selectedPropertyId] as Property,
    onChange: propertyOnChangeBatch
  });

  return (
    <LoanpassCollapseSection
      shouldShow={props.shouldShow}
      title="Property Information"
      customBody={
        <LoanpassSection
          customKey="loanpass-property-sections"
          section={section}
          onChange={propertyOnChangeBatch}
          searchComponent={
            <DealPropertyLiveStateSearchContainer
              fieldType={FieldType.SingleSelect}
              currentValue={selectedPropertyId}
              onSelect={setSelectedProperty}
              dealId={props.dealId}
              wrapperStyle={{ p: "0px" }}
            />
          }
        />
      }
    />
  );
};
