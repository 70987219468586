import { Box } from "@chakra-ui/react";
import { Deal, Property } from "@elphi/types";
import { useEffect } from "react";
import { AppConfig } from "../../config/appConfig";
import { usePropertyFormHandler } from "../../hooks/propertyform.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";
import FormBuilder, { OnChangeInput } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";

const SectionsAfterLeasingTable = (props: {
  dealState?: Deal;
  selectedProperty?: Property;
  snapshotId?: string;
  navigationPath?: NavigationPath;
}) => {
  const { selectedProperty, navigationPath } = props;
  const { updatePropertyHandler } = usePropertyFormHandler();

  const { onChange, syncState } = useFormBuilderStateHandler({
    initialState: { properties: {} },
    callback: props.snapshotId ? undefined : updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (selectedProperty && !props.snapshotId) {
      syncState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
    }
  }, [selectedProperty, props.snapshotId]);

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      !props.snapshotId &&
      onChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  return (
    <Box h="100%" w="100%">
      {selectedProperty && (
        <Box h="100%" w="100%">
          <Box bgColor="white" h="100%" w="100%">
            <FormBuilder
              customKey="sectionsAfterLeasingTable"
              onChange={propertyOnChangeBatch}
              sections={[]}
              navigationPath={navigationPath}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default SectionsAfterLeasingTable;
