import { createIcon } from "@chakra-ui/react";

const customHomeIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 28 28",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.66663 11.6667L14 3.5L23.3333 11.6667V23.3333H17.5V18.6667C17.5 17.7385 17.1312 16.8482 16.4748 16.1918C15.8185 15.5355 14.9282 15.1667 14 15.1667C13.0717 15.1667 12.1815 15.5355 11.5251 16.1918C10.8687 16.8482 10.5 17.7384 10.5 18.6667V23.3333H4.66664L4.66663 11.6667Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const HomeIcon = customHomeIcon();
