import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import { Party } from "@elphi/types";
import { CreateAssetModal } from "../asset/CreateAssetComponent";
import { DeleteAssetModal } from "../asset/DeleteAssetComponent";
import { RemoveAssetModal } from "../asset/RemoveAssetComponent";
import { AssetTableContainer } from "./AssetTable";

const AssetPageContainer = (props: {
  selectedParty: Partial<Party>;
  snapshotId?: string;
}) => {
  return (
    <Box w="100%" overflow="hidden">
      <Box w="100%" minH="105px" maxH="105px" h="100%">
        <Box pl="10px" bgColor="white" position="sticky" h="100%" top="0">
          <Flex justifyContent={"space-between"}>
            {!props.snapshotId && (
              <Box>
                <Flex>
                  <Box p="10px">
                    <DeleteAssetModal selectedParty={props.selectedParty} />
                  </Box>
                  <Box p="10px">
                    <RemoveAssetModal selectedParty={props.selectedParty} />
                  </Box>
                  <Box p="10px">
                    <CreateAssetModal selectedParty={props.selectedParty} />
                  </Box>
                </Flex>
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
      <Flex flexDirection={"row"}></Flex>
      <Tabs h="100%" w="100%" position="sticky" top="0">
        <TabList>
          <Tab>Accounts</Tab>
        </TabList>
        <TabPanels h="100%" w="100%" p="0">
          <TabPanel h="100%" w="100%" p="0">
            <AssetTableContainer
              selectedParty={props.selectedParty}
              snapshotId={props.snapshotId}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AssetPageContainer;
