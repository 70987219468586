import { DEFAULT_PARTY_GROUP, PartyGroup, Status } from "@elphi/types";
import { isString, uniq } from "lodash";
import { useEffect } from "react";
import { usePartyGroup } from "../../../../hooks/partyGroup.hooks";
import { SearchComponentProps } from "../../../SearchComponent";
import { SearchHandler } from "../../../search/SearchHandler";

const buildOption = (partyGroup: PartyGroup) => ({
  label: partyGroup.name,
  value: partyGroup.id
});

const disabledFilter = (
  currentValue: string | string[],
  partyGroup: PartyGroup
) => {
  if (isString(currentValue) && partyGroup.id === currentValue) {
    return true;
  }

  return partyGroup.status === Status.Active;
};

type SearchProps = {
  filter?: (partyGroup: PartyGroup) => boolean;
  shouldFilterDisabled?: boolean;
  preventInitialFetch?: boolean;
} & Pick<
  SearchComponentProps,
  | "onSelect"
  | "currentValue"
  | "label"
  | "isDisabled"
  | "isClearable"
  | "wrapperStyle"
> &
  Pick<Partial<SearchComponentProps>, "fieldType">;

export const PartyGroupSearch = (props: SearchProps) => {
  const {
    searchApi,
    getBatchApi,
    getBatchResponse,
    searchResponse,
    rankedSort,
    partyGroupState
  } = usePartyGroup();

  useEffect(() => {
    if (!props.preventInitialFetch) {
      const ids: string[] = [DEFAULT_PARTY_GROUP];

      if (props.currentValue) {
        const values = isString(props.currentValue)
          ? [props.currentValue]
          : props.currentValue;

        ids.push(...values);
      }

      getBatchApi(uniq(ids), true);
    }
  }, []);

  const filter = props.shouldFilterDisabled
    ? (partyGroup: PartyGroup) => disabledFilter(props.currentValue, partyGroup)
    : props.filter;

  return (
    <SearchHandler
      version="v2"
      {...props}
      filter={filter}
      isLoading={getBatchResponse.isLoading}
      isClearable={props.isClearable || false}
      searchApi={searchApi}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={partyGroupState}
      buildOption={buildOption}
      wrapperStyle={props.wrapperStyle}
    />
  );
};
