import {
  LeasedFinancedUnitKeys,
  LeasedFinancedUnitsFieldType,
  PropertyAggregationFields,
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../aggregations";
import { OmitCreate, OmitUpdate } from "../../db";
import { BooleanSelectType } from "../fieldFormat.types";
import { StateCode, StateName } from "../location.types";
import { PortfolioTemplate } from "../portfolioTemplate.types";
import {
  AppraisalCoordinatorType,
  AppraisalType,
  AppraisalValueDerivationType,
  BillingFrequencyType,
  ConditionRatingType,
  ConstructionBudgetAssessmentType,
  FloodInsurancePremiumPaymentType,
  InspectionOrderType,
  LeaseStatusType,
  LeaseStrategyType,
  PropertyInsurancePremiumPaymentType,
  PropertyLoanPurpose,
  PropertyRightsOwnershipType,
  PropertyType,
  PropertyZoningComplianceRatingType,
  QualityRatingType
} from "../property.enums.types";
import {
  DataSheetProperty,
  LeasedFinancedUnit,
  Property
} from "../property.types";
import {
  FloodCertificateVendorType,
  IntegrationType,
  PropertyReportVendorType,
  RentalDataVendorType
} from "../task-types";
import { removeBaseFields } from "./utils/entityTemplate.utils";

const leasedFinancedUnitsTemplate = (): LeasedFinancedUnitsFieldType => {
  return Object.keys(LeasedFinancedUnitKeys).reduce((acc, key) => {
    const unitKey = key as keyof typeof LeasedFinancedUnitKeys;
    acc[unitKey] = {
      AdjustedRentAmount: aggregationFieldTemplate()
    };
    return acc;
  }, {} as LeasedFinancedUnitsFieldType);
};

export const getPropertyAggregationTemplate = () => {
  const template: PropertyAggregationFields = {
    InitialFloodInsuranceEscrowAmount: aggregationFieldTemplate(),
    InitialFloodInsuranceEscrowMonthCount: aggregationFieldTemplate(),
    InitialPropertyInsuranceEscrowMonthCount: aggregationFieldTemplate(),
    InitialPropertyInsuranceEscrowAmount: aggregationFieldTemplate(),
    CityInitialTaxEscrowAmount: aggregationFieldTemplate(),
    CountyInitialTaxEscrowAmount: aggregationFieldTemplate(),
    CityInitialTaxEscrowMonthCount: aggregationFieldTemplate(),
    CountyInitialTaxEscrowMonthCount: aggregationFieldTemplate(),
    SpecialAssessmentInitialTaxEscrowMonthCount: aggregationFieldTemplate(),
    SpecialAssessmentInitialTaxEscrowAmount: aggregationFieldTemplate(),
    MonthlyLeaseRentAmount: aggregationFieldTemplate(),
    TotalInsuranceEscrowAmount: aggregationFieldTemplate(),
    TotalTaxEscrowAmount: aggregationFieldTemplate(),
    MonthlyMarketRentAmount: aggregationFieldTemplate(),
    AdjustedRentAmount: aggregationFieldTemplate(),
    AnnualTaxAmount: aggregationFieldTemplate(),
    BridgeLTVPercent: thirdPartyFieldTemplate(),
    PricingEngineLTARVRatePercent: aggregationFieldTemplate(),
    AllocatedLoanAmount: aggregationFieldTemplate(),
    TotalCostAmount: aggregationFieldTemplate(),
    LTCRatePercent: aggregationFieldTemplate(),
    MinimumRentLossCoverageAmount: aggregationFieldTemplate(),
    LeasedFinancedUnits: leasedFinancedUnitsTemplate(),
    Integrations: {
      [IntegrationType.PropertyReport]: {
        [PropertyReportVendorType.SiteX]: {
          addressLineText: thirdPartyFieldTemplate(),
          addressUnitIdentifier: thirdPartyFieldTemplate(),
          cityName: thirdPartyFieldTemplate(),
          stateCode: thirdPartyFieldTemplate(),
          postalCode: thirdPartyFieldTemplate(),
          countyName: thirdPartyFieldTemplate(),
          assessorParcelNumber: thirdPartyFieldTemplate(),
          fips: thirdPartyFieldTemplate(),
          orderStatus: thirdPartyFieldTemplate(),
          orderCompletedOn: thirdPartyFieldTemplate()
        }
      },
      [IntegrationType.FloodCertificate]: {
        [FloodCertificateVendorType.ServiceLink]: {
          OrderNumber: thirdPartyFieldTemplate(),
          OrderStatus: thirdPartyFieldTemplate(),
          FloodCheckStatus: thirdPartyFieldTemplate(),
          FloodCheckInfo: thirdPartyFieldTemplate(),
          FloodCheckDisclaimer: thirdPartyFieldTemplate(),
          FloodCheckStatusDescription: thirdPartyFieldTemplate(),
          Disclaimer: thirdPartyFieldTemplate(),
          DeterminationDate: thirdPartyFieldTemplate(),
          FloodMapDate: thirdPartyFieldTemplate(),
          FloodZone: thirdPartyFieldTemplate(),
          GeoCity: thirdPartyFieldTemplate(),
          GeoCounty: thirdPartyFieldTemplate(),
          GeoPropertyAddress1: thirdPartyFieldTemplate(),
          GeoStateCode: thirdPartyFieldTemplate(),
          GeoZipFirst5: thirdPartyFieldTemplate(),
          GeoZipLast4: thirdPartyFieldTemplate(),
          NFIPLegalCommunityNumber: thirdPartyFieldTemplate(),
          NFIPLegalCommunityName: thirdPartyFieldTemplate(),
          NFIPMapCommunityName: thirdPartyFieldTemplate(),
          NFIPMapCommunityNumber: thirdPartyFieldTemplate(),
          NFIPMapPanelNumber: thirdPartyFieldTemplate(),
          NFIPPrgStat: thirdPartyFieldTemplate(),
          PropertyMSAMD: thirdPartyFieldTemplate(),
          RequestedInformation: thirdPartyFieldTemplate(),
          ResultCode: thirdPartyFieldTemplate(),
          ResultDescription: thirdPartyFieldTemplate(),
          ReturnTime: thirdPartyFieldTemplate(),
          ReturnDate: thirdPartyFieldTemplate(),
          TSDNComments: thirdPartyFieldTemplate(),
          SpecialFloodHazardAreaIndicator: aggregationFieldTemplate()
        }
      },
      [IntegrationType.RentalData]: {
        [RentalDataVendorType.RentRange]: {
          confidenceScore: thirdPartyFieldTemplate(),
          errorMessage: thirdPartyFieldTemplate(),
          orderStatus: thirdPartyFieldTemplate(),
          requestId: thirdPartyFieldTemplate()
        }
      }
    }
  };

  return template;
};

export const LeasedFinancedUnitTemplate = (): LeasedFinancedUnit => ({
  UnitName: "",
  MonthlyLeaseRentAmount: "",
  MonthlyMarketRentAmount: "",
  AdjustedRentAmount: "",
  LeaseStatusType: "" as LeaseStatusType,
  LeaseExpirationDate: "",
  RentReportConfidenceScore: "",
  PaymentVerificationIndicator: "" as BooleanSelectType,
  CorporateLeaseIndicator: "" as BooleanSelectType
});

export const getPropertyTemplate = (): Property => {
  const property: Property = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    aggregations: getPropertyAggregationTemplate(),
    Address: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      CountyName: "",
      FullAddressName: "",
      LegalDescription: "",
      PostalCode: "",
      PropertyBlockIdentifier: "",
      PropertyIdentifier: "",
      PropertyLotIdentifier: "",
      PropertySubdivisionIdentifier: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    AnnualHomeownersAssociationFeeAmount: "",
    AnnualTaxesAmount: "",
    Appraisal: {
      AppraisalValueDerivationType: "" as AppraisalValueDerivationType,
      AppraisalExpirationDate: "",
      AppraisalOrderedDate: "",
      AppraisalCompletedDate: "",
      AppraisalEffectiveDate: "",
      AppraisalType: "" as AppraisalType,
      QualityRatingType: "" as QualityRatingType,
      ConditionRatingType: "" as ConditionRatingType,
      TotalBedroomCount: "",
      TotalBathroomCount: "",
      PropertyStructureBuiltYear: "",
      AppraisalDate: "",
      PropertyValuationAmount: "",
      PropertyAreaValue: "",
      PropertyBuiltYear: "",
      SpecificZoningClassification: "",
      PropertyZoningDescription: "",
      PropertyZoningComplianceRatingType:
        "" as PropertyZoningComplianceRatingType,
      SubjectToPropertyValuationAmount: "",
      AppraisalCoordinator: "" as AppraisalCoordinatorType,
      InternalValuationEligibilityIndicator: "" as BooleanSelectType,
      PropertyAccessInformation: "",
      AppraisalForm1007RequiredIndicator: "" as BooleanSelectType
    },
    AppraisalManagementCompany: {
      RepresentativeFullName: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      AppraisalManagementCompanyName: ""
    },
    CondoWarrantableIndicator: "",
    CondoCertificateFeesCollectedIndicator: "" as BooleanSelectType,
    CondoCertificateComments: "",
    ContractClosingDate: "",
    PropertyLoanPurpose: "" as PropertyLoanPurpose,
    DeferredMaintenanceAmount: "",
    FinancedUnitCount: "",
    FloodInsurance: {
      DeductibleAmount: "",
      DwellingCoverageAmount: "",
      InsurancePremiumPaymentType: "" as FloodInsurancePremiumPaymentType,
      PolicyExpirationDate: "",
      PolicyNumber: "",
      InsurancePremiumAmount: "",
      InsurancePremiumOutstandingAmount: "",
      NextPaymentDueDate: ""
    },
    FloodInsuranceAgency: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      CountyName: "",
      FullName: "",
      RepresentativeFullName: "",
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    LeaseExpirationDate: "",
    LeaseStatusType: "" as LeaseStatusType,
    LTARVAmount: "",
    NetFundAmount: "",
    NetFundRatePercent: "",
    NetWireAmount: "",
    AllocatedRDSRRatePercent: "",
    OriginalPurchaseDate: "",
    OriginalPurchasePriceAmount: "",
    OutstandingLoanPayoffAmount: "",
    ParcelNumber: "",
    PropertyInsurance: {
      DeductibleAmount: "",
      DwellingCoverageAmount: "",
      LiabilityCoverageAmount: "",
      PolicyExpirationDate: "",
      PropertyInsurancePolicyIdentifier: "",
      RentalLossCoverageAmount: "",
      InsurancePremiumPaymentType: "" as PropertyInsurancePremiumPaymentType,
      ReplacementCoverageIndicator: "" as BooleanSelectType,
      BuilderRiskInsurance: "",
      InsurancePremiumAmount: "",
      InsurancePremiumOutstandingAmount: "",
      NextPaymentDueDate: ""
    },
    PropertyInsuranceAgency: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      CountyName: "",
      FullName: "",
      InsuranceAgentFullName: "",
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    PropertyInspection: {
      InspectionInitialRequestDate: "",
      InspectionRequestOrderedDate: "",
      InspectionReportDueDate: "",
      InspectionOrderType: "" as InspectionOrderType,
      InspectorFullName: "",
      ConstructionAnalystFullName: "",
      InspectionOrderReceivedDate: "",
      InspectionOrderProcessedDate: "",
      InspectionDocumentsReceivedDate: "",
      InspectionInitialDelayReasonStatusType: []
    },
    PropertyType: "" as PropertyType,
    PurchasePriceAmount: "",
    RenovationCostAmount: "",
    ConstructionCostAmount: "",
    ConstructionCostComments: "",
    TaxDueDate: "",
    ContractExecutionDate: "",
    CEMARefinanceIndicator: "" as BooleanSelectType,
    PropertyRightsOwnershipType: "" as PropertyRightsOwnershipType,
    LeasedFinancedUnits: {
      unit0: LeasedFinancedUnitTemplate(),
      unit1: LeasedFinancedUnitTemplate(),
      unit2: LeasedFinancedUnitTemplate(),
      unit3: LeasedFinancedUnitTemplate(),
      unit4: LeasedFinancedUnitTemplate(),
      unit5: LeasedFinancedUnitTemplate(),
      unit6: LeasedFinancedUnitTemplate(),
      unit7: LeasedFinancedUnitTemplate(),
      unit8: LeasedFinancedUnitTemplate()
    },
    RentReportConfidenceScorePercent: "",
    TotalRentalIncomeAmount: "",
    CountyAnnualTaxAmount: "",
    CountyTaxDueDate: "",
    CountyTaxBillingFrequencyType: "" as BillingFrequencyType,
    CountyTaxingAuthority: "",
    CityAnnualTaxAmount: "",
    CityTaxDueDate: "",
    CityTaxBillingFrequencyType: "" as BillingFrequencyType,
    CityTaxingAuthority: "",
    SpecialAssessmentAnnualTaxAmount: "",
    SpecialAssessmentTaxDueDate: "",
    SpecialAssessmentTaxBillingFrequencyType: "" as BillingFrequencyType,
    SpecialAssessmentTaxingAuthority: "",
    CapitalExpenditureAmount: "",
    PropertyDetailComments: "",
    PSASignedIndicator: "" as BooleanSelectType,
    PSAAddendumIndicator: "" as BooleanSelectType,
    PSAAssignmentContractIndicator: "" as BooleanSelectType,
    AssignmentFeeAmount: "",
    PSAExtensionFormIndicator: "" as BooleanSelectType,
    PSAExtensionRequestedIndicator: "" as BooleanSelectType,
    PSASellerOwnerRecordMismatchIndicator: "" as BooleanSelectType,
    PSABuyerBorrowerMismatchIndicator: "" as BooleanSelectType,
    PayoffExpirationDate: "",
    MortgageeContactFullName: "",
    MortgageeContactPointEmailValue: "",
    MortgageeContactPointTelephoneValue: "",
    MortgageeFullName: "",
    SheetMetadata: {} as DataSheetProperty<PortfolioTemplate>,
    PSAExpirationDate: "",
    BorrowerRenovationCostsAfterPurchaseAmount: "",
    InternalL1CRefinanceIndicator: "" as BooleanSelectType,
    LeaseStrategy: "" as LeaseStrategyType,
    RentalLTVPercent: "",
    ConstructionBudgetAssessment: "" as ConstructionBudgetAssessmentType,
    AnnualCapitalExpenditure: "",
    Integrations: {
      [IntegrationType.FloodCertificate]: {
        [FloodCertificateVendorType.ServiceLink]: {
          CTFFlagIndicator: "",
          FloodFlagIndicator: "",
          LOLFlagIndicator: "",
          ProductCode: "",
          UsersEmailAddress: ""
        }
      }
    },
    defaultInterestPaymentsOrFeesNotedIndicator: ""
  };
  return property;
};

export const getPropertyTemplateOmitCreate = (): OmitCreate<Property> => {
  const property: Property = getPropertyTemplate();
  return removeBaseFields({
    entity: property,
    operationType: "create"
  }) as OmitCreate<Property>;
};

export const getPropertyTemplateOmitUpdate = (): OmitUpdate<Property> => {
  const property: Property = getPropertyTemplate();
  return removeBaseFields({
    entity: property,
    operationType: "update"
  }) as OmitUpdate<Property>;
};
