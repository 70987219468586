import {
  CreditReportVendor,
  CreditReportVendorType
} from "./credit-report-vendor/vendor.types";
import { BaseIntegrationTask, IntegrationType } from "./integration.types";

export enum CreditPullType {
  HardPull = "hard pull",
  SoftPull = "soft pull",
  None = ""
}

export type BaseCreditReportOrderTask<
  T extends {
    vendor: {
      [P in CreditReportVendorType]?: object;
    } & { selectedVendor: CreditReportVendorType };
  }
> = BaseIntegrationTask<IntegrationType.CreditReport> & {
  template: T;
};

//can be used for union
export type CreditReportOrderTask = CreditReportVendor;
