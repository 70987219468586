import { Box } from "@chakra-ui/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useClickOutsideHook } from "../../hooks/clickOutside.hooks";

type TooltipType = "normal" | "full";

export type CustomTooltipProps = {
  id: string;
  showTooltip?: boolean;
  children: ReactNode;
  handleMouseLeave: () => void;
  type?: TooltipType;
};

const SAFETY_ZONE = 130;

export const CustomTooltip = ({
  id,
  children,
  showTooltip,
  handleMouseLeave,
  type = "normal"
}: CustomTooltipProps) => {
  const [horizontalPosition, setHorizontalPosition] = useState<
    "left" | "right"
  >("right");
  const [verticalPosition, setVerticalPosition] = useState<"top" | "bottom">(
    "bottom"
  );
  const tooltipRef = useRef<HTMLDivElement>(null);

  const setPositions = (tooltipElement: HTMLElement | null) => ({
    setHorizontal: () => {
      if (!tooltipElement || !showTooltip) return undefined;
      const boundingRect = tooltipElement.getBoundingClientRect();

      const rightEdge = boundingRect.right + SAFETY_ZONE;
      const viewportWidth = window.innerWidth;

      if (rightEdge > viewportWidth) {
        setHorizontalPosition("left");
      } else {
        setHorizontalPosition("right");
      }
    },
    setVertical: () => {
      if (!tooltipElement || !showTooltip) return undefined;
      const boundingRect = tooltipElement.getBoundingClientRect();

      const bottomEdge = boundingRect.bottom + SAFETY_ZONE;
      const viewportHeight = window.innerHeight;

      if (bottomEdge > viewportHeight) {
        setVerticalPosition("top");
      } else {
        setVerticalPosition("bottom");
      }
    }
  });

  const typeInit = () => {
    const tooltipElement = document.getElementById(id);
    const positions = setPositions(tooltipElement);

    if (type === "normal") {
      positions.setHorizontal();
      positions.setVertical();
    } else if (type === "full") {
      positions.setHorizontal();
    }
  };

  useEffect(() => {
    typeInit();
  }, [id, showTooltip]);

  const getTypeConfig = () => {
    if (type === "normal") {
      const leftPosition = horizontalPosition === "right" ? "90%" : "10%";
      const topPosition = verticalPosition === "bottom" ? "0" : "auto";
      const bottomPosition = verticalPosition === "top" ? "0" : "auto";
      const transform =
        verticalPosition === "bottom"
          ? "translate(-50%, 0)"
          : "translate(-50%, 0)";

      return {
        left: leftPosition,
        top: topPosition,
        bottom: bottomPosition,
        transform
      };
    }
    if (type === "full") {
      const transform =
        horizontalPosition === "right"
          ? "translate(10%, 0)"
          : "translate(-55%, 0)";

      return {
        transform,
        top: 10,
        height: "90%"
      };
    }
  };

  const typeProps = getTypeConfig();

  useClickOutsideHook({
    ref: tooltipRef,
    onClickOutside: handleMouseLeave,
    enableCloseClickOutside: true,
    enableMouseLeave: true,
    onMouseLeave: handleMouseLeave
  });

  return (
    showTooltip && (
      <Box
        {...typeProps}
        ref={tooltipRef}
        id={id}
        pos="absolute"
        transition="all 0.3s ease-in-out"
        zIndex={888}
        bgColor="white"
        padding="6px"
        border="1px solid #e2e8f0"
        borderRadius="0.25rem"
        boxShadow="0px 1px 3px #0000001a"
      >
        {children}
      </Box>
    )
  );
};
