import { ElphiCellType, ElphiTableProps } from "../../../table/table.types";
import { actionStyles } from "../../styles/table";

export const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    data: "",
    type: ElphiCellType.String,
    maxWidth: "80px",
    minWidth: "80px"
  },
  {
    index: 1,
    data: "Actions",
    type: ElphiCellType.String,
    minWidth: actionStyles.minWidth,
    maxWidth: actionStyles.maxWidth
  },
  {
    index: 2,
    data: "Property - Address Line 1",
    type: ElphiCellType.String
  },
  {
    index: 3,
    data: "Property - Address Line 2",
    type: ElphiCellType.String
  },
  {
    index: 4,
    data: "Property - City",
    type: ElphiCellType.String
  },
  {
    index: 5,
    data: "Property - County",
    type: ElphiCellType.String
  },
  {
    index: 6,
    data: "Property - State Name",
    type: ElphiCellType.String
  },
  {
    index: 7,
    data: "Property - State Code",
    type: ElphiCellType.String
  },
  {
    index: 8,
    data: "Property - Zip Code",
    type: ElphiCellType.String
  },
  {
    index: 9,
    data: "Assessors Parcel Number (APN)",
    type: ElphiCellType.String
  },
  {
    index: 10,
    data: "Lot Number",
    type: ElphiCellType.String
  },
  {
    index: 11,
    data: "Block",
    type: ElphiCellType.String
  },
  {
    index: 12,
    data: "Subdivision",
    type: ElphiCellType.String
  },
  {
    index: 13,
    data: "Construction Budget",
    type: ElphiCellType.String
  },
  {
    index: 14,
    data: "As-Is Appraised Value",
    type: ElphiCellType.String
  },
  {
    index: 15,
    data: "Subject-to Appraised Value",
    type: ElphiCellType.String
  },
  {
    index: 16,
    data: "Loan Number",
    type: ElphiCellType.String
  },
  {
    index: 17,
    data: "Loan Program",
    type: ElphiCellType.String
  },
  {
    index: 18,
    data: "Loan User Assignment",
    type: ElphiCellType.String,
    minWidth: "300px",
    maxWidth: "300px"
  }
];
