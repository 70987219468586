import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  BaseSchemaEntityPartyFieldSpecs,
  baseSchemaEntityPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import { mergeSpecs, omitFromSpec } from "../../../utils/formsSpec.utils";
import {
  mofinCommonPartySpecs,
  mofinPartyPathsToOmitFromBase
} from "./party.fields";

export type FullMofinSchemaEntityPartyFieldSpecs =
  Partial<BaseSchemaEntityPartyFieldSpecs>;

export const mofinEntityPartySpecs: FullMofinSchemaEntityPartyFieldSpecs =
  mergeSpecs(
    omitFromSpec({
      obj: baseSchemaEntityPartyFieldSpecs,
      paths: mofinPartyPathsToOmitFromBase
    }),
    mofinCommonPartySpecs
  );

export const fullMofinSchemaEntityPartySpecs = createSpecWithFieldMeta({
  spec: mofinEntityPartySpecs
});
