import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  Spacer,
  Tab,
  TabPanel,
  Text,
  Tooltip
} from "@chakra-ui/react";
import FormBuilder, {
  OnChangeInput,
  Section
} from "../../../../../form-builder/FormBuilder";

export const StyledTab = (props: {
  title: string;
  isDisabled: boolean;
  showDivider?: boolean;
  isValid?: boolean;
  tooltipLabel?: string;
}) => {
  return (
    <>
      <Tooltip
        isDisabled={!props.isDisabled}
        label={props.tooltipLabel}
        placement={"right-end"}
        hasArrow
      >
        <Tab
          _selected={{
            color: "white",
            borderStyle: "unset",
            bg: "blue.500"
          }}
          _disabled={{
            color: "gray.400",
            borderStyle: "dashed",
            borderColor: "gray.400"
          }}
          bg={"white"}
          borderColor={"blue.500"}
          borderRadius={6}
          borderWidth={1}
          color={"blue.500"}
          isDisabled={props.isDisabled}
        >
          {props.title}
        </Tab>
      </Tooltip>
      {props.showDivider && (
        <Center height={"24px"} my={2}>
          <Divider
            orientation="vertical"
            borderLeftWidth={2}
            borderColor={props.isValid ? "blue.500" : "gray.200"}
          />
        </Center>
      )}
    </>
  );
};

export const FormPanel = (props: {
  show: boolean;
  contentHeader: string;
  searchHeader: string;
  formKey: string;
  formSection: Section;
  formOnChange: (v: OnChangeInput) => void;
  children: React.ReactNode;
  skip?: {
    title: string;
    onCheck: (c: boolean) => void;
    checked: boolean;
    readOnly: boolean;
  };
}) => {
  return (
    <TabPanel pl={5} pr={0} py={0}>
      <Flex
        bg={"gray.50"}
        h={500}
        p={5}
        borderRadius={6}
        borderWidth={1}
        borderColor={"gray.200"}
        width={"100%"}
        direction={"column"}
        overflow={"auto"}
      >
        <Flex>
          <Box>
            <Text fontWeight={"Bold"}>{props.contentHeader}</Text>
          </Box>
          <Spacer />
          {props.skip && (
            <Flex>
              <Checkbox
                readOnly={props.skip.readOnly}
                disabled={props.skip.readOnly}
                mr={1}
                isChecked={props.skip.checked}
                onChange={(e) => {
                  props.skip?.onCheck(e.target.checked);
                }}
              />
              <Text fontSize={14}>{props.skip.title}</Text>
            </Flex>
          )}
        </Flex>
        <Box height={120} py={"20px"}>
          <Text pl={"10px"} fontWeight={"Bold"}>
            {props.searchHeader}
          </Text>
          {props.children}
        </Box>
        {props.show && (
          <FormBuilder
            elphiView={"form"}
            customKey={props.formKey}
            onChange={props.formOnChange}
            sections={[props.formSection]}
            size={{
              minW: "400px"
            }}
          />
        )}
      </Flex>
    </TabPanel>
  );
};
