import { AnyAggregationField } from "../aggregations";
import { BaseEntity } from "../db";
import { RentalQuoteFields } from "./deal.quote.rental.types";
import { PricingEngineScenario } from "./deal.quote.shared.types";
import { ShortTermQuoteFields } from "./deal.quote.short.types";

export enum QuoteType {
  ShortTerm = "ShortTerm",
  Rental = "Rental"
}
export type QuoteInfo = {
  id: string;
  program: string;
  product: string;
};

export type BaseDealQuote<
  TType extends QuoteType,
  TData extends { [key: string]: AnyAggregationField | PricingEngineScenario }
> = BaseEntity<{
  quoteType: TType;
  aggregations: TData;
}>;

export type DealQuote = {
  quoteType?: QuoteType;
  aggregations: Partial<ShortTermQuoteFields & RentalQuoteFields>;
};
// Field mapping between quote response to elphi
