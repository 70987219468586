import { Type } from "@sinclair/typebox";
import {
    InsurancePolicyEntityType,
    OutstandingPremiumStatus
} from "../../entities";
import { PropertyInsurancePolicyCoverageType } from "../../entities/insurance-policy/property/propertyInsurancePolicy.enums";
import { EnumField, StringField } from "../utils.typebox";

export const BaseInsurancePolicyModel = Type.Object({
  insuranceCarrier: Type.Optional(StringField),
  policyNumber: StringField,
  expirationDate: Type.Optional(StringField),
  nextPaymentDueDate: Type.Optional(StringField),
  policyPremiumAmount: Type.Optional(StringField),
  policyDeductibleAmount: Type.Optional(StringField),
  outstandingPremiumStatus: Type.Optional(EnumField(OutstandingPremiumStatus)),
  outstandingPremiumAmount: Type.Optional(StringField),
  insurancePremiumPaymentAmount: Type.Optional(StringField),
  entityIds: Type.Optional(Type.Array(StringField)),
  dealIds: Type.Optional(Type.Array(StringField)),
  policyProvider: Type.Optional(
    Type.Optional(
      Type.Object({
        id: StringField,
        name: StringField
      })
    )
  ),
  entityType: Type.Optional(EnumField(InsurancePolicyEntityType)),
  coverageTypes: Type.Array(
    Type.Union([EnumField(PropertyInsurancePolicyCoverageType)])
  ),
  entities: Type.Optional(Type.Object({}))
});
