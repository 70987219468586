import { AccountAsset, AccountType, AssetType } from "../asset.types";

export const getAssetTemplate = (t: AssetType | undefined) => {
  if (!t) return {} as AccountAsset;
  const account: AccountAsset = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    type: "" as AssetType,
    statements: [] as string[],
    estimatedValueAmount: "",
    estimatedValueConsideredPercentage: "",
    appraisalValueAmount: "",
    finalEstimatedValueAmount: "",
    fullNameOnAccount: "",
    accountName: "",
    accountType: "" as AccountType,
    financialInstitution: "",
    last4Digits: ""
  };

  switch (t) {
    case AssetType.Account:
      return account;
  }
};
