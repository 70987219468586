import { DefaultAggregation, ThirdPartyAggregationField } from "../../types";

export const getFocusedData = (
  aggregation?: DefaultAggregation | ThirdPartyAggregationField
) => {
  if (!aggregation) return null;
  if (aggregation.focused === "override") return aggregation.override;
  if (aggregation.focused === "calculated") return aggregation?.calculated;
  if (aggregation.focused === "thirdParty") return aggregation?.thirdParty;
  return null;
};
