import { DEFAULT_PAGE_SIZE } from "../../../../constants/common";
import { usePaginationHooksV2 } from "../../../../redux/v2/hooks/pagination.hooks.v2";
import { propertyApi } from "../../../../redux/v2/property";
import { usePropertyTableState } from "./hooks";
import { getCurrentCursorType } from "./propertyItem.hooks";
import { PropertyTableState } from "./PropertyRow";
import { PropertyTable, PropertyTableContainerProps } from "./PropertyTable";

export const PropertyTableLiveStateContainer = (
  props: Omit<PropertyTableContainerProps, "snapshotId">
) => {
  const pageSize = props.pageSize || DEFAULT_PAGE_SIZE;
  const { propertyId, userId, h } = props;
  const propertyTableState = usePropertyTableState();
  const {
    propertyState,
    dealPropertyState,
    dealUserState,
    setPropertyTableCursor,
    getPropertyTableCursor,
    dealState
  } = propertyTableState;
  const propertyPagination = usePaginationHooksV2({
    input: {
      propertyId,
      userId
    },
    limit: pageSize,
    paginationProvider: propertyApi.useLazyPaginateV2Query,
    setCursor: (cursor) => {
      const currentCursorType = getCurrentCursorType({
        userId: props.userId,
        propertyId: props.propertyId
      });
      setPropertyTableCursor({
        cursor,
        cursorType: currentCursorType,
        propertyId: props.propertyId,
        userId: props.userId
      });
    },
    currentCursor: getPropertyTableCursor({
      cursorType: getCurrentCursorType({
        userId: props.userId,
        propertyId: props.propertyId
      }),
      propertyId: props.propertyId,
      userId: props.userId
    })
  });
  const isLoading =
    propertyPagination.paginateApiResponse.isFetching ||
    propertyPagination.paginateApiResponse.isLoading;

  const dealId = props.dealId;
  const state: PropertyTableState = {
    propertyState,
    dealPropertyState,
    dealUserState,
    dealState
  };
  return (
    <PropertyTable
      h={h}
      state={state}
      pageSize={pageSize}
      isLoading={isLoading}
      userId={props.userId}
      dealId={dealId}
      propertyId={propertyId}
      isActionable={true}
      isDisabled={props.isDisabled}
    />
  );
};
