import { Transitions } from "../type-helpers/transitions.types";

export interface Milestones {
  ClearToClose: Milestone;
  Closed: Milestone;
  DeadDeal: Milestone;
  DealApproved: Milestone;
  DealFeasibilityReview: Milestone;
  DealProcessing: Milestone;
  FinalEstimateIssued: Milestone;
  Funded: Milestone;
  SubmittedToOperations: Milestone;
  UnderwritingApprovalRequested: Milestone;
}

export enum MilestoneColumnNames {
  EntryState = "Entry State",
  ExitState = "Exit State",
  LOSMilestoneLabel = "LOS Milestone Label",
  MilestoneName = "Milestone Name",
  Override = "Override",
  POSStatus = "POS Status",
  POSStep = "POS Step",
  Transitions = "Transitions"
}
export type Milestone = {
  id: string;
  [MilestoneColumnNames.POSStatus]: string;
  [MilestoneColumnNames.MilestoneName]: string;
  [MilestoneColumnNames.POSStep]: string;
  [MilestoneColumnNames.LOSMilestoneLabel]: string;
  [MilestoneColumnNames.ExitState]: boolean;
  [MilestoneColumnNames.Transitions]: Transitions;
  [MilestoneColumnNames.Override]: string[];
  [MilestoneColumnNames.EntryState]: boolean;
};
