import { FeatureFlag } from "@elphi/utils";
import { ReactNode } from "react";
import { useStatsigGate } from "../../hooks/statsig.hooks";

export const GateKeeper = (props: {
  children: ReactNode;
  gate: FeatureFlag;
}) => {
  const { value } = useStatsigGate(props.gate);
  return <>{value ? props.children : <></>}</>;
};
