import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TableManagerSliceState = {
  [key: string]: {
    columnsOrder: { [columnId: number]: { columnId: number; index: number } };
    defaultOrder: { [columnId: number]: { columnId: number; index: number } };
    maxIndex: number;
    minIndex: number;
    reorderMode: boolean;
  };
};

export const tableManagerSlice = createSlice({
  name: "tableManager",
  initialState: {} as TableManagerSliceState,
  reducers: {
    updateColumnsOrder: (
      state,
      action: PayloadAction<{
        tableName: string;
        orderArray: { columnId: number; newIndex: number }[];
      }>
    ) => {
      const { orderArray, tableName } = action?.payload || {};
      const { columnsOrder } = state[tableName];

      if (columnsOrder && orderArray) {
        orderArray.sort(
          (a, b) =>
            columnsOrder[a.columnId].index - columnsOrder[b.columnId].index
        );
        orderArray.forEach(({ columnId, newIndex }) => {
          const column = columnsOrder[columnId];
          const oldIndex = column.index;
          column.index = newIndex;

          Object.values(columnsOrder).forEach((c) => {
            if (c.columnId !== column.columnId) {
              if (
                oldIndex < newIndex &&
                c.index > oldIndex &&
                c.index <= newIndex
              ) {
                c.index--;
              } else if (
                oldIndex > newIndex &&
                c.index >= newIndex &&
                c.index < oldIndex
              ) {
                c.index++;
              }
            }
          });
        });
      }
    },
    setTableColumns: (
      state,
      action: PayloadAction<{
        tableName: string;
        defaultOrder: {
          [columnId: number]: { columnId: number; index: number };
        };
      }>
    ) => {
      const { defaultOrder, tableName } = action?.payload || {};
      const keys: number[] = Object.keys(defaultOrder).map((key) =>
        Number(key)
      );
      const maxIndex = Math.max(...keys);
      const minIndex = Math.min(...keys);
      state[tableName] = {
        maxIndex,
        minIndex,
        columnsOrder: defaultOrder,
        defaultOrder: defaultOrder,
        reorderMode: false
      };
    },
    toggleReorderMode: (
      state,
      action: PayloadAction<{
        tableName: string;
      }>
    ) => {
      const { tableName } = action?.payload || {};
      const tableState = state[tableName];
      if (tableState) tableState.reorderMode = !tableState.reorderMode;
    }
  }
});
