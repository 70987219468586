import {
  Box,
  Button,
  Fade,
  Flex,
  Grid,
  GridItem,
  IconButton,
  useDisclosure,
  useOutsideClick
} from "@chakra-ui/react";
import { useRef } from "react";
import { ReactionIcon } from "../../components/icons";
import { emojiDataSet } from "./emojiDataSet";
import { EmojiData, ReactionPickerProps } from "./reaction.types";

export const ReactionPicker = (props: ReactionPickerProps) => {
  const { onSelect } = props;
  const { isOpen, onClose, onToggle } = useDisclosure();
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref,
    handler: () => {
      if (isOpen) {
        onClose();
      }
    }
  });

  const handleOnSelect = (e: EmojiData) => {
    onSelect(e);
    onClose();
  };

  return (
    <Flex ref={ref} alignItems={"center"} position={"relative"}>
      <IconButton
        aria-label="reaction picker"
        variant={"unstyled"}
        onClick={onToggle}
        icon={
          <ReactionIcon
            w={6}
            h={6}
            _hover={{
              _active: { transform: "scale(0.95)" }
            }}
          />
        }
      />
      <Fade in={isOpen}>
        <Box
          bg={"white"}
          border={"1px solid"}
          borderColor={"gray.400"}
          borderRadius={"md"}
          boxShadow={"md"}
          padding={1}
          pointerEvents={isOpen ? "auto" : "none"}
          position={"absolute"}
          left={"40px"}
          top={"-30px"}
          zIndex={10}
        >
          <Grid templateColumns={"repeat(6, 1fr)"} gap={2}>
            {emojiDataSet.map((e, i) => (
              <GridItem key={i}>
                <Button
                  bgColor={"transparent"}
                  fontSize={"large"}
                  variant={"unstyled"}
                  onClick={() => handleOnSelect(e)}
                  _active={{ transform: "scale(0.90)" }}
                  _hover={{
                    bgColor: "gray.100"
                  }}
                >
                  <Box>{e.native}</Box>
                </Button>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Fade>
    </Flex>
  );
};
