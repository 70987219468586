import { createIcon } from "@chakra-ui/react";

const customDocumentIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 28 28",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.1667 24.5H5.83333V3.5H15.1667H16.3333L22.1667 9.33333V10.5V24.5Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1667 3.5V8.16667C15.1667 9.33333 16.3333 10.5 17.5 10.5C18.6667 10.5 21.1945 10.5 22.1667 10.5"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33333 19.8333H18.6667"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33333 15.1667H16.3333"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const DocumentIcon = customDocumentIcon();
