import { TaskRuleTemplate } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import lodash from "lodash";
import sliceBuilder from "../builders/slice.builder";
import {
  taskRuleApi,
  taskRuleEntityAdapter as entityAdapter
} from "./taskRule.service";

export type TaskRuleSliceState = EntityState<TaskRuleTemplate> & {
  selectedId?: EntityId;
};
export const taskRuleSlice = createSlice({
  name: "taskRule",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as TaskRuleSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(taskRuleApi)(builder);
    builder.addMatcher(
      taskRuleApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      taskRuleApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
  }
});
