import { Party } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { partySlice } from "./party.slice";

export const partyAction: ActionDispatcher<Party> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: Party[]) => void;
    update?: (r: Update<Party>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = partySlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

export default partyAction;
