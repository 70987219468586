import { TaskType } from "@elphi/types";
import {
  EMPTY_ORDER_STATUS_TEXT,
  NOT_RELEVANT_ORDER_STATUS_TEXT
} from "../taskPrint.utils";
import { OrderStatusResolverConfig } from "./types";

const integrationOrderStatusConfig: OrderStatusResolverConfig = {
  fallbackLabel: EMPTY_ORDER_STATUS_TEXT
};

const documentConfig: OrderStatusResolverConfig = {
  fallbackLabel: NOT_RELEVANT_ORDER_STATUS_TEXT,
  ignoreTemplateOrderStatus: true
};

const documentOrderStatusConfig: OrderStatusResolverConfig = {
  fallbackLabel: EMPTY_ORDER_STATUS_TEXT
};

export const orderStatusResolverConfigTypeMap = (taskType: TaskType) => {
  const configMap: {
    [t in TaskType]: OrderStatusResolverConfig;
  } = {
    [TaskType.Integration]: integrationOrderStatusConfig,
    [TaskType.Document]: documentConfig,
    [TaskType.DocumentOrderStatus]: documentOrderStatusConfig
  };

  return configMap[taskType];
};
