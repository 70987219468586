import { Dispatch, EntityId, PayloadAction, Update } from "@reduxjs/toolkit";

export enum ActionType {
  Add = "add",
  Remove = "remove",
  Update = "update"
}
export type AddAction<T> = PayloadAction<T, ActionType.Add>;
export type RemoveAction = PayloadAction<{ id: string }, ActionType.Remove>;
export type UpdateAction<T> = PayloadAction<T, ActionType.Update>;
export type ActionActions<T> = AddAction<T> | RemoveAction | UpdateAction<T>;

export enum ActionResponseType {
  Add = "add",
  Update = "update",
  Remove = "remove"
}
export type ActionBaseResponse<TType extends ActionResponseType, TData> = {
  type: TType;
  payload: TData;
};
export type ActionAddResponse = ActionBaseResponse<
  ActionResponseType.Add,
  { id: string }[]
>;
export type ActionModifiedResponse = ActionBaseResponse<
  ActionResponseType.Update,
  { id: EntityId }[]
>;
export type ActionRemoveResponse = ActionBaseResponse<
  ActionResponseType.Remove,
  readonly EntityId[]
>;

export type CallBackActions<T> = {
  add?: (r: T[]) => void;
  update?: (r: Update<T>[] | T[]) => void;
  remove?: (r: EntityId[]) => void;
};

export type ActionDispatcher<T extends { id: EntityId }> = (
  dispatch: Dispatch,
  callback?: CallBackActions<T>
) => ActionHandler<T>;
export interface ActionHandler<T extends { id: EntityId }> {
  add: (r: T[]) => Promise<ActionAddResponse>;
  update: (r: Update<T>[] | T[]) => Promise<ActionModifiedResponse>;
  remove: (r: EntityId[]) => Promise<ActionRemoveResponse>;
}
