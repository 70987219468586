import {
  Deal,
  FloatRequestEvent,
  LoampassMappingInResponse,
  LoampassMappingOutResponse,
  LoanpassConfigurationsResponse,
  LoanpassEventObject,
  LoanpassFieldValue,
  LoanpassMessageType,
  LoanpassToElphiMappingsRequest,
  MappingDirection,
  MappingRequest,
  MappingResponse,
  StatusCode
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { omit } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../../../../apis/rtk/response.handler";
import { RTKResponse } from "../../../../apis/rtk/response.types";
import useDealHooks from "../../../../hooks/deal.hooks";
import { usePartyHooks } from "../../../../hooks/party.hooks";
import { usePropertyHooks } from "../../../../hooks/property.hooks";
import { RootState } from "../../../../redux/store";
import { loanpassApi } from "../../../../redux/v2/integration/loanpass/loanpass.service";
import {
  DEFAULT_PIPELINE_RECORD,
  loanpassSlice
} from "../../../../redux/v2/integration/loanpass/loanpass.slice";
import {
  WorkerMessage,
  WorkerMessagePayload
} from "../../../../workers/loanpass.worker";
import { useElphiToast } from "../../../toast/toast.hook";
import { LoanpassMessage } from "./loanpass.types";

const LoanpassFloaRequestFieldToOmit: DotNestedKeys<
  Partial<FloatRequestEvent>
>[] = ["productExecutionResult"];

export const useLoanpassHooks = () => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useElphiToast();
  const { selectedDeal, updateDeal, updateDealApiResponse } = useDealHooks();
  const { selectedParty } = usePartyHooks();
  const { selectedProperty } = usePropertyHooks();

  const [loanpassConfigs, setLoanpassConfigs] = useState<
    LoanpassConfigurationsResponse | undefined
  >();

  const [getConfigurationsApi, getConfigurationsResponse] =
    loanpassApi.useLazyGetConfigurationsQuery();

  const [getPipelineRecordsApi, getPipelineRecordsResponse] =
    loanpassApi.useLazyGetPipelineRecordsQuery();

  const [searchPipelineApi, searchPipelineApiResponse] =
    loanpassApi.useLazySearchQuery();

  const [mapFieldsApi, mapFieldsApiResponse] =
    loanpassApi.useLazyMapFieldsQuery();

  const pipelineRecords = useSelector(
    (state: RootState) => state.loanpass.pipelineRecords
  );
  const isDefaultPipelineRecordAdded = useSelector(
    (state: RootState) => state.loanpass.isDefaultPipelineRecordAdded
  );
  const selectedPipelineRecord = useSelector(
    (state: RootState) =>
      state.loanpass.selectedPipelineRecordId &&
      state.loanpass.pipelineRecords.find(
        (x) => x.id === state.loanpass.selectedPipelineRecordId
      )
  );
  const inMapping = useSelector(
    (state: RootState) => state.loanpass.loampassToElphiMapping
  );

  const removeMapping = () =>
    dispatch(loanpassSlice.actions.removeMappedDeal());

  const updateDealWithMapping = async () => {
    if (!selectedDeal?.id) {
      errorToast({
        title: "Error",
        description: "No deal selected"
      });
      return;
    }

    const mappedDeal = inMapping?.deal as Partial<Deal>;
    if (inMapping?.deal) {
      await updateDeal({
        ...mappedDeal,
        id: selectedDeal.id
      });
      successToast({
        title: "Deal Updated",
        description: `Deal: ${selectedDeal.id}`
      });
      removeMapping();
      return;
    }

    errorToast({
      title: "Error",
      description: "Incoming deal data is missing"
    });
  };

  const selectPipelineRecord = (id: string) => {
    if (id === DEFAULT_PIPELINE_RECORD.id && !isDefaultPipelineRecordAdded) {
      dispatch(loanpassSlice.actions.addDefaultPipelineRecord());
    }

    dispatch(loanpassSlice.actions.selectPipelineRecod({ id }));
  };

  const getLoanpassConfigs = async () => {
    await getConfigurationsApi(undefined, true)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.OK) {
          setLoanpassConfigs(r.data);
        } else {
          errorToast({
            title: "Failed to get Loanpass configurations",
            description: r.data.description
          });
        }
      });
  };

  const getLoanpassPipelineRecoreds = async () => {
    await getPipelineRecordsApi(undefined, true)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status !== StatusCode.OK) {
          errorToast({
            title: "Failed to get Loanpass pipeline records",
            description: r.data.description
          });
        }
      });
  };

  const requestMapping = async <TResponse extends MappingResponse>(
    r: MappingRequest
  ) => {
    return await mapFieldsApi(r)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status !== StatusCode.OK) {
          errorToast({
            title: "Failed to map fields",
            description: r.data.description
          });
          return undefined;
        }

        return r.data as TResponse;
      });
  };

  const mapElphiToLoanpass = async () => {
    if (!selectedDeal) {
      errorToast({
        title: "Failed to map fields",
        description: "No deal selected"
      });
      return;
    }

    const payload: MappingRequest = {
      direction: MappingDirection.Out,
      deal: selectedDeal,
      property: selectedProperty ? selectedProperty : undefined,
      party: selectedParty ? selectedParty : undefined
    };
    const loanpassObjectResponse =
      await requestMapping<LoampassMappingOutResponse>(payload);
    return loanpassObjectResponse?.payload;
  };

  const mapLoanpassToElphi = async (r: {
    requestType: LoanpassEventObject["message"];
    loanpassObject: Partial<LoanpassEventObject>;
  }) => {
    const payload: LoanpassToElphiMappingsRequest = {
      direction: MappingDirection.In,
      requestType: r.requestType,
      loanpassObject: r.loanpassObject
    };
    const mappedDeal = await requestMapping<LoampassMappingInResponse>(payload);
    return mappedDeal?.deal;
  };

  return {
    loanpassConfigs,
    getLoanpassConfigs,
    getConfigurationsResponse,
    getLoanpassPipelineRecoreds,
    getPipelineRecordsResponse,
    searchPipelineApi,
    searchPipelineApiResponse,
    pipelineRecords,
    selectedPipelineRecord: selectedPipelineRecord
      ? selectedPipelineRecord
      : undefined,
    selectPipelineRecord,
    mapElphiToLoanpass,
    mapLoanpassToElphi,
    mapFieldsApiResponse,
    inMapping,
    removeMapping,
    updateDealWithMapping,
    updateDealWithMappingResponse: updateDealApiResponse
  };
};

export const useLoanpassWorkerHooks = () => {
  const [isListening, setIsListening] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const selectedPipelineRecordId = useRef<string | undefined>(undefined);
  const { errorToast } = useElphiToast();
  const { selectedPipelineRecord, mapLoanpassToElphi } = useLoanpassHooks();

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const workerRef = useRef<Worker | null>(null);
  const loginDetails = useRef<LoanpassConfigurationsResponse | undefined>(
    undefined
  );

  useEffect(() => {
    const worker = new Worker(
      new URL("../../../../workers/loanpass.worker", import.meta.url)
    );
    workerRef.current = worker;

    worker.onmessage = (event) => {
      const { type, payload } = event.data;

      if (type === WorkerMessage.IframeMessageAck) {
        manageMessages(payload);
      }
    };

    return () => {
      worker.terminate();
      workerRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (selectedPipelineRecord) {
      selectedPipelineRecordId.current = selectedPipelineRecord.id;
    }
  }, [selectedPipelineRecord]);

  useEffect(() => {
    const contentWindow = iframeRef?.current?.contentWindow;
    const canLogin =
      loginDetails && isListening && !isLoggedIn && contentWindow;
    if (canLogin) {
      setIsListening(false);
      const { clientAccessId, email, origin, password } =
        loginDetails?.current || {};
      contentWindow.postMessage(
        {
          message: LoanpassMessageType.LogIn,
          clientAccessId,
          emailAddress: email,
          password
        },
        origin || ""
      );
    }
  }, [loginDetails, isListening, isLoggedIn, iframeRef?.current]);

  const manageMessages = async (payload: WorkerMessagePayload) => {
    const { iframeData } = payload;
    switch (iframeData?.message.data.messageType) {
      case LoanpassMessageType.Listening:
        if (!isListening) {
          setIsListening(true);
        }
        break;
      case LoanpassMessageType.LogIn:
        const { clientAccessId, email, origin, password } =
          iframeData?.message.data;
        loginDetails.current = { clientAccessId, email, origin, password };
        break;
      case LoanpassMessageType.LoggedIn:
        setIsLoggedIn(true);
        break;
      case LoanpassMessageType.LogOut:
        setIsLoggedIn(false);
        break;
      case LoanpassMessageType.SetFields:
        sendElphiData({
          messageType: LoanpassMessageType.SetFields,
          messagePayload: {
            fields: iframeData?.message.data.loanpassPayload
          }
        });
        break;
      case LoanpassMessageType.SetPipelineRecord:
        sendElphiData({
          messageType: LoanpassMessageType.SetPipelineRecord,
          messagePayload: {
            pipelineRecordId: selectedPipelineRecordId.current,
            overrideCreditApplicationFields:
              iframeData?.message.data.loanpassPayload
          }
        });
        break;
      case LoanpassMessageType.FloatRequest:
        await getMappedDealFromLoanpass(
          iframeData?.message.data,
          LoanpassMessageType.FloatRequest
        );
        break;
      case LoanpassMessageType.LockLedgerUpdated:
        await getMappedDealFromLoanpass(
          iframeData?.message.data,
          LoanpassMessageType.LockLedgerUpdated
        );
        break;
      case LoanpassMessageType.LogInError:
      case LoanpassMessageType.LockLedgerUpdateError:
        errorToast({
          title: "Error",
          description: iframeData?.message.data.error
        });
        break;
      default:
        console.warn("[WORKER] Unhandled message", iframeData?.message.data);
    }
  };

  const setIframeRef = (iframe: HTMLIFrameElement) => {
    iframeRef.current = iframe;
  };

  const sendElphiData = (r: {
    messageType: LoanpassMessageType;
    messagePayload: any;
  }) => {
    const { messageType, messagePayload } = r;
    const loanpassOrigin = loginDetails?.current?.origin;
    if (!loanpassOrigin) {
      errorToast({
        title: "Error",
        description: "Origin is missing"
      });
      return;
    }

    iframeRef?.current?.contentWindow?.postMessage(
      { message: messageType, ...messagePayload },
      loanpassOrigin
    );
  };

  const passMessageToWorker = (payload: LoanpassMessage) => {
    workerRef.current?.postMessage({
      type: WorkerMessage.ProcessIframeMessage,
      payload: payload
    });
  };

  const sendDataFromElphiToLoanpass = (
    loanpassPayload: LoanpassFieldValue[]
  ) => {
    if (selectedPipelineRecord?.id === DEFAULT_PIPELINE_RECORD.id) {
      passMessageToWorker({
        messageType: LoanpassMessageType.SetFields,
        loanpassPayload
      });
    } else {
      passMessageToWorker({
        messageType: LoanpassMessageType.SetPipelineRecord,
        loanpassPayload
      });
    }
  };
  const supportedMappingMessages = [
    LoanpassMessageType.FloatRequest,
    LoanpassMessageType.LockLedgerUpdated
  ];

  const processLoanpassObject = (
    loanpassObject: LoanpassEventObject,
    messageType: LoanpassMessageType
  ) => {
    if (messageType === LoanpassMessageType.FloatRequest) {
      return omit(loanpassObject, LoanpassFloaRequestFieldToOmit);
    }

    return loanpassObject;
  };

  const getMappedDealFromLoanpass = async (
    loanpassObject: LoanpassEventObject,
    messageType: LoanpassMessageType
  ) => {
    const processedLoanpassObject = processLoanpassObject(
      loanpassObject,
      messageType
    );
    const requestType = supportedMappingMessages.find(
      (x) => x === messageType
    ) as LoanpassEventObject["message"];
    if (!requestType) {
      errorToast({
        title: "Error",
        description: "Invalid message type"
      });
      return;
    }
    return await mapLoanpassToElphi({
      requestType,
      loanpassObject: processedLoanpassObject
    });
  };

  return {
    passMessageToWorker,
    setIframeRef,
    isListening,
    isLoggedIn,
    sendDataFromElphiToLoanpass,
    iframeRef
  };
};
