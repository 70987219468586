export enum NavigationPages {
  Application = "Application",
  Parties = "Parties",
  Properties = "Properties",
  Checklist = "Checklist",
  ServiceProvider = "ServiceProvider"
}

export enum NavigationTabs {
  Deal = "Deal",
  DealUnderwriting = "DealUnderwriting",
  Parties = "Parties",
  Propersties = "Propersties",
  ConstructionManagement = "ConstructionManagement",
  ThirdParties = "ThirdParties",
  Closing = "Closing",
  PostClosing = "PostClosing",
  PartyStructure = "PartyStructure",
  PartiesProfile = "PartiesProfile"
}
export enum NavigationItemType {
  Page = "page",
  Tab = "tab"
}

export type BaseNavigationItem<TType extends NavigationItemType> = {
  type: TType;
};

export type NavigationPageItem = BaseNavigationItem<NavigationItemType.Page> & {
  value: NavigationPages;
};
export type NavigationTabItem = BaseNavigationItem<NavigationItemType.Tab> & {
  value: NavigationTabs;
};

export type NavigationItem = NavigationPageItem | NavigationTabItem;

export type NavigationPath = NavigationItem[];

export type NavigationItemDictionary = {
  [key in NavigationItemType]: string;
};
