import { RolodexServiceProvider, ServiceProviderType } from "@elphi/types";
import {
  EntityId,
  EntityState,
  PayloadAction,
  createSlice
} from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../../builders/slice.builder";
import { SearchCursorState } from "../../utils/search.utils";
import { serviceProviderAdapter as entityAdapter } from "./serviceProvider.adapter";
import { serviceProviderApi } from "./serviceProvider.service";

export type ServiceProviderSliceState = EntityState<RolodexServiceProvider> & {
  selectedId?: EntityId;
  searchCursor: SearchCursorState;
  preprocessed: {
    serviceProviderIds: string[];
    relationIds: string[];
  };
};

const initialState: ServiceProviderSliceState = {
  ids: [],
  entities: {},
  selectedId: undefined,
  searchCursor: {
    query: {}
  },
  preprocessed: {
    serviceProviderIds: [],
    relationIds: []
  }
};

export const serviceProviderSlice = createSlice({
  name: "serviceProvider",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    resetPreprocessed: (state) => {
      state.preprocessed = initialState.preprocessed;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(serviceProviderApi)(builder);
    builder.addMatcher(
      serviceProviderApi.endpoints.searchCompany.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
        const cursorKey = buildCompanyCursorKey({ ...payload });
        state.searchCursor.query[cursorKey] = {
          hasMore: payload.hasMore,
          nextCursor: payload.nextCursor ? payload.nextCursor : null
        };
      }
    ),
      builder.addMatcher(
        serviceProviderApi.endpoints.searchBranch.matchFulfilled,
        (state, { payload }) => {
          state.ids = union(state.ids, payload.results.ids);
          state.entities = { ...state.entities, ...payload.results.entities };
          const cursorKey = buildBranchCursorKey({ ...payload });
          state.searchCursor.query[cursorKey] = {
            hasMore: payload.hasMore,
            nextCursor: payload.nextCursor ? payload.nextCursor : null
          };
        }
      ),
      builder.addMatcher(
        serviceProviderApi.endpoints.searchRep.matchFulfilled,
        (state, { payload }) => {
          state.ids = union(state.ids, payload.results.ids);
          state.entities = { ...state.entities, ...payload.results.entities };
          const cursorKey = buildRepCursorKey({ ...payload });
          state.searchCursor.query[cursorKey] = {
            hasMore: payload.hasMore,
            nextCursor: payload.nextCursor ? payload.nextCursor : null
          };
        }
      );
    builder.addMatcher(
      serviceProviderApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
    builder.addMatcher(
      serviceProviderApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      serviceProviderApi.endpoints.getTree.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.serviceProviders.ids);
        state.entities = {
          ...state.entities,
          ...payload.serviceProviders.entities
        };
      }
    );
    builder.addMatcher(
      serviceProviderApi.endpoints.cascadeDelete.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.serviceProviders.ids);
        state.entities = {
          ...state.entities,
          ...payload.serviceProviders.entities
        };
        state.preprocessed.serviceProviderIds =
          payload.serviceProviders.ids.map((x) => x.toString());
        state.preprocessed.relationIds = payload.relations.ids.map((x) =>
          x.toString()
        );
      }
    );
    builder.addMatcher(
      serviceProviderApi.endpoints.upsert.matchFulfilled,
      (state, { payload }) => {
        state.selectedId = payload.companyId;
      }
    );
  }
});

export const buildCompanyCursorKey = (r: {
  query: string;
  domainConfigurationId: string;
}) => `${ServiceProviderType.Company}_${r.query}_${r.domainConfigurationId}`;

export const buildBranchCursorKey = (r: { query: string; companyId: string }) =>
  `${ServiceProviderType.Branch}_${r.query}_${r.companyId}`;

export const buildRepCursorKey = (r: { query: string; companyId: string }) =>
  `${ServiceProviderType.Representative}_${r.query}_${r.companyId}`;
