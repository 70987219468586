import { Box, Button, Flex, Tooltip } from "@chakra-ui/react";
import { emojiDataMap } from "./emojiDataSet";
import { EmojiData, ReactionCounter } from "./reaction.types";

export const ReactionCounterBar = (props: {
  reactions: ReactionCounter[];
  onToggle: (e: EmojiData) => void;
}) => {
  const { reactions, onToggle } = props;
  return (
    <Flex alignItems={"center"}>
      {reactions.map(({ id, count, users, isSelf }, key) => {
        const emoji = emojiDataMap.get(id);
        if (!emoji) {
          return <></>;
        }
        return (
          <Tooltip
            key={key}
            label={<ReactionPhrase users={users} reactionName={emoji.name} />}
            hasArrow
          >
            <Button
              key={emoji.id}
              onClick={() => onToggle(emoji)}
              borderRightRadius={"full"}
              borderLeftRadius={"full"}
              border={"1px solid"}
              bgColor={isSelf ? "blue.50" : "gray.50"}
              borderColor={isSelf ? "blue.400" : "gray.400"}
              height={"unset"}
              minWidth={"unset"}
              mx={1}
              px={2}
              variant={"unstyled"}
              _active={{ transform: "scale(0.90)" }}
            >
              <Flex gap={1} justifyContent={"center"}>
                <Box fontSize={"12px"}>{emoji.native}</Box>
                <Box fontSize={"12px"} fontWeight={"bold"}>
                  {count}
                </Box>
              </Flex>
            </Button>
          </Tooltip>
        );
      })}
    </Flex>
  );
};

const ReactionPhrase = (props: { users: string[]; reactionName: string }) => {
  const { users, reactionName } = props;
  if (users.length === 0) return <></>;

  if (users.length === 1)
    return (
      <>
        {users[0]} reacted with {reactionName}
      </>
    );

  if (users.length === 2)
    return (
      <>
        {users[0]} and {users[1]}
        <br />
        reacted with {reactionName}
      </>
    );

  const lastUser = users.pop();
  return (
    <>
      {users.join(", ")}, and {lastUser}
      <br />
      reacted with {reactionName}
    </>
  );
};
