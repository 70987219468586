import { AggregationField } from "../aggregations";
import { BaseEntity } from "../db";

export enum AssetType {
  Account = "Account"
}

export enum AccountType {
  Checking = "Checking",
  Savings = "Savings",
  MoneyMarket = "Money Market",
  Stocks = "Stocks",
  Bonds = "Bonds",
  MutualFund = "Mutual Fund",
  Annuity = "Annuity",
  IRA = "IRA",
  N401K = "401(K)",
  TrustAccount = "Trust Account",
  CashOutProceeds = "Cash-Out Proceeds",
  EarnestMoneyDeposit = "Earnest Money Deposit",
  Other = "Other"
}

export type AssetAggregation = {
  finalEstimationAssetValue?: AggregationField;
};

export type BaseAsset<T extends AssetType> = BaseEntity<{
  type: T;
  statements: string[];
  estimatedValueAmount: string;
  estimatedValueConsideredPercentage: string;
  appraisalValueAmount: string;
  finalEstimatedValueAmount: string;
}> &
  AssetAggregation;
export type AccountAsset = BaseAsset<AssetType.Account> & {
  type: AssetType.Account;
  fullNameOnAccount: string;
  accountName: string;
  accountType: AccountType;
  financialInstitution: string;
  last4Digits: string;
};

export type Asset = AccountAsset;
