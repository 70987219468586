import { Box, Text } from "@chakra-ui/react";
import { RolodexConfiguration } from "@elphi/types";
import { EMPTY } from "../../../../../constants/common";
import { useRolodexConfiguration } from "../../../../../hooks/rolodexConfiguration.hooks";
import { ConfigurationSearch } from "../../../configuration/tabs/service-domain-configuration/search/ConfigurationSearch";

type DomainStepContentProps = {
  filter?: (x: RolodexConfiguration) => boolean;
  onSelect?: (id: string) => void;
};
export const DomainStepContent = (props: DomainStepContentProps) => {
  const { setSelectedConfiguration, selectedConfiguration } =
    useRolodexConfiguration();

  const handleSelect = (selectedOptionId: string) => {
    setSelectedConfiguration(selectedOptionId);
    props.onSelect?.(selectedOptionId);
  };

  return (
    <Box>
      <Text pl={"3px"} pb={"3px"} fontWeight={"semibold"}>
        Select Service Domain
      </Text>
      <ConfigurationSearch
        currentValue={selectedConfiguration ? selectedConfiguration.id : EMPTY}
        onSelect={handleSelect}
        filter={props.filter}
      />
    </Box>
  );
};
