import { Party } from "@elphi/types";
import { ButtonIconAdd } from "../../button-icon/ButtonIcon";
import { NodeAction } from "../../tree/types/Tree.types";
import { AddPartyChildModal } from "./CreateDealParty";

type AddDealPartyButtonProps = {
  dealId: string;
};

const AddDealPartyButton = (props: AddDealPartyButtonProps) => {
  const PartyModal = (
    <AddPartyChildModal dealId={props.dealId} buttonFn={ButtonIconAdd} />
  );

  return {
    component: PartyModal,
    tooltip: "Add a New Entity to the Deal"
  } as NodeAction<Party>;
};

export default AddDealPartyButton;
