import { Property, PropertyInsurancePolicy } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { orderBy } from "lodash";

export const entitiesPath: DotNestedKeys<PropertyInsurancePolicy> = "entities";

export const orderPropertiesByDealId = (r: {
  properties: Property[];
  dealId: string;
}) => {
  const { properties, dealId } = r;
  return orderBy(
    properties,
    [(property) => property.dealRelation?.at(0) === dealId],
    ["desc"]
  );
};
