import {
  BranchServiceProvider,
  CompanyServiceProvider,
  RepresentativeServiceProvider,
  RolodexServiceProvider,
  ServiceProviderType,
  StatusCode
} from "@elphi/types";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { EMPTY } from "../../../../constants/common";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import ModalContainer from "../../../modal-container/ModalContainer";
import { addProviderOption } from "../utils/serviceProvider.utils";
import { ProvidersFormStepContent } from "../wizard/steps/provider-step-content/ProvidersFormStepContent";
import {
  getBranchTemplate,
  getCompanyTemplate,
  getRepTemplate
} from "../wizard/steps/provider-step-content/providersFormStep.utils";
import { ServiceProviderDataModal } from "./serviceProviderModal.utils";

const useServiceProviderForm = <T extends RolodexServiceProvider>() => {
  const { serviceProviderState, getByIdHandler } = useServiceProviderHooks();
  const [selectedProviderId, setSelectedProviderId] = useState<string>(EMPTY);
  const { onChange, state, setState } = useFormBuilderStateHandler<Partial<T>>({
    initialState: {}
  });

  useEffect(() => {
    if (selectedProviderId) {
      const provider = serviceProviderState.entities[selectedProviderId] as
        | T
        | undefined;
      if (
        !provider &&
        addProviderOption.company.value !== selectedProviderId &&
        addProviderOption.branch.value !== selectedProviderId &&
        addProviderOption.representative.value !== selectedProviderId
      ) {
        getByIdHandler({ id: selectedProviderId });
      }
      if (provider) {
        setState(cloneDeep(provider));
      }
    }
  }, [selectedProviderId, serviceProviderState.entities[selectedProviderId]]);

  return {
    onChange,
    state,
    setState,
    selectedProviderId,
    setSelectedProviderId
  };
};

export type FormModalProps = {
  isShow: boolean;
  onClose: () => void;
  data?: ServiceProviderDataModal & { type: ServiceProviderType };
};

export const ServiceProviderFormModal = (props: FormModalProps) => {
  const { isShow, onClose, data } = props;
  const { selectedConfiguration, setSelectedConfiguration } =
    useRolodexConfiguration();
  const { updateHandler, upsertResponse } = useServiceProviderHooks();
  const [isProviderFormValid, setIsProviderFormValid] =
    useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<number>();

  const {
    onChange: onChangeCompanyState,
    state: companyState,
    setState: setCompanyState,
    selectedProviderId: selectedCompanyId,
    setSelectedProviderId: setSelectedCompanyId
  } = useServiceProviderForm<CompanyServiceProvider>();

  const {
    onChange: onChangeBranchState,
    state: branchState,
    setState: setBranchState,
    selectedProviderId: selectedBranchId,
    setSelectedProviderId: setSelectedBranchId
  } = useServiceProviderForm<BranchServiceProvider>();

  const {
    onChange: onChangeRepState,
    state: repState,
    setState: setRepState,
    selectedProviderId: selectedRepId,
    setSelectedProviderId: setSelectedRepId
  } = useServiceProviderForm<RepresentativeServiceProvider>();

  useEffect(() => {
    initProvidersStepState();
  }, []);

  useEffect(() => {
    if (isShow) {
      setSelectedConfiguration(data?.domainConfigurationId || EMPTY);
      setSelectedCompanyId(data?.companyId || EMPTY);
      setSelectedBranchId(data?.branchId || EMPTY);
      setSelectedRepId(data?.representativeId || EMPTY);
      initSelectedTab(data?.type || ServiceProviderType.Company);
    }
  }, [isShow, data]);

  const initProvidersStepState = () => {
    setCompanyState({ type: ServiceProviderType.Company });
    setBranchState({ type: ServiceProviderType.Branch });
    setRepState({ type: ServiceProviderType.Representative });
  };

  const handleProviderFormValidation = (isValid: boolean) => {
    setIsProviderFormValid(isValid);
  };

  const initSelectedTab = (type: ServiceProviderType) =>
    type === ServiceProviderType.Branch
      ? handleTabChange(2)
      : type === ServiceProviderType.Company
      ? handleTabChange(1)
      : handleTabChange(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const handleOnSubmit = () => {
    if (!selectedConfiguration || !isProviderFormValid) {
      return;
    }
    updateHandler({
      dealId: EMPTY,
      domainConfigurationId: selectedConfiguration.id,
      company: getCompanyTemplate(companyState),
      branch: getBranchTemplate(branchState),
      rep: getRepTemplate(repState)
    }).then((r) => {
      if (r.status === StatusCode.OK) {
        onClose();
      }
    });
  };
  return (
    <ModalContainer
      maxWidth={1110}
      isShow={isShow}
      onCloseModal={onClose}
      header={"Add a Service Provider"}
      body={
        <ProvidersFormStepContent
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
          companyForm={{
            isReadOnly: true,
            state: companyState,
            onChangeState: onChangeCompanyState,
            setState: (selected) => {
              setCompanyState(selected);
            },
            selectedProviderId: selectedCompanyId,
            setSelectedProviderId: (id) => {
              setSelectedCompanyId(id);
            }
          }}
          branchForm={{
            isReadOnly: data?.type === ServiceProviderType.Branch,
            state: branchState,
            onChangeState: onChangeBranchState,
            setState: (selected) => {
              setBranchState(selected);
            },
            selectedProviderId: selectedBranchId,
            setSelectedProviderId: (id) => {
              setSelectedBranchId(id);
            }
          }}
          repForm={{
            isReadOnly: false,
            state: repState,
            onChangeState: onChangeRepState,
            setState: (selected) => {
              setRepState(selected);
            },
            selectedProviderId: selectedRepId,
            setSelectedProviderId: (id) => {
              setSelectedRepId(id);
            }
          }}
          onFormValidation={handleProviderFormValidation}
          showAttached={false}
        />
      }
      submit={{
        confirmTitle: "Save",
        onConfirm: handleOnSubmit,
        showClose: false,
        isDisabled: !isProviderFormValid,
        isLoading: upsertResponse.isLoading
      }}
    />
  );
};
