import { EmojiData } from "./reaction.types";

export const emojiDataSet: EmojiData[] = [
  {
    id: "+1",
    name: "Thumbs Up",
    keywords: [
      "+1",
      "thumbsup",
      "yes",
      "awesome",
      "good",
      "agree",
      "accept",
      "cool",
      "hand",
      "like"
    ],
    unified: "1f44d",
    native: "👍"
  },
  {
    id: "-1",
    name: "Thumbs Down",
    keywords: ["-1", "thumbsdown", "no", "dislike", "hand"],
    unified: "1f44e",
    native: "👎"
  },
  {
    id: "clap",
    name: "Clapping Hands",
    keywords: ["clap", "praise", "applause", "congrats", "yay"],
    unified: "1f44f",
    native: "👏"
  },
  {
    id: "ok_hand",
    name: "Ok Hand",
    keywords: ["fingers", "limbs", "perfect", "okay"],
    unified: "1f44c",
    native: "👌"
  },
  {
    id: "muscle",
    name: "Flexed Biceps",
    keywords: ["muscle", "arm", "flex", "hand", "summer", "strong"],
    unified: "1f4aa",
    native: "💪"
  },
  {
    id: "pray",
    name: "Folded Hands",
    keywords: [
      "pray",
      "please",
      "hope",
      "wish",
      "namaste",
      "highfive",
      "high",
      "five",
      "thank",
      "you",
      "thanks",
      "appreciate"
    ],
    unified: "1f64f",
    native: "🙏"
  },
  {
    id: "slightly_smiling_face",
    name: "Slightly Smiling Face",
    keywords: ["smile"],
    unified: "1f642",
    native: "🙂"
  },
  {
    id: "neutral_face",
    name: "Neutral Face",
    keywords: ["indifference", "meh", ":", ""],
    unified: "1f610",
    native: "😐"
  },
  {
    id: "saluting_face",
    name: "Saluting Face",
    keywords: ["respect", "salute"],
    unified: "1fae1",
    native: "🫡"
  },
  {
    id: "star-struck",
    name: "Star-Struck",
    keywords: [
      "star",
      "struck",
      "grinning",
      "face",
      "with",
      "eyes",
      "smile",
      "starry"
    ],
    unified: "1f929",
    native: "🤩"
  },
  {
    id: "scream",
    name: "Face Screaming in Fear",
    keywords: ["scream", "munch", "scared", "omg"],
    unified: "1f631",
    native: "😱"
  },
  {
    id: "eyes",
    name: "Eyes",
    keywords: ["look", "watch", "stalk", "peek", "see"],
    unified: "1f440",
    native: "👀"
  },
  {
    id: "white_check_mark",
    name: "Check Mark Button",
    keywords: [
      "white",
      "green",
      "square",
      "ok",
      "agree",
      "vote",
      "election",
      "answer",
      "tick"
    ],
    unified: "2705",
    native: "✅"
  },
  {
    id: "fire",
    name: "Fire",
    keywords: ["hot", "cook", "flame"],
    unified: "1f525",
    native: "🔥"
  },
  {
    id: "rocket",
    name: "Rocket",
    keywords: ["launch", "ship", "staffmode", "NASA", "outer", "space", "fly"],
    unified: "1f680",
    native: "🚀"
  },
  {
    id: "100",
    name: "Hundred Points",
    keywords: [
      "100",
      "score",
      "perfect",
      "numbers",
      "century",
      "exam",
      "quiz",
      "test",
      "pass"
    ],
    unified: "1f4af",
    native: "💯"
  },
  {
    id: "trophy",
    name: "Trophy",
    keywords: ["win", "award", "contest", "place", "ftw", "ceremony"],
    unified: "1f3c6",
    native: "🏆"
  },
  {
    id: "blue_heart",
    name: "Blue Heart",
    keywords: ["love", "like", "affection", "valentines"],
    unified: "1f499",
    native: "💙"
  }
];

export const emojiDataMap = emojiDataSet.reduce((acc, emoji) => {
  acc.set(emoji.unified, emoji);
  return acc;
}, new Map<string, EmojiData>());
