import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { ElphiLoader } from "./ElphiLoader";

type LoadingContentProps = {
  children?: React.ReactNode;
  isLoading: boolean;
  flexProps?: FlexProps;
  hideContentWhileLoading?: boolean;
};

export const LoadingContent = (props: LoadingContentProps) => {
  const { children, isLoading } = props;
  return (
    <Flex
      direction="column"
      w="100%"
      height="100%"
      position="relative"
      {...props.flexProps}
    >
      <>
        {isLoading && <ElphiLoader />}
        {isLoading ? (
          !props.hideContentWhileLoading ? (
            children
          ) : (
            <></>
          )
        ) : (
          children
        )}
      </>
      {isLoading && (
        <Box
          w={"100%"}
          h={"100%"}
          position={"absolute"}
          top={0}
          right={0}
          zIndex={999}
        />
      )}
    </Flex>
  );
};
