import { pickFromEnum } from "../../../utils/enums";
import {
  baseLoanPartyEntityRole,
  baseLoanPartyIndividualRole
} from "../../base/deal-party/baseDealParty.enumeration";

export const limaLoanPartyEntityRoleType = {
  ...pickFromEnum(baseLoanPartyEntityRole, [
    "PrimaryBorrower",
    "Borrower",
    "None"
  ]),
  DecideLater: "Decide Later"
} as const;

export const limaLoanPartyIndividualRoleType = {
  ...pickFromEnum(baseLoanPartyIndividualRole, [
    "Guarantor",
    "Signatory",
    "SignatoryCorporateResolution",
    "None"
  ]),
  Sponsor: "Sponsor",
  Applicant: "Applicant",
  PrimarySponsor: "Primary Sponsor",
  DecideLater: "Decide Later"
} as const;
