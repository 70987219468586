import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../redux/store";
import { userDispatcher } from "../../../shared";
// import { removeNulls } from "../../../utils/filter.utils";
import { Dashboard } from "../components/Dashboard";

const mapStateToProps = (state: RootState) => ({
  userData: state.userData
  // roles: state.userData?.roles?.map((v) => v.value)?.filter(removeNulls) || []
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...userDispatcher.dispatchers(dispatch)
});

export const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
