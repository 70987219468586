import { createIcon } from "@chakra-ui/react";

const customPeopleIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M19 15a4 4 0 0 1 4 4v2h-2" />
        <path
          d="M19 15a4 4 0 0 1 4 4v2h-2M16 10.874a4.002 4.002 0 0 0 0-7.748M9 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM13 15H5a4 4 0 0 0-4 4v2h16v-2a4 4 0 0 0-4-4z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const PeopleIcon = customPeopleIcon();
