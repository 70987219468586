import { FirebaseFilter } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { BaseQueryFn, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { UseLazyQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { isEqual, last } from "lodash";
import { useEffect, useMemo, useState } from "react";

export type BasePaginationQueryArg = {
  cursor?: string;
  limit: number;
  options?: {
    order?: "desc" | "asc";
    filter?: FirebaseFilter | FirebaseFilter[];
  };
};
export type PaginationLazyQuery<T> = UseLazyQuery<
  QueryDefinition<
    BasePaginationQueryArg,
    BaseQueryFn<any, any, any, any, any>,
    any,
    EntityState<T> & { nextCursor: string },
    string
  >
>;

const getSliceBoundaries = (r: { pageIndex: number; limit: number }) => {
  const { pageIndex, limit } = r;
  const safePageIndex = Math.max(pageIndex, 0);
  const start = safePageIndex * limit;
  return { start, end: start + limit };
};
export const useRTKPagination = <T>(paginationApi: {
  useLazyPaginateQuery: PaginationLazyQuery<T>;
  entityState: EntityState<T>;
  pageFilter?: (v: T) => boolean;
  options?: {
    limit: number;
    filter?: FirebaseFilter | FirebaseFilter[];
  };
}) => {
  const limit = paginationApi.options?.limit || 10;
  const filter = paginationApi.options?.filter;
  const [pageIndex, setPageIndex] = useState(-1);
  const [cursorTracker, setCursorTracker] = useState<string[]>([]);
  const [lastFilter, setLastFilter] = useState<
    FirebaseFilter | FirebaseFilter[] | undefined
  >(undefined);
  const [lastEmptyFilterCursor, setLastEmptyFilterCursor] = useState<string>();

  const [paginateApi, apiResponse] = paginationApi.useLazyPaginateQuery();
  const next = async (r?: { filter?: FirebaseFilter | FirebaseFilter[] }) => {
    const isFilterChanged = !isEqual(r?.filter, lastFilter);

    const cursor = isFilterChanged ? undefined : cursorTracker[pageIndex];

    if (isFilterChanged) {
      setCursorTracker([]);
    }
    setLastFilter(r?.filter);

    paginateApi(
      {
        cursor,
        limit,
        options: { filter: r?.filter || filter }
      },
      true
    ).then((r) => {
      if (pageIndex === cursorTracker.length - 1) {
        r.data?.nextCursor &&
          setCursorTracker([...cursorTracker, r.data?.nextCursor]);
      }

      if (r.data?.ids.length) {
        setPageIndex(pageIndex + 1);
      }
    });
  };
  const prev = async () => {
    const prevIndex = pageIndex - 1;
    if (pageIndex < 0) return;
    paginateApi(
      {
        cursor: cursorTracker[prevIndex - 1],
        limit,
        options: { filter: filter }
      },
      true
    ).then(() => {
      setPageIndex(prevIndex);
    });
  };
  const reset = async () => {
    paginateApi({ limit, options: { filter: filter } }, true).then((r) => {
      setPageIndex(0);
      if (cursorTracker.length === 0) {
        r.data?.nextCursor && setCursorTracker([r.data?.nextCursor]);
      } else {
        lastEmptyFilterCursor && setCursorTracker([lastEmptyFilterCursor]);
      }
    });
  };

  useEffect(() => {
    const isLastEmptyFilter = !lastFilter;
    if (isLastEmptyFilter) {
      const lastCursor = last(cursorTracker);
      setLastEmptyFilterCursor(lastCursor);
    }
  }, [lastFilter, cursorTracker]);

  const pageDataMemo = useMemo(() => {
    const ids = paginationApi?.pageFilter
      ? paginationApi.entityState.ids.filter((id) => {
          const e = paginationApi.entityState.entities[id];
          return (e && paginationApi?.pageFilter?.(e)) || false;
        })
      : paginationApi.entityState.ids;

    const { start, end } = getSliceBoundaries({ pageIndex, limit });

    return {
      ids: ids.slice(start, end),
      entities: ids.slice(start, end).reduce((p, v) => {
        p[v] = paginationApi.entityState.entities[v];
        return p;
      }, {}) as { [key: string]: T }
    };
  }, [paginationApi.entityState, pageIndex, cursorTracker]);

  return {
    next,
    prev,
    reset,
    setPageIndex,
    pageFilter: paginationApi.pageFilter,
    pageIndex,
    pageData: pageDataMemo,
    cursorTracker,
    pageResponse: apiResponse
  };
};
