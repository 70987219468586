import { TObject, TProperties, Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { StringField } from "./utils.typebox";

export const PartyAssetFieldsSchema = Type.Object({
  partyId: StringField,
  assetId: StringField
});

export const PartyAssetUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(PartyAssetFieldsSchema)
]);

export const PartyAssetCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(PartyAssetFieldsSchema)
]);

export const getOperationPartyAssetSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? PartyAssetCreate : PartyAssetUpdate;
};
