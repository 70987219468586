import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { EMPTY } from "../../../constants/common";
import { FuzzyProps } from "./fuzzySearch.types";

export const DEFAULT_FUSE_CONFIG = {
  includeScore: true,
  threshold: 0.4
};

export const useFuzzyHooks = <T extends object>(
  props: Pick<FuzzyProps<T>, "sanitizer" | "onInputChange">
) => {
  const { sanitizer } = props;

  const [query, setQuery] = useState<string>(EMPTY);
  const [debouncedQuery, setDebouncedQuery] = useState(EMPTY);

  const sanitizeQuery = useCallback(
    (query: string) => {
      return sanitizer ? sanitizer(query) : encodeURIComponent(query);
    },
    [sanitizer]
  );

  const onInputChange = useCallback((newInput: string) => {
    props.onInputChange?.(newInput);
    setQuery(sanitizeQuery(newInput));
  }, []);

  const handleDebounceInputChange = useCallback(
    debounce((value: string) => {
      setDebouncedQuery(value);
    }, 300),
    []
  );

  useEffect(() => {
    handleDebounceInputChange(query);
    return () => {
      handleDebounceInputChange.cancel();
      handleDebounceInputChange.flush();
    };
  }, [query, handleDebounceInputChange]);

  return {
    query,
    debouncedQuery,
    onInputChange
  };
};
