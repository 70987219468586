import { connect } from "react-redux";
import { Dispatch } from "redux";
import { resetUserData, updateUserData } from "../../../redux/actions";
import { UserData } from "../../../shared";

const mapStateToProps = (state) => ({
  userData: state.userData
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateUserData: (userData: UserData) => {
    dispatch(updateUserData(userData));
  },
  resetUserData: () => {
    dispatch(resetUserData());
  }
});

const RequireAuth = (props: { userData: UserData; children: JSX.Element }) => {
  // let auth = useAuth();
  // let location = useLocation();

  // if (!auth.instance.currentUser) {
  //   alert("this is me");
  //   // Redirect them to the /login page, but save the current location they were
  //   // trying to go to when they were redirected. This allows us to send them
  //   // along to that page after they login, which is a nicer user experience
  //   // than dropping them off on the home page.
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  // if (location.pathname === "/") {
  //   return <Navigate to={PathVariables.LoanPipeline} replace />;
  // }

  return props.children;
};

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth);
