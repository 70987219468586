import { StatusCode } from "@elphi/types";
import { values } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../../apis/rtk/response.handler";
import { RTKResponse } from "../../apis/rtk/response.types";
import { useElphiToast } from "../../components/toast/toast.hook";
import { RootState } from "../../redux/store";
import {
  InsurancePolicyViewFilterState,
  insurancePolicyViewApi,
  insurancePolicyViewSlice
} from "../../redux/v2/insurance-policy";
import { useInsurancePolicyHooks } from "./insurancePolicy.hooks";

export const useInsurancePolicyViewHooks = () => {
  const dispatch = useDispatch();
  const { errorToast } = useElphiToast();
  const {selectedIdInsurancePolicy} = useInsurancePolicyHooks();
  const insurancePolicyViewState = useSelector(
    (state: RootState) => state.insurancePolicyView
  );
  const setCurrentFilter = (filter: InsurancePolicyViewFilterState) => {
    dispatch(insurancePolicyViewSlice.actions.setFilter(filter));
  };

  const currentFilter = useSelector(
    (state: RootState) => state.insurancePolicyView.current.filter
  );

  const [getAllApi, getAllResponse] =
    insurancePolicyViewApi.useLazyGetAllQuery();

  const getBatchHandler = (r: { dealId?: string; entityId?: string }) => {
    getAllApi(r, true)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status !== StatusCode.OK) {
          errorToast({
            title: "Failed to get",
            description: r.data?.description
          });
        }
      });
  };
  
  const getInsurancePolicyViewById = () =>
    useSelector(
      (state: RootState) =>
        selectedIdInsurancePolicy &&
        values(state.insurancePolicyView.entities).find(
          (x) => x?.policy?.id === selectedIdInsurancePolicy
        )
    );

  return {
    insurancePolicyViewState,
    getBatchHandler,
    getAllResponse,
    setCurrentFilter,
    currentFilter,
    getInsurancePolicyViewById
  };
};
