import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import {
  RolodexServiceProviderView,
  ServiceProviderEntityType
} from "@elphi/types";
import { groupBy } from "lodash";
import { useMemo, useState } from "react";
import { CardsContainer } from "../../../../common-components/card/CardsContainer";
import { MenuOptions } from "../../../../common-components/menu-options/MenuOptions";
import { NONE } from "../../../../constants/common";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import ScrollableSections, { SectionProps } from "../../../ScrollableSections";
import { createSelectOptions } from "../../../utils/formUtils";
import { serviceProviderEntityTypeOptionMap } from "../../configuration/tabs/service-domain-configuration/modal/modal.utils";
import { GroupByOptions, groupByOptionsMap } from "./card/utils.ts/card.utils";
import { RolodexServiceProviderCard } from "./serviceProviderCard.types";
import { serviceProviderListHooks } from "./serviceProviderList.hooks";

type CardComponent = React.ComponentType<{ data: RolodexServiceProviderCard }>;

export const ServiceProviderListCard = (props: {
  isLoading: boolean;
  filteredItems: RolodexServiceProviderView[];
  card: CardComponent;
  isNewProviderProcess?: boolean;
}) => {
  const { isLoading, filteredItems, isNewProviderProcess } = props;
  const { configurationState } = serviceProviderListHooks();
  const { heightOffsetInPx } = useWindowDimensions();
  const [selectedGroupBy, setSelectedGroupBy] = useState<string[]>();

  const groupedData = useMemo(() => {
    const selectedOption = selectedGroupBy?.at(0);
    if (selectedOption === GroupByOptions.ServiceDomain) {
      return groupBy(filteredItems, (item) => item.domainConfigurationId);
    }
    if (selectedOption === GroupByOptions.EntityType) {
      return groupBy(filteredItems, (item) => item.entityType);
    }
    return undefined;
  }, [selectedGroupBy, filteredItems]);

  const handleGroupBy = (selected: string[]) => {
    const selectedOption = selected.at(0);
    !selectedOption
      ? setSelectedGroupBy([])
      : setSelectedGroupBy([selectedOption]);
  };

  const getGroupKey = (key: string) => {
    const selectedOption = selectedGroupBy?.at(0);
    if (selectedOption === GroupByOptions.ServiceDomain) {
      return configurationState?.entities?.[key]?.name || key;
    }
    if (selectedOption === GroupByOptions.EntityType) {
      return (
        serviceProviderEntityTypeOptionMap[key as ServiceProviderEntityType] ||
        key
      );
    }
    return key;
  };

  const sections = Object.entries(groupedData || {}).map(
    ([key, items], index): SectionProps => {
      return {
        index,
        header: <Heading>{getGroupKey(key)}</Heading>,
        body: (
          <ServiceProviderCards
            items={items}
            isLoading={isLoading}
            isNewProviderProcess={isNewProviderProcess}
            card={props.card}
          />
        )
      };
    }
  );

  return (
    <Box width={"100%"}>
      <Flex minWidth="max-content" alignItems="center" gap={2}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          ml={4}
          mr={2}
          mb={2}
        >
          <Text mr={2} color={"gray.500"}>
            GROUP BY
          </Text>
          <MenuOptions
            listContainerWidth={220}
            customKey={"groupBy"}
            onChange={handleGroupBy}
            title={groupByOptionsMap[selectedGroupBy?.at(0) || NONE]}
            options={createSelectOptions(GroupByOptions, groupByOptionsMap)}
            isSingle={true}
            selected={selectedGroupBy}
          />
        </Flex>
      </Flex>
      <Box maxHeight={heightOffsetInPx(460)} overflow={"auto"}>
        {groupedData ? (
          <ScrollableSections
            customKey="serviceProviders"
            sections={sections}
          />
        ) : (
          <ServiceProviderCards
            items={filteredItems}
            isLoading={isLoading}
            isNewProviderProcess={isNewProviderProcess}
            card={props.card}
          />
        )}
      </Box>
    </Box>
  );
};

const ServiceProviderCards = ({
  items,
  isLoading,
  card,
  isNewProviderProcess
}: {
  items: RolodexServiceProviderCard[];
  isLoading: boolean;
  card: CardComponent;
  isNewProviderProcess?: boolean;
}) => {
  return (
    <CardsContainer
      isLoading={isLoading}
      isNewProviderProcess={isNewProviderProcess}
    >
      <>
        {items.map((data) => {
          const ServiceProviderCard = card;
          return <ServiceProviderCard key={data.id} data={data} />;
        })}
      </>
    </CardsContainer>
  );
};
