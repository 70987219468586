import { DealPartyRelationType, Party, PartyType } from "@elphi/types";
import { buildPartyName } from "@elphi/utils";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { useMemo } from "react";
import { EMPTY } from "../../constants/common";
import { useDealPartyRelationHooks } from "../../hooks/dealpartyrelation.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { FuzzySearchSelect } from "../search/fuzzy/FuzzySearchSelect";
import { SelectSearchProps } from "../search/search.types";

const buildOption = (p: Party) => {
  const label = buildPartyName(p);
  const value = p.id;
  return {
    ...p,
    label,
    value
  };
};
export const DealPartySearch = (
  props: SelectSearchProps & {
    dealId: string;
    entityTypes: PartyType[];
    label: string;
  }
) => {
  const { partyState, searchParty, searchResponse } = usePartyHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const dealPartyRelations = props.entityTypes
    .map((entityType) => {
      if (entityType === PartyType.Individual) {
        return DealPartyRelationType.DealIndividual;
      }
      if (entityType === PartyType.Entity) {
        return DealPartyRelationType.DealEntity;
      }
      return undefined;
    })
    .filter(removeEmpty);

  const partyIds = useMemo(
    () =>
      new Set(
        Object.values(dealPartyRelationState.entities)
          .filter(
            (e) =>
              removeEmpty(e) &&
              e?.dealId === props.dealId &&
              dealPartyRelations.includes(e?.type)
          )
          .filter(removeEmpty)
          .map((e) => e.partyId)
      ),
    [dealPartyRelationState.entities, props.dealId]
  );

  return (
    <FuzzySearchSelect
      {...props}
      label={props.label}
      searchApi={searchParty}
      searchResponse={searchResponse}
      state={partyState}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["FirstName", "LastName", "MiddleName", "FullName"],
        shouldSort: true
      }}
      buildOption={buildOption}
      filterOption={(e) =>
        !!e.data?.PartyType &&
        props.entityTypes.includes(e.data.PartyType) &&
        partyIds.has(e.data?.id || EMPTY)
      }
      wrapperStyle={{ p: "0px" }}
    />
  );
};
