import { Text } from "@chakra-ui/react";
import { PosDealUserPartyView } from "@elphi/types";
import { usePosDealUserPartyHooks } from "../../../hooks/posDealUserParty.hooks";
import ModalContainer from "../../modal-container/ModalContainer";

export const UnpublishModal = (props: {
  show: boolean;
  onClose: () => void;
  loanIdentifier: string;
  data: PosDealUserPartyView;
}) => {
  const { handleUnpublishDeal, unpublishResponse } = usePosDealUserPartyHooks();
  const handleOnSubmit = () => {
    handleUnpublishDeal({
      dealId: props.data.dealId,
      userId: props.data.userId
    });
  };
  return (
    <ModalContainer
      header={`Unpublish Loan: ${props.loanIdentifier} From ${props.data.userName}`}
      isShow={props.show}
      onCloseModal={props.onClose}
      maxWidth={800}
      body={
        <Text>
          Are you sure you want to unpublish loan {props.loanIdentifier} and its
          tasks?
        </Text>
      }
      submit={{
        showClose: true,
        closeTitle: "Cancel",
        onConfirm: handleOnSubmit,
        isLoading: unpublishResponse.isLoading
      }}
    />
  );
};
