import { TObject, TProperties, Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { StringField } from "./utils.typebox";

export const DealPropertyFieldsSchema = Type.Object({
  dealId: StringField,
  propertyId: StringField
});

export const DealPropertyUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(DealPropertyFieldsSchema)
]);

export const DealPropertyCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(DealPropertyFieldsSchema)
]);

export const getOperationDealPropertySchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? DealPropertyCreate : DealPropertyUpdate;
};
