import {
  ArrayOptions,
  TAnySchema,
  TLiteral,
  TLiteralValue,
  TSchema,
  TUnion,
  Type
} from "@sinclair/typebox";
import {
  PercentDecimalNumber,
  PercentThreeDecimalNumber,
  PositiveDecimalNumber
} from "../utils/regex";
export const AggregationFieldSchema = <T extends TAnySchema>(t: T) =>
  Type.Partial(
    Type.Object(
      {
        calculated: Type.Optional(t),
        override: Type.Optional(t),
        focused: Type.Optional(
          AllowEmptyString(
            Type.Union([Type.Literal("calculated"), Type.Literal("override")])
          )
        )
      },
      {
        default: {
          calculated: t.default,
          override: t.default,
          focused: "calculated"
        }
      }
    )
  );
export const ThirdPartyFieldSchema = <T extends TAnySchema>(t: T) =>
  Type.Partial(
    Type.Object(
      {
        thirdParty: Type.Optional(t),
        override: Type.Optional(t),
        focused: Type.Optional(
          AllowEmptyString(
            Type.Union([Type.Literal("thirdParty"), Type.Literal("override")])
          )
        )
      },
      {
        default: {
          thirdParty: t.default,
          override: t.default,
          focused: "thirdParty"
        }
      }
    )
  );

export const ThirdPartyResponseFieldSchema = <T extends TAnySchema>(t: T) =>
  Type.Partial(
    Type.Object(
      {
        thirdParty: Type.Optional(t)
      },
      {
        default: {
          thirdParty: t.default
        }
      }
    )
  );
export const ThirdPartyAggregationFieldSchema = <T extends TAnySchema>(t: T) =>
  Type.Partial(
    Type.Object(
      {
        calculated: Type.Optional(t),
        override: Type.Optional(t),
        thirdParty: Type.Optional(t),
        focused: Type.Optional(
          AllowEmptyString(
            Type.Union([
              Type.Literal("calculated"),
              Type.Literal("override"),
              Type.Literal("thirdParty")
            ])
          )
        )
      },
      {
        default: {
          calculated: t.default,
          override: t.default,
          thirdParty: t.default,
          focused: "calculated"
        }
      }
    )
  );

export const AllowEmptyString = <
  T extends ReturnType<typeof Type.String | typeof Type.Enum>
>(
  schema: T
) => {
  return Type.Union([schema, Type.Literal("")], {
    default: schema.default,
    examples: schema.examples
  });
};

export const Deprecated = <T extends TAnySchema>(t: T) => {
  return {
    ...t,
    deprecated: true
  };
};

export type IntoStringLiteralUnion<T> = {
  [K in keyof T]: T[K] extends string ? TLiteral<T[K]> : never;
};
export function StringLiteralUnion<T extends string[]>(
  values: [...T]
): TUnion<IntoStringLiteralUnion<T>> {
  return {
    enum: [...values],
    type: "string"
  } as any;
}

export const BooleanString = Type.Union(
  [Type.Literal("Yes"), Type.Literal("No")],
  {
    examples: ["Yes", "No"]
  }
);

export const MoneyAmountFieldString = Type.String({
  examples: ["", "-1000", "1000", "1000.00"]
});

export const MoneyAmountField = Type.RegEx(PositiveDecimalNumber, {
  examples: ["", "1000", "1000.00"]
});

export const DecimalField = Type.String({
  examples: ["3.5", "7.2"]
});

export const NumberField = Type.String({
  examples: ["100.00", "200.00"]
});

export const PostalCodeField = Type.String({
  examples: ["12345", "67890"],
  maxLength: 5
});

export const PercentFieldString = Type.String({
  examples: ["", "-10", "10.0", "10.00"],
  maxLength: 6
});

export const PercentField = Type.RegEx(PercentDecimalNumber, {
  examples: ["", "10", "10.0", "10.00"]
});

export const ThreeDecimalPercentFieldString = Type.String({
  examples: ["", "-10", "10.0", "10.00", "10.000"],
  maxLength: 7
});

export const ThreeDecimalPercentField = Type.RegEx(PercentThreeDecimalNumber, {
  examples: ["", "10", "10.0", "10.00", "10.000"]
});

export const UnlimitedDecimalPercentField = Type.String({
  examples: ["10.00", "20.00"]
});

export const StringField = Type.String({
  examples: ["example_string_1", "example_string_2"]
});

export const PhoneNumberField = Type.String({
  examples: ["123-456-7890", "987-654-3210"]
});

export const IntegerField = Type.String({
  examples: ["10", "100"]
});

export const EmailField = AllowEmptyString(
  Type.String({
    format: "email",
    examples: ["email@example1.com", "email@example2.com"]
  })
);

export const DateField = AllowEmptyString(
  Type.String({ format: "date", examples: ["2023-12-28", "2024-11-20"] })
);

export const BooleanField = AllowEmptyString(BooleanString);

export const TrueFalseField = Type.Union(
  [Type.Literal(true), Type.Literal(false)],
  {
    examples: [false]
  }
);

export const SwitchField = Type.Union(
  [Type.Literal("active"), Type.Literal("disabled")],
  {
    default: "disabled"
  }
);

export const EnumField = <T extends { [key: string]: string }>(schema: T) => {
  return AllowEmptyString(Type.Enum(schema));
};

export const ArrayField = <T extends TSchema>(schema: T) => {
  const options: ArrayOptions =
    schema.default != null ? { default: [schema.default] } : {};
  return Type.Array(schema, options);
};

//TODO: fix, AggregationField conflicts with the index export due to same AggregationField type
export const AggregationField = <
  T extends ReturnType<typeof Type.String | typeof Type.Enum>
>(
  schema: T
) => {
  return AggregationFieldSchema(schema);
};

export const LiteralField = <T extends TLiteralValue>(schema: T) => {
  return Type.Literal(schema, {
    default: schema
  });
};

export const UnionField = <T extends TSchema[]>(unionSchema: T) => {
  return Type.Union(unionSchema, {
    default: unionSchema[0].default
  });
};

export const StringOrUndefined = Type.Optional(StringField);
export const NumberOrUndefined = Type.Optional(NumberField);
export const BooleanOrUndefined = Type.Optional(Type.Boolean());
export const ArrayStringOrUndefined = Type.Optional(ArrayField(StringField));

export const EnumOrUndefined = <T extends { [key: string]: string }>(type: T) =>
  Type.Optional(EnumField(type));
