import { Property } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import {
  propertyAddressLineText,
  propertyAddressUnitIdentifier,
  propertyAreaValue,
  propertyCityName,
  propertyPostalCode,
  propertyStateCode,
  propertyStructureBuiltYear,
  propertyType,
  totalBathroomCount,
  totalBedroomCount
} from "../../../base/inputs/property.inputs";
import { buildSection } from "../../../utils/formBuilder.utils";
import {
  confidenceScore,
  errorMessage,
  orderStatus,
  requestId
} from "../../inputs/rental-data/rentRange.inputs";

const rentRangeOrderSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Integration Rental Data: Order",
      inputs: [
        {
          ...propertyAddressLineText,
          isRequired: true,
          validation: (v) => !!propertyAddressLineText.validation?.(v, true)
        },
        propertyAddressUnitIdentifier,
        {
          ...propertyCityName,
          isRequired: true,
          validation: (v) => !!propertyCityName.validation?.(v, true)
        },
        {
          ...propertyStateCode,
          isRequired: true,
          validation: (v) => !!propertyStateCode.validation?.(v, true)
        },
        {
          ...propertyPostalCode,
          isRequired: true,
          validation: (v) => !!propertyPostalCode.validation?.(v, true)
        },
        propertyType,
        totalBedroomCount,
        totalBathroomCount,
        propertyStructureBuiltYear,
        propertyAreaValue
      ],
      fieldSpecs
    });

const rentRangeVerifySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Integration Rental Data: Verify",
      inputs: [confidenceScore, orderStatus, errorMessage, requestId],
      fieldSpecs
    });

const rentRangeStatusSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Integration Rental Data: Order Status",
      inputs: [orderStatus, errorMessage],
      fieldSpecs
    });

export const rentRangeSectionBuilders = {
  rentRangeOrderSection,
  rentRangeVerifySection,
  rentRangeStatusSection
};
