import { createIcon } from "@chakra-ui/react";

const customLightningIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 28 28",
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.6667 4.66669H10.5L7 15.1667H11.6667L9.33333 24.5L22.1667 11.6667H15.8667L18.6667 4.66669Z"
          stroke="#A2C1FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const LightningIcon = customLightningIcon();
