import { Party } from "@elphi/types";
import { useCallback } from "react";
import { usePartyHooks } from "../../hooks/party.hooks";
import { SearchComponentProps } from "../SearchComponent";
import { SearchHandler } from "../search/SearchHandler";
import { partyName } from "./utils/printUtils";

const buildOption = (party: Partial<Party>) => {
  const label = `${party.PartyType?.charAt(0) || "[!]"} | ${partyName(party)}`;
  const value = party?.id;
  return {
    label,
    value
  };
};

const PartySearch = (
  props: {
    filter?: (a: Party) => boolean;
  } & Pick<
    SearchComponentProps,
    | "onSelect"
    | "currentValue"
    | "label"
    | "labelPosition"
    | "isReadOnly"
    | "isDisabled"
    | "filterOption"
  > &
    Pick<Partial<SearchComponentProps>, "fieldType">
) => {
  const {
    searchParty,
    searchResponse,
    rankedSort,
    partyState,
    getRankedData,
    dataRank
  } = usePartyHooks();

  const customFilterLogic: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    if (!searchText) return true;
    const party = partyState?.entities?.[option.data.value];
    if (!party) return false;
    return (
      getRankedData(searchText, party, dataRank(party)) > 0.5 ||
      searchText === party.id.toLowerCase()
    );
  };
  const customFilter = useCallback(customFilterLogic, [partyState?.entities]);
  return (
    <SearchHandler
      version="v2"
      {...props}
      searchApi={searchParty}
      filterOption={customFilter}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={partyState}
      buildOption={buildOption}
    />
  );
};

export default PartySearch;
