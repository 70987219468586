import { Box, Button, Center, Flex, Text, useBoolean } from "@chakra-ui/react";
import { DevFeatureFlag } from "@elphi/utils";
import { useLocation } from "react-router";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { NotificationUserBox } from "../../components/notification/user/NotificationUserBox";
import { SelectOrganizationModal } from "../../components/platform/tabs/organization/SelectOrganization";
import { EMPTY } from "../../constants/common";
import { GateKeeper } from "../../features/gate/GateKeeper";
import { useBrowserHooks } from "../../hooks/browser.hooks";
import { useOrgHooks } from "../../hooks/org.hooks";
import customizedRoutes from "../../routes/index";
import { RoutingProps } from "../../shared";
export const Header = () => {
  const location = useLocation();
  const { setTabTitle } = useBrowserHooks();
  const pathname = location.pathname;
  const [showSelectOrg, { off, on }] = useBoolean();
  const { selectedOrgId } = useOrgHooks();
  const headerLabel =
    customizedRoutes(["platform_admin", "organization_admin", "admin"]).find(
      (prop: RoutingProps) => pathname.includes(prop.path)
    )?.name ||
    location.state?.title ||
    pathname;
  const headerTitle = `${selectedOrgId} | ${headerLabel}`;
  setTabTitle(headerTitle);
  return (
    <Box w="100%" borderBottomWidth={1} borderBottomColor={"gray.100"}>
      <Flex justifyContent={"space-between"} w="100%" p="10px">
        <Box mt={2}>
          <Center>
            <HeaderTitle title={String(selectedOrgId || EMPTY)} />
            <Text mx={1} fontSize={"28px"} color="gray.300">
              |
            </Text>
            <HeaderTitle title={headerLabel} />
          </Center>
        </Box>
        <Box>
          <GateKeeper gate={DevFeatureFlag.ESD_2996_NotificationCenter}>
            <NotificationUserBox />
          </GateKeeper>
          <Button
            {...elphiTheme.components.light.button.primary}
            size={"xs"}
            onClick={on}
            ml={2}
          >
            My Organizations
          </Button>
        </Box>
      </Flex>
      <SelectOrganizationModal
        isOpen={showSelectOrg}
        onClose={off}
        op="new-tab"
      />
    </Box>
  );
};

const HeaderTitle = (props: { title: string }) => {
  return (
    <Text fontSize={"20px"} fontWeight="600" color="gray.900">
      {props.title}
    </Text>
  );
};
