import { Progress } from "@chakra-ui/react";
import { useSnapshotHooks } from "../../../../hooks/snapshot.hooks";
import { PropertyTableState } from "./PropertyRow";
import { PropertyTable, PropertyTableContainerProps } from "./PropertyTable";

export const PropertyTableSnapshotContainer = (
  props: Omit<PropertyTableContainerProps, "snapshotId"> & {
    snapshotId: string;
  }
) => {
  const { snapshotDataState, getSnapshotDataResponse } = useSnapshotHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });
  const { propertyState, dealPropertyState, dealUserState, dealState } =
    snapshot || {};

  const isInProgress =
    !propertyState || !dealPropertyState || !dealUserState || !dealState;

  if (isInProgress) {
    return <Progress size="sm" isIndeterminate />;
  }
  const pageSize = propertyState.ids.length;
  const state: PropertyTableState = {
    propertyState,
    dealPropertyState,
    dealUserState,
    dealState
  };
  return (
    <PropertyTable
      state={state}
      pageSize={pageSize}
      isLoading={getSnapshotDataResponse.isLoading}
      userId={props.userId}
      dealId={props.dealId}
      propertyId={props.propertyId}
      snapshotId={props.snapshotId}
      isDisabled={true}
      isActionable={false}
      h={props.h}
    />
  );
};
