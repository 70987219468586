import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { EntityId } from "@reduxjs/toolkit";
import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useDealUserRelationHooks } from "../../hooks/dealuser.hooks";
import { RootState } from "../../redux/store";
import { useElphiToast } from "../toast/toast.hook";

export const DeleteDealUserRelationModal = (props: {
  dealId: EntityId;
  userId: EntityId;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { isOpen, onClose } = props;
  const userState = useSelector((state: RootState) => state.losUser);
  const { deleteDealUserRelation, deleteDealUserRelationResponse } =
    useDealUserRelationHooks();
  const { successToast, errorToast } = useElphiToast();

  const deleteDealUserHandler = async () => {
    if (props.userId) {
      const relationId = `${props.dealId}_${props.userId}`;
      deleteDealUserRelation(relationId).then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Deal-User Relationship Deleted",
            description: `relation id: ${r.data?.id}`
          });
          onClose();
        }
        r.status === 400 &&
          errorToast({
            title: "Failed To Delete Deal-User Relationship",
            description: r.data.description
          });
      });
    }
  };

  const userString = useMemo(() => {
    const thisUser = userState.entities?.[props.userId];
    if (thisUser) {
      return thisUser?.name ?? "N/A";
    }
    return "N/A";
  }, [props.userId]);

  const finalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box tabIndex={-1} ref={finalRef}>
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Delete Deal-User Relationship</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {props.userId && (
              <Text p="10px" fontWeight={"light"}>
                selected user: {userString}
              </Text>
            )}
            <Text p="10px" fontWeight={"bold"}>
              Are you sure you want to delete the relationship between this Deal
              and User?
            </Text>
            <Flex justifyContent={"space-between"}>
              <Button
                {...elphiTheme.components.light.button.primary}
                onClick={() => {
                  deleteDealUserHandler();
                }}
                isLoading={deleteDealUserRelationResponse.isLoading}
              >
                Yes, delete
              </Button>
              <Button
                {...elphiTheme.components.light.button.secondary}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
