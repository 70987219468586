import { Flex } from "@chakra-ui/react";
import {
  InsurancePolicyCoverages,
  InsurancePolicyEntityType,
  InsurancePolicyView,
  PropertyInsurancePolicyView
} from "@elphi/types";
import { MaximizedInfo } from "../../../../common-components/card";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { MaximizedProviderInfo } from "../../../rolodex/service-provider/list-card/card/ServiceProviderCard";
import { coverageTypesOptiosMap } from "../dealInsurancePolicy.utils";
import { DealPropertyInsurancePolicyList } from "./entity-card/DealPropertyInsurancePolicyList";

export const DealInsurancePolicyMaximizedView = (props: {
  view: InsurancePolicyView<InsurancePolicyCoverages>;
}) => {
  const { view } = props;
  return (
    <Flex direction="column" gap="8px">
      <MaximizedInfo
        title={"Service Domain"}
        subTitle={view.domainConfiguration.name}
      />
      <MaximizedProviderInfo
        title={"Company"}
        name={view.providers.company.name || NOT_AVAILABLE}
        status={view.providers.company.status}
      />
      <MaximizedProviderInfo
        title={"Branch"}
        name={view.providers.branch.name || NOT_AVAILABLE}
        status={view.providers.branch.status}
      />
      <MaximizedProviderInfo
        title={"Representative"}
        name={view.providers.rep.name || NOT_AVAILABLE}
        status={view.providers.rep.status}
      />
      <CoverageTypes
        entityType={view.entityType}
        coverages={view.policy.coverageTypes}
      />
      <EntityCards view={view} />
    </Flex>
  );
};

const CoverageTypes = (r: {
  entityType: InsurancePolicyEntityType;
  coverages: InsurancePolicyCoverages[];
}) => {
  const { entityType, coverages } = r;
  const coverageTypesOptios = coverageTypesOptiosMap[entityType];
  const types =
    coverages.map((c) => coverageTypesOptios?.[c] || c).join(", ") ||
    NOT_AVAILABLE;

  return <MaximizedInfo title={"Coverage Type"} subTitle={types} />;
};

const EntityCards = (props: {
  view: InsurancePolicyView<InsurancePolicyCoverages>;
}) => {
  const { view } = props;
  if (view.entityType === InsurancePolicyEntityType.Property) {
    return (
      <DealPropertyInsurancePolicyList
        view={view as PropertyInsurancePolicyView}
      />
    );
  }

  return <></>;
};
