import { rules } from "./passwordStrengthAssessment/rules";
import { Strength } from "./passwordStrengthAssessment/types";

const getScoreStrength = (score: number): Strength | undefined => {
  return score <= 1
    ? Strength.Weak
    : score <= 2
    ? Strength.Medium
    : score >= 3
    ? Strength.Strong
    : undefined;
};

export const getPasswordStrength = (password: string) => {
  const issues: string[] = [];

  const score = rules.reduce((score, rule) => {
    const isChecked = rule.checker(password);
    if (!isChecked) {
      issues.push(rule.issueMessage);
    } else {
      score++;
    }
    return score;
  }, 0);
  const strength = getScoreStrength(score);
  return { strength, issues };
};
