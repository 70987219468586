import {
  NavigationItemType,
  NavigationPages,
  NavigationPath,
  NavigationTabs
} from "../../shared/types/navigation.types";

export const navigationPaths: { [K in NavigationTabs]?: NavigationPath } = {
  [NavigationTabs.Deal]: [
    {
      type: NavigationItemType.Page,
      value: NavigationPages.Application
    },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.Deal
    }
  ],
  [NavigationTabs.DealUnderwriting]: [
    {
      type: NavigationItemType.Page,
      value: NavigationPages.Application
    },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.DealUnderwriting
    }
  ],
  [NavigationTabs.Parties]: [
    {
      type: NavigationItemType.Page,
      value: NavigationPages.Application
    },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.Parties
    }
  ],
  [NavigationTabs.Propersties]: [
    {
      type: NavigationItemType.Page,
      value: NavigationPages.Application
    },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.Propersties
    }
  ],
  [NavigationTabs.ConstructionManagement]: [
    {
      type: NavigationItemType.Page,
      value: NavigationPages.Application
    },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.ConstructionManagement
    }
  ],
  [NavigationTabs.ThirdParties]: [
    {
      type: NavigationItemType.Page,
      value: NavigationPages.Application
    },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.ThirdParties
    }
  ],
  [NavigationTabs.Closing]: [
    {
      type: NavigationItemType.Page,
      value: NavigationPages.Application
    },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.Closing
    }
  ],
  [NavigationTabs.PostClosing]: [
    {
      type: NavigationItemType.Page,
      value: NavigationPages.Application
    },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.PostClosing
    }
  ]
};
