function levenshteinDistance(l: string, r: string) {
  if (!l.length) return r.length;
  if (!r.length) return l.length;
  const arr: any[] = [];
  for (let i = 0; i <= r.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= l.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (l[j - 1] === r[i - 1] ? 0 : 1)
            );
    }
  }
  return arr[r.length][l.length];
}
export function compareStrings(l: string, r: string) {
  if (l.length === 0 && r.length === 0) return 1;
  return Math.max(
    0,
    1 - levenshteinDistance(l, r) / Math.max(l.length, r.length)
  );
}

export const includesKeywords = (query: string, keywords?: string[]) =>
  keywords?.includes?.(query.toLowerCase());

export function compareNumbers(l: number, r: number) {
  return Math.abs(Math.abs(l) > Math.abs(r) ? r / l : l / r);
}
