import { createIcon } from "@chakra-ui/react";

const customDragHandleIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M7.20005 4.8C5.87457 4.8 4.80005 3.72548 4.80005 2.4C4.80005 1.07452 5.87457 0 7.20005 0C8.52553 0 9.60005 1.07452 9.60005 2.4C9.60005 3.72548 8.52553 4.8 7.20005 4.8ZM7.20005 14.4C5.87457 14.4 4.80005 13.3255 4.80005 12C4.80005 10.6745 5.87457 9.6 7.20005 9.6C8.52553 9.6 9.60005 10.6745 9.60005 12C9.60005 13.3255 8.52553 14.4 7.20005 14.4ZM7.20005 24C5.87457 24 4.80005 22.9255 4.80005 21.6C4.80005 20.2745 5.87457 19.2 7.20005 19.2C8.52553 19.2 9.60005 20.2745 9.60005 21.6C9.60005 22.9255 8.52553 24 7.20005 24ZM16.8 4.8C15.4746 4.8 14.4 3.72548 14.4 2.4C14.4 1.07452 15.4746 0 16.8 0C18.1255 0 19.2 1.07452 19.2 2.4C19.2 3.72548 18.1255 4.8 16.8 4.8ZM16.8 14.4C15.4746 14.4 14.4 13.3255 14.4 12C14.4 10.6745 15.4746 9.6 16.8 9.6C18.1255 9.6 19.2 10.6745 19.2 12C19.2 13.3255 18.1255 14.4 16.8 14.4ZM16.8 24C15.4746 24 14.4 22.9255 14.4 21.6C14.4 20.2745 15.4746 19.2 16.8 19.2C18.1255 19.2 19.2 20.2745 19.2 21.6C19.2 22.9255 18.1255 24 16.8 24Z" />
      </svg>
    ),
    defaultProps: {
      fill: "#000000"
    }
  });

export const DragHandleIcon = customDragHandleIcon();
