export const baseFloodInsurancePremiumPayment = {
  PaidInFull: "Paid in Full",
  PaidAfterClosing: "Paid Outside of Closing"
} as const;

export const basePropertyType = {
  Condominium: "Condominium",
  MixedUseCommercial: "Mixed-Use Commercial",
  PUD: "PUD",
  SingleFamily: "Single Family",
  Townhome: "Townhome",
  TwoToFourUnit: "2-4 Unit",
  FiveToTwelveUnitMultifamily: "5-12 Unit Multifamily"
} as const;

export const baseLeaseStatus = {
  Vacant: "Vacant",
  LeasedRentVerified: "Leased Rent Verified",
  LeasedRentNotVerified: "Leased Rent Not Verified"
} as const;

export const baseConstructionBudgetAssessment = {
  StandardRehab: "Standard Rehab",
  NewConstruction: "New Construction",
  MidConstruction: "Mid Construction",
  HeavyRehab: "Heavy Rehab"
} as const;

export const baseAppraisalType = {
  N1004: "1004",
  N1004D: "1004D",
  N1025: "1025",
  N1073: "1073",
  N2055: "2055",
  N5UnitNarrative: "5+ Unit Narrative",
  Other: "Other"
} as const;

export const baseConditionRating = {
  C1: "C1",
  C2: "C2",
  C3: "C3",
  C4: "C4",
  C5: "C5",
  C6: "C6",
  Poor: "Poor",
  Fair: "Fair",
  Average: "Average",
  Good: "Good",
  Excellent: "Excellent"
} as const;
