import { Type } from "@sinclair/typebox";
import { Filters } from "./shared.typebox";
import { StringField } from "./utils.typebox";

export const partyGroupModel = Type.Object({
  name: Type.String({ minLength: 1 }),
  description: Type.Optional(StringField),
  status: Type.Optional(
    Type.Union([Type.Literal("active"), Type.Literal("disabled")])
  )
});

export const partyGroupUpdate = Type.Object({
  id: StringField,
  name: Type.Optional(StringField),
  description: Type.Optional(StringField),
  status: Type.Optional(
    Type.Union([Type.Literal("active"), Type.Literal("disabled")])
  )
});

export const updateDetailsSchema = Type.Optional(Type.Partial(partyGroupModel));

export const partyGroupBatchUpdate = Type.Object({
  updateDetails: updateDetailsSchema,
  excludedIds: Type.Optional(Type.Array(Type.String())),
  partyGroups: Type.Array(partyGroupUpdate),
  filters: Type.Optional(Filters)
});

export const partyGroupCount = Type.Optional(Filters);
