import { DiscussionComment } from "@elphi/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { union, unionBy } from "lodash";
import { discussionEntityApi } from "./discussionEntity.service";

export type DiscussionEntitySliceState = {
  discussions: {
    [entityId: string]: {
      comments: DiscussionComment[];
      watcherUIDs: string[];
    };
  };
};

const initialState: DiscussionEntitySliceState = {
  discussions: {}
};

export const discussionEntitySlice = createSlice({
  name: "discussionEntity",
  initialState: initialState,
  reducers: {
    addComments: (
      state,
      action: PayloadAction<{ entityId: string; comments: DiscussionComment[] }>
    ) => {
      const { entityId, comments } = action.payload;
      if (!state.discussions[entityId]) {
        state.discussions[entityId] = {
          comments: [],
          watcherUIDs: []
        };
      }
      state.discussions[entityId].comments = union(
        state.discussions[entityId].comments,
        comments
      );
    },
    removeComments: (
      state,
      action: PayloadAction<{ entityId: string; commentIds: string[] }>
    ) => {
      const { entityId, commentIds } = action.payload;
      state.discussions[entityId].comments = state.discussions[
        entityId
      ].comments.filter((comment) => !commentIds.includes(comment.id));
    },
    updateComments: (
      state,
      action: PayloadAction<{ entityId: string; comments: DiscussionComment[] }>
    ) => {
      const { entityId, comments } = action.payload;
      state.discussions[entityId].comments = state.discussions[
        entityId
      ].comments.map(
        (comment) => comments.find((c) => c.id === comment.id) || comment
      );
    },
    setWatchers: (
      state,
      action: PayloadAction<{ entityId: string; watchers: string[] }>
    ) => {
      const { entityId, watchers } = action.payload;
      if (!state.discussions[entityId]) {
        state.discussions[entityId] = {
          comments: [],
          watcherUIDs: []
        };
      }
      state.discussions[entityId].watcherUIDs = watchers;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      discussionEntityApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        const entityId = payload.entityId;
        if (!entityId) {
          return;
        }
        if (!state.discussions[entityId]) {
          state.discussions[entityId] = {
            comments: [],
            watcherUIDs: []
          };
        }
        state.discussions[entityId].comments = unionBy(
          payload.page,
          state.discussions[entityId].comments,
          "id"
        );
      }
    );
    builder.addMatcher(
      discussionEntityApi.endpoints.saveWatchers.matchFulfilled,
      (state, { payload }) => {
        const entityId = payload.entityId;
        if (!entityId) {
          return;
        }
        if (!state.discussions[entityId]) {
          state.discussions[entityId] = {
            comments: [],
            watcherUIDs: []
          };
        }
        state.discussions[entityId].watcherUIDs = payload.watcherUIDs;
      }
    );
  }
});
