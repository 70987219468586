import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { get, sum } from "lodash";
import { compareStrings, includesKeywords } from "./searchUtils";
export type FieldsWeight<T> = { [P in DotNestedKeys<T>]?: number };
export type SortedDataProps<T> = {
  query: string;
  dataSrc: T;
  fields: FieldsWeight<T>;
};

export const getRankedData = <T extends { keywords?: string[] }>(
  query: string,
  dataSrc: T | undefined,
  fields: FieldsWeight<T>,
  options?: { sortedByKeywords?: boolean }
) => {
  if (!dataSrc) return 0;

  const { sortedByKeywords = false } = options || {};

  const isKeywordMatch =
    sortedByKeywords && includesKeywords(query, dataSrc?.keywords);
  const levenshteinDistanceValue = getCalculatedRankedData({
    query,
    dataSrc,
    fields
  });

  const keywordRank = isKeywordMatch ? Number.MAX_SAFE_INTEGER : 0;

  const combinedRank = keywordRank + levenshteinDistanceValue;

  return combinedRank;
};

export const getCalculatedRankedData = <T>(r: SortedDataProps<T>) => {
  const { dataSrc, fields, query } = r;

  return Object.keys(fields)
    .map((field) => {
      const v = get(dataSrc, field) || "";
      const weight = fields[field] || 0;
      if (Array.isArray(v)) {
        const arrayWeight = v.map((x) => compareStrings(query, x) * weight);
        return sum(arrayWeight);
      }
      return compareStrings(query, v) * weight;
    })
    .reduce((p, v) => (p += v), 0);
};
