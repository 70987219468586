import { useCallbackRef } from "@chakra-ui/react";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition
} from "@reduxjs/toolkit/dist/query";

import { UseLazyQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
// import { Buffer } from "buffer";
import { useEffect, useState } from "react";
export type PaginationLazyQueryV2<
  I extends object,
  O extends BasePaginationResponse<object>
> = UseLazyQuery<
  QueryDefinition<
    I,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    any,
    O,
    string
  >
>;

export type BasePaginationResponse<T extends object> = {
  hasMore?: boolean;
  nextCursor?: string;
  page: T;
};

const cursorParser = (b64Cursor?: string) => {
  return b64Cursor
    ? JSON.parse(Buffer.from(b64Cursor, "base64").toString())
    : undefined;
};
export const usePaginationHooksV2 = <
  I extends object,
  TCursor extends { [key: string]: string } | undefined,
  O extends BasePaginationResponse<object>
>(props: {
  input: I;
  limit: number;
  paginationProvider: PaginationLazyQueryV2<I, O>;
  setCursor: (cursor: string) => void;
  setHasMore?: (hasMore: boolean) => void;
  currentCursor?: string;
}) => {
  const { setCursor, currentCursor } = props;
  const [paginateApi, paginateApiResponse] = props.paginationProvider();

  const [parsedCursor, setParsedCursor] = useState<TCursor | undefined>(
    cursorParser(currentCursor)
  );
  const [hasMore, setHasMore] = useState(true);
  const clearCursor = () => {
    setCursor("");
    setHasMore(true);
    props?.setHasMore?.(true);
  };
  useEffect(() => {
    setHasMore(!!paginateApiResponse.currentData?.hasMore);
    props?.setHasMore?.(!!paginateApiResponse.currentData?.hasMore);
  }, [paginateApiResponse.currentData?.hasMore]);
  useEffect(() => {
    const responseNextCursor = paginateApiResponse.currentData?.nextCursor;
    const parsedCursorSrc = cursorParser(responseNextCursor);
    setParsedCursor(parsedCursorSrc);

    if (responseNextCursor) setCursor(responseNextCursor);
  }, [paginateApiResponse.currentData?.nextCursor]);

  const nextPage = useCallbackRef(
    async (r?: { preferCacheValue: boolean }) => {
      const preferCacheValue =
        r?.preferCacheValue === undefined ? true : r.preferCacheValue;
      if (paginateApiResponse.isLoading || paginateApiResponse.isFetching) {
        console.log("already fetching");
        return;
      }
      return await paginateApi(
        {
          limit: props.limit.toString(),
          cursor: preferCacheValue ? currentCursor : undefined,
          ...props.input
        },
        preferCacheValue
      );
    },
    [props.input, currentCursor, props.limit]
  );

  return {
    clearCursor,
    paginateApi,
    currentCursor,
    paginateApiResponse,
    nextPage,
    parsedCursor,
    hasMore: hasMore
  };
};
