import { RepeatIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import { ElphiEntityType, FirebaseCollections } from "@elphi/types";
import { onSnapshot } from "firebase/firestore";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { FilterMenuOptions } from "../../../../common-components/menu-options/FilterMenuOptions";
import { useFilterMenuHook } from "../../../../common-components/menu-options/filter-menu-hook";
import firebase, { auth, firestore } from "../../../../firebase/firebaseConfig";
import { getSpecs } from "../../../../forms/schemas/factories/specsFactory";
import {
  PAGE_SIZE_LIMIT,
  useAllDealEventsHooks
} from "../../../../hooks/allEvents.hooks";
import { useFirstMountHook } from "../../../../hooks/firstMount.hook";
import { useLOSUserHooks } from "../../../../hooks/losuser.hooks";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import { DealAuditLogBoxProps } from "../../auditLogBox.types";
import { EventList } from "../list/EventList";
import { EventLoadButton } from "../list/EventLoadButton";
import {
  allEventFilters,
  auditEventOptions,
  auditFieldOptions,
  collectFieldSpecLabels,
  entityOptions
} from "../list/eventList.utils";

export const DealAllEventsTab = (props: DealAuditLogBoxProps) => {
  const { selectedOrgId } = useOrgHooks();
  const { getPaginateUsers } = useLOSUserHooks();
  const filterMenuHook = useFilterMenuHook();
  const { clearFilters } = filterMenuHook;
  const {
    filteredAllEvents,
    currentFilter,
    currentHasNew,
    setCurrentHasNew,
    setCurrentFilter,
    setSelectedDealId,
    nextPage,
    hasMore,
    isLoading,
    selectedDealId,
    userOptions,
    resetFilters
  } = useAllDealEventsHooks();
  const isFirstMount = useFirstMountHook();
  const fieldsSpecs = getSpecs();
  useEffect(() => {
    setSelectedDealId(props.dealId);
    getPaginateUsers(
      {
        limit: 600,
        options: {}
      },
      true
    );

    return () => {
      resetFilters();
    };
  }, []);
  useEffect(() => {
    if (selectedDealId === props.dealId) {
      nextPage({
        preferCacheValue: false
      });
    }
  }, [selectedDealId]);

  useEffect(() => {
    if (isFirstMount) {
      return;
    }
    nextPage();
  }, [
    currentFilter.entities?.length,
    currentFilter.fields?.length,
    currentFilter.auditTypes?.length,
    currentFilter.eventTypes?.length,
    currentFilter.users?.length
  ]);

  useEffect(() => {
    if (!selectedOrgId || currentHasNew) {
      return;
    }

    const dateNow = firebase.firestore.Timestamp.now().toDate();
    const auditQuery = firestore
      .context({
        orgId: selectedOrgId.toString(),
        user: {
          id: auth.currentUser?.uid
        }
      })
      .collection(FirebaseCollections.AuditEvent)
      .where("dealIds", "array-contains", props.dealId)
      .where("createdAt", ">=", dateNow);
    const unsubscribe = onSnapshot(auditQuery, (snapshot) => {
      const isAnyNewAudit = snapshot
        .docChanges()
        .some((change) => change.type === "added");
      if (isAnyNewAudit) {
        setCurrentHasNew(true);
        unsubscribe();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [currentHasNew, props.dealId]);

  const haveSelectedEntityFilter = currentFilter?.entities?.length === 1;

  const fieldOptions = useMemo(() => {
    if (!haveSelectedEntityFilter) {
      return [];
    }
    const entityType = currentFilter?.entities?.at(0) || "";
    const spec =
      entityType && fieldsSpecs
        ? fieldsSpecs[entityType as ElphiEntityType]?.entitySpecs
        : undefined;
    return spec ? collectFieldSpecLabels(spec) : [];
  }, [currentFilter?.entities]);

  const handleReloadClick = () => {
    setCurrentHasNew(false);
    nextPage({
      preferCacheValue: false
    });
  };

  const handleClearClick = () => {
    clearFilters();
    resetFilters();
  };

  return (
    <Box>
      <Box>
        <Flex minWidth="max-content" alignItems="center" gap={2} mb={4}>
          <FilterMenuOptions
            customKey={"entitiesFilter"}
            maxHeight={"330px"}
            scrollHeight={"330px"}
            filterMenuHook={filterMenuHook}
            title={allEventFilters.entities}
            options={entityOptions}
            onChange={(items: string[]) => {
              setCurrentFilter({
                ...currentFilter,
                entities: items,
                fields: []
              });
            }}
            selected={currentFilter?.entities}
            showSearchBar
            isSingle
          />
          <FilterMenuOptions
            buttonTooltipLabel={
              !haveSelectedEntityFilter ? "Select entity first" : undefined
            }
            isDisabled={!haveSelectedEntityFilter}
            key={currentFilter?.entities?.at(0) || "empty"}
            customKey={"fieldsFilter"}
            maxHeight={"300px"}
            scrollHeight={"300px"}
            filterMenuHook={filterMenuHook}
            title={allEventFilters.fields}
            options={fieldOptions}
            showSearchBar={true}
            onChange={(items: string[]) => {
              setCurrentFilter({ ...currentFilter, fields: items });
            }}
            selected={currentFilter?.fields}
            showDisabledTooltip
            showClearAll
          />
          <FilterMenuOptions
            customKey={"auditTypeFilter"}
            maxHeight={"300px"}
            scrollHeight={"300px"}
            filterMenuHook={filterMenuHook}
            title={allEventFilters.auditTypes}
            options={auditFieldOptions}
            showSearchBar={true}
            onChange={(items: string[]) => {
              setCurrentFilter({ ...currentFilter, auditTypes: items });
            }}
            selected={currentFilter?.auditTypes}
            showClearAll
          />
          <FilterMenuOptions
            customKey={"auditEventFilter"}
            maxHeight={"300px"}
            scrollHeight={"300px"}
            filterMenuHook={filterMenuHook}
            title={allEventFilters.eventTypes}
            options={auditEventOptions}
            showSearchBar={true}
            onChange={(items: string[]) => {
              setCurrentFilter({ ...currentFilter, eventTypes: items });
            }}
            selected={currentFilter?.eventTypes}
            showClearAll
          />
          <FilterMenuOptions
            customKey={"userFilter"}
            maxHeight={"300px"}
            scrollHeight={"300px"}
            filterMenuHook={filterMenuHook}
            title={allEventFilters.users}
            options={userOptions}
            showSearchBar={true}
            onChange={(items: string[]) => {
              setCurrentFilter({ ...currentFilter, users: items });
            }}
            selected={currentFilter?.users}
            showClearAll
          />
          <Button
            mr={1}
            background={"transparent"}
            disabled={
              isEmpty(currentFilter?.auditTypes) &&
              isEmpty(currentFilter?.entities) &&
              isEmpty(currentFilter?.eventTypes) &&
              isEmpty(currentFilter?.fields) &&
              isEmpty(currentFilter?.users)
            }
            fontSize={14}
            fontWeight={"normal"}
            onClick={handleClearClick}
          >
            Clear filters
          </Button>
          {/* {filteredAllEvents.length} */}
        </Flex>
        <EventLoadButton
          text={"Load New Events"}
          onClick={handleReloadClick}
          isDisabled={isLoading || !currentHasNew}
          icon={<RepeatIcon w={4} h={4} />}
        />
      </Box>
      <Box>
        <EventList
          data={filteredAllEvents}
          hasMore={hasMore}
          isLoading={isLoading}
          next={nextPage}
          height={480}
          limit={PAGE_SIZE_LIMIT}
        />
      </Box>
    </Box>
  );
};
