import { ElphiEntityType } from "@elphi/types";
import { multiSelectValidation } from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const dealPartyRelationRoleType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs[ElphiEntityType.dealParty]?.specsParts
      ?.dealToEntityFieldSpecs?.relationRoleType?.fieldKey,
  validation: multiSelectValidation
});
