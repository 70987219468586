import { Box } from "@chakra-ui/react";
import { IntegrationCenterFilter } from "../IntegrationCenterFilter";
import {
  IntegrationBoardFilterProps,
  IntegrationBoardFilterType
} from "../integrationCenter.types";

export const BoardFilters = (props: IntegrationBoardFilterProps) => {
  const { filterOptions, state, onChange } = props;
  return (
    <>
      {Object.entries(filterOptions).map(([filterKey, configuration], i) => {
        return (
          <Box mr={1} key={i}>
            <IntegrationCenterFilter
              filterType={IntegrationBoardFilterType.Menu}
              title={configuration.title}
              options={configuration.options}
              selected={state[filterKey]}
              onChange={(selected) => {
                onChange(filterKey, selected);
              }}
            />
          </Box>
        );
      })}
    </>
  );
};
