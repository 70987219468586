import { FieldGroup } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { fieldGroupSlice } from "./fieldGroup.slice";

export const fieldGroupAction: ActionDispatcher<FieldGroup> = (
  dispatch,
  callback
) => {
  const actionDispatcher = buildAction(fieldGroupSlice);
  return actionDispatcher(dispatch, callback);
};
