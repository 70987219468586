import { TObject, TProperties, Type } from "@sinclair/typebox";
import {
  Statement,
  StatementStatusType,
  getStatementTemplate
} from "../entities";
import { AggregationFieldSchema } from "./aggregation.typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import {
  BooleanField,
  DateField,
  EnumField,
  MoneyAmountField,
  PercentField,
  StringField
} from "./utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "./utils/fieldMeta.utils";

export const StatementAggregationFields = Type.Object({
  qualifyingBalance: AggregationFieldSchema(MoneyAmountField)
});

const fieldMetaSchema = buildFieldMetadata<Statement, TObject<TProperties>>({
  entityTemplate: getStatementTemplate(),
  target: FieldMetaTarget.Schema
});

export const StatementFieldsSchema = Type.Object({
  FieldMeta: Type.Partial(fieldMetaSchema),
  aggregations: Type.Partial(StatementAggregationFields),
  assetId: StringField,
  status: EnumField(StatementStatusType),
  latestStatement: BooleanField,
  start: DateField,
  end: DateField,
  statementBalance: MoneyAmountField,
  unverifiedDeposits: MoneyAmountField,
  percentConsidered: PercentField
});

export const StatementSchema = Type.Intersect([
  BaseEntitySchema,
  StatementFieldsSchema
]);

export const StatementUpdate = OmitUpdateSchema(StatementSchema);
export const StatementCreate = OmitCreateSchema(StatementSchema);

export const getOperationStatementSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  if (op === "create") {
    return StatementCreate;
  } else if (op === "update") {
    return StatementUpdate;
  }
  throw `${op} is not supported`;
};
