import { useState } from "react";

export const useloanpassSidebarHooks = () => {
  const [shouldExpandAllSections, setShouldExpandAllSections] = useState(true);
  const [shouldShowSubmitModal, setShouldShowSubmitModal] = useState(false);

  const handleSubmit = () => {
    setShouldShowSubmitModal(true);
  };

  const handleSubmitModalClose = () => {
    setShouldShowSubmitModal(false);
  };

  const handleSubmitModalConfirm = () => {
    setShouldExpandAllSections(false);
    handleSubmitModalClose();
  };

  return {
    shouldExpandAllSections,
    setShouldExpandAllSections,
    shouldShowSubmitModal,
    setShouldShowSubmitModal,
    handleSubmit,
    handleSubmitModalClose,
    handleSubmitModalConfirm
  };
};
