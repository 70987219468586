import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ProgressBarState = Record<string, number | undefined>;

const initialState: ProgressBarState = {};

export const progressBarSlice = createSlice({
  name: "progressBar",
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<{ id: string; progress?: number }>) => {
      state[action.payload.id] = action.payload.progress;
    }
  }
});
