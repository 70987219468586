import { createSlice } from "@reduxjs/toolkit";
import lodash from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { partyApi } from "../party/party.service";
import { partyRelationEntityAdapter as entityAdapter } from "./partyRelation.adapter";
import { partyRelationApi } from "./partyRelation.service";
export const partyRelationSlice = createSlice({
  name: "partyRelation",
  initialState: entityAdapter.getInitialState(),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(partyRelationApi)(builder);
    builder.addMatcher(
      partyRelationApi.endpoints.children.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.children.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.partyTree.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
  }
});
