import { NotificationAuditEvent } from "@elphi/types";
import { createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { EMPTY } from "../../../constants/common";
import sliceBuilder from "../builders/slice.builder";
import { FiltersState } from "../types/stateWithFilter.types";
import {
  initCombination,
  setCombinationInfo,
  setFilterAndCombination
} from "../utils/stateFilter.utils";
import { notificationUserEntityAdapter } from "./notificationUser.adapter";
import { notificationUserApi } from "./notificationUser.service";

type NotificationUserFilterState = Partial<{
  dealIds: string[];
  entityType: string;
  fieldPath: string[];
  auditType: string[];
  createdUID: string[];
  unread: string;
}>;

export type NotificationUserSliceState = EntityState<NotificationAuditEvent> & {
  filters: FiltersState<
    NotificationUserFilterState,
    | "dealIds"
    | "entityType"
    | "fieldPath"
    | "auditType"
    | "createdUID"
    | "unread"
  >;
};

const initialState: NotificationUserSliceState = {
  ids: [],
  entities: {},
  filters: {
    current: {
      dealIds: [],
      entityType: [],
      fieldPath: [],
      auditType: [],
      createdUID: [],
      unread: []
    },
    combinations: {
      [EMPTY]: initCombination()
    },
    currentCombination: EMPTY
  }
};

export const notificationUserSlice = createSlice({
  name: "notificationUser",
  initialState: notificationUserEntityAdapter.getInitialState(initialState),
  reducers: {
    update: notificationUserEntityAdapter.updateOne,
    remove: notificationUserEntityAdapter.removeOne,
    add: notificationUserEntityAdapter.addOne,
    upsert: notificationUserEntityAdapter.upsertOne,
    upsertMany: notificationUserEntityAdapter.upsertMany,
    removeMany: notificationUserEntityAdapter.removeMany,
    updateMany: notificationUserEntityAdapter.updateMany,
    setFilterAndCombination: (
      state,
      action: PayloadAction<{
        combination: string;
        filters: NotificationUserSliceState["filters"];
      }>
    ) => setFilterAndCombination(state, action),
    setFilterCombinationDetails: (
      state,
      action: PayloadAction<
        NotificationUserSliceState["filters"]["combinations"][string]
      >
    ) => setCombinationInfo(state, action),
    resetFilters: (state) => {
      state.filters = initialState.filters;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(notificationUserApi)(builder);
    builder.addMatcher(
      notificationUserApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page, "id")
        };
      }
    );
    builder.addMatcher(
      notificationUserApi.endpoints.toggleStatus.matchFulfilled,
      (state, { payload }) => {
        const entity = state.entities[payload.id];
        if (entity) {
          entity.status = payload.status;
        }
      }
    );
  }
});
