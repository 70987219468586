import { BaseEntity } from "../db";
import { BoxFileInfo, GcpFileInfo } from "../storage";
import { Replace } from "../utils/replace.types";
import { DocumentEntityType } from "./documentConfiguration.types";
import { ElphiEntityType } from "./elphiEntities.types";

export enum OrderStatusType {
  Pending = "Pending",
  Processing = "Processing",
  Ready = "Ready",
  Failed = "Failed"
}

export enum DocumentPackageType {
  ShortTerm = "Short Term",
  LongTerm = "Long Term",
  Manual = "Manual"
}

export enum RulesEngineDecision {
  Match = "Match",
  NoMatch = "No Match",
  Failed = "Failed"
}

export type BaseOrder = {
  status: OrderStatusType;
  items: OrderItems;
  error?: string;
};

type FileInfo = GcpFileInfo | BoxFileInfo;

export type OrderItems = {
  [configId: string]: {
    [P in keyof typeof ElphiEntityType]?: {
      [entityId: string]: OrderItem;
    };
  };
};

export type OrderItem = {
  rulesEngineDecision: RulesEngineDecision;
  ruleTemplateId: string;
  documentEntity: DocumentEntityType;
  documentName: string;
  entityId: string;
  configurationId: string;
  status: OrderStatusType;
} & Partial<FileInfo>;

export type AdditionalFileType = Pick<GcpFileInfo, "data">["data"] & {
  status: OrderStatusType;
};

export type ExtendedGcpFileDataInfo = GcpFileInfo["data"] &
  Partial<{
    additionalFile: {
      [fileType: string]: AdditionalFileType;
    };
  }>;

export type DocumentPackageOrderData = BaseOrder &
  Partial<Replace<GcpFileInfo, "data", ExtendedGcpFileDataInfo>>;

export type DocumentPackageOrder = BaseEntity<{
  type: DocumentPackageType;
  dealId: string;
  userId: string;
  order: DocumentPackageOrderData;
}>;
