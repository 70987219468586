import { RolodexConfigurationFieldType } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { flatten } from "flat";
import { endsWith, get, isArray } from "lodash";
import { NOT_AVAILABLE } from "../../../../../../constants/common";
import { EntityFormFieldSpecs } from "../../../../../form-builder/field-specs/fields.types";
import { presetFieldsMap } from "../modal/modal.utils";

export const buildFieldsSpecsOptions = <T extends object>(r: {
  specs?: EntityFormFieldSpecs<T>;
  type: RolodexConfigurationFieldType;
}) => {
  const { specs, type } = r;
  try {
    return Object.keys(flatten(specs, { safe: true }))
      .map((key) => {
        if (endsWith(key, "label")) {
          const label = get(specs, key, NOT_AVAILABLE);
          const baseKeyPath = key.replace(/\.label$/, "");
          const fieldKey = get(specs, `${baseKeyPath}.fieldKey`, []);
          const path = isArray(fieldKey)
            ? fieldKey.join(".")
            : String(fieldKey);
          return {
            label,
            value: path,
            isDisabled: !!presetFieldsMap?.[type]?.[path]
          };
        }
      })
      .filter(removeEmpty);
  } catch (error) {
    console.error("rolodex configuration build options", error);
  }
};
