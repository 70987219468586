import { Box, Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export const CardMinimizedInfo = (props: {
  dataPreview: {
    text: string;
    color?: string;
  }[];
  delimeter?: string;
  icon: JSX.Element;
}) => {
  const { delimeter = "|" } = props;

  return (
    <Flex width="100%">
      <Flex
        fontSize="14px"
        fontWeight="600"
        flex="1"
        gap="4px"
        overflow="hidden"
      >
        {props.dataPreview.map((data, index) => (
          <Flex key={index} align="center" minWidth="0">
            <TextEllipsis color={data.color || "gray.600"}>
              {data.text}
            </TextEllipsis>
            {index + 1 < props.dataPreview.length && (
              <Box as="span" mx="4px" color={data.color || "gray.600"}>
                {delimeter}
              </Box>
            )}
          </Flex>
        ))}
      </Flex>
      {props.icon && <Box ml="8px">{props.icon}</Box>}
    </Flex>
  );
};

const TextEllipsis = (props: { color: string; children: ReactNode }) => {
  return (
    <Text
      color={props.color}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {props.children}
    </Text>
  );
};
