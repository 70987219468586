import {
  BaseInsurancePolicy,
  InsurancePolicyEntityType,
  StatusCode
} from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { entries } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../../apis/rtk/response.handler";
import { RTKResponse } from "../../apis/rtk/response.types";
import { useElphiToast } from "../../components/toast/toast.hook";
import { RootState } from "../../redux/store";
import {
  insurancePolicyApi,
  insurancePolicySlice
} from "../../redux/v2/insurance-policy";

export const useInsurancePolicyHooks = () => {
  const dispatch = useDispatch();
  const { successToast, errorToast } = useElphiToast();
  const [searchApi, searchResponse] = insurancePolicyApi.useLazySearchQuery();
  const [getAllApi, getAllApiResponse] =
    insurancePolicyApi.useLazyGetAllQuery();
  const [getApi, getApiResponse] = insurancePolicyApi.useLazyGetQuery();
  const [getBatchApi, getBatchApiResponse] =
    insurancePolicyApi.useLazyGetBatchQuery();

  const [deleteDealFromInsuranceApi, deleteDealFromInsuranceResponse] =
    insurancePolicyApi.useDeleteDealFromInsurancePolicyMutation();
  const [deleteEntityFromInsuranceApi, deleteEntityFromInsuranceApiResponse] =
    insurancePolicyApi.useDeleteEntityFromInsurancePolicyMutation();
  const [deleteApi, deleteApiResponse] = insurancePolicyApi.useDeleteMutation();

  const [updateBatchApi, updateBatchApiResponse] =
    insurancePolicyApi.useBatchUpdateMutation();

  const insurancePolicyState = useSelector(
    (state: RootState) => state.insurancePolicy
  );

  const selectedId = useSelector(
    (state: RootState) => state?.insurancePolicy?.selectedId || ""
  );

  const selectedInsurancePolicy = useSelector(
    (state: RootState) => state.insurancePolicy?.entities[selectedId]
  );

  const getInsurancePolicyFromState = (r: { id: string }) => {
    return insurancePolicyState?.entities[r.id];
  };

  const selectPolicy = (r: { id?: string }) =>
    dispatch(insurancePolicySlice.actions.setSelectId({ id: r.id }));

  const deleteEntityFromInsurancePolicy = async (r: {
    entityType?: InsurancePolicyEntityType;
    entityId: string;
    policyId?: string;
  }) => {
    const { entityType, entityId, policyId } = r;
    if (!policyId) {
      errorToast({
        title: "Failed",
        description: "Policy Id: is missing"
      });
      return;
    }

    await deleteEntityFromInsuranceApi({ entityId, policyId })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: `Insurance Policy Removed Successfully`,
            description: `Insurance Policy: ${policyId}`
          });
        } else {
          errorToast({
            title: "Failed To Remove Insurance Policy",
            description: `Failed Remove ${
              entityType || "Entity"
            }: ${entityId} from insurance policy: ${policyId} `
          });
        }
        return res;
      });
  };

  const deleteDealFromInsurancePolicy = async (r: {
    dealId: string;
    policyId?: string;
  }) => {
    const { dealId, policyId } = r;
    if (!policyId) {
      errorToast({
        title: "Failed",
        description: "Policy ID is missing"
      });
      return;
    }

    await deleteDealFromInsuranceApi({ dealId, policyId })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: `Insurance Policy Removed Successfully`,
            description: `Insurance Policy: ${policyId}`
          });
        } else {
          errorToast({
            title: "Failed To Remove Insurance Policy",
            description: `Failed Remove deal: ${dealId} from insurance policy: ${policyId}`
          });
        }
        return res;
      });
  };

  const deleteHandler = async (r: { policyId?: string }) => {
    const { policyId } = r;
    if (!policyId) {
      errorToast({
        title: "Failed",
        description: "Policy ID is missing"
      });
      return;
    }
    await deleteApi(policyId)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Insurance Policy Deleted Successfully",
            description: `Insurance Policy: ${policyId}`
          });
        } else {
          errorToast({
            title: "Failed To Delete Insurance Policy",
            description: `Insurance policy: ${policyId}`
          });
        }
        return res;
      });
  };

  const updateInsurancePolicyHandler = async (map?: {
    [id: string]: Partial<BaseInsurancePolicy<InsurancePolicyEntityType>>;
  }) => {
    if (!map) {
      return;
    }
    const insurancePolicies = entries(map).map(
      ([id, policy]): PartialWithId<
        BaseInsurancePolicy<InsurancePolicyEntityType>
      > => ({
        ...policy,
        id
      })
    );
    return await updateBatchApi({ insurancePolicies })
      .then(responseHandler)
      .then((r) => {
        if (r?.status === StatusCode.OK) {
          successToast({
            title: "insurance policies updated",
            description: `${r?.data?.batch?.length} insurance policies updated`
          });
        }
        if (r?.status === StatusCode.BadRequest) {
          errorToast({
            title: "insurance policies update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      });
  };

  const getInsurancePoliciesByDealId = async (
    dealId: string,
    preferCacheValue?: boolean
  ) => {
    return await getAllApi({ params: { dealId } }, preferCacheValue)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const getInsurancePolicyHandler = async (r: {
    id: string;
    entityType: InsurancePolicyEntityType;
  }) => {
    const { id, entityType } = r;
    await getApi({
      entityType,
      id
    })
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((res) => {
        if (res.status !== StatusCode.OK) {
          errorToast({
            title: "Failed",
            description: `Failed to get insurance policy: ${id}`
          });
          return res;
        }
      });
  };

  return {
    insurancePolicyState,
    selectedIdInsurancePolicy: selectedId,
    getInsurancePolicyFromState,
    selectedInsurancePolicy,
    selectPolicy,
    getApi,
    getBatchApi,
    getAllApi,
    searchApi,
    updateInsurancePolicyHandler,
    deleteDealFromInsurancePolicy,
    deleteEntityFromInsurancePolicy,
    deleteHandler,
    getInsurancePoliciesByDealId,
    getInsurancePolicyHandler,
    getApiResponse,
    getBatchApiResponse,
    getAllApiResponse,
    searchResponse,
    updateBatchApiResponse,
    deleteDealFromInsuranceResponse,
    deleteEntityFromInsuranceApiResponse,
    deleteApiResponse
  };
};
