import { TaskRuleTemplate } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { taskRuleSlice } from "./taskRule.slice";

export const taskRuleAction: ActionDispatcher<TaskRuleTemplate> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: TaskRuleTemplate[]) => void;
    update?: (r: Update<TaskRuleTemplate>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = taskRuleSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

export default taskRuleAction;
