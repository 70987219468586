import { flatMap, get, isNil, isPlainObject, omit, pick } from "lodash";

const OVERRIDE = "override";

const extractObjectPaths = (
  obj: object,
  useAllAggregationOptions?: boolean,
  prefix = ""
): string[] => {
  return flatMap(obj, (value, key) => {
    const fullPath = prefix ? `${prefix}.${key}` : key;
    const overrideValue = useAllAggregationOptions
      ? undefined
      : get(value, OVERRIDE);
    const fieldKey = get(value, "fieldKey");
    if (isPlainObject(value) && isNil(overrideValue) && isNil(fieldKey)) {
      return extractObjectPaths(value, useAllAggregationOptions, fullPath);
    }
    return fullPath;
  });
};

export const getObjectPaths = <T extends object>(config: {
  obj: T;
  options?: {
    fieldsToOmit?: string[];
    fieldsToPick?: string[];
    useAllAggregationOptions?: boolean;
  };
}) => {
  const { obj, options } = config;

  const pickedObj = options?.fieldsToPick
    ? pick(obj, options.fieldsToPick)
    : obj;
  const omittedObj = options?.fieldsToOmit
    ? omit(pickedObj, options.fieldsToOmit)
    : pickedObj;
  const paths = extractObjectPaths(
    omittedObj,
    options?.useAllAggregationOptions
  );

  return paths;
};
