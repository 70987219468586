import { Party, PartyRelation, PartyType } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { PartySliceState } from "../../redux/v2/party/party.slice";
import { emptyAction } from "../tree/TreeUtils";
import { ElphiNode, NodeAction } from "../tree/types/Tree.types";

export const nameLogic = (selectedParty: Party | undefined | "" | 0 | null) =>
  selectedParty
    ? selectedParty?.PartyType === PartyType.Individual
      ? `${selectedParty.FirstName} ${selectedParty.LastName}`
      : selectedParty.FullName
    : "";
export const partyTypeLogic = (
  selectedParty: Party | undefined | "" | 0 | null
) => (selectedParty ? selectedParty?.PartyType : "");

export const partySubLabel = (
  selectedParty: Party | undefined | "" | 0 | null,
  partyRelation?: PartyRelation
) =>
  selectedParty
    ? selectedParty?.PartyType +
      " | " +
      ownerShipPrint(partyRelation) +
      " | " +
      rolesPrint(partyRelation)
    : "";
export const ownerShipPrint = (partyRelation: PartyRelation | undefined) => {
  return partyRelation && !!partyRelation.ownershipPercentage
    ? partyRelation.ownershipPercentage + "%"
    : "n/a";
};
export const rolesPrint = (partyRelation: PartyRelation | undefined) => {
  return partyRelation && partyRelation?.relationRoleType?.length > 0
    ? partyRelation.relationRoleType.join(",")
    : "n/a";
};
export const transformDataToNode = (
  data: Party,
  label: string,
  sublabel: string,
  uniqueKey: string,
  nodeActions?: NodeAction<Party>[]
): ElphiNode<Party> => ({
  id: data.id,
  data,
  children: [],
  label,
  sublabel,
  nodeKey: uniqueKey,
  nodeActions: nodeActions || []
});

export const AddPartyNodeButton = () => {};
export const generateTree = (
  party: Party | null | undefined,
  partyState: PartySliceState,
  partyRelationState: EntityState<PartyRelation>,
  nodeActions?: { addNode: NodeAction<Party>; removeNode: NodeAction<Party> }
) => {
  const rootPartyNode =
    party &&
    transformDataToNode(
      party,
      nameLogic(party),
      partyTypeLogic(party),
      `$_${party.id}`,
      nodeActions
        ? party.PartyType === PartyType.Individual
          ? [emptyAction]
          : [nodeActions.addNode]
        : undefined
    );
  const queue = [rootPartyNode];
  const previouslyGeneratedRelations: string[] = [];
  while (queue.length !== 0) {
    const currentParty = queue.pop();
    const childrenRelations =
      Object.values(partyRelationState?.entities)?.filter?.(
        (child) =>
          currentParty &&
          child?.parentId === currentParty.id &&
          !(
            child &&
            child.id &&
            previouslyGeneratedRelations.includes(child.id)
          )
      ) ?? [];
    childrenRelations.forEach((relation) => {
      relation && relation.id && previouslyGeneratedRelations.push(relation.id);
      const childParty = relation && partyState.entities[relation?.childId];
      const childNode =
        childParty &&
        transformDataToNode(
          childParty,
          nameLogic(childParty),
          partySubLabel(childParty, relation),
          relation.id,
          nodeActions
            ? childParty.PartyType === PartyType.Individual
              ? [nodeActions.removeNode]
              : [nodeActions.addNode, nodeActions.removeNode]
            : undefined
        );
      currentParty &&
        currentParty.children &&
        childNode &&
        currentParty.children.push(childNode);
      childParty && childNode && queue.push(childNode);
    });
  }
  return rootPartyNode;
};
