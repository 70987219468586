import { TaxpayerIdentifierType } from "@elphi/types";
import {
  einInputFormatter,
  ssnInputFormatter
} from "../form-builder/formatters/inputs.formatter";
import { einValidation, ssnValidation } from "./validationUtils";

export const getTaxIdentifierValidator = (identifierType?: string) => {
  if (!identifierType) {
    return undefined;
  }

  switch (identifierType) {
    case TaxpayerIdentifierType.SSN:
    case TaxpayerIdentifierType.TIN:
      return ssnValidation;
    case TaxpayerIdentifierType.EIN:
      return einValidation;
    default:
      return undefined;
  }
};

export const getTaxIdentifierFormatter = (identifierType?: string) => {
  if (!identifierType) {
    return undefined;
  }

  switch (identifierType) {
    case TaxpayerIdentifierType.SSN:
    case TaxpayerIdentifierType.TIN:
      return ssnInputFormatter;
    case TaxpayerIdentifierType.EIN:
      return einInputFormatter;
    default:
      return undefined;
  }
};
