import { BaseEntity, FirebaseFilter, OmitCreate } from "@elphi/types";
import { OmitUpdate } from "@elphi/types/services/service.types";
import { EntityAdapter, EntityState } from "@reduxjs/toolkit";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

export type Builder = EndpointBuilder<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
  any,
  string
>;
export const crudEndpoints =
  <D extends BaseEntity<object>>(req: { entityAdapter: EntityAdapter<D> }) =>
  (builder: Builder) => ({
    get: builder.query<D, string>({
      query: (id) => {
        return {
          url: `/${id}`,
          method: "GET"
        };
      }
    }),
    create: builder.mutation<{ id: string }, OmitCreate<D> | Partial<D>>({
      query: (r) => {
        return {
          url: `/`,
          method: "POST",
          body: r
        };
      }
    }),
    update: builder.mutation<
      { id: string },
      OmitUpdate<D> | ({ id: string } & Partial<D>)
    >({
      query: (r) => {
        return {
          url: `/${r.id}`,
          method: "PUT",
          body: r
        };
      }
    }),
    systemUpdate: builder.mutation<{ id: string }, { id: string } & Partial<D>>(
      {
        query: (r) => {
          return {
            url: `/system-update/${r.id}`,
            method: "PUT",
            body: r
          };
        }
      }
    ),
    delete: builder.mutation<{ id: string }, string>({
      query: (id) => {
        return {
          url: `/${id}`,
          method: "DELETE"
        };
      }
    }),
    paginate: builder.query<
      EntityState<D> & {
        nextCursor: string;
      },
      {
        cursor?: string;
        limit: number;
        options?: {
          order?: "desc" | "asc";
          filter?: FirebaseFilter | FirebaseFilter[];
        };
      }
    >({
      query: (r) => {
        return {
          url: `/paginate`,
          method: "POST",
          body: r
        };
      },
      transformResponse: (response: { page: D[]; nextCursor: string }) => {
        const adapter = req.entityAdapter.addMany(
          req.entityAdapter.getInitialState(),
          response.page
        );
        return {
          ...adapter,
          nextCursor: response.nextCursor
        };
      }
    })
  });
