import { Box } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import StyledInputBuilder, {
  valueContainerBySize
} from "../form-builder/InputBuilder";
import { FieldType } from "../form-builder/fieldFormat.types";
import ModalContainer from "../modal-container/ModalContainer";
import { isMobileDevice } from "../utils/mobileUtils";

const DealUserAssignment = (props: {
  state: { relations: string[] };
  onChange: any;
  hasMore: boolean | undefined;
  fetchMore: (() => void) | undefined;
  dealId: string;
  loanIdentifier?: string;
  isDisabled?: boolean;
  size: "s" | "m" | "l";
}) => {
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const losUserState = useSelector((state: RootState) => state.losUser);

  const maxW = isMobileDevice() ? "310px" : "900px";

  const options = useMemo(() => {
    return losUserState.ids
      .map((userId) => {
        return {
          label: `${losUserState?.entities?.[userId]?.name || ""} (${
            losUserState?.entities?.[userId]?.email || ""
          })`,
          value: userId as string
        };
      })
      .filter((option) => !!option.label);
  }, [losUserState]);

  const onChangeBatch = (v) => {
    props.onChange({
      fieldType: FieldType.MultiSelect,
      fieldKey: ["relations"],
      value: v.target.value
    });
  };

  const onClean = () => {
    setShowRemoveUserModal(true);
  };

  const onCloseRemoveUserModal = () => {
    setShowRemoveUserModal(false);
  };

  const onConfirmRemoveUserModal = () => {
    onChangeBatch({ target: { value: [] } });
  };

  const currentState = useMemo(() => {
    const newRelations = props.state.relations.map((userId) => {
      return userId as string;
    });
    return newRelations;
  }, [props.state, losUserState]);

  return (
    <>
      <Box maxW={maxW}>
        <StyledInputBuilder
          isDisabled={props.isDisabled}
          currentValue={currentState}
          onChange={onChangeBatch}
          onClear={onClean}
          options={options}
          fieldType={FieldType.MultiSelect}
          hasMore={props.hasMore}
          fetchMore={props.fetchMore}
          label={"search users"}
          isValid={true}
          customComponent={{
            ValueContainer: valueContainerBySize[props.size || "m"]
          }}
          chakraStyles={{
            dropdownIndicator: (prev, { selectProps: _ }) => ({
              ...prev,
              w: "20px",
              p: "0px"
            })
          }}
          hideSelectedOptions={false}
        />
      </Box>
      <ModalContainer
        isShow={showRemoveUserModal}
        onCloseModal={onCloseRemoveUserModal}
        header={`Removing User Assignment - Loan #${props?.loanIdentifier}`}
        body={`Are you sure you want to remove all users assigned to loan #${props?.loanIdentifier}`}
        submit={{
          onConfirm: onConfirmRemoveUserModal,
          confirmTitle: "Yes, Remove All Users"
        }}
      />
    </>
  );
};

export default DealUserAssignment;
