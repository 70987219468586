import { Box, Button, Tooltip } from "@chakra-ui/react";
import {
  AggregationFocusType,
  AggregationType,
  FieldStatus,
  FieldType
} from "@elphi/types";
import lodash from "lodash";

import { OnChangeInput } from "./FormBuilder";

export type AttachedComponentProps<T extends object> = {
  state: T;
  prefix: string[];
  fieldPath: string[];
  onChange?: (v: OnChangeInput) => void;
  isDisabled?: boolean;
};

export const defaultAggregationMapByType: {
  [P in AggregationType]: AggregationFocusType;
} = {
  [AggregationType.Aggregation]: AggregationFocusType.Calculated,
  [AggregationType.ThirdParty]: AggregationFocusType.ThirdParty,
  [AggregationType.ThirdPartyAggregation]: AggregationFocusType.ThirdParty
};
export const AttachedAggregationFieldComponet = <T extends object>(
  props: AttachedComponentProps<T> & { aggregationType: AggregationType }
) => {
  const focused = lodash.get(
    props.state,
    [...props.prefix, ...props.fieldPath, "focused"],
    defaultAggregationMapByType[props.aggregationType]
  );
  const focusValue = {
    [AggregationType.Aggregation]:
      focused === "override" ? "calculated" : "override",
    [AggregationType.ThirdParty]:
      focused === "override" ? "thirdParty" : "override",
    [AggregationType.ThirdPartyAggregation]:
      focused === "override"
        ? "thirdParty"
        : focused === "calculated"
        ? "override"
        : "calculated"
  };
  const focusText = {
    [AggregationType.Aggregation]:
      focused === "override" ? "override" : "calculated",
    [AggregationType.ThirdParty]:
      focused === "override" ? "override" : "3rd party",
    [AggregationType.ThirdPartyAggregation]:
      focused === "override"
        ? "override"
        : focused === "calculated"
        ? "calculated"
        : "3rd party"
  };
  const nextClickFocusText = {
    [AggregationType.Aggregation]:
      focused === "override" ? "calculated" : "override",
    [AggregationType.ThirdParty]:
      focused === "override" ? "3rd party" : "override",
    [AggregationType.ThirdPartyAggregation]:
      focused === "override"
        ? "3rd party"
        : focused === "calculated"
        ? "override"
        : "calculated"
  };
  const status = lodash.get(props.state, [
    ...props.prefix,
    "fieldMeta",
    ...props.fieldPath,
    "status"
  ]);
  return (
    <Box pt="8px" pl="4px" pr="4px">
      <Tooltip
        aria-label="aggregation-focused-btn"
        label={
          status === FieldStatus.Approved
            ? "field is valid"
            : `click to ${nextClickFocusText[props.aggregationType]}`
        }
      >
        <Button
          size="xs"
          float={"right"}
          boxShadow={"md"}
          _hover={{}}
          minW="70px"
          isDisabled={status === FieldStatus.Approved || props.isDisabled}
          color={
            status === FieldStatus.Approved
              ? "black"
              : ["override", "thirdParty"].includes(focused)
              ? "white"
              : "black"
          }
          borderRadius={"2px"}
          bgColor={
            status === FieldStatus.Approved
              ? "green.200"
              : focused === "override"
              ? "black"
              : focused === "thirdParty"
              ? "pink.500"
              : focused === "calculated"
              ? "gray.200"
              : "pink.500"
          }
          onClick={() => {
            props.onChange &&
              props.onChange({
                fieldKey: [...props.prefix, ...props.fieldPath, "focused"],
                fieldType: FieldType.String,
                value: focusValue[props.aggregationType]
              });
          }}
        >
          {focusText[props.aggregationType]}
        </Button>
      </Tooltip>
    </Box>
  );
};
