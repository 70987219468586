import { PartyValidationConfiguration } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";

export const partyValidationConfigApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "partyValidationConfigApi",
  baseQuery: serviceBuilder.baseQuery({
    route: "party-validation-configs"
  }),
  endpoints: (builder) => ({
    get: builder.query<PartyValidationConfiguration, void>({
      query: () => {
        return {
          url: `/`,
          method: "GET"
        };
      }
    }),
    update: builder.mutation<
      { id: string },
      Partial<PartyValidationConfiguration>
    >({
      query: (r) => {
        return {
          url: `/`,
          method: "PUT",
          body: r
        };
      }
    })
  })
});
