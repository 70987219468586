import {
  dateValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const vendorName = buildInput({
  fieldKey: baseElphiFieldsSpecs.task?.entitySpecs.vendorName?.fieldKey,
  validation: stringValidation
});

export const vendorInformation = buildInput({
  fieldKey: baseElphiFieldsSpecs.task?.entitySpecs.vendorInformation?.fieldKey,
  validation: stringValidation
});

export const receivedDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.task?.entitySpecs.receivedDate?.fieldKey,
  validation: dateValidation
});

export const dueDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.task?.entitySpecs.dueDate?.fieldKey,
  validation: dateValidation
});

export const orderedDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.task?.entitySpecs.orderedDate?.fieldKey,
  validation: dateValidation
});

export const orderedFrom = buildInput({
  fieldKey: baseElphiFieldsSpecs.task?.entitySpecs.orderedFrom?.fieldKey,
  validation: stringValidation
});

export const orderStatus = buildInput({
  fieldKey: baseElphiFieldsSpecs.task?.entitySpecs.orderStatus?.fieldKey,
  validation: stringValidation
});
