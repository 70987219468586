import { TaskConfiguration } from "@elphi/types";
import { ArgumentTypes } from "@elphi/types/utils/arguments";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RootState, store } from "../redux/store";
import { taskConfigurationApi } from "../redux/v2/task-configuration/taskConfiguration.service";
import { taskConfigurationSlice } from "../redux/v2/task-configuration/taskConfiguration.slice";
import { CallBackActions } from "../redux/v2/types/action.types";
import { compareStrings } from "../utils/searchUtils";

export const useTaskConfigurationHooks = () => {
  const dispatcher = useDispatch();
  const [updateBatchApi, updateBatchResponse] =
    taskConfigurationApi.useBatchUpdateMutation();
  const [deleteConfigurationApi, deleteConfigurationResponse] =
    taskConfigurationApi.useDeleteMutation();
  const [getBatchApi, getBatchResponse] =
    taskConfigurationApi.useLazyGetBatchQuery();
  const deleteConfiguration = async (id: string) => {
    return await deleteConfigurationApi(id).then(responseHandler);
  };
  const updateBatch = async (r: {
    tasks: ({ id: string } & Partial<TaskConfiguration>)[];
  }) => {
    return await updateBatchApi(r).then(responseHandler);
  };
  const setSelectedTaskConfiguration = (id: string) =>
    store.dispatch(taskConfigurationSlice.actions.selectedId({ id }));

  const selectedTaskConfiguration = useSelector(
    (state: RootState) =>
      state.taskConfiguration.selectedId &&
      state.taskConfiguration.entities[state.taskConfiguration.selectedId]
  );
  const taskConfigurationState = useSelector(
    (state: RootState) => state.taskConfiguration
  );
  const [searchTaskConfigurationApi, searchResponse] =
    taskConfigurationApi.useLazySearchQuery();

  const taskConfigurationTableFilter = useSelector(
    (state: RootState) => state.taskConfiguration.taskConfigurationTable?.filter
  );

  const setTaskConfigurationTableFilter = (
    r: ArgumentTypes<
      typeof taskConfigurationSlice.actions.setTaskConfigurationTableFilter
    >[0]
  ) =>
    dispatcher(
      taskConfigurationSlice.actions.setTaskConfigurationTableFilter(r)
    );

  const rankedSort = (query: string) => {
    return Object.values(taskConfigurationState.entities).sort((a, b) => {
      const rankA =
        compareStrings(query, a?.name || "") * 50 +
        compareStrings(query, a?.taskEntity || "") * 15 +
        compareStrings(query, a?.keyClosingTaskType || "") * 10;

      const rankB =
        compareStrings(query, b?.name || "") * 50 +
        compareStrings(query, b?.taskEntity || "") * 15 +
        compareStrings(query, b?.keyClosingTaskType || "") * 10;

      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const taskConfigurationCallback: CallBackActions<TaskConfiguration> = {
    remove: (_) => {
      setSelectedTaskConfiguration("");
    }
  };

  return {
    taskConfigurationCallback,
    deleteConfigurationResponse,
    deleteConfiguration,
    taskConfigurationState,
    searchTaskConfigurationApi,
    searchResponse,
    rankedSort,
    selectedTaskConfiguration,
    setSelectedTaskConfiguration,
    updateBatch,
    updateBatchResponse,
    getBatchApi,
    getBatchResponse,
    taskConfigurationTableFilter,
    setTaskConfigurationTableFilter
  };
};
