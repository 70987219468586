import { Grid } from "@chakra-ui/react";
import { ElphiTrxStatus } from "@elphi/types";
import { useMemo } from "react";
import { IntegrationTaskBoardProps } from "../integrationCenter.types";
import { TaskStatusColumn } from "./BoardColumn";

export const IntegrationBoard = (props: IntegrationTaskBoardProps) => {
  const { taskCards, filteredCards, selectedTaskId, setSelected, isLoading } =
    props;

  const taskCardsByStatus = useMemo(() => {
    return {
      [ElphiTrxStatus.Pending]: taskCards.filter(
        (x) => !x.elphiStatus || x.elphiStatus === ElphiTrxStatus.Pending
      ),
      [ElphiTrxStatus.InProcess]: taskCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.InProcess
      ),
      [ElphiTrxStatus.Success]: taskCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.Success
      ),
      [ElphiTrxStatus.Failed]: taskCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.Failed
      )
    };
  }, [taskCards]);

  const filteredCardsByStatus = useMemo(() => {
    return {
      [ElphiTrxStatus.Pending]: filteredCards.filter(
        (x) => !x.elphiStatus || x.elphiStatus === ElphiTrxStatus.Pending
      ),
      [ElphiTrxStatus.InProcess]: filteredCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.InProcess
      ),
      [ElphiTrxStatus.Success]: filteredCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.Success
      ),
      [ElphiTrxStatus.Failed]: filteredCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.Failed
      )
    };
  }, [filteredCards]);

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Object.entries(taskCardsByStatus).map(([status, cards]) => {
        const key = status as ElphiTrxStatus;
        return (
          <TaskStatusColumn
            key={key}
            elphiTrxStatus={key}
            taskCards={filteredCardsByStatus[key]}
            setSelected={setSelected}
            selectedTask={selectedTaskId}
            totalCards={cards.length}
            isLoading={isLoading}
          />
        );
      })}
    </Grid>
  );
};
