import { Box, Checkbox, Divider, Flex, Text } from "@chakra-ui/react";
import { FieldType, PartyType, PosUser, StatusCode } from "@elphi/types";
import { useState } from "react";
import { EMPTY } from "../../../constants/common";
import { usePosDealUserPartyHooks } from "../../../hooks/posDealUserParty.hooks";
import { usePosUserHooks } from "../../../hooks/posUser.hooks";
import { DealPartySearch } from "../../deal/DealPartySearch";
import FormBuilder, { OnChangeInput } from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import ModalContainer from "../../modal-container/ModalContainer";
import { PosUserSearch } from "../search/UserSearch";

type FormRequest = {
  firstName: string;
  lastName: string;
  email: string;
};

const initialState: FormRequest = {
  firstName: EMPTY,
  lastName: EMPTY,
  email: EMPTY
};

const defaultOption = {
  value: "newPosUser",
  label: "+ Add new POS user"
};

export const InvitePosUserModal = (props: {
  show: boolean;
  onClose: () => void;
  dealId: string;
}) => {
  const { handleInviteUser, inviteResponse, dealUserPartyViewState } =
    usePosDealUserPartyHooks();
  const { handleGetUserByPartyId, posUserState } = usePosUserHooks();
  const [selectedPartyId, setSelectedPartyId] = useState<string>(EMPTY);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>(EMPTY);
  const [openNewForm, setOpenNewForm] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [publish, setPublish] = useState<boolean>(false);
  const { onChange, state, setState } = useFormBuilderStateHandler<FormRequest>(
    {
      initialState: { ...initialState }
    }
  );

  const handleOnSubmit = () => {
    const request = buildSubmitRequest();
    if (!request) return;
    handleInviteUser(request);
  };

  const buildSubmitRequest = () => {
    if (!selectedPartyId) {
      return;
    }
    return openNewForm
      ? {
          dealId: props.dealId,
          partyId: selectedPartyId,
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          publish
        }
      : {
          dealId: props.dealId,
          partyId: selectedPartyId,
          email: selectedUserEmail,
          publish
        };
  };

  const handleOnClose = () => {
    setSelectedPartyId(EMPTY);
    resetUserForm();
    props.onClose();
  };

  const resetUserForm = () => {
    setSelectedUserEmail(EMPTY);
    setState({ ...initialState });
    setOpenNewForm(false);
    setIsReadOnly(false);
  };

  const handleOnSelectPartyId = (partyId: string) => {
    resetUserForm();
    setSelectedPartyId(partyId);
    const user = findPartyUser(partyId);
    if (user) {
      handleOnSelectUser(user);
      return;
    }
    handleGetUserByPartyId(partyId).then((res) => {
      if (!res) return;
      if (res?.status === StatusCode.OK && res?.data?.result) {
        handleOnSelectUser(res.data.result);
      }
    });
  };

  const findPartyUser = (partyId: string) => {
    const partyUser = Object.values(
      dealUserPartyViewState?.entities || {}
    ).find((x) => x?.partyIds?.includes(partyId));
    return posUserState.entities[partyUser?.userId || EMPTY];
  };

  const handleOnSelectUser = (user: PosUser) => {
    setIsReadOnly(true);
    setSelectedUserEmail(user.email);
    setState({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    });
    setOpenNewForm(true);
  };

  const handleOnSelectUserEmail = (email: string) => {
    const isNewUser = email === defaultOption.value;
    isNewUser ? setState({ ...initialState }) : setSelectedUserEmail(email);
    setOpenNewForm(isNewUser);
  };

  const isDisabled =
    (openNewForm
      ? !state.email || !state.firstName || !state.lastName
      : !selectedUserEmail) || !selectedPartyId;

  return (
    <ModalContainer
      header={"Add a New User"}
      isShow={props.show}
      onCloseModal={handleOnClose}
      maxWidth={800}
      body={
        <Flex direction={"column"}>
          <Box mb={4}>
            <Text>
              If the desired Individual party is not listed, ensure a Deal-Party
              role is added first via the Parties tab.
            </Text>
          </Box>
          <DealPartySearch
            entityTypes={[PartyType.Individual]}
            fieldType={FieldType.SingleSelect}
            currentValue={selectedPartyId}
            onSelect={handleOnSelectPartyId}
            label={"Select Individual Party"}
            {...props}
          />
          <Divider my={4} />
          <Text mb={4}>
            Please assign a POS user to the selected Individual party:
          </Text>
          <PosUserSearch
            fieldType={FieldType.SingleSelect}
            currentValue={selectedUserEmail}
            onSelect={handleOnSelectUserEmail}
            defaultOption={defaultOption}
            isReadOnly={isReadOnly}
          />
          {openNewForm && (
            <NewPosUserForm
              state={state}
              onChange={onChange}
              isReadOnly={isReadOnly}
            />
          )}
          <Divider my={4} />
          <Checkbox
            onChange={(e) => {
              setPublish(e.target.checked);
            }}
          >
            <Box>
              <Text>
                Publish the deal and associated tasks to the POS user.
              </Text>
              <Text color={"gray.500"}>
                (Optional: You can also publish them after confirmation.)
              </Text>
            </Box>
          </Checkbox>
        </Flex>
      }
      submit={{
        showClose: true,
        closeTitle: "Close",
        onConfirm: handleOnSubmit,
        isLoading: inviteResponse.isLoading,
        isDisabled
      }}
    />
  );
};

const NewPosUserForm = (props: {
  state: FormRequest;
  onChange: (v: OnChangeInput) => void;
  isReadOnly: boolean;
}) => {
  const { onChange, state, isReadOnly } = props;
  return (
    <Flex direction={"column"}>
      <Box m={"-10px"} mt={2}>
        <FormBuilder
          elphiView={"form"}
          customKey={"newPosUserForm"}
          onChange={onChange}
          sections={[
            {
              inputs: [
                {
                  label: "First Name",
                  labelPosition: "up",
                  fieldType: FieldType.String,
                  fieldKey: ["firstName"],
                  currentValue: state.firstName,
                  isRequired: true,
                  isReadOnly
                },
                {
                  label: "Last Name",
                  labelPosition: "up",
                  fieldType: FieldType.String,
                  fieldKey: ["lastName"],
                  currentValue: state.lastName,
                  isRequired: true,
                  isReadOnly
                },
                {
                  label: "Email",
                  labelPosition: "up",
                  fieldType: FieldType.Email,
                  fieldKey: ["email"],
                  currentValue: state.email,
                  isRequired: true,
                  isReadOnly
                }
              ]
            }
          ]}
          size={{
            minW: "222px"
          }}
        />
      </Box>
      <Box color={"red.500"} mt={4}>
        <Text>Please verify the email address.</Text>
        <Text>
          Confirming sends an email invite unless the POS user already has
          access to the borrower portal.
        </Text>
      </Box>
    </Flex>
  );
};
