import { StatsigEnv } from "@elphi/utils";

// Environment configuration
export enum ElphiDomain {
  Localhost = "localhost",
  Demo = "client-demo.elphimortgage.com",
  ElphiTest = "elphi-los-test.elphimortgage.com",
  LimaTest = "limaone-los-test.elphimortgage.com",
  LimaPoc = "limaone-los-poc.elphimortgage.com",
  LimaProd = "limaone-los.elphimortgage.com",
  BabarTest = "los-babar.test.elphimortgage.com",
  EdomonTest = "los-edomon.test.elphimortgage.com",
  ElamiaTest = "los-elamia.test.elphimortgage.com",
  MofinTest = "mofin-los-test.elphimortgage.com",
  MofinProd = "mofin-los.elphimortgage.com",
  LedgerTCTest = "ledgertc-los-test.elphimortgage.com",
  LedgerTCProd = "ledgertc-los.elphimortgage.com"
}
export type LogRocketDomains =
  | ElphiDomain.LimaProd
  | ElphiDomain.LimaPoc
  | ElphiDomain.Demo
  | ElphiDomain.MofinProd
  | ElphiDomain.LedgerTCProd;

export const envMap: Record<ElphiDomain, StatsigEnv> = {
  [ElphiDomain.Localhost]: StatsigEnv.Localhost,
  [ElphiDomain.ElphiTest]: StatsigEnv.ElphiTest,
  [ElphiDomain.LimaTest]: StatsigEnv.LimaTest,
  [ElphiDomain.Demo]: StatsigEnv.Demo,
  [ElphiDomain.LimaPoc]: StatsigEnv.LimaPoc,
  [ElphiDomain.LimaProd]: StatsigEnv.LimaProd,
  [ElphiDomain.BabarTest]: StatsigEnv.BabarTest,
  [ElphiDomain.EdomonTest]: StatsigEnv.EdomonTest,
  [ElphiDomain.ElamiaTest]: StatsigEnv.ElamiaTest,
  [ElphiDomain.MofinTest]: StatsigEnv.MofinTest,
  [ElphiDomain.MofinProd]: StatsigEnv.MofinProd,
  [ElphiDomain.LedgerTCTest]: StatsigEnv.LedgerTCTest,
  [ElphiDomain.LedgerTCProd]: StatsigEnv.LedgerTCProd
};

////////////////
//
//
//  TODO: move to env_react_baseUrl from process.env
//
//
/**
 *
 * @param orgId
 * @param prefix
 * @returns the base url with the following format http/s://url.com/prefix/orgId
 * example
 * getBaseUrl("12","api/v1/")
 * returns domain.com/api/v1/12
 */

const baseApiUrls = {
  [ElphiDomain.Localhost]: "http://localhost:3000",
  [ElphiDomain.ElphiTest]: "https://api-elphi-los-test.elphimortgage.com",
  [ElphiDomain.Demo]: "https://api-client-demo.elphimortgage.com",
  [ElphiDomain.LimaTest]: "https://api-limaone-los-test.elphimortgage.com",
  [ElphiDomain.LimaPoc]: "https://api-limaone-los-poc.elphimortgage.com",
  [ElphiDomain.LimaProd]: "https://api-limaone-los.elphimortgage.com",
  [ElphiDomain.BabarTest]: "https://api-babar.test.elphimortgage.com",
  [ElphiDomain.MofinTest]: "https://api-mofin-los-test.elphimortgage.com",
  [ElphiDomain.MofinProd]: "https://api-mofin-los.elphimortgage.com",
  [ElphiDomain.LedgerTCTest]: "https://api-ledgertc-los-test.elphimortgage.com",
  [ElphiDomain.LedgerTCProd]: "https://api-ledgertc-los.elphimortgage.com"
};

export const getBaseUrl = (orgId: string, prefix?: string) => {
  const routePrefix = orgId ? `${orgId}` : "";
  const serviceVersion = prefix || "";
  const host = window.location.hostname as ElphiDomain;
  const baseUrl = baseApiUrls[host] || process.env.REACT_APP_API_URL;
  if (!baseUrl) {
    throw `host: ${host} not configured`;
  }
  return `${baseUrl}/${serviceVersion}${routePrefix}`;
};

export const isProd = () => {
  if (process.env.REACT_APP_IS_PROD) {
    return process.env.REACT_APP_IS_PROD;
  }
  return window.location.hostname === ElphiDomain.LimaProd;
};
export const getBoxStorageUrl = () => {
  switch (window.location.hostname) {
    case ElphiDomain.Localhost:
    case ElphiDomain.Demo:
      return "https://elphil1c.app.box.com";
    case ElphiDomain.LimaTest:
    case ElphiDomain.LimaPoc:
      return "https://elphil1c.app.box.com";
    case ElphiDomain.LimaProd:
      return "https://limaonecapital.app.box.com";
    default:
      if (process.env.REACT_APP_BOX_URL) {
        return process.env.REACT_APP_BOX_URL;
      }
      throw `host: ${window.location.hostname} not configured`;
  }
};

export const getFirebaseConfig = () => {
  switch (window.location.hostname) {
    case ElphiDomain.Demo:
      return {
        apiKey: "AIzaSyCB5cvEQA7zvAey3wQMhMKMTHkcNqoI3Dc",
        authDomain: "client-demo-416211.firebaseapp.com",
        projectId: "client-demo-416211",
        storageBucket: "client-demo-416211.appspot.com",
        messagingSenderId: "784050144485",
        appId: "1:784050144485:web:cfe8270c94e4d46c32800c"
      };

    case ElphiDomain.Localhost:
    case ElphiDomain.LimaTest:
      if (process.env.IS_DEMO) {
        return {
          apiKey: "AIzaSyCB5cvEQA7zvAey3wQMhMKMTHkcNqoI3Dc",
          authDomain: "client-demo-416211.firebaseapp.com",
          projectId: "client-demo-416211",
          storageBucket: "client-demo-416211.appspot.com",
          messagingSenderId: "784050144485",
          appId: "1:784050144485:web:cfe8270c94e4d46c32800c"
        };
      }
      return {
        apiKey: "AIzaSyBEv6TwEATQxGMKApfh42ehYuuZupswTV8",
        authDomain: "lima-one-test.firebaseapp.com",
        projectId: "lima-one-test",
        storageBucket: "lima-one-test.appspot.com",
        messagingSenderId: "91927893941",
        appId: "1:91927893941:web:a8ddc13ffc23f4e5cbabfc",
        measurementId: "G-BLTJ3DMSHS"
      };
    case ElphiDomain.LimaPoc:
      return {
        apiKey: "AIzaSyDBtjKVbjLwA61KPrUWujMD2oyQ6Ht_uMc",
        authDomain: "lima-one-poc.firebaseapp.com",
        projectId: "lima-one-poc",
        storageBucket: "lima-one-poc.appspot.com",
        messagingSenderId: "599635795614",
        appId: "1:599635795614:web:9adfd78c9d59d45883a58f",
        measurementId: "G-7B0N4FW1N6"
      };
    case ElphiDomain.LimaProd:
      return {
        apiKey: "AIzaSyCOFg2zatYYpJMw-hyT1T3-jtAe8kcnlDQ",
        authDomain: "lima-one-prod.firebaseapp.com",
        projectId: "lima-one-prod",
        storageBucket: "lima-one-prod.appspot.com",
        messagingSenderId: "763661855152",
        appId: "1:763661855152:web:714ac3d85450d876028e75",
        measurementId: "G-3V7810H6H7"
      };
    case ElphiDomain.BabarTest:
      return {
        apiKey: "AIzaSyCmhYkZYVDeAl9JnX77bz8I0gNPrilBcTg",
        authDomain: "test-babar.firebaseapp.com",
        projectId: "test-babar",
        storageBucket: "test-babar.appspot.com",
        messagingSenderId: "715371505395",
        appId: "1:715371505395:web:04eea2e88296ad512a3986"
      };
    default:
      if (process.env.REACT_APP_FB_API_KEY) {
        return {
          apiKey: process.env.REACT_APP_FB_API_KEY,
          authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
          projectId: process.env.REACT_APP_FB_PROJECT_ID,
          storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
          appId: process.env.REACT_APP_FB_APP_ID
        };
      }
      throw `host: ${window.location.hostname} not configured`;
  }
};

export const getUsersnapConfig = () => {
  switch (window.location.hostname) {
    case ElphiDomain.LimaTest:
      return {
        apiKey: "4847990d-5171-4714-bdcf-5f28bac1ddb7"
      };
    case ElphiDomain.LimaPoc:
      return {
        apiKey: "c4aa6cc4-4275-4350-9e37-5cd329d5fe9b"
      };
    case ElphiDomain.LimaProd:
      return {
        apiKey: "6f02c520-4cae-4694-928a-ee51f9ffffc3"
      };
    default:
      if (process.env.REACT_APP_USER_SNAP) {
        return { apiKey: process.env.REACT_APP_USER_SNAP };
      }
      return undefined;
  }
};

export const getOktaUrl = () => {
  switch (window.location.hostname) {
    case ElphiDomain.Localhost:
    case ElphiDomain.LimaTest:
      return "https://elphi.okta.com/home/oidc_client/0oa15ygz73USuQFPz697/aln177a159h7Zf52X0g8";
    case ElphiDomain.LimaPoc:
      return "https://elphi.okta.com/home/oidc_client/0oa1kidn82TfD7sXE697/aln177a159h7Zf52X0g8";
    case ElphiDomain.LimaProd:
      return "https://limaone.okta.com/home/oidc_client/0oaqphr00gLHRjFiV357/aln177a159h7Zf52X0g8";
    default:
      if (process.env.REACT_APP_OKTA_URL) {
        return process.env.REACT_APP_OKTA_URL;
      }
      throw `host: ${window.location.hostname} not configured`;
  }
};

export const isLocal = () => window.location.hostname === ElphiDomain.Localhost;
