import { Asset, Statement } from "@elphi/types";
import { useSelector } from "react-redux";
import useStatementHooks from "../../hooks/statement.hooks";
import { RootState } from "../../redux/store";
import { SearchHandler } from "../search/SearchHandler";

import lodash from "lodash";
import { statementToString } from "../asset/utils/printUtils";
import { SearchComponentProps } from "../SearchComponent";

export enum statementQueryType {
  onlyAssetStatements = "ONLY_ASSET_STATEMENTS",
  noAssetStatements = "NO_ASSET_STATEMENTS"
}

export const StatementQuerySearch = (
  props: Pick<
    SearchComponentProps,
    "onSelect" | "currentValue" | "fieldType" | "labelPosition" | "label"
  > & {
    selectedAsset: Partial<Asset> | Partial<Asset>[];
    query: statementQueryType;
  }
) => {
  const statementSlice = useSelector((state: RootState) => state.statement);
  const { searchStatement, searchResponse, rankedSort } = useStatementHooks();

  const filter = (a: Statement) => {
    if (Array.isArray(props.selectedAsset)) {
      const possibleIdMap = lodash.flatten(
        props.selectedAsset.map((asset) => asset.statements)
      );
      if (props.query === statementQueryType.noAssetStatements)
        return !possibleIdMap.includes(a.id);
      return possibleIdMap.includes(a.id);
    }
    if (props.query === statementQueryType.noAssetStatements)
      return !props.selectedAsset.statements?.includes?.(a.id);
    return !!props.selectedAsset.statements?.includes?.(a.id);
  };

  const buildOption = (statement: Statement) => {
    const asset = Array.isArray(props.selectedAsset)
      ? lodash.find(
          props.selectedAsset,
          (asset: Asset) =>
            asset.statements && asset.statements.includes(statement.id)
        )
      : props.selectedAsset;
    return {
      label: asset
        ? statementToString({
            statement: statement,
            asset: asset
          })
        : "asset not found",
      value: statement!.id
    };
  };

  return (
    <SearchHandler
      {...props}
      filter={filter}
      searchApi={searchStatement}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={statementSlice}
      buildOption={buildOption}
    />
  );
};
