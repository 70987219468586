import { Box, Text } from "@chakra-ui/react";
import { EntityId } from "@reduxjs/toolkit";
import DealUserAssignmentContainer from "../los-user/DealUserAssignmentContainer";
import { UserTableContainer } from "../los-user/UserTable";

export const UserTabContainer = (props: {
  dealId: EntityId;
  snapshotId?: string;
  loanIdentifier?: string;
}) => {
  return props.snapshotId ? (
    <UserTabSnapshotContainer {...props} snapshotId={props.snapshotId} />
  ) : (
    <UserTabLiveStateContainer {...props} />
  );
};
export const UserTabSnapshotContainer = (props: {
  dealId: EntityId;
  snapshotId: string;
}) => {
  return (
    <Box>
      <UserTableContainer dealId={props.dealId} snapshotId={props.snapshotId} />
    </Box>
  );
};

const UserTabLiveStateContainer = (props: {
  dealId: EntityId;
  loanIdentifier?: string;
}) => {
  return (
    <Box>
      <Box m="10px">
        <Text fontWeight="bold">Assign new users:</Text>
        <DealUserAssignmentContainer
          dealId={props.dealId}
          loanIdentifier={props?.loanIdentifier}
        />
      </Box>
      <UserTableContainer dealId={props.dealId} />
    </Box>
  );
};
