import { CheckIcon, EmailIcon, PhoneIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  Tooltip,
  chakra
} from "@chakra-ui/react";

import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import {
  ActionMeta,
  ChakraStylesConfig,
  Select,
  chakraComponents
} from "chakra-react-select";
import lodash, { isFunction, isString } from "lodash";
import { Children, LegacyRef, ReactElement, useRef, useState } from "react";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { universalWhitefff } from "../../assets/redesign";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { DateRangePicker } from "../custom/date-picker/DateRangePicker";
import { MultiDatePicker } from "../custom/date-picker/MultiDatePicker";
import { isMobileDevice } from "../utils/mobileUtils";
import { OnChangeInput } from "./FormBuilder";
import { FieldType } from "./fieldFormat.types";
import { BaseInputFormatter } from "./formatters/formatter.types";
import { formatterMap } from "./formatters/inputs.formatter";

const ValidIcon = chakra(CheckIcon, {
  baseStyle: {
    color: elphiTheme.colors.light.notifications.success
  }
});
export type BaseInputBuilderProps<TType extends FieldType> = {
  label?: string;
  fieldType: TType;
  isDisabled?: boolean;
  formatter?: BaseInputFormatter<TType, any, any>;
  onChange: (e: {
    target: { value: any };
    actionMeta?: ActionMeta<any>;
  }) => void;
  /** On click clean('X') button - for now implemented only for FieldType MultiSelect */
  onClear?: ({ newInput, removedValues }: any) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onInputChange?: (newInput: any) => void;
  isValid?: boolean;
  isRequired?: boolean;
  shouldMarkEmpty?: boolean;
  customColor?: string;
  isReadOnly?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  currentValue: any;
  customFlexDir?: "column" | "row";
  forwardRef?: LegacyRef<HTMLDivElement>;
  shouldDisplayErrorMessage?: boolean;
  size?: {
    maxW: string;
  };
  responsiveSize?: "sm" | "md" | "lg";
};
export type InputBuilderProps = BaseInputBuilderProps<
  Exclude<
    FieldType,
    FieldType.SingleSelect | FieldType.MultiSelect | FieldType.RadioSelect
  >
>;

export type SelectInputBuilderProps = BaseInputBuilderProps<
  FieldType.SingleSelect | FieldType.MultiSelect
> & {
  options?: { label: string; value: string }[];
  filterOption?:
    | ((option: FilterOptionOption<any>, inputValue: string) => boolean)
    | null
    | undefined;
  hasMore?: boolean;
  fetchMore?: () => void;
  customComponent?: Partial<{
    //ValueContainer: typeof ValueContainer;
    [P in keyof typeof chakraComponents]: (props: any) => EmotionJSX.Element;
  }>; //Partial<typeof chakraComponents>;
  chakraStyles?: ChakraStylesConfig<{ label: string; value: string }>;
  hideSelectedOptions?: boolean;
  isSearchable?: boolean;
  isOptionDisabled?: boolean;
};
export type RadioInputBuilderProps =
  BaseInputBuilderProps<FieldType.RadioSelect> & {
    options?: { label: string; value: string }[];
  };

export type MultiCheckboxBuilderProps =
  BaseInputBuilderProps<FieldType.MultiCheckbox> & {
    options?: {
      label: string;
      value: string;
      isChecked?: boolean;
      icon?: ReactElement;
      isDisabled?: boolean;
      tooltipText?: string;
    }[];
  };

export type CheckboxBuilderProps =
  BaseInputBuilderProps<FieldType.SingleCheckbox> & {
    options?: {
      label: string | ReactElement;
      value: string;
      icon?: React.ReactElement;
    };
  };

//this is a hack for now, it does not actually protect the props union type but allow all union keys
type UnionKeys<T> = T extends any ? keyof T : never;
type StrictUnionHelper<T, TAll> = T extends any
  ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>>
  : never;
type StrictUnion<T> = StrictUnionHelper<T, T>;

export type StrictUnionInputBuilderProps = StrictUnion<
  | InputBuilderProps
  | SelectInputBuilderProps
  | RadioInputBuilderProps
  | CheckboxBuilderProps
  | MultiCheckboxBuilderProps
>;

const selectionStyles: SelectInputBuilderProps["chakraStyles"] = {
  // menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (base) => ({
    ...base
    // maxHeight: maxH,
    // overflow: "hide"
  }),
  container: (provided) => ({
    ...provided,
    background: "white",
    borderRadius: "8px"
  }),

  valueContainer: (provided, _) => ({
    ...provided,
    fontSize: "14px",
    borderRadius: "8px",
    fontWeight: "bold",
    p: "2px"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    p: "0px",
    w: "20px"
  }),
  menuList: (provided) => ({
    ...provided,
    minWidth: "fit-content"
  })
};

const InputBuilder = (props: StrictUnionInputBuilderProps): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  let inputLeft: JSX.Element | null = null;
  let input: JSX.Element;
  const formatter = props.formatter || formatterMap[props.fieldType];
  const commonFields = {
    isRequired: props.isRequired,
    isReadOnly: props.isReadOnly,
    isDisabled: props.isDisabled,
    onChange: (e: {
      target: {
        value: any;
      };
      actionMeta?: ActionMeta<any>;
    }) => {
      if (formatter) {
        e.target.value = formatter?.format?.decode(e.target.value);
      }
      props.onChange(e);
    },
    placeholder: props.label,
    value: formatter
      ? formatter?.format.encode(props?.currentValue ?? "")
      : props.currentValue || ""
  };
  const maxW = props.size
    ? props?.size?.maxW
    : isMobileDevice()
    ? "310px"
    : "500px";

  const selectionFieldStyles = { ...selectionStyles, ...props.chakraStyles };

  const onChangeSelect = (newValue, actionMeta): void => {
    if (actionMeta?.action === "clear" && isFunction(props?.onClear)) {
      const { removedValues } = actionMeta;
      props.onClear({ newValue, removedValues });
    } else {
      props.onChange({
        target: {
          value: isString(newValue) ? newValue : newValue.map((v) => v.value)
        },
        actionMeta
      });
    }
  };

  const onSelectSingleCheckBox = (e) => {
    let value = e?.target?.value;
    if (!e.target.checked) {
      value = "";
    }
    onChangeSelect(value, e);
  };

  switch (props.fieldType) {
    case FieldType.String:
      input = <Input type={"text"} {...commonFields} />;
      break;
    case FieldType.CreditScore:
      input = <Input type={"number"} {...commonFields} />;
      break;
    case FieldType.Integer:
    case FieldType.Number:
    case FieldType.Decimal:
    case FieldType.Year:
    case FieldType.FourDigit:
      input = <Input type={"text"} {...commonFields} />;
      break;
    case FieldType.Money:
    case FieldType.SignedMoney:
      inputLeft = (
        <InputLeftElement
          pointerEvents="none"
          color="gray.300"
          fontSize="1.2em"
          children="$"
        />
      );
      input = <Input type={"text"} {...commonFields} />;

      break;
    case FieldType.Percentage:
    case FieldType.PercentageThreeDecimal:
      inputLeft = (
        <InputLeftElement
          pointerEvents="none"
          color="gray.300"
          fontSize="1.2em"
          children="%"
        />
      );
      input = <Input {...commonFields} />;

      break;
    case FieldType.RichText:
      return (
        <Flex>
          <Box
            ref={props.forwardRef}
            minWidth={"100%"}
            maxW={maxW}
            borderRadius={"5px"}
            border={"1px"}
            borderColor={"gray.200"}
          >
            <Editable
              w="100%"
              value={props.currentValue || ""}
              placeholder={props.label}
              bgColor={props.customColor || universalWhitefff}
            >
              <EditablePreview lineHeight={"8"} padding="5px" w="100%" />
              <EditableTextarea
                w="100%"
                onChange={props.onChange}
                readOnly={props.isReadOnly}
                disabled={props.isDisabled}
                required={props.isRequired}
              />
            </Editable>
          </Box>
        </Flex>
      );

    case FieldType.Phone:
      inputLeft = (
        <InputLeftElement
          pointerEvents="none"
          children={<PhoneIcon color="gray.300" />}
        />
      );
      input = <Input type={"tel"} {...commonFields} />;

      break;
    case FieldType.Email:
      inputLeft = (
        <InputLeftElement
          pointerEvents="none"
          children={<EmailIcon color="gray.300" />}
        />
      );
      input = <Input type={"email"} {...commonFields} />;

      break;

    case FieldType.Date:
      input = (
        <Input
          type={"date"}
          min="1000-01-01"
          max="9999-12-31"
          {...commonFields}
        />
      );
      break;

    case FieldType.MultiDate:
      input = <MultiDatePicker {...commonFields} />;
      break;

    case FieldType.DateRange:
      input = <DateRangePicker {...commonFields} />;
      break;

    case FieldType.RadioSelect:
      return (
        <RadioGroup
          isDisabled={props.isReadOnly}
          value={
            props.currentValue
              ? props.currentValue
              : props.options
              ? props.options[0].value
              : undefined
          }
          onChange={(newValue) => {
            props.onChange({ target: { value: newValue } });
          }}
        >
          <Flex flexDir={props.customFlexDir || "column"}>
            {props.options?.map((op, i) => {
              return (
                <Radio key={i} value={op.value} p="5px">
                  {op.label}
                </Radio>
              );
            })}
          </Flex>
        </RadioGroup>
      );

    case FieldType.Switch:
      return (
        <FormControl width={"fit-content"} display="flex" alignItems="center">
          <Switch
            isDisabled={props.isReadOnly || props.isDisabled}
            isChecked={props.currentValue === "active"}
            size="lg"
            onChange={(_) => {
              props.onChange({
                target: {
                  value: props.currentValue === "active" ? "disabled" : "active"
                }
              });
            }}
          />
        </FormControl>
      );
    case FieldType.SingleSelect:
    case FieldType.Boolean:
      return (
        <Box maxW={maxW} ref={props.forwardRef}>
          <FormControl
            onClick={(e) => {
              props?.onClick ? props?.onClick?.(e) : e.stopPropagation();
            }}
            isInvalid={props.isValid === false}
            bgColor={
              props.customColor
                ? props.customColor
                : !props.currentValue && props.shouldMarkEmpty
                ? elphiTheme.colors.light.bg.emptyInput
                : "white"
            }
          >
            <Select
              isInvalid={props?.isValid === false}
              menuPlacement={"auto"}
              filterOption={props?.filterOption}
              isClearable={props.isClearable ?? true}
              isLoading={props.isLoading}
              isDisabled={props.isReadOnly || props.isDisabled}
              selectedOptionColorScheme={"blue"}
              colorScheme={"teal"}
              loadingMessage={(_) => <div>loading</div>}
              menuPortalTarget={document.body}
              value={
                props.options?.filter(
                  (x) => x.value === props.currentValue
                )[0] || []
              }
              //@ts-ignore - required for menuPortal to have zIndex but collision with chakra react select type so we ignore it
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999
                }),
                ...selectionFieldStyles
              }}
              chakraStyles={{
                ...selectionFieldStyles
              }}
              /////////////////////////////
              //
              //  for single select dropdown
              //  for some reason adding custom component
              //  breaks the placeholder label
              //  and the input change newValue type
              components={props.customComponent}
              onChange={(newValue: any, changeType) => {
                if (changeType.action === "clear") {
                  props.onChange({ target: { value: "" } });
                } else if (changeType.action === "select-option")
                  props.onChange({ target: { value: newValue?.value } });
              }}
              onInputChange={(newValue) => props.onInputChange?.(newValue)}
              placeholder={props.label}
              options={props.options}
              onMenuScrollToTop={(_) => {
                !props.isLoading &&
                  props.hasMore &&
                  props.fetchMore &&
                  props.fetchMore();
              }}
              onMenuScrollToBottom={(_) => {
                !props.isLoading &&
                  props.hasMore &&
                  props.fetchMore &&
                  props.fetchMore();
              }}
              size={props.responsiveSize}
            />
            <ErrorMessage
              currentValue={props.currentValue}
              shouldDisplay={!!props?.shouldDisplayErrorMessage}
            />
          </FormControl>
        </Box>
      );
    case FieldType.MultiCheckbox: {
      const MultiCheckboxOption = ({
        index,
        option
      }: {
        index: number;
        option: {
          label: string;
          value: string;
          isChecked?: boolean;
          icon?: ReactElement;
          isDisabled?: boolean;
          tooltipText?: string;
        };
      }) => {
        return (
          <Flex
            key={index}
            alignItems={"center"}
            w={props?.size?.maxW || "260px"}
            justifyContent={"space-between"}
          >
            <Checkbox
              key={`checkbox${index}`}
              value={option.value}
              isChecked={props.isDisabled || option.isChecked}
              p="5px"
              disabled={props.isDisabled || option.isDisabled}
            >
              {option.label}
            </Checkbox>
            {!!option?.icon && option.icon}
          </Flex>
        );
      };

      return (
        <CheckboxGroup
          isDisabled={props.isReadOnly || props.isDisabled}
          value={props.currentValue}
          onChange={(newValue) => {
            props.onChange({ target: { value: newValue } });
          }}
        >
          <Flex flexDir={props.customFlexDir || "column"}>
            {props.options?.map((op, i) => {
              return op.tooltipText ? (
                <Tooltip
                  label={op.tooltipText}
                  hasArrow
                  placement="left"
                  key={i}
                >
                  <Box>
                    <MultiCheckboxOption key={i} index={i} option={op} />
                  </Box>
                </Tooltip>
              ) : (
                <MultiCheckboxOption key={i} index={i} option={op} />
              );
            })}
          </Flex>
        </CheckboxGroup>
      );
    }
    case FieldType.SingleCheckbox:
      return (
        <Box maxW={maxW} ref={props.forwardRef}>
          <FormControl
            borderRadius={"8px"}
            isInvalid={props.isValid === false}
            bgColor={
              props.customColor
                ? props.customColor
                : !props.currentValue && props.shouldMarkEmpty
                ? elphiTheme.colors.light.bg.emptyInput
                : "white"
            }
            onMouseDown={() => setMenuOpen(false)}
          >
            <Stack direction="row">
              <Checkbox
                value={
                  props.options?.[0]?.["value"] || props.options?.["value"]
                }
                onChange={onSelectSingleCheckBox}
              >
                {props.options?.[0]?.["label"] || props.options?.["label"]}
              </Checkbox>
            </Stack>
            <ErrorMessage
              currentValue={props.currentValue}
              shouldDisplay={!!props?.shouldDisplayErrorMessage}
            />
          </FormControl>
        </Box>
      );
    case FieldType.MultiSelect:
      const currentValue =
        props.currentValue?.map?.((v: string) => {
          return props.options?.filter((x) => x.value === v)[0];
        }) || [];
      return (
        <Box maxW={maxW} ref={props.forwardRef}>
          <FormControl
            borderRadius={"8px"}
            isInvalid={props.isValid === false}
            bgColor={
              props.customColor
                ? props.customColor
                : !props.currentValue && props.shouldMarkEmpty
                ? elphiTheme.colors.light.bg.emptyInput
                : "white"
            }
            onMouseDown={() => setMenuOpen(false)}
          >
            <Select
              isSearchable={props.isSearchable}
              isInvalid={props?.isValid === false}
              isMulti
              menuPlacement={"auto"}
              menuIsOpen={menuOpen}
              onBlur={() => setMenuOpen(false)}
              onMenuOpen={() => setMenuOpen(true)}
              menuPortalTarget={document.body}
              isDisabled={props.isReadOnly || props.isDisabled}
              isOptionDisabled={() => props?.isOptionDisabled || false}
              value={currentValue}
              //@ts-ignore - required for menuPortal to have zIndex but collision with chakra react select type so we ignore it
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                ...selectionFieldStyles
              }}
              hideSelectedOptions={props.hideSelectedOptions}
              selectedOptionColorScheme={"blue"}
              onChange={onChangeSelect}
              onInputChange={
                props.onInputChange
                  ? (newValue) => {
                      props.onInputChange && props.onInputChange(newValue);
                    }
                  : undefined
              }
              chakraStyles={props.chakraStyles}
              components={props.customComponent}
              placeholder={props.label}
              options={props.options}
              filterOption={props?.filterOption}
              onMenuScrollToTop={(_) => {
                !props.isLoading &&
                  props.hasMore &&
                  props.fetchMore &&
                  props.fetchMore();
              }}
              onMenuScrollToBottom={(_) => {
                !props.isLoading &&
                  props.hasMore &&
                  props.fetchMore &&
                  props.fetchMore();
              }}
            />
            <ErrorMessage
              currentValue={props.currentValue}
              shouldDisplay={!!props?.shouldDisplayErrorMessage}
            />
          </FormControl>
        </Box>
      );
    default:
      throw `field type: ${props.fieldType} not exists, label: ${JSON.stringify(
        props
      )}`;
  }
  return (
    <FormControl
      isInvalid={props.isValid === false}
      isRequired={props.isRequired}
      borderRadius={"8px"}
      // fontSize="12px"
      background="white"
      // fontWeight="bold"
      bgColor={
        props.customColor
          ? props.customColor
          : !props.currentValue && props.shouldMarkEmpty
          ? elphiTheme.colors.light.bg.emptyInput
          : "white"
      }
    >
      <>
        <InputGroup ref={props.forwardRef}>
          {inputLeft}
          {input}
          <InputRightElement
            children={
              props.isValid && props.currentValue ? <ValidIcon /> : null
            }
          />
        </InputGroup>
        <ErrorMessage
          currentValue={props.currentValue}
          shouldDisplay={!!props?.shouldDisplayErrorMessage}
        />
      </>
    </FormControl>
  );
};

export const ErrorMessage = (props: {
  shouldDisplay: boolean;
  currentValue: string;
}): JSX.Element => {
  return props?.shouldDisplay && props.currentValue ? (
    <FormErrorMessage overflowWrap={"anywhere"}>
      Invalid value: {props.currentValue}
    </FormErrorMessage>
  ) : (
    <></>
  );
};

export const StyledInputBuilder = chakra(InputBuilder, {
  baseStyle: {
    padding: "0px"
  }
});

export function useFormBuilderStateHandler<T extends object>(r: {
  initialState: T;
  callback?: (
    newState: Partial<T>
  ) => Promise<null | { status: 200 } | { status: 400 }>;
  callbackOptions?: {
    clearDiff?: boolean;
    debounceRate?: number;
  };
}) {
  const [state, setState] = useState<T>(r.initialState);
  const [diffState, setDiffState] = useState<Partial<T>>({});
  // const [requesting, setRequesting] = useState<boolean>(false);
  const [diffKeeperState, setDiffKeeperState] = useState<Partial<T>>({});

  const debouncedCallback = lodash.debounce((newState: Partial<T>) => {
    r.callback &&
      r.callback(newState)?.then((res) => {
        if (res?.status === 200 && r.callbackOptions?.clearDiff) {
          // setDiffKeeperState(newState);
          setDiffKeeperState({});
          // setDiffState({});
        } else if (res?.status === 400) {
          //revert changes
        }
      });
  }, r.callbackOptions?.debounceRate || 2700);
  const callbackRef = useRef(debouncedCallback);

  const arrayFullReplace = (objValue: any, srcValue: any) => {
    if (lodash.isArray(objValue)) {
      return srcValue;
    }
  };

  const syncState = <D extends object>(r: {
    shouldSync: boolean;
    state: D | undefined;
    statePath?: () => string[] | undefined;
  }) => {
    if (!r.shouldSync || !r.state) return;

    const path = r.statePath && r.statePath();
    if (path && path.length > 0) {
      const joinedPath = path.join(".");
      const selectedDiff = lodash.get(diffState, joinedPath);
      const stateToUpdate = lodash.mergeWith(
        {},
        r.state,
        selectedDiff,
        arrayFullReplace
        // syncerState
      );
      const newState = lodash.set(state, joinedPath, stateToUpdate);
      setState({
        ...newState
      });
      setDiffState({});
      // setSyncerState({});
    } else {
      const newState = lodash.mergeWith(
        {},
        state,
        r.state,
        diffState,
        // syncerState,

        arrayFullReplace
      );
      setState({
        ...newState
      });
      setDiffState({});
      // setSyncerState({});
    }
  };

  const onChange = (v: OnChangeInput) => {
    const newDiff = lodash.set(diffState, v.fieldKey, v.value);
    const newState = lodash.set(state, v.fieldKey, v.value);
    const newDiffKeeperState = lodash.set(diffKeeperState, v.fieldKey, v.value);
    const diffMergedWithKeeper = lodash.merge(newDiff, diffKeeperState);
    setState({ ...newState });
    setDiffState({ ...newDiff });
    setDiffKeeperState({ ...newDiffKeeperState });
    if (r.callbackOptions?.debounceRate) {
      r.callback && callbackRef.current(diffMergedWithKeeper);
    } else {
      r.callback && r.callback(diffMergedWithKeeper);
    }
  };

  return { syncState, onChange, state, setState, diffState, setDiffState };
}

export const LargeDropDownLabel = (props: any) => {
  return <ValueContainer {...props} maxLabelLength={20} />;
};
export const MediumDropDownLabel = (props: any) => {
  return <ValueContainer {...props} maxLabelLength={9} />;
};

export const SmallDropDownLabel = (props: any) => {
  return <ValueContainer {...props} maxLabelLength={4} />;
};

export const XLargeDropDownLabel = (props: any) => {
  return <ValueContainer {...props} maxLabelLength={30} />;
};
export const valueContainerBySize = {
  s: SmallDropDownLabel,
  m: MediumDropDownLabel,
  l: LargeDropDownLabel,
  xl: XLargeDropDownLabel
};

export const ValueContainer = (props: any) => {
  var values = props.getValue();
  var valueLabel = props.selectProps.placeholder;

  const offset = values.length > 1 ? 2 : 0;
  const maxLabelLength = props.maxLabelLength || 9;
  if (values.length > 0)
    valueLabel = props.selectProps.getOptionLabel(values[0]);

  valueLabel = `${valueLabel.slice(0, maxLabelLength - offset)}${
    valueLabel.length > maxLabelLength ? "..." : ""
  } `;

  const toolTipLabel = values
    .map((v: string) => props.selectProps.getOptionLabel(v))
    .join(", ");

  //the underscore "_" is for the chips
  const [_, otherChildren] = props.children;
  const [__, setFocused] = useState(false);
  return (
    <chakraComponents.ValueContainer
      isDisabled={false}
      isMulti={true}
      {...props}
    >
      <Box onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
        {/* {!values.length || focused ? otherChildren : <></>} */}
        <Flex>
          <Box>{otherChildren}</Box>
          {/* {!focused && !props.selectProps.inputValue && valueLabel && ( */}
          {!props.selectProps.inputValue && valueLabel && (
            <Tooltip label={toolTipLabel}>
              <Box width="100%" overflow="visible">
                <Text
                  w="100%"
                  noOfLines={1}
                  color={values.length ? "" : "grey"}
                  opacity={values.length ? 1 : 0.7}
                >
                  {!props.selectProps.inputValue && valueLabel}
                  {`${values.length > 1 ? ` + ${values.length - 1}` : ""}`}
                </Text>
              </Box>
            </Tooltip>
          )}
        </Flex>
      </Box>
    </chakraComponents.ValueContainer>
  );
};
ValueContainer.defaultProps = {
  maxLabelLength: 20
};
export const SearchDropDownIndicator = (props: any) => (
  <chakraComponents.DropdownIndicator {...props}>
    <SearchIcon color={"gray"} />
  </chakraComponents.DropdownIndicator>
);

export const DropDownIndicatorFirstControl = (props: any) => {
  const { children, ...otherProps } = props;
  return (
    <chakraComponents.Control {...otherProps}>
      <div>
        <SearchDropDownIndicator {...otherProps} />
        <chakraComponents.IndicatorSeparator {...otherProps} />
      </div>
      {children}
    </chakraComponents.Control>
  );
};
export const IndicatorsContainer = (props: any) => {
  const { children, ...otherProps } = props;
  const removedTypes = ["IndicatorSeparator", "DropdownIndicator"];
  const allowedChildren = Children.map(children, (child) => {
    return child && !removedTypes.includes(child.type.name) ? child : null;
  });

  return (
    <chakraComponents.IndicatorsContainer {...otherProps}>
      {allowedChildren}
    </chakraComponents.IndicatorsContainer>
  );
};
export default StyledInputBuilder;
