import { createIcon } from "@chakra-ui/icons";

const customIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 18 18",
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="18" height="18" rx="9" fill={fill} />
        <path
          d="M13.6875 7.15635H9.46875V8.09385H13.6875V7.15635ZM13.6875 10.9063H9.46875V11.8438H13.6875V10.9063ZM5.97187 9.03135L4.3125 7.37197L4.97344 6.71104L5.96719 7.70479L7.95469 5.71729L8.61563 6.37822L5.97187 9.03135ZM5.97187 12.7813L4.3125 11.122L4.97344 10.461L5.96719 11.4548L7.95469 9.46729L8.61563 10.1282L5.97187 12.7813Z"
          fill="#2D3748"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const ChecklistIcon = customIcon();
export const ChecklistPink100Icon = customIcon("#FED7E2");
