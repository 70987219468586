import { DocumentConfiguration } from "@elphi/types";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RootState, store } from "../redux/store";
import { documentConfigurationApi } from "../redux/v2/document-configuration/documentConfiguration.service";
import { documentConfigurationSlice } from "../redux/v2/document-configuration/documentConfiguration.slice";
import { CallBackActions } from "../redux/v2/types/action.types";
import { compareStrings } from "../utils/searchUtils";

export const useDocumentConfigurationHooks = () => {
  const [updateBatchApi, updateBatchResponse] =
    documentConfigurationApi.useBatchUpdateMutation();
  const [deleteConfigurationApi, deleteConfigurationResponse] =
    documentConfigurationApi.useDeleteMutation();
  const [getConfigurations, getConfigurationsResponse] =
    documentConfigurationApi.useLazyGetConfigurationBatchQuery();
  const deleteConfiguration = async (id: string) => {
    return await deleteConfigurationApi(id).then(responseHandler);
  };
  const updateBatch = async (r: {
    documents: ({ id: string } & Partial<DocumentConfiguration>)[];
  }) => {
    return await updateBatchApi(r).then(responseHandler);
  };
  const setSelectedDocumentConfiguration = (id: string) =>
    store.dispatch(documentConfigurationSlice.actions.selectedId({ id }));

  const selectedDocumentConfiguration = useSelector(
    (state: RootState) =>
      state.documentConfiguration.selectedId &&
      state.documentConfiguration.entities[
        state.documentConfiguration.selectedId
      ]
  );
  const documentConfigurationState = useSelector(
    (state: RootState) => state.documentConfiguration
  );
  const [searchDocumentConfigurationApi, searchResponse] =
    documentConfigurationApi.useLazySearchQuery();

  const rankedSort = (query: string) => {
    return Object.values(documentConfigurationState.entities).sort((a, b) => {
      const rankA = compareStrings(query, a?.name || "") * 50;
      const rankB = compareStrings(query, b?.name || "") * 50;
      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const documentConfigurationCallback: CallBackActions<DocumentConfiguration> =
    {
      remove: (_) => {
        setSelectedDocumentConfiguration("");
      }
    };

  return {
    documentConfigurationCallback,
    deleteConfigurationResponse,
    deleteConfiguration,
    documentConfigurationState,
    searchDocumentConfigurationApi,
    searchResponse,
    rankedSort,
    selectedDocumentConfiguration,
    setSelectedDocumentConfiguration,
    updateBatch,
    updateBatchResponse,
    getConfigurations,
    getConfigurationsResponse
  };
};
