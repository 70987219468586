import { FirebaseFilter, RoleGroup } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";
import { roleGroupAdapter } from "../role-group/roleGroup.adapter";

export type RoleGroupPaginateV2Response = {
  nextCursor?: string;
  hasMore: boolean;
  page: RoleGroup[];
};

export const baseRoleGroupApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "roleGroupApi",
  tagTypes: ["RoleGroup", "Notification", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "role-group"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: roleGroupAdapter
  })
});

export const roleGroupApi = baseRoleGroupApi.injectEndpoints({
  endpoints: (builder) => ({
    updateBatch: builder.mutation<
      { batch: string[] },
      { groups: ({ id: string } & Partial<RoleGroup>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    search: builder.query<
      {
        query: string;
        hasMore: boolean;
        nextCursor: string | null;
        results: EntityState<RoleGroup>;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: RoleGroup[];
          nextCursor: string | null;
          hasMore: boolean;
        },
        _,
        arg
      ) => {
        const adapter = roleGroupAdapter.addMany(
          roleGroupAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          nextCursor: response.nextCursor,
          hasMore: response.hasMore
        };
      }
    }),
    paginateV2: builder.query<
      RoleGroupPaginateV2Response,
      {
        cursor?: string;
        options?: {
          order?: "desc" | "asc";
          filter?: FirebaseFilter | FirebaseFilter[];
        };
      }
    >({
      query: (r) => {
        return {
          url: `/paginate`,
          method: "POST",
          body: r
        };
      }
    }),
    getBatch: builder.query<{ batch: EntityState<RoleGroup> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: RoleGroup[] }) => {
        const adapter = roleGroupAdapter.addMany(
          roleGroupAdapter.getInitialState(),
          response.results
        );

        return { batch: adapter };
      }
    })
  })
});
