import { TaskType } from "@elphi/types";

import { integrationRequestMap } from "./integrations/requests";
import { orderStatusResolverFactory } from "./resolver";
import { hasIntegrationType } from "./typeGuards";
import { GetOrderStatusResponse, OrderStatusResolverRequest } from "./types";
import { TaskRowData } from "../../../shared/types/task.types";

const documentHandler = (data: TaskRowData): GetOrderStatusResponse => {
  const documentRequest: OrderStatusResolverRequest = { data };
  const resolver = orderStatusResolverFactory(TaskType.Document);
  return resolver.resolve(documentRequest);
};

const documentOrderStatusHandler = (
  data: TaskRowData
): GetOrderStatusResponse => {
  const documentOrderRequest: OrderStatusResolverRequest = { data };
  const resolver = orderStatusResolverFactory(TaskType.DocumentOrderStatus);
  return resolver.resolve(documentOrderRequest);
};

const integrationHandler = (
  data: TaskRowData
): GetOrderStatusResponse | undefined => {
  const { task } = data;
  const integrationType = hasIntegrationType(task)
    ? task.integrationType
    : undefined;
  if (!integrationType) return;
  const request: OrderStatusResolverRequest = {
    data,
    ...integrationRequestMap(integrationType)
  };
  return orderStatusResolverFactory(TaskType.Integration).resolve(request);
};

const orderStatusHandlerFactory = (taskType: TaskType) => {
  const handlerMap: {
    [t in TaskType]: (
      rowData: TaskRowData
    ) => GetOrderStatusResponse | undefined;
  } = {
    [TaskType.Integration]: integrationHandler,
    [TaskType.Document]: documentHandler,
    [TaskType.DocumentOrderStatus]: documentOrderStatusHandler
  };

  return handlerMap[taskType];
};

export { orderStatusHandlerFactory };
