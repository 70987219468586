import { Party } from "@elphi/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import SearchComponent, { SearchComponentProps } from "../SearchComponent";
import { assetToString } from "./utils/printUtils";

export const AssetSearch = (
  props: Pick<
    SearchComponentProps,
    "onSelect" | "currentValue" | "fieldType" | "labelPosition" | "label"
  > & { selectedParty: Partial<Party> }
) => {
  const assetState = useSelector((state: RootState) => state.asset);
  const partyAssetState = useSelector((state: RootState) => state.partyAsset);

  const options = useMemo(() => {
    const assetIds: string[] = assetState.ids
      .filter((assetId) => {
        return partyAssetState?.entities?.[
          `${assetId}_${props.selectedParty?.id ?? ""}`
        ];
      })
      .filter((v) => !!v) as string[];

    const allParties = assetIds;

    return (
      allParties
        .filter((v) => !!v)
        .map((id: string) => {
          const entity = assetState.entities[id];
          return {
            label: entity ? assetToString({ asset: entity }) : "No Label",
            value: id
          };
        }) || []
    );
  }, [assetState]);

  return (
    <SearchComponent
      fieldType={props.fieldType}
      options={options}
      currentValue={props.currentValue}
      onSelect={props.onSelect}
      isLoading={false}
      label={props.label}
      labelPosition={props.labelPosition}
    />
  );
};
