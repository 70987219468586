import { createIcon } from "@chakra-ui/react";

const customIcon = () =>
  createIcon({
    viewBox: "0 0 24 24",
    path: (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 6.75C6.75 6.33579 7.08579 6 7.5 6H21C21.4142 6 21.75 6.33579 21.75 6.75C21.75 7.16421 21.4142 7.5 21 7.5H7.5C7.08579 7.5 6.75 7.16421 6.75 6.75ZM6.75 12C6.75 11.5858 7.08579 11.25 7.5 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H7.5C7.08579 12.75 6.75 12.4142 6.75 12ZM6.75 17.25C6.75 16.8358 7.08579 16.5 7.5 16.5H21C21.4142 16.5 21.75 16.8358 21.75 17.25C21.75 17.6642 21.4142 18 21 18H7.5C7.08579 18 6.75 17.6642 6.75 17.25Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 6.75C2.25 5.92157 2.92157 5.25 3.75 5.25C4.57843 5.25 5.25 5.92157 5.25 6.75C5.25 7.57843 4.57843 8.25 3.75 8.25C2.92157 8.25 2.25 7.57843 2.25 6.75Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 12C2.25 11.1716 2.92157 10.5 3.75 10.5C4.57843 10.5 5.25 11.1716 5.25 12C5.25 12.8284 4.57843 13.5 3.75 13.5C2.92157 13.5 2.25 12.8284 2.25 12Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 17.25C2.25 16.4216 2.92157 15.75 3.75 15.75C4.57843 15.75 5.25 16.4216 5.25 17.25C5.25 18.0784 4.57843 18.75 3.75 18.75C2.92157 18.75 2.25 18.0784 2.25 17.25Z"
          fill="black"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const DotListIcon = customIcon();
