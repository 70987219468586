import { EditIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import { PropertyInsurancePolicyView } from "@elphi/types";
import { Card } from "../../../../common-components/card";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { UnplugIcon } from "../../../icons/UnplugIcon";
import { InsurancePolicyMinimizedView } from "../../card/InsurancePolicyMinimizedView";
import { PropertyInsurancePolicyMaximizedView } from "./PropertyInsuranceMaximizedView";

const PROPERTY_INSURANCE_CARD_HEIGHT = "230px";

type OperationActions = {
  onEdit: (view: PropertyInsurancePolicyView) => void;
  onRemove: (view: PropertyInsurancePolicyView) => void;
};

type PropertyInsurancePolicyCardProps = OperationActions & {
  policyView: PropertyInsurancePolicyView;
};

const PropertyInsurancePolicyCard = (
  props: PropertyInsurancePolicyCardProps
) => {
  const { policyView, onEdit, onRemove } = props;
  return (
    <Card
      key={policyView.id}
      header={`Policy #${policyView.policy.number || NOT_AVAILABLE}`}
      cardOperations={[
        {
          label: "Edit insurance policy",
          action: () => onEdit(policyView),
          icon: <EditIcon h={4} w={4} color="gray.600" />
        },
        {
          label: "Remove property from insurance policy",
          action: () => onRemove(policyView),
          icon: <UnplugIcon w={4} h={4} color={"gray.600"} />
        }
      ]}
      minimizedView={
        <InsurancePolicyMinimizedView providers={policyView.providers} />
      }
      maximizedView={<PropertyInsurancePolicyMaximizedView view={policyView} />}
      collapseHeight={PROPERTY_INSURANCE_CARD_HEIGHT}
    />
  );
};

type PropertyInsurancePolicyCardsProps = OperationActions & {
  propertyInsurancePolicyViews: PropertyInsurancePolicyView[];
};

export const PropertyInsurancePolicyCards = (
  props: PropertyInsurancePolicyCardsProps
) => {
  const { propertyInsurancePolicyViews } = props;
  return (
    <Flex gap="12px" wrap="wrap">
      {propertyInsurancePolicyViews?.map((policyView, index) => (
        <PropertyInsurancePolicyCard
          key={index}
          policyView={policyView}
          {...props}
        />
      ))}
    </Flex>
  );
};
