import { LoanpassMessageType } from "@elphi/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import { useLoanpassHooks } from "./loanpass.hooks";
import { LoanpassMessage } from "./loanpass.types";

export const LoanPassPricingIframe = (props: {
  shouldHide?: boolean;
  handleMessage: (payload: LoanpassMessage) => void;
  onIframeLoad: (iframeElement: HTMLIFrameElement | null) => void;
}) => {
  const { handleMessage } = props;
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [fullLoanpassOrigin, setFullLoanpassOrigin] = useState("");
  const { loanpassConfigs: config, getLoanpassConfigs } = useLoanpassHooks();

  useAuthStateChangeHook({
    onAuthStateChange: async (user) => {
      if (user) {
        getLoanpassConfigs();
      }
    },
    deps: [auth.currentUser]
  });

  useEffect(() => {
    if (config) {
      setFullLoanpassOrigin(
        `${config.origin}/frame-redirect/${config.clientAccessId}`
      );

      handleMessage({
        messageType: LoanpassMessageType.LogIn,
        clientAccessId: config.clientAccessId,
        email: config.email,
        password: config.password,
        origin: config.origin
      });
    }
  }, [config]);

  useEffect(() => {
    iframeRef?.current && props.onIframeLoad(iframeRef.current);
  }, [iframeRef?.current]);

  const messageListener = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== config?.origin) {
        return;
      }
      handleMessage(event.data);
    },
    [handleMessage, config?.origin]
  );

  useEffect(() => {
    window.addEventListener("message", messageListener);
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [messageListener]);

  const onLoad = useCallback(() => {
    const contentWindow = iframeRef.current?.contentWindow;
    const canLoad = contentWindow && config?.origin;

    if (!canLoad) {
      return;
    }

    contentWindow.postMessage(
      { message: LoanpassMessageType.Connect },
      config.origin
    );

    contentWindow.postMessage(
      { message: LoanpassMessageType.EnableFloatRequests },
      fullLoanpassOrigin
    );
  }, [config?.origin, fullLoanpassOrigin]);

  return (
    <>
      {fullLoanpassOrigin && (
        <iframe
          ref={iframeRef}
          onLoad={onLoad}
          src={fullLoanpassOrigin}
          style={{
            opacity: props.shouldHide ? 0 : 1,
            width: "100%",
            height: "100%",
            border: "none"
          }}
        />
      )}
    </>
  );
};
