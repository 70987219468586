import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { navigationSlice } from "../redux/v2/navigation/navigation.slice";
import { NavigationItemDictionary } from "../shared/types/navigation.types";

export const useNavigationHooks = () => {
  const dispatcher = useDispatch();
  const navigationState = useSelector((state: RootState) => state.navigation);

  const setExpandAll = ({
    navigationPathDictionary
  }: {
    navigationPathDictionary: NavigationItemDictionary;
  }) => {
    dispatcher(
      navigationSlice.actions.setExpandAll({
        navigationPathDictionary
      })
    );
  };

  const setCollapseAll = ({
    navigationPathDictionary
  }: {
    navigationPathDictionary: NavigationItemDictionary;
  }) => {
    dispatcher(
      navigationSlice.actions.setCollapseAll({
        navigationPathDictionary
      })
    );
  };

  const setTabsSections = ({
    navigationPathDictionary,
    selectedArray,
    index,
    sectionLength,
    excludeCollapse
  }: {
    navigationPathDictionary: NavigationItemDictionary;
    selectedArray: number[];
    sectionLength: number;
    index: string;
    excludeCollapse?: number[];
  }) =>
    dispatcher(
      navigationSlice.actions.setTabsSections({
        navigationPathDictionary,
        selectedArray,
        sectionLength,
        index,
        excludeCollapse
      })
    );

  const updateTabsSections = ({
    navigationPathDictionary,
    index,
    item
  }: {
    navigationPathDictionary: NavigationItemDictionary;
    index: string;
    item: number;
  }) =>
    dispatcher(
      navigationSlice.actions.updateTabsSections({
        navigationPathDictionary,
        index,
        item
      })
    );

  return {
    setExpandAll,
    setCollapseAll,
    setTabsSections,
    updateTabsSections,
    navigationState
  };
};
