import { AuditEventEntityType, ElphiEntityType } from "@elphi/types";

export const auditEntityNameMapper: Partial<{
  [key in AuditEventEntityType]: ElphiEntityType;
}> = {
  [AuditEventEntityType.Asset]: ElphiEntityType.asset,
  [AuditEventEntityType.Deal]: ElphiEntityType.deal,
  [AuditEventEntityType.Party]: ElphiEntityType.party,
  [AuditEventEntityType.Property]: ElphiEntityType.property,
  [AuditEventEntityType.Statement]: ElphiEntityType.statement,
  [AuditEventEntityType.Task]: ElphiEntityType.task,
  [AuditEventEntityType.DealParty]: ElphiEntityType.dealParty,
  [AuditEventEntityType.ServiceProvider]: ElphiEntityType.task
};
