import { LoanpassPipelineRecord } from "@elphi/types";
import { keyBy } from "lodash";
import { DEFAULT_PIPELINE_RECORD } from "../../../../redux/v2/integration/loanpass/loanpass.slice";
import { FuzzySearchSelect } from "../../../search/fuzzy/FuzzySearchSelect";
import { SelectSearchProps } from "../../../search/search.types";
import { useLoanpassHooks } from "./loanpass.hooks";

const buildOption = (r: LoanpassPipelineRecord) => {
  const label =
    r.id === DEFAULT_PIPELINE_RECORD.id ? r.name : `${r.name} | ${r.id}`;

  return {
    label,
    value: r.id
  };
};

export const PipelineRecordsSearchSearch = (props: SelectSearchProps) => {
  const { pipelineRecords, searchPipelineApi, searchPipelineApiResponse } =
    useLoanpassHooks();

  const entities = keyBy(pipelineRecords, (x) => x.id);

  return (
    <FuzzySearchSelect
      {...props}
      label={"Search a pipeline record"}
      searchApi={searchPipelineApi}
      searchResponse={searchPipelineApiResponse}
      state={{ entities }}
      defaultOptions={[
        {
          label: DEFAULT_PIPELINE_RECORD.name,
          value: DEFAULT_PIPELINE_RECORD.id
        }
      ]}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["name", "id"],
        shouldSort: true
      }}
      buildOption={buildOption}
      wrapperStyle={{ p: "0px" }}
    />
  );
};
