import { Status } from "../common";
import { BaseEntity } from "../db";

export type NotificationConfiguration = BaseEntity<{
  name: string;
  auditGroupId: string;
  roleGroupId: string;
  fieldGroupId: string;
  status: Status;
}>;

export enum UserAssignmentType {
  Deal = "deal-user",
  Party = "party-user"
}
