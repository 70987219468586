import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { useElphiToast } from "../../../toast/toast.hook";

export const ItemsMenuCopyWidget = (props: {
  items: { value?: string; label: string }[];
}) => {
  const { infoToast } = useElphiToast();
  return (
    <Menu>
      <MenuButton
        size="xs"
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        bgColor="white"
        variant="outline"
        onClick={(e) => e.stopPropagation()}
      />
      <MenuList zIndex={5}>
        {props.items.map((item, i) => {
          return (
            <MenuItem
              key={i}
              disabled={!item.value}
              onClick={() => {
                item.value && navigator.clipboard.writeText(item.value);
                infoToast({
                  title: `${item.label}: copied to clipboard`,
                  description: `${item.value}`
                });
              }}
            >
              <Tooltip label={"click to copy"} placement="top">
                <Box>
                  <Text fontSize={"16px"} fontWeight={"bold"}>
                    {item.label}:
                  </Text>
                  <Text fontSize={"16px"}>{item.value}</Text>
                </Box>
              </Tooltip>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
