import { NotificationConfiguration } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { EMPTY } from "../../../constants/common";
import sliceBuilder from "../builders/slice.builder";
import { FiltersState } from "../types/stateWithFilter.types";
import {
  initCombination,
  setCombinationInfo,
  setFilterAndCombination
} from "../utils/stateFilter.utils";
import { notificationConfigurationAdapter } from "./notificationConfiguration.adapter";
import { notificationConfigurationApi } from "./notificationConfiguration.service";

export type NotificationConfigurationSliceState =
  EntityState<NotificationConfiguration> & {
    selectedId?: EntityId;
    filters: FiltersState<NotificationConfiguration, "name" | "status">;
  };

const initialState: NotificationConfigurationSliceState = {
  ids: [],
  entities: {},
  selectedId: undefined,
  filters: {
    current: {
      name: [],
      status: []
    },
    combinations: {
      [EMPTY]: initCombination()
    },
    currentCombination: EMPTY
  }
};

export const notificationConfigurationSlice = createSlice({
  name: "notificationConfiguration",
  initialState: notificationConfigurationAdapter.getInitialState(initialState),
  reducers: {
    update: notificationConfigurationAdapter.updateOne,
    remove: notificationConfigurationAdapter.removeOne,
    add: notificationConfigurationAdapter.addOne,
    upsert: notificationConfigurationAdapter.upsertOne,
    upsertMany: notificationConfigurationAdapter.upsertMany,
    removeMany: notificationConfigurationAdapter.removeMany,
    updateMany: notificationConfigurationAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    setFilterAndCombination: (
      state,
      action: PayloadAction<{
        combination: string;
        filters: NotificationConfigurationSliceState["filters"];
      }>
    ) => setFilterAndCombination(state, action),

    setFilterCombinationDetails: (
      state,
      action: PayloadAction<
        NotificationConfigurationSliceState["filters"]["combinations"][string]
      >
    ) => setCombinationInfo(state, action)
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(notificationConfigurationApi)(builder);
    builder.addMatcher(
      notificationConfigurationApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      notificationConfigurationApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page, "id")
        };
      }
    );
  }
});
