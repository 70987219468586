/**
 * Positive Number With Two Digit After Decimal Point
 */
export const PositiveDecimalNumber =
  /^(0|[1-9][0-9]*)(\.\d+)?([0-9]?)([0-9]?)*$|^$/;

/**
 * Percent Decimal Number
 */
export const PercentDecimalNumber =
  /^(0(\.\d{1,2})?|100(\.00)?|\d{1,2}(\.\d{1,3})?)%?$|^$/;

/**
 * Percent Three Decimal Number
 */
export const PercentThreeDecimalNumber =
  /^(0(\.\d{1,2})?|100(\.00)?|\d{1,2}(\.\d{1,3})?)%?$|^$/;

/**
 * Replace Comma With Semicolon
 * @param text
 * @returns Text without Comma
 */
export const replaceCommaWithSemicolon = (text: string) =>
  text.replace(/,/g, ";");

/**
 * Formats a number string with comma separators
 * @param {string} numStr - The number string to format
 * @returns {string} The formatted string with comma separators
 * Usage: input '1234567.89' output: "1,234,567.89"
 */
export const formatNumberWithCommas = (numStr: string) => {
  // Regular expression to insert commas in between every 3 digits
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const passwordAllowedSpecialChars = "!#$";
export const PasswordAllowedSpecialCharsRegex = new RegExp(
  `[${passwordAllowedSpecialChars}]`
);

const passwordValidChars = `a-zA-Z0-9${passwordAllowedSpecialChars}`;
export const PasswordValidCharsRegex = new RegExp(
  `[${passwordValidChars}]+`,
  "g"
);
export const PasswordBadCharsRegex = new RegExp(`[^${passwordValidChars}]`);

export const AlphaNumericUnderscoreHyphen = /[^a-zA-Z0-9_-]/g;
export const removesTrailingZeros = (numStr: string) => {
  return numStr.replace(/(\.0+|(?<=\.\d+?)0+)$/, "");
};

export const removeNonNumeric = (numStr: string) => {
  return numStr.replace(/[^0-9.\-]+/g, "");
};

export const ssnTinGroupsRegex = /(\d{3})(\d{2})(\d{4})/;
export const einGroupsRegex = /(\d{2})(\d{7})/;
