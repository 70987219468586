import { FieldType, ServiceProviderEntityType, Status } from "@elphi/types";
import {
  BaseInsurancePolicy,
  InsurancePolicyEntityType,
  OutstandingPremiumStatus,
  PropertyInsurancePolicyCoverageType
} from "@elphi/types/entities/insurance-policy";
import { ConfigurationSearch } from "../../../rolodex/configuration/tabs/service-domain-configuration/search/ConfigurationSearch";
import {
  createOptionsFromMap,
  createSelectOptions
} from "../../../utils/formUtils";
import { createCustomComponentConfig } from "../../FormBuilder";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";
import { propertyInsurancePolicyCoverageTypeOptions } from "./coverageNamesMappings";

const outstandingPremiumStatusOptions = {
  [OutstandingPremiumStatus.PaidAtClosing]: "Paid At Closing",
  [OutstandingPremiumStatus.PaidOutsideClosing]: "Paid Outside Closing"
};

export type InsurancePolicyFieldSpecs = Omit<
  EntityFormFieldSpecs<
    SpecsBaseOmit<BaseInsurancePolicy<InsurancePolicyEntityType>>
  >,
  "entities"
>;
export const baseInsurancePolicyFieldSpecs: InsurancePolicyFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  insuranceCarrier: {
    fieldKey: ["insuranceCarrier"],
    fieldType: FieldType.String,
    label: "Insurance Carrier"
  },
  domainConfigurationId: {
    fieldKey: ["domainConfigurationId"],
    fieldType: FieldType.SingleSelect,
    label: "Domain Configuration Id",
    componentOverride: createCustomComponentConfig({
      component: ConfigurationSearch,
      props: {
        filter: (x) =>
          x.status === Status.Active &&
          x.entityType === ServiceProviderEntityType.InsurancePolicy
      }
    })
  },
  policyProviderInfo: {
    id: {
      fieldKey: ["policyProviderInfo", "id"],
      fieldType: FieldType.String,
      label: "Policy Provider Id"
    },
    name: {
      fieldKey: ["policyProviderInfo", "name"],
      fieldType: FieldType.String,
      label: "Policy Provider Name"
    }
  },
  policyNumber: {
    fieldKey: ["policyNumber"],
    fieldType: FieldType.String,
    label: "Policy Number"
  },
  effectiveDate: {
    fieldKey: ["effectiveDate"],
    fieldType: FieldType.Date,
    label: "Effective Date"
  },
  expirationDate: {
    fieldKey: ["expirationDate"],
    fieldType: FieldType.Date,
    label: "Expiration Date"
  },
  nextPaymentDueDate: {
    fieldKey: ["nextPaymentDueDate"],
    fieldType: FieldType.Date,
    label: "Next Payment Due Date"
  },
  policyPremiumAmount: {
    fieldKey: ["policyPremiumAmount"],
    fieldType: FieldType.Money,
    label: "Policy Premium"
  },
  policyDeductibleAmount: {
    fieldKey: ["policyDeductibleAmount"],
    fieldType: FieldType.Money,
    label: "Policy Deductible"
  },
  outstandingPremiumStatus: {
    fieldKey: ["outstandingPremiumStatus"],
    fieldType: FieldType.SingleSelect,
    label: "Outstanding Premium Status",
    options: createSelectOptions(
      OutstandingPremiumStatus,
      outstandingPremiumStatusOptions
    )
  },
  outstandingPremiumAmount: {
    fieldKey: ["outstandingPremiumAmount"],
    fieldType: FieldType.Money,
    label: "Outstanding Premium Amount"
  },
  insurancePremiumPaymentAmount: {
    fieldKey: ["insurancePremiumPaymentAmount"],
    fieldType: FieldType.Money,
    label: "Insurance Premium Payment"
  },
  entityType: {
    fieldKey: ["entityType"],
    fieldType: FieldType.SingleSelect,
    label: "Entity Type",
    options: createOptionsFromMap(InsurancePolicyEntityType)
  },
  coverageTypes: {
    fieldKey: ["coverageTypes"],
    fieldType: FieldType.MultiSelect,
    label: "Coverage Types",
    options: createSelectOptions(
      PropertyInsurancePolicyCoverageType,
      propertyInsurancePolicyCoverageTypeOptions
    )
  }
};

export const fullBaseInsurancePolicyFieldFieldSpecs = createSpecWithFieldMeta({
  spec: baseInsurancePolicyFieldSpecs
});
