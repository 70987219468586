import { Box, Button, Center, Flex, Progress } from "@chakra-ui/react";
import { PortfolioTemplateType } from "@elphi/types";
import { SetStateAction } from "react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { FileUploader } from "../file-uploader/FileUploader";
import { FieldType } from "../form-builder/fieldFormat.types";
import StyledInputBuilder from "../form-builder/InputBuilder";
import PortfolioActions, { PortfolioAction } from "./PortfolioActions";

const PortfolioPageHeader = (props: {
  actions: PortfolioAction[];
  fileUpload: {
    onFileUpload: (file: File) => void;
    isLoading: boolean;
  };
  save: {
    onClick: () => void;
    isLoading: boolean;
  };
  template: {
    selectedPortfolioTemplate: PortfolioTemplateType;
    setSelectedPortfolioTemplate: (
      value: SetStateAction<PortfolioTemplateType>
    ) => void;
  };
  isDisabled: boolean;
}) => {
  const HEADER_HEIGHT = "100px";
  return (
    <Flex
      justifyContent={"space-between"}
      w="100%"
      minH={HEADER_HEIGHT}
      maxH={HEADER_HEIGHT}
    >
      <Box>
        <FileUploader
          onClick={props.fileUpload.onFileUpload}
          isLoading={props.fileUpload.isLoading}
          isDisabled={props.isDisabled}
          isSingleFile
        />
        {props.fileUpload.isLoading && <Progress size="xs" isIndeterminate />}
      </Box>
      <Flex flexDir="column" justifyContent={"center"} pt="10px">
        <Box w="100%">
          <Center>
            <Button
              {...elphiTheme.components.light.button.primary}
              isDisabled={props.save.isLoading || props.isDisabled}
              isLoading={props.save.isLoading}
              onClick={props.save.onClick}
            >
              Save
            </Button>
          </Center>
        </Box>
        <StyledInputBuilder
          fieldType={FieldType.RadioSelect}
          onChange={(e) => {
            props.template.setSelectedPortfolioTemplate(e.target.value);
          }}
          isValid={true}
          label={"template type"}
          customFlexDir={"row"}
          currentValue={props.template.selectedPortfolioTemplate}
          options={[
            {
              label: PortfolioTemplateType.ShortTerm,
              value: PortfolioTemplateType.ShortTerm
            },
            {
              label: PortfolioTemplateType.LongTerm,
              value: PortfolioTemplateType.LongTerm
            }
          ]}
        />
      </Flex>
      <Flex justifyContent={"space-between"} pt="20px">
        <PortfolioActions actions={props.actions} />
      </Flex>
    </Flex>
  );
};

export default PortfolioPageHeader;
