import {
  lengthInRangeValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const fullNameOnAccount = buildInput({
  fieldKey: baseElphiFieldsSpecs.asset?.entitySpecs.fullNameOnAccount?.fieldKey,
  validation: stringValidation
});

export const accountName = buildInput({
  fieldKey: baseElphiFieldsSpecs.asset?.entitySpecs.accountName?.fieldKey,
  validation: stringValidation
});

export const accountType = buildInput({
  fieldKey: baseElphiFieldsSpecs.asset?.entitySpecs.accountType?.fieldKey,
  validation: singleSelectValidation
});

export const financialInstitution = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.asset?.entitySpecs.financialInstitution?.fieldKey,
  validation: stringValidation
});

export const last4Digits = buildInput({
  fieldKey: baseElphiFieldsSpecs.asset?.entitySpecs.last4Digits?.fieldKey,
  validation: lengthInRangeValidation(3, 4)
});

export const assetType = buildInput({
  fieldKey: baseElphiFieldsSpecs.asset?.entitySpecs.type?.fieldKey,
  validation: singleSelectValidation
});
