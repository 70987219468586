import {
  BooleanSelectType,
  FieldType,
  FundingShieldAggregationIntergrationFields,
  FundingShieldFlatIntergrationFields,
  IntegrationType
} from "@elphi/types";
import {
  FundingShieldTrxStatus,
  WireInsuranceVendorType
} from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import { mapEnumValues } from "../../../../../../components/utils/general.utils";
import { IntegrationsFieldSpecs } from "../../../types/integrations.types";

export type FundingShieldIntegrationFieldSpecs = IntegrationsFieldSpecs<
  FundingShieldAggregationIntergrationFields,
  FundingShieldFlatIntergrationFields
>;

export const fundingShieldIntegrationFieldSpecs: FundingShieldIntegrationFieldSpecs =
  {
    aggregations: {
      Integrations: {
        [IntegrationType.WireInsuranceCertificate]: {
          [WireInsuranceVendorType.FundingShield]: {
            TransactionId: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "TransactionId",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Funding Shield Transaction ID"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "TransactionId",
                  "focused"
                ],
                fieldType: FieldType.String,
                label: "Focus"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "TransactionId",
                  "override"
                ],
                fieldType: FieldType.String,
                label: "Funding Shield Transaction ID"
              }
            },
            TransactionStatus: {
              thirdParty: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "TransactionStatus",
                  "thirdParty"
                ],
                fieldType: FieldType.String,
                label: "Order Status"
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "TransactionStatus",
                  "focused"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Focus",
                options: mapEnumValues(FundingShieldTrxStatus)
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "TransactionStatus",
                  "override"
                ],
                fieldType: FieldType.SingleSelect,
                label: "Order Status",
                options: mapEnumValues(FundingShieldTrxStatus)
              }
            },
            StateNYIndicator: {
              calculated: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "StateNYIndicator",
                  "calculated"
                ],
                fieldType: FieldType.Boolean,
                label: "Is the deal in the state of NY?",
                options: mapEnumValues(BooleanSelectType)
              },
              focused: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "StateNYIndicator",
                  "focused"
                ],
                fieldType: FieldType.Boolean,
                label: "Focus"
              },
              override: {
                fieldKey: [
                  "aggregations",
                  "Integrations",
                  IntegrationType.WireInsuranceCertificate,
                  WireInsuranceVendorType.FundingShield,
                  "StateNYIndicator",
                  "override"
                ],
                fieldType: FieldType.Boolean,
                label: "Is the deal in the state of NY?",
                options: mapEnumValues(BooleanSelectType)
              }
            }
          }
        }
      }
    },
    Integrations: {
      [IntegrationType.WireInsuranceCertificate]: {
        [WireInsuranceVendorType.FundingShield]: {
          userFirstName: {
            fieldType: FieldType.String,
            label: "Funding Shield User First Name",
            fieldKey: [
              "Integrations",
              IntegrationType.WireInsuranceCertificate,
              WireInsuranceVendorType.FundingShield,
              "userFirstName"
            ]
          },
          userLastName: {
            fieldType: FieldType.String,
            label: "Funding Shield User Last Name",
            fieldKey: [
              "Integrations",
              IntegrationType.WireInsuranceCertificate,
              WireInsuranceVendorType.FundingShield,
              "userLastName"
            ]
          },
          userEmail: {
            fieldType: FieldType.Email,
            label: "Funding Shield User Email",
            fieldKey: [
              "Integrations",
              IntegrationType.WireInsuranceCertificate,
              WireInsuranceVendorType.FundingShield,
              "userEmail"
            ]
          },
          rushOrderIndicator: {
            fieldType: FieldType.Boolean,
            label: "Funding Shield Rush Order?",
            fieldKey: [
              "Integrations",
              IntegrationType.WireInsuranceCertificate,
              WireInsuranceVendorType.FundingShield,
              "rushOrderIndicator"
            ],
            options: mapEnumValues(BooleanSelectType)
          }
        }
      }
    }
  };
