import {
  FieldType,
  PolicyEntityBaseData,
  PropertyCoveragesFields
} from "@elphi/types";
import { booleanOptions } from "../../../../utils/formUtils";
import { EntityFormFieldSpecs } from "../../fields.types";

export type PropertyInsurancePolicyCoveragesFieldSpecs = EntityFormFieldSpecs<
  Pick<PolicyEntityBaseData<PropertyCoveragesFields>, "coverages">
>;

export const propertyInsurancePolicyCoveragesFieldBaseSpecs: PropertyInsurancePolicyCoveragesFieldSpecs =
  {
    coverages: {
      hazardCoverageAmount: {
        fieldKey: ["coverages", "hazardCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Hazard Coverage Amount"
      },
      hazardReplacementCoverage: {
        fieldKey: ["coverages", "hazardReplacementCoverage"],
        fieldType: FieldType.Boolean,
        label: "Hazard Replacement Coverage",
        options: booleanOptions
      },
      floodCoverageAmount: {
        fieldKey: ["coverages", "floodCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Flood Coverage Amount"
      },
      liabilityCoverageAmount: {
        fieldKey: ["coverages", "liabilityCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Liability Coverage Amount"
      },
      buildersRiskCoverageAmount: {
        fieldKey: ["coverages", "buildersRiskCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Builders Risk Coverage Amount"
      },
      rentLossCoverageAmount: {
        fieldKey: ["coverages", "rentLossCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Rent Loss Coverage Amount"
      },
      earthquakeCoverageAmount: {
        fieldKey: ["coverages", "earthquakeCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Earthquake Coverage Amount"
      },
      windCoverageAmount: {
        fieldKey: ["coverages", "windCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Wind Coverage Amount"
      },
      condoMasterHazardCoverageAmount: {
        fieldKey: ["coverages", "condoMasterHazardCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Condo Master Hazard Coverage Amount"
      },
      HO6CoverageAmount: {
        fieldKey: ["coverages", "HO6CoverageAmount"],
        fieldType: FieldType.Money,
        label: "HO6 Coverage Amount"
      },
      condoMasterFloodCoverageAmount: {
        fieldKey: ["coverages", "condoMasterFloodCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Condo Master Flood Coverage Amount"
      },
      CAFairPlanCoverageAmount: {
        fieldKey: ["coverages", "CAFairPlanCoverageAmount"],
        fieldType: FieldType.Money,
        label: "CA Fair Plan Coverage Amount"
      },
      excessFlood: {
        fieldKey: ["coverages", "excessFlood"],
        fieldType: FieldType.Money,
        label: "Excess Flood"
      },
      lavaCoverageAmount: {
        fieldKey: ["coverages", "lavaCoverageAmount"],
        fieldType: FieldType.Money,
        label: "Lava Coverage Amount"
      }
    }
  };
