import { SystemSetup } from "@elphi/types";
import { baseSectionsLoader } from "../base/baseSectionsLoader";
import { limaSectionsLoader } from "../lima/limaSectionsLoader";
import { mofinSectionsLoader } from "../mofin/mofinSectionsLoader";
import { getDomainOwner } from "../utils/formCommon.utils";
import { fieldSpecsMap } from "./specsFactory";

export const getSectionsLoader = (owner?: SystemSetup) => {
  const specsOwner = owner || getDomainOwner();
  switch (specsOwner) {
    case SystemSetup.Lima:
      return limaSectionsLoader({ specs: fieldSpecsMap[SystemSetup.Lima] });
    case SystemSetup.Mofin:
      return mofinSectionsLoader({ specs: fieldSpecsMap[SystemSetup.Mofin] });
    default:
      return baseSectionsLoader({ specs: fieldSpecsMap[SystemSetup.Elphi] });
  }
};
