import {
  DataSheetCellMetadata,
  FieldType,
  LongTermPortfolioTemplate,
  PortfolioPageContainerProps,
  Property,
  PropertyCellData,
  SelectedCells
} from "@elphi/types";
import lodash from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../../apis/rtk/response.handler";
import { auth } from "../../firebase/firebaseConfig";
import { RootState } from "../../redux/store";
import { dealApi } from "../../redux/v2/deal";
import { dealPropertiesSelector } from "../../redux/v2/deal/deal.slice";
import { propertyApi } from "../../redux/v2/property";
import { removeNulls } from "../../utils/filter.utils";
import { DataSheetCellState } from "../data-sheet/dataSheet.types";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { useElphiToast } from "../toast/toast.hook";
import PortfolioPage, {
  PortfolioPagePropertiesState,
  PortfolioPageProps
} from "./PortfolioPage";
import {
  fieldToCalculation,
  portfolioTemplateToFieldTypes
} from "./utils/portfolio.utils";

const PortfolioPageLiveStateContainer = (
  props: PortfolioPageContainerProps
) => {
  const dealProperties = useSelector((state: RootState) =>
    dealPropertiesSelector(state, props.dealId)
  );
  const [isReadOnlyMode, setIsReadOnlyMode] = useState(false);
  const [selectedCells, setSelectedCells] = useState<SelectedCells>();
  const [getDealProperties, dealPropertiesResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const [updatePropertyBatchApi, updatePropertyBatchResponse] =
    propertyApi.useUpdateBatchMutation();
  const { errorToast, successToast } = useElphiToast();
  const [uploadPortfolio, uploadResponse] =
    dealApi.useUploadPortfolioFileMutation();
  const { onChange, diffState, setDiffState, setState } =
    useFormBuilderStateHandler({
      initialState: {} as PortfolioPagePropertiesState
    });

  const actionHandler = (r: {
    newState: DataSheetCellState;
    iterateSelectedCells: (
      selectedCells: SelectedCells | undefined,
      cellCallback: (cell: PropertyCellData) => void
    ) => void;
  }) => {
    const { newState } = r;
    const diffMeta: {
      [id: string]: {
        SheetMetadata: {
          [K in keyof LongTermPortfolioTemplate]: DataSheetCellMetadata;
        };
      };
    } = {};
    r.iterateSelectedCells(selectedCells, (cell) => {
      lodash.set(
        diffMeta,
        [cell.propertyId, "SheetMetadata", cell.key, "state"],
        newState
      );
    });
    const newDiff = lodash.merge({}, diffState, diffMeta);
    setDiffState({ ...newDiff });
  };

  const overrideHandler = (r: {
    iterateSelectedCells: (
      selectedCells: SelectedCells | undefined,
      cellCallback: (cell: PropertyCellData) => void
    ) => void;
  }) => {
    r.iterateSelectedCells(selectedCells, (cell) => {
      const calculationFunc = fieldToCalculation[cell.key];
      if (calculationFunc) {
        const newValue = calculationFunc({
          state: dealProperties,
          changedCell: cell,
          diffState
        });

        //set calculated value
        onChange({
          fieldType: portfolioTemplateToFieldTypes[cell.key],
          fieldKey: [cell!.propertyId, cell.key],
          value: newValue
        });

        //reset override state
        onChange({
          fieldType: FieldType.String,
          fieldKey: [cell!.propertyId, "SheetMetadata", cell.key, "override"],
          value: false
        });
      }
    });
  };

  const updatePropertyBatch = (v: Partial<PortfolioPagePropertiesState>) => {
    const properties = Object.keys(v)
      .map((id) => {
        return {
          ...v[id],
          id
        };
      })
      .filter((v) => v !== undefined);
    if (!properties.length) return;

    updatePropertyBatchApi({ properties } as {
      properties: ({
        id: string;
      } & Partial<Property>)[];
    })
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          successToast({
            title: "properties updated",
            description: `${properties.length} properties updated`
          });
          setState({});
          setDiffState({});
        }
        if (r.status === 400) {
          errorToast({
            title: "failed to update properties",
            description: r.data.description
          });
        }
      });
  };

  const debounceSetSelectedCells = lodash.debounce(
    (selection: SelectedCells) => {
      setSelectedCells(selection);
    },
    300
  );

  const debounceCallback = useCallback((selection: SelectedCells) => {
    debounceSetSelectedCells(selection);
  }, []);

  const onUploadPortfolioHandler = (file: File) => {
    uploadPortfolio({ dealId: props.dealId, file })
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          successToast({
            title: "file uploaded",
            description: `${r.data.properties.length} properties created`
          });
          setDiffState({});
          setState({});
        }
        if (r.status === 400) {
          console.log(r.data.error);
          errorToast({
            title: "failed to upload",
            description: r.data.description
          });
        }
      });
  };

  useEffect(() => {
    if (auth.currentUser) {
      getDealProperties([props.dealId], true);
    }
  }, [auth.currentUser, props.dealId]);

  useEffect(() => {
    setIsReadOnlyMode(false);
  }, []);

  const portfolioPageProps: PortfolioPageProps = useMemo(() => {
    return {
      dealProperties: dealProperties.filter(removeNulls),
      isReadOnlyMode: isReadOnlyMode,
      updatePropertyBatch: updatePropertyBatch,
      updatePropertyBatchResponseIsLoading:
        updatePropertyBatchResponse.isLoading,
      diffState: diffState,
      uploadResponseIsLoading: uploadResponse.isLoading,
      onUploadPortfolioHandler: onUploadPortfolioHandler,
      actionHandler: actionHandler,
      overrideHandler: overrideHandler,
      dealPropertiesResponseIsFetching: dealPropertiesResponse.isFetching,
      dealPropertiesResponseIsSuccess: dealPropertiesResponse.isSuccess,
      onChange: onChange,
      debounceCallback: debounceCallback
    };
  }, [
    dealProperties,
    isReadOnlyMode,
    updatePropertyBatch,
    updatePropertyBatchResponse.isLoading,
    diffState,
    uploadResponse.isLoading,
    onUploadPortfolioHandler,
    actionHandler,
    overrideHandler,
    dealPropertiesResponse.isFetching,
    dealPropertiesResponse.isSuccess,
    onChange,
    debounceCallback
  ]);

  return <PortfolioPage {...portfolioPageProps} />;
};

export default PortfolioPageLiveStateContainer;
