import { DocumentConfiguration } from "@elphi/types";
import { useDocumentConfigurationHooks } from "../../../hooks/documentConfiguration.hooks";
import { SearchHandler } from "../../search/SearchHandler";
import { SearchComponentProps } from "../../SearchComponent";

const buildOption = (documentConfiguration: DocumentConfiguration) => {
  const label = `${documentConfiguration?.name}`;
  const value = documentConfiguration.id;
  return {
    label,
    value
  };
};

const DocumentConfigurationSearch = (
  props: {
    filter?: (a: DocumentConfiguration) => boolean;
  } & Pick<
    SearchComponentProps,
    | "onSelect"
    | "currentValue"
    | "label"
    | "labelPosition"
    | "isReadOnly"
    | "isDisabled"
  > &
    Pick<Partial<SearchComponentProps>, "fieldType">
) => {
  const {
    searchDocumentConfigurationApi,
    rankedSort,
    documentConfigurationState,
    searchResponse
  } = useDocumentConfigurationHooks();
  return (
    <SearchHandler
      {...props}
      searchApi={searchDocumentConfigurationApi}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={documentConfigurationState}
      buildOption={buildOption}
    />
  );
};

export default DocumentConfigurationSearch;
