import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { dealPropertyRelationApi } from "../redux/v2/deal-property-relation";

export const useDealPropertyRelationHooks = () => {
  const dealPropertyRelationState = useSelector((state: RootState) => {
    return state.dealPropertyRelation;
  });
  const [getDealPropertyRelationApi, getDealPropertyRelationApiResponse] =
    dealPropertyRelationApi.useLazyDealPropertyBatchQuery();
  return {
    dealPropertyRelationState,
    getDealPropertyRelationApi,
    getDealPropertyRelationApiResponse
  };
};
