import { Flex, Skeleton, SkeletonProps, SkeletonText } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSkeletonVisibility } from "../../hooks/useAddSkeletonVisibility";

export const CardsContainer = ({
  isLoading,
  children,
  isNewProviderProcess
}: {
  isLoading: boolean;
  children: JSX.Element;
  isNewProviderProcess?: boolean;
}) => {
  const childrenCount = useMemo(
    () => children.props.children.length,
    [children]
  );

  const showSkeleton = useSkeletonVisibility(
    isNewProviderProcess || false,
    childrenCount
  );

  return (
    <Flex gap="12px" wrap="wrap">
      {isLoading ? (
        <CardsSkeleton />
      ) : (
        <>
          {children}
          {showSkeleton && (
            <CardsSkeleton skeletonStyle={{ borderRadius: "10" }} amount={1} />
          )}
        </>
      )}
    </Flex>
  );
};

export const CardsSkeleton = ({
  amount = 10,
  skeletonStyle = {}
}: {
  amount?: number;
  skeletonStyle?: SkeletonProps;
}) => {
  const skeletons = useMemo(
    () =>
      Array.from({ length: amount }, (_, i) => (
        <Skeleton key={i} width="400px" height="80px" {...skeletonStyle}>
          <SkeletonText />
        </Skeleton>
      )),
    [amount, skeletonStyle]
  );

  return <>{skeletons}</>;
};
