import { AggregationType, PricingVendorType } from "@elphi/types";
import { stringValidation } from "../../../../../components/utils/validationUtils";
import { buildInput } from "../../../utils/formBuilder.utils";
import { integrationSpecs } from "../../spec-files/integrationFieldsSpecs";

const { spec } = integrationSpecs.deal.Pricing[PricingVendorType.Loanpass];
export const pipelineRecordId = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .pipelineRecordId?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const pipelineRecordName = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .pipelineRecordName?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const investorName = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .investorName?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const productName = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .productName?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const loanPurpose = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .loanPurpose?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const propertyType = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .propertyType?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const stateCode = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass].stateCode
      ?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const appraisedValue = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .appraisedValue?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const decisionCreditScore = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .decisionCreditScore?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const citizenshipType = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .citizenshipType?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const mortgageLatePayments = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .mortgageLatePayments?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const baseLoanAmount = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .baseLoanAmount?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const adjustedRate = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .adjustedRate?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const adjustedPrice = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .adjustedPrice?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const priceAdjustments = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .priceAdjustments?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const lockStatus = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .lockStatus?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const requestDate = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .requestDate?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const expirationDate = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .expirationDate?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const lockPeriod = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .lockPeriod?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const prepaymentPenaltyTerm = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .prepaymentPenaltyTerm?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const stipulations = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass]
      .stipulations?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
export const totalLLPA = buildInput({
  fieldKey:
    spec.aggregations.Integrations.Pricing[PricingVendorType.Loanpass].totalLLPA
      ?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});
