import { InsurancePolicyCoverages, InsurancePolicyView } from "@elphi/types";
import { EntityState, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { insurancePolicyViewAdapter } from "./insurancePolicyView.adapter";
import { insurancePolicyViewApi } from "./insurancePolicyView.service";

export type InsurancePolicyViewFilterState = Partial<{
  domainConfigurations: string[];
  statuses: string[];
  coverageTypes: string[];
  freeText: string;
}>;

export type InsurancePolicyViewSliceState = EntityState<
  InsurancePolicyView<InsurancePolicyCoverages>
> & {
  current: {
    filter: InsurancePolicyViewFilterState;
  };
};

const initialState: InsurancePolicyViewSliceState = {
  ids: [],
  entities: {},
  current: {
    filter: {}
  }
};

export const insurancePolicyViewSlice = createSlice({
  name: "insurancePolicyView",
  initialState: insurancePolicyViewAdapter.getInitialState(initialState),
  reducers: {
    update: insurancePolicyViewAdapter.updateOne,
    remove: insurancePolicyViewAdapter.removeOne,
    add: insurancePolicyViewAdapter.addOne,
    upsert: insurancePolicyViewAdapter.upsertOne,
    upsertMany: insurancePolicyViewAdapter.upsertMany,
    removeMany: insurancePolicyViewAdapter.removeMany,
    updateMany: insurancePolicyViewAdapter.updateMany,
    setFilter: (
      state,
      action: PayloadAction<InsurancePolicyViewFilterState>
    ) => {
      state.current.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      insurancePolicyViewApi.endpoints.getAll.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.result.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.result, "id")
        };
      }
    );
  }
});
