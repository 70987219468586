import { Asset, PartyAsset, Statement } from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { RootState, store } from "../redux/store";
import { assetApi } from "../redux/v2/asset/asset.service";
import { assetSlice } from "../redux/v2/asset/asset.slice";
import { partyAssetApi } from "../redux/v2/party-asset-relation";
import { getListFromDictionary } from "../utils/batchUtils";
import { compareStrings } from "../utils/searchUtils";

export const useAssetHooks = () => {
  const dispatcher = useDispatch();
  const [searchAsset, searchResponse] = assetApi.useLazySearchQuery();
  const [updateBatchApi, updateBatchResponse] =
    assetApi.useBatchUpdateMutation();
  const [deleteAssetApi, deleteAssetResponse] = assetApi.useDeleteMutation();
  const [getAsset, getAssetResponse] = assetApi.useLazyGetQuery();
  const [updateAssetApi] = assetApi.useUpdateMutation();
  const [createAssetApi, createAssetApiResponse] = assetApi.useCreateMutation();
  const [addAssetToPartyApi, addAssetToPartyResponse] =
    assetApi.useAddAssetToPartyMutation();
  const [addStatementToAssetApi, addStatementToAssetResponse] =
    assetApi.useAddStatementToAssetMutation();
  const [getLatestStatementsApi, getLatestStatementsResponse] =
    assetApi.useLazyGetLatestStatementsQuery();
  const [getAssetStatementsApi, getAssetStatementsResponse] =
    assetApi.useLazyGetAssetStatementsQuery();
  const [createRelationsApi, createRelationsResponse] =
    partyAssetApi.useCreateRelationsMutation();
  const [deleteBatchPartyAssetRelationApi, deleteBatchPartyAssetRelationResponse] =
    partyAssetApi.useBatchDeleteMutation();
  const [getBatchAssetPartyApi, getBatchAssetPartyResponse] =
    assetApi.useLazyGetBatchAssetPartyQuery()

  const setSelectedAsset = (id: string) =>
    store.dispatch(assetSlice.actions.selectedId({ id }));

  const selectedAsset = useSelector((state: RootState) =>
    state.asset.selectedId
      ? state.asset.entities[state.asset.selectedId]
      : undefined
  );
  const assetState = useSelector((state: RootState) => state.asset);

  const rankedSort = (query: string) => {
    return Object.values(assetState.entities).sort((a, b) => {
      const assetAAccountName = a?.accountName || "";
      const assetAAccountType = a?.accountType || "";
      const assetAFinancialInstitution = a?.financialInstitution || "";
      const assetAFullNameOnAccount = a?.fullNameOnAccount || "";

      const assetBAccountName = b?.accountName || "";
      const assetBAccountType = b?.accountType || "";
      const assetBFinancialInstitution = b?.financialInstitution || "";
      const assetBFullNameOnAccount = b?.fullNameOnAccount || "";

      const rankA =
        compareStrings(query, assetAAccountName) * 25 +
        compareStrings(query, assetAAccountType) * 25 +
        compareStrings(query, assetAFinancialInstitution) * 25 +
        compareStrings(query, assetAFullNameOnAccount) * 25;

      const rankB =
        compareStrings(query, assetBAccountName) * 25 +
        compareStrings(query, assetBAccountType) * 25 +
        compareStrings(query, assetBFinancialInstitution) * 25 +
        compareStrings(query, assetBFullNameOnAccount) * 25;
      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const createAsset = async (newAsset: Asset) => {
    const response = await createAssetApi(newAsset);
    return responseHandler(response);
  };
  const addAssetToParty = async (r: { partyId: string; asset: Asset }) => {
    const { partyId, asset } = r;
    const response = await addAssetToPartyApi({ partyId, asset });
    return responseHandler(response);
  };
  const updateAsset = async (newAsset: { id: string } & Partial<Asset>) => {
    const response = await updateAssetApi(newAsset);
    return responseHandler(response);
  };

  const deleteAsset = async (r: string) => {
    const response = await deleteAssetApi(r).then(responseHandler);
    if (response.status === 200) {
      dispatcher(assetSlice.actions.remove(r));
    }
    return response;
  };
  const updateBatch = async (r: {
    assets: ({ id: string } & Partial<Asset>)[];
  }) => {
    const response = await updateBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };
  const updateAssetsHandler = async (
    assetsMap:
      | {
          [id: string]: {
            id: string;
          } & Partial<Asset>;
        }
      | undefined
  ) => {
    if (!assetsMap) return;
    const assets = getListFromDictionary(assetsMap);
    if (!assets.length) return;
    return await updateBatch({ assets } as {
      assets: ({
        id: string;
      } & Partial<Asset>)[];
    });
  };
  const addStatementToAsset = async (r: {
    assetId: string;
    statement: Statement;
  }) => {
    const response = await addStatementToAssetApi(r);
    return responseHandler(response);
  };
  const getLatestStatements = async (r: { assetIds: string[] }) => {
    const response = await getLatestStatementsApi(r, true);
    return responseHandler(
      response as RTKResponse<{
        statements: Statement[];
      }>
    );
  };

  const getAssetStatements = async (r: { assetIds: string[] | EntityId[] }) => {
    const { assetIds } = r;
    const response = await getAssetStatementsApi(
      {
        assetIds: assetIds as string[]
      },
      true
    );
    return responseHandler(
      response as RTKResponse<{
        statements: Statement[];
      }>
    );
  };

  const createRelations = async (r: { partyId: string; assetId: string }[]) => {
    const response = await createRelationsApi(r);
    return responseHandler(response as RTKResponse<PartyAsset[]>);
  };

  const deleteBatchPartyAssetRelation = async (r: { relations: string[] }) => {
    const response = await deleteBatchPartyAssetRelationApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const getBatchAsssetParty = async (assetIds: string[]) => {
    const response = await getBatchAssetPartyApi({ assetIds }, true);
    return responseHandler(response as RTKResponse<typeof response.data>);
  };

  return {
    searchAsset,
    searchResponse,
    rankedSort,
    getAsset,
    getAssetResponse,
    createAsset,
    createAssetApiResponse,
    updateAsset,
    setSelectedAsset,
    selectedAsset,
    assetState,
    addAssetToParty,
    addAssetToPartyResponse,
    updateBatch,
    updateBatchResponse,
    updateAssetsHandler,
    deleteAsset,
    deleteAssetResponse,
    addStatementToAsset,
    addStatementToAssetResponse,
    getLatestStatements,
    getLatestStatementsResponse,
    getAssetStatements,
    getAssetStatementsResponse,
    createRelations,
    createRelationsResponse,
    deleteBatchPartyAssetRelation,
    deleteBatchPartyAssetRelationResponse,
    getBatchAsssetParty,
    getBatchAssetPartyResponse,
  };
};

export default useAssetHooks;
