import { TaskType } from "@elphi/types";
import { orderStatusResolverConfigTypeMap } from "./configs";
import { OrderStatusDataResolver, OrderStatusResolverConfig } from "./types";
import {
  resolveIntegrationOrderStatus,
  resolveTemplateOrderStatus
} from "./utils/resolver.utils";

const orderStatusResolver = ({
  ignoreTemplateOrderStatus,
  fallbackLabel
}: OrderStatusResolverConfig): OrderStatusDataResolver => {
  return {
    resolve: (request) => {
      const {
        data: { task },
        isAggregation,
        integrationOrderStatusPath,
        mapper
      } = request;

      const integrationOrderStatus =
        integrationOrderStatusPath &&
        resolveIntegrationOrderStatus({
          data: request.data,
          path: integrationOrderStatusPath,
          isAggregation,
          mapper
        });

      const templateStatus = !ignoreTemplateOrderStatus
        ? resolveTemplateOrderStatus({ task }) || fallbackLabel
        : fallbackLabel;

      return { orderStatus: integrationOrderStatus || templateStatus };
    }
  };
};

export const orderStatusResolverFactory = (taskType: TaskType) => {
  const resolverMap: {
    [t in TaskType]: OrderStatusDataResolver;
  } = {
    [TaskType.Integration]: orderStatusResolver(
      orderStatusResolverConfigTypeMap(TaskType.Integration)
    ),
    [TaskType.Document]: orderStatusResolver(
      orderStatusResolverConfigTypeMap(TaskType.Document)
    ),
    [TaskType.DocumentOrderStatus]: orderStatusResolver(
      orderStatusResolverConfigTypeMap(TaskType.DocumentOrderStatus)
    )
  };
  return resolverMap[taskType];
};
