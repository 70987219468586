import {
  BranchServiceProvider,
  BranchServiceProviderFields,
  CompanyServiceProvider,
  CompanyServiceProviderFields,
  ElphiEntityType,
  FieldType,
  RepresentativeServiceProviderFields,
  RolodexConfiguration,
  RolodexEntityFields,
  RolodexServiceProvider,
  ServiceProviderType
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { omit, pick } from "lodash";
import { EMPTY } from "../../../../../../constants/common";
import { getSpecs } from "../../../../../../forms/schemas/factories/specsFactory";
import { buildSection } from "../../../../../../forms/schemas/utils/formBuilder.utils";
import {
  BranchServiceProviderRequest,
  CompanyServiceProviderRequest,
  RepServiceProviderRequest
} from "../../../../../../redux/v2/rolodex";
import {
  OnChangeInput,
  Section
} from "../../../../../form-builder/FormBuilder";
import { InputBuilderFieldSpecs } from "../../../../../form-builder/field-specs/fields.types";
import { BaseInputFormatter } from "../../../../../form-builder/formatters/formatter.types";
import { einInputFormatter } from "../../../../../form-builder/formatters/inputs.formatter";
import {
  dateValidation,
  einValidation,
  emailValidation,
  multiSelectValidation,
  numberValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../../utils/validationUtils";

const getSelectedFields = (fields?: RolodexEntityFields) => {
  return new Map(
    Object.values(fields || [])?.map((field) => [
      field.fieldPath,
      field.isRequired
    ])
  );
};

const isHidden = (r: {
  fields: Map<string, boolean>;
  fieldSpec?: InputBuilderFieldSpecs<any>;
}) => {
  if (!r?.fieldSpec?.fieldKey) {
    return true;
  }
  const key = r.fieldSpec.fieldKey.join(".");
  return !r.fields.has(key);
};

const isRequired = (r: {
  fields: Map<string, boolean>;
  fieldSpec?: InputBuilderFieldSpecs<any>;
}) => {
  if (!r?.fieldSpec?.fieldKey) {
    return false;
  }
  const key = r.fieldSpec.fieldKey.join(".");
  if (r.fields.has(key)) {
    return !!r.fields.get(key);
  }
  return false;
};

const buildInput = (r: {
  fieldSpec?: InputBuilderFieldSpecs<any>;
  selectedFields: Map<string, boolean>;
  isReadOnly: boolean;
  validation: (v: any, isRequired?: boolean) => boolean;
  formatter?: BaseInputFormatter<FieldType, any, any>;
}) => {
  const { fieldSpec, selectedFields, isReadOnly, validation, formatter } = r;

  const isFieldRequired = isRequired({
    fields: selectedFields,
    fieldSpec
  });

  return {
    path: fieldSpec?.fieldKey,
    validation: (v) => validation(v, isFieldRequired),
    isHidden: isHidden({
      fields: selectedFields,
      fieldSpec
    }),
    isRequired: isFieldRequired,
    isReadOnly,
    formatter,
    isAggregation: undefined
  };
};

const getServiceProviderSpec = () => {
  const specs = getSpecs();
  return specs?.[ElphiEntityType.serviceProvider];
};

type ServiceProviderSection = {
  state: Partial<RolodexServiceProvider>;
  onChange: (v: OnChangeInput) => void;
  configuration?: RolodexConfiguration;
  isReadOnly: boolean;
  showAttached: boolean;
};

const companyServiceProviderSection = (r: ServiceProviderSection): Section => {
  const { state, onChange, configuration, isReadOnly, showAttached } = r;
  const spec = getServiceProviderSpec();
  if (!configuration) {
    return { inputs: [] };
  }

  const selectedFields = getSelectedFields(
    configuration?.fields?.companyFields
  );

  const name = buildInput({
    fieldSpec: spec?.specsParts?.companyServiceProviderFieldsSpecs?.name,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const status = buildInput({
    fieldSpec: spec?.specsParts?.companyServiceProviderFieldsSpecs?.status,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const ein = buildInput({
    fieldSpec: spec?.specsParts?.companyServiceProviderFieldsSpecs?.ein,
    selectedFields,
    isReadOnly,
    validation: einValidation,
    formatter: einInputFormatter
  });

  const experienceLevel = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs?.experienceLevel,
    selectedFields,
    isReadOnly,
    validation: numberValidation
  });

  const notes = buildInput({
    fieldSpec: spec?.specsParts?.companyServiceProviderFieldsSpecs?.notes,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const crmId = buildInput({
    fieldSpec: spec?.specsParts?.companyServiceProviderFieldsSpecs?.crmId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const partyGroupId = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs?.partyGroupId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });
  const insuranceExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.insuranceExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });
  const workersCompensationExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.workersCompensationExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });
  const licenseExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.licenseExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });

  return buildSection({
    hideAttachedComponent: !showAttached,
    state: showAttached ? state : omit(state, "fieldMeta"),
    onChange,
    header: EMPTY,
    inputs: [
      name,
      status,
      ein,
      experienceLevel,
      notes,
      crmId,
      partyGroupId,
      insuranceExpirationDate,
      workersCompensationExpirationDate,
      licenseExpirationDate
    ],
    fieldSpecs: spec?.specsParts?.companyServiceProviderFieldsSpecs || {}
  });
};

const branchServiceProviderSection = (r: ServiceProviderSection): Section => {
  const { state, onChange, configuration, isReadOnly, showAttached } = r;
  const spec = getServiceProviderSpec();
  if (!configuration) {
    return { inputs: [] };
  }

  const selectedFields = getSelectedFields(configuration?.fields?.branchFields);

  const name = buildInput({
    fieldSpec: spec?.specsParts?.branchServiceProviderFieldsSpecs.name,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const ein = buildInput({
    fieldSpec: spec?.specsParts?.branchServiceProviderFieldsSpecs?.ein,
    selectedFields,
    isReadOnly,
    validation: einValidation,
    formatter: einInputFormatter
  });

  const status = buildInput({
    fieldSpec: spec?.specsParts?.branchServiceProviderFieldsSpecs.status,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const coverageArea = buildInput({
    fieldSpec: spec?.specsParts?.branchServiceProviderFieldsSpecs?.coverageArea,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const email = buildInput({
    fieldSpec: spec?.specsParts?.branchServiceProviderFieldsSpecs?.email,
    selectedFields,
    isReadOnly,
    validation: emailValidation
  });

  const lendingPartnerType = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.lendingPartnerType,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const nmlsLicenseNumber = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.nmlsLicenseNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const numberOfEmployees = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.numberOfEmployees,
    selectedFields,
    isReadOnly,
    validation: numberValidation
  });

  const phoneNumber = buildInput({
    fieldSpec: spec?.specsParts?.branchServiceProviderFieldsSpecs?.phoneNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const wireAccountNumber = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.wireAccountNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const wireNameOnAccount = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.wireNameOnAccount,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const wireRoutingNumber = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.wireRoutingNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const addressLineText = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.Address
        ?.AddressLineText,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const addressUnitIdentifier = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.Address
        ?.AddressUnitIdentifier,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const cityName = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.Address?.CityName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const countyName = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.Address?.CountyName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const postalCode = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.Address?.PostalCode,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const stateName = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.Address?.StateName,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const stateCode = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.Address?.StateCode,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const experienceLevel = buildInput({
    fieldSpec:
      spec?.specsParts?.branchServiceProviderFieldsSpecs?.experienceLevel,
    selectedFields,
    isReadOnly,
    validation: numberValidation
  });

  const notes = buildInput({
    fieldSpec: spec?.specsParts?.branchServiceProviderFieldsSpecs?.notes,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const crmId = buildInput({
    fieldSpec: spec?.specsParts?.branchServiceProviderFieldsSpecs?.crmId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const partyGroupId = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs?.partyGroupId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });
  const insuranceExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.insuranceExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });
  const workersCompensationExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.workersCompensationExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });
  const licenseExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.licenseExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });

  return buildSection({
    hideAttachedComponent: !showAttached,
    state: showAttached ? state : omit(state, "fieldMeta"),
    onChange,
    fieldSpecs: spec?.specsParts?.branchServiceProviderFieldsSpecs || {},
    header: EMPTY,
    inputs: [
      name,
      status,
      ein,
      addressLineText,
      addressUnitIdentifier,
      cityName,
      countyName,
      stateName,
      stateCode,
      postalCode,
      phoneNumber,
      email,
      nmlsLicenseNumber,
      numberOfEmployees,
      coverageArea,
      lendingPartnerType,
      wireAccountNumber,
      wireNameOnAccount,
      wireRoutingNumber,
      experienceLevel,
      notes,
      crmId,
      partyGroupId,
      insuranceExpirationDate,
      workersCompensationExpirationDate,
      licenseExpirationDate
    ]
  });
};

const representativeServiceProviderSection = (
  r: ServiceProviderSection
): Section => {
  const { state, onChange, configuration, isReadOnly, showAttached } = r;
  const spec = getServiceProviderSpec();
  if (!configuration) {
    return { inputs: [] };
  }

  const selectedFields = getSelectedFields(
    configuration?.fields?.representativeFields
  );

  const firstName = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs.firstName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const lastName = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs.lastName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const middleName = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs?.middleName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const status = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs.status,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const birthDate = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs
      ?.birthDate as InputBuilderFieldSpecs<any>,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });

  const phoneNumber = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs?.phoneNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const nmlsLicenseNumber = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.nmlsLicenseNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const licenseNumber = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs?.licenseNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const driversLicenseNumber = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.driversLicenseNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const addressLineText = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.Address?.AddressLineText,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const addressUnitIdentifier = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.Address
        ?.AddressUnitIdentifier,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const cityName = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.Address?.CityName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const countyName = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.Address?.CountyName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const postalCode = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.Address?.PostalCode,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const stateName = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.Address?.StateName,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const stateCode = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs?.Address?.StateCode,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const businessRegistrationStates = buildInput({
    fieldSpec:
      spec?.specsParts?.repServiceProviderFieldsSpecs
        ?.businessRegistrationStates,
    selectedFields,
    isReadOnly,
    validation: multiSelectValidation
  });

  const email = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs?.email,
    selectedFields,
    isReadOnly,
    validation: emailValidation
  });

  const experienceLevel = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs?.experienceLevel,
    selectedFields,
    isReadOnly,
    validation: numberValidation
  });

  const notes = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs?.notes,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const crmId = buildInput({
    fieldSpec: spec?.specsParts?.repServiceProviderFieldsSpecs?.crmId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const partyGroupId = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs?.partyGroupId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });
  const insuranceExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.insuranceExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });
  const workersCompensationExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.workersCompensationExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });
  const licenseExpirationDate = buildInput({
    fieldSpec:
      spec?.specsParts?.companyServiceProviderFieldsSpecs
        ?.licenseExpirationDate,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });

  return buildSection({
    hideAttachedComponent: !showAttached,
    state: showAttached ? state : omit(state, "fieldMeta"),
    onChange,
    fieldSpecs: spec?.specsParts?.repServiceProviderFieldsSpecs || {},
    header: EMPTY,
    inputs: [
      firstName,
      middleName,
      lastName,
      status,
      addressLineText,
      addressUnitIdentifier,
      cityName,
      countyName,
      stateName,
      stateCode,
      postalCode,
      email,
      birthDate,
      phoneNumber,
      nmlsLicenseNumber,
      licenseNumber,
      driversLicenseNumber,
      businessRegistrationStates,
      experienceLevel,
      notes,
      crmId,
      partyGroupId,
      insuranceExpirationDate,
      workersCompensationExpirationDate,
      licenseExpirationDate
    ]
  });
};

export const providerFormSectionBuilder = (
  r: ServiceProviderSection
): Section => {
  const { state, configuration } = r;
  const { type } = state;
  if (!type || !configuration) {
    return { inputs: [] };
  }
  if (type === ServiceProviderType.Company) {
    return companyServiceProviderSection(r);
  }
  if (type === ServiceProviderType.Branch) {
    return branchServiceProviderSection(r);
  }
  if (type === ServiceProviderType.Representative) {
    return representativeServiceProviderSection(r);
  }
  return { inputs: [] };
};

export const companyServiceProviderFields: DotNestedKeys<CompanyServiceProviderFields>[] =
  [
    "ein",
    "name",
    "status",
    "notes",
    "crmId",
    "experienceLevel",
    "partyGroupId",
    "insuranceExpirationDate",
    "workersCompensationExpirationDate",
    "licenseExpirationDate"
  ];

export const branchServiceProviderFields: DotNestedKeys<BranchServiceProviderFields>[] =
  [
    "ein",
    "name",
    "status",
    "Address",
    "coverageArea",
    "email",
    "lendingPartnerType",
    "nmlsLicenseNumber",
    "numberOfEmployees",
    "phoneNumber",
    "wireAccountNumber",
    "wireNameOnAccount",
    "wireRoutingNumber",
    "notes",
    "experienceLevel",
    "crmId",
    "partyGroupId",
    "insuranceExpirationDate",
    "workersCompensationExpirationDate",
    "licenseExpirationDate"
  ];
export const repServiceProviderFields: DotNestedKeys<RepresentativeServiceProviderFields>[] =
  [
    "status",
    "Address",
    "email",
    "nmlsLicenseNumber",
    "phoneNumber",
    "birthDate",
    "businessRegistrationStates",
    "driversLicenseNumber",
    "firstName",
    "lastName",
    "licenseNumber",
    "middleName",
    "notes",
    "experienceLevel",
    "crmId",
    "partyGroupId",
    "insuranceExpirationDate",
    "workersCompensationExpirationDate",
    "licenseExpirationDate"
  ];

export const getCompanyTemplate = (
  r: Partial<CompanyServiceProvider>
): CompanyServiceProviderRequest => {
  const props = ["id", "domainConfigurationId"].concat(
    companyServiceProviderFields
  );
  return pick(r, props);
};

export const getBranchTemplate = (
  r: Partial<BranchServiceProvider>
): BranchServiceProviderRequest => {
  const props = ["id", "companyId"].concat(branchServiceProviderFields);
  return pick(r, props);
};

export const getRepTemplate = (
  r: Partial<RepresentativeServiceProviderFields>
): RepServiceProviderRequest => {
  const props = ["id", "companyId", "skipped"].concat(repServiceProviderFields);
  return pick(r, props);
};

export const sanitizeSpace = (q: string) => decodeURIComponent(q);

export const getRepFullName = (q: string) => {
  const sanitizedName = sanitizeSpace(q);
  const fullName = sanitizedName.split(" ");
  return fullName.length === 2
    ? {
        firstName: fullName[0],
        lastName: fullName[1]
      }
    : {
        firstName: sanitizedName
      };
};
