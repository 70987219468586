import { BaseEntity } from "../db/baseEntity.types";

export enum LoanPartyEntityRoleType {
  PrimaryBorrower = "Primary Borrower",
  Borrower = "Borrower",
  None = "None",
  DecideLater = "Decide Later"
}

export enum LoanPartyIndividualRoleType {
  Guarantor = "Guarantor",
  Sponsor = "Sponsor",
  Signatory = "Signatory",
  SignatoryCorporateResolution = "Signatory via Corporate Resolution",
  Applicant = "Applicant",
  PrimarySponsor = "Primary Sponsor",
  None = "None",
  DecideLater = "Decide Later"
}

export enum DealPartyRelationType {
  DealEntity = "Deal-To-Entity",
  DealIndividual = "Deal-To-Individual"
}

export type BaseDealPartyRelation<TType extends DealPartyRelationType> =
  BaseEntity<{
    type: TType;
    dealId: string;
    partyId: string;
  }>;

export type DealToIndividualRelation =
  BaseDealPartyRelation<DealPartyRelationType.DealIndividual> & {
    relationRoleType: LoanPartyIndividualRoleType[];
  };

export type DealToEntityRelation =
  BaseDealPartyRelation<DealPartyRelationType.DealEntity> & {
    relationRoleType: LoanPartyEntityRoleType[];
  };

export type DealPartyRelation = DealToIndividualRelation | DealToEntityRelation;
