import { BaseEntity, FirestoreTimestampFieldUnionType } from "../db";
import { AuditEvent } from "./audit.event.types";

export type NotificationAuditEvent = AuditEvent & {
  status: NotificationStatus;
  notifiedAt: FirestoreTimestampFieldUnionType;
};

export enum NotificationStatus {
  UNSEEN_UNREAD = "UNSEEN_UNREAD",
  SEEN_READ = "SEEN_READ",
  SEEN_UNREAD = "SEEN_UNREAD"
}

export type NotificationUser = BaseEntity<{
  notifications: NotificationAuditEvent[];
  notify: boolean;
}>;
