import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { includes, isEmpty, mapValues, pull, range } from "lodash";
import { NavigationItemDictionary } from "../../../shared/types/navigation.types";
export type NavigationSliceState = {
  isAllExpanded: {
    [page: string]: {
      [tab: string]: boolean;
    };
  };
  tabsSections: {
    [page: string]: {
      [tab: string]: {
        [index: string]: {
          selectedArray: number[];
          length: number;
          excludeCollapse?: number[];
        };
      };
    };
  };
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    isAllExpanded: {},
    tabsSections: {}
  } as NavigationSliceState,
  reducers: {
    setExpandAll: (
      state,
      action: PayloadAction<{
        navigationPathDictionary: NavigationItemDictionary;
      }>
    ) => {
      const { navigationPathDictionary } = action?.payload || {};
      const { page, tab } = navigationPathDictionary;

      if (state?.tabsSections?.[page]?.[tab]) {
        const transformedObject = mapValues(
          state.tabsSections[page][tab],
          ({ length, excludeCollapse }) => ({
            length,
            selectedArray: range(length),
            excludeCollapse
          })
        );

        state.isAllExpanded = {
          ...state.isAllExpanded,
          [page]: {
            ...(state.isAllExpanded?.[page] || {}),
            [tab]: true
          }
        };
        state.tabsSections = {
          ...state.tabsSections,
          [page]: {
            ...(state.tabsSections?.[page] || {}),
            [tab]: transformedObject
          }
        };
      }
    },
    setCollapseAll: (
      state,
      action: PayloadAction<{
        navigationPathDictionary: NavigationItemDictionary;
      }>
    ) => {
      const { navigationPathDictionary } = action?.payload || {};
      const { page, tab } = navigationPathDictionary;

      if (state?.tabsSections?.[page]?.[tab]) {
        const emptyTransformedObject = mapValues(
          state.tabsSections[page][tab],
          ({ length, excludeCollapse }) => {
            const newSelectedArray = !isEmpty(excludeCollapse)
              ? excludeCollapse!
              : [];
            return { length, selectedArray: newSelectedArray, excludeCollapse };
          }
        );

        state.isAllExpanded = {
          ...state.isAllExpanded,
          [page]: {
            ...(state.isAllExpanded?.[page] || {}),
            [tab]: false
          }
        };
        state.tabsSections = {
          ...state.tabsSections,
          [page]: {
            ...(state.tabsSections?.[page] || {}),
            [tab]: emptyTransformedObject
          }
        };
      }
    },
    setTabsSections: (
      state,
      action: PayloadAction<{
        navigationPathDictionary: NavigationItemDictionary;
        selectedArray: number[];
        sectionLength: number;
        index: string;
        excludeCollapse?: number[];
      }>
    ) => {
      const {
        sectionLength: length,
        selectedArray = [],
        navigationPathDictionary,
        index,
        excludeCollapse
      } = action?.payload || {};
      const { page, tab } = navigationPathDictionary;
      state.tabsSections = {
        ...state.tabsSections,
        [page]: {
          ...(state.tabsSections?.[page] || {}),
          [tab]: {
            ...(state.tabsSections?.[page]?.[tab] || {}),
            [index]: { selectedArray, length, excludeCollapse }
          }
        }
      };
    },
    updateTabsSections: (
      state,
      action: PayloadAction<{
        navigationPathDictionary: NavigationItemDictionary;
        index: string;
        item: number;
      }>
    ) => {
      const { navigationPathDictionary, index, item } = action?.payload || {};
      const { page, tab } = navigationPathDictionary;

      const section = state.tabsSections?.[page]?.[tab]?.[index];
      if (section) {
        if (includes(section.selectedArray, item)) {
          pull(section.selectedArray, item);
        } else {
          section.selectedArray.push(item);
        }
      }
    }
  }
});
