import {
  ElphiEntityType,
  FieldType,
  PropertyInsurancePolicyView
} from "@elphi/types";
import { get } from "lodash";
import { MaximizedInfo } from "../../../../common-components/card";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { getSpecs } from "../../../../forms/schemas/factories/specsFactory";
import { coveragesTypesToFieldsMapping } from "../wizard/steps/coverages-data-step/utils/coveragesSection";

export const MaximaizedPropertyCoveragesInfo = (props: {
  view: PropertyInsurancePolicyView;
  propertyId: string;
}) => {
  const { view, propertyId } = props;
  const selectedCoverageTypes = view.policy.coverageTypes;
  const visibleFields = selectedCoverageTypes
    .map((c) => {
      const pathArr = coveragesTypesToFieldsMapping[c];
      return pathArr.map((p) => p);
    })
    .flat();

  const fieldsSpecs = getSpecs();
  const specs =
    fieldsSpecs?.[ElphiEntityType.insurancePolicy]?.specsParts?.property
      ?.propertyInsurancePolicyCoveragesFieldBaseSpecs;
  return visibleFields.map((visibleField, i) => {
    const spec = get(specs, visibleField);

    const value = get(view?.entities?.[propertyId], visibleField);
    return (
      <MaximizedInfo
        key={i}
        title={spec?.label || NOT_AVAILABLE}
        subTitle={
          !value
            ? NOT_AVAILABLE
            : spec?.fieldType === FieldType.Money
            ? `$${value}`
            : value
        }
      />
    );
  });
};
