import {
  BusinessFeatureFlag,
  businessFeatureFlagFallback
} from "./businessFeatureFlag.enum";
import { DevFeatureFlag, devFeatureFlagFallback } from "./devFeatureFlag.enum";

export type FeatureFlag = DevFeatureFlag | BusinessFeatureFlag;
export type FeatureFlagsFallback<T extends FeatureFlag> = {
  [key in T]: boolean;
};

const featureFlagsFallback: FeatureFlagsFallback<FeatureFlag> = {
  ...devFeatureFlagFallback,
  ...businessFeatureFlagFallback
};

export * from "./statsigEnvNames";
export { BusinessFeatureFlag, DevFeatureFlag, featureFlagsFallback };
