import { ElphiEntityType } from "@elphi/types";
import { creditReportFieldBaseSpecs } from "../../../../components/form-builder/field-specs/credit-report/credit-report.fields";
import { baseElphiFieldsSpecs } from "../../base/spec-files/baseElphiFieldsSpecs";
import { mergeSpecs } from "../../utils/formsSpec.utils";
import { LimaSpecs } from "../types";
import { fullLimaSchemaAccountAssetFieldSpecs } from "./account-asset/accountAsset.fields";
import {
  fullLimaDealToEntitySpecs,
  fullLimaDealToIndividualSpecs
} from "./deal-party/dealParty.fields";
import { fullLimaDealSpecs } from "./deal/deal.fields";
import {
  fullLimaSchemaEntityPartySpecs,
  fullLimaSchemaIndividualPartySpecs,
  limaCommonPartySpecs
} from "./party";
import {
  fullLimaSchemaEntityToEntityFieldSpecs,
  fullLimaSchemaEntityToIndividualFieldSpecs
} from "./party-relation/partyRelation.fields";
import { fullLimaPropertySpecs } from "./property/property.fields";

export const limaSpecs: LimaSpecs = {
  ...baseElphiFieldsSpecs,
  [ElphiEntityType.deal]: {
    entitySpecs: fullLimaDealSpecs,
    specsParts: {}
  },
  [ElphiEntityType.property]: {
    entitySpecs: fullLimaPropertySpecs,
    specsParts: {}
  },
  [ElphiEntityType.party]: {
    entitySpecs: mergeSpecs(
      limaCommonPartySpecs,
      fullLimaSchemaEntityPartySpecs,
      fullLimaSchemaIndividualPartySpecs
    ),
    specsParts: {
      basePartyFieldSpecs: limaCommonPartySpecs,
      entityPartyFieldSpecs: fullLimaSchemaEntityPartySpecs,
      individualPartyFieldSpecs: fullLimaSchemaIndividualPartySpecs
    }
  },
  [ElphiEntityType.dealParty]: {
    entitySpecs: mergeSpecs(
      fullLimaDealToIndividualSpecs,
      fullLimaDealToEntitySpecs
    ),
    specsParts: {
      dealToIndividualFieldSpecs: fullLimaDealToIndividualSpecs,
      dealToEntityFieldSpecs: fullLimaDealToEntitySpecs
    }
  },
  [ElphiEntityType.partyRelation]: {
    entitySpecs: mergeSpecs(
      fullLimaSchemaEntityToEntityFieldSpecs,
      fullLimaSchemaEntityToIndividualFieldSpecs
    ),
    specsParts: {
      entityToEntityRelationFieldSpecs: fullLimaSchemaEntityToEntityFieldSpecs,
      entityToIndividualRelationFieldSpecs:
        fullLimaSchemaEntityToIndividualFieldSpecs
    }
  },
  [ElphiEntityType.asset]: {
    entitySpecs: fullLimaSchemaAccountAssetFieldSpecs
  },
  [ElphiEntityType.creditScore]: {
    entitySpecs: creditReportFieldBaseSpecs,
    specsParts: {}
  }
};
