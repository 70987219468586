export const convertToCSV = (data) => {
  if (data) {
    const header = Object.keys(data?.[0]);
    const csv = [header?.join(",")];

    data.forEach((obj) => {
      const row = header?.map((field) => obj[field]);
      csv.push(row.join(","));
    });

    return csv.join("\n");
  }
  return "";
};

export const convertToHtml = (data) => {
  let html = '<table style="border-collapse: collapse;">\n';
  // Add header row
  const headers = Object.keys(data?.[0]);
  html += "<tr>";
  headers?.forEach((header) => {
    html += `<th style="border: 1px solid #ddd; padding: 8px;">${header}</th>`;
  });
  html += "</tr>\n";
  // Add data rows
  data.forEach((row) => {
    html += "<tr>";
    Object.values(row).forEach((cell) => {
      html += `<td style="border: 1px solid #ddd; padding: 8px;">${cell}</td>`;
    });
    html += "</tr>\n";
  });
  html += "</table>";
  return html;
};
