import { createIcon } from "@chakra-ui/icons";

export const DownloadIcon = createIcon({
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 15V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V15"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11L12 15L16 11"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15V3"
        stroke="#505872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
});
