import { FirestoreTimestampFieldUnionType } from "../../../../../db";
import {
  WireInsuranceVendorFields,
  WireInsuranceVendorType
} from "../wireInsuranceVendor.types";

//TODO remove
export enum ElphiTrxStatus {
  Pending = "Pending Order",
  InProcess = "In Process",
  Success = "Success",
  Failed = "Failed"
}

export enum FundingShieldTrxStatus {
  IssuesReported = "Issues Reported",
  RejectedArchived = "Rejected / Archived",
  InProcess = "In Process",
  Approved = "Approved",
  ApprovedArchived = "Approved / Archived"
}

export enum WireInsuranceFile {
  uploadErrorsAndOmissionsPolicy = "uploadErrorsAndOmissionsPolicy",
  uploadWiresInstructions = "uploadWiresInstructions",
  uploadClosingProtectionLetter = "uploadClosingProtectionLetter",
  uploadCrimesPolicy = "uploadCrimesPolicy"
}

export type FundingShieldFailedStepLabel =
  | "expired"
  | "failedOrder"
  | "failedUploadingCertificate"
  | "failedFetchCertificate"
  | "failedInitialization"
  | "dealMilestone";

export type WireInsuranceCertificateFundingShieldFields =
  WireInsuranceVendorFields<WireInsuranceVendorType.FundingShield> & {
    transactionId: string;
    initializedDate: FirestoreTimestampFieldUnionType;
    attemptedDate?: FirestoreTimestampFieldUnionType;
    finalizedDate?: FirestoreTimestampFieldUnionType;
    retryAttempt: number;
    elphiTrxStatus: ElphiTrxStatus;
    failedLabel?: FundingShieldFailedStepLabel | string;
    fundingShieldTrxStatus: FundingShieldTrxStatus | string;
  };
