import { Box } from "@chakra-ui/react";
import { Deal, Property } from "@elphi/types";
import { useEffect, useMemo } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/common";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import useDealHooks from "../../../../hooks/deal.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { usePaginationHooksV2 } from "../../../../redux/v2/hooks/pagination.hooks.v2";
import { propertyApi } from "../../../../redux/v2/property";
import { uniqCursorId } from "../../../../redux/v2/utils/filter-table.utils";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import { headerCells } from "./PropertyHeader";
import { PropertyRow, PropertyTableState } from "./PropertyRow";
import { usePropertyTableState } from "./hooks";
import {
  QueryParams,
  getCurrentCursorType,
  usePropertyItem
} from "./propertyItem.hooks";

export type PropertyTableProps = PropertyTableContainerProps & {
  state: PropertyTableState;
} & {
  selectedProperty?: Property;
  isLoading: boolean;
};

export type PropertyTableContainerProps = {
  isDisabled?: boolean;
  propertyId?: string;
  userId?: string;
  dealId?: string;
  pageSize?: number;
  h?: string;
  snapshotId?: string;
  isActionable?: boolean;
};

export type PropertyTableStateToUpdate = {
  properties: { [id: string]: { id: string } & Partial<Property> };
  deals: { [id: string]: { id: string } & Partial<Deal> };
};

export type PropertyListItem = {
  propertyId?: string;
  dealPropertyId?: string;
};

const TABLE_WIDTH = "5080px";
export const PropertyTable = (props: PropertyTableProps) => {
  const pageSize = props.pageSize || DEFAULT_PAGE_SIZE;
  const { propertyId, userId, state } = props;
  const { dealPropertyState, dealUserState, propertyState } = state;
  const propertyTableState = props.snapshotId
    ? usePropertyTableState({ options: { config: { isNonMutation: false } } })
    : usePropertyTableState();
  const { setPropertyTableCursor, getPropertyTableCursor } = propertyTableState;
  const { dealTableSelectedDealId } = useDealHooks();
  const { getPropertyItems } = usePropertyItem(state);
  const propertyPagination = usePaginationHooksV2({
    input: {
      propertyId,
      userId
    },
    limit: pageSize,
    paginationProvider: propertyApi.useLazyPaginateV2Query,
    setHasMore: async (hasMore) => {
      propertyTableState.setCurrentCursorHasMore(hasMore);
    },
    setCursor: (cursor) => {
      const filtersUniqCursorId = uniqCursorId({
        id: props.propertyId,
        userId: props.userId
      });
      propertyTableState.setCurrentCursorId(filtersUniqCursorId);
      const currentCursorType = getCurrentCursorType({
        userId: props.userId,
        propertyId: props.propertyId
      });
      setPropertyTableCursor({
        cursor,
        cursorType: currentCursorType,
        propertyId: props.propertyId,
        userId: props.userId
      });
    },
    currentCursor: getPropertyTableCursor({
      cursorType: getCurrentCursorType({
        userId: props.userId,
        propertyId: props.propertyId
      }),
      propertyId: props.propertyId,
      userId: props.userId
    })
  });
  const isLoading =
    propertyPagination.paginateApiResponse.isFetching ||
    propertyPagination.paginateApiResponse.isLoading;

  useAuthStateChangeHook({
    onAuthStateChange: (_) => !items?.length && propertyPagination.nextPage(),
    deps: [auth.currentUser]
  });

  useEffect(() => {
    const uniqId = uniqCursorId({
      id: props.propertyId,
      userId: props.userId
    });
    propertyTableState.setCurrentCursorId(uniqId);
    propertyTableState.setCurrentCursorHasMore(true);
    if (props.propertyId || props.userId) {
      !isLoading && propertyPagination.nextPage();
    }
  }, [props.propertyId, props.userId]);

  const items: PropertyListItem[] = useMemo(() => {
    const { dealId, propertyId, userId } = props;
    const queryParams: QueryParams = { dealId, propertyId, userId };
    return getPropertyItems(queryParams);
  }, [
    props.propertyId,
    props.userId,
    props.dealId,
    dealPropertyState,
    propertyState,
    dealUserState,
    dealTableSelectedDealId
  ]);
  const { heightOffsetInPx } = useWindowDimensions();
  const OFFSET_HEIGHT = 600;
  return (
    <Box w={TABLE_WIDTH} h="100%">
      <ElphiPaginationList
        height={props.h || heightOffsetInPx(OFFSET_HEIGHT)}
        headerCells={headerCells}
        pageSize={pageSize}
        hasMore={
          !props.snapshotId ? propertyTableState.currentCursorIdHasMore : false
        }
        items={items}
        isLoading={isLoading}
        next={propertyPagination.nextPage}
        orderableRowBuilder={({ item, index, tableName, isOrderable }) => {
          return (
            <Box
              p="10px"
              bgColor={
                propertyState.selectedId &&
                propertyState.selectedId === item.propertyId
                  ? elphiTheme.components.light.table.rows.selected.bg
                  : ""
              }
              borderTopColor={
                propertyState.selectedId &&
                propertyState.selectedId === item.propertyId
                  ? elphiTheme.components.light.table.rows.selected.border
                      .secondary
                  : ""
              }
              borderBottomColor={
                propertyState.selectedId &&
                propertyState.selectedId === item.propertyId
                  ? elphiTheme.components.light.table.rows.selected.border
                      .secondary
                  : elphiTheme.components.light.table.rows.selected.border.main
              }
              borderTopWidth={
                propertyState.selectedId === item.propertyId ? "2px" : ""
              }
              borderBottomWidth={
                propertyState.selectedId === item.propertyId ? "2px" : ""
              }
            >
              {item.propertyId ? (
                <PropertyRow
                  index={(Number(index) + 1).toString()}
                  isDisabled={props.isDisabled}
                  propertyId={item.propertyId}
                  dealPropertyId={item.dealPropertyId}
                  state={state}
                  isActionable={props.isActionable}
                  tableName={tableName}
                  isOrderable={isOrderable}
                />
              ) : null}
            </Box>
          );
        }}
        isOrderable={true}
        tableName={"Properties"}
      />
    </Box>
  );
};
