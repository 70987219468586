import { FieldType } from "./fieldFormat.types";
import { StateCode } from "./location.types";
import {
  LeaseStatusType,
  PropertyLoanPurpose,
  PropertyType
} from "./property.enums.types";

export type PortfolioTemplateMetadata = {
  type: string;
  override: boolean;
  overrideValue: string;
  status: string;
};

export enum PortfolioTemplateType {
  LongTerm = "long-term",
  ShortTerm = "short-term"
}

export type BasePortfolioTemplate = {
  ParcelNumber: string;
  AddressLineText: string;
  AddressUnitIdentifier: string;
  CityName: string;
  StateCode: StateCode;
  PostalCode: string;
  PropertyType: PropertyType;
  FinancedUnitCount: string;
  PropertyLoanPurpose: PropertyLoanPurpose;
  PurchasePriceAmount: string;
  OriginalPurchaseDate: string;
  OriginalPurchasePriceAmount: string;
  OutstandingLoanPayoffAmount: string;
  LTVRatePercent: string;
  AllocatedLoanAmount: string;
  RenovationCostAmount: string;
};

export type LongTermPortfolioTemplate = BasePortfolioTemplate & {
  LeaseStatusType: LeaseStatusType;
  MonthlyLeaseRentAmount: string;
  AnnualTaxesAmount: string;
  AnnualPropertyInsuranceAmount: string;
  AnnualFloodInsuranceAmount: string;
  AnnualHomeownersAssociationFeeAmount: string;
  PropertyValuationAmount: string;
  ContractClosingDate: string;
};

export type ShortTermPortfolioTemplate = BasePortfolioTemplate & {
  PropertyLotIdentifier: string;
  PropertyBlockIdentifier: string;
  PropertySubdivisionIdentifier: string;
  PropertyValuationAmount: string;
  SubjectToPropertyValuationAmount: string;
  TotalCostAmount: string;
  ConstructionCostAmount: string;
  ConstructionLTCRatePercent: string;
  ConstructionLTCLoanAmount: string;
  BlendedLTCRatePercent: string;
  BlendedLTCLoanAmount: string;
  LTARVRatePercent: string;
  LTARVAmount: string;
  LTVLoanAmount: string;
  NetFundAmount: string;
  NetFundRatePercent: string;
  SettlementStatementAmount: string;
};

export type PortfolioTemplate =
  | LongTermPortfolioTemplate
  | ShortTermPortfolioTemplate;

export type PortfolioTemplateToFieldType = {
  [K in keyof LongTermPortfolioTemplate &
    ShortTermPortfolioTemplate]: FieldType;
};

export type TemplateToProperty<T> = {
  [K in keyof T]: string[];
};

export type PortfolioPageContainerProps = {
  dealId: string;
};

export type SelectedCells = {
  end: { i: number; j: number };
  start: { i: number; j: number };
};

export type LongTermPortfolioHeader = {
  [K in keyof LongTermPortfolioTemplate]: { index: number; label: string };
};

export type ShortTermPortfolioHeader = {
  [K in keyof ShortTermPortfolioTemplate]: { index: number; label: string };
};

export enum DataSheetCellState {
  Valid = "Valid",
  FollowUp = "Follow Up",
  None = "None"
}

export type DataSheetCellMetadata = {
  override: boolean;
  state: DataSheetCellState;
};

export type PropertyCellData = DataSheetCellMetadata & {
  value: string;
  key: string;
  fieldPath: string[];
  propertyId: string;
};
