import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import { SelectDataType, SelectFormatsType, Task } from "@elphi/types";
import { isEmpty, values } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import useDealHooks from "../../../hooks/deal.hooks";
import { usePartyHooks } from "../../../hooks/party.hooks";
import useTaskHooks from "../../../hooks/task.hooks";
import { RootState } from "../../../redux/store";
import { FieldType } from "../../form-builder/fieldFormat.types";
import ModalContainer from "../../modal-container/ModalContainer";
import { useElphiToast } from "../../toast/toast.hook";
import { mapEnumValues } from "../../utils/general.utils";
import {
  exportChecklistActions,
  selectFormatsOptions,
  transformedTaskEntities
} from "../../utils/task.utils";
import { ChecklistInputBuilder } from "./ChecklistInputBuilder";

export type ExportTaskChecklistProps = {
  dealId?: string;
  propertyId?: string;
  partyId?: string;
  isDisabled?: boolean;
};
export const ExportTaskChecklist = (props: ExportTaskChecklistProps) => {
  const { taskState } = useTaskHooks();
  const { selectedDeal } = useDealHooks();
  const dealPropertyState = useSelector(
    (state: RootState) => state.dealPropertyRelation
  );
  const dealPartyState = useSelector(
    (state: RootState) => state.dealPartyRelation
  );
  const propertyState = useSelector((state: RootState) => state.property);
  const partyRelationState = useSelector(
    (state: RootState) => state.partyRelation
  );
  const { partyState } = usePartyHooks();
  const partyAssetState = useSelector((state: RootState) => state.partyAsset);
  const assetState = useSelector((state: RootState) => state.asset);
  const statementState = useSelector((state: RootState) => state.statement);
  const creditScoreState = useSelector(
    (state: RootState) => state.creditReport
  );
  const { successToast, errorToast } = useElphiToast();

  const states = {
    dealPropertyState,
    dealPartyState,
    propertyState,
    partyRelationState,
    partyState,
    partyAssetState,
    selectedDeal,
    assetState,
    statementState,
    creditScoreState
  };

  const [selectData, setSelectData] = useState(SelectDataType.EntireChecklist);
  const [selectFormats, setSelectFormats] = useState<string[] | undefined>([
    SelectFormatsType.TableToClipboard
  ]);

  useEffect(() => {
    setDefaultSelect();
  }, [taskState?.filteredIds]);

  const setDefaultSelect = () => {
    if (taskState?.filteredIds?.length) {
      setSelectData(SelectDataType.FilteredChecklist);
    } else {
      setSelectData(SelectDataType.EntireChecklist);
    }
    setSelectFormats([SelectFormatsType.TableToClipboard]);
  };

  const onConfirm = () =>
    selectFormats?.forEach((selectFormat) => {
      exportChecklistActions[selectFormat](
        transformedTaskEntities(
          selectData === SelectDataType.FilteredChecklist &&
            !isEmpty(taskState?.filteredIds)
            ? taskState?.filteredIds!
            : taskState?.ids!,
          taskState?.entities as {
            [id: string]: Task;
          },
          states
        ),
        { successToast, errorToast }
      );
    });

  const getSelectFormatsOptionsValues = () => {
    const isCSVSelected = selectFormats?.includes(
      SelectFormatsType.CSVToClipboard
    );
    const isTableSelected = selectFormats?.includes(
      SelectFormatsType.TableToClipboard
    );

    selectFormatsOptions[SelectFormatsType.CSVToClipboard].isDisabled =
      isTableSelected;
    selectFormatsOptions[SelectFormatsType.TableToClipboard].isDisabled =
      isCSVSelected;

    return values(selectFormatsOptions);
  };

  return (
    <ModalContainer
      header="Export Checklist"
      body={
        <Box marginBottom={10}>
          <ChecklistInputBuilder
            title={"Select Data"}
            fieldType={FieldType.RadioSelect}
            onSetState={setSelectData}
            options={mapEnumValues(SelectDataType)}
            selectData={selectData}
            marginBottom={5}
          />
          <ChecklistInputBuilder
            title={"Select Format(s)"}
            fieldType={FieldType.MultiCheckbox}
            onSetState={setSelectFormats}
            options={getSelectFormatsOptionsValues()}
            selectData={selectFormats}
          />
        </Box>
      }
      maxWidth={400}
      submit={{ onConfirm }}
      onCloseComplete={() => setDefaultSelect()}
      customStyles={{ footer: { flexDir: "unset" } }}
    >
      <Box>
        <Button
          isDisabled={props.isDisabled}
          leftIcon={<ExternalLinkIcon />}
          {...elphiTheme.components.light.button.secondary}
        >
          Export Checklist
        </Button>
      </Box>
    </ModalContainer>
  );
};
