import { Type } from "@sinclair/typebox";
import {
  ServiceProviderEntityType,
  ServiceProviderStatusType
} from "../../entities";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "../service.typebox";
import {
  ArrayField,
  BooleanOrUndefined,
  EnumField,
  StringField
} from "../utils.typebox";

const dealEntities = Type.Record(
  Type.String(),
  Type.Object({
    entityIds: ArrayField(StringField)
  })
);

export const BranchRepRelationSchema = Type.Intersect([
  BaseEntitySchema,
  Type.Object({
    branchId: StringField,
    representativeId: StringField,
    companyId: StringField,
    dealIds: ArrayField(StringField),
    dealEntities,
    entityType: EnumField(ServiceProviderEntityType),
    partyIds: ArrayField(StringField)
  })
]);

export const BranchRepRelationCreate = OmitCreateSchema(
  BranchRepRelationSchema
);
export const BranchRepRelationUpdate = OmitUpdateSchema(
  BranchRepRelationSchema
);

export const RolodexServiceProviderViewSchema = Type.Intersect([
  BaseEntitySchema,
  Type.Object({
    domainConfigurationId: StringField,
    companyId: StringField,
    companyName: StringField,
    companyStatus: EnumField(ServiceProviderStatusType),
    branchId: StringField,
    branchName: StringField,
    branchStatus: EnumField(ServiceProviderStatusType),
    repId: StringField,
    repName: StringField,
    repStatus: EnumField(ServiceProviderStatusType),
    repSkipped: BooleanOrUndefined,
    dealIds: ArrayField(StringField),
    dealEntities,
    entityType: EnumField(ServiceProviderEntityType),
    partyIds: ArrayField(StringField),
    status: ArrayField(EnumField(ServiceProviderStatusType))
  })
]);
