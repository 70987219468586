import { useEffect, useRef, useState } from "react";

export const useSkeletonVisibility = (
  isProcessActive: boolean,
  itemCount: number
) => {
  const [showSkeleton, setShowSkeleton] = useState(false);
  const previousCount = useRef(itemCount);

  useEffect(() => {
    if (isProcessActive) {
      setShowSkeleton(true);
    } else if (showSkeleton && itemCount > previousCount.current) {
      setShowSkeleton(false);
    }

    previousCount.current = itemCount; // Update the previous count after each render
  }, [isProcessActive, itemCount, showSkeleton]);

  return showSkeleton;
};